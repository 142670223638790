import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';

// Actions
import { toggleOverlayOnScreenResponsive } from 'Actions';


// Functions
import {
  validateEmail, validateDomain, validateNumbersOnly,
  validateAlphaNoSpacesOnly, validateAlphaWithSpacesOnly,
  validateTextOnly,
} from 'Helpers/Functions';


class IdentityInputV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.moreControlsPopup = this.moreControlsPopup.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.state = {
      value: this.props.value,
      errors: false,
      // pattern: this.props.pattern,
      // maxlength: this.props.maxlength,

      verified: false,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let errors = false;
    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        // Not done yet
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(this.props.value);
        if (validateNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(this.props.value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }
    this.setState({
      errors,
    });

  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  moreControlsPopup(evt) {
    //console.log('moreControlsPopup');
    //console.log('Verify Account');
    const data = {};
    this.props.toggleOverlayOnScreenResponsive('open', 'VerifyAccount', data);
  }

  handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);
    //console.log(typeof (identifiers));
    /* RETURN THE CALLER
    Attempt to return the caller the following data as an object.
    This will be used to push into an array for a state update.
      {
        name: 'test5.nested2.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
      }
    */

    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        // Not done yet
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(value);
        if (validateNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }

    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value,
    };

    //console.log('=== onInputChange Return ===');
    //console.log(returnObj);


    this.props.onChangeInputTextHandler(returnObj);
    let textStyle;
    if (this.props.feature === 'AsYouType') {
      textStyle = new AsYouType().input(event.target.value);
      this.setState({
        value: textStyle,
        errors,
      });
    } else {
      this.setState({
        value: event.target.value,
        errors,
      });
    }

    /*
    //console.log("Regular Expression Matching Validatior");
    //console.log(this.state.pattern);
    //console.log(this.state.maxlength);
    //console.log(this.props.maxlength);
    let re = new RegExp(this.state.pattern, 'gi');
    let found = event.target.value.match(re);
    //console.log(found);
    //console.log("end regular expression matching");
    */
  }


  render() {
    const {
      id, name, label, identifiers, stateIdentifier,
      value, onChangeInputTextHandler, maxlength,
      dataType,
      pattern,
      dataErrorMessage,

      feature = '',


    } = this.props;


    return (
      <div>
        <div className={classNames({ ShcIdentityInput_inputSection: true })}>
          <div>


            <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
            ShcIdentityInput_textInput_root: true,
          })}
          >
              <div className={classNames({
              ShcIdentityInput_textInput_child: true,
              ShcIdentityInput_textInput_errors_icon: this.state.errors,
            })}
            >
                <input
                  {...this.props}
                  id={id}
                  label={label}
                  margin="normal"
                  fullWidth
                  name={name}
                  value={this.state.value}
                  identifiers={identifiers}
                  stateIdentifier={stateIdentifier}
                  onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
                  type="text"
                  className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.errors })}
              />
              </div>
            </div>


          </div>

          <div className={classNames({
            ShcIdentityInput_helpText: true,
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
          })}
          >
            <div>
              {this.state.verified ? (
                <div>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>

      
                </div>
                ) : (
                <div className={classNames({flex: true, flexJustifySpaceBetween: true, flexAlignBaseline: true})}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>

                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>

                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>

                </div>
                )}
            </div>
            <div>

              <StandardBtn
                icon=""
                iconLocation="right"
                text="MORE"
                tooltip=""
                action=""
                classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                handleClick={this.moreControlsPopup}
              />
            </div>
          </div>

        </div>


                   

        <OverlayOnScreenResponsive
         id="VerifyAccount"
         type="default"
         width="50%"
         height="100vh"
         anchor="right"
         title="Identity Verification"
         classes=""
        >
          <div>
            <div className={classNames({ ShcIdentityInput_popupContainer: true })}>
              <div className={classNames({ aaa: true })}>
                
                <TextStyle size="h4" weight="bold">To verify your account, 
                click the Verify button below. </TextStyle>

                <br/>
                <TextStyle size="h4" weight="bold">
                  <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                  aria-label="Not Verified"
                  ></i>
                  Your input is invalid. Please close this window and enter in a valid identity.  

                </TextStyle>

                
              </div>
              <br/>
              <div className={classNames({ ShcIdentityInput_popupSecurityBox: true })}>
                <TextStyle size="h5" weight="bold">
                  <i className={`fas fa-shield-alt ${classNames({ShcIdentityInput_shieldIcon: true})}`}
                  aria-label="Security is Important"
                  ></i>
                  We take security seriously!
                </TextStyle>
                <TextStyle size="h5" weight="">
                You can verify our interaction with you by
                comparing this interaction-code below with the one 
                on the message we send you.
                </TextStyle>
                <br/>
                <TextStyle size="h5" weight="bold">Interaction Code: A123456</TextStyle>
                
              </div>
              <br/>
              
              <div className={classNames({ aaa: true })}>

                <TextStyle size="h5" weight="bold">Identity Verification Code</TextStyle>
                <TextStyle size="h5" weight="">
                Once you have clicked the Verify button, you will receie a verification code. Please enter
                in the following text field:
                </TextStyle>

                  input box

              </div>



              <br /><br />
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true, ShcIdentityInput_popupBorder: true })}>
                <div className={classNames({ abc: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Cancel"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-secondary"
                    handleClick={() => this.closeOverlayOnScreen('VerifyAccount')}
                  />
                </div>
                <div className={classNames({ abc: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Verify"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={() => this.handleAddNewUserConfirmed(this.props.overlayOnScreenData.id)}
                  />
                </div>
              </div>
            </div>

            <div className={classNames({ ShcIdentityInput_popupBorderFooter: true })}>
              <div className={classNames({ flex: true, flexJustifyFlexStart: true, ShcIdentityInput_popupContainerFooter: true })}>
                <div className={classNames({ abc: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Help"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-outline-info"
                    handleClick={() => this.closeOverlayOnScreen('VerifyAccount')}
                  />
                </div>
                <div className={classNames({ abc: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="History"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-outline-info"
                    handleClick={() => this.closeOverlayOnScreen('VerifyAccount')}
                  />
                </div>
              </div>

            </div>
          </div>
        </OverlayOnScreenResponsive>






      </div>
    );
  }
}

IdentityInputV1.propTypes = {

};
IdentityInputV1.defaultProps = {

};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,

});

const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityInputV1);

/*
<TextStyle size="h1" weight="bold">H1 Bold</TextStyle>
<TextStyle size="h1" weight="">H1</TextStyle>
<TextStyle size="h2" weight="bold">H2 Bold</TextStyle>
<TextStyle size="h2" weight="">H2 </TextStyle>
<TextStyle size="h3" weight="bold">H3 Bold</TextStyle>
<TextStyle size="h3" weight="">H3 </TextStyle>
<TextStyle size="h4" weight="bold">H4 Bold</TextStyle>
<TextStyle size="h4" weight="">H4 </TextStyle>
<TextStyle size="h5" weight="bold">H5 Bold</TextStyle>
<TextStyle size="h5" weight="">H5 </TextStyle>
*/