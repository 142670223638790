import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Material UI
import Grid from '@material-ui/core/Grid';

// Stripe Payments
import { Elements, StripeProvider } from 'react-stripe-elements';
import { BusinessRegistrationCheckout, BusinessRegistrationCheckoutV2 } from 'Components/Stripe';


// CSS
import componentStyles from './styles.css';
import commonStyles from '../../Components/_CommonStyles/commonStyles.css';


// Components
// import Breadcrumb from 'Components/Breadcrumb';

import { InputTextV3, IdentityInputV1, IdentityInputV2, RadioOptionCardV1, LinkCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { TextStyle } from 'Components/ShcTextStyles';

import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { MessageTip, MessageHighlight } from 'Components/ShcText';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

// Actions
import { 
  requestBusinessTrialAccount,
  logoutUser, //required to be on each page
  syncProducts } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.requestBusinessTrialAccount = this.requestBusinessTrialAccount.bind(this);
    this.handleTrialInputChange = this.handleTrialInputChange.bind(this);

    this.state = {
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      businessName: '',
      businessWebsite: '',
      contactName: '',
      contactEmail: '',


      apiTimerCollection: [], // List of active timers. (MANDATORY)
    };
  }


  handleTrialInputChange(item) {
    console.log("item");
    console.log(item);
    console.log("item.value");
    console.log(item.value);



    this.setState({
      [item.name]: item.value,
    });



  }



  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }



  async componentDidMount(props) {
    //console.log('Sync Available Subscription Products');
    const apiActivityId = "SyncProducts";
    const apiUi = { apiActivityId };
    const data = {};
    
    console.warn("====== componentDidMount | syncProducts =======");
    this.props.syncProducts({ data, apiUi });





    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  requestBusinessTrialAccount(apiActivityId) {
    console.log("requestBusinessTrialAccount");


    let data = {
      businessName: this.state.businessName,
      businessWebsite: this.state.businessWebsite,
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      
    }

    let business = {
      businessId: this.state.businessId,
    }

    let apiUi = {
      apiActivityId: apiActivityId,
    }

    console.log("data");
    console.log(data);

    this.props.requestBusinessTrialAccount({data, business, apiUi});

  }


  render() {
    const { userBusinessRoutePermissions } = this.props;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              Subscriptions
            </div>
            <br/>

            {this.props.businessAccountClosed === true ?
            (
              <div>
                <CardBasicV2>
                  This account is currently closed.
                </CardBasicV2>
              </div>
            ):(
              <div>

                {this.props.subscriptionProducts.length <= 0 ? 
                (
                  <div>
                    <CardBasicV2>
                      <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                        <br />
                        <div className={classNames({ text_s40: true })}>
                          <i className="fas fa-circle-notch fa-spin" />
                        </div>
                      </div>
                      <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                        <br />
                        <div className={classNames({ text_s20: true })}>
                        Loading
                        </div>
                      </div>
                    </CardBasicV2>
                  </div>
                ):(
                  <div>
                    <div>
                      <InputCard headlineText="Active Subscriptions" headlineSubText="">
                        <div className={classNames({ flex: true })}>
                          <div className={classNames({ width100p: true })}>
                            <div className={classNames({ flex: true })}>


                              {
                                  this.props.activeSubscriptions &&
                                  this.props.activeSubscriptions.length > 0 ?
                                    // Arrow function preserves "this"
                                    this.props.activeSubscriptions.map((item, i) => {
                                      let title = '';
                                      let summary = '';
                                      try {
                                        title = item.subscriptionProductDetail.title;
                                      } catch(e) {}
                                      try {
                                        summary = item.subscriptionProductDetail.summary;
                                      } catch(e) {}

                                      let linkAddress = '';
                                      if (title === 'Trial Subscription') {
                                        try {
                                          linkAddress = `/account/business/${this.state.businessId}/business-subscription/trial-access`
                                        } catch(e) {}
                                      } else {
                                        try {
                                          linkAddress = `/account/business/${this.state.businessId}/business-subscription/${item._id}`
                                        } catch(e) {}
                                      }

                                      
                                      

                                      return (
                                        <div className={classNames({ subscriptionItemActive: true })}>
                                          <Link
                                            to={linkAddress}
                                            className={classNames({textDecoration: true, linkGroup_navigationLinks: true, 
                                              linkGroup_navigationLinkHighlight: true })}
                                          >
                                            <LinkCardV1
                                              key={item._id}
                                              headlineText={title}
                                              secondaryText={summary}
                                            />
                                          </Link>
                                        </div>
                                      )}, this)
                                  : (
                                    <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                                      <TextStyle size="h4" weight="normal">None.</TextStyle>
                                    </div>
                                  )
                                }

                            </div>
                          </div>
                        </div>
                      </InputCard>
                    </div>
                    <div>
                      <InputCard headlineText="Subscription Options" 
                      headlineSubText="To purchase, upgrade or downgrade, select a plan below and complete the purchase.">


                        <div className={classNames({ flex: true })}>
                          <div className={classNames({ width100p: true })}>
                            <div className={classNames({ flex: true, flexWrap: true })}>


                              {
                              this.props.subscriptionProducts.length > 0 ?
                                  // Arrow function preserves "this"
                                this.props.subscriptionProducts.sort((a, b) => a.subscriptionId - b.subscriptionId).map((item, i) => (
                                  <div className={classNames({ subscriptionItemOption: true })}>
                                    <RadioOptionCardV1
                                      businessId={this.state.businessId}
                                      radioOptionsGroup="subscriptionSelection"
                                      radioOptionsId={item.subscriptionId}
                                      headlineText={item.title}
                                      secondaryText={item.summary}
                                      onSelectHandler=""
                                      />
                                  </div>
                                  ), this)
                              : (
                                <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                                  <TextStyle size="h4" weight="normal">None....</TextStyle>
                                </div>
                              )
                              }

                            </div>
                          </div>
                        </div>
                      </InputCard>
                    </div>

                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '100' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Basic" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            Our entry plan allows a business to get started with interacting with customers.
                            This allows our customers to be notififed in advance of your interaction improving 
                            the chances of a successful engagmenet. 
                            This subscription has a standard contract agreement that cannot be altered. 
                            
                            <br /><br/>
                            There are some restrictions of the Basic plan which can be viewed on our products page.
                          </TextStyle>
                          <TextStyle size="h4" weight="">
              
                            You have a maximum number of identities you can create for a business.
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }
                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '200' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Power" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            the Power plan provides additional features above the basic connect plans including 
                            additional user accounts, API access, etc
                          </TextStyle>
                          <TextStyle size="h4" weight="">
                            Terms of use & legal<br />
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }
                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '300' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Enterprise" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            The Enterprise plan is best suited for businesses that want to operate under custom
                            contracts and agreements. The Enterprise Plans are only available to minimum throughput 
                            requirements and incurs transition in costs.
                            <br />
                          </TextStyle>
                          <TextStyle size="h4" weight="">
                            Terms of use & legal
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }

                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '800' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Basic - Test" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            This is a test subscription. If you see this, please advise our staff via the Support Portal.
                          </TextStyle>
                          <TextStyle size="h4" weight="">
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }

                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '810' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Power - Test" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            This is a test subscription. If you see this, please advise our staff via the Support Portal.
                          </TextStyle>
                          <TextStyle size="h4" weight="">
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }

                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '820' ?
                    (
                      <div>
                        <InputCard headlineText="Plan - Enterprise - Test" headlineSubText="">
                          <TextStyle size="h4" weight="">
                            This is a test subscription. If you see this, please advise our staff via the Support Portal.
                          </TextStyle>
                          <TextStyle size="h4" weight="">
                          </TextStyle>
                        </InputCard>
                      </div>
                    ) :
                      null
                    }


                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '800' || 
                    this.props.selectedSubscription.selectedRadioOptionsId === '810' || 
                    this.props.selectedSubscription.selectedRadioOptionsId === '820' ?
                    (
                      <div>
                        <InputCard headlineText="Purchase this Subscription (Test Environment)" headlineSubText="">

                          <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                            {
                            this.props.subscriptionProducts.length > 0 ?
                                // Arrow function preserves "this"
                              this.props.subscriptionProducts.filter(x => x.subscriptionId === this.props.selectedSubscription.selectedRadioOptionsId).map((item, i) => {
                                let monthlyPrice = '(Unavailable)';
                                try {
                                  if (Number.isInteger(item.subscriptionPrice)) {
                                    monthlyPrice = item.subscriptionPrice/100;
                                    monthlyPrice = `$${monthlyPrice}`;
                                  }
                                } catch(e) {}
                                return (
                                  <div className={classNames({ flex: true, flexGrow: true })}>
                                    <div className={classNames({ flexGrow: true })}>
                                      <TextStyle size="h3" weight="bold">
                                        {item.title} Subscription
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                        Billed Monthly (auto-renew)<br/>
                                        Price: {monthlyPrice} per month<br />
                                        Plus Customer Interaction Usage costs<br/>
                                        Plus Business Identity Verification costs<br/>
                                      </TextStyle>
                                    </div>
                                    <div className={classNames({ aaa: true })}>
                                      <TextStyle size="h4" weight="">
                                      {monthlyPrice} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      per month<br/>
                                      Plus Usage Costs
                                      </TextStyle>
                                    </div>
                                  </div>
                                )
                              })
                            :
                              <TextStyle size="h5" weight="">
                                None selected.
                              </TextStyle>
                            } 
                          </div>

                          <br />
                          <hr />

                          <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                          {
                            this.props.subscriptionProducts.length > 0 ?
                                // Arrow function preserves "this"
                              this.props.subscriptionProducts.filter(x => x.subscriptionId === this.props.selectedSubscription.selectedRadioOptionsId).map((item, i) => {
                                let monthlyPrice = '(Unavailable)';
                                let monthlyPriceGST = '(Unavaialble)';
                                try {
                                  if (Number.isInteger(item.subscriptionPrice)) {
                                    monthlyPrice = item.subscriptionPrice/100;
                                    monthlyPrice = `$${monthlyPrice}`;
                                    monthlyPriceGST = (item.subscriptionPrice/100)*0.1;
                                    monthlyPriceGST = `$${monthlyPriceGST.toFixed(2)}`;
                                  }
                                } catch(e) {}
                                return (
                                  <div className={classNames({ flex: true, flexGrow: true })}>
                                    <div className={classNames({ flexGrow: true })}>
                                      <TextStyle size="h3" weight="bold">
                                        Total
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                        Additional charges based on usage.<br/>
                                        Visit our pricing page for more details.
                                      </TextStyle>
                                    </div>
                                    <div className={classNames({ aaa: true })}>
                                      <TextStyle size="h4" weight="">
                                      {monthlyPrice} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      included GST {monthlyPriceGST} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      per month<br/>
                                      Plus Usage Costs
                                      </TextStyle>
                                    </div>
                                  </div>
                                )
                              })
                            :
                              <TextStyle size="h5" weight="">
                                None selected.
                              </TextStyle>
                            } 
                          </div>


                          <br />
                          <div className={classNames({ borderTLight: true, marLRMinus10: true })} />
                          <br />

                          <div className={classNames({ aaa: true })}>
                            <div className={classNames({ aaa: true })}>
                              <StripeProvider stripe={this.state.stripe} apiKey="pk_test_ypX7KO3HyKJTBtJ4SoD6eXwb">
                                <BusinessRegistrationCheckoutV2
                                  businessId={this.state.businessId}
                                  selectedPlanId={this.props.selectedSubscription.selectedRadioOptionsId}
                                />
                              </StripeProvider>
                            </div>
                          </div>

                        </InputCard>
                      </div>
                    ): (
                      <div>
                        
                      </div>
                    )}



                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '100' || 
                    this.props.selectedSubscription.selectedRadioOptionsId === '200' || 
                    this.props.selectedSubscription.selectedRadioOptionsId === '300' ?
                    (
                      <div>
                        <InputCard headlineText="Purchase this Subscription" headlineSubText="">

                          <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                            {
                            this.props.subscriptionProducts.length > 0 ?
                                // Arrow function preserves "this"
                              this.props.subscriptionProducts.filter(x => x.subscriptionId === this.props.selectedSubscription.selectedRadioOptionsId).map((item, i) => {
                                let monthlyPrice = '(Unavailable)';
                                try {
                                  if (Number.isInteger(item.subscriptionPrice)) {
                                    monthlyPrice = item.subscriptionPrice/100;
                                    monthlyPrice = `$${monthlyPrice}`;
                                  }
                                } catch(e) {}
                                return (
                                  <div className={classNames({ flex: true, flexGrow: true })}>
                                    <div className={classNames({ flexGrow: true })}>
                                      <TextStyle size="h3" weight="bold">
                                        {item.title} Subscription
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                        Billed Monthly (auto-renew)<br/>
                                        Price: {monthlyPrice} per month<br />
                                        Plus Customer Interaction Usage costs<br/>
                                        Plus Business Identity Verification costs<br/>
                                      </TextStyle>
                                    </div>
                                    <div className={classNames({ aaa: true })}>
                                      <TextStyle size="h4" weight="">
                                      {monthlyPrice} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      per month<br/>
                                      Plus Usage Costs
                                      </TextStyle>
                                    </div>
                                  </div>
                                )
                              })
                            :
                              <TextStyle size="h5" weight="">
                                None selected.
                              </TextStyle>
                            } 
                          </div>

                          <br />
                          <hr />

                          <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                          {
                            this.props.subscriptionProducts.length > 0 ?
                                // Arrow function preserves "this"
                              this.props.subscriptionProducts.filter(x => x.subscriptionId === this.props.selectedSubscription.selectedRadioOptionsId).map((item, i) => {
                                let monthlyPrice = '(Unavailable)';
                                let monthlyPriceGST = '(Unavaialble)';
                                try {
                                  if (Number.isInteger(item.subscriptionPrice)) {
                                    monthlyPrice = item.subscriptionPrice/100;
                                    monthlyPrice = `$${monthlyPrice}`;
                                    monthlyPriceGST = (item.subscriptionPrice/100)*0.1;
                                    monthlyPriceGST = `$${monthlyPriceGST.toFixed(2)}`;
                                  }
                                } catch(e) {}
                                return (
                                  <div className={classNames({ flex: true, flexGrow: true })}>
                                    <div className={classNames({ flexGrow: true })}>
                                      <TextStyle size="h3" weight="bold">
                                        Total
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                        Additional charges based on usage.<br/>
                                        Visit our pricing page for more details.
                                      </TextStyle>
                                    </div>
                                    <div className={classNames({ aaa: true })}>
                                      <TextStyle size="h4" weight="">
                                      {monthlyPrice} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      included GST {monthlyPriceGST} AUD
                                      </TextStyle>
                                      <TextStyle size="h5" weight="">
                                      per month<br/>
                                      Plus Usage Costs
                                      </TextStyle>
                                    </div>
                                  </div>
                                )
                              })
                            :
                              <TextStyle size="h5" weight="">
                                None selected.
                              </TextStyle>
                            } 
                          </div>


                          <br />
                          <div className={classNames({ borderTLight: true, marLRMinus10: true })} />
                          <br />

                          <div className={classNames({ aaa: true })}>
                            <div className={classNames({ aaa: true })}>
                              <StripeProvider stripe={this.state.stripe} apiKey="pk_live_gNF5a9HLoRktC23LgCWPWPwd">
                                <BusinessRegistrationCheckoutV2
                                  businessId={this.state.businessId}
                                  selectedPlanId={this.props.selectedSubscription.selectedRadioOptionsId}
                                />
                              </StripeProvider>
                            </div>
                          </div>

                        </InputCard>
                      </div>
                    ): (
                      <div>
                        Please select a subscription above to purchase, upgrade or downgrade.
                      </div>
                    )}










                    { this.props.selectedSubscription.radioOptionsGroup === 'subscriptionSelection' &&
                    this.props.selectedSubscription.selectedRadioOptionsId === '999' ?
                    (
                      <div>
                        <InputCard headlineText="Trial Subscription" headlineSubText="Trial Subscriptions are required to be approved by Guard Point and can be cancelled at anytime">

                          <TextStyle size="h3" weight="bold">
                            Trial Subscription
                          </TextStyle>
                          <br/>
                          <TextStyle size="h5" weight="">
                            The Trial Subscription is intended to help businesses start to utilise the 
                            Guard Point services to help protect their customers. The trial can be cancelled
                            at any time by Guard Point for any reason without notification. <br/>
                            The business can transition to a paid subscription and continue using the service
                            unless there has been a breach of the service agreement. 
                          </TextStyle>

                          <br />
                          <hr />
                          To request trial access, please provide our team your details for an assessment.
                          <br/><br/>

                          <InputCardItem headlineText="Business Name" 
                          headlineSubText="" additionalText="">
                            <InputTextV3
                              id="" 
                              name="businessName" 
                              identifiers={[]}
                              stateIdentifier=""
                              fieldName=''
                              fieldRef={[]}
                              fieldStore=""
                              storeId=''
                              label='' 
                              value={this.state.businessName}
                              onChangeInputTextHandler={this.handleTrialInputChange} 
                              
                            />
                          </InputCardItem>

                          <InputCardItem headlineText="Business Website" 
                          headlineSubText="" additionalText="">
                            <InputTextV3
                              id="" 
                              name="businessWebsite" 
                              identifiers={[]}
                              stateIdentifier=""
                              fieldName=''
                              fieldRef={[]}
                              fieldStore=""
                              storeId=''
                              label='' 
                              value={this.state.businessWebsite}
                              onChangeInputTextHandler={this.handleTrialInputChange} 
                              
                            />
                          </InputCardItem>

                          <InputCardItem headlineText="Contact Name" 
                          headlineSubText="" additionalText="">
                            <InputTextV3
                              id="" 
                              name="contactName" 
                              identifiers={[]}
                              stateIdentifier=""
                              fieldName=''
                              fieldRef={[]}
                              fieldStore=""
                              storeId=''
                              label='' 
                              value={this.state.contactName}
                              onChangeInputTextHandler={this.handleTrialInputChange} 
                              
                            />
                          </InputCardItem>

                          <InputCardItem headlineText="Contact Email" 
                          headlineSubText="" additionalText="">
                            <InputTextV3
                              id="" 
                              name="contactEmail" 
                              identifiers={[]}
                              stateIdentifier=""
                              fieldName=''
                              fieldRef={[]}
                              fieldStore=""
                              storeId=''
                              label='' 
                              value={this.state.contactEmail}
                              onChangeInputTextHandler={this.handleTrialInputChange} 
                              
                            />
                          </InputCardItem>

                          <br/>
                          <StandardApiBtn
                          apiActivityId="RequestBusinessTrialAccount"
                          icon=""
                          iconLocation=""
                          text="Request Business Trial Account"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.requestBusinessTrialAccount}
                          />

                          

                        </InputCard>
                      </div>
                    ): (
                      <div>
                        
                      </div>
                    )}
                  </div>
                )}

              </div>
            )}

            

            
            

          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  let selectedSubscription = null;
  try {
    selectedSubscription = state.UI.uiRadioOptions.find(radioOption => radioOption.businessId === businessId && radioOption.radioOptionsGroup === 'subscriptionSelection');
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the selected subscription');
  }
  // console.log("mapStateToProps | businessIdentity");
  //console.log('selectedSubscription');
  //console.log(selectedSubscription);

  let activeSubscriptions = [];
  try {
    activeSubscriptions = businessIdentity.subscriptions;
  } catch (e) {
    console.error('mapStateToProps | No active subscriptions');
  }

  let subscriptionProducts = [];
  try {
    subscriptionProducts = state.Product.subscriptionProducts;
  } catch (e) {
    //console.log('mapStateToProps | Unable to find Product/subscriptionProducts');
  }

  //Join the product details to the active subscriptions
  try {
    for (let x=0; x < activeSubscriptions.length; x++) {
      for (let y=0; y < subscriptionProducts.length; y++) {
        if (activeSubscriptions[x].subscriptionId === subscriptionProducts[y].subscriptionId) {
          activeSubscriptions[x].subscriptionProductDetail = subscriptionProducts[y];
        }
  
      }
    }
  } catch(e) {}

  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }
 

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    selectedSubscription: Object.assign({}, selectedSubscription),
    activeSubscriptions: Object.assign([], activeSubscriptions),
    user: state.User,
    subscriptionProducts: Object.assign([], subscriptionProducts),
  };

};

const mapDispatchToProps = {
  requestBusinessTrialAccount,
  syncProducts,
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
/*
pk_test_ypX7KO3HyKJTBtJ4SoD6eXwb
*/
