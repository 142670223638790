import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { IdentityInputV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessUserPermissionsView } from 'Components/ShcModulesIdentity';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';


// Actions
import { removeBusinessUserPermission, toggleOverlayOnScreenResponsive, reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class BusinessUserProfile extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
   
    this.AddBusinessPermissionToUser = this.AddBusinessPermissionToUser.bind(this);
    this.removeBusinessUserPermission = this.removeBusinessUserPermission.bind(this);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,


      businessId: this.props.businessId,
      businessIdentities: [],


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeItem(userId, evt) {
    //REMOVE THE BUSINESS USER
    //console.log("removeItem");
    //console.log(userId);
    //console.log("evt");
    //console.log(evt);
    const { businessId } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let apiUi = {
      apiActivityId
    }

    let data = {
      businessId,
      userId,
    }
    //console.log("data for removing user");
    //console.log(data);
    console.warn(`ACTION | onRemoveItem`);
    this.props.onRemoveItem({data, apiUi});
  }

  AddBusinessPermissionToUser() {
    //console.log("AddBusinessPermissionToUser | Add Business Permission to User");
    /*
    //View identity list options
    //Select an identity to add
    //UI update to show the new identity added.


    */
   /*
    let tableData=[];
    let tableItem;

    tableItem = {
      type: "Email",
      identity: "selbs01@hotmail.com",
    };


    tableData.push(tableItem);

    */
    const data = {
      //tableData: tableData,
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectBusinessUserPermissionsToAdd', data);

  }
  removeBusinessUserPermission(body = {}) {
    //console.log("removeBusinessUserPermission | remove the permission from this user");
    const { apiUi, item } = body;
    const permissionName = item.permissionName;

    const businessId = this.state.businessId;
    const userId = this.props.data._id;


    const business = { 
      businessId,
      userId,
      permissionName,
    };

    this.props.removeBusinessUserPermission({business, apiUi});

  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }

  AddBusinessPermissionToUserFromTable(rowDetails, evt) {
    //console.log("AddBusinessPermissionToUserFromTable");
    //console.log(rowDetails);

    this.props.toggleOverlayOnScreenResponsive('close', "selectBusinessUserPermissionsToAdd");

    let selectedRecord = rowDetails.original;

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    //console.log('businessId');
    //console.log(this.state.businessId);    

    //console.log('UserId?');
    //console.log(this.props.data._id);

    //console.log('permissionID');
    //console.log(selectedRecord.permission);            


    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessId,
      userId: this.props.data._id,
      permissionName: selectedRecord.permission
     };

    this.props.addBusinessUserPermission({business, apiUi});
  }

  closeOverlayOnScreen = (overlayId) => {
    ////console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  render() {
    const { businessId, businessIdentities, stateIdentifier, identifiers, id, name, data, onChangeHandler,
      onRemoveItem,
    } = this.props;

    let businessUserPermissions = data.userPermissions;

    let userId = "";
    try { userId = data._id; } catch(e) {}

    let profileAttributes = "";
    try { profileAttributes = data.profileAttributes; } catch(e) {}   //this is all the identities linked.
    /*
    try { 

      for(let x=0; x < profileAttributes.length; x++) {
        let identityReferenceId = profileAttributes.identityReferenceId;
        //Get the details relating to this identity
        
        for(let y=0; y < this.props.businessIdentity.length; y++) {
          
  
  
        }

        



      }

    } catch(e) {

    }*/
    
 

    let headlineText = "selbs01@hotmail.com";
    let headlineUserId = userId;
    let headlineProfileIdentityTypes = "";


    const BusinessPermissionsList =() => {
      if (businessIdentities !== '') {
        let tableData = [
          {
            permission: "owner",
            desc: "Full access to this business."
          },
          {
            permission: "admin",
            desc: "Administrator access to this business."
          },
          {
            permission: "connect",
            desc: "Ability to initate interactions with customers from this business."
          },
        ];
        //console.log("BusinessPermissionsList UI Element");
        //console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Permission",
                        accessor: "permission"
                      },
                      {
                        Header: "Description",
                        accessor: "desc"
                      },
                      {
                        Header: "Button",
                        Cell: row => (
                          <div className={classNames({ textAlignRight: true })}>
                            
                            <StandardApiBtn
                              apiActivityId="AddBusinessPermissionToUser"
                              icon=""
                              iconLocation=""
                              text="Add"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.AddBusinessPermissionToUserFromTable.bind(this, row)}
                            />
                            
                          </div>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return "No data.";
        }
        return "No data.";
      }
      return null;
    }

    return (
      <div className={classNames({ ShcModulesIdentityContainer: true })}>
        <div className={classNames({ ShcModulesIdentityContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>{headlineUserId}</div>
                  <div className={classNames({ text_h3: true })}>{headlineText}</div>
                  <div className={classNames({ text_h6: true })}>{headlineProfileIdentityTypes}</div>
                    
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
                {this.state.collapse ?
                  <div><i class="fas fa-angle-down"></i></div>
                :
                <div><i class="fas fa-angle-up"></i></div>
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityStatusContainerBody: true })}>
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardApiBtn
                apiActivityId={`RemoveBusinessProfile_${userId}`}
                icon=""
                iconLocation=""
                text="Delete User"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-small shcbtn-secondary"
                handleClick={this.removeItem.bind(this, userId)}
              />
            </div>
          </div>



          <div className={classNames({ aaa: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">User</TextStyle>
              <TextStyle size="h5" weight="normal">The following user is associated with this business.</TextStyle>


              <TextStyle size="h5" weight="normal">When accepting an invitation, the user is required to select
              a verified identity to share with the business. This is to ensure that the UID provided is in fact
              related/verified against the common identity that would be known between the parties.</TextStyle>


            </div>
            <div className={classNames({ pad10: true, containerStyleLight: true })}>
              
            </div>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h5" weight="normal">You are only seeing this information as both this business and
              this user have both accepted an invitation to connect.</TextStyle>
            </div>
          </div>


          <div className={classNames({ borderTLight: true, marLRMinus10: true })}>
          </div>


          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">User Permissions</TextStyle>
              <TextStyle size="h5" weight="normal">This user has the following permissions.</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardApiBtn
                apiActivityId="AddNewBusinessIdentityToProfile"
                icon=""
                iconLocation=""
                text="Add Permission"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.AddBusinessPermissionToUser}
              />
            </div>
          </div>

          <div>
            <div>

              {
              businessUserPermissions && 
              businessUserPermissions.length > 0 ?
                // Arrow function preserves "this"
                businessUserPermissions.map((item, i) => (

                <li key={item._id} className={classNames({ listStyleNone: true })}>
                  <BusinessUserPermissionsView
                    businessId={businessId}
                    userId={userId}
                    data={businessUserPermissions[i]}
                    onRemoveItem={this.removeBusinessUserPermission}
                  />

                </li>

                ), this)
              : (
                <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                  <TextStyle size="h4" weight="normal">No permissions associated</TextStyle>
                  <TextStyle size="h5" weight="normal">This user does not have any permissions to this business.</TextStyle>
                  
                </div>
              )
              }
              

            </div>
          </div>

          

        </div>
        }






        <OverlayOnScreenResponsive
          id="selectBusinessUserPermissionsToAdd"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add Permissions"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Permissions</TextStyle>
              <TextStyle size="h5">Select a permission to add to this user.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>

              <BusinessPermissionsList/>

            </div>

     
            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectBusinessUserPermissionsToAdd')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>

    );
  }
}

BusinessUserProfile.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
  };

};


const mapDispatchToProps = {
  removeBusinessUserPermission,

  toggleOverlayOnScreenResponsive,

  reduxDataUpdate,
  apiDataUpdate
};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessUserProfile);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(BusinessUserProfile);