import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// CSS
import componentStyles from './styles.css';

// Components
import { InputCard, InputCardItem  } from 'Components/ShcCards';

// Actions
import { resetAppData, } from 'Actions';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      loginLoading: false,
    };
    
    //this.props.resetAppData();
    
  }

  componentDidMount(props) {
    this.props.resetAppData();
  }

  componentDidUpdate() {
    
  }

  componentWillUnmount() {
  }


  render() {

   

    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>

            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Client Reset" headlineSubText="">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Client Reset Completed
                  </div>
                </div>
              </InputCard>
            </div>
         

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  resetAppData,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
