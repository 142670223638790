import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
import commonStyles from '../_CommonStyles/commonStyles.css';
// Components

// Actions


class TableListFacing extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { data } = this.props;

    // dataObj - [{text1: text2}, {text3: text4}]


    const TableListRow = (title, content) => (
      <div className={classNames({ TableListFacing_divTableRow: true })}>
        <div className={classNames({ TableListFacing_divTableCell: true, TableListFacing_titleText: true })}>{title}</div>
        <div className={classNames({ TableListFacing_divTableCell: true, TableListFacing_contentText: true })}>{content}</div>
      </div>
      /*
      <div className={classNames({ flex: true })}>
        <div className={classNames({ TableListFacing_titleText: true })}>
          {title}
        </div>
        <div className={classNames({ TableListFacing_contentText: true })}>
          {content}
        </div>
      </div>
      */
    );

    const TableList = () => (
      <div>
        <div className={classNames({ TableListFacing_divTable: true, TableListFacing_container: true })}>
          <div className={classNames({ TableListFacing_divTableBody: true })}>

            {data.map(obj => (
              TableListRow(obj.title, obj.content)
            ))}

          </div>
        </div>
      </div>
    );


    return (
      <div>
        <TableList />
      </div>
    );
  }
}

TableListFacing.propTypes = {

};
TableListFacing.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TableListFacing);
