import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI
import CodeIcon from '@material-ui/icons/Code';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class LinkGroupListItem extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: true,
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }


  render() {
    // State
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const userDataPropertyListNav = this.props.userDataPropertyListNav;

    // Props
    const hasChildren = this.props.hasChildren;
    const navDropDownItems = this.props.navDropDownItems;
    const navDropDownUserState = this.props.navDropDownUserState;
    const linkHighlight = this.props.linkHighlight;

    const navPosition = this.props.navPosition;


    let navDropDownItemsUserData;

    // Load values from state if it matches.
    // Required to pass state in via props.
    if (navDropDownUserState) {
      if (navDropDownUserState.includes('userDataPropertyListNav')) {
        navDropDownItemsUserData = userDataPropertyListNav;
      }
    }
    return (
      <div>
        {hasChildren ? (
          <div>


            <div button onClick={this.toggle} className={classNames({ linkGroup_listItem: true, linkGroup_listItemParent: true })} data-navPosition={navPosition}>

              <div className={classNames({ linkGroup_extendedSideBarContentCollapse: true })}>
                <div className={classNames({ linkGroup_extendedSideBarTitle: true })}>
                  {this.props.navTitleExpanded}
                </div>
                <div className={classNames({ linkGroup_listItemExpandArrow: true })}>
                  {this.state.collapse ? <ExpandMore /> : <ExpandLess />}
                </div>
              </div>
            </div>


            <div className={classNames({ hide: this.state.collapse, linkGroup_dividerLineCollapse: true })} isOpen={this.state.collapse}>

              {navDropDownItems ? (
                navDropDownItems.map(child => (
                  <Link to={child.link} className={classNames({textDecoration: true, linkGroup_navigationLinks: true })}>
                    <div button className={classNames({ linkGroup_listItem: true, linkGroup_listItemChild: true })} data-navPosition={navPosition}>
                      <div onClick={this.toggle} className={classNames({ linkGroup_narrowSideBarContent: true })}>
                        {child.displayicon ?
                          <i className={child.displayicon} />
                           :
                          <CodeIcon />
                        }
                        <br />
                        <div className={classNames({ linkGroup_navLinkSecondaryTextChildren: true, hide: this.props.sidebarOpen })}>{child.navTitleCollapsed}</div>
                      </div>
                      <div className={classNames({ linkGroup_extendedSideBarTitle: true, linkGroup_navigationLinksChild: true })}>{child.navTitleExpanded}</div>
                    </div>
                  </Link>

                ))
              ) : (
                <div />
              )
              }


              {navDropDownItemsUserData ? (
                navDropDownItemsUserData.map(child => (
                  <Link to={child.link} className={classNames({textDecoration: true, linkGroup_navigationLinks: true })}>
                    <div button className={classNames({ linkGroup_listItem: true, linkGroup_listItemChild: true })} data-navPosition={navPosition}>
                      <div onClick={this.toggle} className={classNames({ linkGroup_narrowSideBarContent: true })}>
                        {child.displayicon ?
                          <i className={child.displayicon} />
                           :
                          <CodeIcon />
                        }
                        <br />
                        <div className={classNames({ linkGroup_navLinkSecondaryTextChildren: true, hide: this.props.sidebarOpen })}>{child.navTitleCollapsed}</div>
                      </div>
                      <div className={classNames({ linkGroup_extendedSideBarTitle: true, linkGroup_navigationLinksChild: true })}>{child.navTitleExpanded}</div>
                    </div>
                  </Link>

                ))
              ) : (
                <div />
              )
              }

            </div>

          </div>
        ) : (
          <div className={classNames({ linkGroup_navigationLinkHighlight: linkHighlight })}>
            <Link to={this.props.to} className={classNames({textDecoration: true, linkGroup_navigationLinks: true, linkGroup_navigationLinkHighlight: linkHighlight })}>
              <div button className={classNames({ linkGroup_listItem: true, linkGroup_listItemParent: true })} data-navPosition={navPosition}>
                <div className={classNames({ linkGroup_narrowSideBarContent: true })}>
                  {this.props.displayicon ?
                    <i className={this.props.displayicon} />
                         :
                    <CodeIcon />
                  }
                </div>
                <div className={classNames({ linkGroup_extendedSideBarTitle: true })}>{this.props.navTitleExpanded}</div>
              </div>
            </Link>
          </div>
      )
    }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
  userDataPropertyListNav: state.User.userDataPropertyListNav,

  sidebarOpen: state.UI.sidebarOpen,
});
const mapDispatchToProps = {

};
export default compose(connect(mapStateToProps, mapDispatchToProps))(LinkGroupListItem);
