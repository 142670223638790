import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectStripe, CardElement } from 'react-stripe-elements';
import classNames from 'classnames';

import AddressSection from './AddressSection';
import CheckoutPaymentInformation from './CardSection';

// Material UI
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

// CSS
import componentStyles from './AddressSection.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';

// Actions
import { createPaymentCharge } from 'Actions';





class BusinessRegistrationCheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
    };
  }
  // https://stripe.com/docs/recipes/elements-react


  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    console.log('HANDLE SUBMIT ON PAYMENT');

    this.props.stripe.createToken({ name: 'Name' })
      .then((token) => {
        console.log(token);
        this.props.createPaymentCharge(token);
      })
      .catch((err) => {
        console.log('ERROR');
        console.log(err);
      });



    // const { token } = await this.props.stripe.createToken({ name: 'Name' });
    // console.log(token);


    /*
    const response = await fetch('http://localhost:3011/payments/v1/charge', {
      method: 'POST',
      headers: { 'Content-Type': 'text/plain' },
      body: token.id,
    });
    */

    if (response.ok) console.log('Purchase Complete!');




    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({ name: 'Jenny Rosen' }).then(({ token }) => {
      console.log('Received Stripe token:', token);
    });

    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});
  }




  render() {




    const CheckoutContactInformation = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
          })}
          >
          <div className={classNames({ heading_h3: true, color_primary_dark: true })}>
              Contact information
          </div>
          <div className={classNames({ text_h6: true, color_primary_medium: true })} />
        </div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({
                  shcMaterialTextFieldMarginNarrow: true,
                  shcMaterialTextField: true,
                  })}
              error
              id="email"
              label="Email"
              margin="normal"
              fullWidth
              helperText="At least 6 characters"
              />
          </div>
        </div>
      </div>
    );

    const CheckoutSubscriptionUnderstanding = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
            })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
              If you choose to register your business you understand that to maintain an active account
              on our platform, that you will incur an administration fee to verify your business. This is
              expected to be performed twice per year to ensure your business is active and your details
              are correct.
            <br /><br />
              Proceeding to sign up and register your business you are agreeing to our terms and
              conditions.
          </div>
        </div>
      </div>
    );

    const CheckoutBillingInformation = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
          })}
          >
          <div className={classNames({ heading_h3: true, color_primary_dark: true })}>
              Billing information
          </div>
          <div className={classNames({ text_h6: true, color_primary_medium: true })}>
              &nbsp;
          </div>
        </div>

        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="businessname"
              label="Business Name"
              margin="normal"
              fullWidth
              />
          </div>
        </div>


        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="firstname"
              label="First Name"
              margin="normal"
              fullWidth
              helperText="At least 6 characters"
              />
          </div>
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="lastname"
              label="Last Name"
              margin="normal"
              fullWidth
              helperText="At least 6 characters"
              />
          </div>
        </div>

        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="addressLine1"
              label="Address"
              margin="normal"
              fullWidth
              />
          </div>
        </div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="addressLineOther"
              label="Apartment, suite, etc. (optional)"
              margin="normal"
              fullWidth
              helperText="Optional"
              />
          </div>
        </div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="city"
              label="City"
              margin="normal"
              fullWidth
              />
          </div>
        </div>

        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="country"
              label="Country"
              margin="normal"
              fullWidth
              />
          </div>
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="state"
              label="State/territory"
              margin="normal"
              fullWidth
              />
          </div>
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <TextField
              className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
              id="postcode"
              label="Postcode"
              margin="normal"
              fullWidth
              />
          </div>
        </div>

        <div className={classNames({ text_h6: true, color_primary_medium: true })}>
          <CheckoutSubscriptionUnderstanding />
        </div>
      </div>
    );


    const CheckoutPayNow = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >
            <StandardBtn
              icon=""
              iconLocation=""
              text="Pay $82.90"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
              handleClick={this.handleSubmit}
              />
          </div>
        </div>
      </div>
    );

    const CheckoutReturnToCart = () => (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          })}
        >
          <div className={classNames({
            flexMargin5: true,
            fullwidth: true,
            shcMaterialTextFieldBaselinePadding: true,
          })}
          >
            <i className="fas fa-angle-left" /> Return to cart
          </div>
        </div>
      </div>
    );


    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <CheckoutContactInformation />

          <CheckoutBillingInformation />

          <CheckoutPaymentInformation />

          <CheckoutPayNow />

          <CheckoutReturnToCart />


        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  createPaymentCharge,
};


// export default injectStripe(BusinessRegistrationCheckoutForm);
export default compose(
  connect(mapStateToProps, mapDispatchToProps), injectStripe,
)(BusinessRegistrationCheckoutForm);
