import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { mapSearchPopupPanelSuburb, mapSearchPopupPanelServices, mapSearchPopupPanelDwelling } from 'Actions'; // actions for setting what nav panel is open, and set url

class StateDrivenButton extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
    console.log(evt);
    console.log(this.props.children);
    // this.props.children.props.className.toggleClass(hidden);
    // this.props.${stateToggle}("ABC")

    // action=""
    switch (this.props.action) {
      case 'TOGGLE_MAP_SEARCH_POPUP_PANEL_SUBURB':
        return this.props.mapSearchPopupPanelSuburb();
      case 'TOGGLE_MAP_SEARCH_POPUP_PANEL_SERVICES':
        return this.props.mapSearchPopupPanelServices();
      case 'TOGGLE_MAP_SEARCH_POPUP_PANEL_DWELLING':
        return this.props.mapSearchPopupPanelDwelling();
      default:
    }
  }

  render() {
    const icon = this.props.icon;
    // console.log('icon...');
    // console.log(icon);
    const text = this.props.text;
    // console.log('text...');
    // console.log(text);
    const iconLocation = this.props.iconLocation;
    // console.log('iconLocation...');
    // console.log(iconLocation);

    let displayIcon = false;
    if (icon != null && icon != '') {
      displayIcon = true;
    }

    let iconLocationLeft = false;
    let iconLocationRight = false;
    if (iconLocation === 'left') {
      iconLocationLeft = true;
    } else if (iconLocation === 'right') {
      iconLocationRight = true;
    }

    const tooltip = this.props.tooltip;
    const buttonActive = false;

    const ButtonContent = () => {
      if (displayIcon) {
        if (!text) {
          return (
            <div>
              <span className={classNames({ stateDrivenButtonAttrHidden: !iconLocationLeft })}><i className={icon} /></span>
            </div>
          );
        }
        if (iconLocation === 'left') {
          return (
            <div>
              <span className={classNames({ stateDrivenButtonAttrHidden: !iconLocationLeft, stateDrivenButtonLeftIcon: iconLocationLeft })}><i className={icon} /></span>
              {text}
            </div>
          );
        }
        return (
          <div>
            {text}
            <span className={classNames({ stateDrivenButtonAttrHidden: !iconLocationRight, stateDrivenButtonRightIcon: iconLocationRight })}><i className={icon} /></span>
          </div>
        );
      }
      return (
        <div>
          {text}
        </div>
      );
    };


    return (
      <div className={classNames({ stateDrivenButtonWrapper: true })}>
        <button
          className={classNames({ stateDrivenButton: true, stateDrivenButtonOutline: !buttonActive, stateDrivenButtonSolid: buttonActive })}
          onClick={(evt) => {
            this.onClick(evt);
          }}
          onMouseLeave={(evt) => {
            this.onMouseLeave(evt);
          }}
          onMouseOut={(evt) => {
            this.onMouseOut(evt);
          }}
          onMouseOver={(evt) => {
            this.onMouseOver(evt);
          }}
        >
          <ButtonContent />
        </button>


      </div>
    );
  }
}

StateDrivenButton.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  mapSearchPopupPanelSuburb,
  mapSearchPopupPanelServices,
  mapSearchPopupPanelDwelling,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StateDrivenButton);


/*
<span className={classNames({ stateDrivenButtonAttrHidden: !iconLocationLeft, stateDrivenButtonLeftIcon: iconLocationLeft })}><i className={icon} /></span>
          {text}
          <span className={classNames({ stateDrivenButtonAttrHidden: !iconLocationRight, stateDrivenButtonRightIcon: iconLocationRight })}><i className={icon} /></span>
          */
