import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";
import Redirect from 'react-router-dom/Redirect';



// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { AuthenticatorAppV1, AuthLoginOptionsCard, AuthLoginSelectionCard } from 'Components/ShcModulesAuthOptions';
import { StandardApiBtn, StandardBtn } from 'Components/ShcButtons';

// Actions
import { toggleOverlayOnScreenResponsive } from 'Actions';


// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.handleAddLoginFactor = this.handleAddLoginFactor.bind(this);
    this.handleCancelAddLoginFactor = this.handleCancelAddLoginFactor.bind(this);
    this.onSelectLoginFactor = this.onSelectLoginFactor.bind(this);

    this.handleRedirectToUserIdentities = this.handleRedirectToUserIdentities.bind(this);

    this.state = {
      user: this.props.user,    //The main user object

      redirectToUserIdentities: false,

      screen: 'loginFactorList',

      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountUserSettingsIdentitiesPage");
    //console.log(nextProps);
    //console.log(prevState);
    let nextUser = prevState.user;
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      nextUser = nextProps.user;
    } 
 
    return { 
      user: nextUser, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    
  }




  async componentDidMount(props) {



    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  
  async handleAddLoginFactor() {
    console.log('handleAddLoginFactor');

    this.setState({
      screen: 'selectLoginFactor',
    });
  }
  async handleCancelAddLoginFactor() {
    console.log('handleCancelAddLoginFactor');

    this.setState({
      screen: 'loginFactorList',
    });
  }
  async onSelectLoginFactor() {
    console.log('onSelectLoginFactor');

    this.setState({
      screen: 'loginFactorList',
    });
  }
  
  handleRedirectToUserIdentities() {
    //Redirect 
    this.setState({
      redirectToUserIdentities: true,
    })
  }
  

  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true" 
        backTo="/account/dashboard" 
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true" 
                backTo="/account/dashboard" 
                backTitle="Dashboard"
                smallTitleTF="true" 
                smallTitle="User"
                headlineTitleTF="true" 
                headlineTitle=""
                sublineTextTF="true" 
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true" 
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
  
            <div>
              {this.state.redirectToUserIdentities === true && (
                <Redirect to={{
                  pathname: `/account/identities`,
                }}/>
              )}
 
              <div className={classNames({ flex: true, maxWidth800: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Login Options</TextStyle>
                </div>
                <div>
 
                  {this.state.screen === 'selectLoginFactor' ? (
                    <StandardBtn
                    icon="fas fa-angle-up"
                    iconLocation="right"
                    text="Close"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleCancelAddLoginFactor}
                    />
                  ):(
                    <StandardBtn
                    icon="fas fa-angle-down"
                    iconLocation="right"
                    text="Add Login Factor"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleAddLoginFactor}
                    />
                  )}

                </div>

              </div>


              {this.state.screen === 'selectLoginFactor' && (
                <div className={classNames({ maxWidth800: true })}>

                  <CardBasicV2>
                    
                    Increase your Guard Point account security by selecting additional 
                    login factors. This will increase the difficulty for an attacker to 
                    access your account. You can add multiple factors where you will 
                    receive one-time security-codes for each factor during the login process. 
                  </CardBasicV2>


                  <div className={classNames({ flexGrow: true, padT20: true, padB10: true, })}>
                    <div className={classNames({ text_s20: true })}>Software Factors</div>
                    <div className={classNames({ text_s14: true })}>
                      Examples include Authy, Google Authenticator, LastPass Authenticator, Microsoft Authenticator   
                    </div>
                  </div>

                  <div>

                    {this.props.authenticatorFactorSetup === true && this.props.authenticatorFactorReinstate === false ? (
                      <div className={classNames({ authenticatorFactorSetupAlready: true })}>
                        Great! You already have a software factor setup.
                      </div>
                    ):(
                        <AuthLoginSelectionCard
                        key={['AuthLoginSelectionCard', 'authenticator'].join('_')}
                        identityId=""
                        type="authenticator"
                        displayText="Authenticator"
                        additionalText="You will need to install an Authenticator app."
                        learnMoreLink=""    //This will have educations and links to the tools to use.
                        removable={true}
                        onSelectLoginFactor={this.onSelectLoginFactor}
                        authenticatorFactorSetup={this.props.authenticatorFactorSetup}
                        authenticatorFactorReInstateOption={this.props.authenticatorFactorReinstate}
                        />
                      
                    )}
                    

                  </div>
                  

                  <div className={classNames({ flexGrow: true, padT20: true, padB10: true, })}>
                    <div className={classNames({ text_s20: true })}>Identity Factors</div>
                    <div className={classNames({ text_s14: true })}>
                      {this.props.authFactorIdentities.length > 0 ? (
                        <div>Select additional login challenges to ensure your account remains secure.</div>
                      ):(
                        <div>To setup and select an identity factor, create and verify your identities first.</div>
                      )}
                    </div>
                  </div>
                  <div>
                    {
                    this.props.authFactorIdentities.length > 0 ?
                    // Arrow function preserves "this"
                    this.props.authFactorIdentities.map((item, i) => {
                      if (this.props.authFactorIdentities[i].verificationStatus !== true) {
                        return null;
                      }
                      if (this.props.authFactorIdentities[i].identityLocked !== true) {
                        return null;
                      }

                      let authenticatorFactorReInstateOption = false;
                      let authenticatorFactorSetup = false;
                      for (let x = 0; x < this.props.authFactors.length; x++) {
                        if (this.props.authFactors[x].identityId === this.props.authFactorIdentities[i]._id) {
                          authenticatorFactorSetup = true;
                          if (this.props.authFactors[x].status === 'removed') {
                            authenticatorFactorReInstateOption = true;
                          }
                        }
                      }

                      return (
                        <li key={item._id} className={classNames({ listNone: true })}>
                          
                          <AuthLoginSelectionCard
                          key={['AuthLoginSelectionCard', item._id].join('_')}
                          identityId={this.props.authFactorIdentities[i]._id}
                          type={this.props.authFactorIdentities[i].type}
                          displayText={this.props.identities[i].identity}
                          additionalText=""
                          removable={true}
                          onSelectLoginFactor={this.onSelectLoginFactor}
                          authenticatorFactorSetup={authenticatorFactorSetup}
                          authenticatorFactorReInstateOption={authenticatorFactorReInstateOption}
                          />

                        </li>
                      )
                    }):(
                      <CardBasicV2>
                        To setup additional login factors you will need to configure your 
                        identities. 
                        <br/><br/>
                        <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Configure Identities"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-fullwidth"
                          handleClick={this.handleRedirectToUserIdentities}
                        /> 
                      </CardBasicV2>
                    )}
                    
                    

                  </div>

 
                </div>
              )}



              {this.state.screen === 'loginFactorList' && (
                <div className={classNames({ maxWidth800: true })}>
                  
                  
                  <CardBasicV2>
                  Guard Point allows you to extend the security of your account by
                  allowing you to select additional login requirements. This means that when
                  you log into your account, you will be requested to enter in additional
                  one-time security codes from each of the below identities.  
                  </CardBasicV2>
                

                  {/* 
                  <AuthLoginOptionsCard
                  type="usernamePassword"
                  displayText="Username & Password"
                  displayIcon={<i class="far fa-keyboard"></i>}
                  additionalText="You will enter your username and password"
                  factorType="Knowledge Factor"
                  removable={false}
                  configurable={false}
                  status="active"
                  />
                  */}

                  <div>
                    {
                    this.props.authFactors.length > 0 ?
                    // Arrow function preserves "this"
                    this.props.authFactors.map((item, i) => {
                      console.log("item");
                      console.log(item);
                      let identity = '';
                      try {
                        identity = item.identity;
                      } catch (e) {
                        identity = '';
                      }
                      if (identity === undefined) {
                        identity = '';
                      }

                      let displayText = '';
                      let displayIcon = '';
                      let additionalText = '';
                      let removable = true;
                      if (item.type === "email") {
                        if (item.factorType === "knowledge") {
                          displayText = <div>Username & Password</div>;
                          displayIcon = <div><i class="far fa-keyboard"></i></div>;
                          additionalText = "You will enter your username and password";
                          removable = false;
                        } else {
                          displayText = <div>{identity}</div>;
                          displayIcon = <div><i class="far fa-envelope"></i></div>;
                        }
                      }
                      if (item.type === "mobile") {
                        displayText = <div>{identity}</div>;
                        displayIcon = <div><i class="fas fa-mobile-alt"></i></div>;
                      }
                      if (item.type === "phone") {
                        displayText = <div>{identity}</div>;
                        displayIcon = <div><i class="fas fa-phone-alt"></i></div>;
                      }
                      if (item.type === "authenticator") {
                        displayText = <div>Authenticator App</div>;
                        displayIcon = <div><i class="fas fa-lock"></i></div>;
                      }
                      
                      let status = 'active';
                      try {
                        status = item.status;
                      } catch (e) {
                        status = 'active';
                      }
                      if (status === undefined) {
                        status = 'active';
                      }

                      if (status === "removed") {
                        return null;
                      }
 
                      return (
                        <li key={item._id} className={classNames({ listNone: true })}>
                          
                          <AuthLoginOptionsCard
                          type={item.type}
                          displayText={displayText}
                          displayIcon={displayIcon}
                          identityId={item.identityId}
                          additionalText={additionalText}
                          factorType={item.factorType}
                          removable={removable}
                          configurable={false}
                          status={status}
                          />

                        </li>
                      )
                    }): (null)
                    }
                    

                  </div>

                </div>
              )}
              

            </div>
          </div>
        </SidebarSubNav>

        
       
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let identities = [];
  try {
    identities = state.User.userIdentities;
  } catch (e) {
    identities = [];
  }

  let userNotifications = [];
  try {
    userNotifications = state.User.userNotifications;
  } catch (e) {
    userNotifications = [];
  }
  
  let authFactors = [];
  try {
    authFactors = state.User.authFactors;
  } catch (e) {
    authFactors = [];
  }
  let authenticatorFactorSetup = false;
  let authenticatorFactorReinstate = false;
  try {
    for (let x = 0; x < authFactors.length; x++) {
      for (let y = 0; y < identities.length; y++) {
        console.log(`${authFactors[x]._id.toString()} === ${identities[y]._id.toString()}`);
        if (authFactors[x].identityId.toString() === identities[y]._id.toString()) {
          authFactors[x].identity = identities[y].identity;
        }
      }
      if (authFactors[x].type === 'authenticator' && authFactors[x].status !== 'removed') {
        authenticatorFactorSetup = true;
      } else if (authFactors[x].type === 'authenticator' && authFactors[x].status === 'removed') {
        authenticatorFactorSetup = true;
        authenticatorFactorReinstate = true;
      }
    }
  } catch (e) {}

  //Remove identities that are not verified
  let authFactorIdentities = identities;
  try {
    for (let x = authFactorIdentities.length - 1; x >= 0; x--) {
      if (authFactorIdentities[x].verificationStatus === false) {
        authFactorIdentities.splice(x, 1);
      }
    }
  } catch (e) {}

  
  return {
    authFactors: Object.assign([], authFactors),
    authenticatorFactorSetup: authenticatorFactorSetup,
    authenticatorFactorReinstate: authenticatorFactorReinstate,
    authFactorIdentities: Object.assign([], authFactorIdentities),
    identities: Object.assign([], identities),
    userNotifications: Object.assign([], userNotifications),
    user: state.User,
    userId: state.User.id,
  };

};



const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,
};
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);

