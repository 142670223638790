import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { CheckboxOptionCardV2 } from 'Components/ShcInput';

import { CreateLinkedUserAccountV1 } from 'Components/ShcModulesAuth';



// Actions
import { toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow, businessIdentitiesVerifyNow, userIdentitiesCodeVerifyNow, businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateWebsiteCode, businessIdentitiesGenerateWebsiteCode,
  userIdentitiesGenerateToken,
  reduxDataUpdate, apiDataUpdate, //API Updates
} from 'Actions';


// Functions
import {
  validateEmail, validateDomain, validateNumbersOnly,
  validateAlphaNoSpacesOnly, validateAlphaWithSpacesOnly,
  validateTextOnly, validateAlphaNumericNoSpacesOnly, validatePhoneNumbersOnly,
  apiDataTransferTimer,
} from 'Helpers/Functions';

const uuidv1 = require('uuid/v1');

class IdentityInputV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); // new dynamic

    

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);
    this.handleIdentityVerificationWebsite = this.handleIdentityVerificationWebsite.bind(this);

    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);

    this.handleUserDataRefresh = this.handleUserDataRefresh.bind(this);
    this.handleIdentityVerificationWebsiteCodeRequest = this.handleIdentityVerificationWebsiteCodeRequest.bind(this);

    


    this.collapseContainer = this.collapseContainer.bind(this);

    this.state = {

      apiTimerCollection: [], // List of active timers.

      identity: this.props.data,
      value: this.props.value,
      name: this.props.name,
      lastChangeTransactionId: '',
      syncTimestamp: '',
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      dataSyncReference: this.props.dataSyncReference,

      
      identityType: this.props.dataType,
      verificationCode: '',
      verificationCodeInputErrors: false,

      identityVerified: false,
      identityNotVerified: true,
      identityReVerify: false,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: false,

      identityLabel: '',    //Alternative name/ label for this identity
      identityLabelInputErrors: false,


      adjustIdentity: false,         //If the user changes an identity that was already verified, asked them if they really want to...

      verifiedStateValue: '',       //If the value is verified, keep a copy here. If the user changes, then changes back, don't have to re-verify - as long as we didn't update the DB value!
          //maybe have a confirmation that you are changing the identity - re-verification is required - proceed or cancel?


      verified: false,
      collapse: true,     //start collapsed

      data: this.props.data,

      webTokenCode: '', //web token for verification of a website

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - IdentityInputV2');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let identityVerified = prevState.identityVerified;
    let identityNotVerified = prevState.identityNotVerified;
    let identityReVerify = prevState.identityReVerify;

    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.


    let updateState = false;
    if (nextProps.data !== prevState.data) {
      updateState = true;
    }

    //was NOT verified and NOW verified = collapse.
    try {
      if (prevState.identityVerified === false && nextProps.data.verificationStatus === true) {
        collapse=true;
      }
    } catch(e) {
      //no change to collapse
    }
    

    let syncStatus = prevState.syncStatus;
    let apiCompleted = '';
    try {
      apiCompleted = nextProps.dataSyncReference.apiCompleted;
    } catch(e) {
      //console.log("unable to get apiCompleted in getDerivied");
    }
    let apiTransactionId = '';
    try {
      apiTransactionId = nextProps.dataSyncReference.apiTransactionId;
    } catch(e) {
      //console.log("unable to get lastChangeTransactionId in getDerivied");
    }
    if (prevState.syncStatus === false && apiCompleted === true) {
      if (prevState.lastChangeTransactionId !== '' && 
      prevState.lastChangeTransactionId === apiTransactionId) {
        //console.log("SET THE SYNCSTATUS TO TRUE NOW!!!");
        syncStatus = true;
      } else {
        //console.log("CAREFUL | this is not actually the id we are watching for");
      }
      
    }

    //if (syncTimestamp) is greater than length of time for lambda to run, then reset with possible error on sync.



    try {
      if (nextProps.data.verificationStatus === true) {
        updateState = true;
  
        identityVerified = true;
        identityNotVerified = false;
        identityReVerify = false; //check
      } else {
        updateState = true;
        identityVerified = false;
        identityNotVerified = true;
        identityReVerify = false; //check
      }
    } catch(e) {
      //IF there is an error getting the verification status, mark it as not verified.
      updateState = true;
      identityVerified = false;
      identityNotVerified = true;
      identityReVerify = false; //check
    }
  
    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      //console.log('data');
      //console.log(nextProps.data);
      return {
        data: nextProps.data,
        identityVerified,
        identityNotVerified,
        identityReVerify,
        collapse,
        identityReference: nextProps.name,
        syncStatus,
      };
    }
    return null;
  }

  openLinkAccountPassword(allowLogin, evt) {
    //console.log("openLinkAccountPassword");
    //console.log(allowLogin);

    //console.log("if the account is not already setup, popup a prompt to set a password for firebase");

    if (allowLogin === true) {
      //console.log("OPEN POPUP LOGIN PROMPT");
      this.props.toggleOverlayOnScreenResponsive('open', 'AccountLinkSetup', '');
    }
  }




  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log('onChangeDynamicInputTextHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /** ************ LOCAL STATE DATA UPDATES ************ */

    this.props.reduxDataUpdate({ recordsForUpdate });

    /** ************ BACKEND DATA UPDATES **************** */
    // Each time there is a change, set a timer for a DB update to follow.

    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      // this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }








  handleUserDataRefresh() {
    //console.log("REFRESH USER DATA");
  }
  
  handleIdentityVerificationWebsiteCodeRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });     //userIdentitiesVerifyNow
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        businessId: this.props.businessId,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });      //businessIdentitiesVerifyNow
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }
  

  handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity,
      };
      this.props.userIdentitiesVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }

  handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identity, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity,
        verificationCode: verificationCode,
      };
      this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationWebsite(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationWebsite');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity,
      };
      this.props.userIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }
  
  

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let errors = false;
    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        if (validatePhoneNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(this.props.value);
        if (validateNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(this.props.value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);
    //console.log(typeof (identifiers));
    /* RETURN THE CALLER
    Attempt to return the caller the following data as an object.
    This will be used to push into an array for a state update.
      {
        name: 'test5.nested2.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
      }
    */

    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        //console.log(value);
        if (validatePhoneNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(value);
        if (validateNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }

    const lastChangeTransactionId = uuidv1();

    // OTHER FEATURES
    let textStyle;
    let featurePhoneData = {};
    if (this.props.feature === 'AsYouType') {
      textStyle = new AsYouType().input(event.target.value);
      const phoneNumber = parsePhoneNumberFromString(event.target.value);
      if (phoneNumber) {
        if (!phoneNumber.isValid()) {
          errors = true;
        } else {
          featurePhoneData = {
            country: phoneNumber.country,
            number: phoneNumber.number,
            type: phoneNumber.getType(),
            valid: phoneNumber.isValid(),
            formatInternational: phoneNumber.formatInternational(),
          };
        }
      }
      this.setState({
        value: textStyle,
        lastChangeTransactionId,
        identityInputErrors: errors,
        syncStatus: false,
      });
    } else {
      this.setState({
        value: event.target.value,
        lastChangeTransactionId,
        identityInputErrors: errors,
        syncStatus: false,
      });
    }

    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value,
      lastChangeTransactionId,
    };



    //console.log('=== onInputChange Return ===');
    //console.log(returnObj);


    this.props.onChangeInputTextHandler(returnObj);
    /*
    let textStyle;
    if (this.props.feature === 'AsYouType') {
      textStyle = new AsYouType().input(event.target.value);
      this.setState({
        value: textStyle,
        identityInputErrors: errors,
      });
    } else {
      this.setState({
        value: event.target.value,
        identityInputErrors: errors,
      });
    }
    */

    /*
    //console.log("Regular Expression Matching Validatior");
    //console.log(this.state.pattern);
    //console.log(this.state.maxlength);
    //console.log(this.props.maxlength);
    let re = new RegExp(this.state.pattern, 'gi');
    let found = event.target.value.match(re);
    //console.log(found);
    //console.log("end regular expression matching");
    */
  }


  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log('=== onChangeInputTextHandler API Call ===');
      //console.log(returnObj);
  
      this.props.onChangeInputTextHandler(returnObj);

    }); //End setState Callback

  }
  

  


  render() {
    const {
      data,
      id, apiEndpoint, businessId, name, label, identifiers, stateIdentifier, challenge,
      value, onChangeInputTextHandler, maxlength,
      dataType,
      pattern,
      dataErrorMessage,

      feature = '',


    } = this.props;

    let identityLocked = false;
    try {
      identityLocked = data.identityLocked;
    } catch(e) {}

    const StatusHeadline = <div><i className={`fas fa-heartbeat ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Status</div>;

    const AllowLoginHeadline = <div><i className={`fas fa-shield-alt ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Allow Login</div>;

    const SecurityHeadline = <div><i className={`fas fa-shield-alt ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Security</div>;

    const NotificationOnHeadline = <div><i className={`fas fa-bell ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Notification</div>;

    const NotificationOffHeadline = <div><i className={`far fa-bell-slash ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Notification</div>;

    const VerificationHeadline = <div><i className={`fas fa-theater-masks ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Verification</div>;

    const LabelHeadline = <div><i className={`fas fa-tag ${classNames({ShcIdentityInput_ContentIcon: true, padR10: true})}`}></i> Label</div>;



    return (
      <div>
        <div className={classNames({ ShcIdentityInput_inputSection: true, ShcIdentityInput_expandedContent: !this.state.collapse })}>
          <div>
            <div className={classNames({
              flex: true,
              flexJustifySpaceBetween: true,
              flexAlignBaseline: true,
              ShcIdentityInput_textInput_root: true,
            })}
            >
              <div className={classNames({
                ShcIdentityInput_textInput_child: true,
                ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
                ShcIdentityInput_textInput_notificationsEnabled_icon: 
                this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
                ShcIdentityInput_textInput_notificationsDisabled_icon: 
                this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
              })}
              >
                {this.state.identityNotificationOptionsAvailable &&
                  <div className={classNames({ 
                    ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
                  })}>
                    <div className={classNames({ 
                      ShcIdentityInput_textInput_notificationsClickOverlay: true,
                    })}
                    onClick={this.toggleIdentityNotification}>

                    </div>
                  </div>
                }
                {identityLocked === true ?
                <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                  ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                  ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
                  {this.state.value}
                </div>
                :
                <input
                  {...this.props}
                  id={id}
                  label={label}
                  margin="normal"
                  fullWidth
                  name={name}
                  value={this.state.value}
                  identifiers={identifiers}
                  stateIdentifier={stateIdentifier}
                  onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
                  type="text"
                  className={classNames({ ShcIdentityInput_textInput: true, 
                    ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                    ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                  })}
                />
                }
                
              </div>
            </div>

          </div>

          <div className={classNames({
            ShcIdentityInput_helpText: true,
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
          })}
          >
            <div className={classNames({ flex: true })}>

              {!this.state.syncStatus && 
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                    <div>
                      <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                      aria-label="Verified"
                      ></i>
                    </div>
                  </div>
              }

  
              {this.state.identityVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityNotVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityReVerify && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }
              
              {this.state.adjustIdentity && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>CHANGE IDENTITY? </div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Change this Verified Identity"
                    ></i>
                  </div>
                  <div>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Yes, Save Changes"
                    tooltip=""
                    action=""
                    classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                    handleClick={this.collapseContainer}
                  />
                  </div>
                </div>
              }


            </div>
            <div>

            {this.state.collapse ?
              <StandardBtn
                icon="fas fa-angle-down"
                iconLocation="right"
                text="MORE"
                tooltip=""
                action=""
                classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                handleClick={this.collapseContainer}
              />
            :
              <StandardBtn
                icon="fas fa-angle-up"
                iconLocation="right"
                text="LESS"
                tooltip=""
                action=""
                classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                handleClick={this.collapseContainer}
              />
            } 

              
            </div>
          </div>

          {this.state.collapse ?
            null
          : (
            <div>
              <div className={classNames({ ShcIdentityInput_dropdownContainer: true })}>
                <div className={classNames({ aaa: true })}>

                                    
                  {!this.state.syncStatus ?
                  (
                    <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
                      <div>
                        <TextStyle size="h4" weight="bold">
                          <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                          aria-label="Verified"
                          ></i>
                          <div className={classNames({ displayInlineBlock: true, padL10: true })}>
                            Saving
                          </div>
                        </TextStyle>
                      </div>
                    </div>
                  ) : (
                  <div>


                    <InputCardItem
                      headlineText={StatusHeadline}
                      headlineSubText=""
                      additionalText=""
                      troubleshootingText=""
                    >
                      <div className={classNames({ padL15: true })}>
                        
                        {!this.state.identityInputErrors && 
                          this.state.identityVerified && 
                            <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                              <TextStyle size="h4" weight="bold">
                                <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true, padR10: true})}`}
                                aria-label="Verified"
                                ></i>
                                VERIFIED
                              </TextStyle>
          
                              {this.state.identityType === 'phone' &&
                                <div className={classNames({ padL20: true })}>
                                  <div className={classNames({ flex: true })}>
                                
                                    <StandardApiBtn
                                      apiActivityId="UserIdentityVerifyNow"
                                      icon=""
                                      iconLocation=""
                                      text="Verify Now"
                                      tooltip=""
                                      action=""
                                      classes="shcbtn shcbtn-small shcbtn-primary"
                                      handleClick={this.handleIdentityVerificationRequest}
                                    />
                                    
                                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, padL10: true, flex: true, flexAlignItemsCenter: true})}`}
                                    aria-label="Verified"
                                    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedPhone', '')}
                                    ></i>

                                  </div>
                                </div>
                              }
                              {this.state.identityType === 'email' &&
                                <div className={classNames({ padL20: true })}>
                                  <div className={classNames({ flex: true })}>
                                
                                    <StandardApiBtn
                                      apiActivityId="UserIdentityVerifyNow"
                                      icon=""
                                      iconLocation=""
                                      text="Verify Now"
                                      tooltip=""
                                      action=""
                                      classes="shcbtn shcbtn-small shcbtn-primary"
                                      handleClick={this.handleIdentityVerificationRequest}
                                    />

                                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, padL10: true, flex: true, flexAlignItemsCenter: true})}`}
                                    aria-label="Verified"
                                    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedEmail', '')}
                                    ></i>

                                  </div>
                                </div>
                              }
                              {this.state.identityType === 'web' &&
                                <div className={classNames({ padL20: true })}>
                                  <div className={classNames({ flex: true })}>
                                
                                    <StandardApiBtn
                                      apiActivityId="UserIdentityVerifyNow"
                                      icon=""
                                      iconLocation=""
                                      text="Request a Verification Now"
                                      tooltip=""
                                      action=""
                                      classes="shcbtn shcbtn-small shcbtn-primary"
                                      handleClick={this.handleIdentityVerificationRequest}
                                    />

                                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, padL10: true, flex: true, flexAlignItemsCenter: true})}`}
                                    aria-label="Verified"
                                    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedWeb', '')}
                                    ></i>

                                  </div>
                                </div>
                              }
                              {this.state.identityType === 'postal' &&
                                <div className={classNames({ padL20: true })}>
                                  <div className={classNames({ flex: true })}>
                                
                                    <StandardApiBtn
                                      apiActivityId="UserIdentityVerifyNow"
                                      icon=""
                                      iconLocation=""
                                      text="Request a Postal Verification Now"
                                      tooltip=""
                                      action=""
                                      classes="shcbtn shcbtn-small shcbtn-primary"
                                      handleClick={this.handleIdentityVerificationRequest}
                                    />

                                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, padL10: true, flex: true, flexAlignItemsCenter: true})}`}
                                    aria-label="Verified"
                                    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedPostal', '')}
                                    ></i>

                                  </div>
                                </div>
                              }
                                
                              
                            </div>
                      }
      
      
      
      
      
      
      
                      {!this.state.identityInputErrors && 
                        this.state.identityNotVerified && 
                          <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                            <TextStyle size="h4" weight="bold">
                              <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                              aria-label="Not Verified"
                              ></i>
                              NOT VERIFIED
                            </TextStyle>
                            <div className={classNames({ flex: true, padL20: true })}>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyNow"
                                icon=""
                                iconLocation=""
                                text="Verify Now"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationRequest}
                              />
        
                              <StandardBtn
                                icon=""
                                iconLocation=""
                                text="Refresh Data"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleUserDataRefresh}
                              />
        
            
                            </div>
                          </div>
                      }
                      {!this.state.identityInputErrors && 
                        this.state.identityReVerify && 
                          <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                            <TextStyle size="h4" weight="bold">
                              <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true, padR10: true})}`}
                              aria-label="Re-Verify Now"
                              ></i>
                              RE-VERIFY NOW
                            </TextStyle>
                            <TextStyle size="h5" weight="">
                              This identity is verified however is approaching the mandatory re-verification date.<br/>
                              <br/>
                            </TextStyle>
                              
                            <div className={classNames({ flex: true, padL20: true })}>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyNow"
                                apiEndpoint={apiEndpoint}
                                icon=""
                                iconLocation=""
                                text="Verify Now"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={() => this.handleAddNewUserConfirmed(this.props.overlayOnScreenData.id)}
                              />
        
        
                            </div>
                          </div>
                      }
      
                      {this.state.identityInputErrors && 
                        <div>
                          <TextStyle size="h4" weight="bold">
                            <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                            aria-label="Invalid Input"
                            ></i>
                            INVALID INPUT
                          </TextStyle>
                          <TextStyle size="h5" weight="">
                            The input you entered is incorrect or does not meet the required input criteria.
                            <br/>
                          </TextStyle>
                        </div>
                      }

                      </div>
                    </InputCardItem>

                    <hr/>
                    
                    <InputCardItem
                      headlineText={LabelHeadline}
                      headlineSubText="Add a label for this identity"
                      additionalText=""
                      troubleshootingText=""
                    >
                      <div className={classNames({ padL15: true })}>
                        <div>
                          <div className={classNames({
                            flex: true,
                            flexJustifySpaceBetween: true,
                            flexAlignBaseline: true,
                            ShcIdentityInput_textInput_root: true,
                          })}
                          >
                            <div className={classNames({
                              ShcIdentityInput_textInput_child: true,
                              ShcIdentityInput_textInput_errors_icon: this.state.identityLabelInputErrors,
                            })}
                            >
      
      
                              <input
                                {...this.props}
                                id={id}
                                label={label}
                                margin="normal"
                                fullWidth
                                name="userIdentities.$.identityLabel"
                                value={this.state.identityLabel}
                                identifiers={identifiers}
                                stateIdentifier={stateIdentifier}
                                onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
                                type="text"
                                className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.identityLabelInputErrors })}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.identityLabelInputErrors && 
                          <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                            <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                            <div>
                              <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                aria-label="Invalid Input"
                              ></i>
                            </div>
                          </div>
                        }
                      </div>
                    </InputCardItem>
                    

                    {dataType==="email" && 
                    (
                      <div>
                        
                        <hr/>
                        Only shows if the account is already verified...
                        <InputCardItem
                          headlineText={AllowLoginHeadline}
                          headlineSubText="Allow this account to be used for logging into your account."
                          additionalText=""
                          troubleshootingText=""
                        >
                          <div className={classNames({ padL15: true })}>
                            <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true, })}>
                              
                            <CheckboxOptionCardV2
                              businessId=''
                              checkboxOptionsId="userAccountAllowLogins"
                              checkboxText="Allow this identity to log into this account"
                              id="userIdentities.$.allowLogin"
                              name="userIdentities.$.allowLogin"
                              identifiers={[{'_id': data._id}]}
                              stateIdentifier="User"
                              value={data.allowLogin}
                              onChangeHandler={this.onChangeDynamicInputTextHandler}
                              onChangeHandlerAction2={this.openLinkAccountPassword.bind(this, !data.allowLogin)}
                            />
                              
                              
                              
                              <StandardBtn
                                icon=""
                                iconLocation=""
                                text="Setup (tick box)"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                //handleClick={() => this.closeOverlayOnScreen('VerifyAccount')}
                              />
                            </div>
                          </div>
                        </InputCardItem>
                      </div>
                    )
                    }

                    <hr/>

                    <InputCardItem
                      headlineText={SecurityHeadline}
                      headlineSubText="We will present the following security code for this
                      interaction. Each interaction has a unique one-time code so you can 
                      valdiate that the messages are from us."
                      additionalText=""
                      troubleshootingText=""
                    >
                      <div className={classNames({ padL15: true })}>
                        <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                          ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                          ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                          bg_colour_white: true, ShcIdentityInput_DisplayTextBox: true })}>
                          
                          
                          {dataType==="phone" && 
                            <div>
                              <TextStyle size="h3" weight="bold">{challenge}</TextStyle>
                            </div>
                          }
                          {dataType==="email" && 
                            <div>
                              <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(challenge)}}></div>
                            </div>
                          }


                        </div>

                        <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true, })}>
                          <StandardBtn
                            icon=""
                            iconLocation=""
                            text="Learn More about our Security"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-small shcbtn-primary"
                            //handleClick={() => this.closeOverlayOnScreen('VerifyAccount')}
                          />
                        </div>
                        <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true, ShcIdentityInput_LightGrey: true })}>
                          <div>
                            <TextStyle size="h5" weight="">
                              You can use our free mobile app to verify this code on the email you receive.<br/> 
                            </TextStyle>
                          </div>
                          
                        </div>
                      </div>

                    </InputCardItem>

                    <hr/>

                    <InputCardItem
                      headlineText={NotificationOnHeadline}
                      headlineSubText="When notifications are active, we will alert you
                      when a business attempts to interact with you. You can switch any of 
                      your identities to slient which will stop the alerts."
                      additionalText=""
                      troubleshootingText=""
                    >
                      <div className={classNames({ padL15: true })}>
                        <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                          ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                          ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                          bg_colour_white: true })}>
                          
                          Notifications are currently 
                          {this.state.identityNotificationEnabled && 
                            " Enabled. "
                          }
                          {!this.state.identityNotificationEnabled && 
                            " Disabled."
                          }

                        </div>
                      </div>
                    </InputCardItem>

                    {this.state.identityType === 'phone' && 
                      <div>
                        <hr/>
                        <InputCardItem
                          headlineText={VerificationHeadline}
                          headlineSubText="To confirm this is your identity, please fill in 
                          the verification code we have sent you."
                          additionalText=""
                          troubleshootingText=""
                        >
                          <div className={classNames({ padL15: true })}>
    
                            <div>
                              <div className={classNames({
                                flex: true,
                                flexJustifySpaceBetween: true,
                                flexAlignBaseline: true,
                                ShcIdentityInput_textInput_root: true,
                              })}
                              >
                                <div className={classNames({
                                  ShcIdentityInput_textInput_child: true,
                                  ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                })}
                                >
                                  <input
                                    {...this.props}
                                    id={id}
                                    label={label}
                                    margin="normal"
                                    fullWidth
                                    name={name}
                                    value={this.state.verificationCode}
                                    identifiers={identifiers}
                                    stateIdentifier={stateIdentifier}
                                    onChange={this.handleIdentityVerificationCodeInput}
                                    type="text"
                                    className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.verificationCodeInputErrors && 
                              <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                <div>
                                  <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                    aria-label="Invalid Input"
                                  ></i>
                                </div>
                              </div>
                            }
      
                            <br/>
                            <StandardApiBtn
                              apiActivityId="UserIdentityVerifyConfirmationCode"
                              icon=""
                              iconLocation=""
                              text="Confirm Identity"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.handleIdentityVerificationCodeSubmit}
                            />




                          </div>
                        </InputCardItem>
                      </div>
                    }


    
                    
    
    
                                 
    
                    
                   
    
                    
    
                    {this.state.identityType === 'web' && 
                      <div>
                        <hr/>
                        <div className={classNames({ ShcIdentityInput_dropdownSecurityBox: true })}>
                          <TextStyle size="h4" weight="bold">
                            <i className={`fas fa-theater-masks ${classNames({ShcIdentityInput_VerifyIcon: true, padR10: true})}`}
                            aria-label="Verification Input"
                            ></i>
                          VERIFICATION
                          </TextStyle>
                          <TextStyle size="h5" weight="">
                          For website verifications, you need to demonstrate that you own the website. This is done by placing a HTML meta
                          tag on your website with the specific security code we provide you.
                          <br/>
                          </TextStyle>
                          <br/>
    
                          <StandardApiBtn
                            apiActivityId="GenerateWebsiteIdentityVerificationCode"
                            icon=""
                            iconLocation=""
                            text="Generate Website HTML Tag"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-small shcbtn-primary"
                            handleClick={this.handleIdentityVerificationWebsiteCodeRequest}
                          />
    
                          <br/>
                          <TextStyle size="h5" weight="">
                            Enter the following html meta tag into the head of your website primary webpage. 
                          </TextStyle>
                          <TextStyle size="h5" weight="">
                            {`${String.fromCharCode('0x003c')}meta name="authchannel-site-verification" content="${this.state.webTokenCode}"/>`}
                            <br />
                          </TextStyle>
                          <br/>
    
                          <PreferenceUserControls title="Instructions" titleSize="medium" desc="" info="" toExpandText="Open" toCollapseText="Close">
                            <div className={classNames({ businessVerificationPreferenceControlsContainer: true })}>
                            <div>
                              You can verify your ownership of a site by adding a Meta tag to the HTML of a specified page.
                              We'll verify that the meta tag exists in the correct location. If we can't find the tag, we'll give you information
                              about the error we encountered. This tag is tied to a specific business account.<br />
                              Removing your verification Meta tag from your site can cause you to lose verification for the site.<br /><br />
    
                              INSTRUCTIONS<br />
    
                              <ol>
                                <li>
                                  <div>
                                    Generate a token to place in a Meta tag on your website.
                                    <br /><br />
    
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    Create a Meta tag within the Head element of the default file (index.html, default.html, etc) at the top
                                    level of the owner's site. A HTML file with a Meta verification token might look like this:<br />
                                    <br />
    
                                    <div className={classNames({
                                      fontWeightBold: true,
                                    })}
                                    >
                                      {`${String.fromCharCode('0x003c')}meta name="authchannel-site-verification" content="${this.state.webTokenCode}"/>`}
                                      <br />
                                    </div>
    
                                  </div>
                                </li>
                              </ol>
    
                            </div>
                          </div>
                          </PreferenceUserControls>
    
                          <br/>
                            <StandardApiBtn
                              apiActivityId="UserIdentityVerifyWebsiteCode"
                              icon=""
                              iconLocation=""
                              text="Verify my website"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.handleIdentityVerificationWebsite}
                            />
                        </div>
                      </div>
                    }

                  </div>
                  
                  )
                  }


                </div>
              </div>
            </div>
          )}

        </div>



        <OverlayOnScreenResponsive
          id="infoIdentityVerifiedPhone"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Info"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Info</TextStyle><br/>
              <TextStyle size="h4"></TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
                This identity is verified. You can re-verify your account at any time within the mandatory re-verification period.<br/>
                To maintain this identity, you are required to re-verify on a regular basis.
              </TextStyle>
              <br/>
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>
        

        <OverlayOnScreenResponsive
          id="infoIdentityVerifiedEmail"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Info"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Info</TextStyle><br/>
              <TextStyle size="h4"></TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
                This identity is verified. You can re-verify your account at any time within the mandatory re-verification period.<br/>
                To maintain this identity, you are required to re-verify on a regular basis. 
              </TextStyle>
              <br/>
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="infoIdentityVerifiedWeb"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Info"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Info</TextStyle><br/>
              <TextStyle size="h4"></TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
              This identity is verified. We will periodically re-verify this website.<br/>
                              To maintain this identity, you are required to demonstrate ownership of this domain on a regular basis. 
              </TextStyle>
              <br/>
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


        <OverlayOnScreenResponsive
          id="infoIdentityVerifiedPostal"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Info"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Info</TextStyle><br/>
              <TextStyle size="h4"></TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
              This identity is verified. You can re-verify your account at any time within the mandatory re-verification period.<br/>
                              To maintain this identity, you are required to re-verify on a regular basis. Verification of a postal address incurs additional costs.
          
              </TextStyle>
              <br/>
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>
  


        <OverlayOnScreenResponsive
          id="AccountLinkSetup"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Link User Account"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
              We will link this account allowing you to login to this portal.
              </TextStyle>

              <CreateLinkedUserAccountV1 emailId={this.state.value}/>

            </div>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

                            





    </div>
    );
  }
}

IdentityInputV2.propTypes = {

};
IdentityInputV2.defaultProps = {

};



const mapStateToProps = (state, ownProps) => {
  //console.log("ownProps");
  //console.log(ownProps);

  let syncStatus = ownProps.syncStatus;
  let dataSyncReference = '';
  const identityReference = ownProps.name;
  //console.log(`identityReference we are looking for: ${identityReference}`);
  try {
    dataSyncReference = state.Sync.dataRecordUpdates.find(data => {
      if (data.reference === identityReference && JSON.stringify(data.identifiers) === JSON.stringify(ownProps.identifiers)) {
        return data;
      }
    });
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  if (dataSyncReference !== '' && dataSyncReference !== undefined) {
    //console.log("mapStateToProps | dataSyncReference");
    //console.log(dataSyncReference);
    //console.log(dataSyncReference.apiCompleted);
    //console.log(JSON.stringify(dataSyncReference));
    if (dataSyncReference.apiCompleted === true) {
      syncStatus = true;
    }
  }
  
  //console.log(`syncStatus | ${syncStatus}`);
  return {
    syncStatus: syncStatus,
    dataSyncReference: dataSyncReference,
    //dataSyncReference: Object.assign({}, dataSyncReference),
    overlayOnScreen: state.CommonActions.overlayOnScreen,
    overlayOnScreenData: state.CommonActions.overlayOnScreenData,
  };

};

const mapDispatchToProps = {
  reduxDataUpdate,  //API Updates
  apiDataUpdate,    //API Updates

  toggleOverlayOnScreenResponsive,
  userIdentitiesVerifyNow, 
  businessIdentitiesVerifyNow, 
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateWebsiteCode,
  businessIdentitiesGenerateWebsiteCode,
  userIdentitiesGenerateToken,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityInputV2);