import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { AddressInputFormV1_Australia  } from 'Components/ShcAdminModulesWorkQueues';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class AddressInputLayoutsInternationalV1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      address: this.props.value,

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
    /* Attempt to get the address split into the following
    recipient.addressLine1 = "Address 1";
    recipient.addressLine2 = "Address 2";
    recipient.addressCity = "addressCity";
    recipient.addressState = "addressState";
    recipient.addressPostalCode = "code";
    recipient.addressCountry = "country";
    */
  
  handleChange = address => {
    //console.log("Handle change from PlacesAutoComplete")
    this.setState({ address });
    this.props.onChange(address);
  };
  
  handleSelect = address => {
    //console.log(address);
    this.setState({ address });
    this.props.onChange(address);





    //For geocoding
    /*
    geocodeByAddress(address)
    .then(results => {
      //console.log(results);
      
      getLatLng(results[0]);
    })
    .then(latLng => //console.log('Success', latLng))
    .catch(error => console.error('Error', error));
    */
  };

  
 
  render() {
    const { countryFormLayout, businessId, identityId, onInputChange } = this.props;

    //load the correct form based on the country.
    //countryFormLayout === australia

    return (
      <div>

        {countryFormLayout === 'australia' && (
          <AddressInputFormV1_Australia
          country={countryFormLayout}
          businessId={businessId}
          identityId={identityId}
          onInputChange={onInputChange}
          />
        )}
        

      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddressInputLayoutsInternationalV1);
