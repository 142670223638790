import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';


import { HashLink } from 'react-router-hash-link';

// CSS
import componentStyles from './styles.css';


class ShcFooterPrimaryContent extends React.Component {
  state = {
  };
  render() {
 
    return (
      <div className={classNames({shcFooterPrimaryContent:true})}>
        <div className={classNames({shcFooterPrimaryContent_container:true})}>


          <div className={classNames({shcGrid290: true, })}>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/home" className={classNames({textDecoration: true, colour_white:true})}>
              Home<br/>
              </Link>
              <Link to="/business" className={classNames({textDecoration: true, colour_white:true})}>
              For Businesses<br/>
              </Link>

              <HashLink to="/challenge#the-challenge" className={classNames({textDecoration: true, colour_white:true})}>
              The Challenge<br/>
              </HashLink>
              <HashLink to="/solution#the-solution" className={classNames({textDecoration: true, colour_white:true})}>
              The Solution<br/>
              </HashLink>
              <HashLink to="/solution#how-it-works" className={classNames({textDecoration: true, colour_white:true})}>
              How it works<br/>
              </HashLink>
              <br/>
            </div>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/sign-up"  className={classNames({textDecoration: true, colour_white:true})}>
              FREE Membership<br/>
              </Link>
              <Link to="/login" className={classNames({textDecoration: true, colour_white:true})}>
              Login<br/>
              </Link>
              <Link to="/support"  className={classNames({textDecoration: true, colour_white:true})}>
              Support<br/>
              </Link>
              <Link to="/feedback"  className={classNames({textDecoration: true, colour_white:true})}>
              Feedback<br/>
              </Link>
              <Link to="/contact-us"  className={classNames({textDecoration: true, colour_white:true})}>
              Contact Us<br/>
              </Link>
            </div>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/privacy" className={classNames({textDecoration: true, colour_white:true})}>
              Privacy Policy<br/>
              </Link>
              <Link to="/terms-of-use" className={classNames({textDecoration: true, colour_white:true})}>
              Terms of Use<br/>
              </Link>
              <Link to="/service-status" className={classNames({textDecoration: true, colour_white:true})}>
              Service Status<br/>
              </Link>
              <Link to="/security" className={classNames({textDecoration: true, colour_white:true})}>
              Our Security<br/>
              </Link>

            </div>
          </div>



        </div>
      </div>
    );
  }
}

ShcFooterPrimaryContent.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcFooterPrimaryContent);