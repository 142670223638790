import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class DashCard1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }


  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
      height,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
    };

    const containerHeightBaseSpacing = {
      height: this.props.height ? `calc(${this.props.height} - 50px)` : 'auto',
    };


    return (
      <div >
        <div className={classNames({ ShcdashboardContainer: true })}>
         
          <div className={classNames({ ShcdashboardContainer_section: true })}>
            <div className={classNames({ ShcdashboardContainer_sectionSplit: true })}>
              <div className={classNames({ SchdashboardContainer_headlineSubTextLeft: true })}>
                {headlineSubText}
              </div>
              <div className={classNames({ SchdashboardContainer_headlineSubTextRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>
            
            <div className={classNames({ SchdashboardContainer_headlineText: true })}>
              {headlineText}
            </div>
            <div className={classNames({ SchdashboardContainer_primaryText: true })}>
              { additionalText ? null : <br /> }
              {secondaryText}
            </div>
            <div className={classNames({ SchdashboardContainer_secondaryText: true })}>
              {additionalText}
            </div>

            <div className={classNames({ SchdashboardContainer_footer: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text={linkText}
                tooltip=""
                action=""
                classes="shcbtn shcbtn-default-primary"
              />
            </div>

            
          </div>
        </div>
      </div>

    );
  }
}

DashCard1.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

DashCard1.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DashCard1);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
