import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


import { HashLink } from 'react-router-hash-link';
// Routing
// import Routes from 'Core/Routes';

// Material UI


// CSS
import componentStyles from './styles.css';

// Components
import { ShcPublicAppBar, ShcParentSidebar } from './ShcPublicHeaderComponents';
import { FooterPrimaryLayout, FooterPrimaryLinks, FooterPrimaryContent, FooterPurposeCopyright, FooterBaseSocial } from 'Components/ShcPublicFooter';


// Actions
// import {  } from 'Actions';


class ShcPublicHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { noFooter, noFooterBranding, showHeader = true } = this.props;

    const childContactMarginStyle = {
      width: '100%',
    };


    return (
      <div className={classNames({ shcPublicHeader: true })}>
        {showHeader === true && (
          <ShcPublicAppBar title={this.props.title} />
        )}

        {showHeader === true ? (
          <div className={classNames({ 
                shcHeaderContentContainerWidth: true,
              })}>
            <main
              className={classNames({ 
                shcHeaderContentContainer: true,
                shcHeaderContentContainerWidth: true,
              })}
            >
              <div className={classNames({ minHeight90vh: true })}>
                {this.props.children}
              </div>
            </main>
            {noFooterBranding !== true ? (
              <div>
                <FooterPrimaryLayout>
                  <FooterPrimaryLinks />
                  <FooterPrimaryContent />
                  <FooterPurposeCopyright />
                  <FooterBaseSocial />
                </FooterPrimaryLayout>
              </div>
            ):(
              <div>
                <FooterPrimaryLayout>
                  <FooterNoBrandingLinks />
                  <FooterPurposeCopyright />
                  <FooterBaseSocial />
                </FooterPrimaryLayout>
              </div>
            )}
          </div>
        ):(
          <div className={classNames({shcHeaderContentContainerWidth: true,})}>
            <main className={classNames({ 
              shcHeaderContentContainerWidth: true,
            })}>
              <div className={classNames({ minHeight90vh: true })}>
                {this.props.children}
              </div>
            </main>
            {noFooterBranding !== true ? (
              <div>
                <FooterPrimaryLayout>
                  <FooterPrimaryLinks />
                  <FooterPrimaryContent />
                  <FooterPurposeCopyright />
                  <FooterBaseSocial />
                </FooterPrimaryLayout>
              </div>
            ):(
              <div>
                <FooterPrimaryLayout>
                  <FooterNoBrandingLinks />
                  <FooterPurposeCopyright />
                  <FooterBaseSocial />
                </FooterPrimaryLayout>
              </div>
            )}
          </div>
        )}
      </div>
    );


  }
}

ShcPublicHeader.propTypes = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ShcPublicHeader);


/*
return (
  <div className={classNames({ shcPublicHeader: true })}>
    <ShcPublicAppBar title={this.props.title} />
    <main
      style={childContactMarginStyle}
      className={classNames({ shcPublicHeaderContnetContainer: true })}
    >
      {this.props.children}
    </main>
  </div>
);




*/