import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardIcon } from 'Components/ShcButtons';


// Actions
import { setUiRadioOptions } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class RadioOptionItemV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      radioOptionsGroup: this.props.radioOptionsGroup,
      radioOptionsId: this.props.radioOptionsId,
      radioSelectedState: this.props.radioSelectedState,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - RadioOptionItemV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');

    //console.log('RadioOptions | businessId');
    //console.log(this.props.businessId);

    const selection = {
      businessId: this.props.businessId,
      radioOptionsGroup: this.state.radioOptionsGroup,
      radioOptionsId: this.state.radioOptionsId,
      radioSelectedState: this.props.radioSelectedState,
    };
    //console.log('Radio Selection');
    //console.log(selection);
    this.props.setUiRadioOptions(selection); // Redux management across multiple components
    this.props.onSelectHandler(selection); // Parent component (if required)
  }

  render() {
    const {
      businessId,
      headlineText,
      secondaryText,
      onSelectHandler,
    } = this.props;

    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ ShcRadioOptionItem_selectIcon: true, width30: true })}>
          {this.props.radioSelectedState ?
            <StandardIcon
              icon="fas fa-dot-circle"
              classes="colour_primary"
              handleClick={this.toggleSelectedItem}
          />
        :
            <StandardIcon
              icon="far fa-circle"
              classes=""
              handleClick={this.toggleSelectedItem}
          />
        }
        </div>
        <div className={classNames({ flexGrow: true })}>
          <div className={classNames({ flexGrow: true, ShcRadioOptionItem_headlineText: true })}>
            {headlineText}
          </div>
          <div className={classNames({ flexGrow: true, ShcRadioOptionItem_secondaryText: true })}>
            {secondaryText}
          </div>
        </div>
      </div>
    );
  }
}

RadioOptionItemV1.propTypes = {

};
RadioOptionItemV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
  let radioSelectedState = false;
  if (state.UI.uiRadioOptions) {
    for (let z = 0; z < state.UI.uiRadioOptions.length; z++) {
      if (state.UI.uiRadioOptions[z].businessId === ownProps.businessId) {
        if (state.UI.uiRadioOptions[z].radioOptionsGroup === ownProps.radioOptionsGroup) { // This Radio Group
          if (state.UI.uiRadioOptions[z].selectedRadioOptionsId === ownProps.radioOptionsId) { // this ID
            radioSelectedState = true;
            //console.log(`RADIO MATCH | ${state.UI.uiRadioOptions[z].selectedRadioOptionsId}`);
          } else {
            //console.log(`RADIO NO-MATCH | ${state.UI.uiRadioOptions[z].selectedRadioOptionsId}`);
          }
        }
      }
    }
  } else {
    radioSelectedState = false;
  }

  return {
    radioSelectedState,
    uiRadioOptions: Object.assign([], state.UI.uiRadioOptions),
  };

};

const mapDispatchToProps = {
  setUiRadioOptions,
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(RadioOptionItemV1);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(RadioOptionItemV1);
