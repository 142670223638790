import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import update from 'immutability-helper';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { MessageTip, MessageHighlight, HighlightContainer } from 'Components/ShcText';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessIdentitiesUsersRolesPermissionsView } from 'Components/ShcAccountModules';


//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { toggleOverlayOnScreenResponsive, removeBusinessProfilebusinessAdministratorsItem } from 'Actions';

function arrayPathToJsonObjUpdate(arr, val, operator) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    console.log(nextParent);
    if (i===0) {
      if (operator==="push") {
        childObj = {
          "$push": [val]
        };
      } else if (operator==="set") {
        childObj = {
          "$set": val
        };
      }
      
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

class BusinessIdentitiesUserRoles extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);
    this.handleBusinessIdentitiesAdministratorsRemoveItem = this.handleBusinessIdentitiesAdministratorsRemoveItem.bind(this);

    //this.handleAddPermissionRequest = this.handleAddPermissionRequest.bind(this);
    this.handleAddPermissionConfirmed = this.handleAddPermissionConfirmed.bind(this);
    this.handleChangePermissionsSelection = this.handleChangePermissionsSelection.bind(this);

    this.state = {
      userRole: {
        name: '',
      },
      UsersRolesPermissionsSelection: 'user',
      identities: this.props.identities, // Master (every business state)
      thisBusinessIdentity: {},
      collapse: true,
    };
  }

  

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }









  onChangeInputTextHandler = (evt) => {
    console.log("input Handler v2");
    console.log(evt.target.name);
    var arr = evt.target.name.split('.');
    /*
    Build data update statements for:
    1) MongoDB - filter & update statements
    2) Redux State - modify state statements (update helper)
    */
    //MONGODB
    let mongoFilterCondition = { _id: this.state.businessViewId };   //The id of this active document
    let mongoUpdateStatement = arrayPathToMongoDBQuery(arr, evt.target.value, "update");
    console.log(JSON.stringify(mongoUpdateStatement));

    //REDUX STATE
    var reduxUpdateStatement = arrayPathToJsonStateUpdater(arr, evt.target.value);
    //Example: {"localBusinessProfile":{"businessName":{"$set":"h"}}}
    console.log(JSON.stringify(reduxUpdateStatement));
    
    let reduxUpdateId = {id: this.state.businessViewId};
    let apiAccessToken = {id: this.props.apiAccessToken};
    /*
    this.props.updateBusinessIdentityProfile({
      apiAccessToken,
      mongoFilterCondition, mongoUpdateStatement,
      reduxUpdateId, reduxUpdateStatement
    });
    */

  }
  /*
  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    const newItem = { [evt.target.name]: evt.target.value };
    let statePath = ["businessAdministrators"];
    var stateJsonMappingObj = arrayPathToJsonObjUpdate(statePath, newItem, "set");
    console.log("Pushing to state");
    console.log(stateJsonMappingObj);
    console.log("Read state");
    console.log(this.state);
    
    const newData = update(this.state, stateJsonMappingObj);
    console.log("new Data");
    console.log(newData);
    console.log(newData.businessAdministrators);
    /* EXAMPLE: don't set state, let it go around the loop through redux
    this.setState((prevState, props) => (newData.businessAdministrators)
    ,() => {
      //this.props.setBusinessProfile(this.state.businessProfile);
      this.props.onChangeBusinessIdentityRegistryHandler(this.props.index, newData.businessAdministrators);
    });
    *//*
    this.props.onChangeBusinessIdentityAdministratorsHandler(this.state.businessAdministrators._id, this.props.index, newData.businessAdministrators);
  }
  */


  /*
  onChangeStepInputHandler = (id, sharedState, sharedStateUpdate) => {       //changing from evt to obj
    //Receive events from child inputs (InputText Component)
    console.log('parent receiving data - updating parent state');
    console.log(id);
    console.log(JSON.stringify(sharedState));
    console.log(JSON.stringify(sharedStateUpdate));

    sharedState[sharedStateUpdate.field] = sharedStateUpdate.value;

    this.setState({
      [id]: sharedState
    });

    //Pass to parent which is a state with an array with the data
    let entireState = {
      websiteStep: this.state.websiteStep,
      methodsStep: this.state.methodsStep,
      verifyStep: this.state.verifyStep,
      verificationStatus: this.state.verificationStatus,
    }
    let updateData = {
      id: id,
      field: sharedStateUpdate.field,
      value: sharedStateUpdate.value,
    }
    this.props.onChangeBusinessIdentityRegistryHandler(this.props.index, entireState, updateData);
  }
  */



  handleBusinessIdentitiesAdministratorsRemoveItem = (evt) => { 
    console.log('remove this item from state');
    console.log(this.props.index);
    this.props.onRemoveBusinessIdentityAdministratorHandler(this.state.businessAdministrators._id, this.props.index);
  }


  handleAddPermissionRequest = (userPermissionData) => {
    console.log('Add permission - request');
    const data = userPermissionData;
    this.props.toggleOverlayOnScreenResponsive('open', 'UserRolesPermissionsAddPermission', data);
  }
  handleAddPermissionConfirmed = (userId) => {
    console.log('remove permission - confirmed - action');
    console.log('remove permission - '+userId);

    const {UsersRolesPermissionsSelection} = this.state;

    console.log('sending to back end to process and add for:')
    console.log(userId+' adding permission '+UsersRolesPermissionsSelection);

  }
  handleChangePermissionsSelection = (e) => {
    this.setState({UsersRolesPermissionsSelection: e.target.value});
  }


  render() {
    const { index, id, onChangeBusinessIdentityRegistryHandler, onChangeBusinessIdentityAdministratorsHandler, value, businessDetails } = this.props;

    //const firstName = value.User.userInformation ? "" ;
    

    
    //const { firstName , lastName  } = value.User.userInformation;
    //const { primaryEmail  } = value.User.userContactInformation;
    const { userActive, userPermission, userId } = value;

    const firstName = "Selby";
    const lastName = "Steer";
    const primaryEmail = "selbs01@hotmail.com";



    console.log("businessDetails");
    console.log(businessDetails);

    let userPermissionData = {
      user: {
        firstName: firstName,
        lastName: lastName,
        primaryEmail: primaryEmail,
        userActive: userActive,
        userId: userId
      },
      business: businessDetails,
      businessUserPermissions: userPermission,
    }





    //const { data, userId, userName, userEmail } = this.props;

    const UserPermissions = () => (
      <BusinessIdentitiesUsersRolesPermissionsView userPermissionData={userPermissionData} />
    );

    return (
      <div className={classNames({ ShcAccountModulesContainer: true })}>
        <div className={classNames({ ShcAccountModulesContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>

     
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>458345807234907234823</div>
                  <div className={classNames({ text_h4: true })}>{primaryEmail}</div>
                  <div className={classNames({ text_h6: true })}>{firstName}</div>
                  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}
                >
                <i class="fas fa-angle-down"></i>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcAccountModulesContainerBody: true })}>
          <div className={classNames({ flex: true})}>

            <div className={classNames({ padT10: true, flexGrow: true })}>
              <TextStyle size="h2">User Details</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Delete User"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                //handleClick={this.handleBusinessVerificationRemoveItem}
              />
            </div>
          </div>


          <div>

            <InputCard headlineText={primaryEmail} headlineSubText="458345807234907234823">

              <InputCardItem headlineText="Name" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">{firstName} {lastName}</TextStyle>
              </InputCardItem>

              <InputCardItem headlineText="Account Status" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">{userActive}</TextStyle>
              </InputCardItem>

              <InputCardItem headlineText="Information" headlineSubText="" additionalText="">
                <MessageHighlight type="warning">
                  This is an Admin account. You can view and edit all users as well as all permissions.
                </MessageHighlight>
              </InputCardItem>


            </InputCard>


            <div>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">User Permissions</TextStyle>
                </div>
                <div>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Add Permission"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-info"
                    handleClick={this.handleAddPermissionRequest.bind(this, userPermissionData)}
                  />
                </div>
              </div>
              <CardBasicV2>
                <InputCardItem headlineText="Information" headlineSubText="" additionalText="">
                  <MessageHighlight type="warning">
                    Permissions may take some time to update.
                  </MessageHighlight>
                </InputCardItem>

                <InputCardItem headlineText="Information" headlineSubText="" additionalText="">
                  <UserPermissions/>
                </InputCardItem>
              </CardBasicV2>
            </div>

          </div>
        </div>
        }
        
      </div>
    );
  }
}

BusinessIdentitiesUserRoles.propTypes = {
  
};

const mapStateToProps = state => ({
  identities: state.BusinessIdentities.identities,
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,
});

const mapDispatchToProps = {
  removeBusinessProfilebusinessAdministratorsItem,
  toggleOverlayOnScreenResponsive
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessIdentitiesUserRoles);
