import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";


// CSS
import componentStyles from './styles.css';

// Components
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';
import { IdentityManagementPanelV2 } from 'Components/ShcModulesIdentityManagement';


import { VerifiedIdentityInput_business } from 'Components/ShcModulesIdentity';



// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { 
  addBusinessIdentity,
  removeBusinessIdentity,
  changeBusinessIdentity,

  
  toggleOverlayOnScreenResponsive, verifyUserPhoneNumberWithCall, setFirebaseAuthObj } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
function getRandomPhoneReferenceId() {
  let code = getRandomInt(9).toString()+getRandomInt(9).toString()+" "+getRandomInt(9).toString()+getRandomInt(9).toString();
  return code.toString();
  /*let a = getRandomInt(9);
  let b = getRandomInt(9);
  let c = getRandomInt(9);
  let d = getRandomInt(9);
  return ""+a+b+" "+c+d;*/
}

function arrayPathToJsonObjUpdate(arr, val, operator) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    //console.log(nextParent);
    if (i===0) {
      if (operator==="push") {
        childObj = {
          "$push": [val]
        };
      } else if (operator==="set") {
        childObj = {
          "$set": val
        };
      }
      
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      //console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.businessIdentitiesRequestCreateItem = this.businessIdentitiesRequestCreateItem.bind(this);

    this.removeVerifiedIdentityInput = this.removeVerifiedIdentityInput.bind(this);


    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child
    this.verifyUsersPhone = this.verifyUsersPhone.bind(this);
    this.verifyByPhoneCall = this.verifyByPhoneCall.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,  //Every Business page must have this in state.
      user: this.props.user,    //The main user object


      thisBusinessIdentity: {},                                 //temp?
      businessIdentities: this.props.businessIdentities,                        //temp?
    

      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountUserSettingsIdentitiesPage");
    //console.log(nextProps);
    //console.log(prevState);
    
    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      //const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      //console.log('businessIdentity');
      //console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  removeVerifiedIdentityInput(body = {}) {
    //console.log("Remove Verified Identity Input");
    //console.log("Request to delete this item from the DB and then update/refresh - should be a loading icon while deleting");

    const { removeItem = {} } = body;

    //console.log('removeItem');
    //console.log(JSON.stringify(removeItem));

    let apiUi = { apiActivityId: removeItem.apiActivityId };
    let business = {
      businessId: this.state.businessViewId,
      identityId: removeItem.identityId,
    }
    this.props.removeBusinessIdentity({business, apiUi});

  }



  businessIdentitiesRequestCreateItem() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'businessIdentitiesAddItem', data);
  }

  businessIdentityAddItemWithParam(type, evt) {
    //console.log("businessIdentityAddItemWithParam");
    //console.log(type);
    this.closeOverlayOnScreen("businessIdentitiesAddItem");

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessViewId,
      type: type,
     };

    this.props.addBusinessIdentity({business, apiUi});
  }















  onChangeInputTextHandler = (evt) => {
    /*
    Challenge:  Keep redux state updated however do not call the API's for user updates on every key entry.
                This causes a performance impact and a lot of API calls. 
    Solution:   Two redux-actions:
                  1. Update the users profile (with no API calls). This will maintain users local state. 
                      If there was an issue, local state would be maintained, and we could capture the data again at a later stage.
                  2. Update the users profile AND send API call. 
    Notes:      We do not need an activity timer per field as we are updating the whole state on the API call which is the focus for the delay timer.
    */
    if (this.state.apiTimer=="inactive") {
      this.setState({
        apiTimer: "active",               //Set state for the current user action
      });
      this.onChangeInputTimer();          //Call the timer to start.
    } else if (this.state.apiTimer=="active") {
      clearTimeout(this.inputTimer);     //Clear the current Timer
      this.onChangeInputTimer();         //Call the timer to start. (e.g. Reset the timer for another period)
    }

    //Update state as typing is happening
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }


  verifyUsersPhone = () => {
    //console.log("verifyUsersPhone");
    let code = getRandomPhoneReferenceId();
    //console.log(code);



    let data = "";
    this.props.toggleOverlayOnScreenResponsive('open', 'userAccountProfileVerifyPhone', data);
  }
  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  verifyByPhoneCall = () => {
    //console.log("verifyByPhoneCall");
    let data = {};
    this.props.verifyUserPhoneNumberWithCall({data});

    

    //Call action to start phone call
  }

  






  render() {
    const { userBusinessRoutePermissions } = this.props;
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            {this.props.businessAccountClosed === true ?
            (
              <div>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Business Identities</TextStyle>
                </div>
                <CardBasicV2>
                  This account is currently closed.
                </CardBasicV2>
              </div>
            ):(
              <div>
                {this.props.activeSubscription === false ? 
                (
                  <CardBasicV2>
                    This business does not have an active subscription. Please sign up.
                  </CardBasicV2>
                ):(
                  <div>
                    <div className={classNames({ flex: true, maxWidth800: true, })}>
                      <div className={classNames({ flexGrow: true })}>
                        <TextStyle size="h2">Business Identities</TextStyle>
                      </div>
                      <div>
                        <StandardApiBtn
                          apiActivityId="AddNewBusinessIdentity"
                          icon=""
                          iconLocation=""
                          text="Add Identity"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.businessIdentitiesRequestCreateItem}
                        />
                      </div>
                    </div>

        
                    <div className={classNames({  maxWidth800: true })}>
                      {
                      this.props.businessIdentity && 
                      this.props.businessIdentity.businessIdentities.length > 0 ?
                        // Arrow function preserves "this"
                      this.props.businessIdentity.businessIdentities.map((item, i) => {
                        let outboundCallSourcePhoneNumber = '';
                        try {
                          outboundCallSourcePhoneNumber = this.props.businessIdentity.businessIdentities[i].outboundCallSourcePhoneNumber;
                        } catch (e) {
                          outboundCallSourcePhoneNumber = '';
                        }
                        if (outboundCallSourcePhoneNumber === undefined) {
                          outboundCallSourcePhoneNumber = '';
                        }

                        let awaitingVerification = false;
                        try {
                          awaitingVerification = this.props.businessIdentity.businessIdentities[i].awaitingVerification;
                        } catch (e) {
                          awaitingVerification = false;
                        }
                        if (awaitingVerification === undefined) {
                          awaitingVerification = false;
                        }

                        let identityLength = 0;
                        try {
                          identityLength = this.props.businessIdentity.businessIdentities[i].identity.length;
                        } catch (e) {
                          identityLength = 0;
                        }
                        if (identityLength === undefined) {
                          identityLength = 0;
                        }

                        return (
                          <li key={item._id} className={classNames({ listNone: true })}>
                            
                            <IdentityManagementPanelV2
                              componentTargetUser="Business"
                              identityLocked={this.props.businessIdentity.businessIdentities[i].identityLocked}
                              verificationStatus={this.props.businessIdentity.businessIdentities[i].verificationStatus}

                              communicationTokenArray={this.props.businessIdentity.businessIdentities[i].communicationTokenArray}
                              outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                              awaitingVerification={awaitingVerification}
                              
                              identityId={this.props.businessIdentity.businessIdentities[i]._id}
                              identityInputValue={this.props.businessIdentity.businessIdentities[i].identity}
                              identityInputLength={identityLength}
                              identityMask={this.props.businessIdentity.businessIdentities[i].identityMask}
                              identityType={this.props.businessIdentity.businessIdentities[i].type}
                              identityLabel={this.props.businessIdentity.businessIdentities[i].identityLabel}
                              notifyThisDevice={this.props.businessIdentity.businessIdentities[i].notifyThisDevice}
                              dataSet={this.props.businessIdentity.businessIdentities[i]}

                              key={['IdentityManagementPanelV1', item._id].join('_')}

                              verificationCode=""

                              index={i} 
                              apiEndpoint="Business"
                              businessId={this.state.businessViewId}
                              stateIdentifier="BusinessIdentities"
                              identifiers={[{ _id: this.state.businessViewId }, {_id: item._id}]}
                              id="identities.businesses.$.businessIdentities.$.identity"
                              name="identities.businesses.$.businessIdentities.$.identity"
                              onRemoveItem={this.removeVerifiedIdentityInput}
                              />

                          </li>
                        )
                      }): (null)
                      }
                      

                    </div>
                    {/* 
                    <div className={classNames({  maxWidth800: true })}>
                      {
                      this.props.businessIdentity && 
                      this.props.businessIdentity.businessIdentities.length > 0 ?
                        // Arrow function preserves "this"
                      this.props.businessIdentity.businessIdentities.map((item, i) => {

                        let identityLength = 0;
                        try {
                          identityLength = this.props.businessIdentity.businessIdentities[i].identity.length;
                        } catch (e) {
                          identityLength = 0;
                        }
                        if (identityLength === undefined) {
                          identityLength = 0;
                        }

                        return (
                        <li key={item._id} className={classNames({ listNone: true })}>
                            <IdentityManagementPanelV1
                            componentTargetUser="Business"
                            identityId={this.props.businessIdentity.businessIdentities[i]._id}
                            identityInputValue={this.props.businessIdentity.businessIdentities[i].identity}
                            identityInputLength={identityLength}
                            identityType={this.props.businessIdentity.businessIdentities[i].type}
                            identityLabel={this.props.businessIdentity.businessIdentities[i].identityLabel}
                            identityLocked={this.props.businessIdentity.businessIdentities[i].identityLocked}
                            notifyThisDevice={this.props.businessIdentity.businessIdentities[i].notifyThisDevice}
                            dataSet={this.props.businessIdentity.businessIdentities[i]}

                            key={['IdentityManagementPanelV1_Business', item._id].join('_')}

                            verificationCode={this.props.businessIdentity.businessIdentities[i].verificationCode}

                            index={i} 
                            apiEndpoint="Business"
                            businessId={this.state.businessViewId}
                            stateIdentifier="BusinessIdentities"
                            identifiers={[{ _id: this.state.businessViewId }, {_id: item._id}]}
                            id="identities.businesses.$.businessIdentities.$.identity"
                            name="identities.businesses.$.businessIdentities.$.identity"
                            onRemoveItem={this.removeVerifiedIdentityInput}
                            />
                          
                          
                        

                        </li>
                        )

                      }): (null)
                      }
                    </div>
                    */}
                  </div>
                )}
              </div>
            )}
          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="businessIdentitiesAddItem"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add an Identity"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Identities are your business public contacts such as Email, Phone Number, Mobile Number, Website, Postal Address.</TextStyle><br/>
            </div>
            <div className={classNames({ abc: true })}>
              To create an identity, select from below:<br/>

              <div className={classNames({ flex: true })}>

                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Email"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "email")}
                />
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Mobile Number"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "mobile")}
                />
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Phone Number"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "phone")}
                />
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Website"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "website")}
                />
              </div>
              <div className={classNames({ flex: true })}>
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Postal Address"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "postal")}
                />

                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Business Name"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "business-name")}
                />

                
                {/* 
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Brand Words"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.businessIdentityAddItemWithParam.bind(this, "business-brand-words")}
                />
                */}
                
              </div>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('businessIdentitiesAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="userAccountProfileVerifyPhone"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Verify your phone number"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ text_h5: true })}>
              We verify your phone number to ensure any business interactions
              that are destined for you, are only delivered to you and no one else.
            </div>
            <br/>
            <div className={classNames({ text_h5: true })}>
              We will be calling +61 419 665 808.
              <br/>
              For your protection, we will quote the following reference number on the call: 45 67
            </div>
            <br/>
            <div className={classNames({ text_h5: true })}>
              With your phone ready, we will call you and request that your enter in the following
              code.
              <br/><br/>
              Code: 12 56 78 32
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userAccountProfileVerifyPhone')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Request call now"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={this.verifyByPhoneCall}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = [];
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch(e) {}
  //console.log("ownProps");
  //console.log(ownProps);
  let businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
    businessIdentity = [];
  }
  console.log("mapStateToProps | businessIdentity");
  console.log(businessIdentity);


  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }

  ///// GET SUBSCRIPTION DETAILS /////
  let businessSubscriptions = [];
  let activeSubscription = false;
  try {
    businessSubscriptions = businessIdentity.subscriptions.filter(sub => sub.active === true);

    if (businessSubscriptions.length > 0) {
      activeSubscription = true;
    }
  } catch (e) {
    //console.error('mapStateToProps | Failed to find subscription');
  }

  return {
    businessAccountClosed,
    activeSubscription, 
    businessIdentity: Object.assign({}, businessIdentity),
    user: state.User,
  };

};

const mapDispatchToProps = {
  addBusinessIdentity,
  removeBusinessIdentity,
  changeBusinessIdentity,




  toggleOverlayOnScreenResponsive,
  
  verifyUserPhoneNumberWithCall,
  setFirebaseAuthObj
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);


/* (TEMP REMOVED FOR LAUNCH VERSION)
<StandardApiBtn
  apiActivityId="AddNewBusinessIdentity"
  icon=""
  iconLocation=""
  text="Brand Image/Logo"
  tooltip=""
  action=""
  classes="shcbtn shcbtn-info"
  handleClick={this.businessIdentityAddItemWithParam.bind(this, "business-brand-image")}
/>
*/