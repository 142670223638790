import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class CollapsableNavListItems extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: true,
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }


  render() {
    // State
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const userDataPropertyListNav = this.props.userDataPropertyListNav;

    // Props
    const hasChildren = this.props.hasChildren;
    const navDropDownItems = this.props.navDropDownItems;
    const navDropDownUserState = this.props.navDropDownUserState;

    const navPosition = this.props.navPosition;


    let navDropDownItemsUserData;

    // Load values from state if it matches.
    // Required to pass state in via props.
    if (navDropDownUserState) {
      if (navDropDownUserState.includes('userDataPropertyListNav')) {
        navDropDownItemsUserData = userDataPropertyListNav;
      }
    }
    return (
      <div>
        {hasChildren ? (
          <div>


            <div button onClick={this.toggle} className={classNames({ listItem: true, listItemParent: true })} data-navPosition={navPosition}>
              <div className={classNames({ narrowSideBarContent: true })}>
                {this.props.displayicon ?
                  <i className={this.props.displayicon} />
                        :
                  <i className="fas fa-code" />
                }
                <br />
                <div className={classNames({ navLinkSecondaryText: true, hide: this.props.sidebarOpen })}>
                  <div className={classNames({ collapsedSideBarTitle: true })}>
                    <div>{this.props.navTitleCollapsed}</div>
                    <div className={classNames({ divLineDropDownIcon: true })} />
                  </div>
                </div>
              </div>
              <div className={classNames({ extendedSideBarContentCollapse: true })}>
                <div className={classNames({ extendedSideBarTitle: true })}>
                  {this.props.navTitleExpanded}
                </div>
                <div className={classNames({ listItemExpandArrow: true })}>
                  {this.state.collapse ? <i className="fas fa-angle-down" /> : <i className="fas fa-angle-up" />}
                </div>
              </div>
            </div>


            <div className={classNames({ hide: this.state.collapse, dividerLineCollapse: true })} isOpen={this.state.collapse}>

              {navDropDownItems ? (
                navDropDownItems.map(child => (
                  <Link to={child.link} className={classNames({ textDecoration: true, navigationLinks: true })}>
                    <div button className={classNames({ listItem: true, listItemChild: true })} data-navPosition={navPosition}>
                      <div onClick={this.toggle} className={classNames({ narrowSideBarContent: true })}>
                        {child.displayicon ?
                          <i className={child.displayicon} />
                           :
                          <i className="fas fa-code" />
                        }
                        <br />
                        <div className={classNames({ navLinkSecondaryTextChildren: true, hide: this.props.sidebarOpen })}>{child.navTitleCollapsed}</div>
                      </div>
                      <div className={classNames({ extendedSideBarTitle: true, navigationLinksChild: true })}>{child.navTitleExpanded}</div>
                    </div>
                  </Link>

                ))
              ) : (
                <div />
              )
              }


              {navDropDownItemsUserData ? (
                navDropDownItemsUserData.map(child => (
                  <Link to={child.link} className={classNames({ textDecoration: true, navigationLinks: true })}>
                    <div button className={classNames({ listItem: true, listItemChild: true })} data-navPosition={navPosition}>
                      <div onClick={this.toggle} className={classNames({ narrowSideBarContent: true })}>
                        {child.displayicon ?
                          <i className={child.displayicon} />
                           :
                          <i className="fas fa-code" />
                        }
                        <br />
                        <div className={classNames({ navLinkSecondaryTextChildren: true, hide: this.props.sidebarOpen })}>{child.navTitleCollapsed}</div>
                      </div>
                      <div className={classNames({ extendedSideBarTitle: true, navigationLinksChild: true })}>{child.navTitleExpanded}</div>
                    </div>
                  </Link>

                ))
              ) : (
                <div />
              )
              }

            </div>

          </div>
        ) : (
          <div>
            <Link to={this.props.to} className={classNames({ textDecoration: true, navigationLinks: true })}>
              <div button className={classNames({ listItem: true, listItemParent: true })} data-navPosition={navPosition}>
                <div className={classNames({ narrowSideBarContent: true })}>
                  {this.props.displayicon ?
                    <i className={this.props.displayicon} />
                         :
                    <i className="fas fa-code" />
                  }
                  <br />
                  <div className={classNames({ navLinkSecondaryText: true, hide: this.props.sidebarOpen })}>{this.props.navTitleCollapsed}</div>
                </div>
                <div className={classNames({ extendedSideBarTitle: true })}>{this.props.navTitleExpanded}</div>
              </div>
            </Link>
          </div>
      )
    }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
  userDataPropertyListNav: state.User.userDataPropertyListNav,

  sidebarOpen: state.UI.sidebarOpen,
});
const mapDispatchToProps = {

};
export default compose(connect(mapStateToProps, mapDispatchToProps))(CollapsableNavListItems);
