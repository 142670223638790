import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';

import { 
  IM_container,
  IM_mobile_statusV1,
  IM_mobile_inputV1,
  IM_mobile_verifyV1,
  IM_email_statusV1,
  IM_email_inputV1,
  IM_email_verifyV1,
  IM_phone_statusV1,
  IM_phone_inputV1,
  IM_phone_verifyV1,
  IM_website_statusV1,
  IM_website_inputV1,
  IM_website_verifyV1,
  IM_business_name_statusV1,
  IM_business_name_inputV1,
  IM_business_name_verifyV1,
  IM_postal_statusV1,
  IM_postal_inputV1,
  IM_postal_verifyV1,

} from 'Components/ShcModulesIdentityManagement';

import { 
  //OLD ONE
  IdentityManagementVerificationCodeInputContainerV1, 
  IdentityManagementPrimaryInputContainerV1, IdentityManagementPrimaryLocationInputContainerV1, 
  IdentityManagementPrimaryLocationInputContainerV2,
  IdentityManagementPrimaryPhoneInputContainerV1,
  IdentityManagementPrimaryBusinessNameInputContainerV2,
  IdentityManagementPrimaryBusinessBrandImageInputContainerV2,
  IdentityManagementPrimaryBusinessBrandWordsInputContainerV2,
  IdentityManagementPrimaryContentContainerV1, 
  IdentityManagementExpandedContentContainerV1 } from 'Components/ShcModulesIdentityManagementBusiness';


// Actions
import { 
  syncUser, 
  syncBusiness,
  reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,
  
  cancelUserVerification,
  cancelBusinessVerification,

  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class IdentityManagementPanelV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    this.toggleScreenUp = this.toggleScreenUp.bind(this);
    this.toggleScreenDown = this.toggleScreenDown.bind(this);
    this.requestToRemoveIdentity = this.requestToRemoveIdentity.bind(this);

    //Change handler for verification code input
    this.onIdentityVerificationCodeInput = this.onIdentityVerificationCodeInput.bind(this);
    //Submit verification code
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);

    //Resync data (as the user has clicked on the link in the email for verification)
    this.handleIdentityVerificationViaEmail = this.handleIdentityVerificationViaEmail.bind(this);

    //Feedback from the child component as to the status of the identity
    this.recordIdentityInputValidationStatus = this.recordIdentityInputValidationStatus.bind(this);
    this.recordIdentityInputValidationSaving = this.recordIdentityInputValidationSaving.bind(this);

    
    this.handleCancelVerificationAndGoBack = this.handleCancelVerificationAndGoBack.bind(this);
 
    //On Change from input box
    this.onIdentityInputHandler = this.onIdentityInputHandler.bind(this);
    //Input of Verification Code
    
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    //Verify Request Button
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    //View a Verify Request Button
    this.handleIdentityVerificationView = this.handleIdentityVerificationView.bind(this);



    //Request a website meta tag
    this.handleIdentityVerificationWebsiteCodeRequest = this.handleIdentityVerificationWebsiteCodeRequest.bind(this);
    //Verify a website (after meta tag has been published)
    this.handleIdentityVerificationWebsite = this.handleIdentityVerificationWebsite.bind(this);

    
    //toggle the notification of the identity
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    

    this.state = {
      apiTimerCollection: [], // List of active timers.

      activeScreen: this.props.identityLocked === true ? 'ScreenOverallStatus' : this.props.awaitingVerification === true ? 'ScreenSetupIdentity_verifyIdentity' : 'ScreenSetupIdentity_input',
      screensList: [
        {
          order: 1,
          screenComponent: 'ScreenSetupIdentity_input',
        },
        {
          order: 2,
          screenComponent: 'ScreenSetupIdentity_verifyIdentity',
        },
        {
          order: 3,
          screenComponent: 'ScreenOverallStatus',
        },
        { //for UI confirmation
          order: 4,
          screenComponent: 'ScreenConfirmationCheckEmail',
        },
        { //for UI confirmation
          order: 5,
          screenComponent: 'ScreenConfirmationRemoveIdentity',
        },
        { //for UI confirmation
          order: 6,
          screenComponent: 'ScreenConfirmationCheckSMS',
        },
        { //for UI confirmation
          order: 7,
          screenComponent: 'ScreenVerificationCodeSuccessful',
        },
        { //for UI confirmation
          order: 8,
          screenComponent: 'ScreenVerificationCodeUnsuccessful',
        },
        { //for UI confirmation
          order: 9,
          screenComponent: 'ScreenSetupIdentity_verifyIdentityFailure',
        },
        { //for UI confirmation
          order: 10,
          screenComponent: 'ScreenConfirmationCheckPhoneCall',
        },

      ],

      identityLocked: this.props.identityLocked,      //Whether the identity can be edited or not.
      identityType: this.props.identityType,

      inputStatus: '',       //Initial input status
      inputSaving: false,    //If the input is currently saving





      identityData: this.props.dataSet,    //All the identity data

      identityInputLength: this.props.identityInputLength,
      
      identityNotificationEnabled: this.props.notifyThisDevice,

      dataSet: '',
      communicationToken: '',
      communicationTokenArray: [],
      websiteVerificationCode: '',

      //Entered verification code
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }


  async handleCancelVerificationAndGoBack() {
    console.warn(`handleCancelVerificationAndGoBack`);
    const { identityData } = this.state;
    let data = {}
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      //Cancel verification and go back
      //Version 1 - just remove the awaitingVerification from the local store
      await this.props.cancelUserVerification({data});
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      //Cancel verification and go back
      //Version 1 - just remove the awaitingVerification from the local store
      await this.props.cancelBusinessVerification({data});
    }

    
    console.warn(`update activeScreen`);

    this.setState({
      activeScreen: 'ScreenSetupIdentity_input',
    });
    
    
    //this.goToScreen.bind(this, 'ScreenSetupIdentity_input');

  }

  async handleIdentityVerificationViaEmail(apiActivityId) {
    //The user should have clicked on the link by now. 
    //Pressing OK will resync the user data so they
    //should see the green success.

    console.warn(`handleIdentityVerificationViaEmail: apiActivityId | ${apiActivityId}`);
    
    const apiUi = { apiActivityId };
    let data = {}
    if (this.props.apiEndpoint==="User") {
      try {
        let apiResponse = await this.props.syncUser({data, apiUi});
        console.log('identity management | syncUser response');
      } catch (e) {}
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        businessId: this.props.businessId,
      };
      try {
        let apiResponseBusiness = await this.props.syncBusiness({data, apiUi});
        console.log('identity management | syncBusiness response');
      } catch (e) {}
    }
    
    console.warn(`handleIdentityVerificationViaEmail: gotoscreen | ScreenOverallStatus`);
    //this.goToScreen.bind(this, 'ScreenOverallStatus');
    this.setState({
      activeScreen: 'ScreenOverallStatus',
    });
  }

  goToScreen(screenName, evt) {
    console.log("goToScreen");
    console.log(screenName);
    console.log(evt);
    this.setState({
      activeScreen: screenName,
    });
  }

  requestToRemoveIdentity() {
    console.log('are you sure you want to remove this identity');
    this.setState({
      activeScreen: 'ScreenConfirmationRemoveIdentity',
    });
  }

  onIdentityVerificationCodeInput(verificationCode) {
    this.setState({
      verificationCode: verificationCode,
    });
  }


  toggleScreenUp() {
    console.log('toggleScreenUp');

    let activeScreen = this.state.activeScreen;
    let screensList = this.state.screensList;

    /**
     * Get active screen, get that position, find the position up and roll over at end
     */

    let activeScreenOrder = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].screenComponent === activeScreen) {
        activeScreenOrder = screensList[x].order;
      }
    }
    console.log(`activeScreenOrder: ${activeScreenOrder}`);

    if (activeScreenOrder === undefined || activeScreenOrder === '' || activeScreenOrder === null) {
      activeScreenOrder = screensList.length;   //Move up to position 1
    }

    let nextScreenOrderTarget = '';
    nextScreenOrderTarget = activeScreenOrder + 1;

    console.log(`nextScreenOrderTarget: ${nextScreenOrderTarget}`);

    if (nextScreenOrderTarget >= screensList.length + 1) {
      nextScreenOrderTarget = 1;
    }
    
    console.log(`nextScreenOrderTarget (going up) (updated): ${nextScreenOrderTarget}`);

    let nextScreen = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].order === nextScreenOrderTarget) {
        nextScreen = screensList[x].screenComponent;
      }
    }
    
    this.setState({
      activeScreen: nextScreen,
    });

  }

  toggleScreenDown() {
    console.log('toggleScreenDown');

    let activeScreen = this.state.activeScreen;
    let screensList = this.state.screensList;

    /**
     * Get active screen, get that position, find the position up and roll over at end
     */

    let activeScreenOrder = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].screenComponent === activeScreen) {
        activeScreenOrder = screensList[x].order;
      }
    }
    console.log(`activeScreenOrder: ${activeScreenOrder}`);

    if (activeScreenOrder === undefined || activeScreenOrder === '' || activeScreenOrder === null) {
      activeScreenOrder = 2;    //Move down to position 1
    }

    let nextScreenOrderTarget = '';
    nextScreenOrderTarget = activeScreenOrder - 1;

    console.log(`nextScreenOrderTarget: ${nextScreenOrderTarget}`);

    if (nextScreenOrderTarget <= 0) {
      nextScreenOrderTarget = screensList.length;
    }
    
    console.log(`nextScreenOrderTarget (going down) (updated): ${nextScreenOrderTarget}`);

    let nextScreen = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].order === nextScreenOrderTarget) {
        nextScreen = screensList[x].screenComponent;
      }
    }
    
    this.setState({
      activeScreen: nextScreen,
    });

  }





































  static getDerivedStateFromProps(nextProps, prevState) {
    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.
    let updateState = false;
    
    //console.warn('getDerivedStateFromProps | IdentityManagementPanelV1');
    //console.warn(prevState);
    //console.warn(nextProps);



    if (nextProps.data !== prevState.data) {
      updateState = true;
    }
    try {
      if (prevState.identityVerified === false && nextProps.verificationStatus === true) {
        collapse=true;
        updateState = true;
      }
    } catch(e) {}

    try {
      if (prevState.dataSet !== nextProps.dataSet) {
        updateState = true;
      }
    } catch(e) {}

    let communicationTokenArray = [];
    try {
      communicationTokenArray = nextProps.dataSet.communicationTokenArray;
    } catch (e) {}
    if (communicationTokenArray === undefined) {
      communicationTokenArray = [];
    }
    let communicationToken = '';
    try {
      communicationToken = nextProps.dataSet.communicationToken;
    } catch (e) {}
    if (communicationToken === undefined) {
      communicationToken = '';
    }

    if (prevState.communicationToken !== nextProps.dataSet.communicationToken) {
      updateState = true;
    }
    if (prevState.communicationTokenArray !== nextProps.dataSet.communicationTokenArray) {
      updateState = true;
    }


    let websiteVerificationCode = '';
    try {
      websiteVerificationCode = nextProps.dataSet.websiteVerificationCode;
    } catch (e) {}
    if (websiteVerificationCode === undefined) {
      websiteVerificationCode = '';
    }
    if (prevState.websiteVerificationCode !== nextProps.dataSet.websiteVerificationCode) {
      updateState = true;
    }





    if (updateState === true) {
      //console.warn('UPDATING STATE FROM DERIVED');
      /*console.warn(JSON.stringify({
        collapse,
        dataSet: nextProps.dataSet,
        communicationToken,
        communicationTokenArray,
        websiteVerificationCode,
      }));*/
      return {
        collapse,
        dataSet: nextProps.dataSet,
        communicationToken: communicationToken,
        communicationTokenArray: communicationTokenArray,
        websiteVerificationCode: websiteVerificationCode,
      };
    }
    return null;
  }


  componentDidUMount() {
  }

  
  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  recordIdentityInputValidationStatus(inputStatus) {
    console.warn(`recordIdentityInputValidationStatus | UPDATING TO | ${inputStatus}`);
    this.setState({
      inputStatus: inputStatus
    });
  }

  recordIdentityInputValidationSaving(inputSaving) {
    console.warn(`recordIdentityInputValidationSaving | UPDATING TO | ${inputSaving}`);
    this.setState({
      inputSaving: inputSaving
    });
  }

  




  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }


  handleGeneralInputChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);

    if (validateAlphaNumericWithSpacesOnly(value)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === false) {
      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value,
      };
      //console.log('=== onInputChange Return ===');
      //console.log(returnObj);

      this.updateData(returnObj);
      
    }

    //Advise that there is an error with the input and we are not saving it.
  }


  onIdentityInputHandler(event) {
    //Update the length for improved ui experience

    let identityInputValue = '';
    try {
      identityInputValue = event.target.value;
    } catch (e) {
      identityInputValue = '';
    }
    if (identityInputValue === undefined) {
      identityInputValue = '';
    }
    
    let identityInputLength = 0;
    try {
      identityInputLength = identityInputValue.length;
    } catch (e) {
      identityInputLength = 0;
    }
    if (identityInputLength === undefined) {
      identityInputLength = 0;
    }
    this.setState({
      identityInputLength: identityInputLength,
    });

  }

  removeItem(identityId, evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }

  
  async handleIdentityVerificationView(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationView');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    console.log("API Request Dataset");
    console.log(identityData);

    
    let data = {};
    if (this.props.apiEndpoint==="User") {
      //NOT SETUP / NOT REQUIRED CURRENTLY
    } else if (this.props.apiEndpoint==="Business") {

      this.setState({
        activeScreen: 'ScreenSetupIdentity_verifyIdentity',
      });

    } else {
      console.error("handleIdentityVerificationView | No specified endpoint.");
    }

  }

  async handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    console.log("API Request Dataset | handleIdentityVerificationRequest");
    console.log(identityData);

    
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      let identityVerificationResponse = await this.props.userIdentitiesVerifyNow({ data, apiUi });

      if (identityVerificationResponse.status === true) {
        console.log('received success message from api call.');
        if (this.state.identityType === 'email') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckEmail',
          });
         
        } else if (this.state.identityType === 'mobile') { 
          this.setState({
            activeScreen: 'ScreenConfirmationCheckSMS',
          });
        } else if (this.state.identityType === 'phone') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckPhoneCall',
          });
        }
  
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);

      } else {
        console.log('received an error message from api call.');
        let errorMessage = '';
        try {
          errorMessage = identityVerificationResponse.message;
        } catch (e) {
          errorMessage = '';
        }
        if (errorMessage === undefined) {
          errorMessage = '';
        }

        this.setState({
          activeScreen: 'ScreenSetupIdentity_verifyIdentityFailure',
        });

        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_input',
          });
        }.bind(this), 2500);

      }




    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      console.log('Verify Now: businessIdentitiesVerifyNow');
      let identityVerificationResponse = await this.props.businessIdentitiesVerifyNow({ data, apiUi });

      if (identityVerificationResponse.status === true) {
        console.log('received success message from api call.');
        if (this.state.identityType === 'email') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckEmail',
          });
         
        } else if (this.state.identityType === 'mobile') { 
          this.setState({
            activeScreen: 'ScreenConfirmationCheckSMS',
          });
        } else if (this.state.identityType === 'phone') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckPhoneCall',
          });
        } else if (this.state.identityType === 'business-name') {
          this.setState({
            activeScreen: 'ScreenConfirmationBusinessNameVerificationInProgress',
          });
        }
  




        //Variable times for reading the messages
        let timeout = null;
        if (this.state.identityType === 'email' || this.state.identityType === 'mobile'
        || this.state.identityType === 'phone') {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 2500);
        } else if (this.state.identityType === 'business-name') {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 5500);
        } else {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 4000);
        }


        

      } else {
        console.log('received an error message from api call.');
        let errorMessage = '';
        try {
          errorMessage = identityVerificationResponse.message;
        } catch (e) {
          errorMessage = '';
        }
        if (errorMessage === undefined) {
          errorMessage = '';
        }

        this.setState({
          activeScreen: 'ScreenSetupIdentity_verifyIdentityFailure',
        });

        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_input',
          });
        }.bind(this), 2500);

      }

    } else {
      console.error("handleIdentityVerificationRequest | No specified endpoint.");
    }

  }





  async handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identityData, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
      };
      let verificationCodeResponse = await this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
      console.warn("verificationCodeResponse");
      console.warn(verificationCodeResponse);

      if (verificationCodeResponse.status === true) {
        console.warn('verification code successful - sync data');
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessfulSyncData',
        });

        //Sync the data as we will not return data from a verification activity.
        let apiResponse = await this.props.syncUser({data, apiUi});

        
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenOverallStatus',
          });
        }.bind(this), 2500);
      } else {
        console.warn('verification code invalid');
        this.setState({
          activeScreen: 'ScreenVerificationCodeUnsuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);
      }



    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };

      let verificationCodeResponse = {status: false}
      if (this.state.identityType === 'website') {
        verificationCodeResponse = await this.props.businessIdentitiesVerificationActivity_website({ data, apiUi });
      } else {
        verificationCodeResponse = await this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
      }
 
      console.warn("verificationCodeResponse");
      console.warn(verificationCodeResponse);

      if (verificationCodeResponse.status === true) {
        console.warn('verification code successful - sync data');
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessfulSyncData',
        });

        //Sync the data as we will not return data from a verification activity.
        let apiResponse = await this.props.syncBusiness({data, apiUi});

        
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenOverallStatus',
          });
        }.bind(this), 2500);
      } else {
        console.warn('verification code invalid');
        this.setState({
          activeScreen: 'ScreenVerificationCodeUnsuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);
      }

    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }



  handleIdentityVerificationWebsiteCodeRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });     //userIdentitiesVerifyNow
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });      //businessIdentitiesVerifyNow
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }


  handleIdentityVerificationWebsite(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationWebsite');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity: identityData,
      };
      this.props.userIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      //this.props.businessIdentitiesWebsiteVerifyNow({ data, apiUi });
      this.props.businessIdentitiesVerificationActivity_website({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }


  
  render() {
    const { componentTargetUser, identityId, identityInputValue, identityInputLength, identityMask, 
      identityType, 
      identityLocked, dataSet, index, apiEndpoint, businessId,
      stateIdentifier, identifiers, id, name, onRemoveItem, challenge, 
      verificationCode, identityLabel, notifyThisDevice, 
      communicationTokenArray,
      outboundCallSourcePhoneNumber,
      awaitingVerification,
     } = this.props;


    let labelInputIdentifier = '';
    if (componentTargetUser === "User") {
      labelInputIdentifier = "userIdentities.$.identityLabel";
    } else if (componentTargetUser === "Business") {
      labelInputIdentifier = "identities.businesses.$.businessIdentities.$.identityLabel";
    }
       

    ////// IDENTITY DISPLAY FORMATTING //////
    let identitySectionTitle = "Unknown";
    let identitySectionSubTitle = "Unknown";
    let identityTypeDisplayIcon = <div><i className={`fas fa-question-circle`}></i></div>;
    
    if (identityType === "phone") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "PHONE NUMBER";
      identitySectionSubTitle = "Please enter a phone number in the international format (e.g. +61)";
    
    } else if (identityType === "mobile") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "MOBILE";
      identitySectionSubTitle = "Please enter a mobile number in the international format (e.g. +61)";

    } else if (identityType === "email") {
      identityTypeDisplayIcon = <div><i className={`far fa-envelope`}></i></div>;
      identitySectionTitle = "EMAIL";
      identitySectionSubTitle = "Please enter your email address";

    } else if (identityType === "website") {
      identityTypeDisplayIcon = <div><i className={`fas fa-network-wired`}></i></div>;
      identitySectionTitle = "WEBSITE";
      identitySectionSubTitle = "Please enter your website (e.g. https://)";
      
    } else if (identityType === "social") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "SOCIAL IDENTITY";
      identitySectionSubTitle = "Please enter your social identity id (e.g. @handle)";
    
    } else if (identityType === "postal") {
      identityTypeDisplayIcon = <div><i className={`fas fa-home`}></i></div>;
      identitySectionTitle = "POSTAL ADDRESS";
      identitySectionSubTitle = "Please enter your address";

    } else if (identityType === "inperson") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "IN PERSON";
      identitySectionSubTitle = "";
      
    } else if (identityType === "business-name") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BUSINESS NAME";
      identitySectionSubTitle = "";
      
    }  else if (identityType === "business-brand-image") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BRAND IMAGE";
      identitySectionSubTitle = "";
      
    }  else if (identityType === "business-brand-words") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BRAND WORDS";
      identitySectionSubTitle = "";
      
    } 

    /////////////////////////////////////////////////////
    //// Extract Data from the dataSet prop
    /////////////////////////////////////////////////////
    let verificationStatus = false;
    try {
      verificationStatus = dataSet.verificationStatus;
    } catch (e) {
      verificationStatus = false;
    }
    if (verificationStatus === undefined) {
      verificationStatus = false;
    }

    let verificationStatusDate = false;
    try {
      verificationStatusDate = dataSet.verificationStatusDate;
      verificationStatusDate = parseInt(verificationStatusDate);
    } catch (e) {
      verificationStatusDate = false;
    }
    if (verificationStatusDate === undefined) {
      verificationStatusDate = false;
    }

    let reValidationDueDate = false;
    try {
      reValidationDueDate = dataSet.reValidationDueDate;
      reValidationDueDate = parseInt(reValidationDueDate);
    } catch (e) {
      reValidationDueDate = false;
    }
    if (reValidationDueDate === undefined) {
      reValidationDueDate = false;
    }


    let ivrMenuOptions = null;
    try {
      ivrMenuOptions = dataSet.ivrMenuOptions;
    } catch (e) {}
    let phoneHasIvr = null;
    try {
      phoneHasIvr = dataSet.phoneHasIvr;
    } catch (e) {}
    let phoneHasQueue = null;
    try {
      phoneHasQueue = dataSet.phoneHasQueue;
    } catch (e) {}


    let relativeReValdiationDueDate = '';
    if (reValidationDueDate !== null) {
      var a = moment();
      var b = moment.unix(reValidationDueDate).format("MM/DD/YYYY");

      relativeReValdiationDueDate = a.to(b);
      
      //console.log("=============== MOMENT ====================");
      //console.log(a.to(b));
      
    }




    let communicationVerificationCode = '';
    try {
      //communicationVerificationCode = dataSet.communicationToken;
      communicationVerificationCode = this.state.communicationToken;
    } catch (e) {}

    let websiteVerificationCode = '';
    try {
      websiteVerificationCode = this.state.websiteVerificationCode;
    } catch (e) {}
    if (websiteVerificationCode === undefined) {
      websiteVerificationCode = '';
    }


    //If business name, collate the additional identity values into an array
    //and pass in for on-screen view
    let identityAdditionalData = [];
    try {
      identityAdditionalData = dataSet.identityAdditionalData;
    } catch (e) {}
    if (identityAdditionalData === undefined) {
      identityAdditionalData = [];
    }






    


    let communicationVerificationCode_html = '';
    try {
      communicationVerificationCode_html = dataSet.communicationToken_html;
    } catch (e) {}
    

    


    const buttonTitleContactMade = <div><i className={`fas fa-volume-up ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    const buttonTitleNoContact = <div><i className={`fas fa-volume-mute ${classNames({colour_white: true, padR10: true})}`}></i> They didn't contact me</div>;
    const buttonTitleReportIssue = <div><i className={`fas fa-flag ${classNames({colour_white: true, padR10: true})}`}></i> Report suspicious activity</div>;
    

    /*
    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, flex: true, flexAlignItemsCenter: true})}`}
    aria-label="Verified"
    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedPhone', '')}
    ></i>
    */


    return (
      <div className={classNames({ ShcModulesIM2ViewContainerHeader: true, marB30: !this.state.collapse })}>
        <IM_container
        identityType={identityType} 
        identitySectionTitle={identitySectionTitle}
        verificationStatus={verificationStatus}
        verificationActionRequired={false}
        identityTypeDisplayIcon={identityTypeDisplayIcon}
        toggleScreenUp={this.toggleScreenUp}
        toggleScreenDown={this.toggleScreenDown}
        requestToRemoveIdentity={this.requestToRemoveIdentity}
        activeScreen={this.state.activeScreen}
        >


          {this.state.activeScreen === 'ScreenOverallStatus' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              {identityType === "email" && (
                <IM_email_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "mobile" && (
                <IM_mobile_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "phone" && (
                <IM_phone_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "website" && (
                <IM_website_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "business-name" && (
                <IM_business_name_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityAdditionalData={identityAdditionalData}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "postal" && (
                <IM_postal_statusV1
                componentTargetUser={componentTargetUser}
                reValidationDueDate={reValidationDueDate}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              <div className={classNames({ flex: true,})}>
                <div className={classNames({ flex: true, flexGrow: true, })}>
                
                </div>
                
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                  {identityType === "website" && (
                    <StandardApiBtn
                      apiActivityId="View"
                      icon=""
                      iconLocation=""
                      text="Re-verify Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationView}
                    />
                  )}
                  {identityType !== "website" && (
                    <StandardApiBtn
                    apiActivityId="UserIdentityVerifyNow"
                    icon=""
                    iconLocation=""
                    text="Re-verify Now"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleIdentityVerificationRequest}
                  />
                  )}
                  
                </div>
              </div>
            </div>
          )}
          

          {this.state.activeScreen === 'ScreenSetupIdentity_input' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              {identityType === "email" && (
                <IM_email_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              {identityType === "mobile" && (
                <IM_mobile_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              {identityType === "phone" && (
                <IM_phone_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              {identityType === "website" && (
                <IM_website_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              {identityType === "business-name" && (
                <IM_business_name_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              {identityType === "postal" && (
                <IM_postal_inputV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                recordIdentityInputValidationSaving={this.recordIdentityInputValidationSaving}
                />
              )}
              <div className={classNames({ flex: true,})}>
                <div className={classNames({ flex: true, flexGrow: true, })}>
                
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                  {this.state.inputStatus === true ? (
                    <div>
                      {this.state.inputSaving === true ? (
                        <StandardApiBtn
                          apiActivityId="UserIdentityVerifyNow"
                          icon=""
                          iconLocation=""
                          text="Saving"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-secondary"
                          //handleClick={this.handleIdentityVerificationRequest}
                        />
                      ):(
                        <StandardApiBtn
                          apiActivityId="UserIdentityVerifyNow"
                          icon=""
                          iconLocation=""
                          text="Verify Now"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.handleIdentityVerificationRequest}
                        />
                      )}                    
                    </div>
                  ):(
                    <div>
                      {this.state.inputSaving === '' ? (
                        <StandardApiBtn
                          apiActivityId="UserIdentityVerifyNow"
                          icon=""
                          iconLocation=""
                          text="Verify Now"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.handleIdentityVerificationRequest}
                        />
                      ):(
                      <StandardBtn
                        icon=""
                        iconLocation=""
                        text="Verification on valid input"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-secondary"
                      />
                      )}
                    </div>
                    
                  )}
                  {awaitingVerification === true && (
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Input Verification Code"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.goToScreen('ScreenSetupIdentity_verifyIdentity')}
                    /> 
                  )}
                </div>
              </div>
            </div>
          )}

          {this.state.activeScreen === 'ScreenConfirmationCheckEmail' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, flexJustifyCenter: true })}>
              We have sent you an email. 
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetupIdentity_verifyIdentity' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              {identityType === "mobile" || identityType === "phone" ? (
                <div>
                  VERIFICATION CODE
                </div>
               ):(null)}
               {identityType === "business-name" || identityType === "postal" ? (
                <div>
                  <div>
                    VERIFICATION CODE
                  </div>
                  <div>
                    The postal letter may take up to 4 weeks depending on location. 
                  </div>
                </div>
               ):(null)}
              {identityType === "email" && (
                <IM_email_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                communicationTokenArray={communicationTokenArray}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}

              {identityType === "mobile" && (
                <IM_mobile_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}

              {identityType === "phone" && (
                <IM_phone_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              
              {identityType === "website" && (
                <IM_website_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                websiteVerificationCode={websiteVerificationCode}
                outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "business-name" && (
                <IM_business_name_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "postal" && (
                <IM_postal_verifyV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityVerificationCodeInput}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
                />
              )}
              {identityType === "mobile" || identityType === "phone" || identityType === "website"
               || identityType === "business-name" || identityType === "postal" ? (
                <div className={classNames({ flex: true, padT20: true})}>
                  <div className={classNames({ flex: true, flexGrow: true, })}>
                    <StandardBtn
                      icon=""
                      iconLocation=""
                      text="Cancel"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-secondary"
                      handleClick={this.handleCancelVerificationAndGoBack}
                    />
                  </div>
                  {identityType === "website" && (
                    <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                      <StandardApiBtn
                        apiActivityId="UserIdentityVerifyConfirmationCode"
                        icon=""
                        iconLocation=""
                        text="New Verification"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn shcbtn-primary"
                        handleClick={this.handleIdentityVerificationRequest}
                      />
                      <StandardApiBtn
                        apiActivityId="UserIdentityVerifyConfirmationCode"
                        icon=""
                        iconLocation=""
                        text="Verify"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn shcbtn-primary"
                        handleClick={this.handleIdentityVerificationCodeSubmit}
                      />
                    </div>
                  )}
                  {identityType !== "website" && (
                    <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                      <StandardApiBtn
                        apiActivityId="UserIdentityVerifyConfirmationCode"
                        icon=""
                        iconLocation=""
                        text="Confirm"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn shcbtn-primary"
                        handleClick={this.handleIdentityVerificationCodeSubmit}
                      />
                    </div>
                  )}
                  
                </div>
              ):(
                <div className={classNames({ flex: true, padT20: true})}>
                  <div className={classNames({ flex: true, flexGrow: true, })}>
                    <StandardBtn
                      icon=""
                      iconLocation=""
                      text="Cancel"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-secondary"
                      handleClick={this.handleCancelVerificationAndGoBack}
                    />
                  </div>
                  <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyConfirmationCode"
                      icon=""
                      iconLocation=""
                      text="Ok, Done"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationViaEmail}
                    />
                  </div>
                </div> 
              )}
                
            </div>
          )}

          
          {this.state.activeScreen === 'ScreenVerificationCodeSuccessful' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              Successful verification.
            </div>
          )}

          {this.state.activeScreen === 'ScreenVerificationCodeSuccessfulSyncData' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              Successful verification.<br/>
              Updating user account.
            </div>
          )}


          {this.state.activeScreen === 'ScreenVerificationCodeUnsuccessful' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_danger: true })}>
              Verification code invalid. Please update and try again.
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetupIdentity_verifyIdentityFailure' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_danger: true })}>
              We have experienced an error. Please try again.
            </div>
          )}



          {this.state.activeScreen === 'ScreenConfirmationRemoveIdentity' && (
            <div className={classNames({ pad10: true, flex: true, padT10: true, flexJustifyCenter: true })}>
              <div>
                <div className={classNames({ padB10: true, flexJustifyCenter: true })}>
                  Are you sure you want to delete this identity?
                </div>
               
                <div className={classNames({ flex: true,})}>
                  <div className={classNames({ flex: true, flexGrow: true, })}>
                    <StandardBtn
                      icon=""
                      iconLocation=""
                      text="Cancel"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-secondary"
                      handleClick={this.goToScreen.bind(this, 'ScreenSetupIdentity_input')}
                    />
                  </div>
                  <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                    <StandardApiBtn
                    apiActivityId={`RemoveUserIdentity_${identityId}`}
                      icon=""
                      iconLocation=""
                      text="Delete"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-primary"
                      handleClick={this.removeItem.bind(this, identityId)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          
          {this.state.activeScreen === 'ScreenConfirmationCheckSMS' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, flexJustifyCenter: true })}>
              We have sent you an SMS.
            </div>
          )}

          {this.state.activeScreen === 'ScreenConfirmationCheckPhoneCall' && (
            <div className={classNames({ pad10: true, padT20: true, flexJustifyCenter: true })}>
              <div className={classNames({ flex: true, flexJustifyCenter: true })}>
                We are about to call you.
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true })}>
                Listen for the verification code. 
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, padT20: true })}>
              This call will be from {outboundCallSourcePhoneNumber}
              </div>
            </div>
          )}

          {this.state.activeScreen === 'ScreenConfirmationBusinessNameVerificationInProgress' && (
            <div className={classNames({ pad10: true, padT20: true, flexJustifyCenter: true })}>
              <div className={classNames({ flex: true, flexJustifyCenter: true })}>
                We have logged the business name verification request.
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true })}>
                Guard Point will post a letter to this address where you can enter the verification code. 
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, padT20: true })}>
                This can take up to 4 weeks depending on your location.
              </div>
            </div>
          )}




        </IM_container>

      </div>

    );
  }
}

IdentityManagementPanelV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  //console.log("ownProps.identityId");
  //console.log(ownProps.identityId);

  let businessId = '';
  try {
    businessId = ownProps.businessId;
  } catch (e) {
  }

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `identityOptionsTabGroup_${ownProps.identityId}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  return {
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  syncUser,
  syncBusiness,
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,

  cancelUserVerification,
  cancelBusinessVerification,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementPanelV1);


/* ALLOW LOGINS CONCEPT - UI COMPONENT

<IdentityManagementExpandedContentContainerV1
div1="iconSection" div2="headerSection" div3="bodySection">
  <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
    <div className={classNames({ flexGrow: true })}>
      <i class="fas fa-sign-in-alt"></i>
    </div>
  </div>
  
  <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
    <div className={classNames({ text_h3: true })}>ALLOW LOGIN</div>
  </div>
  
  <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
    <div className={classNames({ text_h6: true })}>
    Allow logins on this identity
    </div>
  </div>
</IdentityManagementExpandedContentContainerV1>


*/