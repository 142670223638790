import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessAgentDesktopConnectProfileSelectionItems } from 'Components/ShcModulesConnect';

// Actions
import { setConnectActiveProfile, customerConnectSetBusinessProfileId } from 'Actions';


class BusinessAgentDesktopConnectProfileSelection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.selectConnectBusinessProfile = this.selectConnectBusinessProfile.bind(this);

    this.state = {
      selectedBusinessProfileId: this.props.selectedBusinessProfileId ? this.props.selectedBusinessProfileId : '',
      selectedBusinessProfileName: this.props.selectedBusinessProfileName ? this.props.selectedBusinessProfileName : '',
      businessId: this.props.businessId,
      
      collapse: true,     //If not active selection, expand for the user to select a profile
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  selectConnectBusinessProfile(profileId) {
    //console.log("selectConnectBusinessProfile has been clicked/selected ");

    let profileName = "";
    let profileLabel = "";
    let profileDesc = "";
    let profileAttributes = "";
    //With the profile id, get all the details about this profile and add to the header.
    for (let x=0; x < this.props.businessIdentity.businessIdentityProfiles.length; x++) {
      if (this.props.businessIdentity.businessIdentityProfiles[x]._id === profileId) {
        //console.log("MATCH | profileId: "+profileId);
        profileName = this.props.businessIdentity.businessIdentityProfiles[x].profileName;
        profileLabel = this.props.businessIdentity.businessIdentityProfiles[x].profileLabel;
        profileDesc = this.props.businessIdentity.businessIdentityProfiles[x].profileDesc;
        profileAttributes = this.props.businessIdentity.businessIdentityProfiles[x].profileAttributes;
      }
    }
    //Get the profile details for ProfileId : profileId
    this.setState({
      selectedBusinessProfileId: profileId,
      selectedBusinessProfileName: profileName,
      collapse: true,
    });
    //Update React State for the active business we are communicating from. This will be read when 
    //sending the request to communciation

    let data = {
      businessId: this.state.businessId,
      profileId,
    }
    this.props.setConnectActiveProfile(data);

    //New Action/Reducer
    this.props.customerConnectSetBusinessProfileId(data);
    


  }

  render() {
    const { profileName, profileDetails, profileTypes, profileIdentities, businessId, businessDataRegion, businessProfileId, data } = this.props;

    return (
      <div className={classNames({ ShcAccountModulesBusinessConnectContainer: true })}>
        <div className={classNames({ ShcAccountModulesBusinessConnectContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>Customer Connect Profile</div>
                  <div className={classNames({ text_h3: true })}>{profileName}</div>
                  <div className={classNames({ text_h6: true })}>{profileTypes}</div>
                  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width80: true, cursorPointer: true })}>
                <div className={classNames({ flex: true, flexJustifyCenter: true, 
                flexAlignItemsCenter: true, flexColumn: true })}>
                  <TextStyle size="h4" weight="normal">Select</TextStyle>
                  <i class="fas fa-angle-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcAccountModulesBusinessConnectContainerBody: true })}>
          <div className={classNames({ flex: false})}>

            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Business Card Details</TextStyle>
              <TextStyle size="h5" weight="normal">The following details will be shared with the customer during an interaction.</TextStyle>
            </div>

            

            <div>
              {
              profileDetails && 
              profileDetails.length > 0 ?
                // Arrow function preserves "this"
              profileDetails.map((item, i) => {
                return (
                  <li key={item._id} className={classNames({ listNone: true })}>
                    <div className={classNames({ text_s14: true })}>{item.type}: {item.identity}</div>
                  </li>
                )

                }, this)
              : null
              }
            </div>


            <div className={classNames({ ccccc: true })}></div>
          </div>

          <br/>

          <div>
            
            <a target="_blank" href={`https://connect.guardpoint.com.au/agent/v1/${businessDataRegion}/${businessId}/${businessProfileId}`} 
            className={classNames({textDecoration: true,})}>

              <div className={`shcbtn shcbtn-primary shcbtn-rounded shcbtn-fullwidth `}>
                Open Agent Desktop
              </div>

            </a>

          </div>
        </div>
        }
        
      </div>

    );
  }
}

BusinessAgentDesktopConnectProfileSelection.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;

  //Find the business identity from the array
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  //Find the currently active business connect profile id
  let selectedBusinessProfileId = ownProps.selectedBusinessProfileId;
  try {
    selectedBusinessProfileId = businessIdentity.localSelectedBusinessProfile;
  } catch (e) {
    console.error('mapStateToProps | Unable to retrieve the active profile for connect');
  }

  //console.log("searching for business profile");

  //Find the business identity profile details (profile name, description, etc)
  let businessIdentityProfile = {};
  try {
    businessIdentityProfile = businessIdentity.businessIdentityProfiles.find(businessIdentityProfile => businessIdentityProfile._id === selectedBusinessProfileId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity Profile');
  }

  let businessDataRegion = '';
  try {
    businessDataRegion = businessIdentity.dataRegion;
  } catch (e) {
    businessDataRegion = '';
  }
  if (businessDataRegion === undefined) {
    businessDataRegion = '';
  }


  console.warn("businessIdentityProfile");
  console.warn(businessIdentityProfile);


  let selectedBusinessProfileName = '';
  try {
    selectedBusinessProfileName = businessIdentityProfile.profileName;
  } catch (e) {}
  


  console.warn("selectedBusinessProfileId");
  console.warn(selectedBusinessProfileId);

  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessDataRegion: businessDataRegion,
    selectedBusinessProfileId: selectedBusinessProfileId,
    selectedBusinessProfileName: selectedBusinessProfileName,
    businessIdentityProfile: Object.assign({}, businessIdentityProfile),
  };

};

const mapDispatchToProps = {
  setConnectActiveProfile,
  customerConnectSetBusinessProfileId,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessAgentDesktopConnectProfileSelection);
