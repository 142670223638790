import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, CheckboxOptionCardReduxV1, InputTextV3, InputTextV6 } from 'Components/ShcInput';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { userDelete, reduxDataUpdate, apiDataUpdate } from 'Actions';

// Store
import { auth, actionCodeSettings } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.deleteUserAccount = this.deleteUserAccount.bind(this);
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); // new dynamic


    this.state = {
      user: this.props.user, // The main user object
      

      languageChange: 0,    //UI regeneration on language change

      apiTimerCollection: [], // List of active timers.

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } 
      return null;
    
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("componentDidUpdate - AccountUserSettingsProfilePage");
    if (prevProps !== this.props) {

    }

  }

  userChangeLanguage(lang, evt) {
    console.log("change language");
    console.log(lang);
    this.props.i18n.changeLanguage(lang);

  }

  
  deleteUserAccount(e) {
    console.log('deleteUserAccount');

    this.props.userDelete();

    // delete from our db first (utilise the auth process and lastly remove from firebase)
    /*
    Promise.resolve(this.props.userDelete(auth().currentUser)) // dispatch
      .then((result) => {
        //console.log('userDelete action returned');
        //console.log(result);
        auth().currentUser.delete();
      })
      .then((result) => {
        //console.log('successful removal of user account');
        //console.log(result);
      })
      .catch((error) => {
        //console.log('error in catch');
        //console.log(error);
      });
      */
  }


  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log('onChangeDynamicInputTextHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /** ************ LOCAL STATE DATA UPDATES ************ */

    this.props.reduxDataUpdate({ recordsForUpdate });

    /** ************ BACKEND DATA UPDATES **************** */
    // Each time there is a change, set a timer for a DB update to follow.

    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      // this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }


  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/dashboard"
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/dashboard"
                backTitle="Dashboard"
                smallTitleTF="true"
                smallTitle="User"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.t('user.account.pageTitle')}
            </div>
            <br/>
            
            {this.props.userId.toString() === '5f2e3cedd70dee0008155fd5' && (
              <div>
                <InputCard headlineText={this.props.t('user.account.details.title')} headlineSubText={this.props.t('user.account.details.subTitle')}>
                  
                
                  <InputCardItem headlineText={this.props.t('user.account.details.firstName')} 
                  headlineSubText="New V6 Input" additionalText="">
                    <InputTextV6
                      id="userProfile.firstName"
                      name="userProfile.firstName"
                      identifiers={[]}
                      stateIdentifier="User"
                      label=""
                      value={this.props.firstName}
                      onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
                    />
                  </InputCardItem>
                  
                  
                  
                  
                  <InputCardItem headlineText={this.props.t('user.account.details.firstName')} headlineSubText="" additionalText="">
                    <InputTextV3
                      id="userProfile.firstName"
                      name="userProfile.firstName"
                      identifiers={[]}
                      stateIdentifier="User"
                      label=""
                      value={this.props.firstName}
                      onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
                    />
                  </InputCardItem>
                  <InputCardItem headlineText={this.props.t('user.account.details.lastName')} headlineSubText="" additionalText="">
                    <InputTextV3
                      id="userProfile.lastName"
                      name="userProfile.lastName"
                      identifiers={[]}
                      stateIdentifier="User"
                      label=""
                      value={this.props.lastName}
                      onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
                    />
                  </InputCardItem>
                </InputCard>

              </div>
            )}
            
            <div>
              <InputCard headlineText={this.props.t('user.account.personalInfo.title')} headlineSubText={this.props.t('user.account.personalInfo.subTitle')}>
                <TextStyle size="h4">{this.props.t('user.account.personalInfo.userIdText')}</TextStyle>
                <br/>
                <div className={classNames({ ShcIdentityInput_textNoInput: true, })}>
                  {this.state.user._id}
                </div>
                <br/><br/>
 
                <TextStyle size="h4">{this.props.t('user.account.personalInfo.dataStoredAt')}</TextStyle>
                <br/>
                <div className={classNames({ ShcIdentityInput_textNoInput: true, })}>
                  {this.props.userDataStorageRegion}
                </div>
                <br/>
                <TextStyle size="h5">{this.props.t('user.account.personalInfo.toChangeDataRegion')}</TextStyle>
              </InputCard>
            </div>
            <br/>
            <div>
              <InputCard headlineText={this.props.t('user.account.deleteAccount.title')} headlineSubText={this.props.t('user.account.deleteAccount.subTitle')}>
                <div className={classNames({ text_s14: true, })}>
                  {this.props.t('user.account.deleteAccount.deleteAccountImpactInformation_1')}<br/>
                  <br/>
                  {this.props.t('user.account.deleteAccount.deleteAccountImpactInformation_2')}<br/>
                  <ul>
                    
                    <li>Removing any personal information collected (such as first name, etc)</li>
                    <li>Removing all your configured identities</li>
                    <li>Removing all your notification devices</li>
                    <li>Removing any personal information linked to a business account on the Guard Point platform</li>
                  </ul>

                  <br/>
                  You will need to manually uninstall any of our installed mobile apps on your mobile device.
                  <br/><br/>
                  Once your account has been deleted, you will not be able to recover your account.<br/>

                </div>

                <br/><br/>
                <div>
                  <CheckboxOptionCardReduxV1
                  checkboxOptionsId="deleteUser_agreement1"
                  businessId=""
                  checkboxText="I understand that by clicking this checkbox, I am deleting this 
                  user account and canceling all associated services.

                  I understand that if I hold any 'ownership' roles in a business on this 
                  platform that I will first need to remove my account from that business before 
                  I can delete this user account. 

                  I understand that once this user account is deleted, the user account 
                  cannot be recovered.

                  I understand my access to the Guard Point platform will be removed immediately."
                  />
                  <br/>
                </div>
                

                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div>
                    <StandardApiBtn
                    apiActivityId="deleteUserAccount"
                    icon=""
                    iconLocation=""
                    text="Delete User Account"
                    tooltip=""
                    action=""
                    classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                    handleClick={this.deleteUserAccount}
                    />
                  </div>
                </div>


              </InputCard>

            </div>




            

            <TroubleshootingAdminWindow>
              <div className={classNames({ wordWrapBreak: true })}>
                {JSON.stringify(this.state)}
              </div>
            </TroubleshootingAdminWindow>



          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {

  let userDataStorageRegion = '';
  try {
    userDataStorageRegion = state.User.dataRegion;
  } catch (e) {}
  if (userDataStorageRegion === 'aus') {
    userDataStorageRegion = "Australia";
  } else if (userDataStorageRegion === 'usa') {
    userDataStorageRegion = "United States";
  }

  let firstName = '';
  let lastName = '';
  try {
    firstName = state.User.userProfile.firstName;
  } catch (e) {}
  try {
    lastName = state.User.userProfile.lastName;
  } catch (e) {}

  let userId = '';
  try {
    userId = state.User._id;
  } catch (e) {
    userId = '';
  }
  if (userId == undefined) {
    userId = '';
  }


  return {
    user: state.User,
    userId,
    userDataStorageRegion,
    firstName,
    lastName,
  };

};

const mapDispatchToProps = {
  userDelete,
  reduxDataUpdate,
  apiDataUpdate,
};

export default compose(withTranslation('common'),
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
