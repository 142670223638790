import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing

// Material UI
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


function TabContainer({ children }) {
  return (
    <div>
    { children }
    </div>
  );
}

class TabPanel extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

 handleChange = (event, value) => {
   //console.log("tab handleChange");
    this.setState({ value });
  };
  handleChangeIndex = index => {
    //console.log("tab handleChangeIndex");
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;

    const GenerateTabContent = () => {
      let data = this.props.children.map((child, i) => {
        if (i % 2) {
          return child;
        } else {
          return null;
        }
      });
      //Clean out nulls
      data = data.filter((obj) => obj );
      return (
        <SwipeableViews
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        > 
        {data.map((child, i) => {
            return (<TabContainer>{child}</TabContainer>);
        })}
        </SwipeableViews> 
      )
    }

    return (
      <div className={classNames({ outlineBtnWrapper: true })}>
        <div
          className={`${classes} 
          ${classNames({
          })}`}
        >
        </div>

        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {
            this.props.children.map((child, i) => (
              (i % 2 ? 
                null
              : 
                <Tab label={child} />
              )
            ))
          }
        </Tabs>
        <GenerateTabContent/>
      </div>
    );
  }
}

TabPanel.propTypes = {

};
TabPanel.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TabPanel);
