import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// Stripe Payments
import { Elements, StripeProvider } from 'react-stripe-elements';
import { BusinessSubscriptionUpdateCreditCardV1 } from 'Components/Stripe';


// CSS
import componentStyles from './styles.css';
import commonStyles from '../../Components/_CommonStyles/commonStyles.css';


// Components
// import Breadcrumb from 'Components/Breadcrumb';

import { InputTextV3, IdentityInputV1, IdentityInputV2, RadioOptionCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';

import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { MessageTip, MessageHighlight } from 'Components/ShcText';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

// Actions
import {  } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      businessId: this.props.match.params.routebusinessid,
      route_subscriptionId: this.props.match.params.subscriptionId,


      user: this.props.user, // The main user object


      subscriptionId: this.props.businessSubscriptionDetails.paymentsSubscriptionId,


      apiTimerCollection: [], // List of active timers. (MANDATORY)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }





  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


 


  render() {
    const { userBusinessRoutePermissions } = this.props;
    const { selectedSubscriptionProduct, businessSubscriptionDetails } = this.props;

    

    /////////////////////////////////////////////////////////////////////////
    // GENERAL SUBSCRIPTION INFORMATION
    /////////////////////////////////////////////////////////////////////////

    //Details about the product (subscription)
    let subCreatedAt = '';
    let subDesc = '';
    let subRecordId = '';
    let subPlanId = '';
    let subAvailableDate = '';
    let subAvailableForPurchase = '';
    let subExpiresDate = '';
    let subId = '';
    let subPeriod = '';
    let subTransitionPlanId = '';
    let subSummary = '';
    let subTitle = '';
    let subUsageCosts = '';

    let subscriptionPrice = '';
    let subscriptionCurrency = 'AUD';
    let subscriptionUsageCosts = true;
    let stripePlanId = '';

    let plusUsageMessage  = '';
    
    try {
      subCreatedAt = selectedSubscriptionProduct.createdAt;
      subDesc = selectedSubscriptionProduct.description;
      subRecordId = selectedSubscriptionProduct.id;
      subPlanId = selectedSubscriptionProduct.stripePlanId;
      subAvailableDate = selectedSubscriptionProduct.subscriptionAvailableDate;
      subAvailableForPurchase = selectedSubscriptionProduct.subscriptionAvailableForSale;
      subExpiresDate = selectedSubscriptionProduct.subscriptionExpiryDate;
      subId = selectedSubscriptionProduct.subscriptionId;
      subPeriod = selectedSubscriptionProduct.subscriptionPeriod;
      subUsageCosts = selectedSubscriptionProduct.subscriptionUsageCosts;
      subTransitionPlanId = selectedSubscriptionProduct.subscriptionTransitionPlanId;
      subSummary = selectedSubscriptionProduct.summary;
      subTitle = selectedSubscriptionProduct.title;

      subscriptionPrice = selectedSubscriptionProduct.subscriptionPrice;
      subscriptionCurrency = selectedSubscriptionProduct.subscriptionCurrency;
      subscriptionUsageCosts = selectedSubscriptionProduct.subscriptionUsageCosts;

      stripePlanId = selectedSubscriptionProduct.stripePlanId;

    } catch(e) { }


    
    let subscriptionHeadlineText = `${subTitle} Subscription Overview`;
    let subscriptionPlan = `${subTitle} (${stripePlanId})`;
    
    let priceInDollars = subscriptionPrice/100;
    if (subscriptionUsageCosts === true) {
      plusUsageMessage = "plus usage costs";
    } else {
      plusUsageMessage = "";
    }
    let subscriptionPriceMessage = `Monthly fee of $${priceInDollars} ${subscriptionCurrency} ${plusUsageMessage}`;



    /////////////////////////////////////////////////////////////////////////
    // BUSINESS SUBSCRIPTION DETAILS (THIS SPECIFIC SUBSCRIPTION)
    /////////////////////////////////////////////////////////////////////////

    let subscriptionDetailHeadlineText = `${subTitle} Subscription Details`;

    //Details about the business subscription
    let businessSubscriptionId = '';
    let subscriptionStatus = '';
    let paymentsSubscriptionId = '';

    try {
      businessSubscriptionId = businessSubscriptionDetails._id;
      subscriptionStatus = businessSubscriptionDetails.active;
      paymentsSubscriptionId = businessSubscriptionDetails.paymentsSubscriptionId;
    } catch(e) {}




    

    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              
            </div>

            {this.state.route_subscriptionId === 'trial-access' && (
              <div>TRIAL ACCESS SCREEN</div>
            )}


            <div>
              <InputCard headlineText={subscriptionHeadlineText} headlineSubText="">
                <div className={classNames({ aaa: true })}>
                
                  <InputCardItem
                    headlineText="Subscription Plan"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                    textDisplayContainer: true,
                    bg_colour_white: true,
                    })}
                    >

                      {subscriptionPlan}

                    </div>
                  </InputCardItem>

                  <InputCardItem
                    headlineText="Subscription Price"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                    textDisplayContainer: true,
                    bg_colour_white: true,
                    })}
                    >

                      {subscriptionPriceMessage}

                    </div>
                  </InputCardItem>

                  <InputCardItem
                    headlineText="Billing Method"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                      textDisplayContainer: true,
                      bg_colour_white: true,
                    })}
                    >

                      Auto-renew Monthly

                    </div>
                  </InputCardItem>


                </div>


              </InputCard>




              <InputCard headlineText={subscriptionHeadlineText} headlineSubText="">
                <div className={classNames({ aaa: true })}>
                
                  

                <InputCardItem
                    headlineText="Subscription ID"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                    textDisplayContainer: true,
                    bg_colour_white: true,
                    })}
                    >

                      {businessSubscriptionId}

                    </div>
                  </InputCardItem>

                  

                  

                  <InputCardItem
                    headlineText="Status"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                      textDisplayContainer: true,
                      bg_colour_white: true,
                    })}
                    >

                      {subscriptionStatus === true ? (
                        <div>
                          Active
                        </div>
                      ):(
                        <div>
                          Inactive
                        </div>
                      )}

                    </div>
                  </InputCardItem>

                  <InputCardItem
                    headlineText="Business ID"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({
                      textDisplayContainer: true,
                      bg_colour_white: true,
                    })}
                    >

                      {this.state.businessId}

                    </div>
                  </InputCardItem>



                </div>


              </InputCard>


              

             

              <InputCard headlineText="Switch Plans" headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <TextStyle size="h5">
                    To switch plans simply choose a different plan and follow the 
                    purchase process.<br/> 
                    The current plan will be finalised and an invoice sent to close out 
                    the account. <br/>
                    You will be setup on a new account immediately where you can continue
                    to connect with customers.

                  </TextStyle>
                  

                </div>


              </InputCard>

              <InputCard headlineText="Cancel Subscription" headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <TextStyle size="h5">
                    To cancel this subscription you will need to delete this business 
                    from the account page. <br/>
                    The subscription is linked directly to the business account and is 
                    required to operate this business on our platform.

                  </TextStyle>

                  
                  <br/> 
                </div>

              </InputCard>

              <InputCard headlineText="Disputes" headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <TextStyle size="h5">
                  To dispute a subscription or billing cost please log this via out support portal.
                  </TextStyle>
                  <br />
                </div>

              </InputCard>


     
              <InputCard headlineText="Update Credit Card" headlineSubText="To update the credit card for this subscription, please enter in all the details">
                <div className={classNames({ aaa: true })}>
                  <div className={classNames({ aaa: true })}>
                    <StripeProvider stripe={this.state.stripe} apiKey="pk_test_ypX7KO3HyKJTBtJ4SoD6eXwb">
                      <BusinessSubscriptionUpdateCreditCardV1
                        businessId={this.state.businessId}
                        businessSubscriptionId={businessSubscriptionId}
                      />
                    </StripeProvider>
                  </div>
                </div>
              </InputCard>


            </div>

          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  const subscriptionId = ownProps.match.params.subscriptionId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }


  let businessSubscriptionDetails = {};
  try {
    businessSubscriptionDetails = businessIdentity.subscriptions.find(x => x._id === subscriptionId);
  } catch (e) {
    console.error('mapStateToProps | businessSubscriptionDetails');
  }

  let productId = '';
  try {
    productId = businessSubscriptionDetails.subscriptionId;
  } catch (e) {
    console.error('mapStateToProps | productId');
  }


  console.warn(`productId: ${productId}`);
  let selectedSubscriptionProduct = {};
  try {
    selectedSubscriptionProduct = state.Product.subscriptionProducts.find(x => x.subscriptionId === productId);
  } catch (e) {
    //console.log('mapStateToProps | selectedSubscriptionProduct');
  }

  let businessSubscriptionId = '';
  try {
    businessSubscriptionId = selectedSubscriptionProduct._id;
  } catch (e) {

  }




  

  
  




  return {
    businessSubscriptionId: businessSubscriptionId,
    businessIdentity: Object.assign({}, businessIdentity),
    user: state.User,
    selectedSubscriptionProduct: Object.assign([], selectedSubscriptionProduct),
    businessSubscriptionDetails: Object.assign({}, businessSubscriptionDetails),
  };

};

const mapDispatchToProps = {

};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
