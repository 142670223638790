import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions

// Store
import store from 'Store/ConfigureStore';

/*
  //Adding features
  > Update switch for componentWillMount()
  > Update switch for store.subscribe(()
  > Update switch for SelectedItems

*/


class UserSelectionDropdown extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: props.collapse,
      // filter_services_selections: props.filter_services_selections,

      selectionCount: 0,
    };
  }


  componentWillMount() {
    //console.log('WillUpdate - UserSelectionDropdown');
    if (this.props.isOpen === true) {
      this.state.collapse = false;
    }
    // On mounting, set state.
    let displayValue = 0;
    if (Array.isArray(this.props.filter_services_selections)) {
      switch (this.filterServicesCategory) {
        case 'transport':
          this.props.filter_services_selections.forEach((value, index) => {
            if (value.category === this.filterServicesCategory) {
              displayValue += this.props.filter_services_selections[index].selections;
            }
          });
          this.setState({ selectionCount: displayValue });

        default:
      }
    }
  }

  componentDidMount() {
    // console.log('DidMount - UserSelectionDropdown');
  }
  componentWillUpdate() {
    // console.log('WillUpdate - UserSelectionDropdown');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('DidUpdate - UserSelectionDropdown');
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    // State
    const { filter_services_selections } = this.state;

    // Props
    const {
      title, desc, info, openText, closeText,
      filterServicesCategory, // "transport" <-- category
    } = this.props;


    store.subscribe(() => {
      //console.log('should we update this component');
      // console.log(store.getState());

      const current_store = store.getState();

      let displayValue = 0;
      if (Array.isArray(current_store.UserSearch.filter_services_selections)) {
        switch (filterServicesCategory) {
          case 'transport':
            current_store.UserSearch.filter_services_selections.forEach((value, index) => {
              if (value.category === filterServicesCategory) {
                displayValue += current_store.UserSearch.filter_services_selections[index].selections;
              }
            });
            if (this.state.selectionCount !== displayValue) {
              // Different values for the specific items we are watching - rerender this component
              // console.log('>>>>>> WE ARE UPDATING');
              this.setState({ selectionCount: displayValue });
            } else {
              // console.log('<<<<< NO CHANGE');
            }
            // console.log(`new value for selection is: ${displayValue}`);

          default:
            // console.log('nothing to update from store monitoring');
        }
      }
    });

    const SelectedItems = () => {
      let displayValue = 0;
      if (Array.isArray(this.props.filter_services_selections)) {
        switch (filterServicesCategory) {
          case 'transport':
            this.props.filter_services_selections.forEach((value, index) => {
              if (value.category === filterServicesCategory) {
                displayValue += this.props.filter_services_selections[index].selections;
              }
            });
            this.state.selectionCount = displayValue;
            return <div>{displayValue} selected</div>;
          default:
            return ' ';
        }
      }
      return ' ';
    };

    return (
      <div className={classNames({
        UserSelectionDropdown_container: true,
        expander: true,
        expanded: !this.state.collapse,
        })}
      >
        <div onClick={this.toggle} className={classNames({ UserSelectionDropdown_contentRow: true })}>
          <div button onClick={this.toggle} className={classNames({ UserSelectionDropdown_title: true })}>{title}</div>
          <div button onClick={this.toggle} className={classNames({ UserSelectionDropdown_change: true })}>
            {this.state.collapse ? (
              <div>{openText}</div>
          ) : (
            <div>{closeText}</div>
          )}
          </div>
        </div>
        <div onClick={this.toggle} className={classNames({ UserSelectionDropdown_contentRow: true, UserSelectionDropdown_contentRowBottom: true })}>
          <div button onClick={this.toggle} className={classNames({ UserSelectionDropdown_desc: true })}>{desc}</div>
          <div button onClick={this.toggle} className={classNames({ UserSelectionDropdown_info: true })}>
            {<SelectedItems /> || info}

          </div>
        </div>
        <div className={classNames({ UserSelectionDropdown_content: true, UserSelectionDropdown_hide: this.state.collapse })} isOpen={this.state.collapse}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

UserSelectionDropdown.defaultProps = {
  openText: 'Open',
  closeText: 'Close',
  collapse: true,
  isOpen: false,
};

const mapStateToProps = state => ({
  filter_services_selections: state.UserSearch.filter_services_selections,
});
const mapDispatchToProps = {

};
export default compose(connect(mapStateToProps, mapDispatchToProps))(UserSelectionDropdown);
