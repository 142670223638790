import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
// Components
import { UsersRolesPermissionsDisplay } from 'Components/ShcAccountModules';
// Actions


class UsersRolesDisplay extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { data } = this.props;


    // dataObj - [{text1: text2}, {text3: text4}]


    const UserPermissions = (permissions, userId, userName, userEmail) => (
      <UsersRolesPermissionsDisplay data={permissions} userId={userId} userName={userName} userEmail={userEmail} />
    );


    const TableListRow = (userName, userEmail, userId, permissions) => (
      <div className={classNames({ UsersRolesDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesDisplay_TableRowStyles: true })}>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true })}>
            <div className={classNames({ UsersRolesDisplay_titleText: true })}>
              {userName}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              {userEmail}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              {userId}
            </div>
          </div>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true, UsersRolesDisplay_contentText: true })}>
            <UserPermissions data={permissions} userId={userId} userName={userName} userEmail={userEmail} />
          </div>
        </div>
      </div>
    );

    const TableList = () => (
      <div>
        <div className={classNames({ UsersRolesDisplay_divTable: true, UsersRolesDisplay_container: true })}>
          <div className={classNames({ UsersRolesDisplay_divTableBody: true })}>

            {data.map(obj => (
              TableListRow(obj.userName, obj.userEmail, obj.userId, obj.permissions)
            ))}

          </div>
        </div>
      </div>
    );


    return (
      <div>
        <TableList />
      </div>
    );
  }
}

UsersRolesDisplay.propTypes = {

};
UsersRolesDisplay.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UsersRolesDisplay);
