import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';
import Redirect from 'react-router-dom/Redirect';



// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';
import { InputCard, InputCardItem } from 'Components/ShcCards';

// Actions
import { logoutUser, userAuth, userAuthEmail, setuserlogout } from 'Actions';

import { providerG, providerFB, auth, actionCodeSettings } from 'Store/client';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      loggingUserOut: true,
      userLoggedOut: false,
      isAuthenticated: this.props.isAuthenticated,
      //REDIRECTION CONTROL
      redirectToLoginPage: false, 
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount(props) {
    auth().signOut()
    .then(() => {
      this.setState({ user: null });
      this.props.logoutUser()
      .then((logoutUserResult) => {
        console.log("===== removed user data ======");
        console.log(logoutUserResult);

        let timeout = setTimeout(() => { 
          this.setState({     
            redirectToLoginPage: true,
          });
         }, 1500);


      })

    });

  }

  componentDidUpdate() {
  }

  logout() {
    /* REMOVING TEMP
    auth().signOut()
    .then(() => {
      this.setState({ user: null });
      this.props.setuserlogout(); // 19/08 - removed data as reducer doesn't have the user obj passed.... will need to review

    });
    */
  }

  componentWillUnmount() {
  }




  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>

          {this.state.redirectToLoginPage === true && (
            <Redirect to={{
              pathname: '/login',
            }}/>
          )}

          {this.state.loggingUserOut === true && 
            this.props.userLoggedOut === false && (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Logout" headlineSubText="We are logging you out of the system.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s16: true })}>
                  Logging out and clearing user data
                  </div>
                </div>
              </InputCard>
            </div>
          )}

          {this.props.userLoggedOut === true && (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Logged Out" headlineSubText="">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="far fa-check-circle" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s16: true })}>
                  You have been logged out and local user data removed
                  </div>
                </div>
              </InputCard>
            </div>
          )}

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};



const mapStateToProps = (state, ownProps) => {
  let userLoggedOut = false;

  let authApiActive = true;
  try {
    let apiAccessToken = state.Auth.apiAccessToken;
    if (apiAccessToken === "") {
      authApiActive = false;
    }
  } catch (e) {}
  let isAuthenticated = state.Auth.isAuthenticated;
  
  let userIdSet = true;
  let userId = false;
  try {
    let userId = state.User.id;
  } catch (e) {
    userId = false;
  }
  if (userId === false) {
    userIdSet = false;
  }

  
  if (authApiActive == false && isAuthenticated === false && userIdSet === false ) {
    userLoggedOut = true
  }


  return {
    userLoggedOut,
    isAuthenticated: state.Auth.isAuthenticated,
  };

};


const mapDispatchToProps = {
  logoutUser,
  userAuth,
  userAuthEmail,
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
