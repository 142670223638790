import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';


class FooterPrimaryLinks extends React.Component {
  state = {
  };
  render() {

    return (
      <div className={classNames({shcFooterPrimaryLinks:true})}>
        <div className={classNames({shcFooterPrimaryLinks_container:true, flex: true,})}>

          <div className={classNames({ flex: true, flexJustifyCenter: true, flexGrow: true })}>
            <div className={classNames({ text_s14: true })}>
            GuardPoint.com.au&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Guard Point Pty Ltd&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;ACN: 639 579 025
            </div>
          </div>

        </div>
      </div>
    );
  }
}

FooterPrimaryLinks.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(FooterPrimaryLinks);