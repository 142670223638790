import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';



// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';

import { BusinessChartLine } from 'Components/ShcModulesBusinessReporting';

// Actions
import { 
  logoutUser, //required to be on each page
  apiDataUpdate, reduxDataUpdate,
  deleteBusiness, requestImmediateBusinessDeletion, 
toggleOverlayOnScreenResponsive, addBusinessContactIdentityToAccount } from 'Actions';


// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); //new dynamic

    this.chartOnClick = this.chartOnClick.bind(this);
    
    
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.state = { 
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState=false;
    if (nextProps.user !== prevState.user) {
      updateState=true;
    }
    if (updateState===true) {
      return {
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.forceUpdate();
  }


  //NEW 201907
  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }


  chartOnClick(point, event) {
    console.log("chartOnClick ======");
    console.log(point);
    console.log(event);
  }

  render() {
    const { businessId } = this.state;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            <div>
              <InputCard headlineText="Last 30 days" headlineSubText="Interactions made over the last 30 days">
                <BusinessChartLine
                businessId={this.props.match.params.routebusinessid}
                reportDatasetsByBusinessId={this.props.businessIdDatasets}
                reportDatasetName="connectLast30Days"
                reportDataset="businessInteractionsLast30Days"
                reportIntervals={30}
                

                />
              </InputCard>
            </div>
    
            <br/>
  
              

            <OverlayOnScreenResponsive
              id="selectBusinessContactIdentitiesItemToAdd"
              type="default"
              width="50%"
              height="100vh"
              anchor="right"
              title="Add a Business Contact Identity"
              classes=""
            >
              <div className={classNames({ pad10: true })}>
                <div className={classNames({ abc: true })}>
                  <TextStyle size="h3">Identities</TextStyle>
                  <TextStyle size="h5">Select an identity to add to this profile.</TextStyle>
                </div>
                <div className={classNames({ abc: true })}>

      
                </div>

        
                <br/>
                <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
                  <div className={classNames({ abc: true })}>
                    <StandardBtn
                      icon=""
                      iconLocation=""
                      text="Close"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-secondary"
                      handleClick={() => this.closeOverlayOnScreen('selectBusinessContactIdentitiesItemToAdd')}
                    />
                  </div>
                  <div className={classNames({ abc: true })}>
                    
                  </div>
                </div>

              </div>
            </OverlayOnScreenResponsive>
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = '';
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  let accountOptions = {};
  try {
    accountOptions = businessIdentity.accountOptions;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business account options');
  }
  let identityNotificationStartTime = {};
  try {
    identityNotificationStartTime = accountOptions.identityNotificationStartTime;
  } catch (e) {}
  let identityNotificationEndTime = {};
  try {
    identityNotificationEndTime = accountOptions.identityNotificationEndTime;
  } catch (e) {}
  let accountCountry = {};
  try {
    accountCountry = accountOptions.accountCountry;
  } catch (e) {}
  let accountTimezone = {};
  try {
    accountTimezone = accountOptions.accountTimezone;
  } catch (e) {}














  let businessAccountPostClosure = false;
  try {
    businessAccountPostClosure = businessIdentity.accountPostClosure;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive account info');
  }
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive account info');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  let businessUiCheckboxOptionsValues = [];
  if (state.UI.uiCheckboxOptions) {
    //console.log('mapStateToProps | uiCheckboxOptions is true');
    for (let z = 0; z < state.UI.uiCheckboxOptions.length; z++) {
      //console.log(`mapStateToProps | seaching for business id: ${state.UI.uiCheckboxOptions[z].businessId} === ${businessId}`);
      try {
        if (state.UI.uiCheckboxOptions[z].businessId.toString() === businessId.toString()) {
          businessUiCheckboxOptionsValues.push(state.UI.uiCheckboxOptions[z]);
        }
      } catch (e) {}
      
    }
  } else {
    checkboxSelectedState = false;
    //console.log('mapStateToProps | Checkbox state not found');
  }
  


  ///// REPORTING /////
  let businessDatasets = state.Reporting.businessDatasets;
  console.log(businessDatasets);
  let businessIdDatasets = businessDatasets.filter(rep => rep.businessId === businessId);



  


  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
    businessAccountPostClosure: businessAccountPostClosure,
    businessAccountClosed: businessAccountClosed,

    businessUiCheckboxOptionsValues: Object.assign([], businessUiCheckboxOptionsValues),  //Allows the lookup of what checkbox options have been selected.
  

    identityNotificationStartTime,
    identityNotificationEndTime,
    accountCountry,
    accountTimezone,

    // REPORTING //
    businessIdDatasets,
  
  };

};


const mapDispatchToProps = {
  logoutUser,
  reduxDataUpdate,
  apiDataUpdate,

  deleteBusiness,
  requestImmediateBusinessDeletion,


  toggleOverlayOnScreenResponsive,
  addBusinessContactIdentityToAccount,


};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)
