import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import {  } from 'Actions';


class GettingStartedCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headline1: props.headline1,
      headline2: props.headline2,
      headlineDetail: props.headlineDetail,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { 
      headlineIcon,
      headline1, headline2, headlineDetail
    } = this.props;

    return (
      <div>
        <div className={classNames({ ShcInputContainer: true })}>
          <div className={classNames({ ShcInputContainer_header: true, flex: true, })}>

            <div className={classNames({ gettingStartedCardIcon: true })}>
              <i class={headlineIcon}></i>
            </div>
            <div className={classNames({ yyyy: true })}>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  {headline1}
                </div>
                <div className={classNames({ null: true })}>
              
                </div>
              </div>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true, text_s26: true, })}>
                  {headline2}
                </div>
                <div className={classNames({ null: true })}>
                  
                </div>
              </div>
              <div className={classNames({ ShcInputContainer_headerSecondaryText: true })}>
                {headlineDetail}
              </div>
            </div>

          </div>
          <div className={classNames({ ShcInputContainer_body: true })}>
            
            {this.props.children}

            
          </div>
            <div className={classNames({ ShcInputContainer_footer: true })}>
            
            </div>
        </div>
      </div>

    );
  }
}

GettingStartedCard.propTypes = {
  headline1: PropTypes.string,
  headline2: PropTypes.string,
  headlineDetail: PropTypes.string,
};

GettingStartedCard.defaultProps = {
  headline1: '',
  headline2: '',
  headlineDetail: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(GettingStartedCard);
