import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { StandardText } from 'Components/ShcText';


// Actions
import { MapSearchApi } from 'Actions';

// Notes
//https://stackoverflow.com/questions/42036865/react-how-to-navigate-through-list-by-arrow-keys


// TO DO
/*

> Regular expression library for validation of input from the users. Similar to php filters
> handler or ACTION** to get the data from the chips when required.

> Pass through styling on the component for some basic theme options (e.g. for header bar, page content, small size)
> Options for size of text, layout and other data fields (Photo - name - phone number) <-- example


//Create function for api calls (separate)
//Paramater to be passed in for what the listLookup will be (SUBURB_NAMES, or STREET_ADDRESS, etc )


*/
/*
<SelectInputChips 
  chips={...array}       
  chipsAsObjects={true}    
  dataDrivenChipsOnly={true}  
  maxChipSelection={10} 
  minTextBeforeApiCall={3}        
  smartSelectTextField="Some text to start the search of with..."
  placeholderText: "Custom placeholder"


  api="cool api 1"
  />



  API's
  > SUBURB_NAME_LOOKUP_AND_DATA_PROCESS
  > SUBURB_NAME_LOOKUP
  

*/

class SelectInputChipsApi extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    
    this.state = {         
      chips: props.chips,                               //Array of chips / tags / search selections
      chipsAsObjects: props.chipsAsObjects,             //true=chips are stored as objects, false=chips are a string
      dataDrivenChipsOnly: props.dataDrivenChipsOnly,   //true=force all chips to be only from the dataset (not freely typed in)
      maxChipListItems: props.maxChipListItems,         //Max chips that will be displayed, before hiding
      maxChipSelection: props.maxChipSelection,         //Max chips that a user can select
      minTextBeforeApiCall: props.minTextBeforeApiCall, //Number of characters required before an api is called
      expandValueList: props.expandValueList,           //Displaying the search results panel
      valueListData: props.valueListData,               //Search results data provided back from api
      popupChipList: props.popupChipList,               //Popup Chip List for small displays
      cursor: props.cursor,                             //Cursor position in list. -1 is textbox.
      smartSelectTextField: props.smartSelectTextField, //Text Input
      placeholderText: props.placeholderText,           //Paramater passed in via props
      loadingApi: props.loadingApi,                     //Loading notice for when the api is called and there is delay
      loadingApiError: props.loadingApiError,           //Error notice for when the api fails
      debug: props.debug,                               //Switch on debug (console out)

      allowDataProcessing: props.allowDataProcessing,    //Option to allow the status and process now button
      apiServiceRef: props.apiServiceRef,               //API Reference: SUBURB_NAME_LOOKUP

    };
  }

  componentDidMount(props) {
    if (this.state.debug) { console.log('componentDidMount.'); }
    this.smartSelectTextInput.focus();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.debug) { console.log('componentDidUpdate.'); }
    this.smartSelectTextInput.focus();
  }

  
  
  onClickRemoveChip(evt, itemIndex) {
    if (this.state.debug) { console.log('onClickRemoveChip(evt, '+itemIndex+')'); console.log(evt); }
    let chipsArray = [...this.state.chips]; // make a separate copy of the array
    chipsArray.splice(itemIndex, 1);
    this.setState({chips: chipsArray});
    evt.stopPropagation();
  }


  api_getSuburbListByTextSearchWithDataProcessing(body, accessToken, apiLoadingTimer, apiErrorNoticeClearTimer) {
    MapSearchApi.getSuburbListByTextSearch(body, accessToken)
      .then((results) => {
        if (this.state.debug) { console.log('MapSearchApi.getSuburbListByTextSearch results returned'); console.log(results.data); }
        clearTimeout(apiLoadingTimer);
        clearTimeout(apiErrorNoticeClearTimer);
        this.setState({
          valueListData: results.data,
          expandValueList: true,
          loadingApi: false,
          loadingApiError: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingApi: false,
          loadingApiError: true,
        });
        //Clear the api Error Notice after 4 seconds
        let apiErrorNoticeClearTimer = setTimeout(() => {
          this.setState({loadingApiError: false})
        }, 4000);

      })
  }

  api_requestSuburbDataProcessingById(body, accessToken) {
    //TEMP SWITCHED OFF
    console.log('calling API for request for suburb data processing');

    //CALL API

    //WITH RESULTS (update icon to "requested" and green)


  }




  api_getSuburbListByTextSearch(body, accessToken, apiLoadingTimer, apiErrorNoticeClearTimer) {
    MapSearchApi.getSuburbListByTextSearch(body, accessToken)
      .then((results) => {
        if (this.state.debug) { console.log('MapSearchApi.getSuburbListByTextSearch results returned'); console.log(results.data); }
        clearTimeout(apiLoadingTimer);
        clearTimeout(apiErrorNoticeClearTimer);
        this.setState({
          valueListData: results.data,
          expandValueList: true,
          loadingApi: false,
          loadingApiError: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingApi: false,
          loadingApiError: true,
        });
        //Clear the api Error Notice after 4 seconds
        let apiErrorNoticeClearTimer = setTimeout(() => {
          this.setState({loadingApiError: false})
        }, 4000);
      })
  }




  onChange = (evt) => {
    if (this.state.debug) { console.log('onChange(evt)'); console.log(evt); console.log('value: '+evt.target.value); }
    this.setState({
      cursor: -1,
      smartSelectTextField: evt.target.value,
      popupChipList: false,
    });  
    if (evt.target.value.length >= this.state.minTextBeforeApiCall) {
      // API //
      let apiErrorNoticeClearTimer;
      let apiLoadingTimer = setTimeout(() => {
        this.setState({loadingApi: true})
      }, 1000);


      let apiBody = {};
      apiBody['data'] = evt.target.value;
      let body = JSON.stringify(apiBody);
      let accessToken = "99999999999999999";


      /////////////////
      // API SWITCH  //
      /////////////////
      switch (this.state.apiServiceRef) {
        case 'SUBURB_NAME_LOOKUP_AND_DATA_PROCESS':
          return this.api_getSuburbListByTextSearchWithDataProcessing(body, accessToken, apiLoadingTimer, apiErrorNoticeClearTimer);
        case 'SUBURB_NAME_LOOKUP':
          return this.api_getSuburbListByTextSearch(body, accessToken, apiLoadingTimer, apiErrorNoticeClearTimer);
        default:
      }



      
      





      /*
      MapSearchApi.getSuburbListByTextSearch(evt.target.value)
      .then((results) => {
        if (this.state.debug) { console.log('MapSearchApi.getSuburbListByTextSearch results returned'); console.log(results.data); }
        clearTimeout(apiLoadingTimer);
        this.setState({
          valueListData: results.data,
          expandValueList: true,
          loadingApi: false,
        });
      })
      .catch((err) => {
        console.log(err);
        if (false === err instanceof Error &&  err.type && err.type === 'unparsable') {
            console.log("set icon"+err);
        }
      })
      */
    }
  } 

  //<i class="fas fa-exclamation"></i>

  createChipObjAndAdd(dataId, dataName) {
    if (this.state.debug) { console.log( 'createChipObjAndAdd('+dataId+', '+dataName+')' ); }
    //Version 1.0: id, name (required) [ {id:"132", name:"chip1"} ]
    //Create the chipObj
    let chipObj = {};
    chipObj['id'] = dataId;
    chipObj['name'] = dataName;
    return this.addItemObjToChips(chipObj);
  }

  uniqueChip = (chip, chipsArray) => {
    if (this.state.debug) { console.log( 'uniqueChip('+chip+', chipsArray)'); console.log(chipsArray); }
    return new Promise(function(resolve) {
      let uniqueChip = true;
      if (chipsArray.findIndex(k => k==chip) !== -1) {
        uniqueChip = false;
      }
      return uniqueChip;
    });
  }

  //NEW (determine if the chip being added is unique or not)
  uniqueChipObj = (chipObj, chipsArray) => {
    if (this.state.debug) { console.log( 'uniqueChipObj(chipObj, chipsArray)'); console.log(chipObj); console.log(chipsArray); }
    return new Promise(function(resolve) {
      let newChipId = chipObj.id;
      let uniqueChip = true;
      chipsArray.map(chip => { 
        //(state not accessible) if (this.state.debug) { console.log(chip.id+" === "+newChipId); }
        if (chip.id === newChipId) {
          uniqueChip = false;
        }
      })
      resolve(uniqueChip);
    });
  }

  addItemObjToChips(chipObj) {
    if (this.state.debug) { console.log( 'addItemObjToChips(chipObj)'); console.log(chipObj); }
    let chipsArray = [...this.state.chips]; // make a separate copy of the array
    this.uniqueChipObj(chipObj, chipsArray)
    .then((uniqueChip) => {
      if (this.state.debug) { console.log( 'uniqueChip: '+uniqueChip); }
      if (uniqueChip) {
        chipsArray.push(chipObj);
          this.setState({
            chips: chipsArray,
            smartSelectTextField: '',
            cursor: -1,
            expandValueList: false,
          });
      } else {
        this.setState({
          smartSelectTextField: '',
          cursor: -1,
          expandValueList: false,
        });
      }
    });
  }

  addItemToChips(chipItem) {
    if (this.state.debug) { console.log( 'addItemToChips('+chipItem+')'); }
    let chipsArray = [...this.state.chips]; // make a separate copy of the array
    if (chipsArray.findIndex(k => k==chipObj) === -1) {
      chipsArray.push(chipItem);
      this.setState({
        chips: chipsArray,
        smartSelectTextField: '',
        cursor: -1,
        expandValueList: false,
      });
    } else {
      this.setState({
        smartSelectTextField: '',
        cursor: -1,
        expandValueList: false,
      });
    }
  }

  toggleValueList = (evt) => {
    this.setState(prevState => ({
      expandValueList: !prevState.expandValueList
    }));
  }

  toggleChipPopupList = (evt) => {
    if (this.state.debug) { console.log( 'toggleChipPopupList' ); }
    this.setState(prevState => ({
      popupChipList: !prevState.popupChipList
    }));
  }


  
  handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      if (this.state.debug) { console.log( 'handleKeyPress' ); }
      if (this.state.cursor >=0) {
        if (this.state.debug) { console.log( 'enter pressed on list item (cursor >=0)' ); }
        let selectedValueListItem = this.state.valueListData.slice(this.state.cursor, this.state.cursor+1);
        let selectedValueListItemId = selectedValueListItem[0].id;
        let selectedValueListItemName = selectedValueListItem[0].name;
        //Handling chips as either objects or strings
        if (this.state.chipsAsObjects) {
          this.createChipObjAndAdd(selectedValueListItemId, selectedValueListItemName)
        } else {
          this.addItemToChips(selectedValueListItemName);
        }
      } else {
        if (this.state.debug) { console.log( 'enter pressed not on a list item. Determine action and validate input.' ); }
        // VALIDATION
        let userInput = evt.target.value;
        if (this.state.debug) { console.log(evt.target.value); }
        userInput = userInput.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');          //Input validation (create functions)
        if (this.state.debug) { console.log(userInput); }
        
        //Handling chips as either objects or strings
        if (this.state.chipsAsObjects) {
          if (this.state.dataDrivenChipsOnly) {
            //Do not allow the chip to be added (only from datasets allowed)
          } else {
            //this.createChipObjAndAdd("9999999", evt.target.value);      //id needs be unique to be added... (this would only work once)
          }
        } else {
          this.addItemToChips(evt.target.value);
          evt.target.value = "";
        }
      }
    }
  }



  handleKeyDown = (evt) => {
    if (this.state.debug) { console.log( 'handleKeyDown' ); }
    const { cursor, valueListData } = this.state
    if (this.state.debug) {
      console.log(evt.keyCode);
      console.log(cursor);
      console.log(valueListData);
    }

    if (evt.keyCode === 38 && cursor === 0) {
      if (this.state.debug) { console.log('Cursor returning to text box'); console.log('up: WAS: '+ this.state.cursor); }
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    }

    if (evt.keyCode === 38 && cursor > 0) {
      if (this.state.debug) { console.log('up: WAS: '+ this.state.cursor); }
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }),
        () => {
          if (this.state.debug) { console.log('up: NOW: '+ this.state.cursor); }
          this.updateTextFieldFromList(this.state.cursor);
        }
      )
      
    } else if (evt.keyCode === 40 && cursor < valueListData.length - 1) {
      if (this.state.debug) { console.log('down: WAS: '+ this.state.cursor); }
      this.setState( prevState => ({
        cursor: prevState.cursor + 1,
      }),
        () => {
          if (this.state.debug) { console.log('down: NOW: '+ this.state.cursor); }
          this.updateTextFieldFromList(this.state.cursor);
        }
      )
    }
  }



  updateTextFieldFromList(cursor) {
    if (this.state.debug) { console.log('updateTextFieldFromList('+cursor+')'); }
    let value = this.state.valueListData.slice(cursor, cursor+1);
    let valueName = value[0].name;
    //This part of the function isn't currently used.
  }
  
  onClickValueListItem = (evt, index) => {
    if (this.state.debug) { console.log('onClickValueListItem(evt, '+index+')'); }
    let selectedValueListItem = this.state.valueListData.slice(index, index+1);
    let selectedValueListItemId = selectedValueListItem[0].id;
    let selectedValueListItemName = selectedValueListItem[0].name;
    //Handling chips as either objects or strings
    if (this.state.chipsAsObjects) {
      this.createChipObjAndAdd(selectedValueListItemId, selectedValueListItemName)
    } else {
      this.addItemToChips(selectedValueListItemName);
    }
  }



  onClickProcessData(evt, dataId) {
    //TEMP SWITCHED OFF
    evt.persist();  //testing
    evt.stopPropagation();
    console.log('---- VALUE LIST PROCESS DATA CLICK -----');
    console.log('API Call with ID: '+ dataId);
    console.log(evt);
    switch (this.state.apiServiceRef) {
      case 'SUBURB_NAME_LOOKUP_AND_DATA_PROCESS':
        /*
        let apiErrorNoticeClearTimer;
        let apiLoadingTimer = setTimeout(() => {
          this.setState({loadingApi: true})
        }, 1000);
        */

        let apiBody = {};
        apiBody['id'] = dataId;
        let body = JSON.stringify(apiBody);
        let accessToken = "99999999999999999";
        return this.api_requestSuburbDataProcessingById(body, accessToken);

        //THEN change to green for the button



      default:
    }
      
  }


  render() {
    const { classes } = this.props;
    const {chips, valueListData, cursor, maxChipListItems, popupChipList, chipsAsObjects } = this.state;

    const ListOfChips = () => {
      return chips.map((value, index) => {
        if (chipsAsObjects) {
          return (
            <div key={index} className={classNames({smartSelectChipItem: true})}>
              <div className={classNames({smartSelectChipItemText: true})}>
                {value.name}
              </div>
              <div className={classNames({smartSelectChipItemIcon: true})}
              onClick={(evt) => {
                this.onClickRemoveChip(evt, index);
              }}
              >
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          )
        } else {
          return (
            <div key={index} className={classNames({smartSelectChipItem: true})}>
              <div className={classNames({smartSelectChipItemText: true})}>
                {value}
              </div>
              <div className={classNames({smartSelectChipItemIcon: true})}
              onClick={(evt) => {
                this.onClickRemoveChip(evt, index);
              }}
              >
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          )
        }
      })
    } 


    const TooManyChips = () => {
      return (
        <div className={classNames({smartSelectChipsPopupView: true})}>
          <div className={classNames({smartSelectChipPopupIcon: true})}>
            {this.state.popupChipList ?
              <i class="fas fa-times-circle"></i>
            :
              chips.length
            }
          </div>
        </div>
      )
    } 

    const TooManyChipsPopupList = () => {
      return chips.map((value, index) => {
        if (chipsAsObjects) {
          return (
            <li key={index}>
              <div  className={classNames({smartSelectChipItem: true})}>
                <div className={classNames({smartSelectChipItemText: true})}>
                  {value.name}
                </div>
                <div className={classNames({smartSelectChipItemIcon: true})}
                onClick={(evt) => {
                  this.onClickRemoveChip(evt, index);
                }}
                >
                  <i class="fas fa-times-circle"></i>
                </div>
              </div>
            </li>
          )
        } else {
          return (
            <li key={index}>
              <div  className={classNames({smartSelectChipItem: true})}>
                <div className={classNames({smartSelectChipItemText: true})}>
                  {value}
                </div>
                <div className={classNames({smartSelectChipItemIcon: true})}
                onClick={(evt) => {
                  this.onClickRemoveChip(evt, index);
                }}
                >
                  <i class="fas fa-times-circle"></i>
                </div>
              </div>
            </li>
          )
        }
      })
    }

    const ValueList = () => {
      let highlight = false;
      return valueListData.map((value, index) => {
        return (
          <li key={index} className={classNames({smartSelectValueListItem: true, smartSelectValuelistItemActive: cursor === index }) }
          onClick={(evt) => {
            this.onClickValueListItem(evt, index);
          }}
          data-id={value['id']}
          data-name={value['name']}
          >
            <div className={classNames({smartSelectValueListItem_displayContainer: true})} >
              <div>
                {value['name']}
              </div>
            </div>
          </li>
        )
      })
    }

    const ValueListDataProcessing = () => {
      let highlight = false;
      return valueListData.map((value, index) => {
        return (
          <li key={index} className={classNames({smartSelectValueListItem: true, smartSelectValuelistItemActive: cursor === index }) }
          onClick={(evt) => {
            this.onClickValueListItem(evt, index);
          }}
          data-id={value['id']}
          data-name={value['name']}
          >
            <div className={classNames({smartSelectValueListItem_displayContainer: true})} >
              <div>
                {value['name']}
              </div>
              <div className={classNames({
                smartSelectValueListItem_displaySubContainer: true
              })}>
                 
                <StandardText
                  icon=""
                  iconLocation=""
                  text="Unavailable"
                  tooltip=""
                  action=""
                  classes="shctext shctext-default-danger shctext-rounded shctext-tiny"
                />
                
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Process Now (Free)"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-tiny"
                   handleClick={(evt) => {
                    this.onClickProcessData(evt, value['id']);
                  }}
                  
                  
                />
                
                
              </div>
            </div>
          </li>
        )
      })
    }

    

    const ChipPanel = () => {
      return (
        <div className={classNames({smartSelectChips: true})}>
          {chips.length > maxChipListItems ?
            <div 
              onClick={(evt) => {
                this.toggleChipPopupList(evt);
              }}
            >
              <TooManyChips/>
            </div>
          :
            <ListOfChips/>
          }
        </div>
      )
    }

    const TextPanel = () => {
      return (
        <div className={classNames({smartSelectText: true})}>
          <input type="text"
            ref={(smartSelectTextRef) => { this.smartSelectTextInput = smartSelectTextRef; }} 
            autofocus
            className={classNames({smartSelectTextStyle: true})}
            fullwidth
            value={this.state.smartSelectTextField}
            placeholder={this.state.placeholderText}
            InputProps={{
              disableUnderline: true,
              autoFocus: true,
              placeholder: this.state.placeholderText,
            }}
            onKeyPress={(evt) => {
              this.handleKeyPress(evt);
            }}
            onChange={(evt) => {
              this.onChange(evt);
            }}
            onKeyDown={(evt) => {
              this.handleKeyDown(evt);
            }}
          />
        </div>
      )
    }

    const LoadingNoticePanel = () => {
      return (
        <div className={classNames({smartSelectLoadingNotice: true})}>
        {this.state.loadingApi ?
          <i class="fas fa-circle-notch fa-spin"></i>
        :
          null
        }
        </div>
      )
    }

    const ErrorNoticePanel = () => {
      return (
        <div className={classNames({smartSelectErrorNotice: true})}>
        {this.state.loadingApiError ?
          <i class="fas fa-exclamation"></i>
        :
          null
        }
        </div>
      )
    }

    const ListViewControlPanel = () => {
      return (
        <div className={classNames({smartSelectControls: true})}
          onClick={(evt) => {
            this.toggleValueList(evt);
          }}
        >
        {this.state.expandValueList ?
          <i class="fas fa-angle-up"></i>
        :
          <i class="fas fa-angle-down"></i>
        }
        </div>
      )
    }

    const ValueListDropdownPanel = () => {
      return (
        <div className={classNames({hidden: !this.state.expandValueList, smartSelectWrapperValueList: true})}>
          <ul className={classNames({smartSelectValueListCollection: true})}>
            {this.state.allowDataProcessing ? 
              <ValueListDataProcessing/>
            :
              <ValueList/>
            }
          </ul>
        </div>
      )
    }

    const ChipPopupListPanel = () => {
      return (
        <div className={classNames({hidden: !this.state.popupChipList, smartSelectWrapperChipPopupList: true,  hidden_ruleMinChipsInPopup: chips.length <= maxChipListItems })}>
            <ul className={classNames({smartSelectChipPopupListCollection: true})}>
              <TooManyChipsPopupList/>
            </ul>
        </div>
      )
    }


    return (
      <div className={classNames({ pageWrapper: false })}>
        <div className={classNames({smartSelectWrapper: true, smartSelectExpander: true})}>
          <ChipPanel/>
          <TextPanel/>
          <ErrorNoticePanel/>
          <LoadingNoticePanel/>
          <ListViewControlPanel/>
          <ValueListDropdownPanel/>
          <ChipPopupListPanel/>
        </div>
      </div>

    );
  }
}

SelectInputChipsApi.propTypes = {
  debug: PropTypes.bool,
  chips: PropTypes.array,

};

SelectInputChipsApi.defaultProps  = {
  debug: false,
  chips: [],               
  chipsAsObjects: true,    
  dataDrivenChipsOnly: true,  
  maxChipListItems: 2,   
  maxChipSelection: 15,   
  minTextBeforeApiCall: 3, 
  expandValueList: false,   
  valueListData: [],       
  popupChipList: false,    
  cursor: -1,               
  smartSelectTextField: "",
  placeholderText: "Search", 
  loadingApi: false,
  loadingApiError: false,

  apiServiceRef: null,
  allowDataProcessing: false,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SelectInputChipsApi);
