import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class InputCardHeader extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineText: props.headlineText,
      height: props.height,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { classes } = this.props;
    const {
      headlineText, 
      height,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
    };

    return (
      <div >
        <div className={classNames({ ShcInputContainer: true })}>
          <div className={classNames({ ShcInputContainer_header: true })}>
            <div className={classNames({ null: true })}>
              {headlineText}
            </div>
            <div className={classNames({ ShcInputContainer_headerSecondaryText: true })}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

InputCardHeader.propTypes = {
  headlineText: PropTypes.string,
};

InputCardHeader.defaultProps = {
  headlineText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputCardHeader);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
