import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
//import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem } from 'Components/ShcCards';

// import { askForPermissioToReceiveNotifications } from 'Components/ShcPushNotify/push-notification';


// Actions
import { refreshAccessToken, updateIsAuthenticatedStatus, userAuth, userAuthEmail, setuserlogout } from 'Actions';
import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function unixTimestampMillisecondsToSeconds(timestamp) {
  let num = timestamp;
  if (num.toString().length >=13) {
    //In milliseconds
    num = (num-(num%1000))/1000;
  }
  return num;
}

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      resetPasswordComplete: false,
      resetPasswordScreen: this.props.resetPasswordScreen,        //Main Login Screen
      redirectScreen: this.props.redirectScreen,    //Redirect Screen
      resetPasswordLoading: this.props.resetPasswordLoading,      //Transition state from LoginScreen

      user: null,
      emailUsername: '',
      isAuthenticated: this.props.isAuthenticated,
      errorCode: '',
      errorMessage: '',
    };

    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleEmailUsernameInput = this.handleEmailUsernameInput.bind(this);
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }



  componentDidMount(props) {


  }

  componentDidUpdate() {

  }




  handleForgotPasswordClick(e) {
    console.log(`Forgot password process for ${this.state.emailUsername}`);
    this.setState({ 
      resetPasswordScreen: false,
      resetPasswordLoading: true,
     });
 
    if (this.state.emailUsername == '') {
      // Popup asking for email address.
      console.log('no email, popup and request user to enter email.');
      toast.error('No email found. Please enter your email in the form before requesting your password reset.', {
        closeOnClick: true,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        className: 'toastError',
        bodyClassName: 'grow-font-size',
        progressClassName: 'toastErrorProgress',
      });
      this.setState({ 
        resetPasswordScreen: true,
        resetPasswordLoading: false,
       });
    } else if (validateEmail(this.state.emailUsername.trim())) {
      auth().sendPasswordResetEmail(this.state.emailUsername.trim())
        .then((result) => {
          // Password reset email sent.
          toast.success('Password reset email sent.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastSuccess',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastSuccessProgress',
          });
          this.setState({ 
            resetPasswordScreen: false,
            resetPasswordLoading: false,
            resetPasswordComplete: true,
          });
          //Timer, reset to normal state... TODO


        })
        .catch((error) => {
          console.log('Forgot password email send error.');
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);

          if (errorCode === 'auth/user-not-found') {
            toast.error('User not found', {
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              className: 'toastError',
              bodyClassName: 'grow-font-size',
              progressClassName: 'toastErrorProgress',
            });
          } else {
            toast.error('There was an error sending your password reset email. Please try again later.', {
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              className: 'toastError',
              bodyClassName: 'grow-font-size',
              progressClassName: 'toastErrorProgress',
            });
          }

          this.setState({ 
            resetPasswordScreen: true,
            resetPasswordLoading: false,
           });

        });

    } else {
      // Invalid email, notify to fix the issue.
      console.log('Forgot password invalid email.');
      toast.error('Invalid email address.', {
        closeOnClick: true,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        className: 'toastError',
        bodyClassName: 'grow-font-size',
        progressClassName: 'toastErrorProgress',
      });
      this.setState({ 
        resetPasswordScreen: true,
        resetPasswordLoading: false,
       });

    }
  }

  handleEmailUsernameInput(e) {
    console.log('setting username');
    this.setState({ emailUsername: e.target.value });
  }
  componentWillUnmount() {
  }




  render() {
    const { errorMessage } = this.state;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          
        {this.state.resetPasswordScreen === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Forgot Password" headlineSubText="Reset your password.">

              <InputCardItem headlineText="Username" headlineSubText="Please enter your email address for your password reset" additionalText="">
                <InputTextV2
                  id="emailusername"
                  name="emailusername"
                  value={this.state.emailUsername}
                  label="Username"
                  onChangeInputTextHandler={this.handleEmailUsernameInput}
                  />
              </InputCardItem>

              { errorMessage !== '' && (
              <StandardText
                icon=""
                iconLocation=""
                text={errorMessage}
                tooltip=""
                action=""
                classes="shctext shctext-default-danger shctext-rounded shctext-medium shctext-fullwidth"
                    />
                )
                }

              <div>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Reset password"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                  handleClick={this.handleForgotPasswordClick}
                  />
              </div>

              <div>
                <Link to="/sign-up" className={classNames({ noLinkStyles: true })}>
                  <StandardText
                    icon=""
                    iconLocation=""
                    text="Sign up here"
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
                    />
                </Link>
              </div>
              <div>
                <Link to="/login" className={classNames({ noLinkStyles: true })}>
                  <StandardText
                    icon=""
                    iconLocation=""
                    text="Login"
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
                    />
                </Link>
              </div>


            </InputCard>
          </div>
        )}
        {this.state.resetPasswordLoading === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Forgot Password" headlineSubText="Reset your password.">
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s40: true })}>
                  <i className="fas fa-circle-notch fa-spin" />
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s20: true })}>
                Sending reset procedure to your email address
                </div>
              </div>
            </InputCard>
          </div>
        )}

        {this.state.resetPasswordComplete === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Forgot Password" headlineSubText="Reset your password.">
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s40: true })}>
                  <i className="far fa-check-circle" />
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s20: true })}>
                Successfully sent you an email
                </div>
              </div>
            </InputCard>
          </div>
        )}

        </div>
        
      </div>
    );
  }
}

Template.propTypes = {
};






const mapStateToProps = (state, ownProps) => {
  let resetPasswordScreen = true;
  let redirectScreen = false;
  let resetPasswordLoading = false;

  


  return {
    resetPasswordScreen,
    redirectScreen,
    resetPasswordLoading,

    isAuthenticated: state.Auth.isAuthenticated,
    apiAccessTokenExpiresOn: state.Auth.apiAccessTokenExpiresOn,
    apiRefreshTokenExpiresOn: state.Auth.apiRefreshTokenExpiresOn,
  };

};


const mapDispatchToProps = {
  refreshAccessToken,
  updateIsAuthenticatedStatus,

  userAuth,
  userAuthEmail,
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);