import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Select from 'react-select';
import Dropdown from 'react-dropdown';

import ReCAPTCHA from "react-google-recaptcha";


import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';

import update from 'immutability-helper';

const ct = require('countries-and-timezones');


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextareaV1, IdentityInputV1, IdentityInputV2, CheckboxOptionCardReduxV1, 
  RadioOptionCardV1, CheckboxOptionCardV2, LinkCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';



//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { createSupportCaseInteraction } from 'Actions';


// Functions
import { validateEmail, apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    
  
    //CATEGORY BILLING SUPPORT
    this.supportCaseBilling_selectInput_caseClassificationType = this.supportCaseBilling_selectInput_caseClassificationType.bind(this);
    this.supportCaseBilling_checkInput_requester_user = this.supportCaseBilling_checkInput_requester_user.bind(this);
    this.supportCaseBilling_checkInput_requester_business = this.supportCaseBilling_checkInput_requester_business.bind(this);
    this.supportCaseBilling_selectInput_requester_businessOption = this.supportCaseBilling_selectInput_requester_businessOption.bind(this);

    this.supportCaseBilling_requesterName = this.supportCaseBilling_requesterName.bind(this);
    this.supportCaseBilling_requesterEmail = this.supportCaseBilling_requesterEmail.bind(this);

    this.supportCaseBilling_messageSubject = this.supportCaseBilling_messageSubject.bind(this);
    this.supportCaseBilling_messageDescription = this.supportCaseBilling_messageDescription.bind(this);


    //CATEGORY TECHNICAL SUPPORT
    this.supportCaseTechnical_selectInput_caseClassificationType = this.supportCaseTechnical_selectInput_caseClassificationType.bind(this);
    this.supportCaseTechnical_checkInput_requester_user = this.supportCaseTechnical_checkInput_requester_user.bind(this);
    this.supportCaseTechnical_checkInput_requester_business = this.supportCaseTechnical_checkInput_requester_business.bind(this);
    this.supportCaseTechnical_selectInput_requester_businessOption = this.supportCaseTechnical_selectInput_requester_businessOption.bind(this);

    this.supportCaseTechnical_requesterName = this.supportCaseTechnical_requesterName.bind(this);
    this.supportCaseTechnical_requesterEmail = this.supportCaseTechnical_requesterEmail.bind(this);

    this.supportCaseTechnical_messageSubject = this.supportCaseTechnical_messageSubject.bind(this);
    this.supportCaseTechnical_messageDescription = this.supportCaseTechnical_messageDescription.bind(this);


    //SHARED LODGEMENT
    this.logSupportCase = this.logSupportCase.bind(this);
    
    //RECAPTCHA
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
    this.onReCaptchaExpired = this.onReCaptchaExpired.bind(this);
    this.onReCaptchaErrored = this.onReCaptchaErrored.bind(this);



    this.state = { 
      captchaStatus: false,
      supportCaseLogged: false,                       //Whether the support case has been officially lodged.


      //CUSTOMER DETAILS
      customerIsAuth: this.props.isAuthenticated,        //Whether a logged in user or not

      //CUSTOMER AUTHED
      customerLoggedInId: this.props.userId,             //This will be available on the case lodgement JWT (which is where we will verify it from)
      customerLoggedInFirstName: this.props.userFirstName,

      //CASE DETAILS
      supportCaseCategory: this.props.supportCaseCategory,

      //CATEGORY BILLING SUPPORT
      supportCaseBilling_caseType: '',
      supportCaseBilling_user: false,
      supportCaseBilling_business: false,
      supportCaseBilling_businessDetail: '',
      supportCaseBilling_messageSubject: '',
      supportCaseBilling_messageDescription: '',

      supportBillingInputErrors: false,
      supportBillingInputErrorList: [],

      //CUSTOMER NOT AUTHED
      supportCaseBilling_customerContactName: '',
      supportCaseBilling_customerContactEmail: '',

      //CATEGORY TECHNICAL SUPPORT
      supportCaseTechnical_caseType: '',
      supportCaseTechnical_user: false,
      supportCaseTechnical_business: false,
      supportCaseTechnical_businessDetail: '',
      supportCaseTechnical_messageSubject: '',
      supportCaseTechnical_messageDescription: '',

      supportTechnicalInputErrors: false,
      supportTechnicalInputErrorList: [],

      //CUSTOMER NOT AUTHED
      supportCaseTechnical_customerContactName: '',
      supportCaseTechnical_customerContactEmail: '',

    



      businessId: this.props.match.params.routebusinessid,
      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)




      businessName: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountBusinessDashboardPage");
    //console.log("prevState");
    //console.log(prevState);
    //console.log("nextProps");
    //console.log(nextProps);

    let updateState=false;

    let supportCaseCategory = prevState.supportCaseCategory;
    if (nextProps.supportCaseCategory !== prevState.supportCaseCategory) {
      updateState=true;
      supportCaseCategory = nextProps.supportCaseCategory;
    }

    if (updateState===true) {
      
      return {
        supportCaseCategory,
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    this.setState({
      captchaStatus: false,
    });


  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate");
    //console.log("forceUpdate()");
    this.forceUpdate();
   
  }

  onReCaptchaChange(value) {
    this.setState({
      captchaStatus: true,
    });
  }
  onReCaptchaExpired() {
    this.setState({
      captchaStatus: false,
    });
  }
  onReCaptchaErrored() {
    this.setState({
      captchaStatus: false,
    });
  }

  /*****************************************************************
   * SUPPORT CASE - BILLING 
   ****************************************************************/

  supportCaseBilling_selectInput_caseClassificationType(evt) {
    let value = evt.value;
    this.setState({
      supportCaseBilling_caseType: value,
    });
  }

  supportCaseBilling_checkInput_requester_user() {
    this.setState(prevState => ({
      supportCaseBilling_user: !prevState.supportCaseBilling_user
    }));
  }

  supportCaseBilling_checkInput_requester_business() {
    //For true, which will toggle to false, remove the business detail item
    if (this.state.supportCaseBilling_business === true) {
      this.setState(prevState => ({
        supportCaseBilling_business: !prevState.supportCaseBilling_business,
        supportCaseBilling_businessDetail: '', 
      }));
    } else {
      this.setState(prevState => ({
        supportCaseBilling_business: !prevState.supportCaseBilling_business
      }));
    }
  }

  supportCaseBilling_selectInput_requester_businessOption(evt) {
    let value = evt.value;
    this.setState({
      supportCaseBilling_businessDetail: value,
    });
  }

  supportCaseBilling_requesterName(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseBilling_customerContactName: value,
    });
  }
  supportCaseBilling_requesterEmail(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseBilling_customerContactEmail: value,
    });
  }

  supportCaseBilling_messageSubject(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseBilling_messageSubject: value,
    });
  }

  supportCaseBilling_messageDescription(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseBilling_messageDescription: value,
    });
  }

  /*****************************************************************
   * SUPPORT CASE - TECHNICAL 
   ****************************************************************/

  
  supportCaseTechnical_selectInput_caseClassificationType(evt) {
    let value = evt.value;
    this.setState({
      supportCaseTechnical_caseType: value,
    });
  }

  supportCaseTechnical_checkInput_requester_user() {
    this.setState(prevState => ({
      supportCaseTechnical_user: !prevState.supportCaseTechnical_user
    }));
  }

  supportCaseTechnical_checkInput_requester_business() {
    //For true, which will toggle to false, remove the business detail item
    if (this.state.supportCaseTechnical_business === true) {
      this.setState(prevState => ({
        supportCaseTechnical_business: !prevState.supportCaseTechnical_business,
        supportCaseTechnical_businessDetail: '', 
      }));
    } else {
      this.setState(prevState => ({
        supportCaseTechnical_business: !prevState.supportCaseTechnical_business
      }));
    }
  }

  supportCaseTechnical_selectInput_requester_businessOption(evt) {
    let value = evt.value;
    this.setState({
      supportCaseTechnical_businessDetail: value,
    });
  }

  supportCaseTechnical_requesterName(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseTechnical_customerContactName: value,
    });
  }
  supportCaseTechnical_requesterEmail(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseTechnical_customerContactEmail: value,
    });
  }

  supportCaseTechnical_messageSubject(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseTechnical_messageSubject: value,
    });
  }

  supportCaseTechnical_messageDescription(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      supportCaseTechnical_messageDescription: value,
    });
  }


  


  /*****************************************************************
   * SUPPORT CASE - COMBINED - LODGEMENT
   ****************************************************************/
 
  logSupportCase(evt) {
    //Need to cancel the apiActivityId via redux if the form fails out.
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    

    console.log("===== logSupportCase ======");
    //console.log(JSON.stringify(this.state));

    let errors = [];
    let allowCaseLodgement = true;

    
    

    let customerIsAuth = this.state.customerIsAuth;
    
    if (this.state.supportCaseCategory === 'accountBilling') {
      if (this.state.captchaStatus === true) {
        let supportCaseBilling_customerContactName = this.state.supportCaseBilling_customerContactName;
        let supportCaseBilling_customerContactEmail = this.state.supportCaseBilling_customerContactEmail;
        if (customerIsAuth === false) {
          //Ensure we have some contact details. 
          if (supportCaseBilling_customerContactName.length <= 1) {
            errors.push({
              message: 'Please enter a valid customer name',
            });
            allowCaseLodgement = false;
          }
          if (supportCaseBilling_customerContactEmail.length <= 3) {
            errors.push({
              message: 'Please enter a valid email address. Too short.',
            });
            allowCaseLodgement = false;
          } else {
            if (validateEmail(supportCaseBilling_customerContactEmail) === false) {
              errors.push({
                message: 'Please enter a valid email address',
              });
              allowCaseLodgement = false;
            }
          }
        }
        if (this.state.supportCaseBilling_caseType === "") {
          errors.push({
            message: 'Please select a case classification',
          });
          allowCaseLodgement = false;
        }
        if (this.state.supportCaseBilling_messageSubject === "") {
          errors.push({
            message: 'Please provide a message subject',
          });
          allowCaseLodgement = false;
        }
        if (this.state.supportCaseBilling_messageDescription === "") {
          errors.push({
            message: 'Please provide a message description',
          });
          allowCaseLodgement = false;
        }

        if (allowCaseLodgement === true) {
          this.setState({
            supportBillingInputErrors: false,
            supportBillingInputErrorList: [],
          });

          this.props.createSupportCaseInteraction({
            apiUi,
            supportCaseCategory: this.state.supportCaseCategory,
            caseType: this.state.supportCaseBilling_caseType,
            user: this.state.supportCaseBilling_user,
            business: this.state.supportCaseBilling_business,
            businessDetail: this.state.supportCaseBilling_businessDetail,
      
            messageSubject: this.state.supportCaseBilling_messageSubject,
            messageDescription: this.state.supportCaseBilling_messageDescription,
    
            customerIsAuth: this.state.customerIsAuth,
            customerLoggedInId: this.state.customerLoggedInId,
            customerLoggedInFirstName: this.state.customerLoggedInFirstName,
      
            customerContactName: this.state.supportCaseBilling_customerContactName,
            customerContactEmail: this.state.supportCaseBilling_customerContactEmail,
      
          });



        } else {
          this.setState({
            supportBillingInputErrors: true,
            supportBillingInputErrorList: errors,
          });
        }
      } else {
        errors.push({
          message: 'Please complete Captcha Security.',
        });
        this.setState({
          supportBillingInputErrors: true,
          supportBillingInputErrorList: errors,
        });
      }



    } else if (this.state.supportCaseCategory === 'technicalSupport') {
      if (this.state.captchaStatus === true) {
        let supportCaseTechnical_customerContactName = this.state.supportCaseTechnical_customerContactName;
        let supportCaseTechnical_customerContactEmail = this.state.supportCaseTechnical_customerContactEmail;
        if (customerIsAuth === false) {
          //Ensure we have some contact details. 
          if (supportCaseTechnical_customerContactName.length <= 1) {
            errors.push({
              message: 'Please enter a valid customer name',
            });
            allowCaseLodgement = false;
          }
          if (supportCaseTechnical_customerContactEmail.length <= 3) {
            errors.push({
              message: 'Please enter a valid email address. Too short.',
            });
            allowCaseLodgement = false;
          } else {
            if (validateEmail(supportCaseTechnical_customerContactEmail) === false) {
              errors.push({
                message: 'Please enter a valid email address',
              });
              allowCaseLodgement = false;
            }
          }
        }
        if (this.state.supportCaseTechnical_caseType === "") {
          errors.push({
            message: 'Please select a case classification',
          });
          allowCaseLodgement = false;
        }
        if (this.state.supportCaseTechnical_messageSubject === "") {
          errors.push({
            message: 'Please provide a message subject',
          });
          allowCaseLodgement = false;
        }
        if (this.state.supportCaseTechnical_messageDescription === "") {
          errors.push({
            message: 'Please provide a message description',
          });
          allowCaseLodgement = false;
        }

        if (allowCaseLodgement === true) {
          this.setState({
            supportTechnicalInputErrors: false,
            supportTechnicalInputErrorList: [],
          });
          this.props.createSupportCaseInteraction({
            apiUi,
            supportCaseCategory: this.state.supportCaseCategory,
            caseType: this.state.supportCaseTechnical_caseType,
            user: this.state.supportCaseTechnical_user,
            business: this.state.supportCaseTechnical_business,
            businessDetail: this.state.supportCaseTechnical_businessDetail,
      
            messageSubject: this.state.supportCaseTechnical_messageSubject,
            messageDescription: this.state.supportCaseTechnical_messageDescription,
    
            customerIsAuth: this.state.customerIsAuth,
            customerLoggedInId: this.state.customerLoggedInId,
            customerLoggedInFirstName: this.state.customerLoggedInFirstName,
      
            customerContactName: this.state.supportCaseTechnical_customerContactName,
            customerContactEmail: this.state.supportCaseTechnical_customerContactEmail,
      
          });

        } else {
          this.setState({
            supportTechnicalInputErrors: true,
            supportTechnicalInputErrorList: errors,
          })
        }
      } else {
        errors.push({
          message: 'Please complete Captcha Security.',
        });
        this.setState({
          supportTechnicalInputErrors: true,
          supportTechnicalInputErrorList: errors,
        });
      }
      
    }


    //End Support Function
  }





  render() {

    const { businessId } = this.state;
    const { userBusinessRoutePermissions, businessIdentities, businessAccountPostClosure, businessAccountClosed } = this.props;

    //Get List of All Countries
    const countries = ct.getAllCountries();
    //console.log("======================countries=====================");
    //console.log(countries);
    let countryOptionList = [];
    for (let [key, value] of Object.entries(countries)) {
      countryOptionList.push({ value: `${value.id}`, label: `${value.name}` });
    }

    //Get List of All TimeZones
    const timezones = ct.getAllTimezones();
    //console.log(timezones);
    let timezoneOptionList = [];
    for (let [key, value] of Object.entries(timezones)) {
      timezoneOptionList.push({ value: `${value.name}`, label: `${value.name}` });
    }


    let timeOptions = [];
    for (let t = 0; t <= 24; t++) {
      let hour = `0${t}`.slice(-2);
      timeOptions.push({ value: `${hour}:00`, label: `${hour}:00` });
      if (t !== 24) {
        timeOptions.push({ value: `${hour}:30`, label: `${hour}:30` });
      }
    }

    //////////////////////////////////////////
    // ACCOUNT & BILLING

    let supportCase_accountBilling_Classification_Options = [
      {value: 'account', label: 'Account & Account Management'},
      {value: 'billing', label: 'Billing'},
      {value: 'general', label: 'General Info and Getting Started'},
    ];

    let supportCase_technicalSupport_Classification_Options = [
      {value: 'website', label: 'Website'},
      {value: 'mobileApp', label: 'Mobile App'},
      {value: 'process', label: 'Process'},
      {value: 'other', label: 'Other'},
    ];




    //// CATEOGRY - BILLING ////
    let supportCaseBilling_businessList = [
      {value: 'businessesAll', label: 'Applies to all businesses'},
      {value: 'businessesNotListed', label: 'Applies to a business not listed'},
    ]; 
    for (let x = 0; x < this.props.managedBusinessDetails.length; x++) {
      supportCaseBilling_businessList.push({
        value: this.props.managedBusinessDetails[x].id, label: this.props.managedBusinessDetails[x].name
      })
    }

    //// CATEOGRY - TECHNICAL ////
    let supportCaseTechnical_businessList = [
      {value: 'businessesAll', label: 'Applies to all businesses'},
      {value: 'businessesNotListed', label: 'Applies to a business not listed'},
    ];
    for (let x = 0; x < this.props.managedBusinessDetails.length; x++) {
      supportCaseTechnical_businessList.push({
        value: this.props.managedBusinessDetails[x].id, label: this.props.managedBusinessDetails[x].name
      })
    }

    



    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/support"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business/:routebusinessid"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle=""
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Support Centre"
                linkGroupSelectionTF="true"
                linkGroupSelection="support"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            {this.state.supportCaseLogged === true && (
              <InputCard headlineText="Case Lodged" headlineSubText="">
                We have lodged your support case. We will contact you as soon as possible.<br/>
                Case ID: 239402839482940
              </InputCard>
            )}

            <div>
              <div class="shcGrid290">

                <div class="shcGridItem">
                  <RadioOptionCardV1
                    businessId=""
                    radioOptionsGroup="supportCaseSelection"
                    radioOptionsId="accountBilling"
                    headlineText="Account and billing support"
                    secondaryText="Billing related assistance"
                    onSelectHandler=""
                    />
                </div>
                
                <div class="shcGridItem">
                  <RadioOptionCardV1
                    businessId=""
                    radioOptionsGroup="supportCaseSelection"
                    radioOptionsId="technicalSupport"
                    headlineText="Technical support"
                    secondaryText="Technical issues with website and mobile app"
                    onSelectHandler=""
                    />
                </div>
                
              </div>
            </div>

            { this.props.selectedSupportCase.radioOptionsGroup === 'supportCaseSelection' &&
              this.props.selectedSupportCase.selectedRadioOptionsId === 'accountBilling' ?
              (
                <div>
                  <InputCard headlineText="Case classification" headlineSubText="">
 
                     <InputCardItem headlineText="Type" headlineSubText="" additionalText="">
        
                      <div className={classNames({ flex: true,})}>
                        <div className={classNames({ minWidth350: true })}>

                          <Dropdown 
                          options={supportCase_accountBilling_Classification_Options} 
                          onChange={this.supportCaseBilling_selectInput_caseClassificationType} 
                          value={supportCase_accountBilling_Classification_Options.find(option => option.value === this.state.supportCaseBilling_caseType)}
                          placeholder="Select an option" />

                        </div>
                      </div>
                    
                    </InputCardItem>
                  </InputCard>

                  <InputCard headlineText="Requester details" headlineSubText="">
                    {this.props.isAuthenticated === true ? (
                      <div>
                        <InputCardItem headlineText="Requester" headlineSubText="" additionalText="">
                          {this.state.customerLoggedInFirstName} (ID: {this.state.customerLoggedInId})
                        </InputCardItem>


                        <InputCardItem headlineText="Request Relates to" headlineSubText="" additionalText="">
                          <div>
                            <CheckboxOptionCardV2
                            businessId=""
                            checkboxText="My account"
                            checkboxState={this.state.supportCaseBilling_user}
                            onChangeHandler={this.supportCaseBilling_checkInput_requester_user}
                            //onChangeHandlerAction2
                            />
                          </div>
                          <div>
                            <CheckboxOptionCardV2
                            businessId=""
                            checkboxText="A business account"
                            checkboxState={this.state.supportCaseBilling_business}
                            onChangeHandler={this.supportCaseBilling_checkInput_requester_business}
                            //onChangeHandlerAction2
                            />
                          </div>
                        </InputCardItem>

                        {this.state.supportCaseBilling_business === true && (
                          <InputCardItem headlineText="Select Business Details" headlineSubText="" additionalText="">

                            <div className={classNames({ flex: true,})}>
                              <div className={classNames({ minWidth350: true, flexGrow: true, })}>
                                <Dropdown 
                                options={supportCaseBilling_businessList} 
                                onChange={this.supportCaseBilling_selectInput_requester_businessOption} 
                                value={supportCaseBilling_businessList.find(option => option.value === this.state.supportCaseBilling_businessDetail)}
                                placeholder="Select an option" />
                              </div>
                            </div>

                          </InputCardItem>
                        )}
                      </div>
                    ):(
                      <div>
                        <InputCardItem headlineText="Name" headlineSubText="" additionalText="">
                          <InputTextV3
                            id="name" 
                            name="name" 
                            identifiers=""
                            stateIdentifier=""
                            label='' 
                            value={this.state.supportCaseBilling_customerContactName}
                            onChangeInputTextHandler={this.supportCaseBilling_requesterName} 
                            />
                        </InputCardItem>
                        <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                          <InputTextV3
                            id="email" 
                            name="email" 
                            identifiers=""
                            stateIdentifier=""
                            label='' 
                            value={this.state.supportCaseBilling_customerContactEmail}
                            onChangeInputTextHandler={this.supportCaseBilling_requesterEmail} 
                            />
                        </InputCardItem>
                      </div>
                    )}
    
                  </InputCard>

                  <InputCard headlineText="Case description" headlineSubText="">
 
                    <InputCardItem headlineText="Subject" headlineSubText="" additionalText="">
        
                      <InputTextV3
                        id="subject" 
                        name="subject" 
                        identifiers=""
                        stateIdentifier=""
                        label='' 
                        value=""
                        onChangeInputTextHandler={this.supportCaseBilling_messageSubject} 
                        />
                    
                    </InputCardItem>

                    <InputCardItem headlineText="Description" headlineSubText="Detailed message of the question or issue" additionalText="">
        
                     
                      <InputTextareaV1
                      id="message" 
                      name="message" 
                      identifiers=""
                      stateIdentifier=""
                      label='' 
                      value=""
                      onChangeInputTextHandler={this.supportCaseBilling_messageDescription} 
                      />
                    
                    </InputCardItem>


                    <div className={classNames({ text_s14: true, colour_danger: true, })}>
                      {this.state.supportBillingInputErrors === true && (
                        <div className={classNames({ text_s20: true, colour_danger: true, })}>
                          <i class="fas fa-exclamation-triangle"></i> There are items that require your attention
                        </div>
                      )}

                      {this.state.supportBillingInputErrors === true && (
                        <div>
                          <ul>
                          {this.state.supportBillingInputErrorList.map((item, index) => (
                            <li>{item.message}</li>
                          ))}
                          </ul>
                          <hr/>
                          <br/>
                        </div>
                      )}

    
                    </div>




                    <div className={classNames({ flex: true, flexColumn: true })}>
                      <div className={classNames({ flex: true, flexJustifyCenter: true, padB20: true })}>
                        <ReCAPTCHA
                          sitekey="6Lf7YK0ZAAAAAH-AXpnH5pcZe2I09rTdA2J2UjDR"
                          onChange={this.onReCaptchaChange}
                          onExpired={this.onReCaptchaExpired}
                          onErrored={this.onReCaptchaErrored}
                        />
                      </div>
                      <div>
                        <StandardApiBtn
                        apiActivityId="logSupportCase"
                        icon=""
                        iconLocation=""
                        text="Log Support Case"
                        tooltip=""
                        action=""
                        classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                        handleClick={this.logSupportCase}
                        />
                      </div>
                    </div>


                  </InputCard>
                  
                </div>
              ) :
                null
              }

              {this.props.selectedSupportCase.radioOptionsGroup === 'supportCaseSelection' &&
              this.props.selectedSupportCase.selectedRadioOptionsId === 'technicalSupport' ?
              (
                <div>
                  <InputCard headlineText="Case classification" headlineSubText="">
                    <TextStyle size="h4" weight="">
                    </TextStyle>


                    <InputCardItem headlineText="Type" headlineSubText="" additionalText="">

                      <div className={classNames({ flex: true,})}>
                        <div className={classNames({ minWidth350: true })}>
                          
                          <Dropdown 
                          options={supportCase_technicalSupport_Classification_Options} 
                          onChange={this.supportCaseTechnical_selectInput_caseClassificationType} 
                          value={supportCase_technicalSupport_Classification_Options.find(option => option.value === this.state.supportCaseTechnical_caseType)}
                          placeholder="Select an option" />

                        </div>
                      </div>

                    </InputCardItem>

                  </InputCard>

                  <InputCard headlineText="Requester details" headlineSubText="">
                    {this.props.isAuthenticated === true ? (
                      <div>
                        <InputCardItem headlineText="Requester" headlineSubText="" additionalText="">
                          {this.state.customerLoggedInFirstName} (ID: {this.state.customerLoggedInId})
                        </InputCardItem>


                        <InputCardItem headlineText="Request Relates to" headlineSubText="" additionalText="">
                          <div>
                            <CheckboxOptionCardV2
                            businessId=""
                            checkboxText="My account"
                            checkboxState={this.state.supportCaseTechnical_user}
                            onChangeHandler={this.supportCaseTechnical_checkInput_requester_user}
                            //onChangeHandlerAction2
                            />
                          </div>
                          <div>
                            <CheckboxOptionCardV2
                            businessId=""
                            checkboxText="A business account"
                            checkboxState={this.state.supportCaseTechnical_user}
                            onChangeHandler={this.supportCaseTechnical_checkInput_requester_business}
                            //onChangeHandlerAction2
                            />
                          </div>
                        </InputCardItem>

                        {this.state.supportCaseTechnical_business === true && (
                          <InputCardItem headlineText="Select Business Details" headlineSubText="" additionalText="">

                            <div className={classNames({ flex: true,})}>
                              <div className={classNames({ minWidth350: true, flexGrow: true, })}>
                                <Dropdown 
                                options={supportCaseTechnical_businessList} 
                                onChange={this.supportCaseTechnical_selectInput_requester_businessOption} 
                                value={supportCaseTechnical_businessList.find(option => option.value === this.state.supportCaseTechnical_businessDetail)}
                                placeholder="Select an option" />
                              </div>
                            </div>

                          </InputCardItem>
                        )}
                      </div>
                    ):(
                      <div>
                        <InputCardItem headlineText="Name" headlineSubText="" additionalText="">
                          <InputTextV3
                            id="name" 
                            name="name" 
                            identifiers=""
                            stateIdentifier=""
                            label='' 
                            value={this.state.supportCaseTechnical_customerContactName}
                            onChangeInputTextHandler={this.supportCaseTechnical_requesterName} 
                            />
                        </InputCardItem>
                        <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                          <InputTextV3
                            id="email" 
                            name="email" 
                            identifiers=""
                            stateIdentifier=""
                            label='' 
                            value={this.state.supportCaseTechnical_customerContactEmail}
                            onChangeInputTextHandler={this.supportCaseTechnical_requesterEmail} 
                            />
                        </InputCardItem>
                      </div>
                    )}
                  </InputCard>

                  <InputCard headlineText="Case description" headlineSubText="">
 
                    <InputCardItem headlineText="Subject" headlineSubText="" additionalText="">
        
                      <InputTextV3
                        id="subject" 
                        name="subject" 
                        identifiers=""
                        stateIdentifier=""
                        label='' 
                        value=""
                        onChangeInputTextHandler={this.supportCaseTechnical_messageSubject} 
                        />
                    
                    </InputCardItem>

                    <InputCardItem headlineText="Description" headlineSubText="Detailed message of the question or issue" additionalText="">
        
                     
                      <InputTextareaV1
                      id="message" 
                      name="message" 
                      identifiers=""
                      stateIdentifier=""
                      label='' 
                      value=""
                      onChangeInputTextHandler={this.supportCaseTechnical_messageDescription} 
                      />
                    
                    </InputCardItem>

                    <div className={classNames({ text_s14: true, colour_danger: true, })}>
                      {this.state.supportTechnicalInputErrors === true && (
                        <div className={classNames({ text_s20: true, colour_danger: true, })}>
                          <i class="fas fa-exclamation-triangle"></i> There are items that require your attention
                        </div>
                      )}

                      {this.state.supportTechnicalInputErrors === true && (
                        <div>
                          <ul>
                          {this.state.supportTechnicalInputErrorList.map((item, index) => (
                            <li>{item.message}</li>
                          ))}
                          </ul>
                          <hr/>
                          <br/>
                        </div>
                      )}

                    </div>

                    <div className={classNames({ flex: true, flexColumn: true })}>
                      <div className={classNames({ flex: true, flexJustifyCenter: true, padB20: true })}>
                        <ReCAPTCHA
                          sitekey="6Lf7YK0ZAAAAAH-AXpnH5pcZe2I09rTdA2J2UjDR"
                          onChange={this.onReCaptchaChange}
                          onExpired={this.onReCaptchaExpired}
                          onErrored={this.onReCaptchaErrored}
                        />
                      </div>
                      <div>
                        <StandardApiBtn
                        apiActivityId="logSupportCase"
                        icon=""
                        iconLocation=""
                        text="Log Support Case"
                        tooltip=""
                        action=""
                        classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                        handleClick={this.logSupportCase}
                        />
                      </div>
                    </div>


                  </InputCard>
                  
                </div>
              ) :
                null
              }



            <br/>
  
              

            
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  
  ///// SUPPORT CASE /////
  let selectedSupportCase = null;
  try {
    selectedSupportCase = state.UI.uiRadioOptions.find(radioOption => radioOption.radioOptionsGroup === 'supportCaseSelection');
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the selected supportCaseSelection');
  }
  //Defaults
  if (selectedSupportCase === null || selectedSupportCase === undefined) {
    selectedSupportCase = {
      radioOptionsGroup: '',
      selectedRadioOptionsId: '',
    }
  }


  let selectedSupportCaseRadioOptionsGroup = '';
  try {
    selectedSupportCaseRadioOptionsGroup = selectedSupportCase.radioOptionsGroup;
  } catch (e) {
    selectedSupportCaseRadioOptionsGroup = '';
  }
  if (selectedSupportCaseRadioOptionsGroup === undefined) {
    selectedSupportCaseRadioOptionsGroup = '';
  }

  let selectedSupportCaseSelectedRadioOptionsId = '';
  try {
    selectedSupportCaseSelectedRadioOptionsId = selectedSupportCase.selectedRadioOptionsId;
  } catch (e) {
    selectedSupportCaseSelectedRadioOptionsId = '';
  }
  if (selectedSupportCaseSelectedRadioOptionsId === undefined) {
    selectedSupportCaseSelectedRadioOptionsId = '';
  }




  let supportCaseCategory = '';
  try {
    supportCaseCategory = ownProps.supportCaseCategory;
  } catch (e) {}

  if (selectedSupportCaseRadioOptionsGroup === 'supportCaseSelection' &&
  selectedSupportCaseSelectedRadioOptionsId === 'accountBilling') {
    supportCaseCategory = 'accountBilling';
  }

  if (selectedSupportCaseRadioOptionsGroup === 'supportCaseSelection' &&
  selectedSupportCaseSelectedRadioOptionsId === 'technicalSupport') {
    supportCaseCategory = 'technicalSupport';
  }

  //If the supportCaseCategory changes then reset all the values in state to clear them.
  let resetSupportPageState = false;
  if (ownProps.supportCaseCategory !== supportCaseCategory) {
    console.warn("=============== RESET ALL THE SUPPORT CASE CATEGORIES =====================");
    console.warn(`new case category: ${supportCaseCategory}`)
    resetSupportPageState = true;
  }



  let isAuthenticated = false;
  try {
    isAuthenticated = state.Auth.isAuthenticated;
  } catch (e) {
    isAuthenticated = false;
  }
  if (isAuthenticated === undefined) {
    isAuthenticated = false;
  }

  let userFirstName = 'Name not set.';
  try {
    userFirstName = state.User.userProfile.firstName;
  } catch (e) {
    userFirstName = 'Name not set.';
  }
  if (userFirstName === undefined) {
    userFirstName = 'Name not set.';
  }
  let userId = '';
  try {
    userId = state.User.id;
  } catch (e) {
    userId = '';
  }
  if (userId === undefined) {
    userId = '';
  }

  let businessIdentities = [];
  try {
    businessIdentities = state.BusinessIdentities.identities.businesses;
  } catch (e) {
    businessIdentities = [];
  }
  if (businessIdentities === undefined) {
    businessIdentities = [];
  }

  let managedBusinesses = [];
  try {
    managedBusinesses = state.User.managedBusinesses;
  } catch (e) {
    managedBusinesses = [];
  }
  if (managedBusinesses === undefined) {
    managedBusinesses = [];
  }
  //Get all the users managed businesses
  let managedBusinessIds = [];
  for (let x = 0; x < managedBusinesses.length; x++) {
    managedBusinessIds.push(managedBusinesses[x].businessId);
  }

  //Get the managed business details
  let managedBusinessDetails = [];
  for (let x = 0; x < managedBusinessIds.length; x++) {
    for (let y = 0; y < businessIdentities.length; y++) {
      if (managedBusinessIds[x].toString() === businessIdentities[y]["_id"].toString()) {
        if (businessIdentities[y].businessProfile.businessName.length === 0) {
          managedBusinessDetails.push({
            id: businessIdentities[y]["_id"].toString(),
            name: `(id: ${businessIdentities[y]["_id"].toString()})`,
          })
        } else {
          managedBusinessDetails.push({
            id: businessIdentities[y]["_id"].toString(),
            name: `${businessIdentities[y].businessProfile.businessName} (id: ${businessIdentities[y]["_id"].toString()})`,
          })
        }
      }
    }
  }

 
  console.log("===== managedBusinessDetails =====");
  console.log(managedBusinessDetails);
  console.log(JSON.stringify(managedBusinessDetails));


  /*
  [{"id":"5e92bde7b34aa9000870a524","name":""},
  {"id":"5e92beb3b34aa9000870a532","name":"Selby Music V224"},
  {"id":"5e93ccdafc29b50008e3d558","name":"Selby Music"},
  {"id":"5eddb9c90261a800089cdb58","name":"Guard Point"}]
  */


 

  //resetSupportPageState

  /*
  let supportRequestAccountUser = ownProps.supportRequestAccountUser;
  let supportRequestAccountBusiness = ownProps.supportRequestAccountBusiness;
  let supportRequestAccountBusinessDetail = ownProps.supportRequestAccountBusinessDetail;


 
  console.log("===== resetSupportPageState =====");
  console.log(resetSupportPageState);

  if (resetSupportPageState === true) {
    supportRequestAccountUser = false;
    supportRequestAccountBusiness = false;
    supportRequestAccountBusinessDetail = '';
  }
  */

  return {
    //supportRequestAccountUser,
    //supportRequestAccountBusiness,
    //supportRequestAccountBusinessDetail,

    supportCaseCategory,      //For the state object
    selectedSupportCase,
    isAuthenticated,          //How we display the UI
    userFirstName,
    userId,
    managedBusinessDetails,


    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
    
  
  };

};


const mapDispatchToProps = {
  createSupportCaseInteraction,

};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)
