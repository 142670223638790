import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2, InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { MessageTip, MessageHighlight, HighlightContainer } from 'Components/ShcText';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { TableListFacing } from 'Components/ShcTables';
import { apiDataTransferTimer } from 'Helpers/Functions';

// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { userIdentitiesVerifyNow, userIdentitiesCodeVerifyNow, reduxDataUpdate } from 'Actions';


class StepUserVerify extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)

    this.handleIdentityVerification = this.handleIdentityVerification.bind(this);
    this.handleIdentityCodeVerification = this.handleIdentityCodeVerification.bind(this);

    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this);

    this.state = {
      userIdentity: this.props.value,
      verificationCode: '',
      userIdentities: [],
      thisIdentity: {},

      apiTimerCollection: [], // List of active timers. (MANDATORY)

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps - StepUserVerify');
    console.log('prevState');
    console.log(prevState);
    console.log('nextProps');
    console.log(nextProps);

    let updateState = false;
    if (nextProps.userIdentities !== prevState.userIdentities) {
      updateState = true;
    }
    //if (nextProps.user !== prevState.user) {
    //  updateState = true;
    //}

    if (updateState === true) {
      console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      const thisIdentity = nextProps.userIdentities.find(user => user.identity === prevState.userIdentity);
      console.log("thisIdentity");
      console.log(thisIdentity);
      console.log('userIdentities');
      console.log(nextProps.userIdentities);
      return {
        userIdentities: nextProps.userIdentities,
        thisIdentity: thisIdentity,
        //user: nextProps.user,
      };
    }
    return null;

    //userIdentities: state.User.userIdentities.find(e => e.identity === ownProps.userIdentities.identity )

  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate - StepUserVerify');
    console.log(`${JSON.stringify(prevProps.value)} !== ${JSON.stringify(this.props.value)}`);
    if (prevProps.value !== this.props.value) {
      // Perform some operation here
      console.log('perform up of state for StepUserVerify');
      this.setState({
        userIdentity: this.props.value,
      });
    }
  }


  handleIdentityVerification(event) {
    event.preventDefault();
    console.log('Verify this identity now.');
    const { userIdentity } = this.state;
    const data = {
      userIdentity,
    };
    this.props.userIdentitiesVerifyNow({ data });
  }

  handleIdentityCodeVerification(event) {
    event.preventDefault();
    console.log('Confirm Verification Code Input');
    const { userIdentity } = this.state;
    
    let identityLocalInputVerificationCode = this.state.verificationCode;
    console.log("identityLocalInputVerificationCode");
    console.log(identityLocalInputVerificationCode);
    /*
    try {
      identityLocalInputVerificationCode = this.props.userIdentities.identityLocalInputVerificationCode;
      console.log("this.props.userIdentities.identityLocalInputVerificationCode");
      console.log(this.props.userIdentities.identityLocalInputVerificationCode);
    } catch(e) {
      console.log("error: unable to retrieve: identityLocalInputVerificationCode");
    }
    */
    const data = {
      userIdentity,
      verificationCode: identityLocalInputVerificationCode,
    };
    this.props.userIdentitiesCodeVerifyNow({ data });
  }

  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    console.log("onChangeDynamicInputTextHandler");

    this.setState({
      verificationCode: receivedUpdateObj.value,
    });

    

    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);

    console.log(JSON.stringify(recordsForUpdate));
        
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */

    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      console.log(timerReferenceGroup);
      console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    console.log("===============================");
    console.log("SEND TO PARENT: inputTextChange");

    //Update local state (do not update fields that are shared with parent)
    this.setState({
      [evt.target.name]: evt.target.value,
    });

    let updateData= {
      field: evt.target.name,
      value: evt.target.value,
    }
    this.props.onChangeStepInputHandler(this.props.id, this.state.userIdentity, updateData);
    
  }



  render() {

    const {
      id, name, onChangeStepInputHandler, value,
    } = this.props;
    /*
    const dataJSON_website = [
      { id: 1, title: 'Website', content: 'https://www.smarthome.cloud' },
    ];
    */
    const VerificationStatus = () => {
      const verificationStatusDate = this.state.userIdentity.verificationStatusDate;
      const verificationAttemptDate = this.state.userIdentity.verificationAttemptDate;
      if (this.state.userIdentity.verificationStatus === true) {
        return (
          <TextStyle size="h4" weight="normal">Status: Verified ({verificationStatusDate})</TextStyle>
        );
      }
      let notVerifiedDate = '';
      if (verificationAttemptDate !== '') {
        notVerifiedDate = verificationAttemptDate;
      } else {
        notVerifiedDate = 'Not attempted';
      }
      return (
        <div>
          <TextStyle size="h4" weight="bold">Status:</TextStyle> <TextStyle size="h4" weight="normal">Not Verified ({notVerifiedDate})</TextStyle>
          <TextStyle size="h5" weight="normal">You have until 30/04/2019 to verify this
            identity before it is automatically removed.
          </TextStyle>
        </div>
      );

    };

    const NextVerificationDate = () => {
      const nextRequiredVerificationDate = this.state.userIdentity.nextRequiredVerificationDate;
      return (
        <TextStyle size="h4" weight="normal">We will attempt to verify this identity on
          {nextRequiredVerificationDate} or nearby to this date.<br />
        If this identity fails to verify, it will be automatically removed from your business
        profiles and marked as not verified.

        </TextStyle>
      );
    };

    const EnterVerificationCode = () => {
      const identityType = this.state.userIdentity.type;
      if (identityType === 'phone') {
        return (
          <div>
            <TextStyle size="h4" weight="normal">We have just sent you a verification message.
              <br />
            Enter the verification code here.
            </TextStyle>

            <InputCardItem headlineText="Verification Code" headlineSubText="" additionalText="">
              <InputTextV3
                id="userIdentities.$.identityLocalInputVerificationCode"
                name="userIdentities.$.identityLocalInputVerificationCode"
                identifiers={[{ _id: this.state.userIdentity._id }]}
                stateIdentifier="User"
                label=""
                value={this.state.userIdentity.identityLocalInputVerificationCode}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
              />
            </InputCardItem>
          </div>
        );
      }
      return null;


    };


    const ValidateCard = () => {
      const identityType = this.state.userIdentity.type;
      if (identityType === 'phone') {
        return (
          <InputCard headlineText="Validate" headlineSubText="Enter in the code received to verify your identity">
            <div>

              <EnterVerificationCode /><br />
              <StandardBtn
                icon=""
                iconLocation=""
                text="Confirm"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                handleClick={this.handleIdentityCodeVerification}
                />
              <br />

              <hr className={classNames({ dividerHorizontalRule: true })} />

              <NextVerificationDate /><br />

              <VerificationStatus /><br />

            </div>
          </InputCard>
        );
      }
      return null;

    };




    console.log(`THE VALUE FROM PARENT: ${JSON.stringify(value)}`);

    return (
      <div>
        <InputCard headlineText="Verification" headlineSubText="This is where we will make contact with you to verify this identity.">
          <div>

            <TextStyle size="h4" weight="bold">Verification for:</TextStyle>
            <TableListFacing data={[{ id: 1, title: this.state.userIdentity.type, content: this.state.userIdentity.identity }]} />
            <br />
            <TextStyle size="h4" weight="bold">Confirmation Code:</TextStyle>
            <TableListFacing data={[{ id: 1, title: 'Code', content: this.state.userIdentity.communicationToken }]} />
            <br />


            <br />
            <div className={classNames({ flex: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Verify now"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                handleClick={this.handleIdentityVerification}
            />

              <StandardBtn
                icon=""
                iconLocation=""
                text="Check Status"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
            />

            </div>
            <br />
          </div>
        </InputCard>

        <ValidateCard />








        <InputCard headlineText="Help" headlineSubText="">
          <div>


            <TextStyle size="h3" weight="normal">Having Problems?</TextStyle>
            <NamedTextStyle name="para" >
            Once you have completed the verification steps which includes adding the relevant code to your website, you can then click the verify button.
              <br /><br />
            We will attempt to make contact with the below website address to verify your identity. Please ensure the below address is correct.
            </NamedTextStyle >
          </div>
        </InputCard>
      </div>
    );
  }
}

StepUserVerify.propTypes = {

};

const mapStateToProps = (state, ownProps) => ({
  userIdentities: state.User.userIdentities,
  //userIdentities: state.User.userIdentities.find(e => e.identity === ownProps.userIdentities.identity )
});

const mapDispatchToProps = {
  userIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  reduxDataUpdate,
};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(StepUserVerify);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(StepUserVerify);
