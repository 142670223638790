import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');
import Select from 'react-select';


// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';
import { AddressInputLayoutsInternationalV1 } from 'Components/ShcAdminModulesWorkQueues';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class AddressInputV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)

    this.updateData = this.updateData.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.

      postageCountry: '',




      identityInputValue: this.props.identityInputValue,

      identityInputErrors: false,





      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
   
    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {


  }

  componentDidUpdate(prevProps, prevState) {
  }


  handleLocationInputChange = address => {
    console.log("handleLocationInputChange")
    console.log(address);
    this.setState({ identityInputValue: address });

    let returnObj = {
      identifiers: this.props.identifiers,
      name: this.props.name,
      stateIdentifier: this.props.stateIdentifier,
      value: address,
    }
    //console.log(returnObj);

    //this.updateData(returnObj);

  };

  handleSelectInputChange(evt) {
    //console.log("handleSelectInputChange");
    //console.log(evt);
    let value = evt.value;

    this.setState({postageCountry: value});
    
    
    let receivedUpdateObj = {
      name: 'identities.businesses.$.businessIdentities.$.postageCountry',
      identifiers: [{_id: this.props.businessId}, {_id: this.props.identityId}],
      value: value,
      stateIdentifier: "BusinessIdentities",
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /////////this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      /////////////////this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }
  


  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }






  render() {
    const { 
      businessId, identityId, onInputChange,
    } = this.props;

    const options = [
      { value: 'australia', label: 'Australia' },
      { value: 'other', label: 'Other Country' },
    ];
    

  
    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
          })}
          >
            
            
            <div>

              <div className={classNames({ flex: true, cursorPointer: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <Select options={options} 
                  onChange={this.handleSelectInputChange} 
                  value={options.find(option => option.value === this.state.postageCountry)}
                  />
                </div>
              </div>

              <AddressInputLayoutsInternationalV1
              countryFormLayout={this.state.postageCountry}
              onChange={this.handleLocationInputChange}
              businessId={businessId}
              identityId={identityId}
              onInputChange={onInputChange}

              />
            </div>
            
            
          </div>
        </div>
          


          <div className={classNames({ flex: true })}>

              {this.state.savingData && 
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                    <div>
                      <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                      aria-label="Verified"
                      ></i>
                    </div>
                  </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }
              
     
            </div>



      </div>

    );
  }
}




AddressInputV1.defaultProps = {
};


AddressInputV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  

  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddressInputV1);
