import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';
import Redirect from 'react-router-dom/Redirect';

import Select from 'react-select';
import Dropdown from 'react-dropdown';

//Password Strenght
import ReactPasswordStrength from 'react-password-strength';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem  } from 'Components/ShcCards';

// Actions
import { userAuth, userSignupEmail, userSignupAuth, userSignUpEmail, setuserlogout } from 'Actions';

import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      //PIN ACCESS
      guardPointPinAccess: false,  //Whether Pin access is on/off
      guardpointPin: '',          //Entered Pin Number
      guardPointPinCode: '6933', 
      guardPointPinAccessErrorMessage: '', 

      //REDIRECTION CONTROL
      redirectToDashboard: false,     

      //PASSWORD
      password: '',
      passwordLength: 0,
      passwordIsValid: false,

      //PASSWORD REQUIREMENTS
      passRequirementsValid: false,
      passLowerAndUpperCase: false,
      passHasNumber: false,
      passHasSpecialChar: false,

      //USERNAME
      emailUsername: '',


      loginLoading: false,
      
      userDataRegion: '',     //Where the user data will be stored

      user: null,
      
      isAuthenticated: this.props.isAuthenticated,
      errorCode: '',
      errorMessage: '',
    };


    //Gaurd Point PIN Access
    this.handleGuardPointPinAccessInput = this.handleGuardPointPinAccessInput.bind(this);

    this.handleEmailSignupClick = this.handleEmailSignupClick.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleEmailUsernameInput = this.handleEmailUsernameInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);   //old
    this.handlePasswordStrengthInput = this.handlePasswordStrengthInput.bind(this);

    //User Data Region
    this.handleSelectInputUserDataRegion = this.handleSelectInputUserDataRegion.bind(this);
  }

  componentDidMount(props) {


    if (this.props.isAuthenticated === true) {
      //window.location.href = '/account/dashboard';
      this.setState({
        redirectToDashboard: true,
      });
    }
  }

  componentDidUpdate() {
    //console.log('component updated');

    if (this.props.isAuthenticated === true) {
      //window.location.href = '/account/dashboard';
      this.setState({
        redirectToDashboard: true,
      });
    }
  }

  
  
  handleGuardPointPinAccessInput(e) {
    this.setState({ guardpointPin: e.target.value });
  }

  handleSelectInputUserDataRegion(evt) {
    //console.log("handleSelectInputUserDataRegion");
    //console.log(evt);
    let value = evt.value;
    //console.log("value");
    //console.log(value);
    this.setState({
      userDataRegion: value,
    });
  }

  handleEmailSignupClick(e) {
    let guardPointSignUpAccess = false;
    if (this.state.guardPointPinAccess === true) {
      //Pin access is required. 
      if (this.state.guardpointPin.toString() === this.state.guardPointPinCode.toString()) {
        guardPointSignUpAccess = true;
        this.setState({ 
          guardPointPinAccessErrorMessage: '', 
        });
      } else {
        this.setState({ 
          guardPointPinAccessErrorMessage: 'Invalid Pin', 
        });
      }
    } else {
      guardPointSignUpAccess = true;
    }
 
    if (guardPointSignUpAccess === true) {
      if (this.state.passwordIsValid === false) {
        //Password is not valid
        this.setState({ 
          errorMessage: `Password does not meet the security requirements`, 
        });
      } else if (this.state.passwordIsValid === true) {
        //Password is valid

        let userDataRegion = this.state.userDataRegion;
        if (userDataRegion === '' || userDataRegion === undefined || userDataRegion === null) {
          this.setState({ 
            errorMessage: `Please select a region where you would like your data stored`, 
          });
        } else {
          // Present loading screen
          this.setState({ loginLoading: true });

          auth().createUserWithEmailAndPassword(this.state.emailUsername, this.state.password)
          .then((user) => {
            //console.log("user response from signup");
            //console.log(user);

            this.setState({ user: user.user, loginLoading: true });
            auth().currentUser.sendEmailVerification()
            .then(function(){
              //console.log("email verification sent to user");
            });
            return user;
          })
          .then((result) => {
            auth().signInWithEmailAndPassword(this.state.emailUsername, this.state.password)
            .then((result) => {
              //console.log('user sign in. >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
              //console.log(JSON.stringify(result));
              //console.log(JSON.stringify(result.user));
              //console.log(auth().currentUser);

              this.setState({ user: result.user });

              //The user selected region
              let data = {
                userDataRegion: this.state.userDataRegion,
              }

              let userData = result.user;

              this.props.userSignupEmail({data, userData})
              .catch((error) => {
                console.warn('Sign up failed');
                console.warn(error);
                this.setState({ loginLoading: false });
                toast.error('Error experienced during signup.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              });

            })
            .catch((error) => {
              // Handle Errors here.
              this.setState({ loginLoading: false });
              //console.log('handle the error');
              //console.log(error);
              const errorCode = error.code;
              const errorMessage = error.message;
              //console.log(errorCode);

              if (errorCode === 'auth/invalid-email') {
                this.setState({ errorCode: 'auth/invalid-email', errorMessage: 'Invalid email' });
                toast.error('Invalid email address.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              } else if (errorCode === 'auth/wrong-password') {
                this.setState({ errorCode: 'auth/wrong-password', errorMessage: 'Wrong password' });
                toast.error('Incorrect Password.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              } else if (errorCode === 'auth/user-disabled') {
                this.setState({ errorCode: 'auth/user-disabled', errorMessage: 'Account disabled' });
                toast.error('Your account is disabled.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              } else if (errorCode === 'auth/user-not-found') {
                this.setState({ errorCode: 'auth/user-not-found', errorMessage: 'User not found' });
                toast.error('User not found.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              } else {
                //console.log(errorMessage);
                this.setState({ errorCode: 'auth/other', errorMessage: 'An error has occured' });
                toast.error('An error has occured during login.', {
                  closeOnClick: true,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  className: 'toastError',
                  bodyClassName: 'grow-font-size',
                  progressClassName: 'toastErrorProgress',
                });
              }
            });
          })
          .catch((error) => {
            // Handle Errors here.
            this.setState({ loginLoading: false });
            //console.log('handle the error');
            //console.log(error);
            const errorCode = error.code;
            const errorMessage = error.message;
            //console.log(errorCode);

            if (errorCode === 'auth/email-already-in-use') {
              this.setState({ errorCode: 'auth/email-already-in-use', errorMessage: 'The email address is already in use by another account.' });
              toast.error('The email address is already in use by another account.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            } else if (errorCode === 'auth/invalid-email') {
              this.setState({ errorCode: 'auth/invalid-email', errorMessage: 'Invalid email' });
              toast.error('Invalid email address.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            } else if (errorCode === 'auth/wrong-password') {
              this.setState({ errorCode: 'auth/wrong-password', errorMessage: 'Wrong password' });
              toast.error('Incorrect Password.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            } else if (errorCode === 'auth/user-disabled') {
              this.setState({ errorCode: 'auth/user-disabled', errorMessage: 'Account disabled' });
              toast.error('Your account is disabled.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            } else if (errorCode === 'auth/user-not-found') {
              this.setState({ errorCode: 'auth/user-not-found', errorMessage: 'User not found' });
              toast.error('User not found.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            } else {
              //console.log(errorMessage);
              this.setState({ errorCode: 'auth/other', errorMessage: 'An error has occured' });
              toast.error('An error has occured during login.', {
                closeOnClick: true,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                className: 'toastError',
                bodyClassName: 'grow-font-size',
                progressClassName: 'toastErrorProgress',
              });
            }
          });
        }
    
      }   //End is password valid
    } else {
      //No access allowed (Guard Point PIN)
    }
  }



  handleForgotPasswordClick(e) {
    auth().sendPasswordResetEmail(this.state.emailUsername);
  }

  handleEmailUsernameInput(e) {
    //console.log('setting username');
    this.setState({ emailUsername: e.target.value });
  }
  handlePasswordInput(e) {
    this.setState({ password: e.target.value });
  }

  handlePasswordStrengthInput(passwordStrength) {
    //Password Strength Input is used to guide the user.
    //Also require certain character types
    /*
    At least 1 uppercase character.
    At least 1 lowercase character.
    At least 1 digit.
    At least 1 special character.
    */

    let score = passwordStrength.score;
    let password = passwordStrength.password;
    let isValid = passwordStrength.isValid;

    //console.log(`score: ${score}`);
    //console.log(`password: ${password}`);
    //console.log(`isValid: ${isValid}`);

    let passwordLength = password.length;
    //console.log(passwordLength);

    //Does the password meet the Char Requirements?
    let passLowerAndUpperCase = false;
    if (password.match(/[a-zA-Z]/g)) {
      passLowerAndUpperCase = true;
    } else {
      passLowerAndUpperCase = false;
    }
    let passHasNumber = false;
    if (password.match(/[0-9]/g)) {
      passHasNumber = true;
    } else {
      passHasNumber = false;
    }
    /* Best practice says do not force.
    let passHasSpecialChar = false;
    //if (password.match(/[!@#$%^&*()_+-=\[\]{};':"|,.<>\/?]/g)) {
    if (password.match(/[!@#$%^&*()_+\-=[\]{}|;:<>?,./]/i)) {
      passHasSpecialChar = true;
    } else {
      passHasSpecialChar = false;
    }

    JSX
     {this.state.passHasSpecialChar === false && (
        <li>Password requires at least one special character</li>
      )}
    */


    let passRequirementsValid = false;
    if (passLowerAndUpperCase === true && passHasNumber === true) {   //&& passHasSpecialChar === true
      passRequirementsValid = true;
      this.setState({ 
        passRequirementsValid: passRequirementsValid,
        passLowerAndUpperCase: passLowerAndUpperCase,
        passHasNumber: passHasNumber,
        //passHasSpecialChar: passHasSpecialChar,
      });
    } else {
      passRequirementsValid = false;
      this.setState({ 
        passRequirementsValid: passRequirementsValid,
        passLowerAndUpperCase: passLowerAndUpperCase,
        passHasNumber: passHasNumber,
        //passHasSpecialChar: passHasSpecialChar,
      });
    }
    


    if (passRequirementsValid === true && isValid === true) {
      if (passwordLength > 128) {
        //Too long
        this.setState({ 
          passwordLength: passwordLength,
          passwordIsValid: false,
        });
      } else {
        this.setState({ 
          password: password,
          passwordLength: passwordLength,
          passwordIsValid: isValid,
        });
      }
    } else {
      this.setState({ 
        passwordLength: passwordLength,
        passwordIsValid: isValid,
      });
    }



    /* (likely on submit, provide feedback)
    toast.error('Error experienced during signup.', {
      closeOnClick: true,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      className: 'toastError',
      bodyClassName: 'grow-font-size',
      progressClassName: 'toastErrorProgress',
    });
    */

    //this.setState({ password: e.target.value });
  }

  passwordReset() {
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  }

  changePassword() {
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
  }

  logout() {
    auth().signOut()
      .then(() => {
        this.setState({ user: null });
        // Previous: this.props.setuserlogout(result.user);
        this.props.setuserlogout(); // 19/08 - removed data as reducer doesn't have the user obj passed.... will need to review

      });
  }
 

  componentWillUnmount() {
  }




  render() {

    const { errorMessage } = this.state;

    let dataRegions = [];
    dataRegions.push({value: 'aus', label: 'Australia'});
    //dataRegions.push({value: 'usa', label: 'United States'});












    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>

        {this.state.redirectToDashboard === true && (
          <Redirect to={{
            pathname: '/account/dashboard',
          }}/>
        )}

        {this.state.loginLoading ?
          (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Sign Up" headlineSubText="FREE user account. Sign up here.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Loading
                  </div>
                </div>
              </InputCard>
            </div>
          ) : (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Sign Up" headlineSubText="FREE user account. Sign up here.">

                <InputCardItem headlineText="Email Address" headlineSubText="" additionalText="">
                  <InputTextV2
                    id="emailusername"
                    name="emailusername"
                    value={this.state.emailUsername}
                    label="Username"
                    onChangeInputTextHandler={this.handleEmailUsernameInput}
                    />
                </InputCardItem>
                <InputCardItem headlineText="Password" headlineSubText="" additionalText="">
                  <ReactPasswordStrength
                    className={`customClass ${classNames({ displayBlock: true })}`}
                    minLength={10}
                    minScore={3}
                    scoreWords={['weak', 'weak', 'weak', 'strong', 'stronger']}
                    changeCallback={this.handlePasswordStrengthInput}
                    inputProps={{ name: "password_input", autoComplete: "off", className: "form-control" }}
                  />
                </InputCardItem>

                
                {this.state.passRequirementsValid === true && (
                  <div>
                    {this.state.passwordIsValid === true ? (
                      <div className={classNames({ text_s14: true, colour_success: true, })}>
                        <i class="far fa-check-circle"></i> Password meets security requirement.
                      </div>
                    ):(
                      <div>
                        {this.state.passwordLength > 0 && (
                          <div>
                            {this.state.passwordLength <= 128 ? (
                              <div className={classNames({ text_s14: true, colour_danger: true, })}>
                                <i class="far fa-times-circle"></i> Password does not meet security requirement.
                              </div>
                            ):(
                              <div className={classNames({ text_s14: true, colour_danger: true, })}>
                                <i class="far fa-times-circle"></i> Password is too long.
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {this.state.passRequirementsValid === false && (
                  <div className={classNames({ text_s14: true, colour_danger: true, })}>
                    <i class="far fa-times-circle"></i> Password does not meet character requirements:
                    <ul>
                    {this.state.passLowerAndUpperCase === false && (
                      <li>Password requires both uppercase and lowercase characters</li>
                    )}
                    {this.state.passHasNumber === false && (
                      <li>Password requires at least one number</li>
                    )}
                    </ul>


                  </div>
                )}



                  
                {this.state.guardPointPinAccess === true && (
                  <div>
                    <br/>
                    <InputCardItem headlineText="Guard Point - Sign ups are currently paused" 
                    headlineSubText="If you have been provided a Sign Up Pin, enter below to create
                    your account." additionalText="">
                      <InputTextV2
                        id="guardpointPinAccess"
                        name="guardpointPinAccess"
                        value={this.state.guardpointPin}
                        label="guardpointPinAccess"
                        onChangeInputTextHandler={this.handleGuardPointPinAccessInput}
                        />
                    </InputCardItem>

                    {this.state.guardPointPinAccessErrorMessage !== '' && (
                      <div className={classNames({ text_s14: true, colour_danger: true, })}>
                        <i class="far fa-times-circle"></i> {this.state.guardPointPinAccessErrorMessage}
                      </div>
                    )} 
                  </div>
                )}

                <br/>
                <hr/>
                <br/>

                <InputCardItem headlineText="User Data" 
                headlineSubText="Select a region where we will set up your account and store your user data.">
                  <div className={classNames({ flex: true,})}>
                    <div className={classNames({ minWidth350: true })}>
                      <Dropdown 
                      options={dataRegions} 
                      onChange={this.handleSelectInputUserDataRegion} 
                      value={dataRegions.find(option => option.value === this.state.userDataRegion)}
                      placeholder="Select an option" />
                      
                    </div>
                  </div>
                </InputCardItem>

                <TextStyle size="h5">
                  The region you select will not affect any of the services on our
                  platform. This is simply which location you would prefer your 
                  data to be stored.
                </TextStyle>

                <br/>


                
                <br/>


                { errorMessage !== '' && (
                <StandardText
                  icon=""
                  iconLocation=""
                  text={errorMessage}
                  tooltip=""
                  action=""
                  classes="shctext shctext-default-danger shctext-rounded shctext-medium shctext-fullwidth"
                      />
                  )
                  }

                <div>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Signup with email"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                    handleClick={this.handleEmailSignupClick}
                    />
                </div>


              </InputCard>
            </div>
          )}

        </div>
      </div>
    );
  }
}

/*
<InputCardItem headlineText="Password" headlineSubText="" additionalText="">
  <InputTextV2
    id="Password"
    name="Password"
    value={this.state.password}
    label="Password"
    onChangeInputTextHandler={this.handlePasswordInput}
    />
</InputCardItem>
*/

Template.propTypes = {
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

const mapDispatchToProps = {
  userSignupEmail,

  userSignupAuth,
  userSignUpEmail,
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
