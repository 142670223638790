import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// Routing
// import Routes from 'Core/Routes';

// Material UI


// CSS
import componentStyles from './styles.css';

// Components
import { ShcAppBar, ShcParentSidebar } from './ShcHeaderComponents';
import { FooterPrimaryLayout, FooterPrimaryLinks, FooterNoBrandingLinks, FooterPrimaryContent, FooterPurposeCopyright, FooterBaseSocial } from 'Components/ShcFooter';


// Actions
// import {  } from 'Actions';


class ShcHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { noFooter, noFooterBranding } = this.props;

    return (
      <div className={classNames({ shcHeader: true })}>
        <ShcAppBar title={this.props.title} />
        <ShcParentSidebar />
        <div className={classNames({ 
              shcHeaderContentContainerWidth: true,
             })}>
          <main
            className={classNames({ 
              shcHeaderContentContainer: true,
              shcHeaderContentContainerWidth: true,
             })}
          >
            <div className={classNames({ minHeight90vh: true })}>
              {this.props.children}
            </div>
          </main>
          {noFooterBranding !== true ? (
            <div>
              <FooterPrimaryLayout>
                <FooterPrimaryLinks />
                <FooterPrimaryContent />
                <FooterPurposeCopyright />
                <FooterBaseSocial />
              </FooterPrimaryLayout>
            </div>
          ):(
            <div>
              <FooterPrimaryLayout>
                <FooterNoBrandingLinks />
                <FooterPurposeCopyright />
                <FooterBaseSocial />
              </FooterPrimaryLayout>
            </div>
          )}
          
        </div>
      </div>
    );
  }
}

ShcHeader.propTypes = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ShcHeader);
