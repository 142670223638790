import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

import Dropdown from 'react-dropdown';




// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiIcon } from 'Components/ShcButtons';

import { IdentityViewV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class BusinessContactsIdentitySelection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.
      businessId: this.props.businessId,

      collapse: true,



    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
    console.log("==== getDerivedStateFromProps ===="); 
    console.log("nextProps");
    console.log(nextProps);
    console.log("prevState");
    console.log(prevState);

    return null;

  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeItem(identityReferenceId, evt) {
    console.log("removeItem");

    console.log("identityReferenceId");
    console.log(identityReferenceId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      identityReferenceId,
    }

    this.props.onRemoveItem({item, apiUi});
  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }

  handleSelectInputChange(evt) {
    //console.log("handleSelectInputChange");
    //console.log(evt);
    let value = evt.value;

    let businessContactsId='';
    try {
      businessContactsId = this.props.data.businessContactsId;
    } catch(e) {

    }

    let receivedUpdateObj = {
      name: 'identities.businesses.$.businessContacts.$.identityContactType',
      identifiers: [{_id: this.state.businessId}, {_id: businessContactsId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'businessContacts.$[contactid].identityContactType',
      fieldRef: [{"contactid._id": businessContactsId}],
      fieldValue: value,
      fieldStore: "BusinessIdentities",
      storeId: this.state.businessId,

    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }
  

  render() {
    const { 
      businessId,
      identityReferenceId,
      identityContactType,
      data, 
      onRemoveItem
      
    } = this.props;
     /*
    let dataSet = {
      identityContactType: identityType,  //Primary Contact, Billing Contact etc
      _id: identityDetails._id,
      identity: identityDetails.identity,
      type: identityDetails.type,
      verificationStatus: identityDetails.verificationStatus, 
    }
    */
    //console.log("========== data =========");
    //console.log(data);
    let businessContactsId = "";
    try { businessContactsId = data.businessContactsId; } catch(e) {}

    let identity = "";
    try { identity = data.identity; } catch(e) {}
    let type = "";
    try { type = data.type; } catch(e) {}
    let verificationStatus = "";
    try { verificationStatus = data.verificationStatus; } catch(e) {}


    let headlineText="";
    if (type === "email") {
      headlineText="EMAIL ADDRESS";
    }
    if (type === "mobile") {
      headlineText="MOBILE NUMBER";
    }
    if (type === "phone") {
      headlineText="PHONE NUMBER";
    }
    if (type === "web") {
      headlineText="WEBSITE";
    }
    if (type === "postal") {
      headlineText="POSTAL ADDRESS";
    }


    const options = [
      { value: 'primaryContact', label: 'Primary Contact' },
      { value: 'billingContact', label: 'Billing Contact' },
      { value: 'technicalContact', label: 'Technical Contact' }
    ];

    let selectInputValue = '';
    //console.log("set value for identityContactType");
    //console.log(identityContactType);
    if (identityContactType === "primaryContact") {
      selectInputValue = 'Primary Contact';
    }
    if (identityContactType === "billingContact") {
      selectInputValue = 'Billing Contact';
    }
    if (identityContactType === "technicalContact") {
      selectInputValue = 'Technical Contact';
    }
    

    return (
      <div className={classNames({ ShcModulesIdentityPrimaryContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ ShcModulesIdentityRemoveItem: true })}>

              <StandardApiIcon
                apiActivityId={`RemoveBusinessContactsIdentity_${identityReferenceId}`}
                icon="fas fa-times"
                classes=""
                handleClick={this.removeItem.bind(this, identityReferenceId)}
              />

            </div>
          </div>

          <div className={classNames({ flex: true, cursorPointer: true })}>
            <InputCardItem
              headlineText={headlineText}
              headlineSubText=''
              additionalText=''
              customStyle="slim"
            >
              {identity}<br/>

            </InputCardItem>
          </div>
          <div className={classNames({ flex: true, cursorPointer: true })}>
            <div className={classNames({ flexGrow: true })}>
              <Dropdown 
              options={options} 
              onChange={this.handleSelectInputChange} 
              value={options.find(option => option.value === identityContactType)}
              placeholder="Select an option" />
            </div>
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

BusinessContactsIdentitySelection.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
  };

};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessContactsIdentitySelection);
