import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';
import Moment from 'react-moment';
import JSONPretty from 'react-json-pretty';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem, InputCardHeader } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


import { VerifiedProfileInput } from 'Components/ShcModulesIdentity';


// Actions
import {

} from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object
      foundApiLogIdLocalState: this.props.foundApiLogIdLocalState,
      logEntry: this.props.logEntry,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (nextProps.foundApiLogIdLocalState !== prevState.foundApiLogIdLocalState) {
      updateState = true;
    }
    if (nextProps.logEntry !== prevState.logEntry) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      //console.log("nextProps.foundApiLogIdLocalState");
      //console.log(nextProps.foundApiLogIdLocalState);
      //console.log("nextProps.logEntry");
      //console.log(nextProps.logEntry);


      return {
        user: nextProps.user,
        foundApiLogIdLocalState: nextProps.foundApiLogIdLocalState,
        logEntry: nextProps.logEntry,

      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {

  }




  render() {
    const { userBusinessRoutePermissions } = this.props;

    const { logEntry } = this.props;

    const requestBodyHeadlineTitle = `Request ${logEntry.requestMethod} body`;
    const requestBody = logEntry.requestBody;
    const responseBody = logEntry.responseBody;


    const yourData = {
      email: 'REDACTED',
      firstName: 'REDACTED',
      tokenId: 'REDACTED',
      id: 'abc',
      array: [
        { name: 'a' },
        { name: 'b' },
      ],
      number: 1234567234,
      object: {
        a: 1234,
        b: 4556,
        c: 6949,
        d: 3939,
        nest1: {
          next2: {
            next3: {
              next4: {
                z: 1,
                y: 2,
                x: 3,
              },
            },
          },
        },
      },
      ghi: {
        j: 'a',
        k: 'b',
        l: 'c',
      },
      other: true,
      extra: false,
      mno: {
        aaa: [
          { name: 'a', team: 'aaaa' },
          { name: 'b', team: 'bbbb' },
        ],
        ddd: {
          a: 'aaaaaaaa',
          b: 'bbbbbbbb',
        },
      },
      todo: true,
    };

    const responseBody_TEST = {
      id: 'sub_FgecgXEGCGgSoz',
      object: 'subscription',
      application_fee_percent: null,
      billing: 'charge_automatically',
      billing_cycle_anchor: 1566722570,
      billing_thresholds: null,
      cancel_at: null,
      cancel_at_period_end: false,
      canceled_at: null,
      collection_method: 'charge_automatically',
      created: 1566722570,
      current_period_end: 1566808970,
      current_period_start: 1566722570,
      customer: 'cus_FgecvF1NVFY8lW',
      days_until_due: null,
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [
      ],
      discount: null,
      ended_at: null,
      items: {
        object: 'list',
        data: [
          {
            id: 'si_FgecBJOfIJ56ta',
            object: 'subscription_item',
            billing_thresholds: null,
            created: 1566722570,
            metadata: {
            },
            plan: {
              id: 'plan_Euwlhi5b89jrSN',
              object: 'plan',
              active: true,
              aggregate_usage: 'sum',
              amount: null,
              amount_decimal: null,
              billing_scheme: 'tiered',
              created: 1555719376,
              currency: 'aud',
              interval: 'day',
              interval_count: 1,
              livemode: false,
              metadata: {
              },
              nickname: 'Usage Plan Tier',
              product: 'prod_EuwaGePkDnXO7f',
              tiers: [
                {
                  amount: 0,
                  flat_amount: 1000,
                  flat_amount_decimal: '1000',
                  unit_amount_decimal: '0',
                  up_to: 5,
                },
                {
                  amount: 0,
                  flat_amount: 2000,
                  flat_amount_decimal: '2000',
                  unit_amount_decimal: '0',
                  up_to: 10,
                },
                {
                  amount: 100,
                  flat_amount: 0,
                  flat_amount_decimal: '0',
                  unit_amount_decimal: '100',
                  up_to: null,
                },
              ],
              tiers_mode: 'graduated',
              transform_usage: null,
              trial_period_days: null,
              usage_type: 'metered',
            },
            subscription: 'sub_FgecgXEGCGgSoz',
            tax_rates: [
            ],
          },
        ],
        has_more: false,
        total_count: 1,
        url: '/v1/subscription_items?subscription=sub_FgecgXEGCGgSoz',
      },
      latest_invoice: 'in_1FBHJGF3qIr5i0iicyQ46nWa',
      livemode: false,
      metadata: {
      },
      pending_setup_intent: null,
      plan: {
        id: 'plan_Euwlhi5b89jrSN',
        object: 'plan',
        active: true,
        aggregate_usage: 'sum',
        amount: null,
        amount_decimal: null,
        billing_scheme: 'tiered',
        created: 1555719376,
        currency: 'aud',
        interval: 'day',
        interval_count: 1,
        livemode: false,
        metadata: {
        },
        nickname: 'Usage Plan Tier',
        product: 'prod_EuwaGePkDnXO7f',
        tiers: [
          {
            amount: 0,
            flat_amount: 1000,
            flat_amount_decimal: '1000',
            unit_amount_decimal: '0',
            up_to: 5,
          },
          {
            amount: 0,
            flat_amount: 2000,
            flat_amount_decimal: '2000',
            unit_amount_decimal: '0',
            up_to: 10,
          },
          {
            amount: 100,
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount_decimal: '100',
            up_to: null,
          },
        ],
        tiers_mode: 'graduated',
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'metered',
      },
      quantity: 1,
      schedule: null,
      start: 1566722570,
      start_date: 1566722570,
      status: 'active',
      tax_percent: null,
      trial_end: null,
      trial_start: null,
    };

    const apiErrors = `${logEntry.errors}`;

    const summaryJson = [
      { ID: logEntry.id },
      { Time: <Moment unix>{logEntry.requestTime}</Moment> },
      { Method: logEntry.requestMethod },
      { URL: logEntry.url },
      { Status: logEntry.apiStatusCode },
      { 'IP Address': logEntry.ipAddress },
      { Errors: apiErrors },
      { 'API Endpoint': logEntry.apiEndpoint },
    ];


    const logHeadlineTitle = `${logEntry.requestMethod} ${logEntry.url}`;



    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business/:routebusinessid/reporting/logs"
        backTitle="Back to Logs"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business/:routebusinessid/reporting/logs"
                backTitle="Back to Logs"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Reporting"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-reporting"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}

            </div>

            <div className={classNames({ aaa: true, aaa: true })}>
              <InputCardHeader headlineText={logHeadlineTitle}>
                <div className={classNames({ aaa: true })}>
                  <Moment unix>{logEntry.requestTime}</Moment>
                  
                </div>
              </InputCardHeader>

              <InputCard headlineText="Summary" headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <div>
                    {
                    summaryJson &&
                    summaryJson.length > 0 ?
                    // Arrow function preserves "this"
                    summaryJson.map((item, i) => {

                      const keys = Object.keys(item);
                      const values = Object.values(item);

                      return (
                        <li key={item._id} className={classNames({ listNone: true, text_h5: true })}>
                          <div className={classNames({ flex: true })}>
                            <div className={classNames({ __ShcTableList_key__: true })}>
                              {keys[0]}
                            </div>
                            <div className={classNames({ __ShcTableList_value__: true })}>
                              {values[0]}
                            </div>
                          </div>

                        </li>
                      );
                    }, this)
                    : null
                    }
                  </div>


                </div>
              </InputCard>

              <InputCard headlineText={requestBodyHeadlineTitle} headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <JSONPretty id="json-pretty" data={requestBody} />
                </div>
              </InputCard>

              <InputCard headlineText="Response body" headlineSubText="">
                <div className={classNames({ aaa: true })}>
                  <JSONPretty id="json-pretty" data={responseBody} />
                </div>
              </InputCard>


            </div>


          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  let businessReportingLogs = [];
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  try {
    businessReportingLogs = businessIdentity.businessReportingLogs;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business logs');
  }

  // Attempt to find the id of the log and update state whether it was found or not.
  const businessApiLogId = ownProps.match.params.businessApiLogId;
  console.error(`businessApiLogId: ${businessApiLogId}`);
  let foundApiLogIdLocalState = false;
  let logEntry = {};
  try {
    console.warn(businessReportingLogs.find(log => log.id === businessApiLogId));
    logEntry = businessReportingLogs.find(log => log.id === businessApiLogId);
    if (logEntry !== undefined) {
      foundApiLogIdLocalState = true;
    } else {
      console.error('businessReportingLogs: DID NOT find a record');
    }
  } catch (e) {
    console.error('mapStateToProps | Unable to find the log id');
  }


  //console.log('foundApiLogIdLocalState');
  //console.log(foundApiLogIdLocalState);
  //console.log('logEntry');
  //console.log(logEntry);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    businessReportingLogs: Object.assign([], businessReportingLogs),
    foundApiLogIdLocalState,
    logEntry: Object.assign({}, logEntry),
    user: state.User,
  };

};

const mapDispatchToProps = {

};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
