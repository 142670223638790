import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setFilterServicesSelections, setFilterServicesTransportBus, setFilterServicesTransportTrain } from 'Actions';



/*
<ButtonGroup data={data} init={} state={}/>
<ButtonGroup data={transport_train_data} init={transport_train_data} state={filter_services_transport_bus} />

//Working scenarios
<ButtonGroup data={transport_train_data} init={transport_bus_init} state="filter_services_transport_bus" />
<ButtonGroup data={transport_bus_data} init={transport_bus_init} state="filter_services_transport_bus"/>
<ButtonGroup init={transport_bus_init} state="filter_services_transport_bus"/>
<ButtonGroup init={transport_bus_init} />
<ButtonGroup data={transport_bus_data} init={transport_bus_init}/>

//USAGE
> If state is set, populate the data with state
> if state is not set: 
  > if data is set, populate with data (passed in paramaters specific for this object)
  > if data it not set, load from init

Reducer: SET_FILTER_SERVICES_TRANSPORT_BUS


//Data Structure
  data: [
    {
      title: '50m',
      value: 50,
      selected: false,
    },
    {
      title: '100m',
      value: 100,
      selected: true,
    },
    {
      title: '200m',
      value: 200,
      selected: false,
    },
    {
      title: '300m',
      value: 300,
      selected: false,
    },
    {
      title: '400m',
      value: 400,
      selected: false,
    },
    {
      title: '500m',
      value: 500,
      selected: false,
    }
  ],
*/

/*
Constructor:
> set everything to init (initial load)

componentWillMount:
> check if state is set, if so, update with the current state



state: 
> data        :what will be displayed
> dispatch    :function called to dispatch

*/





class ButtonGroup extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.init,
      reducer: '',
    };
    let dataLoaded = false;
  }

  componentWillMount() {
    console.log("WillMount - ButtonGroup");
    this.LoadDataFromState();
  }

  LoadDataFromState() {
    if (this.props.state) {
      switch(this.props.state) {
        case 'filter_services_transport_bus':
          this.state.reducer = 'filter_services_transport_bus';
          this.filter_services_transport_bus();
          return;
        case 'filter_services_transport_train':
          this.state.reducer = 'filter_services_transport_train';
          this.filter_services_transport_train();
          return;
        default:
          console.log('failed loading state');
          return;
      }
    } else {
      this.LoadDataFromProps();
    } 
  }

  filter_services_transport_bus() {
    if ( Array.isArray(this.props.filter_services_transport_bus) ) {
      if (this.props.filter_services_transport_bus.length > 0) {
        this.state.data = this.props.filter_services_transport_bus;
        this.dataLoaded=true;
        console.log('Set via state - bus');
      } else {
        this.LoadDataFromProps();
      }
    } else {
      console.log('failed to set via state - bus');
      this.LoadDataFromProps();
    }
  }
  filter_services_transport_train() {
    if ( Array.isArray(this.props.filter_services_transport_train) ) {
      if (this.props.filter_services_transport_train.length > 0) {
        this.state.data = this.props.filter_services_transport_train;
        this.dataLoaded=true;
        console.log('Set via state - train');
      } else {
        this.LoadDataFromProps();
      }
    } else {
      console.log('failed to set via state - train');
      this.LoadDataFromProps();
    }
  }


  LoadDataFromProps() {
    console.log("LoadDataFromProps");
    console.log(this.dataLoaded);
    if (!this.dataLoaded) {
      if (this.props.data) {
        this.state.data = this.props.data;
        this.dataLoaded=true;
        console.log('Set via data');
      } else {
        this.state.data = this.props.init;
        this.dataLoaded=true;
        console.log('Set via init');
      }
    }
  }


  

  componentDidMount() {
    console.log("DidMount - ButtonGroup");
  }

  componentWillUpdate() {
    console.log("WillUpdate - ButtonGroup");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("DidUpdate - ButtonGroup");
  }



  handleChange_filter_services_transport_bus(dataCategory) {
    let category = dataCategory.category;
    let subCategory = dataCategory.subcategory;
    let selections = 0;
    //action: update the dataset
    this.props.setFilterServicesTransportBus(this.state.data);
    //action: check number of selections and update
    this.state.data.map((item, index) => {
      if (item.selected === true) {
        selections += 1;
      }
    });
    this.props.setFilterServicesSelections(
      {category: category, subcategory: subCategory, selections: selections}
    );
  }

  handleChange_filter_services_transport_train(dataCategory) {
    let category = dataCategory.category;
    let subCategory = dataCategory.subcategory;
    let selections = 0;
    //action: update the dataset
    this.props.setFilterServicesTransportTrain(this.state.data);
    //action: check number of selections and update
    this.state.data.map((item, index) => {
      if (item.selected === true) {
        selections += 1;
      }
    });
    this.props.setFilterServicesSelections(
      {category: category, subcategory: subCategory, selections: selections}
    );
  }



  handleClick = (evt) => {
    console.log('====clicked item====');    //test
    evt.persist();    //test
    console.log(evt);    //test
    //console.log(evt.target.dataset.id);
    let dataId = evt.target.dataset.id;
    let data = this.state.data;
    data[dataId]['selected'] = !data[dataId]['selected'];

    this.setState({data: data},() => {
      let selections = 0;
      console.log('REDUCER: ');
      console.log(this.state.reducer);
      switch(this.state.reducer) {
        case 'filter_services_transport_bus':
          this.handleChange_filter_services_transport_bus({category: "transport", subcategory: "bus"});
          return;

        case 'filter_services_transport_train':
          this.handleChange_filter_services_transport_train({category: "transport", subcategory: "train"});
          return;

        default:
          console.log('failed loading state reducer');
          return;
      }
      
    });
  }

  render() {
    let { data } = this.state;
    let { filter_services_transport_bus } = this.props;

    const ButtonGroupItems = () => {
      let thisElActive=false;
      let lastElActive=false;
      return data.map((item, index) => {
        let showBorder = false;
        if (item.selected) {
          thisElActive=true;
        } else {
          thisElActive=false;
        }
        if (thisElActive==true && lastElActive==true) {
          //Add a border to the left
          showBorder=true;
        }
        if (thisElActive) {
          lastElActive=true;
        } else {
          lastElActive=false;
        }
        return (
          <div 
            className={classNames({ 
              buttonGroupItem: true, 
              buttonGroupItemActive: item.selected, 
              buttonGroupItemBorderLeft: showBorder })}
            value={item.title}
            data-id={index}
            data-value={item.value}
            onClick={this.handleClick}
          >
            {item.title}
          </div>
        )
        
      });
    }
    /*
    const Test = () => {
      return filter_services_transport_bus.map((item, index) => {
        return <div>{item.title},</div>;
      })
      
    }

    const ABC = () => {
      if ( Array.isArray(filter_services_transport_bus) ) {
        console.log('check if its an array - ok');
        return <div>isArray,</div>
      } 
    }
    */

    


    return (
      <div className={classNames({ outlineBtnWrapper: true })}>

        <div className={classNames({ buttonGroup: true })}>
          <ButtonGroupItems />
        </div>

      </div>
    );
  }
}

ButtonGroup.propTypes = {

};

ButtonGroup.defaultProps = {

};

const mapStateToProps = state => ({
  filter_services_transport_bus: state.UserSearch.filter_services_transport_bus,
  filter_services_transport_train: state.UserSearch.filter_services_transport_train,
});

const mapDispatchToProps = {
  setFilterServicesTransportBus,
  setFilterServicesTransportTrain,
  setFilterServicesSelections,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ButtonGroup);

