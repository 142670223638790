import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');
import Select from 'react-select';


// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';
import { AddressInputLayoutsInternationalV1 } from 'Components/ShcAdminModulesWorkQueues';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class Instructions_phoneNumber extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)

    this.state = {
      apiTimerCollection: [], // List of active timers.
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { 
      phoneNumber, phoneHasIVR, phoneIvrMenuSelection, phoneHasQueue, identity
    } = this.props;

    return (
      <div>
        <TextStyle size="h5">
        <ol>
          <li>Dial the phone number: {phoneNumber}</li>
          {phoneHasIVR === true && (
            <li>Enter in the dial-tone (DTMF) digits after listening to each message: {phoneIvrMenuSelection}</li>
          )}
          {phoneHasQueue === true && (
            <li>The phone number has an agent queue. Await for a maximum time of 10 minutes.</li>
          )}
          <li>Await an call centre agent to answer the phone call.</li>
          <li>Greet, provide details regarding this call. Example below.</li>
          <li>Hello, I am calling from GUARD POINT. We've been instructed to contact this business phone number and provide a verification code. 
            Would you be able to provide the following code to "Person's name"?
          </li>
          <li>If the agent is happy to pass on the code, provide the verification code: A445533 to the agent.</li>
        </ol>
        </TextStyle>
      </div>
    );
  }
}



Instructions_phoneNumber.defaultProps = {
};


Instructions_phoneNumber.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Instructions_phoneNumber);
