import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


import { HashLink } from 'react-router-hash-link';
// Routing
//import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { SimpleDropdown } from 'Components/ShcMenus';
import { StandardBtn } from 'Components/ShcButtons';
import { StandardText } from 'Components/ShcText';

// Actions
import { setSidebarState, setAppbarHeight } from 'Actions';


class ShcPublicAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.state = {
      sidebarOpenState: this.props.sidebarOpenState,
      appbarHeight: this.props.appbarHeight,

      open: false,
      auth: true,
      anchorEl: null,
      isAuthenticated: null,
      isAdmin: null,
    };
  }
  

  componentDidMount() {
    console.log("componentDidMount - ShcPublicAppBar");
    console.log("appbarHeight: "+ this.state.appbarHeight);
    this.props.setSidebarState(false);
  }
  


  handleDrawerOpen = () => {
    console.log('handleDrawerOpen - set to open:true');
    this.setState({ open: true });
    this.props.setSidebarState(true);
  };

  handleDrawerClose = () => {
    console.log('handleDrawerClose - set to open:false');
    this.setState({ open: false });
    this.props.setSidebarState(false);
  };

  render() {
    const { sidebarOpenState, title, isAuthenticated, isAdmin, forceUserRegistration } = this.props;
    
    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";

    const MenuItemUserAccount = () => {
      return (
        <div className={classNames({ShcMenuItemHeaderContainer: true})}>
          <div className={classNames({text_h4: true, color_primary_dark: true, wordWrapBreak: true})}>
          My Account
          </div>
          <div className={classNames({text_h7: true, color_primary_dark: true})}>
          {this.props.user.id}
          </div>
        </div>
      )
    }

    const mainUserMenuAuth = [
      {
        title: <MenuItemUserAccount/>,
        link: '/account',
        icon: 'fas fa-user-circle',
        divideAfter: true,
      },
      {
        title: 'Get the App',
        link: '/app',
        icon: 'fas fa-mobile-alt',
      },
      {
        title: 'My Businesses',
        link: '/account/business',
        icon: 'fas fa-building',
      },
      {
        title: 'Sign Out',
        link: '/logout',
        icon: 'fas fa-sign-out-alt',
        divideBefore: true,
      },
    ];

    const mainUserMenuNoAuth = [
       {
        title: 'Home',
        link: '/home',
        icon: 'fas fa-home',
      },
      {
        title: 'Challenge',
        link: '/challenge',
        icon: 'fas fa-flag',
      },
      {
        title: 'Solution',
        link: '/solution',
        icon: 'fas fa-star',
      },
      {
        title: 'App',
        link: '/app',
        icon: 'fas fa-mobile-alt',
      },
      {
        title: 'Business',
        link: '/business',
        icon: 'fas fa-building',
      },
      {
        title: 'Pricing',
        link: '/pricing',
        icon: 'fas fa-dollar-sign',
      },
      {
        title: 'Create a free account',
        link: '/sign-up',
        icon: 'fas fa-user-circle',
      },
      {
        title: 'Log In',
        link: '/login',
        icon: 'fas fa-user-circle',
        divideBefore: true,
      },
    ];


    return (
      <div className={classNames({abc:true})}>

        <div className={classNames({shcHeader_AppBar:true})}>
          <div className={classNames({shcHeader_appbarContainer:true})}>

            {/**********************************************************************************
             * TITLE
             ***********************************************************************************/}

            <div className={classNames({shcHeader_Title:true, padL20: true})}>
              {this.props.windowWidth < 460 ?
                <Link
                to={{
                  pathname: "/",
                }}
                className={classNames({textDecoration: true, colour_white: true})}>
                  GP
                </Link>
              :
                <Link
                to={{
                  pathname: "/",
                }}
                className={classNames({textDecoration: true, colour_white: true})}>
                  Guard Point
                </Link>
              }
            </div>

            {/**********************************************************************************
             * LINK ITEMS
             ***********************************************************************************/}
   
            {this.props.windowWidth < 460 ?
            <div className={classNames({displayInherit:true, shcHeader_navBarControls: true})}>
              {/* < 460: */}
              {this.props.windowWidth > 320 && (
                <div className={classNames({displayInherit:true, shcHeader_navBarControls: true})}>
                {/* < 460 && > 320: Only show the elipse for drop down more links */}
                <div className={classNames({displayInherit:true})}>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/business",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Business"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>
                  
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/challenge#the-challenge"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Challenge"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>
  
                    </div>
                  </div>
  
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/solution#the-solution"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Solution"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>
  
                    </div>
                  </div>
          
                </div>
              </div>
              )}
            </div>
            :
            <div className={classNames({displayInherit:true, shcHeader_navBarControls: true})}>
              {/* > 460:  Based on size of screen show more link (scale up with additional drop down) */}
              {this.props.windowWidth < 800 ?
              <div className={classNames({displayInherit:true})}>
                {/* > 460 && < 800: Show a subset */}
                <div className={classNames({displayInherit:true})}>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/business",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Business"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/challenge#the-challenge"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Challenge"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>

                    </div>
                  </div>

                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/solution#the-solution"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Solution"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>

                    </div>
                  </div>

                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                        <Link
                          to={{
                            pathname: "/login",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Login"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-secondary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                          />
                        </Link>
                    </div>
                  </div>

                </div>
              </div>
              :
              <div className={classNames({displayInherit:true})}>
                {/* > 800: Show the main set */}
                <div className={classNames({displayInherit:true})}>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/business",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Business"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/challenge#the-challenge"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Challenge"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>

                    </div>
                  </div>

                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <HashLink
                          to="/solution#the-solution"
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Solution"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </HashLink>

                    </div>
                  </div>

                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/app",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="App"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>

                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/pricing",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Pricing"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>
                  {!isAuthenticated && (
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                      
                        <Link
                          to={{
                            pathname: "/sign-up",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="FREE Sign Up"
                          tooltip=""
                          action=""
                          classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                          />
                        </Link>

                    </div>
                  </div>
                  )}
                  {!isAuthenticated && (
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
                        <Link
                          to={{
                            pathname: "/login",
                          }}
                          className={classNames({textDecoration: true,})}>
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Login"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-secondary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                          />
                        </Link>
                    </div>
                  </div>
                  )}

                </div>
              </div>
              }
            </div>
            }





            {/**********************************************************************************
             * LOADING / API CALLS
             ***********************************************************************************/}

            {this.props.apiInProgress>0 ? 
            <div>
              <div className={classNames({fullHeight: true, flex: true, 
                flexColumn: true, flexJustifyCenter: true})}>
                <i className={`fas fa-sync fa-spin`}></i>
              </div>
            </div>
            : ""
            }

            {/**********************************************************************************
             * ADDITIONAL / DROPDOWN 
             ***********************************************************************************/}

            {this.props.windowWidth < 460 ?
            <div>
              {/* Only show the elipse for drop down more links */}
              <SimpleDropdown 
                className={classNames({text_h3: true})}
                debug={false} menuWidth={220} menuFormat="icons" 
                menuTheme="primaryUserAccount" menuItems={mainUserMenuNoAuth}>
                <StandardText
                  icon="fas fa-ellipsis-v"
                  iconLocation=""
                  text=""
                  tooltip=""
                  action=""
                  classes="shctext shctext-default-white shctext-rounded text_s16"
                />
              </SimpleDropdown>
            </div>
            :
            <div>
              {/* > 460:  Based on size of screen show more link (scale up with additional drop down) */}
              {this.props.windowWidth < 800 ?
              <div>
                {/* > 460 && < 800: Show a subset */}
                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuNoAuth}>
                  <StandardText
                    icon="fas fa-ellipsis-v"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>
              </div>
              :
              <div>
                {/* > 800: Show the main set */}
                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuNoAuth}>
                  <StandardText
                    icon="fas fa-ellipsis-v"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>
              </div>
              }
            </div>
            }


            {/**********************************************************************************
             * AUTHENTICATED  
             ***********************************************************************************/}


            {isAuthenticated && (
            <div>
              {this.props.windowWidth < 460 ?
              <div>
                {/* Only show the elipse for drop down more links */}
                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuAuth}>
                  <StandardText
                    icon="fas fa-user-circle"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>
              </div>
              :
              <div>
                {/* > 460:  Based on size of screen show more link (scale up with additional drop down) */}
                {this.props.windowWidth < 800 ?
                <div>
                  {/* > 460 && < 800: Show a subset */}
                  <SimpleDropdown 
                    className={classNames({text_h3: true})}
                    debug={false} menuWidth={220} menuFormat="icons" 
                    menuTheme="primaryUserAccount" menuItems={mainUserMenuAuth}>
                    <StandardText
                      icon="fas fa-user-circle"
                      iconLocation=""
                      text=""
                      tooltip=""
                      action=""
                      classes="shctext shctext-default-white shctext-rounded text_s16"
                    />
                  </SimpleDropdown>
                </div>
                :
                <div>
                  {/* > 800: Show the main set */}
                  <SimpleDropdown 
                    className={classNames({text_h3: true})}
                    debug={false} menuWidth={220} menuFormat="icons" 
                    menuTheme="primaryUserAccount" menuItems={mainUserMenuAuth}>
                    <StandardText
                      icon="fas fa-user-circle"
                      iconLocation=""
                      text=""
                      tooltip=""
                      action=""
                      classes="shctext shctext-default-white shctext-rounded text_s16"
                    />
                  </SimpleDropdown>
                </div>
                }
              </div>
              }
            </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}

ShcPublicAppBar.propTypes = {

};


const mapStateToProps = (state, ownProps) => {
  let windowSizeWidth = 720;
  let windowSizeHeight = 360;
  try {
    windowSizeWidth = state.UI.windowSize.windowWidth;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window width');
  }
  try {
    windowSizeHeight = state.UI.windowSize.windowHeight;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window height');
  }

  return {
    //businessIdentities: Object.assign({}, businessIdentities),
    user: state.User,

    windowWidth: windowSizeWidth,
    windowHeight: windowSizeHeight,
    

    sidebarOpenState: state.UI.sidebarOpen,
    appbarHeight: state.UI.appbarHeight,
    isAuthenticated: state.Auth.isAuthenticated,
    isAdmin: state.Auth.isAdmin,
    forceUserRegistration: state.User.forceUserRegistration,
    apiInProgress: state.ApiActions.ApiInProgress,
  };

};

const mapDispatchToProps = {
  setSidebarState,
  setAppbarHeight,
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcPublicAppBar);