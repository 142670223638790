import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import Countdown from 'react-countdown-now';
import Timer from 'react-compound-timer';

import emailTextTemplate from './emailTokenTextFormat.txt';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';

// Actions


class InfoCopyLabelEmail extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.copyToEmailTemplateToClipboard = this.copyToEmailTemplateToClipboard.bind(this);
    this.state = {
      token: this.props.token,
      copySuccess: '',
      tokenExpired: false,
      copyEmailSuccess: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps - InfoCopyLabelEmail');
    console.log(`${nextProps.token} !== ${prevState.token}`);
    if (nextProps.token !== prevState.token) {
      return {
        tokenExpired: false,
        token: nextProps.token,
      };
    }

    return null;
  }


  componentDidMount() {
    let utcDateNow = new Date(Date.now());
    utcDateNow = Date.parse(utcDateNow.toUTCString());
    let timeLeftFortoken = this.state.token.tokenExpiry - utcDateNow;

    if (this.state.tokenExpired===false && timeLeftFortoken<=0) {
      console.log("set expired token to state");
      this.setState({tokenExpired: true});
    }
 
  }

  componentDidUpdate(prevProps, prevState) {
    //if (this.props !== prevProps) {
    //  console.log('componentDidUpdate - InfoCopyLabelEmail');
    //}
  }

  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.props.token.tokenEmailId;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({ copySuccess: 'Copied' });
      setTimeout(function(){
      this.setState({ copySuccess: '' });
    }.bind(this),3000);
    
  };

  copyToEmailTemplateToClipboard = () => {
    let thisEmail = emailTextTemplate;
    //console.log(thisEmail);
    //console.log("inserting the following token");
    //console.log(this.props.token);
    thisEmail = thisEmail.replace("=%=%TOKEN%=%=", this.props.token);
    //console.log("after replace has completed");
    //console.log(thisEmail);
    const el = document.createElement('textarea');
    el.value = thisEmail;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({ copyEmailSuccess: 'Copied Email Template' });
      setTimeout(function(){
      this.setState({ copyEmailSuccess: '' });
    }.bind(this),3000);
  };

  handleExpiredToken() {
    console.log('Token has expired - remove/grey out token - they may be in the middle of talking to the person');
  }


  render() {

    const { subHeading, token } = this.props;

    let utcDateNow = new Date(Date.now());
    //console.log(utcDateNow);
    //console.log(utcDateNow.toTimeString());
    //console.log(utcDateNow.toUTCString());
    utcDateNow = Date.parse(utcDateNow.toUTCString());
    //console.log("utcDateNow");
    //console.log(utcDateNow);
    //console.log("tokenVoiceExpiry");
    //console.log(token.tokenVoiceExpiry);
    let timeLeftFortoken = token.tokenExpiry - utcDateNow;
    //console.log("timeLeftFortoken");
    //console.log(timeLeftFortoken);

    return (
      <div>
        <div className={classNames({ ShcTextInfoCopyLabelContainer: true })}>
          <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>

            <div className={classNames({ ShcTextInfoCopyLabelSubHeaderLeft: true })}>
              {subHeading}
            </div>
            <div className={classNames({ ShcTextInfoCopyLabelSubHeaderRight: true })}>
              {this.props.token &&
                <Timer
                  key={this.props.token.tokenEmailId}
                  initialTime={timeLeftFortoken}      //{token.tokenEmailExpirySeconds}    //300000 (5mins)
                  direction="backward"
                  checkpoints={[
                      {
                          time: 4000,
                          callback: () => console.log('4 seconds to go.'),
                      },
                      {
                          time: 1000,
                          callback: () => console.log('1 second to go'),
                      },
                      {
                          time: 0,
                          callback: () => {
                            console.log("token expired");
                            this.setState({tokenExpired: true})
                          },
                      },
                  ]}
              >
                  {(reset) => (
                    <React.Fragment>
                      <div>
                        <Timer.Minutes /> mins&nbsp;
                        <Timer.Seconds /> secs 
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              }
            </div>
          </div>
          <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>
            <div id="connectManuallyCardEmailTokenField" name="connectManuallyCardEmailTokenField" 
              className={classNames({ ShcTextInfoCopyLabelContent: true, flexGrow: true, wordWrapBreak: true })}>
              {this.props.children}
            </div>
            <div
              className={classNames({ ShcTextInfoCopyLabelControls: true })}
              onClick={this.copyToClipboard}
            >
              <i className="far fa-clone" />
            </div>
          </div>
        </div>


        <div>

          <div>
            {this.state.copySuccess!=='' &&
            <MessageHighlight>
              Token copied to clipboard.

            </MessageHighlight>
            }

            {this.state.tokenExpired &&
            <MessageHighlight type="danger" key={this.props.token.tokenEmailId}>
              This token has expired. The customer will be prompted not to accept it.

            </MessageHighlight>
            }
          </div>

        </div>

        <div>
          <br/>
          <StandardBtn
            icon=""
            iconLocation=""
            text="Copy Customer Email Template"
            tooltip=""
            action=""
            classes="shcbtn shcbtn-info shcbtn-rounded"
            handleClick={this.copyToEmailTemplateToClipboard}
          />
        </div>
        <div>
          {this.state.copyEmailSuccess!=='' &&
            <MessageHighlight>
              Copied Email Template

            </MessageHighlight>
            }
        </div>
      </div>

    );
  }
}

InfoCopyLabelEmail.propTypes = {

};

const mapStateToProps = state => ({
  //activeEmailToken: state.BusinessConnect.ActiveEmailToken.tokenEmail,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InfoCopyLabelEmail);
