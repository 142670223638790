import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";

import Dropdown from 'react-dropdown';


// CSS
import componentStyles from './styles.css';

// Components
import { NotificationManagementPanelV1, } from 'Components/ShcModulesNotificationManagement';


import { Breadcrumb } from 'Components/ShcNavs';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';



//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { 
  setupNewUserNotificationMethod, 

  reduxDataInsert,
  userNotificationsAddNewIdentity,
  userNotificationsRemoveIdentity,


  addUserIdentities,
  updateUserIdentities,
  removeUserIdentities,
  
  setUserProfile, toggleOverlayOnScreenResponsive, verifyUserPhoneNumberWithCall, setFirebaseAuthObj } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange, validateAlphaNumericNoSpacesOnly } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.userNotificationsRequestCreateItem = this.userNotificationsRequestCreateItem.bind(this);
    this.userNotificationsRequestNewMethod = this.userNotificationsRequestNewMethod.bind(this);
    this.handleSelectInputChange_newNotificationMethod = this.handleSelectInputChange_newNotificationMethod.bind(this);
    this.userNotificationsAddNewMethod = this.userNotificationsAddNewMethod.bind(this);

    this.removeUserNotificationIdentityCard = this.removeUserNotificationIdentityCard.bind(this);
    this.handleMobileAppNotificationCodeInput = this.handleMobileAppNotificationCodeInput.bind(this);
    this.handleMobileAppNotificationCodeSubmit = this.handleMobileAppNotificationCodeSubmit.bind(this);


    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.onChangeInputTimer = this.onChangeInputTimer.bind(this);

    this.state = {
      user: this.props.user,    //The main user object
      identities: this.props.identities,    //All the users identities
      
      notificationIdentityId: '',     //If a user wants to select email as a method

      mobileAppLookupCode: '',
      mobileAppLookupCodeInputErrors: false,

      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountUserSettingsIdentitiesPage");
    //console.log(nextProps);
    //console.log(prevState);
    let nextUser = prevState.user;
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      nextUser = nextProps.user;
    } 
    let nextIdentities = prevState.identities;
    if (nextProps.identities !== undefined && nextProps.user !== prevState.identities) {
      nextIdentities = nextProps.identities;
    } 
    return { 
      user: nextUser, 
      identities: nextIdentities,
    };
  }


  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }



  componentDidUpdate(prevProps, prevState) {
    
  }

  handleSelectInputChange_newNotificationMethod(evt) {
    let notificationIdentityId = evt.value;
    this.setState({
      notificationIdentityId: notificationIdentityId,
    })
  }

  userNotificationsAddNewMethod() {
    //Create a new notification method for this email. 
    let data = {
      identityId: this.state.notificationIdentityId,
    }
    this.props.setupNewUserNotificationMethod({data});

    this.closeOverlayOnScreen("userNotificationsRequestNewMethod");

  }


  removeUserNotificationIdentityCard(body = {}) {
    //console.log("Remove User Notification Identity Input");
    
    const { removeItem = {} } = body;

    //console.log('removeItem');
    //console.log(JSON.stringify(removeItem));

    let apiUi = { apiActivityId: removeItem.apiActivityId };
    let data = {
      identityId: removeItem.identityId
    }
    this.props.userNotificationsRemoveIdentity({data, apiUi});

  }



  userNotificationsRequestCreateItem() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'userNotificationsAddItem', data);
  }

  userNotificationsRequestNewMethod() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'userNotificationsRequestNewMethod', data);
  }


  userIdentityAddItemWithParam(type, evt) {
    //console.log("userIdentityAddItemWithParam");
    //console.log(type);
    this.closeOverlayOnScreen("userNotificationsAddItem");

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    const apiUi = { apiActivityId };
    this.props.addUserIdentities({type: type, apiUi});
  }














  onChangeInputTimer() {
    //On Text Change Event Handling (reducing API impact)
    this.inputTimer = setTimeout(() => { 
      this.setState({     
        apiTimer: "inactive",
      });
      this.props.setUserProfile(this.props.userId,this.state);
     }, 4000);
  }
  onChangeInputTextHandler = (evt) => {
    /*
    Challenge:  Keep redux state updated however do not call the API's for user updates on every key entry.
                This causes a performance impact and a lot of API calls. 
    Solution:   Two redux-actions:
                  1. Update the users profile (with no API calls). This will maintain users local state. 
                      If there was an issue, local state would be maintained, and we could capture the data again at a later stage.
                  2. Update the users profile AND send API call. 
    Notes:      We do not need an activity timer per field as we are updating the whole state on the API call which is the focus for the delay timer.
    */
    if (this.state.apiTimer=="inactive") {
      this.setState({
        apiTimer: "active",               //Set state for the current user action
      });
      this.onChangeInputTimer();          //Call the timer to start.
    } else if (this.state.apiTimer=="active") {
      clearTimeout(this.inputTimer);     //Clear the current Timer
      this.onChangeInputTimer();         //Call the timer to start. (e.g. Reset the timer for another period)
    }

    //Update state as typing is happening
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  


  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }



  handleMobileAppNotificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      mobileAppLookupCode: event.target.value,
      mobileAppLookupCodeInputErrors: errors,
    });
  }

  handleMobileAppNotificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleMobileAppNotificationCodeSubmit');
    const { mobileAppLookupCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    const data = {
      mobileAppLookupCode: mobileAppLookupCode,
    };
    this.props.userNotificationsAddNewIdentity({ data, apiUi });
    this.closeOverlayOnScreen('userNotificationsAddItem');
    
  }






  render() {
    let userIdentities = [];
    
    this.props.validEmailIdentities.map((value, index) => {
      try {
        userIdentities.push({ value: value.identityId, label: value.identity });
      } catch (e) {
        //console.warn(e);
      } 
    })
    


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true" 
        backTo="/account/dashboard" 
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true" 
                backTo="/account/dashboard" 
                backTitle="Dashboard"
                smallTitleTF="true" 
                smallTitle="User"
                headlineTitleTF="true" 
                headlineTitle="" //{this.state.user.userProfile.firstName}
                sublineTextTF="true" 
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true" 
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>

            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })}>
                <TextStyle size="h2">Notification</TextStyle>
              </div>
              <div>
                <StandardApiBtn
                  apiActivityId="AddNotificationMethod"
                  icon=""
                  iconLocation=""
                  text="Add Notification Method"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={this.userNotificationsRequestNewMethod}
                />
              </div>
              <div>
                <StandardApiBtn
                  apiActivityId="AddNewUserIdentity"
                  icon=""
                  iconLocation=""
                  text="Add Remote User"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={this.userNotificationsRequestCreateItem}
                />
              </div>
              
            </div>
            <TextStyle size="h5">
              Log into the Guard Point mobile app for automatic device linking. Please note, we only
              automatically add your device when you "push notifications" are enabled.
            </TextStyle>


            <div>
              {
              this.props.userNotifications.length > 0 ?
                // Arrow function preserves "this"
              this.props.userNotifications.map((item, i) => (

                <li key={item._id} className={classNames({ listNone: true })}>
                   
                    <NotificationManagementPanelV1
                    deviceName={this.props.userNotifications[i].deviceName}
                    notificationDeviceUserId={this.props.userNotifications[i].userId}
  
                    type={this.props.userNotifications[i].type}
                    identity={this.props.userNotifications[i].identity}
                    identityId={this.props.userNotifications[i]._id}
                    identityLabel={this.props.userNotifications[i].identityLabel}
                    identityType="mobileapp"
                    notifyThisDevice={this.props.userNotifications[i].notifyThisDevice}
                    dataSet={this.props.userNotifications[i]}
                    key={['NotificationManagementPanelV1', item._id].join('_')}

                    verificationCode=""

                    index={i} 
                    apiEndpoint="User"
                    businessId=""
                    stateIdentifier="User"
                    identifiers={[{_id: this.props.userNotifications[i]._id}]}
                    id="userNotifications.$.identity"
                    name="userNotifications.$.identity"
                    onRemoveItem={this.removeUserNotificationIdentityCard}
                    />


                </li>

                ), this)
              : 
              (
                <div>
                  <br/>
                  <TextStyle size="h5">There are currently no configured notification devices. </TextStyle>
                  <br/>
                </div>
              )
              }
            </div>

          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="userNotificationsAddItem"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Link Another User's Mobile App"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
           <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Note:</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
                To link your mobile to this account, simply log into the app using the same
                username and password as on the Guard Point website. 
              </TextStyle>
            </div>
            
            <hr/>
  
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Link another user's mobile app</TextStyle>
              <TextStyle size="h5">This may be a family member, a carer or a guardian.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
                <ol>
                  <li>Ask the other person to open the Guard Point app on their mobile.
                    Ensure they are logged in with their own Guard Point account.
                  </li>
                  <li>Under Account Tab, choose 'App Link'.</li>
                  <li>Click 'Generate App Link Code'.</li>
                  <li>The other person can send you this code to enter into this screen.</li>
                </ol>

                <TextStyle size="h3">What will this do?</TextStyle>
                This will enable all notification destined for YOU to also be sent to 
                the OTHER PERSON. You should only perform this action if you want the other 
                person to receive business interaction notifications that are destined for yourself. <br/><br/>

                Examples of this is in the case of aged care and disability care as its primary intent. 
                This can also be used for family members.
              </TextStyle>
              <br/>
            </div>
            <div className={classNames({ abc: true })}>
              Please enter the mobile app code:<br/>



              <div>
                <div className={classNames({
                  flex: true,
                  flexJustifySpaceBetween: true,
                  flexAlignBaseline: true,
                  ShcIdentityInput_textInput_root: true,
                })}
                >
                  <div className={classNames({
                    ShcIdentityInput_textInput_child: true,
                    ShcIdentityInput_textInput_errors_icon: this.state.mobileAppLookupCodeInputErrors,
                  })}
                  >
                    <input
                      {...this.props}
                      label=""
                      margin="normal"
                      fullWidth
                      name="mobileAppCode"
                      value={this.state.mobileAppLookupCode}
                      identifiers=""
                      stateIdentifier=""
                      onChange={this.handleMobileAppNotificationCodeInput}
                      type="text"
                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.mobileAppLookupCodeInputErrors })}
                    />
                  </div>
                </div>
              </div>
              {this.state.mobileAppLookupCodeInputErrors && 
                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }

              <br/>
              <StandardApiBtn
                apiActivityId="UserNotificationAddMobileApp"
                icon=""
                iconLocation=""
                text="Add Remote User"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.handleMobileAppNotificationCodeSubmit}
              />

            </div>

            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userNotificationsAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


        <OverlayOnScreenResponsive
          id="userNotificationsRequestNewMethod"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Select Notification Method"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h4">
                By selecting an email address, we will send you notifications as soon
                as a business registers an interaction. Please note that there may be 
                delays with sending email.</TextStyle><br/>
            </div>
            <div className={classNames({ abc: true })}>
              Select a verified identity below and select Setup.<br/>

              <div className={classNames({ flex: true })}>

                <div className={classNames({ minWidth110: true, width100p: true, })}>
                  <Dropdown 
                  options={userIdentities} 
                  onChange={this.handleSelectInputChange_newNotificationMethod} 
                  value={userIdentities.find(option => option.value === this.state.notificationIdentityId)}
                  placeholder="Select..." />

                </div>
              </div>

              <div className={classNames({ flex: true, padT20: true, })}>

                <StandardApiBtn
                  apiActivityId="UserNotificationAddMobileApp"
                  icon=""
                  iconLocation=""
                  text="Add Notification Method"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={this.userNotificationsAddNewMethod}
                />
              </div>

              <br/>
              <TextStyle size="h5">
                If you cannot see your email address, ensure you have an email identity setup and
                that the identity is verified. 
              </TextStyle><br/>

            </div>

            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userIdentitiesAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>




      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let identities = state.User.userIdentities;
  let validEmailIdentities = [];
  try {
    for (let x = 0; x < identities.length; x++) {
      if (identities[x].type === 'email' && identities[x].verificationStatus === true) {
        validEmailIdentities.push({identity: identities[x].identity, identityId: identities[x]['_id']});
      }
    }
  } catch (e) {
    validEmailIdentities = [];
  }
  if (validEmailIdentities === undefined) {
    validEmailIdentities = [];
  }

  return {
    validEmailIdentities: Object.assign([], validEmailIdentities),
    userNotifications: Object.assign([], state.User.userNotifications),
    userRemoteNotifications: Object.assign([], state.User.userRemoteNotifications),


    identities: Object.assign({}, state.User.userIdentities),
    user: state.User,
    userId: state.User.uid,
  };

};


const mapDispatchToProps = {
  setupNewUserNotificationMethod,

  reduxDataInsert,
  userNotificationsAddNewIdentity,
  userNotificationsRemoveIdentity,


  addUserIdentities,
  updateUserIdentities,
  removeUserIdentities,



  setUserProfile,
  toggleOverlayOnScreenResponsive,
  verifyUserPhoneNumberWithCall,
  setFirebaseAuthObj
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);

