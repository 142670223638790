import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
//import {  } from 'Actions';


class StepBusinessIdentity extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child

    this.state = {
      businessIdentity: this.props.value,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps - StepBusinessWebsite");
    //We are comparing the inbound value (websiteStep) which is an object with the local state paramaters

    console.log(JSON.stringify(nextProps.value) +" !== "+ JSON.stringify(prevState.businessIdentity));
    if (nextProps.value !== undefined && nextProps.value !== prevState.businessIdentity) {
      return { businessIdentity: nextProps.value };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - StepBusinessWebsite");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform up of state for StepBusinessWebsite");
      this.setState({
        businessIdentity: this.props.value,
      });
    }
  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    console.log("===============================");
    console.log("SEND TO PARENT: inputTextChange");

    //Update local state (do not update fields that are shared with parent)
    this.setState({
      [evt.target.name]: evt.target.value,
    });

    let updateData= {
      field: evt.target.name,
      value: evt.target.value,
    }
    this.props.onChangeStepInputHandler(this.props.id, this.state.businessIdentity, updateData);
    
  }


  render() {
    const { id, name, onChangeStepInputHandler, value } = this.props;

    let activityType = this.state.businessIdentity.type;
    let inputCardHeadlineText = "";
    let identityHeadlineText = "";
    let identityAdditionalText = "";
    if (activityType==="website") {
      inputCardHeadlineText="Website Address";
      identityHeadlineText = "Website";
      identityAdditionalText = "Format: https://";
    } else if (activityType==="email") {
      inputCardHeadlineText="Email Address";
      identityHeadlineText = "Email";
      identityAdditionalText = "Your business primary email address used for customer contact.";
    } else if (activityType==="phone") {
      inputCardHeadlineText="Phone Number";
      identityHeadlineText = "Phone Number";
      identityAdditionalText = "Your business primary phone number used for customer contact.";
    } else {
      inputCardHeadlineText="Unknown Identity Type"
    }
  
    return (
      <InputCard headlineText={inputCardHeadlineText} headlineSubText="">

        <InputCardItem headlineText={identityHeadlineText} headlineSubText="" additionalText={identityAdditionalText}>
          <InputTextV2 id="identity" name="identity" label="" value={this.state.businessIdentity.identity} onChangeInputTextHandler={this.onChangeInputTextHandler} />
        </InputCardItem>

        <InputCardItem headlineText="Label" headlineSubText="This is a private label you can attach to this identity." additionalText="Customers will not see this.">
          <InputTextV2 id="identityLabel" name="identityLabel" label="Private custom label" value={this.state.businessIdentity.identityLabel} onChangeInputTextHandler={this.onChangeInputTextHandler} />
        </InputCardItem>

      </InputCard>
    );
  }
}

StepBusinessIdentity.propTypes = {
  
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StepBusinessIdentity);

/*
 <div className={classNames({ flex: true, width200pix: true })} >
  {JSON.stringify(this.state.businessIdentity)}
</div>
*/