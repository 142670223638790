import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText } from 'Components/ShcInput';
import { CardBasic } from 'Components/ShcCards';

// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
// import {  } from 'Actions';

function hasValue(value) {
  if (typeof (value) !== undefined &&
        value !== undefined &&
        value !== '' &&
        value !== null
  ) {
    return true;
  }
  return false;
}

class VerifyResultsPublic extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token,
      verifications: this.props.verifications,
      thisVerification: this.props.thisVerification,
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps - verifyresultspublic');
    if (prevState.thisVerification !== nextProps.thisVerification) {
      console.log('thisVerification - mismatch - update state');
      return {
        thisVerification: nextProps.thisVerification,
        verifications: nextProps.verifications,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate - verifyresultspublic');
  }

  render() {
    const { token } = this.props;

    const InteractionItendedForYou = () => (
      <div>
        Want to know if this interaction was intended for you? Sign up and any future interactions
        we can advise if they were intended for you, or not.
      </div>
    );

    const TermsOfUserSummary = () => (
      <div>
        <TextStyle size="h3" weight="bold">Terms of Use</TextStyle>
        Using our service, you are bound to the <Link to="/terms-and-conditions">Terms of Conditions Agreement</Link>.
        <br />
        <br />
        <TextStyle size="h5">
          Our service is intended to protect the people from spam and scams worldwide. While we will
          endevour to protect you; you should validate and verify the interaction you have received
          to determine if it is legitimate or not. You can view our help section.
        </TextStyle>
      </div>
    );


    const ResultsHeader = () => {
      if (hasValue(this.state.thisVerification.status)
      ) {
        console.log('ResultsHeader');
        console.log(this.state.thisVerification.status);

        // We have results
        const { transactionExpireDate, transactionExpired, transactionTime, transactionType } = this.state.thisVerification.status;


        if (transactionExpired===false) {
          console.log('ResultsHeader - token not expired');
          // Healthy
          return (
            <div className={classNames({ VerifyResultsPublic_Header: true })}>
              <div className={classNames({ flex: true })}>
                <div className={classNames({
                    VerifyResultsPublic_headerOverallStatus: true,
                    VerifyResultsPublic_headerOverallStatus_healthy: true,
                    VerifyResultsPublic_headerOverallStatus_unhealthly: false,
                    VerifyResultsPublic_headerOverallStatus_questionable: false,
                    VerifyResultsPublic_headerOverallStatus_expired: false,
                    })}
                >

                  <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>

                    <i className="fas fa-check" />
                  </div>

                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                      <div className={classNames({ text_h6: true })}>Interaction Details</div>
                      <div className={classNames({ text_h4: true })}>{this.state.thisVerification.transaction.tokenEmailId}</div>
                    </div>
                  </div>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })} />
                  </div>
                </div>

              </div>
            </div>
          );
        }
        if (transactionExpired===true) {
          // Expired
          console.log('ResultsHeader - token expired');
          return (
            <div className={classNames({ VerifyResultsPublic_Header: true })}>
              <div className={classNames({ flex: true })}>
                <div className={classNames({
                      VerifyResultsPublic_headerOverallStatus: true,
                      VerifyResultsPublic_headerOverallStatus_healthy: false,
                      VerifyResultsPublic_headerOverallStatus_unhealthly: false,
                      VerifyResultsPublic_headerOverallStatus_questionable: false,
                      VerifyResultsPublic_headerOverallStatus_expired: true,
                      })}
                  >

                  <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>

                    <i className="fas fa-exclamation" />
                  </div>

                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                      <div className={classNames({ text_h6: true })}>Interaction Details</div>
                      <div className={classNames({ text_h4: true })}>{this.state.thisVerification.transaction.tokenEmailId}</div>
                    </div>
                  </div>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })} />
                  </div>
                </div>

              </div>
            </div>
          );
        }
        return (
          <div className={classNames({ VerifyResultsPublic_Header: true })}>
            <div className={classNames({ flex: true })}>
              <div className={classNames({
                  VerifyResultsPublic_headerOverallStatus: true,
                  VerifyResultsPublic_headerOverallStatus_healthy: false,
                  VerifyResultsPublic_headerOverallStatus_unhealthly: true,
                  VerifyResultsPublic_headerOverallStatus_questionable: false,
                  VerifyResultsPublic_headerOverallStatus_expired: false,
                  })}
              >

              <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>

                <i className="fas fa-times" />
              </div>

            </div>
              <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                  <div className={classNames({ text_h6: true })}>Interaction Details</div>
                  <div className={classNames({ text_h4: true })}>NOT FOUND</div>
                </div>
              </div>
              <div>
                <div className={classNames({ flex: true, flexColumn: true, pad10: true })} />
              </div>
            </div>

            </div>
          </div>
        );

      }
      // No results - No token details
      return null;

    };


    const ResultsContentInteractionDetails = () => {
      if (hasValue(this.state.thisVerification.status)) {
        // We have results
        const {
          transactionExpireDate, transactionExpired, transactionTime, transactionType
        } = this.state.thisVerification.status;

        let interactionTypeText = '';
        if (transactionType === 'voice') {
          interactionTypeText = 'You received the interaction from this business via a voice call.';
        } else if (transactionType === 'email') {
          interactionTypeText = 'You received the interaction from this business via an email.';
        }


        if (transactionExpired===false) {
          // Healthy
          return (
            <CardBasic>
              <div className={classNames({ flex: true })}>
                <div className={classNames({
                    VerifyResultsPublic_headerOverallStatus: true,
                    VerifyResultsPublic_headerOverallStatus_healthy: true,
                    VerifyResultsPublic_headerOverallStatus_unhealthly: false,
                    VerifyResultsPublic_headerOverallStatus_questionable: false,
                    VerifyResultsPublic_headerOverallStatus_expired: false,
                    })}
                >
                  <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>
                    <i className="fas fa-check" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                      <div className={classNames({ text_h6: true })}>Interaction Expires: {transactionExpireDate} </div>
                      <div className={classNames({ text_h4: true })}>Interaction Healthy</div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })} >
                  <TextStyle size="h4" weight="bold">Status: Healthy</TextStyle>
                  <TextStyle size="h4">Interaction Time: {transactionTime}</TextStyle>
                  <TextStyle size="h4">Interaction Expires on: {transactionExpireDate}</TextStyle>
                  <TextStyle size="h4">Interaction Type: {transactionType}</TextStyle>
                  <hr />
                  <TermsOfUserSummary />
                  <hr />
                  <TextStyle size="h3" weight="bold">Next Steps</TextStyle>
                  <div>
                    Confirm the following:
                    <ol>
                      <li>You received the interaction from the business described below.</li>
                      <li>{interactionTypeText}</li>
                      <li>You received the interaction on the same date. The time received may vary.</li>
                      <li>View other tips and tricks ***HERE***</li>
                    </ol>
                  </div>

                  <hr />
                  <InteractionItendedForYou />
                </div>
              </div>
            </CardBasic>
          );
        }
        if (transactionExpired===true) {
          return (
            <CardBasic>
              <div className={classNames({ flex: true })}>
                <div className={classNames({
                      VerifyResultsPublic_headerOverallStatus: true,
                      VerifyResultsPublic_headerOverallStatus_healthy: false,
                      VerifyResultsPublic_headerOverallStatus_unhealthly: false,
                      VerifyResultsPublic_headerOverallStatus_questionable: false,
                      VerifyResultsPublic_headerOverallStatus_expired: true,
                      })}
                  >
                  <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>
                    <i className="fas fa-exclamation" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
                  <div>
                    <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                      <div className={classNames({ text_h6: true })}>Interaction Expires: {transactionExpireDate} </div>
                      <div className={classNames({ text_h4: true })}>Interaction Expired</div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })} >
                  <TextStyle size="h4" weight="bold">Status: Expired</TextStyle>
                  <TextStyle size="h4">Interaction Time: {transactionTime}</TextStyle>
                  <TextStyle size="h4">Interaction Expired on: {transactionExpireDate}</TextStyle>
                  <TextStyle size="h4">Interaction Type: {transactionType}</TextStyle>

                  <hr />
                  <TermsOfUserSummary />
                  <hr />
                  <TextStyle size="h3" weight="bold">Next Steps</TextStyle>
                  <div>
                    <ol>
                      <li>We recommend ignoring and/or deleting this interaction as the token for this interaction has expired.</li>
                      <li>If you know the sender, request them via your normal communication channel to send/call again with a new token.</li>
                      <li>View other tips and tricks ***HERE***</li>
                    </ol>
                  </div>
                  <hr />
                  <InteractionItendedForYou />
                </div>
              </div>
            </CardBasic>
          );
        }
        return (
          <CardBasic>
            <div className={classNames({ flex: true })}>
              <div className={classNames({
                    VerifyResultsPublic_headerOverallStatus: true,
                    VerifyResultsPublic_headerOverallStatus_healthy: false,
                    VerifyResultsPublic_headerOverallStatus_unhealthly: true,
                    VerifyResultsPublic_headerOverallStatus_questionable: false,
                    VerifyResultsPublic_headerOverallStatus_expired: false,
                    })}
                >

                <div className={classNames({ VerifyResultsPublic_headerStatusContainer: true })}>

                  <i className="fas fa-times" />
                </div>

              </div>
              <div className={classNames({ flex: true, flexGrow: true, flexJustifySpaceBetween: true })}>
                <div>
                  <div className={classNames({ flex: true, flexColumn: true, pad10: true })}>
                    <div className={classNames({ text_h6: true })}>Exercise caution!</div>
                    <div className={classNames({ text_h4: true })}>Interaction Not Found</div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })} >
                <TextStyle size="h4" weight="bold">Status: Not Found</TextStyle>
                <TextStyle size="h4" weight="bold">Recommendation (received via phone call):</TextStyle>
                <TextStyle size="h5">
                    Hang up immedaitely. Do not share any personal information with the caller.
                </TextStyle>
                <br />
                <TextStyle size="h4" weight="bold">Recommendation (received via email):</TextStyle>
                <TextStyle size="h5">
                    Delete the email immedaitely. Do not interact with the email or click on any links.
                </TextStyle>
                <hr />
                <TermsOfUserSummary />
                <hr />
                <TextStyle size="h3" weight="bold">Next Steps</TextStyle>
                <div>
                    Confirm the following (Interaction received via phone call):
                  <ol>
                    <li>You have not shared any personal information.</li>
                    <li>You have hung up the phone call.</li>
                    <li>If you have shared personal information, you should seek advise on next steps to take to
                      protect your personal information.
                    </li>
                  </ol>
                </div>
                <div>
                    Confirm the following (Interaction received via email):
                  <ol>
                    <li>You have not clicked on any links in the email.</li>
                    <li>You have not entered in any personal information.</li>
                    <li>You have deleted the email.</li>
                    <li>You have run a virus scan over your computer</li>
                    <li>If you believe you have clicked on any links, you should seek technical advise on confirming
                      your computer is not at risk.
                    </li>
                  </ol>
                </div>

                <hr />
                <InteractionItendedForYou />
              </div>
            </div>
          </CardBasic>
        );


      }
      return null;


    };

    const ResultsContentBusinessDetails = () => {
      console.log('ResultsContentBusinessDetails');
      if (hasValue(this.state.thisVerification.status) &&
          hasValue(this.state.thisVerification.business) &&
          hasValue(this.state.thisVerification.business.businessProfile)
      ) {
        // We have results

        const {
          businessName, businessNameVerified, businessTradingName, businessTradingNameVerified,
          headOfficeCountry, headOfficeCountryVerified, primaryNumber, primaryNumberVerified,
          primaryWebsite, primaryWebsiteVerified,
        } = this.state.thisVerification.business.businessProfile;

        return (
          <CardBasic>
            <div>
              Below are the details of the business that initated this interaction. We have prefixed the information
              below with our verification status. You can learn more about our verification status' here ***HERE***.
            </div>

            <div className={classNames({ flex: true })}>
              <div className={classNames({
                VerifyResultsPublic_verifiedBusinessContainer: true,
                VerifyResultsPublic_text_healthy: businessNameVerified,
                VerifyResultsPublic_text_questionable: !businessNameVerified,
                  })}
              >
                <i className="fas fa-times" />
              </div>
              <div className={classNames({ flexGrow: true })} >
                Business Name: {businessName}
              </div>
            </div>

            <div className={classNames({ flex: true })}>
              <div className={classNames({
                VerifyResultsPublic_verifiedBusinessContainer: true,
                VerifyResultsPublic_text_healthy: primaryWebsiteVerified,
                VerifyResultsPublic_text_questionable: !primaryWebsiteVerified,
                  })}
              >
                <i className="fas fa-check" />
              </div>
              <div className={classNames({ flexGrow: true })} >
                Website: {primaryWebsite}
              </div>
            </div>

            <div className={classNames({ flex: true })}>
              <div className={classNames({
                VerifyResultsPublic_verifiedBusinessContainer: true,
                VerifyResultsPublic_text_healthy: primaryNumberVerified,
                VerifyResultsPublic_text_questionable: !primaryNumberVerified,
                  })}
              >
                <i className="fas fa-question" />
              </div>
              <div className={classNames({ flexGrow: true })} >
                Phone Number: {primaryNumber}
              </div>
            </div>


          </CardBasic>
        );
      }
      return (
        <CardBasic>
          <div>
            <br />We have no details to show.<br />
          </div>
        </CardBasic>
      );

    };




    return (
      <div className={classNames({ VerifyResultsPublic_Container: true })}>
        <ResultsHeader key={this.state.token} />
        <div className={classNames({ ShcStepsContainerBody: true })}>
          <div>
            <br/>
            <TextStyle size="h3" weight="bold">Interaction Details</TextStyle>
            <ResultsContentInteractionDetails key={this.state.token} />
          </div>
          <br />
          <div>
            <TextStyle size="h3" weight="bold">Business Details</TextStyle>
            <ResultsContentBusinessDetails key={token} />
          </div>
        </div>
      </div>

    );
  }
}

VerifyResultsPublic.propTypes = {

};

const mapStateToProps = state => ({
  verifications: state.Verify.verifications,
  thisVerification: state.Verify.thisVerification,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifyResultsPublic);
