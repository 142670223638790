import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Sockette from "sockette";

import Moment from 'react-moment';


import { get } from 'lodash';
// let phoneTokens = get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokens') !== undefined ? get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokens') : [];



// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { ConnectManuallyCardPhone, ConnectManuallyCardEmail } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { BusinessConnectProfileSelection, BusinessAgentDesktopConnectProfileSelection } from 'Components/ShcModulesConnect';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { InputTextV2, RadioOptionCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';

import { BusinessConnectCardOutboundPhone, BusinessConnectCardInPerson, BusinessConnectInteractionItem, 
  BusinessConnectInteractionAddItem } from 'Components/ShcModulesConnect';


import { TabContainerV3Light } from 'Components/ShcTabs';


// Actions
import { socketConnection,
  updateConnectLocalInteractionStatus,
  
  wssConnectionObject, wssOnOpen, wssOnClose, wssOnReconnect, wssOnMaximum, wssOnError, wssMessageReceived, 
  customerConnectNew, customerConnectLoad, interactionStatusUpdate, customerConnectShortMessageResponse, } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  //static contextType = Context;
  constructor(props) {
    super(props);

    
    this.connectTransactionHistoryManager = this.connectTransactionHistoryManager.bind(this);


    //Connect to websocket on disconnected status
    this.connectToWebSocket = this.connectToWebSocket.bind(this);



    this.sendMessage = this.sendMessage.bind(this);
    this.wssConnect = this.wssConnect.bind(this);

    this.newCustomerConnection = this.newCustomerConnection.bind(this);
    this.loadCustomerConnection = this.loadCustomerConnection.bind(this);

    //Textarea Input Box OnChange
    this.onTextMessageInputChange = this.onTextMessageInputChange.bind(this);
    
    //Respond with a short message
    this.customerConnectShortMessageResponse = this.customerConnectShortMessageResponse.bind(this);

    //webSocket
    let ws = null;

    this.state = {
      businessId: this.props.match.params.routebusinessid,

      shortMessageInputCharactersLeft: 150,

      // Connect Transaction History Timer
      connectTransactionHistoryTimer: false,

      user: this.props.user, // The main user object
      identities: this.props.identities,
      thisBusinessIdentity: {},
      businessViewId: this.props.match.params.routebusinessid,

      businessProfileId: '',

      ws: null,


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextUser = prevState.user;
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      nextUser = nextProps.user;
    }
    let nextIdentities = prevState.identities;
    let nextThisBusinessIdentity = prevState.thisBusinessIdentity;
    if (nextProps.identities !== undefined && nextProps.user !== prevState.identities) {
      nextIdentities = nextProps.identities;
      const business = nextProps.identities.businesses.find(aBusiness => aBusiness._id === nextProps.match.params.routebusinessid);
      if (business !== prevState.thisBusinessIdentity) {
        nextThisBusinessIdentity = business;
      }
    }
    return {
      user: nextUser,
      identities: nextIdentities,
      thisBusinessIdentity: nextThisBusinessIdentity,
    };
  }





  async componentDidMount(props) {
    //Update status of local interactions
    this.props.interactionStatusUpdate();

    /*************************************************************
     * CONNECT TRANSACTION HISTORY MANAGER
     *************************************************************/
    this.setState({
      connectTransactionHistoryTimer: setInterval(() => {
        this.connectTransactionHistoryManager();
      }, 30000),
    });
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    
    clearInterval(this.state.connectTransactionHistoryTimer);

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }



  /*************************************************************
   * CONNECT TRANSACTION HISTORY MANAGER
   * This process will start a timer which will check the amount of
   * history items and the current state. This will update the UI
   * (Active/Inactive) and one Inactive has aged 5mins past expiry,
   * remove from the list. 
   *************************************************************/
  async connectTransactionHistoryManager() {
    console.log("==== connectTransactionHistoryManager ====");
    let data = {
      businessId: this.state.businessId,
    }
    this.props.updateConnectLocalInteractionStatus(data);

  }











  connectToWebSocket() {

    this.props.socketConnection();
  }



  





  async wssConnect() {

    let wssConnection = new Promise((resolve, reject) => {
      let ws = new Sockette(
        "wss://g6w83v1xw1.execute-api.us-east-1.amazonaws.com/dev?token=123&realtoken=" +
          this.props.accessToken,
        {
          timeout: 5e3,
          maxAttempts: 1,
          onopen: e => {
            //console.log("connected:", e);
            this.props.wssOnOpen(e);
          },
          onmessage: e => {
            //console.log("Message Received:", e);
            this.props.wssMessageReceived(e);
          },
          onreconnect: e => {
            //console.log("Reconnecting...", e);
            this.props.wssOnReconnect(e);
          },
          onmaximum: e => {
            //console.log("Stop Attempting!...", e);
            this.props.wssOnMaximum(e);
          },
          onclose: e => {
            //console.log("Closed!", e);
            this.props.wssOnClose(e);
          },
          onerror: e => {
            //console.log("Error: ", e);
            this.props.wssOnError(e);
          }
        }
      );
      this.props.wssConnectionObject(ws);
      resolve(ws);
    });

    Promise.all([wssConnection]).then((values) => {
      //console.log(values);
      //console.log(values[0]);
      this.setState({
        ws: values[0],
      })
    })

  }

  async sendMessage() {

    const { ws } = this.state;

    //Send Message
    ws.json({
      action: "sendMessage",
      data: {
        userId: '5dd8814ee1c5fa00085c3282',
        businessId: '5dd8814ee1c5fa00085c3282',
        appClientInstanceId: 'a',
        message: 'dsfjasfd',
      }
    });
  }


  loadCustomerConnection(receivedData, event) {
    console.warn("=============== loadCustomerConnection =================");
    //console.log(this.state.businessId);
    //console.log(receivedData);
    let transactionId = '';
    try {
      transactionId = receivedData.transactionId;
    } catch (e) {}

    let data = {
      businessId: this.state.businessId,
      transactionId,
    }
    this.props.customerConnectLoad(data);
  }

  newCustomerConnection(event) {
    console.warn("=============== newCustomerConnection =================");
    //console.log(this.state.businessId);
    let data = {
      businessId: this.state.businessId,
    }
    this.props.customerConnectNew(data);
  }

  
  onTextMessageInputChange(event) {
    console.warn("=============== onTextMessageInputChange =================");
    //console.log(event);
    //console.log(event.target.value);
    //console.log(event.target.value.length);

    this.setState({
      shortMessageInputCharactersLeft: 150 - event.target.value.length,
      shortMessageResponse: event.target.value,
    })

  }

  customerConnectShortMessageResponse() {
    console.log("customerConnectShortMessageResponse");
    console.log(this.state.shortMessageResponse);

    if (this.state.shortMessageInputCharactersLeft.length > 150) {
      console.log("Too many chracters");
      //alertbox
    } else {
      let data = {
        businessId: this.state.businessId,
        shortMessageResponse: this.state.shortMessageResponse,
        shortMessageAuthCode: '',
      }

      this.props.customerConnectShortMessageResponse({data});
    }
  }


  render() {
    const { userBusinessRoutePermissions } = this.props;


    // const { _id = '' } = this.state.thisBusinessIdentity;
    const businessName = get(this.state, 'thisBusinessIdentity.businessProfile.businessName') !== undefined ? get(this.state, 'thisBusinessIdentity.businessProfile.businessName') : '';
    const phoneTokenActive = get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokenActive') !== undefined ? get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokenActive') : {};
    const phoneTokens = get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokens') !== undefined ? get(this.state, 'thisBusinessIdentity.businessConnect.phoneTokens') : [];

    const emailTokenActive = get(this.state, 'thisBusinessIdentity.businessConnect.emailTokenActive') !== undefined ? get(this.state, 'thisBusinessIdentity.businessConnect.emailTokenActive') : {};
    const emailTokens = get(this.state, 'thisBusinessIdentity.businessConnect.emailTokens') !== undefined ? get(this.state, 'thisBusinessIdentity.businessConnect.emailTokens') : [];

    // let { phoneTokenActive = {}, phoneTokens = [] } = this.state.thisBusinessIdentity.businessConnect;

    let dashboardNavLink = `/account/business/${this.props.match.params.routebusinessid}/dashboard`;
    let accountNavLink = `/account/business/${this.props.match.params.routebusinessid}/account`;
    let helpNavLink = `/account/business/${this.props.match.params.routebusinessid}`;



    return (
      <div className={classNames({ pageWrapper: true })}>





        <div className={classNames({ flex: true, minHeight50: true, height50: true, bg_colour_panelWhite: true, })}>
          <div className={classNames({ flexGrow: true })}>

            <div>
              <Link
                to={`/account/business/${this.props.match.params.routebusinessid}/account`}
                className={classNames({textDecoration: true, linkGroup_navigationLinks: true, linkGroup_navigationLinkHighlight: true })}
              >
                <div className={classNames({ linkGroup_listItem: true, linkGroup_listItemParent: true })}>
                  <div className={classNames({ linkGroup_listBackItem: true })}>
                    <div className={classNames({ linkGroup_extendedSideBarBackIconLeft: true })}>
                      <i className="fas fa-arrow-left" />
                    </div>
                    <div className={classNames({ linkGroup_extendedSideBarBackTitle: true })}>
                      Back
                    </div>
                    
                  </div>
                </div>
              </Link>
            </div>

           
          </div>
          <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>
            
            {this.props.socketConnectionStatus === true ? 
            (
              <div className={classNames({ flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true,
              padL10: true, padR10: true })}
              
              >
                <div className={classNames({ aaaa: true })}>             
                  <i className="fas fa-link text_color_grey_navlinks" title="Connected" aria-label="Connected" />
                </div>
                {this.props.windowWidth > 460 && (
                <div className={classNames({ text_h6: true, text_color_grey_navlinks: true })}>             
                    Connected
                </div>
                )}
              </div>
            ):(
              <div className={classNames({ flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true,
              padL10: true, padR10: true })}
              onClick={this.connectToWebSocket}
              >
                <div className={classNames({ aaaa: true })}>             
                  <i className="fas fa-unlink text_color_grey_navlinks" title="Disconnected" aria-label="Disconnected" />
                </div>
                {this.props.windowWidth > 460 && (
                <div className={classNames({ text_h6: true, text_color_grey_navlinks: true })}>             
                    Disconnected
                </div>
                )}
              </div>
            )}
            

            <div className={classNames({ flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true,
            padL10: true, padR10: true })}>
              <Link
                to={dashboardNavLink}
                className={classNames({textDecoration: true, flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true })}
              >
                <div className={classNames({ aaaa: true })}>             
                  <i className="fas fa-tachometer-alt text_color_grey_navlinks" title="Dashboard" aria-label="Dashboard" />
                </div>
                {this.props.windowWidth > 460 && (
                <div className={classNames({ text_h6: true, text_color_grey_navlinks: true })}>             
                    Dashboard
                </div>
                )}
              </Link>
            </div>
            <div className={classNames({ flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true,
            padL10: true, padR10: true })}>
              <Link
                to={accountNavLink}
                className={classNames({textDecoration: true, flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true })}
              >
                <div className={classNames({ aaaa: true })}>             
                  <i className="fas fa-cog text_color_grey_navlinks" title="Account" aria-label="Account" />
                </div>
                {this.props.windowWidth > 460 && (
                <div className={classNames({ text_h6: true, text_color_grey_navlinks: true })}>             
                    Account
                </div>
                )}
              </Link>
            </div>
            <div className={classNames({ flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true,
            padL10: true, padR10: true })}>
               <Link
                to={helpNavLink}
                className={classNames({textDecoration: true, flex: true, flexColumn: true, flexAlignItemsCenter: true, flexJustifyCenter: true })}
              >
                <div className={classNames({ aaaa: true })}>             
                  <i className="far fa-question-circle text_color_grey_navlinks" title="Help" aria-label="Help" />
                </div>
                {this.props.windowWidth > 460 && (
                <div className={classNames({ text_h6: true, text_color_grey_navlinks: true })}>             
                    Help
                </div>
                )}
              </Link>
            </div>

          </div>
        </div>




        <div className={classNames({ pad20: true })}>
          <div className={classNames({ text_s24: true })}>Customer Connect</div>
          <div className={classNames({ text_s14: true })}>For your business to securely connect with customers 
          you are required to initiate the engagement via the Guard Point Agent Desktop. This allows Guard Point to securely
          notify your customer of your interaction and provide you with real-time information as to whether they are 
          available to take your call. </div>
          <br/>

          Select a business profile below to connect with customers.
          <br/><br/>


          <div>
            {
            this.props.businessIdentity && 
            this.props.businessIdentity.businessIdentityProfiles.length > 0 ?
              // Arrow function preserves "this"
            this.props.businessIdentity.businessIdentityProfiles.map((item, i) => {
              let profileName = '';
              try {
                profileName = item.profileName;
              } catch (e) {
                profileName = '';
              }
              if (profileName === undefined) {
                profileName = '';
              }
              if (profileName === '') {
                profileName = "Unnamed Profile";
              }
              let profileDetails = [];
              let profileTypes = '';
              let profileIdentities = '';
              try {
                for (let y = 0; y < this.props.businessIdentity.businessIdentities.length; y++) {
                  for (let x = 0; x < item.profileAttributes.length; x++) {
                    let identityReferenceId = item.profileAttributes[x].identityReferenceId;
                    if (identityReferenceId === this.props.businessIdentity.businessIdentities[y]._id) {
                      //match
                      let identityData = {
                        identity: this.props.businessIdentity.businessIdentities[y].identity,
                        type: this.props.businessIdentity.businessIdentities[y].type,
                      }
                      profileDetails.push(identityData);
                      if (profileTypes.length === 0) {
                        profileTypes = this.props.businessIdentity.businessIdentities[y].type;
                      } else {
                        profileTypes = `${profileTypes}, ${this.props.businessIdentity.businessIdentities[y].type}`;
                      }
                      if (profileIdentities.length === 0) {
                        profileIdentities = this.props.businessIdentity.businessIdentities[y].identity;
                      } else {
                        profileIdentities = `${profileIdentities}, ${this.props.businessIdentity.businessIdentities[y].identity}`;
                      }
                    }
    
                  }
                }
              } catch(e) {}

              

              return (
                <li key={item._id} className={classNames({ listNone: true })}>
                  <BusinessAgentDesktopConnectProfileSelection
                  profileName={profileName}
                  profileDetails={profileDetails}
                  profileTypes={profileTypes}
                  profileIdentities={profileIdentities}
                  businessId={this.state.businessId}
                  businessDataRegion={this.props.businessIdentityDataRegion}
                  businessProfileId={item._id}
                  data={item}
                  />
                </li>
              )

              }, this)
            : (
              <div className={classNames({ padT10: true })}>
                Please create a Business Profile in the business account section.
                <br/><br/>
                A Business Profile is a collation of verified identities that represents your business. 
                These identities are provided to the customer when you make contact with them.
              </div>
            )
            }
          </div>


 
        </div>







        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="false"
        backTo="/account/business"
        backTitle="Back to Businesses"
        >
          <div>

            <BusinessConnectInteractionAddItem
              onSelect={this.newCustomerConnection}
            />





            {this.props.customerConnections &&
            this.props.customerConnections.length > 0 ?
              // Arrow function preserves "this"
            this.props.customerConnections.map((item, i) => {
              let transactionStatus = '';
              try {
                transactionStatus = item.status;
              } catch (e) {}
              let transactionId = '';
              try {
                transactionId = item.transactionId;
              } catch (e) {}
              let businessInteractionIntentType = "unknown";
              try {
                businessInteractionIntentType = item.transactionData.businessInteractionIntentType;
              } catch (e) {}
              let tokenGenerationTimestamp = "";
              try {
                tokenGenerationTimestamp = item.transactionData.tokenGenerationTimestamp;
              } catch (e) {}
              let tokenExpiryTimestamp = "";
              try {
                tokenExpiryTimestamp = item.transactionData.tokenExpiryTimestamp;
              } catch (e) {}
              let customerContactIdentityMasked = "";
              try {
                customerContactIdentityMasked = item.transactionData.customerContactIdentityMasked;
              } catch (e) {
                customerContactIdentityMasked = '';
              }
              if (customerContactIdentityMasked === undefined) {
                customerContactIdentityMasked = '';
              }

              let interactionShortMessageRequest = "";
              try {
                interactionShortMessageRequest = item.interactionShortMessageRequest;
              } catch (e) {}

              
              return (
                <BusinessConnectInteractionItem
                  onSelect={this.loadCustomerConnection}
                  transactionStatus={transactionStatus}
                  transactionId={transactionId}
                  customerContactIdentityMasked={customerContactIdentityMasked}
                  identityType={businessInteractionIntentType}
                  tokenGenerationTimestamp={tokenGenerationTimestamp}
                  tokenExpiryTimestamp={tokenExpiryTimestamp}
                  interactionShortMessageRequest={interactionShortMessageRequest}
                />
              )
            }, this)
            :
            null
            }


    
            


          </div>
          <div>


          
            {this.props.activeSubscription === false ? 
            (
              <CardBasicV2>
                This business does not have an active subscription. Please sign up.
              </CardBasicV2>
            ):(
              <div name="Valid Subscription Connect Section">
                {!userBusinessRoutePermissions.includes("connect") ? 
                (
                  <CardBasicV2>
                    You are not authorised to connect with customers. Add the user permission "Connect" to connect with customers.
                  </CardBasicV2>
                ):(
                  <div name="Authorised Access Connect Section">
                    {this.props.selectedInteraction === undefined &&
                    (
                      <div class="NEW_CUSTOMER_CONNECTION">
                        <div>
                          <div className={classNames({ text_h2: true })}>Customer Connect</div>

                          <BusinessConnectProfileSelection
                            businessId={this.state.businessId}
                          />


                        </div>



                        <div>
                          <div className={classNames({ text_h4: true })}>Who is initiating the communication?</div>

                          <TabContainerV3Light
                          businessId={this.state.businessId}
                          tabOptionsGroup={`customerConnectOptionsTabGroupInteractionInitation_${this.state.businessId}`}
                          >
                            <div tabOptionsId="connectBusinessInitiatedTabOpt" className={classNames({ flexColumn: true, 
                              ShcModulesIdentityManagementExpandTabbedOption_light: true, padL10: true, padR10: true, })}>
                              <div className={classNames({ text_h4: true })}>OUTBOUND</div> 
                              <div className={classNames({ text_h6: true, text_color_grey_light: true })}>Business is initiating the communication with the customer.</div>           
                            </div>
                            
                            <div tabOptionsId="connectCustomerInitiatedTabOpt" className={classNames({ flexColumn: true, 
                              ShcModulesIdentityManagementExpandTabbedOption_light: true, padL10: true, padR10: true,  })}>
                              <div className={classNames({ text_h4: true })}>INBOUND</div> 
                              <div className={classNames({ text_h6: true, text_color_grey_light: true })}>Customer is initiating the communication with the business.</div>
                            </div>
                            

                          </TabContainerV3Light>

                          <div>

                            <br/>

                            { this.props.selectedTabOptionInitiatedInteraction.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionInitation_${this.state.businessId}` &&
                            this.props.selectedTabOptionInitiatedInteraction.selectedTabOptionsId === 'connectBusinessInitiatedTabOpt' ?
                            (
                              <div className={classNames({ color_primary_dark: true })}>
 
                                <div>
                                  <div className={classNames({ text_h4: true })}>How are you connecting with your customer?</div>

                                  <TabContainerV3Light
                                  businessId={this.state.businessId}
                                  tabOptionsGroup={`customerConnectOptionsTabGroupInteractionIdentityType_${this.state.businessId}`}
                                  >
                                    <div tabOptionsId="connectInteractionIdentityType_phone" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true,
                                      padL10: true, padR10: true,  })}>
                                      PHONE               
                                    </div>
                                  
                                    <div tabOptionsId="connectInteractionIdentityType_inperson" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true, 
                                      padL10: true, padR10: true,  })}>
                                      MEETING               
                                    </div>
                                    
                                  </TabContainerV3Light>
                                </div>

                                <br/>

                                
                                { this.props.selectedTabOptionInteractionIdentityType.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionIdentityType_${this.state.businessId}` &&
                                this.props.selectedTabOptionInteractionIdentityType.selectedTabOptionsId === 'connectInteractionIdentityType_phone' ?
                                (
                                  <div className={classNames({ color_primary_dark: true })}>
                                      
                                      <BusinessConnectCardOutboundPhone
                                      cardType="phone"
                                      headlineText="Connect via Phone"
                                      headlineSubIcon="fas fa-phone"
                                      headlineSubText="Connect with your customers via phone"
                                      inputTextTitle1="Phone Number"
                                      secondaryText=""
                                      customTextProfile=""
                                      additionalText=""
                                      linkTextLeft=""
                                      linkTextRight="History"
                                      linkPath=""

                                      businessId={this.state.businessId}
                                      businessProfileId={this.state.businessProfileId}

                                      tokenObj={phoneTokenActive}
                                      tokenObjHistory={phoneTokens}
                                      />


                                    </div>
                                ): null}

                                { this.props.selectedTabOptionInteractionIdentityType.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionIdentityType_${this.state.businessId}` &&
                                this.props.selectedTabOptionInteractionIdentityType.selectedTabOptionsId === 'connectInteractionIdentityType_email' ?
                                (
                                  <div className={classNames({ color_primary_dark: true })}>
                                      
                                      <ConnectManuallyCardEmail
                                      cardType="email"
                                      headlineText="Connect via Email"
                                      headlineSubIcon="far fa-envelope"
                                      headlineSubText="Connect with your customers via email"

                                      inputTextTitle1="Email Address"

                                      secondaryText=""
                                      customTextProfile=""
                                      additionalText=""
                                      linkTextLeft="Switch to API"
                                      linkTextRight="History"
                                      linkPath=""

                                      businessId={this.state.businessId}
                                      businessProfileId={this.state.businessProfileId}
                                      tokenObj={emailTokenActive}
                                      tokenObjHistory={emailTokens}
                                      />


                                    </div>
                                ): null}

                                { this.props.selectedTabOptionInteractionIdentityType.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionIdentityType_${this.state.businessId}` &&
                                this.props.selectedTabOptionInteractionIdentityType.selectedTabOptionsId === 'connectInteractionIdentityType_inperson' ?
                                (
                                  <div className={classNames({ color_primary_dark: true })}>
                                      
                                      <CardBasicV2>
                                        Coming Soon.
                                        <br/>


                                      </CardBasicV2>
                                      {/* 
                                      <BusinessConnectCardInPerson
                                      cardType="phone"
                                      headlineText="Meet In-Person"
                                      headlineSubIcon="fas fa-phone"
                                      headlineSubText="Connect with your customers via a meeting"
                                      inputTextTitle1="Phone Number"
                                      secondaryText=""
                                      customTextProfile=""
                                      additionalText=""
                                      linkTextLeft=""
                                      linkTextRight="History"
                                      linkPath=""

                                      businessId={this.state.businessId}
                                      businessProfileId={this.state.businessProfileId}

                                      tokenObj={phoneTokenActive}
                                      tokenObjHistory={phoneTokens}
                                      />
                                      */}


                                    </div>
                                ): null}


                                <br/>

                                <div>
                                  {this.props.selectedInteractionData.interactionShortMessageRequest === true ?
                                  (
                                    <CardBasicV2>
                                      <div className={classNames({ flex: true })}>
                                        <div className={classNames({ padR10: true, flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true,  })}>
                                          <i className={`far fa-comment-dots ${classNames({ text_30: true, colour_warning: true })}`}></i>
                                        </div>
                                        <div className={classNames({ aaaa: true })}>
                                          <div className={classNames({ colour_warning: true })}>
                                            Customer requested a short message
                                          </div>
                                          <div className={classNames({ aaaa: true })}>
                                            Provide a summary as for the intention of your communication.
                                          </div>
                                        </div>
                                      </div>

                                      <div className={classNames({ fullwidth: true })}>
                                        <textarea cols="50" rows="10" className={classNames({ fullwidth: true })}
                                        onChange={this.onTextMessageInputChange}
                                        maxlength="150"
                                        ></textarea>                  
                                      </div>
                                      <div className={classNames({ fullwidth: true })}>
                                        {this.state.shortMessageInputCharactersLeft} characters left               
                                      </div>

                                      <div>
                                        Note: You can only send a single message.
                                      </div>
                                      <div>
                                        <StandardApiBtn
                                        apiActivityId="CustomerConnectShortMessage"
                                        icon=""
                                        iconLocation=""
                                        text="Send Message"
                                        tooltip=""
                                        action=""
                                        classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-fullwidth"
                                        handleClick={this.customerConnectShortMessageResponse}
                                        />
                                      </div>


                                    </CardBasicV2>
                                  )
                                  : null}
                                </div>




                                <br/><br/><br/><br/>















                                



                              </div>
                            ) : null}

                            { this.props.selectedTabOptionInitiatedInteraction.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionInitation_${this.state.businessId}` &&
                            this.props.selectedTabOptionInitiatedInteraction.selectedTabOptionsId === 'connectCustomerInitiatedTabOpt' ?
                            (
                              <div className={classNames({ color_primary_dark: true })}>

                                <CardBasicV2>
                                  Coming Soon.
                                  <br/>
                                </CardBasicV2>
                                
                                


                              </div>
                            ) : null}


                          </div>
                        </div>


                      </div>
                    )}

                    {this.props.selectedInteraction !== {} &&
                    this.props.selectedInteraction !== undefined &&
                    this.props.selectedInteraction !== '' &&
                    (
                      <div class="LOADED_CUSTOMER_CONNECTION">
                        <div className={classNames({ text_h2: true })}>Customer Interaction</div>

                        <CardBasicV2>
                          <div className={classNames({ flex: true })}>
                            <div className={classNames({ padR10: true, flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true,  })}>
                              <i className={`fas fa-phone ${classNames({ text_30: true, text_color_grey_navlinks: true })}`}></i>
                            </div>
                            <div className={classNames({ aaaa: true })}>
                              <div className={classNames({ aaaa: true })}>
                                Interaction with: {this.props.customerContactIdentityMasked? this.props.customerContactIdentityMasked : ''}
                              </div>
                              <div className={classNames({ aaaa: true })}>
                                Interaction code: {this.props.interactionToken} 
                              </div>
                            </div>
                          </div>

                          
                        </CardBasicV2>
                        
                        {this.props.selectedTransactionStatus === true ?
                        (
                          <CardBasicV2>
                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true,  })}>
                                <i className={`fas fa-check-circle ${classNames({ text_30: true, colour_success: true })}`}></i>
                              </div>
                              <div className={classNames({ aaaa: true })}>
                                <div className={classNames({ aaaa: true })}>
                                  Interaction is active
                                </div>
                                <div className={classNames({ aaaa: true })}>
                                  Expires at <Moment unix  format="hh:mm A">{this.props.tokenExpiryTimestamp}</Moment>        
          
                                </div>
                              </div>
                            </div>
                          </CardBasicV2>
                        )
                        :
                        (
                        <CardBasicV2>
                          <div className={classNames({ flex: true })}>
                            <div className={classNames({ padR10: true, flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true,  })}>
                              <i className={`fas fa-times-circle ${classNames({ text_30: true, colour_danger: true })}`}></i>
                            </div>
                            <div className={classNames({ aaaa: true })}>
                              <div className={classNames({ colour_danger: true })}>
                                Interaction has Expired
                              </div>
                              <div className={classNames({ aaaa: true })}>
                                Do not use this interaction code with your customer. 
                              </div>
                            </div>
                          </div>
                        </CardBasicV2>
                        )}

                        

                        


                        <CardBasicV2>
                          <div className={classNames({ aaaa: true })}>


                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Tranasction ID:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.selectedTransactionTransactionId}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Status:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.selectedTransactionStatus ?
                                (
                                  "Active"
                                )
                              :
                              (
                                  "Expired"
                              )}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Business Profile ID:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.selectedTransactionBusinessProfileId}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Interaction Intent:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.businessInteractionIntentType}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Customer Identity:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.customerContactIdentityMasked}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Interaction Valid for:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.tokenExpiryInWords}     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Interaction Created:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                <Moment unix>{this.props.tokenGenerationTimestamp}</Moment>     
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Interaction Expires:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                <Moment unix>{this.props.tokenExpiryTimestamp}</Moment>
                                    
                              </div>
                            </div>

                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, text_h5: true })}>
                                Interaction Code:               
                              </div>
                              <div className={classNames({ text_h5: true })}>
                                {this.props.interactionToken}     
                              </div>
                            </div>

                          </div>
                        </CardBasicV2>

                        

                        {this.props.selectedInteractionData.interactionShortMessageRequest === true ?
                        (
                          <CardBasicV2>
                            <div className={classNames({ flex: true })}>
                              <div className={classNames({ padR10: true, flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true,  })}>
                                <i className={`far fa-comment-dots ${classNames({ text_30: true, colour_warning: true })}`}></i>
                              </div>
                              <div className={classNames({ aaaa: true })}>
                                <div className={classNames({ colour_warning: true })}>
                                  Customer requested a short message
                                </div>
                                <div className={classNames({ aaaa: true })}>
                                  Provide a summary as for the intention of your communication.
                                </div>
                              </div>
                            </div>

                            <div className={classNames({ fullwidth: true })}>
                              <textarea cols="50" rows="10" className={classNames({ fullwidth: true })}
                              onChange={this.onTextMessageInputChange}
                              maxlength="150"
                              ></textarea>                  
                            </div>
                            <div className={classNames({ fullwidth: true })}>
                              {this.state.shortMessageInputCharactersLeft} characters left               
                            </div>
                          </CardBasicV2>
                        )
                        : null}


                        </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </SidebarSubNav>


      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  //console.log("ownProps");
  //console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine this business Identity');
  }
  //console.log("mapStateToProps | businessIdentity");
  //console.log(businessIdentity);

  let businessIdentityDataRegion = '';
  try {
    businessIdentityDataRegion = businessIdentity.dataRegion;
  } catch (e) {
    businessIdentityDataRegion = '';
  }
  if (businessIdentityDataRegion === undefined) {
    businessIdentityDataRegion = '';
  }

  

  //List all the interactions we have started
  let customerConnections = [];
  try {
    customerConnections = businessIdentity.localInteractions.sort((a, b) => { 
      return b.transactionData.tokenGenerationTimestamp - a.transactionData.tokenGenerationTimestamp
    });
  } catch (e) {
    customerConnections = [];
    //console.error('mapStateToProps | Unable to get customer connections');
  }
  if (customerConnections === undefined) {
    customerConnections = [];
  }

  //Get the current selected interaction
  let selectedInteraction = undefined;
  try {
    selectedInteraction = businessIdentity.localSelectedInteraction;
  } catch (e) {
    //console.error('mapStateToProps | Unable to get selected interaction');
  }

  //Get the current selected interaction data
  let selectedInteractionData = {};
  try {
    selectedInteractionData = businessIdentity.localInteractions.find(interaction => interaction.transactionId === selectedInteraction);
  } catch (e) {
    selectedInteractionData = {};
    //console.error('mapStateToProps | Unable to get the selected interaction data');
  }
  if (selectedInteractionData === undefined) {
    selectedInteractionData = {};
  }

  console.warn("selectedInteractionData");
  console.warn(selectedInteractionData);

  let customerContactIdentityMasked = '';
  try {
    customerContactIdentityMasked = selectedInteractionData.transactionData.customerContactIdentityMasked;
  } catch (e) {
    customerContactIdentityMasked = '';
  }
  if (customerContactIdentityMasked === undefined) {
    customerContactIdentityMasked = '';
  }
  let interactionToken = '';
  try {
    interactionToken = selectedInteractionData.transactionData.tokenId;
  } catch (e) {
    interactionToken = '';
  }
  if (interactionToken === undefined) {
    interactionToken = '';
  }
  let businessInteractionIntentType = '';
  try {
    businessInteractionIntentType = selectedInteractionData.transactionData.businessInteractionIntentType;
  } catch (e) {
    businessInteractionIntentType = '';
  }
  if (businessInteractionIntentType === undefined) {
    businessInteractionIntentType = '';
  }




  let tokenExpiryInWords = '';
  try {
    tokenExpiryInWords = selectedInteractionData.transactionData.tokenExpiryInWords;
  } catch (e) {
    tokenExpiryInWords = '';
  }
  if (tokenExpiryInWords === undefined) {
    tokenExpiryInWords = '';
  }

  let tokenGenerationTimestamp = '';
  try {
    tokenGenerationTimestamp = selectedInteractionData.transactionData.tokenGenerationTimestamp;
  } catch (e) {
    tokenGenerationTimestamp = '';
  }
  if (tokenGenerationTimestamp === undefined) {
    tokenGenerationTimestamp = '';
  }

  let tokenExpiryTimestamp = '';
  try {
    tokenExpiryTimestamp = selectedInteractionData.transactionData.tokenExpiryTimestamp;
  } catch (e) {
    tokenExpiryTimestamp = '';
  }
  if (tokenExpiryTimestamp === undefined) {
    tokenExpiryTimestamp = '';
  }

  let selectedTransactionTransactionId = '';
  try {
    selectedTransactionTransactionId = selectedInteractionData.transactionId;
  } catch (e) {
    selectedTransactionTransactionId = '';
  }
  if (selectedTransactionTransactionId === undefined) {
    selectedTransactionTransactionId = '';
  }

  let selectedTransactionStatus = '';
  try {
    selectedTransactionStatus = selectedInteractionData.status;
  } catch (e) {
    selectedTransactionStatus = '';
  }
  if (selectedTransactionStatus === undefined) {
    selectedTransactionStatus = '';
  }

  let selectedTransactionBusinessProfileId = '';
  try {
    selectedTransactionBusinessProfileId = selectedInteractionData.businessProfileId;
  } catch (e) {
    selectedTransactionBusinessProfileId = '';
  }
  if (selectedTransactionBusinessProfileId === undefined) {
    selectedTransactionBusinessProfileId = '';
  }








  ////////////////////////////////////////////////////////////////////////////////////
  let transactionData_SET = true;
  let value = '';
  try {
    value = selectedInteractionData.transactionData;
  } catch (e) {
    transactionData_SET = false;
  }
  if (value === undefined) {
    transactionData_SET = false;
  }
  if (transactionData_SET === false) {
    selectedInteractionData = '';
  }

  if (transactionData_SET === true) {
    //Make sure every record had the required fields for render.
    let customerContactIdentityMasked_SET = true;
    let value = '';
    try {
      value = selectedInteractionData.transactionData.customerContactIdentityMasked;
    } catch (e) {
      customerContactIdentityMasked_SET = false;
    }
    if (value === undefined) {
      customerContactIdentityMasked_SET = false;
    }

    if (customerContactIdentityMasked_SET === false) {
      selectedInteractionData.transactionData.customerContactIdentityMasked = '';
    }




  } //end
  
  console.warn("selectedInteractionData");
  console.warn(selectedInteractionData);




  

  

  //console.log("selectedInteraction");
  //console.log(selectedInteraction);

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `customerConnectOptionsTabGroup_${businessId}`);
  } catch (e) {
    //console.error('mapStateToProps | Unable to find the tab options');
  }
  //console.log("selectedTabOption");
  //console.log(selectedTabOption);

  



  let selectedTabOptionInitiatedInteraction = null;
  try {
    selectedTabOptionInitiatedInteraction = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionInitation_${businessId}`);
  } catch (e) {
    //console.error('mapStateToProps | Unable to find the tab options');
  }

  let selectedTabOptionInteractionChannel = null;
  try {
    selectedTabOptionInteractionChannel = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionChannel_${businessId}`);
  } catch (e) {
    //console.error('mapStateToProps | Unable to find the tab options');
  }

  let selectedTabOptionInteractionIdentityType = null;
  try {
    selectedTabOptionInteractionIdentityType = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `customerConnectOptionsTabGroupInteractionIdentityType_${businessId}`);
  } catch (e) {
    //console.error('mapStateToProps | Unable to find the tab options');
  }


  ///// GET SUBSCRIPTION DETAILS /////
  let businessSubscriptions = [];
  let activeSubscription = false;
  try {
    businessSubscriptions = businessIdentity.subscriptions.filter(sub => sub.active === true);

    if (businessSubscriptions.length > 0) {
      activeSubscription = true;
    }
  } catch (e) {
    //console.error('mapStateToProps | Failed to find subscription');
  }

  //console.log("businessSubscriptions");
  //console.log(businessSubscriptions);
  //console.log("activeSubscription");
  //console.log(activeSubscription);
  
  
  ///// GET SOCKET CONNECTION STATUS /////
  let socketConnectionStatus = false;
  try {
    socketConnectionStatus = state.Socket.connection;
  } catch (e) {}



  let windowSizeWidth = 720;
  let windowSizeHeight = 360;
  try {
    windowSizeWidth = state.UI.windowSize.windowWidth;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window width');
  }
  try {
    windowSizeHeight = state.UI.windowSize.windowHeight;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window height');
  }



  return {
    accessToken: state.Auth.apiAccessToken,
    businessIdentity: Object.assign({}, businessIdentity),


    businessIdentityDataRegion: businessIdentityDataRegion,

    user: state.User,
    identities: state.BusinessIdentities.identities,

    customerConnections: Object.assign([], customerConnections),
    selectedInteraction: selectedInteraction,
    selectedInteractionData: Object.assign({}, selectedInteractionData),

    selectedTabOption: Object.assign([], selectedTabOption),
    selectedTabOptionInitiatedInteraction: Object.assign([], selectedTabOptionInitiatedInteraction),    //Interaction (Outbound/Inbound)
    selectedTabOptionInteractionChannel: Object.assign([], selectedTabOptionInteractionChannel),        //Interaction Channel
    selectedTabOptionInteractionIdentityType: Object.assign([], selectedTabOptionInteractionIdentityType), //Interaction Identity Type (Phone, Email, etc)
    
    activeSubscription, 
    businessSubscriptions: Object.assign([], businessSubscriptions),

    socketConnectionStatus,

    windowWidth: windowSizeWidth,
    windowHeight: windowSizeHeight,  
    
    //SelectedInteraction
    customerContactIdentityMasked,
    interactionToken,
    businessInteractionIntentType,
    tokenExpiryInWords,
    tokenGenerationTimestamp,
    tokenExpiryTimestamp,
    selectedTransactionTransactionId,
    selectedTransactionStatus,
    selectedTransactionBusinessProfileId,

  };

};

const mapDispatchToProps = {
  socketConnection, //Allow the connection to a websocket (e.g. if it disconnects)
  updateConnectLocalInteractionStatus,


  wssConnectionObject,
  wssOnOpen,
  wssOnClose,
  wssOnReconnect,
  wssOnMaximum,
  wssOnError,
  wssMessageReceived,

  customerConnectNew,
  customerConnectLoad,
  interactionStatusUpdate,

  customerConnectShortMessageResponse,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);


/* 

Email option
<div tabOptionsId="connectInteractionIdentityType_email" className={`${classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}`}>
  EMAIL               
</div>


How are you interacting with your customer?

<div>
  <div className={classNames({ text_h4: true })}>How are you interacting with your customer?</div>

  <TabContainerV3Light
  businessId={this.state.businessId}
  tabOptionsGroup={`customerConnectOptionsTabGroupInteractionChannel_${this.state.businessId}`}
  >
    <div tabOptionsId="connectViaPhoneTabOpt1" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}>
      PHONE               
    </div>
    <div tabOptionsId="connectViaEmailTabOpt2" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}>
      EMAIL               
    </div>
    <div tabOptionsId="connectViaEmailTabOpt3" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}>
      SOCIAL               
    </div>
    <div tabOptionsId="connectViaEmailTabOpt4" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}>
      IN-PERSON               
    </div>
    <div tabOptionsId="connectViaEmailTabOpt5" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption_light: true })}>
      POSTAL               
    </div>

  </TabContainerV3Light>
</div>

<br/>

*/