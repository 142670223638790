import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing


// CSS
import componentStyles from './styles.css';

// Components

// Actions


function TabContainer({ children }) {
  return (
    <div>
    { children }
    </div>
  );
}

class TabContainerV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (value, event) => {
    //console.log("tab handleChange");
    //console.log(this.state.value);
    this.setState({ value });
  };
  handleChangeIndex = index => {
    //console.log("tab handleChangeIndex");
    this.setState({ value: index });
  };

  render() {
    const { name, classes } = this.props;

    const GenerateTabContent = () => {
      
      let data = this.props.children.map((child, i) => {
        if (i % 2) {
          return child;
        } else {
          return child;     /* was null*/
        }
      });
      //Clean out nulls
      data = data.filter((obj) => obj );
      
      //console.log("data");
      //console.log(data);
      return (
        <div> 
          {data.map((child, i) => {
            //console.log(`${i} === ${this.state.value + 1}`);
            
            if (i === this.state.value + 1) {
              //console.log(`Matched - show child`);
              return (<TabContainer 
                key={['TabContainer_', i ].join('_')}
              >{child}</TabContainer>);
            }
              
          })}
        </div> 
      )
    }

    return (
      <div className={classNames({ width100p: true })}>
        <div className={classNames({ flex: true })}>
          {
            this.props.children.map((child, i) => (
              (i % 2 ? 
                null
              : 
              <div onClick={this.handleChange.bind(this, i)} 
              className={classNames({ ShcModulesIdentityManagementExpandTabbedOption: i !== this.state.value,
                ShcModulesIdentityManagementExpandTabbedOptionSelected: i === this.state.value })}>
                {child}
              </div>
              )
            ))
          }
        </div>
        <GenerateTabContent name={name}/>

        
      </div>
    );
  }
}

TabContainerV2.propTypes = {

};
TabContainerV2.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TabContainerV2);
