import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { IdentityInputV2, InputTextV3, TextContainerLabel, RadioOptionItemV1 } from 'Components/ShcInput';


import { ApiManagementKeyViewV2, ApiManagementKeyAdd, ApiManagementIpAddressItem } from 'Components/ShcModulesApiManagement';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';


// Actions
import { toggleOverlayOnScreenResponsive, reduxDataUpdate, apiDataUpdate, 
  createApiKeyItem, removeApiKeyItem, toggleApiKeyItemStatus,
  createApiIpRestriction, removeApiIpRestriction, 
} from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class ApiManagementCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.reGenerateKey = this.reGenerateKey.bind(this);
    this.revertToPreviousKey = this.revertToPreviousKey.bind(this);
   
    this.AddBusinessPermissionToUser = this.AddBusinessPermissionToUser.bind(this);
    this.removeAccessKey = this.removeAccessKey.bind(this);
    this.toggleKeyItemStatus_active = this.toggleKeyItemStatus_active.bind(this);
    this.toggleKeyItemStatus_inactive = this.toggleKeyItemStatus_inactive.bind(this);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);

    this.addApiKeyItem = this.addApiKeyItem.bind(this);

    this.addApiIpRestriction = this.addApiIpRestriction.bind(this);
    this.handleAddNewIpAddressRestriction = this.handleAddNewIpAddressRestriction.bind(this);
    this.setIpAddressRestrictionInput = this.setIpAddressRestrictionInput.bind(this);

    this.removeApiIpRestriction = this.removeApiIpRestriction.bind(this);
    
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,


      businessId: this.props.businessId,
      businessIdentities: [],

      ipAddressRestrictionInput: '',
      ipAddressRestrictionFormatError: false,


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  reGenerateKey() {
    //console.log("reGenerateKey | Generate Key");
    const data = {
    };
    //Popup confirm
    this.props.toggleOverlayOnScreenResponsive('open', 'reGenerateApiKey', data);

  }

  revertToPreviousKey() {
    //console.log("reGenerateKey | Generate Key");
    const data = {
    };
    //Popup confirm
    this.props.toggleOverlayOnScreenResponsive('open', 'revertToPreviousKey', data);

  }





  removeItem(keyId, evt) {
    //REMOVE THE BUSINESS API KEY
    //console.log("removeItem");
    //console.log(keyId);
    //console.log("evt");
    //console.log(evt);
    const { businessId } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let apiUi = {
      apiActivityId
    }

    let data = {
      businessId,
      keyId,
    }
    //console.log("data for removing user");
    //console.log(data);
    console.warn(`ACTION | onRemoveItem`);
    this.props.onRemoveItem({data, apiUi});
  }

  removeApiIpRestriction(body = {}) {

    const { businessId } = this.state;
    const { item, apiUi } = body;

    let keyId = '';
    try {
      keyId = item.keyId;
    } catch (e) {
      keyId = '';
    }
    if (keyId === undefined) {
      keyId = '';
    }

    let httpIpRestrictions = '';
    try {
      httpIpRestrictions = item.value;
    } catch (e) {
      httpIpRestrictions = '';
    }
    if (httpIpRestrictions === undefined) {
      httpIpRestrictions = '';
    }
    
    let data = {
      businessId,
      keyId,
      httpIpRestrictions,
    }

    let business = {
      businessId,
    }

    this.props.removeApiIpRestriction({business, data, apiUi});
  }

  AddBusinessPermissionToUser() {
    //console.log("AddBusinessPermissionToUser | Add Business Permission to User");
    /*
    //View identity list options
    //Select an identity to add
    //UI update to show the new identity added.


    */
   /*
    let tableData=[];
    let tableItem;

    tableItem = {
      type: "Email",
      identity: "selbs01@hotmail.com",
    };


    tableData.push(tableItem);

    */
    const data = {
      //tableData: tableData,
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectBusinessUserPermissionsToAdd', data);

  }
  removeAccessKey(body = {}) {   
    let apiUi = null;
    let item = null;
    try {
      apiUi = body.apiUi;
    } catch (e) {
      apiUi = '';
    }
    try {
      item = body.item;
    } catch (e) {
      item = '';
    }

    let keyId = '';
    try {
      keyId = item.keyId;
    } catch (e) {
      keyId = '';
    }
    let keyItemId = '';
    try {
      keyItemId = item.keyItemId;
    } catch (e) {
      keyItemId = '';
    }

    const businessId = this.state.businessId;

    const business = { 
      businessId,
    };

    let data = {
      businessId: this.state.businessId,
      keyId,
      keyItemId,
    }



    this.props.removeApiKeyItem({business, data, apiUi});

  }

  toggleKeyItemStatus_active(body = {}) {
    console.log("toggleKeyItemStatus active");
    const businessId = this.state.businessId;
    const business = { 
      businessId,
    };

    let apiUi = null;
    let item = null;
    try {
      apiUi = body.apiUi;
    } catch (e) {
      apiUi = '';
    }
    try {
      item = body.item;
    } catch (e) {
      item = '';
    }

    let keyId = '';
    try {
      keyId = item.keyId;
    } catch (e) {
      keyId = '';
    }
    let keyItemId = '';
    try {
      keyItemId = item.keyItemId;
    } catch (e) {
      keyItemId = '';
    }

    let data = {
      businessId: this.state.businessId,
      keyId,
      keyItemId,
      status: 'active',
    }

    this.props.toggleApiKeyItemStatus({business, data, apiUi});

  }

  toggleKeyItemStatus_inactive(body = {}) {
    console.log("toggleKeyItemStatus inactive");
    const businessId = this.state.businessId;
    const business = { 
      businessId,
    };

    let apiUi = null;
    let item = null;
    try {
      apiUi = body.apiUi;
    } catch (e) {
      apiUi = '';
    }
    try {
      item = body.item;
    } catch (e) {
      item = '';
    }

    let keyId = '';
    try {
      keyId = item.keyId;
    } catch (e) {
      keyId = '';
    }
    let keyItemId = '';
    try {
      keyItemId = item.keyItemId;
    } catch (e) {
      keyItemId = '';
    }

    let data = {
      businessId: this.state.businessId,
      keyId,
      keyItemId,
      status: 'inactive',
    }

    this.props.toggleApiKeyItemStatus({business, data, apiUi});

  }

  

  onChangeHandler(receivedUpdateObj) {
    console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });


    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    


  }
  onRemoveHandler() {

  }

  AddBusinessPermissionToUserFromTable(rowDetails, evt) {
    //console.log("AddBusinessPermissionToUserFromTable");
    //console.log(rowDetails);

    this.props.toggleOverlayOnScreenResponsive('close', "selectBusinessUserPermissionsToAdd");

    let selectedRecord = rowDetails.original;

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;


    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessId,
     };

    this.props.addBusinessUserPermission({business, apiUi});
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }



  addApiKeyItem(evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    const business = {
      businessId: this.state.businessId,
    };

    const data = {
      businessId: this.state.businessId,
      keyId: this.props.keyId,
    };

    console.log("======= addApiKeyItem ========");
    console.log(data);

    this.props.createApiKeyItem({ business, data, apiUi });
  }

  
  addApiIpRestriction() {
    console.log('addApiIpRestriction');
    const data = {
    };
    //Popup confirm
    this.props.toggleOverlayOnScreenResponsive('open', 'setIpAddressRestriction', data);
  }

  setIpAddressRestrictionInput(receivedUpdateObj) {
    console.log('setIpAddressRestrictionInput');
    console.log(receivedUpdateObj);
    /*
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    */
    let value = receivedUpdateObj.value;

    let validIpv4 = false;
    let validIpv6 = false;

    ///// IP v4 Test /////
    const regexIpv4 = RegExp('^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$');
    if (regexIpv4.test(value) === true) {
      validIpv4 = true; 
    }

    ///// IP v4 Test /////
    const regexIpv6 = RegExp('^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$');
    if (regexIpv6.test(value) === true) {
      validIpv6 = true; 
    }
    let ipAddressRestrictionFormatError = true;
    if (validIpv4 === true || validIpv6 === true) {
      ipAddressRestrictionFormatError = false;
    }

    this.setState({
      ipAddressRestrictionInput: value,
      ipAddressRestrictionFormatError,
    });
  }
  


  handleAddNewIpAddressRestriction(evt) {
    console.log('handleAddNewIpAddressRestriction');

    let ipAddressRestrictionInput = this.state.ipAddressRestrictionInput;

    let validIpv4 = false;
    let validIpv6 = false;

    ///// IP v4 Test /////
    const regexIpv4 = RegExp('^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$');
    if (regexIpv4.test(ipAddressRestrictionInput) === true) {
      validIpv4 = true; 
    }

    ///// IP v4 Test /////
    const regexIpv6 = RegExp('^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$');
    if (regexIpv6.test(ipAddressRestrictionInput) === true) {
      validIpv6 = true; 
    }
    if (validIpv4 === true || validIpv6 === true) {
      //Process the request

      const apiActivityId = evt.currentTarget.dataset.apiactivityid;
      const apiUi = { apiActivityId };

      const business = {
        businessId: this.state.businessId,
      };

      const data = {
        businessId: this.state.businessId,
        keyId: this.props.keyId,
        httpIpRestrictions: ipAddressRestrictionInput,
      };

      console.log("======= handleAddNewIpAddressRestriction ========");
      console.log(data);

      this.props.createApiIpRestriction({ business, data, apiUi });

      //Hide the popup
      this.closeOverlayOnScreen('setIpAddressRestriction');
    } else {
      //Do not process the request

    }
  }


  render() {
    let { 
      key,
      keyId,
      onRemoveItem,

      createdAt,
      keyRestrictions,
      httpOriginRestrictions,
      httpIpRestrictions,

      keys,
      keyName,
      keyDesc,
      data,

   
      businessId, businessIdentities, stateIdentifier, identifiers, id, name, onChangeHandler,
      
    } = this.props;

    if (Array.isArray(httpIpRestrictions) === false) {
      httpIpRestrictions = [];
    }

    if (Array.isArray(keys) === false) {
      keys = [];
    }
    


    let headlineText = keyName;
    let headlineTopSmall = `ID: ${keyId}`;
    let headlineBottomSmall = "API Key should be rotated (1024 days)";

    return (
      <div className={classNames({ ShcModulesIdentityContainer: true })}>
        <div className={classNames({ ShcModulesIdentityContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}> {headlineTopSmall} </div>
                  <div className={classNames({ text_h3: true })}> {headlineText} </div>
                  <div className={classNames({ text_h6: true })}> {headlineBottomSmall} </div>
                    
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
                {this.state.collapse ?
                  <div><i class="fas fa-angle-down"></i></div>
                :
                <div><i class="fas fa-angle-up"></i></div>
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityStatusContainerBody: true })}>
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              
            </div>
            <div className={classNames({ flex: true, padT10: true })}>
              <StandardApiBtn
                apiActivityId={`DeleteApiKey_${keyId}`}
                icon=""
                iconLocation=""
                text="Delete Key"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-small shcbtn-secondary"
                handleClick={this.removeItem.bind(this, keyId)}
              />
            </div>
          </div>



          <div className={classNames({ aaa: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>

              <InputCardItem headlineText="Name" headlineSubText="" additionalText="">
                <InputTextV3
                  id="identities.businesses.$.accessManagement.connectKeys.$.keyName"
                  name="identities.businesses.$.accessManagement.connectKeys.$.keyName"
                  identifiers={[{ _id: businessId}, {_id: keyId }]}
                  stateIdentifier="BusinessIdentities"
                  fieldName='accessManagement.connectKeys.$[keyid].keyName'
                  fieldRef={[{"keyid._id": keyId }]}
                  fieldStore="BusinessIdentities"
                  storeId={businessId}
                  label=""
                  value={keyName}
                  onChangeInputTextHandler={this.onChangeHandler}
                />
              </InputCardItem>

              <InputCardItem headlineText="Description" headlineSubText="" additionalText="">
                <InputTextV3
                  id="identities.businesses.$.accessManagement.connectKeys.$.keyDesc"
                  name="identities.businesses.$.accessManagement.connectKeys.$.keyDesc"
                  identifiers={[{ _id: businessId}, {_id: keyId }]}
                  stateIdentifier="BusinessIdentities"
                  fieldName='accessManagement.connectKeys.$[keyid].keyDesc'
                  fieldRef={[{"keyid._id": keyId }]}
                  fieldStore="BusinessIdentities"
                  storeId={businessId}
                  label=""
                  value={keyDesc}
                  onChangeInputTextHandler={this.onChangeHandler}
                />
              </InputCardItem>


              <TextStyle size="h5" weight="bold">
                Creation date
              </TextStyle>
              <TextStyle size="h5" weight="normal">
                {createdAt}
              </TextStyle>
              <br/>


              <div className={classNames({ borderTLight: true, marLRMinus10: true, padB10: true })}></div>
              <div className={classNames({ flex: true, maxWidth800: true,  })}>
                <div className={classNames({ flexGrow: true, maxWidth75p: true })}>
                  <TextStyle size="h3" weight="bold">API Keys</TextStyle>
                  <TextStyle size="h5" weight="normal">
                    You can have a maximum of 2 access keys. This is intended
                    to allow you to rotate the api keys in your applications.
                  </TextStyle>
                  <TextStyle size="h5" weight="normal">
                    An api key has a maximum lifetime of 3 months before we automatically delete. 
                    You should attempt to rotate your api keys on a regular basis. 
                  </TextStyle>
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true })}>
                  
                  <StandardApiBtn
                  apiActivityId="AddNewApiAccessKey"
                  icon=""
                  iconLocation=""
                  text="Add Key"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={this.addApiKeyItem}
                  />
                  
                  
                </div>

              </div>
              



              <div className={classNames({  maxWidth800: true })}>
                {
                this.props.keys &&
                this.props.keys.length > 0 ?
                  // Arrow function preserves "this"
                this.props.keys.map((item, i) => {

                  let tempKey = '';
                  try {
                    tempKey = item.tempKey;
                  } catch (e) {
                    tempKey = '';
                  }
                  if (tempKey === undefined) {
                    tempKey = '';
                  }

                  return (
                    <li key={item._id} className={classNames({ listNone: true })}>
                      
                      <ApiManagementKeyViewV2
                        maskedKey={item.maskedKey}
                        tempKey={tempKey}
                        created={item.created}
                        expires={item.expires}
                        businessId={businessId}
                        keyId={keyId}
                        keyItemId={item._id}
                        active={item.active}
                        onRemoveItem={this.removeAccessKey}
                        ontoggleKeyItemStatus_active={this.toggleKeyItemStatus_active}
                        ontoggleKeyItemStatus_inactive={this.toggleKeyItemStatus_inactive}
                      />

                    </li>
                  )
                }, this)
                : null
                }
              </div>


              <div className={classNames({ borderTLight: true, marLRMinus10: true })}></div>

            </div>

            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>

              <TextStyle size="h3" weight="bold">Application restrictions</TextStyle>

              <TextStyle size="h5" weight="normal">
              An application restriction controls the use of your API key.
              </TextStyle>

              <br/>

              <RadioOptionItemV1
              businessId={this.state.businessId}
              radioOptionsGroup={`apiAppRestrictionOption_${this.props.uniqueIdentifier}`}
              radioOptionsId="none"
              headlineText="None"
              secondaryText=""
              onSelectHandler=""
              />
              <RadioOptionItemV1
              businessId={this.state.businessId}
              radioOptionsGroup={`apiAppRestrictionOption_${this.props.uniqueIdentifier}`}
              radioOptionsId="ip address"
              headlineText="IP Address"
              secondaryText="web servers, cron jobs, etc"
              onSelectHandler=""
              />

              {/* 
              <div>
                <i class="fas fa-exclamation-triangle"></i> You must set an X-API-Key header with this value: hhhhhhhhhhhhhhh
              </div>

              <br/>

              {keyRestrictions === true ? (
                <div>
                  <i class="fas fa-exclamation-triangle"></i> API Key Restrictions = true
                </div>
              ):(
                <div>
                  <i class="fas fa-exclamation-triangle"></i> API Key Restrictions = false
                </div>
              )}

              */}

  
            </div>

            
            { this.props.selectedApiKeyApplicationRestriction.radioOptionsGroup === `apiAppRestrictionOption_${this.props.uniqueIdentifier}` &&
            this.props.selectedApiKeyApplicationRestriction.selectedRadioOptionsId === 'ip address' ?
            (
              <div>
                <InputCard headlineText="HTTP IP restrictions" 
                headlineSubText="Restrict key usage requests to specific IP addresses in the HTTP header.">
                  {httpIpRestrictions.length > 0 ? (
                    <div className={classNames({  text_h5: true, color_primary_dark: true })}>
                      You have HTTP IP Restrictions applied.
                      Our servers will only respond to requests from the specific 
                      ip addresses in the HTTP header.
                      
                    </div>
                  ):(
                    <div className={classNames({  text_h5: true, color_primary_dark: true })}>
                      This API Key does not have any HTTP IP restrictions applied.
                      We will process all API requests from any source IP address.
                    </div>
                  )}
                  <br/>
                  <br/>
                  <StandardApiBtn
                  apiActivityId="AddNewIpRestriction"
                  icon=""
                  iconLocation=""
                  text="Add IP Restriction"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={this.addApiIpRestriction}
                  />


                  <div className={classNames({  maxWidth800: true })}>
                    {
                    this.props.httpIpRestrictions &&
                    this.props.httpIpRestrictions.length > 0 ?
                      // Arrow function preserves "this"
                    this.props.httpIpRestrictions.map((item, i) => {
                      console.log("array item - ip addresses");
                      console.log(item);
                      return (
                        <li key={item} className={classNames({ listNone: true })}>
                          
                          <ApiManagementIpAddressItem
                            businessId={businessId}
                            keyId={keyId}
                            value={item}
                            onRemoveItem={this.removeApiIpRestriction}
                          />

                        </li>
                      )
                    }, this)
                    : null
                    }
                  </div>




                </InputCard>
              </div>
            ) :
              null
            }


            
          </div>


          <div className={classNames({ borderTLight: true, marLRMinus10: true })}>
          </div>


        </div>
        }





        <OverlayOnScreenResponsive
          id="setIpAddressRestriction"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Set IP Address Restriction"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Enter IP range in CIDR format</TextStyle>
              <TextStyle size="h5">You can enter a IP v4 or IP v6 address</TextStyle>
            </div>
            <div className={classNames({ text_s12: true })}>
              <br/>
              <div className={classNames({ text_s12: true })}>
                Examples: 13.159.0.1, 2001:db8::1 or 2001:db8::/64
              </div>
              
              <br/><br/>
      

              <InputTextV3
              id="ipAddressRestriction"
              name="ipAddressRestriction"
              identifiers=""
              stateIdentifier=""
              label=""
              value=""
              onChangeInputTextHandler={this.setIpAddressRestrictionInput}
              />
              {this.state.ipAddressRestrictionFormatError && 
                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }

              <br/>
              <StandardApiBtn
                apiActivityId="addNewIpAddressRestriction"
                icon=""
                iconLocation=""
                text="Add Ip Address Restriction"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.handleAddNewIpAddressRestriction}
              />



            </div>
            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectBusinessUserPermissionsToAdd')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="reGenerateApiKey"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add Permissions"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Re-Generate Key</TextStyle>
              <TextStyle size="h5">blah blah</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              ccccc
      
            </div>

     
            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectBusinessUserPermissionsToAdd')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>

    );
  }
}

ApiManagementCard.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  let uniqueIdentifier = '';
  try {
    uniqueIdentifier = ownProps.uniqueIdentifier;
  } catch (e) {}
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  
  let selectedApiKeyApplicationRestriction = null;
  try {
    selectedApiKeyApplicationRestriction = state.UI.uiRadioOptions.find(radioOption => radioOption.businessId === businessId 
      && radioOption.radioOptionsGroup === `apiAppRestrictionOption_${uniqueIdentifier}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the selected Api Application Restriction Option');
  }
  //console.log(`apiAppRestrictionOption`);
  //console.log(`apiAppRestrictionOption_${uniqueIdentifier}`);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    selectedApiKeyApplicationRestriction: Object.assign({}, selectedApiKeyApplicationRestriction),

  };

};


const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,

  reduxDataUpdate,
  apiDataUpdate,
  createApiKeyItem,
  removeApiKeyItem,
  toggleApiKeyItemStatus,

  createApiIpRestriction,
  removeApiIpRestriction,

};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiManagementCard);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(ApiManagementCard);