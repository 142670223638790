import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';
import { 
  IM_input_mobileV1,
  IdentityManagementVerificationCodeInputContainerV1, 
  IdentityManagementPrimaryInputContainerV1, IdentityManagementPrimaryLocationInputContainerV1, 
  IdentityManagementPrimaryLocationInputContainerV2,
  IdentityManagementPrimaryPhoneInputContainerV1,
  IdentityManagementPrimaryBusinessNameInputContainerV2,
  IdentityManagementPrimaryBusinessBrandImageInputContainerV2,
  IdentityManagementPrimaryBusinessBrandWordsInputContainerV2,
  IdentityManagementPrimaryContentContainerV1, 
  IdentityManagementExpandedContentContainerV1 } from 'Components/ShcModulesIdentityManagementBusiness';


// Actions
import { reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,

  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class IdentityManagementPanelV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    //On Change from input box
    this.onIdentityInputHandler = this.onIdentityInputHandler.bind(this);
    //Input of Verification Code
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    //Verify Request Button
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);

    //Request a website meta tag
    this.handleIdentityVerificationWebsiteCodeRequest = this.handleIdentityVerificationWebsiteCodeRequest.bind(this);
    //Verify a website (after meta tag has been published)
    this.handleIdentityVerificationWebsite = this.handleIdentityVerificationWebsite.bind(this);

    //Feedback from the child component as to the status of the identity
    this.recordIdentityInputValidationStatus = this.recordIdentityInputValidationStatus.bind(this);
    //toggle the notification of the identity
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    

    this.state = {

      apiTimerCollection: [], // List of active timers.

      identityData: this.props.dataSet,    //All the identity data

      identityInputLength: this.props.identityInputLength,
      inputStatus: '',       //Initial input status
      identityNotificationEnabled: this.props.notifyThisDevice,

      dataSet: '',
      communicationToken: '',
      communicationTokenArray: [],
      websiteVerificationCode: '',

      //Entered verification code
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.
    let updateState = false;
    
    //console.warn('getDerivedStateFromProps | IdentityManagementPanelV1');
    //console.warn(prevState);
    //console.warn(nextProps);



    if (nextProps.data !== prevState.data) {
      updateState = true;
    }
    try {
      if (prevState.identityVerified === false && nextProps.verificationStatus === true) {
        collapse=true;
        updateState = true;
      }
    } catch(e) {}

    try {
      if (prevState.dataSet !== nextProps.dataSet) {
        updateState = true;
      }
    } catch(e) {}

    let communicationTokenArray = [];
    try {
      communicationTokenArray = nextProps.dataSet.communicationTokenArray;
    } catch (e) {}
    if (communicationTokenArray === undefined) {
      communicationTokenArray = [];
    }
    let communicationToken = '';
    try {
      communicationToken = nextProps.dataSet.communicationToken;
    } catch (e) {}
    if (communicationToken === undefined) {
      communicationToken = '';
    }

    if (prevState.communicationToken !== nextProps.dataSet.communicationToken) {
      updateState = true;
    }
    if (prevState.communicationTokenArray !== nextProps.dataSet.communicationTokenArray) {
      updateState = true;
    }


    let websiteVerificationCode = '';
    try {
      websiteVerificationCode = nextProps.dataSet.websiteVerificationCode;
    } catch (e) {}
    if (websiteVerificationCode === undefined) {
      websiteVerificationCode = '';
    }
    if (prevState.websiteVerificationCode !== nextProps.dataSet.websiteVerificationCode) {
      updateState = true;
    }





    if (updateState === true) {
      //console.warn('UPDATING STATE FROM DERIVED');
      /*console.warn(JSON.stringify({
        collapse,
        dataSet: nextProps.dataSet,
        communicationToken,
        communicationTokenArray,
        websiteVerificationCode,
      }));*/
      return {
        collapse,
        dataSet: nextProps.dataSet,
        communicationToken: communicationToken,
        communicationTokenArray: communicationTokenArray,
        websiteVerificationCode: websiteVerificationCode,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  recordIdentityInputValidationStatus(inputStatus) {
    this.setState({
      inputStatus: inputStatus
    });
  }

  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }


  handleGeneralInputChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);

    if (validateAlphaNumericWithSpacesOnly(value)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === false) {
      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value,
      };
      //console.log('=== onInputChange Return ===');
      //console.log(returnObj);

      this.updateData(returnObj);
      
    }

    //Advise that there is an error with the input and we are not saving it.
  }


  onIdentityInputHandler(event) {
    //Update the length for improved ui experience

    let identityInputValue = '';
    try {
      identityInputValue = event.target.value;
    } catch (e) {
      identityInputValue = '';
    }
    if (identityInputValue === undefined) {
      identityInputValue = '';
    }
    
    let identityInputLength = 0;
    try {
      identityInputLength = identityInputValue.length;
    } catch (e) {
      identityInputLength = 0;
    }
    if (identityInputLength === undefined) {
      identityInputLength = 0;
    }
    this.setState({
      identityInputLength: identityInputLength,
    });

  }

  removeItem(identityId, evt) {
    //console.log("removeItem");
    //console.log(identityId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }


  handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    console.log("API Request Dataset");
    console.log(identityData);



    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      this.props.userIdentitiesVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }





  handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identityData, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity: identityData,
        verificationCode: verificationCode,
      };
      this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }



  handleIdentityVerificationWebsiteCodeRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });     //userIdentitiesVerifyNow
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });      //businessIdentitiesVerifyNow
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }


  handleIdentityVerificationWebsite(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationWebsite');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity: identityData,
      };
      this.props.userIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      //this.props.businessIdentitiesWebsiteVerifyNow({ data, apiUi });
      this.props.businessIdentitiesVerificationActivity_website({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }


  
  render() {
    const { componentTargetUser, identityId, identityInputValue, identityInputLength, identityMask, 
      identityType, 
      identityLocked, dataSet, index, apiEndpoint, businessId,
      stateIdentifier, identifiers, id, name, onRemoveItem, challenge, 
      verificationCode, identityLabel, notifyThisDevice } = this.props;


    let labelInputIdentifier = '';
    if (componentTargetUser === "User") {
      labelInputIdentifier = "userIdentities.$.identityLabel";
    } else if (componentTargetUser === "Business") {
      labelInputIdentifier = "identities.businesses.$.businessIdentities.$.identityLabel";
    }
       

    ////// IDENTITY DISPLAY FORMATTING //////
    let identitySectionTitle = "Unknown";
    let identitySectionSubTitle = "Unknown";
    let identityTypeDisplayIcon = <div><i className={`fas fa-question-circle`}></i></div>;
    
    if (identityType === "phone") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "PHONE NUMBER";
      identitySectionSubTitle = "Please enter a phone number in the international format (e.g. +61)";
    
    } else if (identityType === "mobile") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "MOBILE";
      identitySectionSubTitle = "Please enter a mobile number in the international format (e.g. +61)";

    } else if (identityType === "email") {
      identityTypeDisplayIcon = <div><i className={`far fa-envelope`}></i></div>;
      identitySectionTitle = "EMAIL";
      identitySectionSubTitle = "Please enter your email address";

    } else if (identityType === "website") {
      identityTypeDisplayIcon = <div><i className={`fas fa-network-wired`}></i></div>;
      identitySectionTitle = "WEBSITE";
      identitySectionSubTitle = "Please enter your website (e.g. https://)";
      
    } else if (identityType === "social") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "SOCIAL IDENTITY";
      identitySectionSubTitle = "Please enter your social identity id (e.g. @handle)";
    
    } else if (identityType === "postal") {
      identityTypeDisplayIcon = <div><i className={`fas fa-home`}></i></div>;
      identitySectionTitle = "POSTAL ADDRESS";
      identitySectionSubTitle = "Please enter your address";

    } else if (identityType === "inperson") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      identitySectionTitle = "IN PERSON";
      identitySectionSubTitle = "";
      
    } else if (identityType === "business-name") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BUSINESS NAME";
      identitySectionSubTitle = "";
      
    }  else if (identityType === "business-brand-image") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BRAND IMAGE";
      identitySectionSubTitle = "";
      
    }  else if (identityType === "business-brand-words") {
      identityTypeDisplayIcon = <div><i className={`fas fa-signature`}></i></div>;
      identitySectionTitle = "BRAND WORDS";
      identitySectionSubTitle = "";
      
    } 

    /////////////////////////////////////////////////////
    //// Extract Data from the dataSet prop
    /////////////////////////////////////////////////////
    let verificationStatus = false;
    try {
      verificationStatus = dataSet.verificationStatus;
    } catch (e) {
      verificationStatus = false;
    }
    if (verificationStatus === undefined) {
      verificationStatus = false;
    }

    let verificationStatusDate = false;
    try {
      verificationStatusDate = dataSet.verificationStatusDate;
      verificationStatusDate = parseInt(verificationStatusDate);
    } catch (e) {
      verificationStatusDate = false;
    }
    if (verificationStatusDate === undefined) {
      verificationStatusDate = false;
    }

    let reValidationDueDate = false;
    try {
      reValidationDueDate = dataSet.reValidationDueDate;
      reValidationDueDate = parseInt(reValidationDueDate);
    } catch (e) {
      reValidationDueDate = false;
    }
    if (reValidationDueDate === undefined) {
      reValidationDueDate = false;
    }


    let ivrMenuOptions = null;
    try {
      ivrMenuOptions = dataSet.ivrMenuOptions;
    } catch (e) {}
    let phoneHasIvr = null;
    try {
      phoneHasIvr = dataSet.phoneHasIvr;
    } catch (e) {}
    let phoneHasQueue = null;
    try {
      phoneHasQueue = dataSet.phoneHasQueue;
    } catch (e) {}


    let relativeReValdiationDueDate = '';
    if (reValidationDueDate !== null) {
      var a = moment();
      var b = moment.unix(reValidationDueDate).format("MM/DD/YYYY");

      relativeReValdiationDueDate = a.to(b);
      
      //console.log("=============== MOMENT ====================");
      //console.log(a.to(b));
      
    }




    let communicationVerificationCode = '';
    try {
      //communicationVerificationCode = dataSet.communicationToken;
      communicationVerificationCode = this.state.communicationToken;
    } catch (e) {}
    let communicationVerificationCodeArray = [];
    try {
      //communicationVerificationCodeArray = dataSet.communicationTokenArray;
      communicationVerificationCodeArray = this.state.communicationTokenArray;
    } catch (e) {}
    if (communicationVerificationCodeArray === undefined) {
      communicationVerificationCodeArray = [];
    }
    let websiteVerificationCode = '';
    try {
      websiteVerificationCode = this.state.websiteVerificationCode;
    } catch (e) {}
    if (websiteVerificationCode === undefined) {
      websiteVerificationCode = '';
    }


    //If business name, collate the additional identity values into an array
    //and pass in for on-screen view
    let identityAdditionalData = [];
    try {
      identityAdditionalData = dataSet.identityAdditionalData;
    } catch (e) {}
    if (identityAdditionalData === undefined) {
      identityAdditionalData = [];
    }






    


    let communicationVerificationCode_html = '';
    try {
      communicationVerificationCode_html = dataSet.communicationToken_html;
    } catch (e) {}
    

    


    const buttonTitleContactMade = <div><i className={`fas fa-volume-up ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    const buttonTitleNoContact = <div><i className={`fas fa-volume-mute ${classNames({colour_white: true, padR10: true})}`}></i> They didn't contact me</div>;
    const buttonTitleReportIssue = <div><i className={`fas fa-flag ${classNames({colour_white: true, padR10: true})}`}></i> Report suspicious activity</div>;
    

    /*
    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_verifiedIcon: true, flex: true, flexAlignItemsCenter: true})}`}
    aria-label="Verified"
    onClick={() => this.props.toggleOverlayOnScreenResponsive('open', 'infoIdentityVerifiedPhone', '')}
    ></i>
    */


    return (
      <div className={classNames({ ShcModulesIdentityManagementViewContainerHeader: true, marB30: !this.state.collapse })}>
        
        
        <IdentityManagementPrimaryContentContainerV1
        verificationStatus={verificationStatus}
        verificationActionRequired={false}
        div1="iconSection" div2="headerSection" div3="bodySection">
            <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
              <div className={classNames({ flexGrow: true })}>
                {identityTypeDisplayIcon}
                {verificationStatus === true ?
                  <i className={`fas fa-check-circle ${classNames({ colour_success: true })}`}></i>
                :
                  <i className={`fas fa-times-circle ${classNames({ colour_danger: true })}`}></i>
                }
              </div>
              <div>
                  <div></div>
              </div>
            </div>
            
            <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h3: true, text_color_grey_light: true })}> {identitySectionTitle} 
              
              </div>
              <div className={classNames({ text_h5: true, text_color_grey_light: true })}> 
                <StandardApiIcon
                  apiActivityId={`RemoveUserIdentity_${identityId}`}
                  icon="fas fa-times"
                  classes=""
                  handleClick={this.removeItem.bind(this, identityId)}
                />
              </div>
            </div>
            
            <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h5: true, text_color_grey_soft: true })}> 
              {verificationStatus !== true && (
                <div>{identitySectionSubTitle}</div>
              )}
              </div>
              
              {identityType !== "postal" && identityType !== "phone" && identityType !== "mobile" &&
               identityType !== "business-name" &&  identityType !== "business-brand-image" &&
               identityType !== "business-brand-words" &&
                <IdentityManagementPrimaryInputContainerV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}

                />
              }

              {identityType === "mobile" &&
                <IM_input_mobileV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}

                />
              }

              {identityType === "phone" &&
                <IdentityManagementPrimaryPhoneInputContainerV1
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}

                ivrMenuOptions={ivrMenuOptions}
                phoneHasIvr={phoneHasIvr}
                phoneHasQueue={phoneHasQueue}
  
                />
              }

              {identityType === "postal" &&
                <IdentityManagementPrimaryLocationInputContainerV2
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
  
                />
              }

              {identityType === "business-name" &&
                <IdentityManagementPrimaryBusinessNameInputContainerV2
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityAdditionalData={identityAdditionalData}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
  
                />
              }

              {identityType === "business-brand-image" &&
                <IdentityManagementPrimaryBusinessBrandImageInputContainerV2
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
  
                />
              }


              {identityType === "business-brand-words" &&
                <IdentityManagementPrimaryBusinessBrandWordsInputContainerV2
                componentTargetUser={componentTargetUser}
                identityId={identityId}
                identityInputValue={identityInputValue}
                identityMask={identityMask}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                id={id}
                name={name}
                onChangeHandler={this.onIdentityInputHandler}
                identityInputValidationStatus={this.recordIdentityInputValidationStatus}
  
                />
              }
              

              {verificationStatus === true ? (
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ aaa: true })}>
                    {this.state.collapse ?
                      <StandardBtn
                        icon="fas fa-angle-down"
                        iconLocation="right"
                        text="VERIFY IDENTITY"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-primary"
                        handleClick={this.collapseContainer}
                      />
                    :
                      <StandardBtn
                        icon="fas fa-angle-up"
                        iconLocation="right"
                        text="COLLAPSE"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-primary"
                        handleClick={this.collapseContainer}
                      />
                    } 
                  </div>
                  {verificationStatus !== true && (
                    <div className={classNames({ flex: true, })}>
                      {this.state.collapse && (
                        <div className={classNames({ padL20: true, colour_danger: true, flex: true, flexAlignItemsCenter: true, text_s14: true })}>
                          REQUIRED
                        </div>
                      )}
                    </div>
                  )}
                  <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true })}>
                    {this.state.identityNotificationEnabled ? (
                      <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                        <i class="fas fa-bell"></i>
                      </div>
                    ):(
                      <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                        <i class="fas fa-bell-slash"></i>
                      </div>
                    )}
                    
                  </div>
                </div>
              ):(
                <div>
                  
                  {this.state.identityInputLength > 0 ?
                  (
                    <div>
                      {this.state.inputStatus === true && (
                        <div className={classNames({ flex: true })}>
                          <div className={classNames({ aaa: true })}>
                            {this.state.collapse ?
                              <StandardBtn
                                icon="fas fa-angle-down"
                                iconLocation="right"
                                text="VERIFY IDENTITY"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-primary"
                                handleClick={this.collapseContainer}
                              />
                            :
                              <StandardBtn
                                icon="fas fa-angle-up"
                                iconLocation="right"
                                text="COLLAPSE"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-primary"
                                handleClick={this.collapseContainer}
                              />
                            } 
                          </div>
                          {verificationStatus !== true && (
                            <div>
                              {this.state.collapse && (
                                <div className={classNames({ padL20: true, colour_danger: true, flex: true, flexAlignItemsCenter: true, text_s14: true })}>
                                  REQUIRED
                                </div>
                              )}
                            </div>
                          )}
                          <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true })}>
                            {this.state.identityNotificationEnabled ? (
                              <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                                <i class="fas fa-bell"></i>
                              </div>
                            ):(
                              <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                                <i class="fas fa-bell-slash"></i>
                              </div>
                            )}
                            
                          </div>
                        </div>
                      )}
                      {this.state.inputStatus === '' && (
                        <div className={classNames({ flex: true })}>
                          <div className={classNames({ aaa: true })}>
                            {this.state.collapse ?
                              <StandardBtn
                                icon="fas fa-angle-down"
                                iconLocation="right"
                                text="VERIFY IDENTITY"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-primary"
                                handleClick={this.collapseContainer}
                              />
                            :
                              <StandardBtn
                                icon="fas fa-angle-up"
                                iconLocation="right"
                                text="COLLAPSE"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-primary"
                                handleClick={this.collapseContainer}
                              />
                            } 
                          </div>
                          {verificationStatus !== true && (
                            <div>
                              {this.state.collapse && (
                                <div className={classNames({ padL20: true, colour_danger: true, flex: true, flexAlignItemsCenter: true, text_s14: true })}>
                                  REQUIRED
                                </div>
                              )}
                            </div>
                          )}
                          <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true })}>
                            {this.state.identityNotificationEnabled ? (
                              <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                                <i class="fas fa-bell"></i>
                              </div>
                            ):(
                              <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                                <i class="fas fa-bell-slash"></i>
                              </div>
                            )}
                            
                          </div>
                        </div>
                      )}
                    </div>
                  ):(
                    <div>
                      {identityType === 'postal' && (
                        <div className={classNames({ flex: true })}>
                        <div className={classNames({ aaa: true })}>
                          {this.state.collapse ?
                            <StandardBtn
                              icon="fas fa-angle-down"
                              iconLocation="right"
                              text="VERIFY IDENTITY"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-primary"
                              handleClick={this.collapseContainer}
                            />
                          :
                            <StandardBtn
                              icon="fas fa-angle-up"
                              iconLocation="right"
                              text="COLLAPSE"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-primary"
                              handleClick={this.collapseContainer}
                            />
                          } 
                        </div>
                        {verificationStatus !== true && (
                          <div>
                            {this.state.collapse && (
                              <div className={classNames({ padL20: true, colour_danger: true, flex: true, flexAlignItemsCenter: true, text_s14: true })}>
                                REQUIRED
                              </div>
                            )}
                          </div>
                        )}
                        <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true })}>
                          {this.state.identityNotificationEnabled ? (
                            <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                              <i class="fas fa-bell"></i>
                            </div>
                          ):(
                            <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                              <i class="fas fa-bell-slash"></i>
                            </div>
                          )}
                          
                        </div>
                      </div>
                      )}
                      {identityType === 'business-name' && (
                        <div className={classNames({ flex: true })}>
                        <div className={classNames({ aaa: true })}>
                          {this.state.collapse ?
                            <StandardBtn
                              icon="fas fa-angle-down"
                              iconLocation="right"
                              text="VERIFY IDENTITY"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-primary"
                              handleClick={this.collapseContainer}
                            />
                          :
                            <StandardBtn
                              icon="fas fa-angle-up"
                              iconLocation="right"
                              text="COLLAPSE"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-primary"
                              handleClick={this.collapseContainer}
                            />
                          } 
                        </div>
                        {verificationStatus !== true && (
                          <div>
                            {this.state.collapse && (
                              <div className={classNames({ padL20: true, colour_danger: true, flex: true, flexAlignItemsCenter: true, text_s14: true })}>
                                REQUIRED
                              </div>
                            )}
                          </div>
                        )}
                        <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true })}>
                          {this.state.identityNotificationEnabled ? (
                            <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                              <i class="fas fa-bell"></i>
                            </div>
                          ):(
                            <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                              <i class="fas fa-bell-slash"></i>
                            </div>
                          )}
                          
                        </div>
                      </div>
                      )}
                    </div>
                  )}
                </div>
              )}


            </div>
          </IdentityManagementPrimaryContentContainerV1>

        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityManagementViewContainerBody: false })}>
          <div className={classNames({ flex: false})}>

            
            
          <IdentityManagementExpandedContentContainerV1
          div1="iconSection" div2="headerSection" div3="bodySection">
            <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
              <div className={classNames({ flexGrow: true })}>
                <i class="fas fa-heartbeat"></i>
              </div>
              <div className={classNames({ aaa: true })}>
                
              </div>
            </div>
            
            <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h3: true })}>STATUS</div>
            </div>
            
            <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h5: true })}>
                
                {verificationStatus === true ?
                  <div><i className={`fas fa-check-circle ${classNames({ colour_success: true })}`}></i> VERIFIED</div>
                :
                  <div><i className={`fas fa-times-circle ${classNames({ colour_danger: true })}`}></i> NOT VERIFIED</div>
                }
 
              </div>

              


              {verificationStatus === true && verificationStatusDate !== false &&
              (
                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true, })}>
                    <i class="far fa-clock"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                    Verified on
                  </div>
                  <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                    &nbsp;
                    <Moment unix format="Do MMM YYYY">
                      {verificationStatusDate}
                    </Moment>
                  </div>
                </div>
              )
              }

              {verificationStatus === true && reValidationDueDate !== false &&
              (
                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true })}>
                    <i class="far fa-clock"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL10: true, })}>
                    Re-Validation on 
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, fontWeightBold: true, })}>
                    &nbsp;
                    <Moment unix format="Do MMM YYYY">
                      {reValidationDueDate}
                    </Moment>
                  </div>
                  <div className={classNames({ text_h5: true, padL10: true, })}>
                    ({relativeReValdiationDueDate})
                  </div>
                </div>
              )
              }

              





              


              <div className={classNames({ text_h6: true })}>
              
              </div>


              {identityType === 'phone' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Verify Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}
                    
                    

                  </div>
                </div>
              }
              {identityType === 'mobile' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Verify Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}
  
                  </div>
                </div>
              }
              {identityType === 'email' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Verify Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }
              {identityType === 'website' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Request a Verification Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }
              {identityType === 'postal' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Request a Postal Verification Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }
              {identityType === 'business-name' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Request a Business Name Verification Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }
              {identityType === 'business-brand-image' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Request a Business Image Verification Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }
              {identityType === 'business-brand-words' &&
                <div className={classNames({ padL20: false })}>
                  <div className={classNames({ flex: true })}>
                
                    <StandardApiBtn
                      apiActivityId="UserIdentityVerifyNow"
                      icon=""
                      iconLocation=""
                      text="Request a Business Name Verification Now"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />

                    {componentTargetUser === "Business" && (
                      <div className={classNames({ flex: true, businessVerificationPricingApplies: true })}>
                        Verification pricing applies.
                      </div>
                    )}

                  </div>
                </div>
              }

            </div>
          </IdentityManagementExpandedContentContainerV1>



            


            <div className={classNames({ ShcModulesIdentityManagementExpandTabbedContainer: true, })}>

              <TabContainerV3
              businessId={businessId}
              tabOptionsGroup={`identityOptionsTabGroup_${identityId}`}
              >
                <div tabOptionsId="secTabOpt" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption: true })}>
                  SECURITY               
                </div>
                <div tabOptionsId="optTabOpt" className={classNames({ ShcModulesIdentityManagementExpandTabbedOption: true })}>
                  OPTIONS               
                </div>

              </TabContainerV3>
            </div>


            <div>


              { this.props.selectedTabOption.tabOptionsGroup === `identityOptionsTabGroup_${identityId}` &&
              this.props.selectedTabOption.selectedTabOptionsId === 'secTabOpt' ?
              (
                <div className={classNames({ color_primary_dark: true })}>

                  {identityType !== 'website' && (
                    <IdentityManagementExpandedContentContainerV1
                    key={['identityManagementContentContainer_', identityId, '_secTabOpt_', 'Security' ].join('_')}
                                        
                    div1="iconSection" div2="headerSection" div3="bodySection">
                      <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
                        <div className={classNames({ flexGrow: true })}>
                          <i class="fas fa-shield-alt"></i>
                        </div>
                      </div>
                      
                      <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                        <div className={classNames({ text_h3: true })}>SECURITY</div>
                      </div>
                      
                      <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                        <div className={classNames({ text_h6: true })}>
                        We will present the following security code for this interaction. Each interaction has a unique one-time code so you can valdiate that the messages are from us.
                        </div>


                        <div className={classNames({ padL15: false })}>
                          <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                            ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                            ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                            bg_colour_white: true, ShcIdentityInput_DisplayTextBox: true })}>
                            
                            
                            {identityType==="phone" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="mobile" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="email" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="postal" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="business-name" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="business-brand-image" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }
                            {identityType==="business-brand-words" && 
                              <div>
                                {communicationVerificationCodeArray.map((value, index) => {
                                  return <li key={index}>{value}</li>
                                })}
                              </div>
                            }


                          </div>
                        </div>


                      </div>
                    </IdentityManagementExpandedContentContainerV1>
                  )}

       


                  <IdentityManagementExpandedContentContainerV1
                  key={['identityManagementContentContainer_', identityId, '_secTabOpt_', 'Verification' ].join('_')}
                  div1="iconSection" div2="headerSection" div3="bodySection">
                    <div div1="iconSection" className={classNames({ width30: true, minWidth30: true, flex: true, flexColumn: true })}>
                      <div className={classNames({ flexGrow: true })}>
                        <i class="fas fa-theater-masks"></i>
                      </div>
                    </div>
                    
                    <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                      <div className={classNames({ text_h3: true })}>VERIFICATION</div>
                    </div>
                    
                    <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                      <div className={classNames({ text_h6: true })}>

                        {identityType==="phone" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a landline number or toll numbers, our system will phone the number entered
                              and provide you with a verification code to enter into the section below. 
                              <br/>
                              If you landline number is fronted by an IVR and or a queue, please log a support cause
                              within the support centre.

                            </TextStyle>
                          </div>
                        }

                        {identityType==="mobile" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a mobile phone, you will receive a SMS with a verification code and a communication code. 
                              The communication code is a way you can verify the SMS was sent by us. The verification
                              code should be entered below.<br/> 
                            </TextStyle>
                          </div>
                        }

                        {identityType==="email" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify an email address, you will receive an email from "verify@guardpoint.com.au"
                              with a verification link and a communication code. 
                              The communication code is a way you can verify the email was sent by us.
                            </TextStyle>
                          </div>
                        }

                        {identityType==="website" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              For website verifications, you need to demonstrate that you own the website. This is done by placing a HTML meta
                              tag on your website with the specific security code we provide you.
                            </TextStyle>
                          </div>
                        }

                        {identityType==="postal" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a postal address, we will send a letter to the address you have submitted. 
                              The letter will have a verification code that you can enter below. Please allow up to 
                              2 weeks for a letter to arrive.
                            </TextStyle>
                          </div>
                        }

                        {identityType==="business-name" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a business name, we will check the identity details on your countries
                              business registry and send a letter to the recorded business address associated with the
                              identity. This letter will have a verification code that you can enter below to 
                              demonstrate that you are an authority holder of the business name. Please allow up to 
                              2 weeks for the letter to arrive.
                            </TextStyle>
                          </div>
                        }

                        {identityType==="business-brand-image" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a business brand image, we will check the identity details on your countries
                              business registry and send a letter to the recorded business address associated with the
                              identity. This letter will have a verification code that you can enter below to 
                              demonstrate that you are an authority holder of the business brand image. Please allow up to 
                              2 weeks for the letter to arrive.
                            </TextStyle>
                          </div>
                        }

                        {identityType==="business-brand-words" && 
                          <div>
                            <TextStyle size="h5" weight="normal">
                              To verify a business brand words, we will check the identity details on your countries
                              business registry and send a letter to the recorded business address associated with the
                              identity. This letter will have a verification code that you can enter below to 
                              demonstrate that you are an authority holder of the business brand words. Please allow up to 
                              2 weeks for the letter to arrive.
                            </TextStyle>
                          </div>
                        }


                      </div>


                      {identityType === 'phone' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }

                      {identityType === 'mobile' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }



                      {identityType === 'website' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText=""
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
                              <TextStyle size="h5" weight="">
                                Enter the following html meta tag into the head of your website primary webpage. 
                              </TextStyle>
                              <br/>
                              <TextStyle size="h5" weight="">
                                <div className={classNames({
                                  wordWrapBreakWord: true,
                                })}>
                                  {`${String.fromCharCode('0x003c')}meta name="guardpoint-site-verification" content="${websiteVerificationCode}"/>`}
                                </div>
                                <br />
                              </TextStyle>
                              <br/>
        
                              <PreferenceUserControls title="Instructions" titleSize="medium" desc="" info="" toExpandText="Open" toCollapseText="Close">
                                <div className={classNames({ businessVerificationPreferenceControlsContainer: true })}>
                                <div>
                                  You can verify your ownership of a site by adding a Meta tag to the HTML of a specified page.
                                  We'll verify that the meta tag exists in the correct location. If we can't find the tag, we'll give you information
                                  about the error we encountered. This tag is tied to a specific business account.<br />
                                  Removing your verification Meta tag from your site can cause you to lose verification for the site.<br /><br />
        
                                  
        
                                </div>
                              </div>
                              </PreferenceUserControls>
        
                              <br/>
                                <StandardApiBtn
                                  apiActivityId="UserIdentityVerifyWebsiteCode"
                                  icon=""
                                  iconLocation=""
                                  text="Verify my website"
                                  tooltip=""
                                  action=""
                                  classes="shcbtn shcbtn-small shcbtn-primary"
                                  handleClick={this.handleIdentityVerificationWebsite}
                                />
                            
                             
        




                            </div>
                          </InputCardItem>
                        </div>
                      }




                      {identityType === 'postal' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }

                      {identityType === 'business-name' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }

                      {identityType === 'business-brand-image' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }

                      {identityType === 'business-brand-words' && 
                        <div>
                          <hr/>
                          <InputCardItem
                          id={['verificationCodeInputContainer', identityId].join('_')}
                          key={['verificationCodeInputContainer', identityId].join('_')}

                            headlineText="Code"
                            headlineSubText=""
                            additionalText=""
                            troubleshootingText=""
                          >
                            <div className={classNames({ padL15: false })}>
      
                              <div>
                                <div className={classNames({
                                  flex: true,
                                  flexJustifySpaceBetween: true,
                                  flexAlignBaseline: true,
                                  ShcIdentityInput_textInput_root: true,
                                })}
                                >
                                  <div className={classNames({
                                    ShcIdentityInput_textInput_child: true,
                                    ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                  })}
                                  >
                                    <input
                                      id={['verificationCodeInputField', identityId].join('_')}
                                      key={['verificationCodeInputField', identityId].join('_')}
                                      
                                      margin="normal"
                                      fullWidth
                                      name={name}
                                      value={this.state.verificationCode}
                                      identifiers={identifiers}
                                      stateIdentifier={stateIdentifier}
                                      onChange={this.handleIdentityVerificationCodeInput}
                                      type="text"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                    />
                                  </div>
                                </div>
                              </div>
                              {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                  <div>
                                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                      aria-label="Invalid Input"
                                    ></i>
                                  </div>
                                </div>
                              }
        
                              <br/>
                              <StandardApiBtn
                                apiActivityId="UserIdentityVerifyConfirmationCode"
                                icon=""
                                iconLocation=""
                                text="Confirm Identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-small shcbtn-primary"
                                handleClick={this.handleIdentityVerificationCodeSubmit}
                              />




                            </div>
                          </InputCardItem>
                        </div>
                      }






                    </div>
                  </IdentityManagementExpandedContentContainerV1>
                
                </div>
              ) : null}



              { this.props.selectedTabOption.tabOptionsGroup === `identityOptionsTabGroup_${identityId}` &&
              this.props.selectedTabOption.selectedTabOptionsId === 'optTabOpt' ?
              (
                <div className={classNames({ color_primary_dark: true })}>

                  <IdentityManagementExpandedContentContainerV1
                  key={['identityManagementContentContainer_', identityId, '_optTabOpt_', 'Label' ].join('_')}
                  div1="iconSection" div2="headerSection" div3="bodySection">
                    <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
                      <div className={classNames({ flexGrow: true })}>
                        <i class="fas fa-tag"></i>
                      </div>
                    </div>
                    
                    <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                      <div className={classNames({ text_h3: true })}>LABEL</div>
                    </div>
                    
                    <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                      <div className={classNames({ text_h6: true })}>
                      Add a label for this identity
                      </div>



                      <div>
                        <hr/>
                        <InputCardItem
                        id={['identityLabelInputContainer', identityId].join('_')}
                        key={['identityLabelInputContainer', identityId].join('_')}

                          headlineText="Label"
                          headlineSubText=""
                          additionalText=""
                          troubleshootingText=""
                        >
                          <div className={classNames({ padL15: false })}>
    
                            <div>
                              <div className={classNames({
                                flex: true,
                                flexJustifySpaceBetween: true,
                                flexAlignBaseline: true,
                                ShcIdentityInput_textInput_root: true,
                              })}
                              >
                                <div className={classNames({
                                  ShcIdentityInput_textInput_child: true,
                                  ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                                })}
                                >
                                  <input
                                    id={['identityLabelInputField', identityId].join('_')}
                                    key={['identityLabelInputField', identityId].join('_')}
                                    margin="normal"
                                    fullWidth
                                    name={labelInputIdentifier}
                                    value={identityLabel}
                                    identifiers={identifiers}
                                    stateIdentifier={stateIdentifier}
                                    onChange={this.handleGeneralInputChange.bind(this, identifiers, stateIdentifier)}
                                    type="text"
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                                  />
                                </div>
                              </div>
                            </div>
                            {verificationStatus !== true && this.state.verificationCodeInputErrors && 
                              <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                                <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                                <div>
                                  <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                    aria-label="Invalid Input"
                                  ></i>
                                </div>
                              </div>
                            }
      
     
                          </div>
                        </InputCardItem>
                      </div>







                    </div>
                  </IdentityManagementExpandedContentContainerV1>

                  

                  {/*this.props.apiEndpoint==="Business" && (
                    <IdentityManagementExpandedContentContainerV1
                    div1="iconSection" div2="headerSection" div3="bodySection">
                      <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
                        <div className={classNames({ flexGrow: true })}>
                          <i class="far fa-bell"></i>
                        </div>
                      </div>
                      
                      <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                        <div className={classNames({ text_h3: true })}>NOTIFICATIONS</div>
                      </div>
                      
                      <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                        <div className={classNames({ text_h6: true })}>
                        Notifications for this identity is currently disabled. You can activate by clicking on 
                        the bell icon next to the identity input field.
                        </div>
                      </div>
                    </IdentityManagementExpandedContentContainerV1>
                  )*/}
                  
                </div>
              ) : null}

            </div>


          </div>
        </div>
        }
        
      </div>

    );
  }
}

IdentityManagementPanelV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  //console.log("ownProps.identityId");
  //console.log(ownProps.identityId);

  let businessId = '';
  try {
    businessId = ownProps.businessId;
  } catch (e) {
  }

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `identityOptionsTabGroup_${ownProps.identityId}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  return {
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementPanelV1);


/* ALLOW LOGINS CONCEPT - UI COMPONENT

<IdentityManagementExpandedContentContainerV1
div1="iconSection" div2="headerSection" div3="bodySection">
  <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
    <div className={classNames({ flexGrow: true })}>
      <i class="fas fa-sign-in-alt"></i>
    </div>
  </div>
  
  <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
    <div className={classNames({ text_h3: true })}>ALLOW LOGIN</div>
  </div>
  
  <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
    <div className={classNames({ text_h6: true })}>
    Allow logins on this identity
    </div>
  </div>
</IdentityManagementExpandedContentContainerV1>


*/