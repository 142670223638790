import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, IdentityInputV1, IdentityInputV2, RadioOptionCardV1, LinkCardV1 } from 'Components/ShcInput';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { userDelete, reduxDataUpdate, apiDataUpdate } from 'Actions';

// Store
import { auth, actionCodeSettings } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.deleteUserAccount = this.deleteUserAccount.bind(this);
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); // new dynamic


    this.state = {
      user: this.props.user, // The main user object

      languageChange: 0,    //UI regeneration on language change

      apiTimerCollection: [], // List of active timers.

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } 
      return null;
    
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("componentDidUpdate - AccountUserSettingsProfilePage");
    if (prevProps !== this.props) {

    }

  }

  userChangeLanguage(lang, evt) {
    console.log("change language");
    console.log(lang);
    this.props.i18n.changeLanguage(lang);

  }

  
  deleteUserAccount(e) {
    console.log('deleteUserAccount');

    this.props.userDelete();

    // delete from our db first (utilise the auth process and lastly remove from firebase)
    /*
    Promise.resolve(this.props.userDelete(auth().currentUser)) // dispatch
      .then((result) => {
        //console.log('userDelete action returned');
        //console.log(result);
        auth().currentUser.delete();
      })
      .then((result) => {
        //console.log('successful removal of user account');
        //console.log(result);
      })
      .catch((error) => {
        //console.log('error in catch');
        //console.log(error);
      });
      */
  }


  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log('onChangeDynamicInputTextHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /** ************ LOCAL STATE DATA UPDATES ************ */

    this.props.reduxDataUpdate({ recordsForUpdate });

    /** ************ BACKEND DATA UPDATES **************** */
    // Each time there is a change, set a timer for a DB update to follow.

    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      // this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }


  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/dashboard"
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/dashboard"
                backTitle="Dashboard"
                smallTitleTF="true"
                smallTitle="User"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>

            <div>
              <InputCard headlineText={this.props.t('user.account.language.title')} 
              headlineSubText="Select a language">

                
                
                {/*this.props.t('welcome.title', {framework:'Guard Point'})*/}
                {this.props.t('welcome.languageSelected')}<br/>

                <div className={classNames({ flex: true })}>
                  <RadioOptionCardV1
                  businessId=""
                  radioOptionsGroup="userLanguageSelection"
                  radioOptionsId="en"
                  headlineText="English"
                  secondaryText=""
                  onSelectHandler={this.userChangeLanguage.bind(this, 'en')}
                  />

                  <RadioOptionCardV1
                  businessId=""
                  radioOptionsGroup="userLanguageSelection"
                  radioOptionsId="pt"
                  headlineText="Portuguese"
                  secondaryText=""
                  onSelectHandler={this.userChangeLanguage.bind(this, 'pt')}
                  />

                  <RadioOptionCardV1
                  businessId=""
                  radioOptionsGroup="userLanguageSelection"
                  radioOptionsId="de"
                  headlineText="German"
                  secondaryText=""
                  onSelectHandler={this.userChangeLanguage.bind(this, 'de')}
                  />
                </div>
                  
              </InputCard>

            </div>

          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {

  let userDataStorageRegion = '';
  try {
    userDataStorageRegion = state.User.dataRegion;
  } catch (e) {}
  if (userDataStorageRegion === 'aus') {
    userDataStorageRegion = "Australia";
  } else if (userDataStorageRegion === 'usa') {
    userDataStorageRegion = "United States";
  }

  let firstName = '';
  let lastName = '';
  try {
    firstName = state.User.userProfile.firstName;
  } catch (e) {}
  try {
    lastName = state.User.userProfile.lastName;
  } catch (e) {}


  return {
    user: state.User,
    userDataStorageRegion,
    firstName,
    lastName,
  };

};

const mapDispatchToProps = {
  userDelete,
  reduxDataUpdate,
  apiDataUpdate,
};

export default compose(withTranslation('common'),
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
