import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class CartItemCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }


  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
    } = this.state;


    return (
      <div className={classNames({ pageWrapper: false })}>
        <CardBasic className={classNames({ shcCardNavigationCard_container: true })}>
          <div>
            <div>
              SmartHome.Cloud Pro - Annual Subscription<br />
              $480 AUD / half-yearly<br />
              ($80 x 6 months)<br /><br />
              Total: $480<br />

            </div>

            <div>
            By checking out, you agree to our Terms of Service and authorize your payment method to be charged
            on a reoccuring basis until you cancel, which you can do at any time. You understand how your
            subscription works and how to cancel.

            </div>


            <div className={classNames({ shcCardNavigationCard_headlineSubTextPanel: true })}>
              <div className={classNames({ shcCardNavigationCard_headlineSubLeft: true })}>
                {headlineSubText}
              </div>
              <div className={classNames({ shcCardNavigationCard_headlineSubRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>

            <div className={classNames({ shcCardNavigationCard_headlineText: true })} component="h2">
              {headlineText}
            </div>
            <div className={classNames({ shcCardNavigationCard_secondaryText: true })}>
              { additionalText ? null : <br /> }
              {secondaryText}
            </div>
            <div className={classNames({ shcCardNavigationCard_additionalText: true })} component="p">
              {additionalText}
            </div>
          </div>
          <div className={classNames({ shcCardNavigationCard_buttonContainer: true })}>


            <StandardBtn
              icon=""
              iconLocation=""
              text={linkText}
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
            />


          </div>
        </CardBasic>
      </div>

    );
  }
}

CartItemCard.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

CartItemCard.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CartItemCard);

