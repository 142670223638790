import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';




class AuthenticatorAppContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.toggleScreenUp = this.toggleScreenUp.bind(this);
    this.toggleScreenDown = this.toggleScreenDown.bind(this);

    this.handleActivateDeactivate = this.handleActivateDeactivate.bind(this);
    
    this.state = {
      authEnabled: this.props.authEnabled,     //Provided by the parent component
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleActivateDeactivate() {
    console.log(`handleActivateDeactivate: ${this.state.authEnabled}`);
    this.props.activateAuth(this.state.authEnabled);
  }

  toggleScreenUp() {
    this.props.toggleScreenUp();
  }
  toggleScreenDown() {
    this.props.toggleScreenDown();
  }

 
  
  
  render() {
    const { 
      authEnabled,

      children, activeScreen, activateAuth
    } = this.props;



    return (
      <div className={classNames({ AuthenticatorAppContainer_ExpandContainerHeader: true, 
      flex: true, cursorPointer: true, flexGrow: true })}>


        <div className={classNames({ flexGrow: true, })}>
          <div className={classNames({flex: true})}>

            <div className={classNames({ flex: true, flexAlignSelfStart: true, flexGrow: true})}>
              <div className={classNames({ flex: true})}>
                <div>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true})}>

                    

                  </div>
                </div>
                <div>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true, padL10: true, padB20: true, })}>

                    {activeScreen === 'ScreenOverallStatus' && (
                      <div className={classNames({fontWeightBold: true,  })}>1. Setup Authenticator App</div>
                    )}
                    {activeScreen === 'ScreenSetup_qrcode' && (
                      <div className={classNames({fontWeightBold: true,  })}>2. Setup Guard Point Authentication</div>
                    )}
                    {activeScreen === 'ScreenSetup_verifyCode' && (
                      <div className={classNames({fontWeightBold: true,  })}>3. Verify Authenticator Setup Authenticator App</div>
                    )}
                    {activeScreen === 'ScreenSetup_confirmation' && (
                      <div className={classNames({fontWeightBold: true,  })}>4. Confirmation</div>
                    )}

                  </div>
                </div>
                
                
                <div>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenDown}>

                    <i className={`fas fa-angle-left ${classNames({color_primary_dark: true, 
                      padL10: true, text_s20: true, cursorPointer: true})}`}
                    aria-label="Left"
                    ></i>

                  </div>
                </div>
                <div>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenUp}>

                    <i className={`fas fa-angle-right ${classNames({color_primary_dark: true, 
                    padL10: true, padR10: true, text_s20: true, cursorPointer: true})}`}
                    aria-label="Right"
                    ></i>

                  </div>
                </div>
                

              </div>
              <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                <div className={classNames({fullHeight: true, flex: true, 
                  flexColumn: true, flexJustifyCenter: true})}>

   



                </div>
              </div>
              


            </div>
          </div>
          <div className={classNames({ flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ flexGrow: true })}>
                <div className={classNames({ flex: true, flexColumn: true })}>
                    
                  {children}

                </div>
              </div>
              
            </div>

          </div>
        </div>
        
      </div>

    );
  }
}

AuthenticatorAppContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthenticatorAppContainerV1);
