import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';

// Routing
//import Routes from 'Core/Routes';

// Material UI
import Grid from '@material-ui/core/Grid';

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import {  } from 'Actions';


class FooterPurposeCopyright extends React.Component {
  state = {
  };
  render() {

    return (
      <div className={classNames({shcFooterPurposeCopyrightContent:true})}>
        <div className={classNames({shcFooterPurposeCopyrightContent_container:true, flex: true,})}>

          <div className={classNames({positionRelative: true, height120: true, 
            maxHeight120: true, flexGrow: true, width100p: true })}>
            
            <div className={classNames({shcPatternsFontAbsolute: true, shcPatternsPath1: true, shcPatternsThemeDark: true, 
              height120: true, maxHeight120: true,})}>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
              cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            </div>
            <div className={classNames({shcPatternsTextAbsolute: true, height120: true, 
              maxHeight120: true, flexJustifyCenter: true, flex: true, flexAlignItemsCenter: true})}>
              
              <div>

                <div className={classNames({ flex: true, flexJustifyCenter: true, flexGrow: true })}>
                  <div className={classNames({ text_s16: true })}>
                    Operating to Protect the Community from Spam, Scam & Fraud
                  </div>
                </div>

                <div className={classNames({ flex: true, flexJustifyCenter: true, flexGrow: true })}>
                  <div className={classNames({ text_s12: true })}>
                    Copyright 2019 - {(new Date().getFullYear())}
                  </div>
                </div>

              </div>
              

            </div>

          </div>

        </div>
      </div>
    );
  }
}

FooterPurposeCopyright.propTypes = {

};


const mapStateToProps = state => ({
})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(FooterPurposeCopyright);