import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';

// Actions
import { toggleOverlayOnScreenResponsive, userIdentitiesVerifyNow, userIdentitiesCodeVerifyNow } from 'Actions';


// Functions
import {
  validateEmail, validateDomain, validateNumbersOnly,
  validateAlphaNoSpacesOnly, validateAlphaWithSpacesOnly,
  validateTextOnly, validateAlphaNumericNoSpacesOnly, validatePhoneNumbersOnly,
} from 'Helpers/Functions';


class IdentityViewV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.moreControlsPopup = this.moreControlsPopup.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);


    this.handleUserDataRefresh = this.handleUserDataRefresh.bind(this);

    


    this.collapseContainer = this.collapseContainer.bind(this);

    this.state = {
      userIdentity: this.props.data,
      value: this.props.value,
      verificationCode: '',
      verificationCodeInputErrors: false,
      //errors: false,
      // pattern: this.props.pattern,
      // maxlength: this.props.maxlength,

      identityVerified: false,
      identityNotVerified: true,
      identityReVerify: false,
      identityInputErrors: false,

      adjustIdentity: false,         //If the user changes an identity that was already verified, asked them if they really want to...

      verifiedStateValue: '',       //If the value is verified, keep a copy here. If the user changes, then changes back, don't have to re-verify - as long as we didn't update the DB value!
          //maybe have a confirmation that you are changing the identity - re-verification is required - proceed or cancel?


      verified: false,
      collapse: true,     //start collapsed

      data: this.props.data,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - IdentityViewV1');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let identityVerified = prevState.identityVerified;
    let identityNotVerified = prevState.identityNotVerified;
    let identityReVerify = prevState.identityReVerify;

    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.


    let updateState = false;
    if (nextProps.data !== prevState.data) {
      updateState = true;
    }

    //was NOT verified and NOW verified = collapse.
    try {
      if (prevState.identityVerified === false && nextProps.data.verificationStatus === true) {
        collapse=true;
      }
    } catch(e) {
      //no change to collapse
    }

    try {
      if (nextProps.data.verificationStatus === true) {
        updateState = true;
  
        identityVerified = true;
        identityNotVerified = false;
        identityReVerify = false; //check
      } else {
        updateState = true;
        identityVerified = false;
        identityNotVerified = true;
        identityReVerify = false; //check
      }
    } catch(e) {
      //IF there is an error getting the verification status, mark it as not verified.
      updateState = true;
      identityVerified = false;
      identityNotVerified = true;
      identityReVerify = false; //check
    }
    






    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      //console.log('data');
      //console.log(nextProps.data);
      return {
        data: nextProps.data,
        identityVerified,
        identityNotVerified,
        identityReVerify,
        collapse,
      };
    }
    return null;
  }

  handleUserDataRefresh() {
    //console.log("REFRESH USER DATA");
  }

  handleIdentityVerificationRequest(event) {
    event.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { userIdentity } = this.state;
    const data = {
      userIdentity,
    };
    this.props.userIdentitiesVerifyNow({ data });
  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }

  handleIdentityVerificationCodeSubmit() {
    event.preventDefault();
    //console.log("handleIdentityVerificationCodeSubmit");
    const { userIdentity } = this.state;
    
    let identityLocalInputVerificationCode = this.state.verificationCode;
    //console.log("identityLocalInputVerificationCode");
    //console.log(identityLocalInputVerificationCode);
    /*
    try {
      identityLocalInputVerificationCode = this.props.userIdentities.identityLocalInputVerificationCode;
      //console.log("this.props.userIdentities.identityLocalInputVerificationCode");
      //console.log(this.props.userIdentities.identityLocalInputVerificationCode);
    } catch(e) {
      //console.log("error: unable to retrieve: identityLocalInputVerificationCode");
    }
    */
    const data = {
      userIdentity,
      verificationCode: identityLocalInputVerificationCode,
    };
    this.props.userIdentitiesCodeVerifyNow({ data });
  }
  
  

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let errors = false;
    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        if (validatePhoneNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(this.props.value);
        if (validateNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(this.props.value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  moreControlsPopup(evt) {
    //console.log('moreControlsPopup');
    //console.log('Verify Account');
    const data = {};
    this.props.toggleOverlayOnScreenResponsive('open', 'VerifyAccount', data);
  }



  render() {
    const {
      identifiers, stateIdentifier, data,
    } = this.props;

    let recordId = "";
    let identity = "";
    let type = "";

    try { recordId = data._id; } catch(e) {}
    try { identity = data.identity; } catch(e) {}
    try { type = data.type; } catch(e) {}



    return (
      <div>
        <div className={classNames({ ShcIdentityView_Section: true, ShcIdentityInput_expandedContent: !this.state.collapse })}>
          <div>
            <div className={classNames({
              flex: true,
              flexJustifySpaceBetween: true,
              flexAlignBaseline: true,
              ShcIdentityInput_textInput_root: true,
            })}
            >
              <div className={classNames({
              ShcIdentityInput_textInput_child: true,
              ShcIdentityViewOnlyFieldRemoveItem_icon: true,
              })}
              >
                <div className={classNames({
                ShcIdentityViewOnlyField: true,
                })}
                {...this.props}
                margin="normal"
                fullWidth
                identifiers={identifiers}
                stateIdentifier={stateIdentifier}
                >
                  {identity}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IdentityViewV1.propTypes = {

};
IdentityViewV1.defaultProps = {

};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,

});

const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,
  userIdentitiesVerifyNow, 
  userIdentitiesCodeVerifyNow
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityViewV1);
