
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class SingleLineCardItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      
      headlineText: props.headlineText,
      referenceId: props.secondaryText,
      height: props.height,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { classes } = this.props;
    const {
      headlineText, referenceId, headlineSubText, secondaryText, additionalText, troubleshootingText, linkText,
      height, customStyle,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
      width: '100%',
    };

    let sectionPadding = true;
    if (customStyle === 'slim') {
      sectionPadding = false;
    }


    return (
      <div >
        <div className={classNames({ ShcSingleLineCardItem: sectionPadding })}>
          <div className={classNames({ ShcSingleLineCardItem_primaryText: true })}>
            {headlineText}
          </div>
          { referenceId ?
            <div className={classNames({ ShcSingleLineCardItem_refText: true })}>
              {referenceId}
            </div>
          : null }
        </div>
      </div>

    );
  }
}

SingleLineCardItem.propTypes = {
  headlineText: PropTypes.string,
  referenceId: PropTypes.string,
};

SingleLineCardItem.defaultProps = {
  headlineText: '',
  referenceId: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SingleLineCardItem);
