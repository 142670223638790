import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class CardBasic extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { padding, height } = this.props;

    return (
      <div className={classNames({ CardBasic_wrapper: true, CardBasic_padding20: padding !== 'none', marB10: true })}>
        {this.props.children}
      </div>
    );
  }
}

CardBasic.propTypes = {

};
CardBasic.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardBasic);
