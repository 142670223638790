import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';


import Moment from 'react-moment';
var moment = require('moment');
moment().format();



// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';

//import { IdentityViewV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { SingleLineAccessKeyItem } from 'Components/ShcModulesApiManagement';




// Actions
import { reduxDataUpdate, apiDataUpdate, revealSecretKey } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class ApiManagementKeyView extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);

    this.revealSecretKey = this.revealSecretKey.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,
      apiSecretKey: '',



    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeItem(keyId, keyItemId, evt) {
    //console.log("removeItem");

    //console.log("keyId");
    //console.log(keyId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      keyId,
      keyItemId,
    }

    this.props.onRemoveItem({item, apiUi});
  }

  toggleKeyItemStatus_active(keyId, keyItemId, evt) {
    //console.log("removeItem");

    //console.log("keyId");
    //console.log(keyId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      keyId,
      keyItemId,
    }

    this.props.ontoggleKeyItemStatus_active({item, apiUi});
  }

  toggleKeyItemStatus_inactive(keyId, keyItemId, evt) {
    //console.log("removeItem");

    //console.log("keyId");
    //console.log(keyId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      keyId,
      keyItemId,
    }

    this.props.ontoggleKeyItemStatus_inactive({item, apiUi});
  }

  
  revealSecretKey(body = {}) {
    //business, data, apiUi

    let { business, data, apiUi } = body;
    let apiSecretKey = '';
    this.props.revealSecretKey({business, data, apiUi})
    .then((response) => {
      if (response.status === true) {
        apiSecretKey = response.apiSecretKey;
        this.setState({
          apiSecretKey: apiSecretKey,
        })
      }
    });



  }
  

  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }


  render() {
    let { businessId, keyId, keyItemId, active, maskedKey, created, onRemoveItem, ontoggleKeyItemStatus_active, ontoggleKeyItemStatus_inactive,
     } = this.props;

    maskedKey = `${maskedKey}******`;

    //created - as a date, days from created to now. (USE MOMENT)

    console.log("=============== created ====================");
    console.log(created);


    let timeSinceCreatedDateInDays = 0;
    if (created !== null) {
      var a = moment().utc();
      var b = moment.unix(created).utc().format("MM/DD/YYYY");

      timeSinceCreatedDateInDays = a.to(b);
      
      console.log("=============== MOMENT ====================");
      console.log(`a: ${a}`);
      console.log(`b: ${b}`);
      console.log(timeSinceCreatedDateInDays);
      
    }

    let keyItemStatus = '';
    if (active === true) {
      keyItemStatus = 'Active';
    } else {
      keyItemStatus = 'Inactive';
    }


    return (
      <div className={classNames({ ShcAccountModulesChildContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>
            
              
              <div className={classNames({ flex: true })}>
                <div className={classNames({ text_s12: true, flexAlignSelfCenter: true, padLR10: true })}>
                  {keyItemStatus}
                </div>
                {active === true ? (
                  <div className={classNames({ aaa: true })}>
                    <StandardApiBtn
                      apiActivityId={`ChangeKeyStatus_${keyId}_${keyItemId}_onBusiness_${businessId}`}
                      icon=""
                      iconLocation=""
                      text="Make Inactive"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-small shcbtn-outline-secondary"
                      handleClick={this.toggleKeyItemStatus_inactive.bind(this, keyId, keyItemId)}
                    />
                  </div>
                ):(
                  <div className={classNames({ aaa: true })}>
                    <StandardApiBtn
                      apiActivityId={`ChangeKeyStatus_${keyId}_${keyItemId}_onBusiness_${businessId}`}
                      icon=""
                      iconLocation=""
                      text="Make Active"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-small shcbtn-outline-secondary"
                      handleClick={this.toggleKeyItemStatus_active.bind(this, keyId, keyItemId)}
                    />
                  </div>
                )}
                
              </div>
              <div className={classNames({ ShcModulesApiMgt_removeItem: true })}>
                <StandardApiBtn
                  apiActivityId={`RemoveApiKey_${keyId}_${keyItemId}_onBusiness_${businessId}`}
                  icon=""
                  iconLocation=""
                  text="Delete"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-small shcbtn-outline-danger"
                  handleClick={this.removeItem.bind(this, keyId, keyItemId)}
                />
              </div>
            </div>
 
          </div>

          <div className={classNames({ flex: true, cursorPointer: true, padB10: true })}
          onClick={this.collapseContainer}>

            <SingleLineAccessKeyItem
            secretKey={this.state.apiSecretKey}
            maskedKey={maskedKey}
            businessId={businessId}
            keyId={keyId}
            keyItemId={keyItemId}
            revealSecretKey={this.revealSecretKey}
            />
    
          </div>

          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>

              <div className={classNames({ ShcModulesApiMgt_keyCreatedText: true })}>
                Created:&nbsp;
                <Moment unix format="Do MMM YYYY">
                  {created}
                </Moment>
              </div>
              <div className={classNames({ ShcModulesApiMgt_keyAgeText: true })}>
                Age: {timeSinceCreatedDateInDays} <i class="fas fa-exclamation-circle"></i>
              </div>
              
            </div>
            <div className={classNames({ flex: true, flexJustifyFlexEnd: true, ShcModulesApiMgt_keyAgeText: true })}>
              ID: {keyItemId}
            </div>
 
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

ApiManagementKeyView.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  revealSecretKey,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiManagementKeyView);
