import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


import Moment from 'react-moment';
var moment = require('moment');
moment().format();

// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';

// Actions
import { reduxDataUpdate, apiDataUpdate, updateUserIdentities } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType, timeNow } from 'Helpers/Functions';

class IM_website_statusV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);

    this.handleChangeV2 = this.handleChangeV2.bind(this);


    this.getReValidationData = this.getReValidationData.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.

      reValidationDueDate: this.props.reValidationDueDate,




      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    
    

    //console.log("nextProps.dataSavingReference");
    //console.log(nextProps);
    //console.log(nextProps.dataSavingReference);

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
    

    /*
    let savingData = prevState.savingData;
    let apiCompleted = '';
    try {
      apiCompleted = nextProps.dataSavingReference.apiCompleted;
    } catch(e) {
      ////console.log("unable to get apiCompleted in getDerivied");
    }
    */
    /*
    let apiTransactionId = '';
    try {
      apiTransactionId = nextProps.dataSavingReference.apiTransactionId;
    } catch(e) {
      ////console.log("unable to get lastChangeTransactionId in getDerivied");
    }
    */
   /*
    if (prevState.savingData === false && apiCompleted === true) {
      if (prevState.lastChangeTransactionId !== '' && 
      prevState.lastChangeTransactionId === apiTransactionId) {
        ////console.log("SET THE SYNCSTATUS TO TRUE NOW!!!");
        savingData = false;
      } else {
        ////console.log("CAREFUL | this is not actually the id we are watching for");
      }
    }
    */

    //console.log(`SET SAVING DATA to ${savingData}`);
    //console.log(`UPDATE STATE: ${updateState}`);

    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }

    let reValidationDueDate = null;
    try {
      reValidationDueDate = parseInt(this.state.reValidationDueDate);
    } catch (e) {}
    
    let reValidationDueDateAsString = '';
    try {
      //reValidationDueDateAsString = moment.unix(reValidationDueDate).format('L');    //DD/MM/YYYY
      reValidationDueDateAsString = moment.unix(reValidationDueDate).format("Do MMM YYYY");
    } catch (e) {
      reValidationDueDateAsString = '';
    }
    if (reValidationDueDateAsString === undefined) {
      reValidationDueDateAsString = '';
    }
    console.log(`reValidationDueDateAsString | ${reValidationDueDateAsString}`);

    let reValidationDisplayText = `Re-validation on ${reValidationDueDateAsString}`;

    
    console.log(`reValidationDisplayText | ${reValidationDisplayText}`);


    let timeNowObj = timeNow();
    let dateTimeNowSec = timeNowObj.dateTimestampSec;

    
    console.log("====== dateTimeNowSec ======");
    console.log(dateTimeNowSec);

    let verificationStatus = this.props.verificationStatus;
    if (dateTimeNowSec > reValidationDueDate) {
      console.log("THIS IDENTITY HAS EXPIRED");
      verificationStatus = false;
    } else {
      console.log("THIS IDENTITY IS STILL VALID FROM A TIME PERSPECTIVE");
    }



    this.setState({
      identityInputErrors: errors,
      verificationStatus,
      reValidationDueDate,
    });

  }


  getReValidationData() {
    let reValidationDueDate = this.state.reValidationDueDate;
    let reValidationDueDateAsString = '';   //Re-Validation on 3rd Dec 2020 (in 2 months)
    
    ///// 
    try {
      reValidationDueDate = parseInt(reValidationDueDate);
    } catch (e) {} 
    try {
      reValidationDueDateAsString = moment.unix(reValidationDueDate).format("Do MMM YYYY");
    } catch (e) {
      reValidationDueDateAsString = '';
    }
    if (reValidationDueDateAsString === undefined) {
      reValidationDueDateAsString = '';
    }

    /////
    let timeNowObj = timeNow();
    let dateTimeNowSec = timeNowObj.dateTimestampSec;

    /////
    let relativeReValdiationDueDate = '';
    if (reValidationDueDate !== null) {
      var a = moment();
      var b = moment.unix(reValidationDueDate).format("MM/DD/YYYY");
      relativeReValdiationDueDate = a.to(b);
    }

    let reValidationDisplayText = `Re-validation on ${reValidationDueDateAsString} (${relativeReValdiationDueDate})`;
    console.log(`reValidationDisplayText | ${reValidationDisplayText}`);

    return {
      reValidationDueDate: reValidationDueDate,
      relativeReValdiationDueDate: relativeReValdiationDueDate,
      reValidationDisplayText: reValidationDisplayText,
    }
  }
  

  componentDidUpdate(prevProps, prevState) {
  }
  


  //identifiers, stateIdentifier, event
  async handleChangeV2(receivedUpdateObj) {
    console.log("handleChangeV2");
    console.log(receivedUpdateObj);

    let identifiers = receivedUpdateObj.identifiers;
    let stateIdentifier = receivedUpdateObj.stateIdentifier;
    let identityInputValue = receivedUpdateObj.value;
    let name = receivedUpdateObj.name;

    let errors = false;
    let identityInputErrors = receivedUpdateObj.errors;    //new
    if (identityInputErrors === true) {
      errors = true;
    }

    console.log("identityInputErrors");
    console.log(identityInputErrors);

    console.log("errors");
    console.log(errors);



    //const name = event.target.name;
    //const identityInputValue = event.target.value;
    
    const lastChangeTransactionId = uuidv1();

    //console.log(identifiers);
    //console.log(stateIdentifier);

    //console.log(this.props.identityType);
    //console.log(identityInputValue);

    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        //errors = false;   //Do not override pervious error settings
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    

    if (errors === true) {
      console.log('errors | set identityInputErrors');
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        savingData: false,
      });
      //Advise the parent component that the input status changed
      this.props.identityInputValidationStatus(false);
    } else {
      //No errors experienced, save record
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value: identityInputValue,
        lastChangeTransactionId,
      };


      


      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////
      this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.

      
      //Advise the parent component that the input status changed
      this.props.identityInputValidationStatus(true);


      //this.updateData(returnObj);   //ORIG

      //New code for identity field updates
      //Remove spaces for mobile numbers
      identityInputValue = identityInputValue.replace(/\s/g, "");
      let data = {
        type: this.props.identityType,
        identity: identityInputValue,
        identityId: this.props.identityId,
      }

      let apiResponse = await this.props.updateUserIdentities({data});
      console.log('apiResponse');
      console.log(apiResponse);

      this.setState({
        savingData: false,
      });


      

    } //end if errors
  }





  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }




  render() {
    const { 
      identityInputValidationStatus,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,
      identityInputValue,
      icon, children 
    } = this.props;

    let { 
      identityMask,
    } = this.props;

    let businessIdentity = false;
    if (businessId === '' || businessId === undefined) {
      businessIdentity = false;
    } else {
      businessIdentity = true;
    }

    //This is used for transition of existing identities
    if (identityMask === '' || identityMask === undefined || identityMask === null) {
      identityMask = identityInputValue;
    }

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    /*
    let relativeReValdiationDueDate = '';
    if (this.state.reValidationDueDate !== null) {
      var a = moment();
      var b = moment.unix(this.state.reValidationDueDate).format("MM/DD/YYYY");

      relativeReValdiationDueDate = a.to(b);
    }
    */


    let reValidationData = this.getReValidationData();
    let reValidationDueDate = reValidationData.reValidationDueDate;
    let relativeReValdiationDueDate = reValidationData.relativeReValdiationDueDate;
    let reValidationDisplayText = reValidationData.reValidationDisplayText;



    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: identityLocked === true ? false : this.state.identityInputErrors,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ flex: true })}>
              <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                ShcIdentityInput_textInput_errors: false, flexGrow: true,
                })}>
                {businessIdentity === true ? (
                  this.state.identityInputValue
                ):(
                  identityMask
                )}
              </div>
              <div className={classNames({ flex: true, flexJustifyFlexEnd: true, flexAlignItemsCenter: true, text_s14: true,
              padL10: true, padR10: true })}>
                {this.state.identityNotificationEnabled ? (
                  <div className={classNames({ text_s20: true, colour_primary: true })} onClick={this.toggleIdentityNotification}>
                    <i class="fas fa-bell"></i>
                  </div>
                ):(
                  <div className={classNames({ text_s20: true, colour_lightGrey: true })} onClick={this.toggleIdentityNotification}>
                    <i class="fas fa-bell-slash"></i>
                  </div>
                )}
                
              </div>
            </div>
            :
            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })}>
                <InputTextV3 
                  id={name} 
                  name={name} 
                  identifiers={identifiers}
                  stateIdentifier={stateIdentifier}
                  label='' 
                  value={this.state.identityInputValue}
                  onChangeInputTextHandler={this.handleChangeV2} 
                  dataType="email" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                  pattern=""
                  feature=""
                />
              </div>
            </div>
            }
            
          </div>
        </div>
          
        <div className={classNames({ flex: true })}>

          {this.props.verificationStatus && 
            <div className={classNames({ flex: true })}>
              <div>
                <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                aria-label="Verified"
                ></i>
              </div>
              <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
            </div>
          }

          {!this.props.verificationStatus && 
            <div className={classNames({ flex: true })}>
              <div>
                <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                aria-label="Not Verified"
                ></i>
              </div>
              <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
            </div>
          }

        </div>

        {verificationStatus === true && this.state.reValidationDueDate !== false && (
          <div id="FlexGroup" className={classNames({ flex: true })}>
            <div className={classNames({ text_h3: true })}>
              <i class="far fa-clock"></i>
            </div>
            <div className={classNames({ text_h5: true, padL10: true, flexAlignSelfCenter: true })}>
              {reValidationDisplayText} 
            </div>
          </div>
        )}

      </div>
    );
  }
}




IM_website_statusV1.defaultProps = {
  identityLocked: false,
};


IM_website_statusV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  
  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  updateUserIdentities,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IM_website_statusV1);
