import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
// Components
import { StandardBtn } from 'Components/ShcButtons';
import { OverlayOnScreen, OverlayOnScreenResponsive  } from 'Components/ShcPopups';
// Actions
import { toggleOverlayOnScreen, toggleOverlayOnScreenResponsive } from 'Actions';


class BusinessIdentitiesUsersRolesPermissionsView extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleRemovePermissionRequest = this.handleRemovePermissionRequest.bind(this);
    this.handleRemovePermissionConfirmed = this.handleRemovePermissionConfirmed.bind(this);

    //this.handleAddPermissionRequest = this.handleAddPermissionRequest.bind(this);
    this.handleAddPermissionConfirmed = this.handleAddPermissionConfirmed.bind(this);
    this.handleChangePermissionsSelection = this.handleChangePermissionsSelection.bind(this);

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.state = {
      overlayOnScreen: '',
      overlayOnScreenResponsiveData: '',
      UsersRolesPermissionsSelection: 'customerConnect',

    };
  }


  componentDidUpdate(prevProps, prevState) {
    console.log("overlayOnScreenResponsiveData");
    console.log(this.props.overlayOnScreenResponsiveData);
  }

  closeOverlayOnScreen = (overlayId) => {
    console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  
  
  handleRemovePermissionRequest = (permissionName, userPermissionData) => {
    console.log('Remove permission');
    console.log(permissionName+', '+JSON.stringify(userPermissionData));
    // popup - are you sure you want to remove this permission?
    const data = {
      userPermissionData: userPermissionData,
      permission: permissionName,
      action: 'remove',
    };

    this.props.toggleOverlayOnScreenResponsive('open', 'UserRolesPermissionsRemovePermission', data);
  }

  handleRemovePermissionConfirmed = (userId, permissionName) => {
    console.log('remove permission - confirmed - action');
    console.log('remove permission - '+userId+' '+permissionName);

    console.log('sending to back end to process and remove for:')
    console.log(userId+' removing permission '+permissionName);

  }
  
  handleChangePermissionsSelection = (e) => {
    this.setState({UsersRolesPermissionsSelection: e.target.value});
  }
  
  
  handleAddPermissionConfirmed = (userId) => {
    console.log('remove permission - confirmed - action');
    console.log('remove permission - '+userId);

    const {UsersRolesPermissionsSelection} = this.state;

    console.log('sending to back end to process and add for:')
    console.log(userId+' adding permission '+UsersRolesPermissionsSelection);

  }
  


  /*
  handleAddPermissionRequest = (userId, userName) => {
    console.log('Add permission');
    const data = {
      id: userId,
      name: userName,
      action: 'add',
    };
    this.props.toggleOverlayOnScreenResponsive('open', 'UserRolesPermissionsAddPermission', data);
  }
  */


  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { userPermissionData } = this.props;
    console.log('permissions on user roles permissions display');
    console.log(userPermissionData);

    const TableListRow = permissionName => (
      <div className={classNames({ UsersRolesPermissionDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesPermissionDisplay_divTableCell: true, UsersRolesPermissionDisplay_contentText: true })}>

          <div
            className={classNames({ UsersRolesPermissionDisplay_permissionItemStyle: true })}
            onClick={() => this.handleRemovePermissionRequest(permissionName, userPermissionData)}
          >
            {permissionName}

            <div className={classNames({ UsersRolesPermissionDisplay_permissionItemStyleRemove: true })}>
              <i className={`fas fa-times ${classNames({ UsersRolesPermissionDisplay_permissionItemStyleRemove: true })}`} />
            </div>

          </div>

        </div>
      </div>
    );



    const TableList = () => (
      <div>
        <div className={classNames({ UsersRolesPermissionDisplay_divTable: true, UsersRolesPermissionDisplay_container: true })}>
          <div className={classNames({ UsersRolesPermissionDisplay_divTableBody: true })}>

            {userPermissionData.businessUserPermissions.map(obj => (
              TableListRow(obj.name)
            ))}

          </div>
        </div>
      </div>
    );
    /*
    user: {
        firstName: firstName,
        lastName: lastName,
        primaryEmail: primaryEmail,
        userActive: userActive,
        userId: userId
      },
      business: businessDetails,
      businessUserPermissions: userPermission,
    */
    const RemovePermissionFromUserDisplay = () => {
      if (this.props.overlayOnScreenResponsiveData !== '') {
        const {permission, userPermissionData} = this.props.overlayOnScreenResponsiveData;
        console.log('state overlay data');
        console.log(this.props.overlayOnScreenResponsiveData);
        return (
          <div>
            Business: <font className={classNames({ fontWeightBold: true })}>{userPermissionData.business.businessName}</font><br/>
            Business ID: <font className={classNames({ fontWeightBold: true })}>{userPermissionData.business.businessId}</font><br/>
            <br/>
            Are you sure you want to remove the following permission from user: 
            <font className={classNames({ fontWeightBold: true })}> {userPermissionData.user.firstName} : {userPermissionData.user.primaryEmail}</font> ?
            <br/><br/>
            Permission being removed: <font className={classNames({ fontWeightBold: true })}>{permission}</font><br/>

          </div>
        )
      }
      return "";
    };

    const AddPermissionToUserDisplayInnerTitle = () => {
      if (this.props.overlayOnScreenResponsiveData !== undefined && this.props.overlayOnScreenResponsiveData.name !== undefined) {
        const {name} = this.props.overlayOnScreenResponsiveData;
        return (
          <div>
          
            Add permissions to user
          </div>
        )
      
      }
      return "";
    };

    /*
    let userPermissionData = {
      user: {
        firstName: firstName,
        lastName: lastName,
        primaryEmail: primaryEmail,
        userActive: userActive,
        userId: userId
      },
      business: businessDetails,
      businessUserPermissions: userPermission,
    }
    */

    const AddPermissionToUserDisplay = () => {
      if (this.props.overlayOnScreenResponsiveData !== undefined &&
        this.props.overlayOnScreenResponsiveData !== ""
          ) {
        console.log("AddPermissionToUserDisplay");
        console.log(this.props.overlayOnScreenResponsiveData);
        const {user, business, businessUserPermissions} = this.props.overlayOnScreenResponsiveData; 
        return (
          <div>
            <div>
              Business: <font className={classNames({ fontWeightBold: true })}>{business.businessName}</font><br/>
              Business ID: <font className={classNames({ fontWeightBold: true })}>{business.businessId}</font><br/>
              <br/>
              Add permission for : <font className={classNames({ fontWeightBold: true })}> {user.firstName} : {user.primaryEmail}</font>
              <br/>
            </div>
            <div>
              <select id="UsersRolesPermissionsSelection" name="UsersRolesPermissionsSelection" 
                onChange={this.handleChangePermissionsSelection}
                defaultValue={this.state.UsersRolesPermissionsSelection}>
                <option value="user">User</option>
                <option value="billing">Billing</option>
                <option value="dev-test">Developer - Test Access</option>
                <option value="dev-prod">Developer - Prod Access</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
        )
      }
      return "";
    };


    return (
      <div>
        <TableList />

        <OverlayOnScreenResponsive
          id="UserRolesPermissionsRemovePermission"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Remove User Permission"
          classes=""
        >
          <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionContainer: true })}>
            <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle: true })}>
              Are you sure?
            </div>
            <RemovePermissionFromUserDisplay/>

            <br/><br/>
            <div className={classNames({ flex: true, 
            flexJustifySpaceBetween: true, 
            UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('UserRolesPermissionsRemovePermission')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Remove Permission"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-danger"
                  handleClick={() => this.handleRemovePermissionConfirmed(this.props.overlayOnScreenResponsiveData.id, this.props.overlayOnScreenResponsiveData.permission)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="UserRolesPermissionsAddPermission"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add User Permission"
          classes=""
        >
          <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionContainer: true })}>
            <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle: true })}>
              <AddPermissionToUserDisplayInnerTitle/>
            </div>
            <AddPermissionToUserDisplay/>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('UserRolesPermissionsAddPermission')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Add Permission"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={() => this.handleAddPermissionConfirmed(this.props.overlayOnScreenResponsiveData.id)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>
    );
  }
}

BusinessIdentitiesUsersRolesPermissionsView.propTypes = {

};
BusinessIdentitiesUsersRolesPermissionsView.defaultProps = {
};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenResponsiveData: state.CommonActions.overlayOnScreenResponsiveData,

  toggleOverlayOnScreenResponsive: state.CommonActions.toggleOverlayOnScreenResponsive,
  toggleOverlayOnScreenResponsiveData: state.CommonActions.toggleOverlayOnScreenResponsiveData,

  
});

const mapDispatchToProps = {
  toggleOverlayOnScreen,
  toggleOverlayOnScreenResponsive,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessIdentitiesUsersRolesPermissionsView);
