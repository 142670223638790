export function validateEmail(email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

export function validateDomain(domain) {
  const regexp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
  return regexp.test(domain);
}

export function validateDomainUrlFull(domain) {
  const regexp = /(^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
  return regexp.test(domain);
}

export function validatePhoneNumbersOnly(string) {
  const regExPattern = /^([0-9+ ]+)$/;
  return regExPattern.test(string);
}

export function validateNumbersOnly(string) {
  const regExPattern = /^(\d+)$/;
  return regExPattern.test(string);
}
export function validateAlphaNoSpacesOnly(string) {
  const regExPattern = /^([A-Za-z]+)$/;
  return regExPattern.test(string);
}
export function validateAlphaWithSpacesOnly(string) {
  const regExPattern = /^([A-Za-z ]+)$/;
  return regExPattern.test(string);
}
export function validateTextOnly(string) {
  const regExPattern = /^([\w .,:;]+)$/;
  return regExPattern.test(string);
}
export function validateAlphaNumericNoSpacesOnly(string) {
  const regExPattern = /^([A-Za-z0-9]+)$/;
  return regExPattern.test(string);
}

export function validateAlphaNumericWithSpacesOnly(string) {
  const regExPattern = /^([A-Za-z0-9 ]+)$/;
  return regExPattern.test(string);
}

export function validateAlphaNumericSpacesAndTextCharacters(string) {
  const regExPattern = /^([A-Za-z0-9 \:\;\'\,\.\-]+)$/;
  return regExPattern.test(string);
}


export function validateSocialIdentity(string) {
  const regExPattern = /^([A-Za-z0-9\_\.\-@]+)$/;
  return regExPattern.test(string);
}

export function validatePostalAddressIdentity(string) {
  const regExPattern = /^([A-Za-z0-9 \,\.\-]+)$/;
  return regExPattern.test(string);
}

export function validateNumbersCommasNoSpacesOnly(string) {
  const regExPattern = /^([0-9,]+)$/;
  return regExPattern.test(string);
}




//Validate Data Type
//Pass in a data type and get a validation response (True/False)
export function validateDataType(dataType, value, pattern = null) {
  // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

  let validInput = null;

  if (dataType === 'email') {
    console.log('Case: Email');
    if (validateEmail(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'website') {
    console.log('Case: Website');
    if (validateDomain(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'social') {
    console.log('Case: Social Identity');
    if (validateSocialIdentity(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'postal') {
    console.log('Case: Postal Address Identity');
    if (validatePostalAddressIdentity(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'domain') {
    console.log('Case: Domain');
    if (validateDomain(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'phone') {
    console.log('Case: Phone');
    if (validatePhoneNumbersOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'mobile') {
    console.log('Case: Mobile');
    if (validatePhoneNumbersOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'business-name') {
    console.log('Case: Business Name');
    if (validateAlphaNumericSpacesAndTextCharacters(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'business-brand-words') {
    console.log('Case: Brand Words');
    if (validateAlphaNumericSpacesAndTextCharacters(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'business-brand-image') {
    console.log('Case: Brand Image');
    if (validateAlphaNumericSpacesAndTextCharacters(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'number') {
    console.log('Case: Number');
    console.log(value);
    if (validateNumbersOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'alphaNoSpace') {
    console.log('Case: alphaNoSpace');
    if (validateAlphaNoSpacesOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'alphaSpace') {
    console.log('Case: alphaSpace');
    if (validateAlphaWithSpacesOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'text') {
    console.log('Case: Text');
    if (validateTextOnly(value)) {
      validInput = true;
    } else {
      validInput = false;
    }
  } else if (dataType === 'custom') {
    console.log('Case: Custom');
    if (pattern !== null && pattern !== undefined) {
      let isValidRegEx = true;
      try {
        new RegExp(pattern);
      } catch (e) {
        isValidRegEx = false;
      }
      // If the regex expression is valid, continue
      console.log(`isValidRegEx: ${isValidRegEx}`);
      if (isValidRegEx) {
        const regExPattern = new RegExp(pattern);
        if (regExPattern.test(value)) {
          console.log('Regex: valid pattern');
          validInput = true;
        } else {
          console.log('Regex: invalid pattern');
          validInput = false;
        }
      } else {
        console.log('RegEx expression incorrect');
      }
    }
  }
  return validInput;
}
