import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';
// Components
import { StandardBtn } from 'Components/ShcButtons';
import { SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';


// Actions


class NavAccountHeaderSummary extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const {
      urlmatch, url, backLinkTF, backTo, backTitle, smallTitleTF, smallTitle,
      headlineTitleTF, headlineTitle, sublineTextTF, sublineText,
      sectionTitle, linkGroupSelectionTF, linkGroupSelection,
    } = this.props;

    const BackLinkSection = () => {
      /* This is now located in the open/close drawer */
      if (backLinkTF === 'true') {
        return (
          <SidebarSubNavBackLink to={backTo} title={backTitle} urlmatch={urlmatch} />
        );
      }
      return null;
    };

    const SmallTitleSection = () => {
      if (smallTitleTF === 'true') {
        return (
          <div className={classNames({ sidebarSubNav_headlineSmall: true })}>{smallTitle}</div>
        );
      }
      return null;
    };

    const HeadlineTitleSection = () => {
      if (headlineTitleTF === 'true') {
        return (
          <div className={classNames({ sidebarSubNav_headlineLarge: true, wordWrapBreak: true })}>{headlineTitle}</div>
        );
      }
      return null;
    };

    const SublineTitleSection = () => {
      if (sublineTextTF === 'true') {
        return (
          <div className={classNames({ sidebarSubNav_headlineTiny: true })}>{sublineText}</div>
        );
      }
      return null;
    };

    const SectionTitleSection = () => (
      <div className={classNames({ sidebarSubNav_NavLinkSectionHeader: true })}>{sectionTitle}</div>
    );

    const LinkGroupSection = () => {
      if (linkGroupSelectionTF === 'true') {
        return (
          <SidebarSubNavLinks linkGroupSelection={linkGroupSelection} url={url} urlmatch={urlmatch} />
        );
      }
      return null;
    };


    return (
      <div>
        <SmallTitleSection />
        <HeadlineTitleSection />
        <SublineTitleSection />
        <SectionTitleSection />
        <LinkGroupSection />
      </div>
    );
  }
}


NavAccountHeaderSummary.propTypes = {

};
NavAccountHeaderSummary.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NavAccountHeaderSummary);
