import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { BusinessSubscriptionInvoicesLogLineHeader, BusinessSubscriptionInvoicesLogLineItem } from 'Components/ShcModulesReports';


// Actions
import { listBusinessInvoices } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      apiTimerCollection: [], // List of active timers. (MANDATORY)

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }


  async componentDidMount(props) {
    let data = {
      businessId: this.props.match.params.routebusinessid,
    }
    this.props.listBusinessInvoices({data});
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  render() {
    const { userBusinessRoutePermissions } = this.props;


    function userFilterOptions(object) {
      return object;
    }


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            {this.props.activeSubscription === false ? 
            (
              <CardBasicV2>
                This business does not have an active subscription. Please sign up.
              </CardBasicV2>
            ):(
              <div className={classNames({ aaa: true, aaa: true })}>
                <InputCard headlineText="Invoices" headlineSubText="">
                  <BusinessSubscriptionInvoicesLogLineHeader />
                  <div>

                    {
                    this.props.businessSubscriptionInvoices &&
                    this.props.businessSubscriptionInvoices.length > 0 ?
                    // Arrow function preserves "this"
                    this.props.businessSubscriptionInvoices.filter(userFilterOptions).sort((a, b) => b.period_end - a.period_end).map((item, i) => {
                      
                      let id = '';
                      let object = '';
                      let hosted_invoice_url = '';
                      let invoice_pdf = '';
                      let number = '';
                      let paid = '';
                      let period_start = '';
                      let period_end = '';
                      let status = '';
                      let subscription = '';
                      try {
                        id = item.id;
                      } catch(e) {}
                      try {
                        object = item.object;
                      } catch(e) {}
                      try {
                        hosted_invoice_url = item.hosted_invoice_url;
                      } catch(e) {}
                      try {
                        invoice_pdf = item.invoice_pdf;
                      } catch(e) {}
                      try {
                        number = item.number;
                      } catch(e) {}
                      try {
                        paid = item.paid;
                      } catch(e) {}
                      try {
                        //parse to an int (if this works, its a number)
                        period_start = parseInt(item.period_start);
                        if (period_start.length >= 13) {
                          //time is in milliseconds, convert to seconds.
                          period_start = parseInt(stripUnixMilliseconds(period_start.toString()));
                        }
                      } catch(e) {
                        period_start = item.period_start;
                      }
                      try {
                        //parse to an int (if this works, its a number)
                        period_end = parseInt(item.period_end);
                        if (period_end.length >= 13) {
                          //time is in milliseconds, convert to seconds.
                          period_end = parseInt(stripUnixMilliseconds(period_end.toString()));
                        }
                      } catch(e) {
                        period_end = item.period_end;
                      }
                      try {
                        status = item.status;
                      } catch(e) {}
                      try {
                        subscription = item.subscription;
                      } catch(e) {}

                      

                      return (
                        <BusinessSubscriptionInvoicesLogLineItem
                          id={id}
                          object={object}
                          hosted_invoice_url={hosted_invoice_url}
                          invoice_pdf={invoice_pdf}
                          number={number}
                          paid={paid}
                          period_start={period_start}
                          period_end={period_end}
                          status={status}
                          subscription={subscription}
                        />
                      )
                    }, this)
                    : null
                    }
                  </div>
                  
                </InputCard>

              </div>
            )}




          </div>
        </SidebarSubNav>


      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  let businessSubscriptionInvoices = [];
  try {
    businessSubscriptionInvoices = businessIdentity.subscriptionInvoices;
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  //console.log("businessSubscriptionInvoices");
  //console.log(businessSubscriptionInvoices);


  ///// GET SUBSCRIPTION DETAILS /////
  let businessSubscriptions = [];
  let activeSubscription = false;
  try {
    businessSubscriptions = businessIdentity.subscriptions.filter(sub => sub.active === true);

    if (businessSubscriptions.length > 0) {
      activeSubscription = true;
    }
  } catch (e) {
    //console.error('mapStateToProps | Failed to find subscription');
  }

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessSubscriptionInvoices: Object.assign([], businessSubscriptionInvoices),
    user: state.User,
    activeSubscription, 
  };

};

const mapDispatchToProps = {
  listBusinessInvoices,

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
