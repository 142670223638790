import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';


// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { } from 'Actions';

// Functions
import { firebaseOnAuthStateChange } from 'Helpers/Functions';



class TemplateBusinessPermissionsDenied extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      apiTimerCollection: [], // List of active timers. (MANDATORY)
      apiTimer: 'inactive',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  async componentDidMount(props) {
    this.authFirebaseListener = await firebaseOnAuthStateChange();
  }

  componentDidUpdate(prevProps, prevState) {

  }


  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle=''
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Overview"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <CardBasicV2>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Access Denied</TextStyle>
                </div>
              </div>
              <br/>
              <div className={classNames({ flex: true })}>       
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h4">
                    You have a valid business user account however you do not have the 
                    permissions to view this page. <br/>
                    Please contact your business administrator(s) to request access.<br/>
                  </TextStyle>
                </div>
              </div>
            </CardBasicV2>
            
          </div>
        </SidebarSubNav>

        
      </div>
    );
  }
}

TemplateBusinessPermissionsDenied.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
  };

};

const mapDispatchToProps = {
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TemplateBusinessPermissionsDenied);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(TemplateBusinessPermissionsDenied);
