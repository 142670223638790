import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class InputCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { classes,
      showButtons,
      button1Handler,
      button1ApiActivityId,
      button1Text,
    } = this.props;
    const {
      headlineText, headlineSubText, secondaryText, additionalText, linkText,
      height,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
    };

    const containerHeightBaseSpacing = {
      height: this.props.height ? `calc(${this.props.height} - 50px)` : 'auto',
    };

    var Button = () => {
      return (<div></div>);
    }

    if (showButtons === true) {
      Button = () => {
        return (
          <div>
            <StandardApiBtn
            apiActivityId={button1ApiActivityId}
            icon=""
            iconLocation=""
            text={button1Text}
            tooltip=""
            action=""
            classes="shctext shctext-primary"
            handleClick={button1Handler}
            />
          </div>
        );
      }
    }


    return (
      <div>
        <div className={classNames({ ShcInputContainer: true })}>
          <div className={classNames({ ShcInputContainer_header: true })}>
            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })}>
                {headlineText}
              </div>
              <div className={classNames({ null: true })}>
                <Button/>
              </div>
            </div>
            <div className={classNames({ ShcInputContainer_headerSecondaryText: true })}>
              {headlineSubText}
            </div>
          </div>
          <div className={classNames({ ShcInputContainer_body: true })}>
            
            {this.props.children}

            
          </div>
            <div className={classNames({ ShcInputContainer_footer: true })}>
            
            </div>
        </div>
      </div>

    );
  }
}

InputCard.propTypes = {
  headlineText: PropTypes.string,
  headlineSubText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

InputCard.defaultProps = {
  headlineText: '',
  headlineSubText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputCard);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
