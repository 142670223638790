import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI


// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';

// Actions
import { MapSearchApi } from 'Actions';


class NavigationPropertyCard_v2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }


  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
    } = this.state;


    return (
      <div className={classNames({ pageWrapper: false })}>
        <div className={classNames({ NavigationPropertyCard_container: true })}>
          <div className={classNames({ NavigationPropertyCard_head: true })}>
            12 Birkalla Street, Bulimba
          </div>
          <div className={classNames({ NavigationPropertyCard_body: true })}>
            <div className={classNames({ NavigationPropertyCard_body_sidebar: true })}>
              <div className={classNames({ NavigationPropertyCard_body_sidebar_links: true })}>
                <div className={classNames({ NavigationPropertyCard_body_sidebar_link_item: true })}>
                  <i className="fas fa-th" />
                </div>
                <div className={classNames({ NavigationPropertyCard_body_sidebar_link_item: true })}>
                  <i className="fas fa-envelope" />
                </div>
                <div className={classNames({ NavigationPropertyCard_body_sidebar_link_item: true })}>
                  <i className="fas fa-users" />
                </div>
                <div className={classNames({ NavigationPropertyCard_body_sidebar_link_item: true })}>
                  <i className="fas fa-eye" />
                </div>
                <div className={classNames({ NavigationPropertyCard_body_sidebar_link_item: true })}>
                  <i className="fas fa-map-marker-alt" />
                </div>


              </div>


            </div>
            <div className={classNames({ NavigationPropertyCard_body_main: true })}>

              <div className={classNames({ NavigationPropertyCard_body_main_panel: true })}>
                <div className={classNames({ NavigationPropertyCard_section: true })}>
                  <div className={classNames({ NavigationPropertyCard_innerTitle: true })}>
                    Estimated Value
                  </div>
                  <div className={classNames({ NavigationPropertyCard_innerArrow: true })}>
                    <i className="fas fa-angle-right" />
                  </div>
                </div>
                <div className={classNames({ NavigationPropertyCard_sectionContent: true })}>
                  $23,000,000
                </div>
              </div>

              <div className={classNames({ NavigationPropertyCard_body_main_panel: true })}>
                <div className={classNames({ NavigationPropertyCard_section: true })}>
                  <div className={classNames({ NavigationPropertyCard_innerTitle: true })}>
                    Statistics
                  </div>
                  <div className={classNames({ NavigationPropertyCard_innerArrow: true })}>
                    <i className="fas fa-angle-right" />
                  </div>
                </div>
                <div className={classNames({ NavigationPropertyCard_sectionContent: true })}>


                  <div className={classNames({ NavigationPropertyCard_statCard_container: true })}>
                    <div className={classNames({ NavigationPropertyCard_statCard_section: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerTitle: true })}>
                        Viewed
                      </div>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerArrow: true })}>
                        <i className="fas fa-angle-right" />
                      </div>
                    </div>
                    <div className={classNames({ NavigationPropertyCard_statCard_sectionContent: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_icon: true })}>
                          <i className="fas fa-map-marker-alt" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_text: true })}>
                          3,596
                        </div>
                      </div>

                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_icon: true })}>
                          <i className="fas fa-level-down-alt" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_text: true })}>
                          0.2%
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className={classNames({ NavigationPropertyCard_statCard_container: true })}>
                    <div className={classNames({ NavigationPropertyCard_statCard_section: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerTitle: true })}>
                        Loved
                      </div>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerArrow: true })}>
                        <i className="fas fa-angle-right" />
                      </div>
                    </div>
                    <div className={classNames({ NavigationPropertyCard_statCard_sectionContent: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_icon: true })}>
                          <i className="fas fa-heart" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_text: true })}>
                          200
                        </div>
                      </div>

                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_icon: true })}>
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_text: true })}>
                          18%
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className={classNames({ NavigationPropertyCard_statCard_container: true })}>
                    <div className={classNames({ NavigationPropertyCard_statCard_section: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerTitle: true })}>
                        Monitoring
                      </div>
                      <div className={classNames({ NavigationPropertyCard_statCard_innerArrow: true })}>
                        <i className="fas fa-angle-right" />
                      </div>
                    </div>
                    <div className={classNames({ NavigationPropertyCard_statCard_sectionContent: true })}>
                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_icon: true })}>
                          <i className="fas fa-users" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_left_text: true })}>
                          458
                        </div>
                      </div>

                      <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right: true })}>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_icon: true })}>
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className={classNames({ NavigationPropertyCard_statCard_sectionContent_right_text: true })}>
                          10%
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>

              <div className={classNames({ NavigationPropertyCard_body_main_panel: true })}>
                <div className={classNames({ NavigationPropertyCard_sectionContent: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="CLAIMED"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-success shcbtn-rounded"
                  />
                </div>
              </div>


            </div>


          </div>
        </div>
      </div>

    );
  }
}

NavigationPropertyCard_v2.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

NavigationPropertyCard_v2.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NavigationPropertyCard_v2);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
