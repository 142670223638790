import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';

import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import { } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';





class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user, // The main user object
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;

  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/dashboard"
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/dashboard"
                backTitle="Dashboard"
                smallTitleTF="true"
                smallTitle="User"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              Guard Point Mobile App
            </div>
            <div className={classNames({ text_s16: true })}>
              
            </div>


            <br />


            <div>

              <div className={classNames({flex: true, flexWrap: true, flexJustifyCenter: true})}>

                <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfEnd: true, })}>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">
                    <img className={classNames({ maxWidth200: true })}
                    src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/google-play-store-guardpoint.png"
                    alt="GuardPoint mobile app on Google Play Store"
                    />
                  </a>
                </div>

                <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfStart: true, })}>
                  <a target="_blank" href="https://apps.apple.com/us/app/id1519569737t">
                    <img  className={classNames({ maxWidth200: true })}
                    src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/apple-store-guardpoint.png"
                    alt="GuardPoint mobile app on Apple App Store"
                    />
                  </a>
                </div>

                </div>

            </div>


            <br /> 


            <div>
              <InputCard headlineText="Getting Setup" 
              headlineSubText="Steps to get your Guard Point account and Mobile App setup">
                
                <ul>
                  <li>Install the Guard Point mobile app. &nbsp;
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">Android</a>
                    &nbsp;&nbsp;
                    <a target="_blank" href="https://apps.apple.com/us/app/id1519569737">Apple iOS</a>
                    </li>
                  <li>Open the app and login to your account on the mobile app.</li>
                </ul>
                
              </InputCard>

            </div>

            <div>
              <InputCard headlineText="Confirming Its Working" 
              headlineSubText="The following steps are intended to help confirm your app is working">
                
                <ul>
                  <li>Ensure you have setup at least one mobile identity in your account. &nbsp;
                    <Link to="/account/identities" className={classNames({textDecoration: true,})}>
                    Account Identities
                    </Link>
                  </li>
                  <li>Visit our demo service where you can test your app. &nbsp;
                    <Link to="/account/demo" className={classNames({textDecoration: true,})}>
                    Demo Service
                    </Link>
                  </li>
                  <li>While using the Demo Service, your Guard Point app will receive a demo notification
                    which will confirm your app is working. If you are having issues, please ensure 'Push Notifications'
                    are enabled for the Guard Point mobile app and follow the other troubleshooting steps.
                  </li>
                </ul>
                
              </InputCard>

            </div>

          


          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
  user: state.User,


});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
