import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import HelmetSEO from 'Components/HelmetSEO';
import { SiteHeader, SiteHeaderSubPanel, ShcHeader, ShcSubHeader } from 'Components/ShcHeader';
import { FooterPrimaryLayout, FooterPrimaryLinks, FooterPrimaryContent, FooterBaseSocial } from 'Components/ShcFooter';
import Template from './Template';
import { TemplateBusinessPermissionsDenied } from 'Components/ShcModulesRouting';

// Helpers

// Actions


class AccountManagmentPage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    },
    this.pageData = {
      contentTitle: '',
      displayicon: '',
      breadcrumb: '',
      rightcrumb: '',
    };

    Routes.forEach((obj) => {
      //console.log(this.props.location.pathname);
      if (`/${obj.path}` == this.props.location.pathname) {
        //console.log('Loading this route');
        this.pageData.contentTitle = obj.contentTitle;
        this.pageData.displayicon = obj.displayicon;
        this.pageData.breadcrumb = obj.breadcrumb;
        this.pageData.rightcrumb = obj.rightcrumb;

        //console.log(`Breadcrumb: ${JSON.stringify(this.pageData.breadcrumb)}`);
      }
    });
  }


  render() {

    const { userHasRoutePermission, userBusinessRoutePermissions } = this.props;
    //console.log(`User has route permission: ${userHasRoutePermission}`);

    return (
      <div className={classNames({ pageWrapper: true })}>
        <HelmetSEO url={this.props.location.pathname} />

        <ShcHeader title={this.pageData.contentTitle}>
          {userHasRoutePermission === "true" ?
          (
            <Template {...this.props} title={this.pageData.contentTitle} breadcrumb={this.pageData.breadcrumb} url={this.props.location.pathname} 
            userBusinessRoutePermissions={userBusinessRoutePermissions} />
          ):(
            <TemplateBusinessPermissionsDenied {...this.props} title={this.pageData.contentTitle} breadcrumb={this.pageData.breadcrumb} url={this.props.location.pathname} />
          )}
          </ShcHeader>

      </div>
    );
  }
}

AccountManagmentPage.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AccountManagmentPage);

