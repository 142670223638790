import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


import { HashLink } from 'react-router-hash-link';

// CSS
import componentStyles from './styles.css';

import 'Fonts/mazeletter-path.ttf';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { CardBasicV2  } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { InputText } from 'Components/ShcInput';
import { NavigationCard } from 'Components/ShcCards';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';

// Actions
// import { } from 'Actions';

// Images
import PrimaryBackgroundImage from 'Images/Security/person-holding-a-smartphone-3740314.jpg';

import primaryImage0_330 from 'Images/Home/launch1_0_330.jpg';
import primaryImage330_700 from 'Images/Home/launch1_330_700.jpg';
import primaryImage700_1250 from 'Images/Home/launch1_700_1250.jpg';
import primaryImage1250plus from 'Images/Home/launch1_1250_plus.jpg';

import guardPointLogoLong from 'Images/Logos/logo-long-grey-400x.jpg';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.verifyTokenCodeNow = this.verifyTokenCodeNow.bind(this);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);
    this.state = {
      verifyToken: '',
    };
  }

  verifyTokenCodeNow = () => {
    //console.log("verifyTokenCodeNow");
  }
  
  onChangeInputTextHandler = (evt) => {
    //console.log("input Handler v2");
    //console.log(evt.target.name);
    //FAIL //this.setState({[evt.target.name]: evt.target.value});

  }

  render() {

    const primaryImage = {
      backgroundImage: `url(${PrimaryBackgroundImage})`,
      width: '100%',
      height: 'auto',
    };

    ///////////////////////////////////////////////////////////////////////////////////
    // PRIMARY LANDING - PITCH
    ///////////////////////////////////////////////////////////////////////////////////
    //<img src={PrimaryBackgroundImage} className={classNames({ webImageSettings: true })} />
    const SectionContentPrimary1 = () => (
      <div id="top">
        <div className={classNames({ PublicChallengeSolutionPage100vhContainer: true })}>
          <div className={classNames({ PublicChallengeSolutionPagePrimaryImageContainer: true })}>
            <img src={primaryImage0_330} className={classNames({ primaryImage0_330: true })} />
            <img src={primaryImage330_700} className={classNames({ primaryImage330_700: true })} />
            <img src={primaryImage700_1250} className={classNames({ primaryImage700_1250: true })} />
            <img src={primaryImage1250plus} className={classNames({ primaryImage1250plus: true })} />
          </div>

          <div className={classNames({ PublicChallengeSolutionPagePrimaryContentContainer: true })}>

            <div className={classNames({ PublicChallengeSolutionPagePrimaryContent: false, })}>

              <div className={classNames({primaryHeaderGrid: true, })}>
                <div className={classNames({ primaryHeader_title: true,  })}>

                  
                  <div className={classNames({ bannerTagLine: true,  })}>
                    <span>Everyday</span>
                    <span>challenges</span>
                    <span>solved</span>
                    <span>with</span>
                    <span>guard</span>
                    <span>point</span>
                  </div>


                </div>
                <div className={classNames({ primaryHeader_subTitle: true,  })}>
                  We understand the challenges of protecting your identity.
                  We've designed a process and built the technology to help protect
                  you from scams and identity theft. Read more about the challenge and 
                  our solution below.
                </div>
              </div>

            </div>

          </div>
        </div>
        <div>
          <div className={classNames({shcFooterPurposeCopyrightContent_container:true, flex: true,})}>

            <div className={classNames({positionRelative: true, primaryHeader_appLinkContainer: true, 
              flexGrow: true, width100p: true })}>
              
              <div className={classNames({shcPatternsFontAbsolute: true, shcPatternsPath1: true, shcPatternsThemeDark: true, 
                primaryHeader_appLinkContainer: true, })}>
                aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
                cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
                aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
                cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
                aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
                cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
                aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
                cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
              </div>
              <div className={classNames({shcPatternsTextAbsolute: true, primaryHeader_appLinkContainer: true, 
                flexJustifyCenter: true, flex: true, flexAlignItemsCenter: true})}>
                <div>

                  <div className={classNames({ primaryHeader_appLinks: true,  })}>
                    <div className={classNames({flex: true, flexWrap: true, flexJustifyCenter: true})}>

                      <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfEnd: true, })}>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">
                          <img className={classNames({ mobileAppLinks_inlineMax200: true })}
                          src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/google-play-store-guardpoint.png"
                          alt="GuardPoint mobile app on Google Play Store"
                          />
                        </a>
                      </div>

                      <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfStart: true, })}>
                        <a target="_blank" href="https://apps.apple.com/us/app/id1519569737">
                          <img  className={classNames({ mobileAppLinks_inlineMax200: true })}
                          src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/apple-store-guardpoint.png"
                          alt="GuardPoint mobile app on Apple App Store"
                          />
                        </a>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );







    


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div>
          <SectionContentPrimary1/>
        </div>

        <div className={classNames({ flex: true, flexJustifyCenter: true, pad20: true, })}>
          <img src={guardPointLogoLong} className={classNames({ guardPointLogo_inlineMax300: true })} />
        </div>
        {/* 
        <br/>
        <div className={classNames({shcGrid290: true, })}>

          <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfCenter: true, })}>
            <img className={classNames({ maxWidth400: true, })}
            src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/web-images/Guard+Point+-+How+it+works.png"
            alt=""
            /> 
          </div>
        
        </div>
        <br/>
        */}

        <div id="the-challenge" className={classNames({ contentEdgeToEdgeContainer: true, infoPanel: true, bg_colour_white: false, })}>
          <div className={classNames({ pad20: true })}>
            <div className={classNames({shcGrid290: true, })}>
              
              <div className={classNames({ maxWidth800: true, flexJustifySelfCenter: true })}>
                <div className={classNames({ textAlignCenter: true })}>
                  <div className={classNames({ text_s16: true, colour_primary: true, fontWeightBold: true  })}>
                  GUARD POINT
                  </div>
                  <div className={classNames({ text_s28: true, padB15: true,  })}>
                  the challenge
                  </div>
                  <div className={classNames({ text_s16: true, textAlignLeft: true })}>
                  

                    Around the world consumers of business services all face the same challenges of
                    dealing with scams, spam and identity theft. While businesses have been improving 
                    their security systems to ensure they protect your data that they hold,
                    we haven't seen the same level of increased protection being available for consumers (You)!
                    <br/><br/>

                    Scammers often target consumers with various techniques, with one of them as simple
                    as masquerading as a business to steal your personal information. Almost all the readers
                    would have experienced a business calling you and requesting you to provide personal 
                    information such as your full name, date of birth, home address and so on as a form of 
                    identity verification without knowing who the caller is or how to verify them.
                    <br/><br/>

                    <div className={classNames({ fontWeightBold: true, })}>
                      Ask yourself
                    </div>
                    <ul>
                      <li>How do you identify who you are talking to?</li>
                      <li>How do you verify a caller is from the business they say they are from?</li>
                      <li>Do you ignore calls from unknown numbers?</li>
                      <li>At what point are you suspicious and hang up a call?</li>
                      <li>How do you know when you are being scammed?</li>
                      <li>Would you know what to do if you knew you were scammed?</li>
                      <li>How do you protect your identity and personal information?</li>
                      <li>Who is helping you protect your identity?</li>
                    </ul>

                    We are all faced with the same challenges when it comes to interacting with 
                    another person or machine. Today, the general advice you will read on websites, 
                    from businesses and security experts to consumers is to:

                    <ul>
                      <li>Be aware of consumer scams.</li>
                      <li>Be suspicious of all unknown callers.</li>
                      <li>Don’t trust caller ID.</li>
                      <li>Ask Questions. </li>
                      <li>Call them back. </li>
                      <li>Call them back. </li>
                      <li>Report suspicious phone calls.</li>
                    </ul>

                    While the above advice is reasonable, you are still gambling on the fact that every phone call you 
                    answer is possibly a scam. <br/><br/>

                    <div className={classNames({ fontWeightBold: true, })}>
                      What you really need is to know the verified identity of who is interating with you. <br/><br/>
                    </div>
                    

                    With that in mind, do you think senior citizens and vunlerable people are equipped to protect 
                    their identity?<br/><br/>

                    How do you engage with legitimate businesses when the general advice is not to engage with 
                    anyone over the phone, SMS and email?<br/><br/>

                    The consumer identity verification processes that most businesses have put in place to protect 
                    your personal information is in fact flawed. These processes encourage the consumer (You)
                    to initially share your personal information to a caller in an attempt to verify you. 
                    This process opens up additional attacks on consumers by scammers armed with your personal
                    information to commit fraud. <br/><br/>

                    Guard Point is changing the perspective, looking for the business initiating the interation 
                    with a consumer to first verify themselves before asking for the consumer verification. <br/><br/>

                    Read more about our solution in the below section.<br/><br/>

                    If you would like to read more on a few of the forms of social engineering attacks from wikipedia:
                    <ul>
                      <li>
                        <a className={classNames({ color_primary_dark: true, })} href="https://en.wikipedia.org/wiki/Voice_phishing" target="_blank">
                        Phone call attacks – Voice Phishing (Vishing)
                        </a>
                      </li>
                      <li>
                        <a className={classNames({ color_primary_dark: true, })} href="https://en.wikipedia.org/wiki/Phishing#SMS_phishing" target="_blank">
                        SMS attacks – SMS Phishing (Smishing)
                        </a>
                      </li>
                      <li>
                        <a className={classNames({ color_primary_dark: true, })} href="https://en.wikipedia.org/wiki/Phishing" target="_blank">
                        Email attacks – Phishing
                        </a>
                      </li>
                      <li>
                        <a className={classNames({ color_primary_dark: true, })} href="https://en.wikipedia.org/wiki/Digital_cloning#Voice_cloning" target="_blank">
                        Voice Cloning attacks – Phishing
                        </a>
                      </li>
                    </ul>
  
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>


        <div id="the-solution" className={classNames({ contentEdgeToEdgeContainer: true, infoPanel: true, bg_colour_white: true, })}>
          <div className={classNames({ pad20: true })}>
            <div className={classNames({shcGrid290: true, })}>
              
              <div className={classNames({ maxWidth800: true, flexJustifySelfCenter: true })}>
                <div className={classNames({ textAlignCenter: true })}>
                  <div className={classNames({ text_s16: true, colour_primary: true, fontWeightBold: true  })}>
                  GUARD POINT
                  </div>
                  <div className={classNames({ text_s28: true, padB15: true,  })}>
                  the solution
                  </div>
                  <div className={classNames({ text_s16: true, textAlignLeft: true })}>
                  
                  Guard Point has developed the process and technology to allow you as a consumer to 
                  be notified in advance of a phone call or interaction, provided with verified 
                  contact information of the business and a way to verify the caller 
                  is who they say they are. <br/><br/>

                  The Guard Point solution focuses on the business first verifying themselves before 
                  requesting verification of the consumer (You). This is to limit the amount of 
                  personal information shared to a potential attacker. <br/><br/>

                  While this seems obvious, how many businesses currently do this? <br/>
                  Have you experienced a business verifying themselves first before asking 
                  you to provide your personal information? <br/><br/>

                  Guard Point was born with the primary goal of protecting consumers. This means 
                  we do everything we possibly can to protect you and your data and attempt to provide
                  you with the safest interations with businesses.<br/><br/>

                  In order for the Guard Point solution to work for you, we do need to know and verify 
                  your identities such as your mobile phone number. This is so we are able to 
                  notify you when a business attempts to interact with your identity. <br/><br/>

                  We also request businesses to verify their identities to ensure the business details 
                  that are shared with you during an interaction are correct, up to date and verified. <br/><br/>

                  We would like to reassure you that we take the protection of your data and identity very seriously. 
                  Your personal information is encrypted at rest, encrypted in transit and encrypted in operation.
                  Your data is not shared with anyone for any reasion other than yourself for managing your account.                 


                  </div>
                </div> 
              </div>


            </div>
          </div>
        </div>



        <div id="how-it-works" className={classNames({ contentEdgeToEdgeContainer: true, infoPanel: true, bg_colour_white: false, })}>
          <div className={classNames({ pad20: true })}>
            <div className={classNames({shcGrid290: true, })}>
              
              <div className={classNames({ maxWidth800: true, flexJustifySelfCenter: true })}>
                <div className={classNames({ textAlignCenter: true })}>
                  <div className={classNames({ text_s16: true, colour_primary: true, fontWeightBold: true  })}>
                  GUARD POINT
                  </div>
                  <div className={classNames({ text_s28: true, padB15: true,  })}>
                  how it works
                  </div>
                  <div className={classNames({ text_s16: true, textAlignLeft: true })}>

                    Guard Point - A universal business-interaction verification app. <br/>

                    After you as a consumer has signed up and a participating business on Guard Point.
                  
                    <br/>
                    <div className={classNames({shcGrid290: true, })}>

                      <div className={classNames({ flex: true, maxWidth800: true, flexJustifySelfCenter: true, })}>
                        
                        <img className={classNames({ maxWidth800: true, howItWorksLarge: true, howItWorksImage: true })}
                        src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/web-images/Guard+Point+-+How+it+works.png"
                        alt="GuardPoint - How it works - Description"
                        />

                        <img className={classNames({ maxWidth800: true, howItWorksSmall: true, howItWorksImage: true })}
                        src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/web-images/Guard+Point+-+How+it+works+-+small+v1.png"
                        alt="GuardPoint - How it works - Description"
                        />
                        
                      </div>
                    
                    </div>
                    <br/>


                    


                    As a consumer you will need to review the business verified contact information
                    to determine if you believe the business is legitimate or not and make a decision 
                    as to whether you wish to answer/engage with the caller. If you do encounter a scam, 
                    spam or identity theft you should report this to your local authorities as well as 
                    reporting this to Guard Point within the app. <br/><br/>

                    
                    While our process is not fool proof, it certainly must be one of the best solutions 
                    in market focusing on consumer cyber protection. We are trying to reduce scam, spam 
                    and identity theft and we believe this is a great step forward in achieving that.<br/><br/>


                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>



        <div id="help-us-help-you" className={classNames({ contentEdgeToEdgeContainer: true, infoPanel: true, bg_colour_white: true, })}>
          <div className={classNames({ pad20: true })}>
            <div className={classNames({shcGrid290: true, })}>
              
              <div className={classNames({ maxWidth800: true, flexJustifySelfCenter: true })}>
                <div className={classNames({ textAlignCenter: true })}>
                  <div className={classNames({ text_s16: true, colour_primary: true, fontWeightBold: true  })}>
                  GUARD POINT
                  </div>
                  <div className={classNames({ text_s28: true, padB15: true,  })}>
                  help us help you
                  </div>
                  <div className={classNames({ text_s16: true, textAlignLeft: true })}>
                  
                 
                  We are a young Australian business tackling a global problem (Scams, Spam and Identity Theft). <br/>
                  If you value what we are trying to achieve, we would appreciate your support by requesting 
                  your business or businesses that interact with you to use Guard Point to help protect your identity,
                  my identity and everyone elses identity.<br/>
                  <br/>
                  Thank you for your support<br/>
                  Guard Point team 


                  </div>
                </div> 
              </div>


            </div>
          </div>
        </div>


        

        <div className={classNames({ flex: true, flexJustifyCenter: true, pad20: true, })}>
          <img src={guardPointLogoLong} className={classNames({ guardPointLogo_inlineMax300: true })} />
        </div>
        

        <br/>

        <div className={classNames({shcGrid290: true, })}>
          
          <div className={classNames({ maxWidth800: true, flexJustifySelfCenter: true })}>
            <div className={classNames({ textAlignCenter: true })}>
              
              <div className={classNames({ text_s16: true, textAlignLeft: true })}>
                Get the app, sign up for free and help support a good cause!<br/>
              </div>
            </div> 
          </div>

        </div>

        <div className={classNames({ pad20: true })}>

          <div className={classNames({shcGrid290: true, })}>

            <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfEnd: true, })}>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">
                <img className={classNames({ maxWidth200: true })}
                src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/google-play-store-guardpoint.png"
                alt="GuardPoint mobile app on Google Play Store"
                />
              </a>
            </div>

            <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfStart: true, })}>
              <a target="_blank" href="https://apps.apple.com/us/app/id1519569737">
                <img  className={classNames({ maxWidth200: true })}
                src="https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/app-images/apple-store-guardpoint.png"
                alt="GuardPoint mobile app on Apple App Store"
                />
              </a>
            </div>

          </div>

        </div>



        <br/><br/><br/>

        

        <div className={classNames({positionRelative: true, height100: true, maxHeight100: true,})}>
          <div className={classNames({height100: true, maxHeight100: true,})}>
           
          </div>
          <div className={classNames({shcPatternsPath1: true, shcPatternsAbsolute: true, shcPatternsThemeDark: true,
             height100: true, maxHeight100: true,})}>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
          </div>
        </div>
        
        
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
  identities: state.BusinessIdentities.identities,
});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
