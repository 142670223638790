import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class NamedTextStyle extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // Nice names like small danger, italics bold etc, under title text
  render() {
    const { name, weight, highlight } = this.props;


    const ParagraphText = () => (
      <div className={classNames({
        ShcTextStyles_paragraphText: true,
        color_primary_dark: true,
        ShcTextStyles_highlight: highlight === 'true',
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const SmallNoteText = () => (
      <div className={classNames({
        ShcTextStyles_smallNoteText: true,
        color_primary_dark: true,
        ShcTextStyles_highlight: highlight === 'true',
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TinyNoteText = () => (
      <div className={classNames({
        ShcTextStyles_tinyNoteText: true,
        color_primary_dark: true,
        ShcTextStyles_highlight: highlight === 'true',
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );


    let headingStyle;
    if (name === 'para') {
      headingStyle = <ParagraphText />;
    } else if (name === 'small') {
      headingStyle = <SmallNoteText />;
    } else if (name === 'tiny') {
      headingStyle = <TinyNoteText />;
    }


    return (
      <div>
        {headingStyle}
      </div>
    );
  }
}

NamedTextStyle.propTypes = {

};
NamedTextStyle.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NamedTextStyle);
