import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class IdentityManagementVerificationCodeInputContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   

    this.state = {
      apiTimerCollection: [], // List of active timers.

      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityVerified: false,
      identityNotVerified: true,
      identityReVerify: false,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: false,

      identityLabelInputErrors: false,



      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
    //console.warn('getDerivedStateFromProps | identityManangementPrimaryInputContainerV1 | RECEIVED STATE CHANGE FROM PROPS');
  }

  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const identityInputValue = event.target.value;

    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    this.setState({
      identityInputErrors: errors,
      identityInputValue,
    });




    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);


    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value: identityInputValue,
    };

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  }

 
  




  render() {
    const { 
      identityType, identityLocked, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,

      
      identityInputValue,

       icon, children } = this.props;

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    
  

    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
            ShcIdentityInput_textInput_notificationsEnabled_icon: 
            this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
            ShcIdentityInput_textInput_notificationsDisabled_icon: 
            this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ ShcIdentityInput_textNoInput: true, 
              ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
              ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
              {this.state.identityInputValue}
            </div>
            :
            <input
              {...this.props}
              margin="normal"
              fullWidth
              name={name}
              value={this.state.identityInputValue}
              identifiers={identifiers}
              stateIdentifier={stateIdentifier}
              onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
              type="text"
              className={classNames({ ShcIdentityInput_textInput: true, 
                ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
              })}
            />
            }
            
          </div>
        </div>
          {identityInputValue}



          <div className={classNames({ flex: true })}>

              {!this.state.syncStatus && 
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                    <div>
                      <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                      aria-label="Verified"
                      ></i>
                    </div>
                  </div>
              }

  
              {this.state.identityVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityNotVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityReVerify && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }
              
     
            </div>



      </div>

    );
  }
}




IdentityManagementVerificationCodeInputContainerV1.defaultProps = {
  identityLocked: false,
};


IdentityManagementVerificationCodeInputContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementVerificationCodeInputContainerV1);
