import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";



// CSS
import componentStyles from './styles.css';

// Components
import { VerifiedIdentityInput_user } from 'Components/ShcModulesIdentity';
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';

import { IdentityManagementPanelV2 } from 'Components/ShcModulesIdentityManagement';


// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { 
  resetAllState,
  reduxDataInsert,


  addUserIdentities,
  updateUserIdentities,
  removeUserIdentities,
  
  setUserProfile, toggleOverlayOnScreenResponsive, verifyUserPhoneNumberWithCall, firebaseAuthChange } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
function getRandomPhoneReferenceId() {
  let code = getRandomInt(9).toString()+getRandomInt(9).toString()+" "+getRandomInt(9).toString()+getRandomInt(9).toString();
  return code.toString();
  /*let a = getRandomInt(9);
  let b = getRandomInt(9);
  let c = getRandomInt(9);
  let d = getRandomInt(9);
  return ""+a+b+" "+c+d;*/
}

function arrayPathToJsonObjUpdate(arr, val, operator) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    //console.log(nextParent);
    if (i===0) {
      if (operator==="push") {
        childObj = {
          "$push": [val]
        };
      } else if (operator==="set") {
        childObj = {
          "$set": val
        };
      }
      
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      //console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    
    this.userIdentitiesRequestCreateItem = this.userIdentitiesRequestCreateItem.bind(this);

    this.removeVerifiedIdentityInput = this.removeVerifiedIdentityInput.bind(this);


    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.verifyByPhoneCall = this.verifyByPhoneCall.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.onChangeInputTimer = this.onChangeInputTimer.bind(this);

    this.state = {
      user: this.props.user,    //The main user object
      identities: this.props.identities,    //All the users identities

 
      thisBusinessIdentity: {},                                                //temp?
      businessViewId: this.props.match.params.routebusinessid,  //temp?


      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountUserSettingsIdentitiesPage");
    //console.log(nextProps);
    //console.log(prevState);
    let nextUser = prevState.user;
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      nextUser = nextProps.user;
    } 
    let nextIdentities = prevState.identities;
    if (nextProps.identities !== undefined && nextProps.user !== prevState.identities) {
      nextIdentities = nextProps.identities;
    } 
    return { 
      user: nextUser, 
      identities: nextIdentities,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  removeVerifiedIdentityInput(body = {}) {
    //console.log("Remove Verified Identity Input");
    //console.log("Request to delete this item from the DB and then update/refresh - should be a loading icon while deleting");

    const { removeItem = {} } = body;

    //console.log('removeItem');
    //console.log(JSON.stringify(removeItem));

    let apiUi = { apiActivityId: removeItem.apiActivityId };
    let identity = {
      identityId: removeItem.identityId
    }
    this.props.removeUserIdentities({identity, apiUi});

  }



  userIdentitiesRequestCreateItem() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'userIdentitiesAddItem', data);
  }

  userIdentityAddItemWithParam(type, evt) {
    //console.log("userIdentityAddItemWithParam");
    //console.log(type);
    this.closeOverlayOnScreen("userIdentitiesAddItem");

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    const apiUi = { apiActivityId };
    this.props.addUserIdentities({type: type, apiUi});
  }
















  handleSwitchChange(checked, event, id) {
    //console.log("handleSwitchChange");
    //console.log(checked);
    //console.log(event);
    //console.log(id);

    if (id==="emailNotifications") {
      this.setState({ 
        [id]: checked 
      },() => {
        this.props.setUserProfile(this.props.userId, this.state);
      });
    } else if (id==="pushNotifications") {
      this.setState({ 
        [id]: checked 
      },() => {
        this.props.setUserProfile(this.props.userId, this.state);
      });
    }

  }

  onChangeInputTimer() {
    //On Text Change Event Handling (reducing API impact)
    this.inputTimer = setTimeout(() => { 
      this.setState({     
        apiTimer: "inactive",
      });
      this.props.setUserProfile(this.props.userId,this.state);
     }, 4000);
  }
  onChangeInputTextHandler = (evt) => {
    /*
    Challenge:  Keep redux state updated however do not call the API's for user updates on every key entry.
                This causes a performance impact and a lot of API calls. 
    Solution:   Two redux-actions:
                  1. Update the users profile (with no API calls). This will maintain users local state. 
                      If there was an issue, local state would be maintained, and we could capture the data again at a later stage.
                  2. Update the users profile AND send API call. 
    Notes:      We do not need an activity timer per field as we are updating the whole state on the API call which is the focus for the delay timer.
    */
    if (this.state.apiTimer=="inactive") {
      this.setState({
        apiTimer: "active",               //Set state for the current user action
      });
      this.onChangeInputTimer();          //Call the timer to start.
    } else if (this.state.apiTimer=="active") {
      clearTimeout(this.inputTimer);     //Clear the current Timer
      this.onChangeInputTimer();         //Call the timer to start. (e.g. Reset the timer for another period)
    }

    //Update state as typing is happening
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  verifyByPhoneCall = () => {
    //console.log("verifyByPhoneCall");
    let data = {};
    this.props.verifyUserPhoneNumberWithCall({data});

    

    //Call action to start phone call
  }

  






  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true" 
        backTo="/account/dashboard" 
        backTitle="Dashboard"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true" 
                backTo="/account/dashboard" 
                backTitle="Dashboard"
                smallTitleTF="true" 
                smallTitle="User"
                headlineTitleTF="true" 
                headlineTitle=""
                sublineTextTF="true" 
                sublineText={this.state.user._id}
                sectionTitle="Settings"
                linkGroupSelectionTF="true" 
                linkGroupSelection="accountusersettings"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
  
            <div>
 
              <div className={classNames({ flex: true, maxWidth800: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Your Identities</TextStyle>
                </div>
                <div>
                  <StandardApiBtn
                    apiActivityId="AddNewUserIdentity"
                    icon=""
                    iconLocation=""
                    text="Add Identity"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.userIdentitiesRequestCreateItem}
                  />
                </div>
                
              </div>



              
              <div>
                {
                this.props.identities.length > 0 ?
                // Arrow function preserves "this"
                this.props.identities.map((item, i) => {
                  let outboundCallSourcePhoneNumber = '';
                  try {
                    outboundCallSourcePhoneNumber = this.props.identities[i].outboundCallSourcePhoneNumber;
                  } catch (e) {
                    outboundCallSourcePhoneNumber = '';
                  }
                  if (outboundCallSourcePhoneNumber === undefined) {
                    outboundCallSourcePhoneNumber = '';
                  }

                  let awaitingVerification = false;
                  try {
                    awaitingVerification = this.props.identities[i].awaitingVerification;
                  } catch (e) {
                    awaitingVerification = false;
                  }
                  if (awaitingVerification === undefined) {
                    awaitingVerification = false;
                  }

                  let identityLength = 0;
                  try {
                    identityLength = this.props.businessIdentity.businessIdentities[i].identity.length;
                  } catch (e) {
                    identityLength = 0;
                  }
                  if (identityLength === undefined) {
                    identityLength = 0;
                  }
                  
                  return (
                    <li key={item._id} className={classNames({ listNone: true })}>
                      
                      <IdentityManagementPanelV2
                        componentTargetUser="User"
                        identityLocked={this.props.identities[i].identityLocked}
                        verificationStatus={this.props.identities[i].verificationStatus}

                        communicationTokenArray={this.props.identities[i].communicationTokenArray}
                        outboundCallSourcePhoneNumber={outboundCallSourcePhoneNumber}
                        awaitingVerification={awaitingVerification}

                        identityId={this.props.identities[i]._id}
                        identityInputValue={this.props.identities[i].identity}
                        identityInputLength={identityLength}
                        identityMask={this.props.identities[i].identityMask}
                        identityType={this.props.identities[i].type}
                        identityLabel={this.props.identities[i].identityLabel}
                        notifyThisDevice={this.props.identities[i].notifyThisDevice}
                        dataSet={this.props.identities[i]}

                        key={['IdentityManagementPanelV1', item._id].join('_')}

                        verificationCode=""

                        index={i} 
                        apiEndpoint="User"
                        businessId=""
                        stateIdentifier="User"
                        identifiers={[{_id: this.props.identities[i]._id}]}
                        id="userIdentities.$.identity"
                        name="userIdentities.$.identity"
                        onRemoveItem={this.removeVerifiedIdentityInput}
                        />

                    </li>
                  )
                }): (
                  <CardBasicV2>
                    Setup identities to enable Guard Point notifications when a business 
                    attempts to make contact with you.
                  </CardBasicV2>
                )
                }
                

              </div>
              
              {this.props.userId.toString() === 'OLD_STYLE' && (
                <div>
                  {
                  this.props.identities.length > 0 ?
                    // Arrow function preserves "this"
                  this.props.identities.map((item, i) => (

                    <li key={item._id} className={classNames({ listNone: true })}>
                      
                        <IdentityManagementPanelV1
                          componentTargetUser="User"
                          identityId={this.props.identities[i]._id}
                          identityInputValue={this.props.identities[i].identity}
                          identityInputLength={this.props.identities[i].identity.length}
                          identityMask={this.props.identities[i].identityMask}
                          identityType={this.props.identities[i].type}
                          identityLabel={this.props.identities[i].identityLabel}
                          identityLocked={this.props.identities[i].identityLocked}
                          notifyThisDevice={this.props.identities[i].notifyThisDevice}
                          dataSet={this.props.identities[i]}

                          key={['IdentityManagementPanelV1', item._id].join('_')}

                          verificationCode=""

                          index={i} 
                          apiEndpoint="User"
                          businessId=""
                          stateIdentifier="User"
                          identifiers={[{_id: this.props.identities[i]._id}]}
                          id="userIdentities.$.identity"
                          name="userIdentities.$.identity"
                          onRemoveItem={this.removeVerifiedIdentityInput}
                          />

                    </li>

                    ), this)
                  : null
                  }
                </div>
              )}





            </div>
          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="userIdentitiesAddItem"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add an Identity"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Identities are specific to you and are used when businesses 
              make contact with you.</TextStyle><br/>
              <TextStyle size="h4">Examples include you email address, mobile phone number nad home phone number.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              To create an identity, select an option below:<br/>

              <div className={classNames({ flex: true })}>

                <StandardApiBtn
                  apiActivityId="AddNewUserIdentity"
                  icon=""
                  iconLocation=""
                  text="Email"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.userIdentityAddItemWithParam.bind(this, "email")}
                />
                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Mobile Number"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.userIdentityAddItemWithParam.bind(this, "mobile")}
                />
                <StandardApiBtn
                  apiActivityId="AddNewUserIdentity"
                  icon=""
                  iconLocation=""
                  text="Phone Number"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick={this.userIdentityAddItemWithParam.bind(this, "phone")}
                />
                
              </div>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userIdentitiesAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

       
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let identities = [];
  try {
    console.warn("mapStateToProps | IDENTITIES ARRAY CHANGE");
    identities = state.User.userIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to connect with the userIdentities');
    identities = [];
  }



  return {
    identities: Object.assign([], identities),
    user: state.User,
    userId: state.User.id,
  };

};



const mapDispatchToProps = {
  resetAllState,
  reduxDataInsert,


  addUserIdentities,
  updateUserIdentities,
  removeUserIdentities,



  setUserProfile,
  toggleOverlayOnScreenResponsive,
  verifyUserPhoneNumberWithCall,
  firebaseAuthChange
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);



/*
 <VerifiedIdentityInput_user 
  index={i} 
  apiEndpoint="User"
  businessId=""
  stateIdentifier="User"
  identifiers={[{_id: this.props.identities[i]._id}]}
  id="userIdentities.$.identity"
  name="userIdentities.$.identity"
  data={this.props.identities[i]}
  onRemoveItem={this.removeVerifiedIdentityInput}
  />
*/

/* (CURRENTLY ON HOLD - USERS CAN'T VERIFY POSTAL ($$$))
<StandardApiBtn
  apiActivityId="AddNewUserIdentity"
  icon=""
  iconLocation=""
  text="Postal Address"
  tooltip=""
  action=""
  classes="shcbtn shcbtn-info"
  handleClick={this.userIdentityAddItemWithParam.bind(this, "postal")}
/>
*/