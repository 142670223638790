import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { UsersRolesDisplay, UsersRolesAddNewDisplay, ApiManagementDisplay } from 'Components/ShcAccountModules';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';

import { ApiManagementCard } from 'Components/ShcModulesApiManagement';

// Actions
import { 
  toggleOverlayOnScreenResponsive, 
  createApiKey,
  removeApiKey,
 } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.showModalSelectKeyType = this.showModalSelectKeyType.bind(this);
    this.createKeyConnectTransactionKey = this.createKeyConnectTransactionKey.bind(this);
    this.removeApiKey = this.removeApiKey.bind(this);

    this.state = {
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      apiTimerCollection: [], // List of active timers. (MANDATORY)

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  showModalSelectKeyType() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectNewKeyType', data);
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  
  createKeyConnectTransactionKey(evt) {
    evt.preventDefault();
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    this.closeOverlayOnScreen('selectNewKeyType');

    const business = {
      businessId: this.state.businessId,
    };

    const data = {
      apiKeyType: 'connect-transaction',
    };
    this.props.createApiKey({ business, data, apiUi });
  }

  removeApiKey(dataReceived) {

    console.log("dataReceived");
    console.log(dataReceived);

    const business = {
      businessId: this.state.businessId,
    };

    console.log("dataReceived");
    console.log(dataReceived);

    let apiUi = '';
    try {
      apiUi = dataReceived.apiUi;
    } catch (e) {
      apiUi = '';
    }
    if (apiUi === undefined) {
      apiUi = '';
    }

    let keyId = '';
    try {
      keyId = dataReceived.data.keyId;
    } catch (e) {
      keyId = '';
    }
    if (keyId === undefined) {
      keyId = '';
    }
     

    const data = {
      apiKeyType: 'connect-transaction',
      keyId: keyId,
      businessId: this.state.businessId,
    };
    this.props.removeApiKey({ business, data, apiUi });
  }

  



  render() {
    const { userBusinessRoutePermissions } = this.props;

    //Permission requirements are either Owner or Admin.
    let requiredUserPermissions = ['owner', 'admin'];
    let userHasPermission = false;
    for (let p = 0; p < userBusinessRoutePermissions.length; p++) {
      //console.log(`if ${userBusinessRoutePermissions[p]} user permission is in the array for the route permissions, grant access`);
      if (requiredUserPermissions.includes(userBusinessRoutePermissions[p])) {
        userHasPermission = true;
      }
    }



    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            <div>
        
              {this.props.businessAccountClosed === true ?
              (
                <div>
                  <div className={classNames({ flexGrow: true })}>
                    <TextStyle size="h2">Business Users</TextStyle>
                  </div>
                  <CardBasicV2>
                    This account is currently closed.
                  </CardBasicV2>
                </div>
              ):(
                <div>
                  <div className={classNames({ flex: true, maxWidth800: true,  })}>
                    <div className={classNames({ flexGrow: true })}>
                      <TextStyle size="h2">API Management</TextStyle>
                    </div>
                    <div>
                      {userHasPermission && (
                        <StandardApiBtn
                        apiActivityId="AddNewApiKey"
                        icon=""
                        iconLocation=""
                        text="Add API Key"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-primary"
                        handleClick={this.showModalSelectKeyType}
                        />
                      )}
                      
                    </div>

                  </div>



                  {userHasPermission ? (
                    <div className={classNames({  maxWidth800: true })}>
                      {
                      this.props.apiConnectAccessKeys &&
                      this.props.apiConnectAccessKeys.length > 0 ?
                        // Arrow function preserves "this"
                      this.props.apiConnectAccessKeys.map((item, i) => {

                        return (
                          <li key={item._id} className={classNames({ listNone: true })}>
                            {/* 
                            <BusinessUserProfile
                              key={['BusinessUsersProfile_', this.state.businessId, '_', item.userId].join('_')}
                              userId={item.userId}
                              businessId={this.state.businessId}
                              businessIdentities={this.props.businessIdentities}
                              stateIdentifier="BusinessIdentities"
                              identifiers={[{ _id: this.state.businessId }, { _id: item._id }]}
                              id="identities.businesses.$.businessAdministrators.$"
                              name="identities.businesses.$.businessAdministrators.$"
                              data={item}
                              onRemoveItem={this.removeBusinessUser}
                            />
                            */}
                            <ApiManagementCard
                              key={['ApiKeyManagement_', this.state.businessId, '_', item.userId].join('_')}
                              keyId={item._id}
                              onRemoveItem={this.removeApiKey}
                              businessId={this.state.businessId}
                              stateIdentifier="BusinessIdentities"
                              identifiers={[{ _id: this.state.businessId, _id: item._id }]}
                              id="identities.businesses.$.accessManagement.connectKeys.$"
                              name="identities.businesses.$.accessManagement.connectKeys.$"

                              createdAt={item.createdAt}
                              keyRestrictions={item.keyRestrictions}

                              httpOriginRestrictions={item.httpOriginRestrictions}
                              httpIpRestrictions={item.httpIpRestrictions} 

                              keys={item.keys}
                              keyName={item.keyName}
                              keyDesc={item.keyDesc}
                              data={item}



                              uniqueIdentifier="xndnfsdifnonsdfnipsnpdfnsd"
                              
                              
                              //onRemoveItem={this.removeBusinessUser}
                            />

                          </li>
                        )
                      }, this)
                      : null
                      }
                    </div>
                  ):(
                    <CardBasicV2>
                      You are not authorised to view.
                    </CardBasicV2>
                  )}

                </div>
              )}



            </div>
          </div>
        </SidebarSubNav>



        <OverlayOnScreenResponsive
          id="selectNewKeyType"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Select New Key Type"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>

              <StandardApiBtn
                apiActivityId="UserNotificationAddMobileApp"
                icon=""
                iconLocation=""
                text="Connect Transaction Key"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.createKeyConnectTransactionKey}
              />

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectNewKeyType')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }

  let apiConnectAccessKeys = [];
  let apiMasterAccessKeys = [];
  try {
    apiConnectAccessKeys = businessIdentity.accessManagement.connectKeys;
  } catch (e) {
    apiConnectAccessKeys = [];
  }
  if (apiConnectAccessKeys === undefined) {
    apiConnectAccessKeys = [];
  }
  try {
    apiMasterAccessKeys = businessIdentity.accessManagement.masterKeys;
  } catch (e) {
    apiMasterAccessKeys = [];
  }
  if (apiMasterAccessKeys === undefined) {
    apiMasterAccessKeys = [];
  }


  

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),

    apiConnectAccessKeys: Object.assign([], apiConnectAccessKeys),
    apiMasterAccessKeys: Object.assign([], apiMasterAccessKeys),

    user: state.User,
  };

};

const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,
  createApiKey,
  removeApiKey,

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
