import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import Switch from "react-switch";

import { StandardApiIcon, StandardApiBtn, StandardBtn } from 'Components/ShcButtons';
import { AuthenticatorAppV1 } from 'Components/ShcModulesAuthOptions';

// Actions
import { removeLoginFactor } from 'Actions';



class AuthLoginOptionsCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.handleActivateDeactivate = this.handleActivateDeactivate.bind(this);

    this.handleRemoveLoginFactor = this.handleRemoveLoginFactor.bind(this);
    this.toggleAuthenticatorConfig = this.toggleAuthenticatorConfig.bind(this);
    this.requestAuthenticatorConfigClosure = this.requestAuthenticatorConfigClosure.bind(this);
    
    this.state = {
      authEnabled: this.props.authEnabled,     //Provided by the parent component

      authenticatorConfigVisible: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleActivateDeactivate() {
    console.log(`handleActivateDeactivate: ${this.state.authEnabled}`);
    this.props.activateAuth(this.state.authEnabled);
  }

  async handleRemoveLoginFactor() {
    console.log("handleRemoveLoginFactor");

    await this.props.removeLoginFactor({identityId: this.props.identityId});

  }


  toggleAuthenticatorConfig() {
    this.setState(prevState => ({
      authenticatorConfigVisible: !prevState.authenticatorConfigVisible
    }));
  }

  requestAuthenticatorConfigClosure() {
    this.setState({
      authenticatorConfigVisible: false,
    });
  }
 
  
  
  render() {
    let {
        displayIcon,
        additionalText,
    } = this.props;
    const { 
        identityId, type, factorType, displayText, removable,
        configurable, status,

      children, activeScreen, activateAuth
    } = this.props;


    //Only update if it is a specific type
    if (type === 'mobile') {
      displayIcon = <i class="fas fa-mobile-alt"></i>;
      additionalText = 'We will sms you a One-Time-Passcode';
    } else if (type === 'phone') {
      displayIcon = <i class="fas fa-phone-alt"></i>;
      additionalText = 'We will call you with a One-Time-Passcode';
    } else if (type === 'email') {
      if (factorType === "knowledge") {
        displayIcon = <div><i class="far fa-keyboard"></i></div>;
        additionalText = "You will enter your username and password";
      } else {
        displayIcon = <div><i class="far fa-envelope"></i></div>;
        additionalText = 'We will email you with a One-Time-Passcode';
      }
    } else if (type === 'authenticator') {
      displayIcon = <i class="fas fa-lock"></i>;
      additionalText = 'You will need to enter in your Authenticator App code';
    }

    return (
      <div className={classNames({ AuthLoginOptionsCard_parentContainer: true, 
      flexGrow: true })}>
        <div className={classNames({ AuthLoginOptionsCard_container: true, 
        flex: true, cursorPointer: true, flexGrow: true })}>

          {status === 'active' && (
            <div className={classNames({ AuthLoginOptionsCard_containerStatusActive: true,
            })}>
                <i class="fas fa-check"></i>
            </div>
          )}
          {status === 'setup' && (
            <div className={classNames({ AuthLoginOptionsCard_containerStatusSetup: true,
            })}>
                <i class="fas fa-cogs"></i>
            </div>
          )}
          <div className={classNames({ AuthLoginOptionsCard_containerContent: true, })}>
            <div className={classNames({ flex: true, })}>
              <div className={classNames({ AuthLoginOptionsCard_containerTypeIcon: true, })}>
                <div className={classNames({ text_s16: true, })}>
                  {displayIcon}
                </div>
              </div>
              <div className={classNames({ AuthLoginOptionsCard_containerPrimaryContent: true, })}>
                <div className={classNames({ text_s16: true, })}>
                    {displayText}
                </div>
                <div className={classNames({ text_s12: true, AuthLoginOptionsCard_containerPrimaryContentSecondaryText: true, })}>
                    {additionalText}
                </div>
              </div>
              <div className={classNames({ flex: true })}>

                {type === 'authenticator' && (
                  <div className={classNames({ padR10: true,
                  })}>
                    {status === 'setup' && (
                      <div>
                            
                        {this.state.authenticatorConfigVisible === true ? (
                          <StandardBtn
                          icon="fas fa-angle-up"
                          iconLocation="right"
                          text="Close"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.toggleAuthenticatorConfig}
                          />
                        ):(
                          <StandardBtn
                          icon="fas fa-angle-down"
                          iconLocation="right"
                          text="Configure"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.toggleAuthenticatorConfig}
                          />
                        )}
                      </div>
                    )}
                    
                  </div>
                )}
            

                {removable === true && (
                  <div className={classNames({ AuthLoginOptionsCard_containerContentRemoval: true,
                  })}>
                      <StandardApiIcon
                      apiActivityId={`RemoveLoginFactor_${identityId}`}
                      icon="fas fa-times"
                      iconLocation=""
                      text=""
                      tooltip=""
                      action=""
                      classes=""
                      handleClick={this.handleRemoveLoginFactor}
                      />
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
        {this.state.authenticatorConfigVisible === true && (
          <div className={classNames({ AuthLoginOptionsCard_dropdownContainer: true, })}>
            <AuthenticatorAppV1
            authEnabled={false}
            onRequestClosure={this.requestAuthenticatorConfigClosure}
            />
          </div>
        )}

      </div>

    );
  }
}

AuthLoginOptionsCard.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {
    removeLoginFactor
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthLoginOptionsCard);
