import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Select from 'react-select';


// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2 } from 'Components/ShcCards';

// Actions
import { businessInteractionTokenLookup } from 'Actions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(props) {
  }


  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ pad20: true })}>
          <div >
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
            {this.props.title}
          </div>

          <div>
            <CardBasicV2>
              <div>
                <div className={classNames({ a: true })} />

                <HeadingStyle size="h3">Permission Denied</HeadingStyle>

    

              </div>
            </CardBasicV2>
          </div>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  businessInteractionTokenLookup,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
