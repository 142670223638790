import React from 'react';
import { Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from './BusinessSubscriptionUpdateCreditCardFormV1';
class BusinessRegistrationCheckout extends React.Component {
  render() {
    const { businessId, businessSubscriptionId } = this.props;
    return (
      <Elements>
        <InjectedCheckoutForm
        businessId={businessId}
        businessSubscriptionId={businessSubscriptionId} />
      </Elements>
    );
  }
}

export default BusinessRegistrationCheckout;
