import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class AddressInputFormV1_Australia extends React.Component {
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this);

    this.state = { 
      apiTimerCollection : [],  //List of active timers. (MANDATORY)



      country: this.props.country,
      singleLineAddress: '',
      attention: '',
      companyName: '',
      suiteUnitApt: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      state: '',
      postcode: '',
      

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateData = false;
    let updateObject = {};

    let attention = '';
    try {
      attention = nextProps.dataSet.postageAdditionalData.attention;
    } catch (e) {}
    let companyName = '';
    try {
      companyName = nextProps.dataSet.postageAdditionalData.companyName;
    } catch (e) {}
    let suiteUnitApartment = '';
    try {
      suiteUnitApartment = nextProps.dataSet.postageAdditionalData.suiteUnitApartment;
    } catch (e) {}
    let addressLine1 = '';
    try {
      addressLine1 = nextProps.dataSet.postageAdditionalData.addressLine1;
    } catch (e) {}
    let addressLine2 = '';
    try {
      addressLine2 = nextProps.dataSet.postageAdditionalData.addressLine2;
    } catch (e) {}
    let state = '';
    try {
      state = nextProps.dataSet.postageAdditionalData.state;
    } catch (e) {}
    let suburb = '';
    try {
      suburb = nextProps.dataSet.postageAdditionalData.suburb;
    } catch (e) {}
    let postcode = '';
    try {
      postcode = nextProps.dataSet.postageAdditionalData.postcode;
    } catch (e) {}


    try {
      if (prevState.attention !== attention) {
        updateData = true;
        updateObject['attention'] = attention;
      }
    } catch (e) {}
    try {
      if (prevState.companyName !== companyName) {
        updateData = true;
        updateObject['companyName'] = companyName;
      }
    } catch (e) {}
    try {
      if (prevState.suiteUnitApartment !== suiteUnitApartment) {
        updateData = true;
        updateObject['suiteUnitApartment'] = suiteUnitApartment;
      }
    } catch (e) {}
    try {
      if (prevState.addressLine1 !== addressLine1) {
        updateData = true;
        updateObject['addressLine1'] = addressLine1;
      }
    } catch (e) {}
    try {
      if (prevState.addressLine2 !== addressLine2) {
        updateData = true;
        updateObject['addressLine2'] = addressLine2;
      }
    } catch (e) {}
    try {
      if (prevState.state !== state) {
        updateData = true;
        updateObject['state'] = state;
      }
    } catch (e) {}
    try {
      if (prevState.suburb !== suburb) {
        updateData = true;
        updateObject['suburb'] = suburb;
      }
    } catch (e) {}
    try {
      if (prevState.postcode !== postcode) {
        updateData = true;
        updateObject['postcode'] = postcode;
      }
    } catch (e) {}

    //console.log(updateObject);
    if (updateData === true) {
      return updateObject;
    } else {
      return null;
    }

  }

  
  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    console.log("onChangeDynamicInputTextHandler | AddressInputFormV1_");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */




     /************ CHECK IF ALL THE INPUT IS FILLED TO SEND A LETTER ******************** */

     try {
       if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.attention') {
         this.setState({
          attention: receivedUpdateObj.value,
         });
       }
     } catch (e) {}
     try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.companyName') {
        this.setState({
          companyName: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.suiteUnitApartment') {
        this.setState({
          suiteUnitApt: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine1') {
        this.setState({
          addressLine1: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine2') {
        this.setState({
          addressLine2: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.suburb') {
        this.setState({
          suburb: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.state') {
        this.setState({
          state: receivedUpdateObj.value,
        });
      }
    } catch (e) {}
    try {
      if (receivedUpdateObj.name === 'identities.businesses.$.businessIdentities.$.postageAdditionalData.postcode') {
        this.setState({
          postcode: receivedUpdateObj.value,
        });
      }
    } catch (e) {}

     let inputCountry = this.state.country;
     let inputAttention = this.state.attention;
     let inputCompanyName = this.state.companyName;
     let inputSuiteUnitApt = this.state.suiteUnitApt;
     let inputAddressLine1 = this.state.addressLine1;
     let inputAddressLine2 = this.state.addressLine2;
     let inputSuburb = this.state.suburb;
     let inputState = this.state.state;
     let inputPostcode = this.state.postcode;
 
 
 
     let inputErrors = false;
     try {
       if (inputCountry.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
     try {
       if (inputCompanyName.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
     try {
       if (inputAddressLine1.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
     try {
       if (inputSuburb.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
     try {
       if (inputState.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
     try {
       if (inputPostcode.length > 0) {
         //Good
       } else {
         inputErrors = true;
       }
     } catch (e) {}
 
 
 
     console.log(`inputCountry: ${inputCountry}`);
     console.log(`inputAttention: ${inputAttention}`);
     console.log(`inputCompanyName: ${inputCompanyName}`);
     console.log(`inputSuiteUnitApt: ${inputSuiteUnitApt}`);
     console.log(`inputAddressLine1: ${inputAddressLine1}`);
     console.log(`inputAddressLine2: ${inputAddressLine2}`);
     console.log(`inputSuburb: ${inputSuburb}`);
     console.log(`inputState: ${inputState}`);
     console.log(`inputPostcode: ${inputPostcode}`);
     
     if (inputErrors === false) {
       console.warn('No input errors - update allowed');
       this.props.identityInputValidationStatus(true);
     } else {
       console.warn('Input errors seen');
       this.props.identityInputValidationStatus(false);
 
     }











    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      console.log(timerReferenceGroup);
      console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


   

    


  }
  

 
  render() {

    const { 
      componentTargetUser, countryFormLayout, onChange, identity,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, 
      identityInputValue,
      identityInputValidationStatus,
    } = this.props;

    let attention = '';
    try {
      attention = dataSet.postageAdditionalData.attention;
    } catch (e) {}
    let companyName = '';
    try {
      companyName = dataSet.postageAdditionalData.companyName;
    } catch (e) {}
    let suiteUnitApartment = '';
    try {
      suiteUnitApartment = dataSet.postageAdditionalData.suiteUnitApartment;
    } catch (e) {}
    let addressLine1 = '';
    try {
      addressLine1 = dataSet.postageAdditionalData.addressLine1;
    } catch (e) {}
    let addressLine2 = '';
    try {
      addressLine2 = dataSet.postageAdditionalData.addressLine2;
    } catch (e) {}
    let state = '';
    try {
      state = dataSet.postageAdditionalData.state;
    } catch (e) {}
    let suburb = '';
    try {
      suburb = dataSet.postageAdditionalData.suburb;
    } catch (e) {}
    let postcode = '';
    try {
      postcode = dataSet.postageAdditionalData.postcode;
    } catch (e) {}

    
 
    return (
      <div>

        {componentTargetUser === 'Business' && (
          <div>
            <InputCardItem headlineText="Attention" headlineSubText="" additionalText="This field is only used for the verification letter and not shared with customers.">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.attention" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.attention" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={attention}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <InputCardItem headlineText="Company Name" headlineSubText="" additionalText="">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.companyName" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.companyName" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={companyName}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <InputCardItem headlineText="Suite/Unit/Apartment" headlineSubText="" additionalText="">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.suiteUnitApartment" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.suiteUnitApartment" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={suiteUnitApartment}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>


            <InputCardItem headlineText="Address Line 1" headlineSubText="" additionalText="">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine1" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine1" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={addressLine1}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <InputCardItem headlineText="Address Line 2" headlineSubText="" additionalText="">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine2" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.addressLine2" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={addressLine2}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>


            <InputCardItem headlineText="Suburb" headlineSubText="" additionalText="">
              <InputTextV3 
                id="identities.businesses.$.businessIdentities.$.postageAdditionalData.suburb" 
                name="identities.businesses.$.businessIdentities.$.postageAdditionalData.suburb" 
                identifiers={[{'_id': businessId}, {'_id': identityId}]}
                stateIdentifier="BusinessIdentities"
                label='' 
                value={suburb}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <div className={classNames({ flex: true})}>

              <InputCardItem headlineText="State" headlineSubText="" additionalText=""
              className={classNames({ padR5: true})}>
                <InputTextV3 
                  id="identities.businesses.$.businessIdentities.$.postageAdditionalData.state" 
                  name="identities.businesses.$.businessIdentities.$.postageAdditionalData.state" 
                  identifiers={[{'_id': businessId}, {'_id': identityId}]}
                  stateIdentifier="BusinessIdentities"
                  label='' 
                  value={state}
                  onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                />
              </InputCardItem>

              <InputCardItem headlineText="Postcode" headlineSubText="" additionalText=""
              className={classNames({ padL5: true})}>
                <InputTextV3 
                  id="identities.businesses.$.businessIdentities.$.postageAdditionalData.postcode" 
                  name="identities.businesses.$.businessIdentities.$.postageAdditionalData.postcode" 
                  identifiers={[{'_id': businessId}, {'_id': identityId}]}
                  stateIdentifier="BusinessIdentities"
                  label='' 
                  value={postcode}
                  onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                />
              </InputCardItem>

            </div>
          </div>

        )}

        {componentTargetUser === 'User' && (

          <div>
            <InputCardItem headlineText="Attention" headlineSubText="" additionalText="">
              <InputTextV3 
                id="userIdentities.$.postageAdditionalData.attention" 
                name="userIdentities.$.postageAdditionalData.attention" 
                identifiers={[{'_id': identityId}]}
                stateIdentifier="User"
                label='' 
                value={attention}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <InputCardItem headlineText="Suite/Unit/Apartment" headlineSubText="" additionalText="">
              <InputTextV3 
                id="userIdentities.$.postageAdditionalData.suiteUnitApartment" 
                name="userIdentities.$.postageAdditionalData.suiteUnitApartment" 
                identifiers={[{'_id': identityId}]}
                stateIdentifier="User"
                label='' 
                value={suiteUnitApartment}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>


            <InputCardItem headlineText="Address Line 1" headlineSubText="" additionalText="">
              <InputTextV3 
                id="userIdentities.$.postageAdditionalData.addressLine1" 
                name="userIdentities.$.postageAdditionalData.addressLine1" 
                identifiers={[{'_id': identityId}]}
                stateIdentifier="User"
                label='' 
                value={addressLine1}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <InputCardItem headlineText="Address Line 2" headlineSubText="" additionalText="">
              <InputTextV3 
                id="userIdentities.$.postageAdditionalData.addressLine2" 
                name="userIdentities.$.postageAdditionalData.addressLine2" 
                identifiers={[{'_id': identityId}]}
                stateIdentifier="User"
                label='' 
                value={addressLine2}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>


            <InputCardItem headlineText="Suburb" headlineSubText="" additionalText="">
              <InputTextV3 
                id="userIdentities.$.postageAdditionalData.suburb" 
                name="userIdentities.$.postageAdditionalData.suburb" 
                identifiers={[{'_id': identityId}]}
                stateIdentifier="User"
                label='' 
                value={suburb}
                onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
              />
            </InputCardItem>

            <div className={classNames({ flex: true})}>

              <InputCardItem headlineText="State" headlineSubText="" additionalText=""
              className={classNames({ padR5: true})}>
                <InputTextV3 
                  id="userIdentities.$.postageAdditionalData.state" 
                  name="userIdentities.$.postageAdditionalData.state" 
                  identifiers={[{'_id': identityId}]}
                  stateIdentifier="User"
                  label='' 
                  value={state}
                  onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                />
              </InputCardItem>

              <InputCardItem headlineText="Postcode" headlineSubText="" additionalText=""
              className={classNames({ padL5: true})}>
                <InputTextV3 
                  id="userIdentities.$.postageAdditionalData.postcode" 
                  name="userIdentities.$.postageAdditionalData.postcode" 
                  identifiers={[{'_id': identityId}]}
                  stateIdentifier="User"
                  label='' 
                  value={postcode}
                  onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                />
              </InputCardItem>

            </div>   
          </div>          

        )}

        


      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddressInputFormV1_Australia);
