import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';
import { NotificationManagementExpandedContentContainerV1 } from 'Components/ShcModulesNotificationManagement';


// Actions
import { reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class NotificationManagementPanelV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    //Toggle the notification option for this device
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);

    //On Change from input box
    this.onIdentityInputHandler = this.onIdentityInputHandler.bind(this);
    //Input of Verification Code
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    //Verify Request Button
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    
    this.state = {

      apiTimerCollection: [], // List of active timers.

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,


      identityLabel: this.props.identityLabel,

      identityData: this.props.dataSet,    //All the identity data



      //review
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.
    let updateState = false;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }
    try {
      if (prevState.identityVerified === false && nextProps.verificationStatus === true) {
        collapse=true;
        updateState = true;
      }
    } catch(e) {}


    if (updateState === true) {
      return {
        collapse,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userNotifications.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }


  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }


  handleGeneralInputChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);

    if (validateAlphaNumericWithSpacesOnly(value)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === false) {
      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value,
      };
      //console.log('=== onInputChange Return ===');
      //console.log(returnObj);

      this.updateData(returnObj);
      
    }

    //Advise that there is an error with the input and we are not saving it.
  }


  onIdentityInputHandler(event) {
    //console.log("Received change event from identity input");
  }

  removeItem(identityId, evt) {
    //console.log("removeItem");
    //console.log(identityId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }


  handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      this.props.userIdentitiesVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }





  handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identityData, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity: identityData,
        verificationCode: verificationCode,
      };
      this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }


  
  render() {
    const { deviceName, notificationDeviceUserId, type, identity, identityId, identityLabel, identityInputValue, identityLocked, dataSet, index, apiEndpoint, businessId,
    stateIdentifier, identifiers, id, name, onRemoveItem, challenge } = this.props;

    //Extract the data
    let appGeneratedId="";
    try {
      appGeneratedId = dataSet.appGeneratedId;
    } catch(e) {}
    let deviceIdentityId="";
    try {
      deviceIdentityId = dataSet.identityId;
    } catch(e) {}
    //When we create the device via login, we dont have an id to store. IdentityId is null.
    //this is really used for remote devices (so not an issue for the user owned ones)
    if (deviceIdentityId === '') {
      deviceIdentityId = dataSet._id; 
    }
    
    let appDeviceBrand = '';
    let appDeviceSystemName = '';





    



    let myNotificationDevice = true;
    if (this.props.userId !== notificationDeviceUserId) {
      myNotificationDevice = false;
      //Potentially call ACTION to get the users details
    }

    //Set the Icon
    let identityTypeDisplayIcon = <div><i className={`fas fa-mobile-alt`}></i></div>;

    //Set the headline & subheadline
    let headlineText="";
    let headlineSubText="";
    let headlineSubText2="";
    let headlineAdditionalText="";
    let additionalText="";


    if (type === 'email') {
      headlineText = "MY EMAIL";
      headlineSubText = `Email: ${identity}`;
      headlineSubText2 = ``;
      headlineAdditionalText = ``;
      identityTypeDisplayIcon = <div><i className={`far fa-envelope`}></i></div>;
    } else {
      if (myNotificationDevice === true) {
        headlineText = "MY DEVICE";
        headlineSubText = `Device: ${deviceName}`;
        //additionalText=`Device: ${appDeviceBrand} | ${appDeviceSystemName}`; (No need to repeat)
      } else {
        headlineText = "REMOTE DEVICE";
        headlineSubText = "";
        headlineSubText2 = `User: ${notificationDeviceUserId}`;
        headlineAdditionalText = `${identityLabel}`;
      }
    }


    
    let troubleshootingText="";
    troubleshootingText=`${identityId}`;



    let verificationStatus="";
    return (
      <div className={classNames({ ShcModulesIdentityManagementViewContainerHeader: true, marB30: !this.state.collapse })}>
        
  
        <div className={classNames({ ShcModulesIdentityManagementViewContainerBody: false })}>
          <div className={classNames({ flex: false})}>

            {myNotificationDevice === false &&
            (
              <NotificationManagementExpandedContentContainerV1
              div1="iconSection" div2="headerSection" div3="bodySection">
                <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
                  <div className={classNames({ flexGrow: true })}>
                    {identityTypeDisplayIcon}
                  </div>
                  <div className={classNames({ aaa: true })}>
                    
                  </div>
                </div>
                
                <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                  <div className={classNames({ text_h3: true, padB10: true, })}>REMOTE USER</div>
                  <div className={classNames({ text_h5: true, text_color_grey_light: true, padR10: true, })}> 
                    <StandardApiIcon
                      apiActivityId={`RemoveUserNotification_${identityId}`}
                      icon="fas fa-times"
                      classes=""
                      handleClick={this.removeItem.bind(this, identityId)}
                    />
                  </div>
                </div>
                
                <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, padTB10: true,
                  notificationDevices_notificationsOn: this.state.identityNotificationEnabled === true ? true : false,
                  notificationDevices_notificationsOff: this.state.identityNotificationEnabled === false ? true : false, })}>
                  <div className={classNames({ minWidth18: true, text_h3: true, flexAlignSelfCenter: true, })}>
                      {this.state.identityNotificationEnabled === true ? (
                        <div onClick={this.toggleIdentityNotification}>
                          <i className={`fas fa-bell ${classNames({ colour_primary: true })}`}></i> 
                        </div>
                      ):(
                        <div onClick={this.toggleIdentityNotification}>
                          <i className={`fas fa-bell-slash ${classNames({ colour_lightGrey: true })}`}></i> 
                        </div>
                      )}
                    </div>
                    <div className={classNames({ text_h4: true, padL5: true, padL10: true, flexGrow: true, })}>
                      {this.state.identityNotificationEnabled === true ? (
                        <div className={classNames({ flex: true, })}>
                          <div className={classNames({ flex: true, flexGrow: true, flexAlignSelfCenter: true, })}>
                            Notifications: On
                          </div>
                          <div className={classNames({ fontWeightBold: true,})}>
                            
                            <StandardBtn
                            icon=""
                            iconLocation=""
                            text="Switch Off"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-outline-secondary"
                            handleClick={this.toggleIdentityNotification}
                            />
                          
                          </div>
                        </div>
                      ):(
                        <div className={classNames({ flex: true, })}>
                          <div className={classNames({ flex: true, flexGrow: true, flexAlignSelfCenter: true, })}>
                            Notifications: Off
                          </div>
                          <div className={classNames({ fontWeightBold: true,})}>
                            
                            <StandardBtn
                            icon=""
                            iconLocation=""
                            text="Switch On"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-outline-secondary"
                            handleClick={this.toggleIdentityNotification}
                            />
                          
                          </div>
                        </div>
                      )}
                    </div>
                  </div>     

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                    <div className={classNames({ minWidth18: true, text_h5: true, })}>
                      <i class="far fa-user"></i>
                    </div>
                    <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                      User ID: 
                    </div>
                    <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                      &nbsp;
                      {notificationDeviceUserId}
                    </div>
                  </div>

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                    <div className={classNames({ minWidth18: true, text_h5: true, })}>
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                      Device ID:
                    </div>
                    <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                      &nbsp;
                        {deviceIdentityId}
                    </div>
                  </div>

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                    <div className={classNames({ minWidth18: true, text_h5: true, })}>
                      <i class="fas fa-info-circle"></i>
                    </div>
                    <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
    
                        Business interactions destined for you will alert on this user's 
                        device via a push notification. 
                    
                    </div>
                  </div> 
                  

                </div>
              </NotificationManagementExpandedContentContainerV1>
            )}

            {myNotificationDevice === true &&
            (
              <NotificationManagementExpandedContentContainerV1
              div1="iconSection" div2="headerSection" div3="bodySection">
                <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
                  <div className={classNames({ flexGrow: true })}>
                    {identityTypeDisplayIcon}
                  </div>
                  <div className={classNames({ aaa: true })}>
                    
                  </div>
                </div>
                
                <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                  <div className={classNames({ text_h3: true, padB10: true, })}>MY DEVICE</div>
                  <div className={classNames({ text_h5: true, text_color_grey_light: true, padR10: true, })}> 
                    <StandardApiIcon
                      apiActivityId={`RemoveUserNotification_${identityId}`}
                      icon="fas fa-times"
                      classes=""
                      handleClick={this.removeItem.bind(this, identityId)}
                    />
                  </div>
                </div>
                
                <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, padTB10: true, 
                  notificationDevices_notificationsOn: this.state.identityNotificationEnabled === true ? true : false,
                  notificationDevices_notificationsOff: this.state.identityNotificationEnabled === false ? true : false, })}>
                    <div className={classNames({ minWidth18: true, text_h3: true, flexAlignSelfCenter: true, })}>
                      {this.state.identityNotificationEnabled === true ? (
                        <div onClick={this.toggleIdentityNotification}>
                          <i className={`fas fa-bell ${classNames({ colour_primary: true })}`}></i> 
                        </div>
                      ):(
                        <div onClick={this.toggleIdentityNotification}>
                          <i className={`fas fa-bell-slash ${classNames({ colour_lightGrey: true })}`}></i> 
                        </div>
                      )}
                    </div>
                    <div className={classNames({ text_h4: true, padL5: true, padL10: true, flexGrow: true, })}>
                      {this.state.identityNotificationEnabled === true ? (
                        <div className={classNames({ flex: true, })}>
                          <div className={classNames({ flex: true, flexGrow: true, flexAlignSelfCenter: true, })}>
                            Notifications: On
                          </div>
                          <div className={classNames({ fontWeightBold: true,})}>
                            
                            <StandardBtn
                            icon=""
                            iconLocation=""
                            text="Switch Off"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-outline-secondary"
                            handleClick={this.toggleIdentityNotification}
                            />
                          
                          </div>
                        </div>
                      ):(
                        <div className={classNames({ flex: true, })}>
                          <div className={classNames({ flex: true, flexGrow: true, flexAlignSelfCenter: true, })}>
                            Notifications: Off
                          </div>
                          <div className={classNames({ fontWeightBold: true,})}>
                            
                            <StandardBtn
                            icon=""
                            iconLocation=""
                            text="Switch On"
                            tooltip=""
                            action=""
                            classes="shcbtn shcbtn-outline-secondary"
                            handleClick={this.toggleIdentityNotification}
                            />
                          
                          </div>
                        </div>
                      )}
                    </div>
                  </div>  

                  {type === 'email' && (
                    <div>
                      <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                        <div className={classNames({ minWidth18: true, text_h5: true, })}>
                          <i class="far fa-envelope"></i>
                        </div>
                        <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                          Email:
                        </div>
                        <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                          &nbsp;
                            {`${identity}`}
                        </div>
                      </div>     
                    </div>  
                  )}
                  {type !== 'email' && (
                    <div>
                      <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                        <div className={classNames({ minWidth18: true, text_h5: true, })}>
                          <i class="fas fa-mobile-alt"></i>
                        </div>
                        <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                          Device:
                        </div>
                        <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                          &nbsp;
                            {`${deviceName}`}
                        </div>
                      </div>     

                      <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                        <div className={classNames({ minWidth18: true, text_h5: true, })}>
                          <i class="fas fa-mobile-alt"></i>
                        </div>
                        <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                          Device ID:
                        </div>
                        <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                          &nbsp;
                            {deviceIdentityId}
                        </div>
                      </div>
                    </div>  
                  )}

                  <div id="FlexGroup" className={classNames({ flex: true, padB10: true, })}>
                    <div className={classNames({ minWidth18: true, text_h5: true, })}>
                      <i class="fas fa-info-circle"></i>
                    </div>
                    <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                      {type === 'email' && (
                        <div>
                          Business interactions destined for you will alert you via an email. 
                        </div>  
                      )}
                      {type !== 'email' && (
                        <div>
                          Business interactions destined for you will alert on this user's 
                          device via a push notification. 
                        </div>  
                      )}
                    </div>
                  </div> 
                  

                  



                </div>
              </NotificationManagementExpandedContentContainerV1>

            )}

            
          </div>
        </div>
        
        
      </div>

    );
  }
}

NotificationManagementPanelV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  //console.log("ownProps.identityId");
  //console.log(ownProps.identityId);

  let businessId = '';
  try {
    businessId = ownProps.match.params.routebusinessid;
  } catch (e) {
  }

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `notificationDevicesTabGroup_${ownProps.identityId}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  let userId = '';
  try {
    userId = state.User.id;
  } catch (e) {}

  return {
    userId,
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NotificationManagementPanelV1);
