// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS

// Components
import CollapsableNavListItem from 'Components/CollapsableNavListItems';

// Actions


class ShcPublicSidebarNavLinks extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    //console.log("ShcPublicSidebarNavLinks loading");
  }


  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const RouteSort = Routes.sort((a, b) => a.navPosition - b.navPosition);
    let navDropDownItemsUserData;

    //console.log("ShcPublicSidebarNavLinks");

    const ListItemCustomControl = (props, state) => {
      const {
        path, exact, permission, navTitleCollapsed,
        navTitleExpanded, navTitle, displayicon, navVisible, navVisiblePublic,
        navDropDownItems, navDropDownUserState,
        navPosition,
      } = props.navData;
      //if (!navVisible) return null;   (For Public, we only match on navVisiblePublic)
      if (navVisiblePublic !== true) { return null; }
      //console.log('----------START--------------');
      // console.log(props.adminRoute);
      // console.log(isAdmin);
      // console.log(props.authRoute);
      // console.log(isAuthenticated);
      // console.log('-----');

      // console.log(`${props.adminRoute} && ${!isAdmin}`);
      // console.log(`${props.authRoute} && ${!isAuthenticated}`);

      if (props.adminRoute && !isAdmin) return null;
      if (props.authRoute && !isAuthenticated) return null;

      //Only display public routes for the public page side bar
      //console.log("navVisiblePublic");
      //console.log(navVisiblePublic);
      
      

      if (typeof navDropDownItems !== 'undefined') {
        if (navDropDownItems.length > 0) {
          // console.log('Nav has drop down items');
          return (
            <CollapsableNavListItem hasChildren navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navDropDownItems={navDropDownItems} navDropDownUserState={navDropDownUserState} navPosition={navPosition} />
          );
        }
      }
      return (
        <CollapsableNavListItem to={`/${path}`} hasChildren={false} navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navPosition={navPosition} />

      );
    };
    //Changed key from singleRoute.key to singleRoute.path
    return (
      <div>

        {
          RouteSort.map(singleRoute => (

            <li key={singleRoute.path} data-item={singleRoute.navPosition}>
              {
                <ListItemCustomControl
                  navData={singleRoute}
                  adminRoute={singleRoute.permission.includes('admin')}
                  authRoute={singleRoute.permission.includes('auth')}
                />
              }
            </li>
            )).sort((a, b) => a.navPosition - b.navPosition)
          }
          
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


ShcPublicSidebarNavLinks.propTypes = {

};

export default compose(connect(mapStateToProps))(ShcPublicSidebarNavLinks);
