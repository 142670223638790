import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectStripe, CardElement } from 'react-stripe-elements';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import AddressSection from './AddressSection';
import CheckoutPaymentInformation from './CardSection';

// Material UI
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

// CSS
import componentStyles from './AddressSection.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2, InputTextV3, CheckboxOptionCardReduxV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';


// Actions
import { createBusinessSubscription, createAdhocChange, createPaymentCharge, reduxDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Documents
import BusinessSaaSTermsDocumentUrl from 'Documents/Guard Point Pty Ltd - Saas Terms.pdf';



class BusinessRegistrationCheckoutFormV2 extends React.Component {
  constructor(props) {
    super(props);
    this.handleBusinessPayment = this.handleBusinessPayment.bind(this);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);

    this.state = {
      businessId: this.props.businessId,
      apiTimerCollection: [], // List of active timers. (MANDATORY)

      contactEmail: '',
      businessName: '',
      firstName: '',
      lastName: '',
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_country: '',
      address_state: '',
      address_zip: '',

      firebaseUserId: this.props.user.firebaseUserId,
      userUid: this.props.user.uid,

      //selectedPlan: this.props.selectedPlanId,    //this.props.selectedSubscription.selectedRadioOptionsId,   //Doesn't appear to be working
      selectedPlan: this.props.selectedSubscription.selectedRadioOptionsId,
    };
  }
  // https://stripe.com/docs/recipes/elements-react


  async handleBusinessPayment(evt) {
    // We don't want to let default form submission happen here, which would refresh the page.
    evt.preventDefault();
    console.log('Payment Handler');

    console.log(JSON.stringify(this.state));

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };


    // get the user details in order to create a Stripe Payment token.

    const contactEmail = this.state.contactEmail;
    const businessName = this.state.businessName;
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const address_line1 = this.state.address_line1;
    const address_line2 = this.state.address_line2;
    const address_city = this.state.address_city;
    const address_country = this.state.address_country;
    const address_state = this.state.address_state;
    const address_zip = this.state.address_zip;

    const businessId = this.state.businessId;
    const selectedPlan = this.props.selectedSubscription.selectedRadioOptionsId;
    const firebaseUserId = this.state.firebaseUserId;
    const userUid = this.state.userUid;

    const tokenData = {
      name: `${this.state.firstName} ${this.state.lastName}`,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      address_city: this.state.address_city,
      address_state: this.state.address_country,
      address_zip: this.state.address_state,
      address_country: this.state.address_zip,
      currency: 'aud',

      // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    };

    this.props.stripe.createToken(tokenData)
      .then((result) => {
        console.log('response from createToken');
        console.log(result);

        let errors = false;
        let errorObj = null;
        try {
          if (result.error !== undefined) {
            errorObj = result.error;
          }
        } catch (e) {}
        if (errorObj !== null) {
          errors = true;
        }

        if (errors) {
          console.error('ERRORS | CREATE TOKEN');
          console.error(errorObj);

          toast.error(errorObj.message, {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });

        // code: "incomplete_number"
        // message: "Your card number is incomplete."
        // type: "validation_error"
        /*
        code: "incomplete_cvc"
        message: "Your card's security code is incomplete."
        type: "validation_error"

        code: "incomplete_expiry"
        message: "Your card's expiration date is incomplete."
        type: "validation_error"


        code: "incomplete_number"
        message: "Your card number is incomplete."
        type: "validation_error"


        */
        } else {
          console.warn('NO ERRORS FOUND');
          let token = null;
          try {
            if (result.token !== undefined) {
              token = result.token;
            }
          } catch (e) {}

          if (token === null) {
            console.error('ERROR READING TOKEN');
          } else {
          // PROCESS THIS RECORD
            console.error('PROCESS THIS RECORD');

            
            const timestamp = Date.now();

            

            const subscriptionObj = {
            // Billing Info
              contactEmail,
              businessName,
              firstName,
              lastName,
              address_line1,
              address_line2,
              address_city,
              address_country,
              address_state,
              address_zip,

              // BusinessId
              businessId,

              // Selected Plan
              selectedPlan,

              // Legal & Terms of Use Acceptance

              // Stripe Token
              stripeToken: token,

              // User
              userFirebaseUserId: firebaseUserId,
              userUid,

              // Timestamp
              timestamp: Date.now(),

            };

            console.log(subscriptionObj);

            //Data is used for populating the API call
            const data = {
              businessId,
            }


            // this.props.createAdhocChange({subscriptionObj});

            this.props.createBusinessSubscription({ data, apiUi, subscriptionObj });


          }
        }


      })
      .catch((err) => {
        console.log('ERROR');
        console.log(err);
      });

  }

  onChangeInputTextHandler(receivedUpdateObj) {
    console.log('onChangeInputTextHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /** ************ LOCAL STATE DATA UPDATES ************ */
    // In this version, get the id and update local state only.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      const name = recordsForUpdate[x].name;
      const value = recordsForUpdate[x].value;
      console.log(`${name} : ${value}`);
      this.setState({
        [name]: value,
      });
    }
  }


  render() {
    const { businessId } = this.props;

    const PricingLink = () => (
      //Can't seem to get this to work passing into string below...
      <a target="_blank" href="https://www.guardpoint.com.au/pricing">Guard Point Pricing</a>
    )

    const CheckoutSubscriptionUnderstanding = () => (
      <TextStyle size="h4">
        <CheckboxOptionCardReduxV1
          businessId={this.state.businessId}
          checkboxOptionsId="businessSubscriptionAgreement1"
          checkboxText={`If you choose to register your business you understand that to maintain an active account
          on our platform, that you will incur an administration fee to verify each of your business identities on a regular basis. 
          The costs of verifications and time requirements can be viewed on our pricing page.`}
        />

        <CheckboxOptionCardReduxV1
          businessId={this.state.businessId}
          checkboxOptionsId="businessSubscriptionAgreement2"
          checkboxText="Proceeding to sign up and register your business you are agreeing to the Guard Point SaaS terms of use."
        />

      </TextStyle>
    );


    const CheckoutPayNow = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >


            <StandardApiBtn
              apiActivityId="BusinessSubscriptionPayment"
              icon="far fa-credit-card"
              iconLocation=""
              text="Pay Now"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary shcbtn-fullwidth"
              handleClick={this.handleBusinessPayment}
            />
          </div>
        </div>
      </div>
    );




    return (
      <form onSubmit={this.handleBusinessPayment}>
        <div>
          <TextStyle size="h2">Contact Information</TextStyle>

          <InputCardItem headlineText="Email Address" headlineSubText="We will send this email address the invoice along with any registered billing contact addresses." additionalText="">
            <InputTextV3
              id="contactEmail"
              name="contactEmail"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.contactEmail}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <TextStyle size="h2">Billing Information</TextStyle>

          <InputCardItem headlineText="Business Name" headlineSubText="" additionalText="">
            <InputTextV3
              id="businessName"
              name="businessName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.businessName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="First Name" headlineSubText="Name on Card" additionalText="">
            <InputTextV3
              id="firstName"
              name="firstName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.firstName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Last Name" headlineSubText="Name on Card" additionalText="">
            <InputTextV3
              id="lastName"
              name="lastName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.lastName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Address" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_line1"
              name="address_line1"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_line1}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Apartment, suite, etc" headlineSubText="Optional" additionalText="">
            <InputTextV3
              id="address_line2"
              name="address_line2"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_line2}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="City" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_city"
              name="address_city"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_city}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Country" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_country"
              name="address_country"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_country}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="State" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_state"
              name="address_state"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_state}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Postcode" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_zip"
              name="address_zip"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_zip}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>
          <br />
          <TextStyle size="h2">Terms of Use</TextStyle>

          <TextStyle size="h4">
            By creating a business account you have agreed to the Guard Point SaaS Terms of Use.
            You can read the Guard Point Software-as-a-service terms here: <a href={BusinessSaaSTermsDocumentUrl} target='_blank'>GuardPoint SaaS Terms</a>.
            If you have changed your mind, you should delete this business account immedaitely. 
          </TextStyle>

          <br/><br/>

          <CheckoutSubscriptionUnderstanding />

          <br />
          <TextStyle size="h2">Payment Information</TextStyle>

          <CheckoutPaymentInformation />

          <CheckoutPayNow />


        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  let selectedSubscription = null;
  try {
    selectedSubscription = state.UI.uiRadioOptions.find(radioOption => radioOption.businessId === businessId && radioOption.radioOptionsGroup === 'subscriptionSelection');
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the selected subscription');
  }
  // console.log("mapStateToProps | businessIdentity");
  console.log('selectedSubscription Checkout Form v2');
  console.log(selectedSubscription);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    selectedSubscription: Object.assign({}, selectedSubscription),
    user: state.User,
  };

};

const mapDispatchToProps = {
  createBusinessSubscription,
  createAdhocChange,
  createPaymentCharge,
  reduxDataUpdate,
};


// export default injectStripe(BusinessRegistrationCheckoutFormV2);
export default compose(
  connect(mapStateToProps, mapDispatchToProps), injectStripe,
)(BusinessRegistrationCheckoutFormV2);
