import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class HeadingStyle extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { size, weight } = this.props;


    const HeadingH1 = () => (
      <div className={classNames({
        heading_h1: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const HeadingH2 = () => (
      <div className={classNames({
        heading_h2: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const HeadingH3 = () => (
      <div className={classNames({
        heading_h3: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const HeadingH4 = () => (
      <div className={classNames({
        heading_h4: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const HeadingH5 = () => (
      <div className={classNames({
        heading_h5: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    let headingStyle;
    if (size === 'h1') {
      headingStyle = <HeadingH1 />;
    } else if (size === 'h2') {
      headingStyle = <HeadingH2 />;
    } else if (size === 'h3') {
      headingStyle = <HeadingH3 />;
    } else if (size === 'h4') {
      headingStyle = <HeadingH4 />;
    } else if (size === 'h5') {
      headingStyle = <HeadingH5 />;
    }




    return (
      <div>
        {headingStyle}
      </div>
    );
  }
}

HeadingStyle.propTypes = {

};
HeadingStyle.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(HeadingStyle);
