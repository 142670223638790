import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setUIApiActivity } from 'Actions';


class StandardIcon extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  // uiApiActivity
  static getDerivedStateFromProps(nextProps, prevState) {
    
    return null;
  }


  render() {
    const { classes, handleClick, icon } = this.props;


    const ButtonContent = () => {
      return (
        <div>
          <span className={classNames({ outlineBtnRightIcon: true })}> <i className={icon} /> </span>
        </div>
      );
    };


    return (
      <div className={classNames({ outlineBtnWrapper: true })}>
        <div
          className={`${classes} 
          ${classNames({
          })}`}
          onClick={(evt) => {
            handleClick(evt);
          }}
        >
          <ButtonContent />

        </div>
      </div>
    );
  }
}

StandardIcon.propTypes = {

};

StandardIcon.defaultProps = {
  handleOnMouseLeave: null,
  handleOnMouseOut: null,
  handleOnMouseOver: null,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {

};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(StandardIcon);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(StandardIcon);
