import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');

// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3, InputTextV4, IdentityInputV1, IdentityInputV2, 
  CheckboxOptionCardReduxV1, CheckboxOptionCardV1, ToggleOptionCardV1, 
  ToggleOptionCardReduxV1, RadioOptionCardV1, LocationSearchInputV1 } from 'Components/ShcInput';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

// Actions
import { reduxDataUpdate, apiDataUpdate, updateUserIdentities } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType, validateNumbersCommasNoSpacesOnly  } from 'Helpers/Functions';


class IdentityManagementPrimaryPhoneInputContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);

    this.handleIdentityivrMenuOptionsInput = this.handleIdentityivrMenuOptionsInput.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.

      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

      ivrMenuOptions: this.props.ivrMenuOptions,
      phoneHasIvr: this.props.phoneHasIvr,
      phoneHasQueue: this.props.phoneHasQueue,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    
    

    //console.log("nextProps.dataSavingReference");
    //console.log(nextProps);
    //console.log(nextProps.dataSavingReference);

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
    

    /*
    let savingData = prevState.savingData;
    let apiCompleted = '';
    try {
      apiCompleted = nextProps.dataSavingReference.apiCompleted;
    } catch(e) {
      ////console.log("unable to get apiCompleted in getDerivied");
    }
    */
    /*
    let apiTransactionId = '';
    try {
      apiTransactionId = nextProps.dataSavingReference.apiTransactionId;
    } catch(e) {
      ////console.log("unable to get lastChangeTransactionId in getDerivied");
    }
    */
   /*
    if (prevState.savingData === false && apiCompleted === true) {
      if (prevState.lastChangeTransactionId !== '' && 
      prevState.lastChangeTransactionId === apiTransactionId) {
        ////console.log("SET THE SYNCSTATUS TO TRUE NOW!!!");
        savingData = false;
      } else {
        ////console.log("CAREFUL | this is not actually the id we are watching for");
      }
    }
    */

    //console.log(`SET SAVING DATA to ${savingData}`);
    //console.log(`UPDATE STATE: ${updateState}`);

    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }

  async handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    let identityInputValue = event.target.value;
    let errors = false;
    const lastChangeTransactionId = uuidv1();

    //console.log(identifiers);
    //console.log(stateIdentifier);

    //console.log(this.props.identityType);
    //console.log(identityInputValue);

    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }




    if (errors === true) {
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        savingData: false,
      });
    } else {
      //No errors experienced, save record
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value: identityInputValue,
        lastChangeTransactionId,
      };

      //this.updateData(returnObj);
     

      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////
      this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
      

      //Advise the parent component that the input status changed
      this.props.identityInputValidationStatus(true);

      //this.updateData(returnObj);   //ORIG

      //New code for identity field updates
      //Remove spaces for mobile numbers
      identityInputValue = identityInputValue.replace(/\s/g, "");
      let data = {
        type: this.props.identityType,
        identity: identityInputValue,
        identityId: this.props.identityId,
      }

      let apiResponse = await this.props.updateUserIdentities({data});
      console.log('apiResponse');
      console.log(apiResponse);

      this.setState({
        savingData: false,
      });



    } //end if errors
  }

  
  handleIvrMenuOptionsChange(identifiers, stateIdentifier, event) {
    // This function is to record the ivr menu selection. We need to store this for automated verification in the future.
    const name = event.target.name;
    const ivrMenuOptionsInputValue = event.target.value;
    let errors = false;
    const lastChangeTransactionId = uuidv1();

    //console.log(identifiers);
    //console.log(stateIdentifier);


    if (validateNumbersCommasNoSpacesOnly(ivrMenuOptionsInputValue)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === true) {
      this.setState({
        ivrMenuOptionsInputErrors: errors,
        ivrMenuOptions: ivrMenuOptionsInputValue,
        savingData: false,
      });
    } else {
      //No errors experienced, save record
      this.setState({
        ivrMenuOptionsInputErrors: errors,
        ivrMenuOptions: ivrMenuOptionsInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      
      let returnObj = {};
      if (this.props.componentTargetUser === "User") {
        console.log("USER IDENTITY UPDATE");
        returnObj = {
          name: 'userIdentities.$.phoneIvrMenuSelection',
          identifiers,
          stateIdentifier,
          value: ivrMenuOptionsInputValue,
          lastChangeTransactionId,
        };
      } else if (this.props.componentTargetUser === "Business") {
        console.log("BUSINESS IDENTITY UPDATE");
        returnObj = {
          name: 'identities.businesses.$.businessIdentities.$.phoneIvrMenuSelection',
          identifiers,
          stateIdentifier,
          value: ivrMenuOptionsInputValue,
          lastChangeTransactionId,
        };
      }


      this.updateData(returnObj);
      
      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////
      //this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
      

    } //end if errors
  }

  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  handleLocalCheckboxChange(item, identifiers, stateIdentifier, event) {
    //console.log("handleLocalCheckboxChange");
    //console.log(item);
    //console.log(event);
    const ivrMenuOptionsInputValue = event.checkboxState;
    const lastChangeTransactionId = uuidv1();

    //phoneNumberHasIVR
    //phoneNumberHasQueueAndAgents

    if (item === 'phoneNumberHasIVR') {
      this.setState(prevState => ({
        phoneHasIvr: !prevState.phoneHasIvr
      }),
      () => {
        let returnObj = {};
        if (this.props.componentTargetUser === "User") {
          console.log("USER IDENTITY UPDATE");
          returnObj = {
            name: 'userIdentities.$.phoneHasIVR',
            identifiers,
            stateIdentifier,
            value: ivrMenuOptionsInputValue,
            lastChangeTransactionId,
          };
        } else if (this.props.componentTargetUser === "Business") {
          console.log("BUSINESS IDENTITY UPDATE");
          returnObj = {
            name: 'identities.businesses.$.businessIdentities.$.phoneHasIVR',
            identifiers,
            stateIdentifier,
            value: ivrMenuOptionsInputValue,
            lastChangeTransactionId,
          };
        }

        //console.log(returnObj);
    
        this.updateData(returnObj);
  
      }); //End setState Callback

    } else if (item === 'phoneNumberHasQueueAndAgents') {

      this.setState(prevState => ({
        phoneHasQueue: !prevState.phoneHasQueue
      }),
      () => {
        let returnObj = {};
        if (this.props.componentTargetUser === "User") {
          console.log("USER IDENTITY UPDATE");
          returnObj = {
            name: 'userIdentities.$.phoneHasQueue',
            identifiers,
            stateIdentifier,
            value: ivrMenuOptionsInputValue,
            lastChangeTransactionId,
          };
        } else if (this.props.componentTargetUser === "Business") {
          console.log("BUSINESS IDENTITY UPDATE");
          returnObj = {
            name: 'identities.businesses.$.businessIdentities.$.phoneHasQueue',
            identifiers,
            stateIdentifier,
            value: ivrMenuOptionsInputValue,
            lastChangeTransactionId,
          };
        }
    
        this.updateData(returnObj);
  
      }); //End setState Callback

    }

  }



  handleIdentityivrMenuOptionsInput(event) {
    //console.log("handleIdentityivrMenuOptionsInput");
    let errors = false;
    if (validateNumbersCommasNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    //console.log(`Setting ivr menu selection to: ${event.target.value}`);
    this.setState({
      ivrMenuOptions: event.target.value,
      ivrMenuOptionsInputErrors: errors,
    });
  }



  render() {
    const { 
      componentTargetUser,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,

      identityInputValue,

      icon, children 
    } = this.props;

    let { 
      identityMask,
    } = this.props;

    let businessIdentity = false;
    if (businessId === '' || businessId === undefined) {
      businessIdentity = false;
    } else {
      businessIdentity = true;
    }

    //This is used for transition of existing identities
    if (identityMask === '' || identityMask === undefined || identityMask === null) {
      identityMask = identityInputValue;
    }


    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    
  

    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: identityLocked === true ? false : this.state.identityInputErrors,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ ShcIdentityInput_textNoInput: true, 
              ShcIdentityInput_textInput_errors: false,
              })}>
              {businessIdentity === true ? (
                this.state.identityInputValue
              ):(
                identityMask
              )}
            </div>
            :
            <input
              {...this.props}
              margin="normal"
              fullWidth
              name={name}
              value={this.state.identityInputValue}
              identifiers={identifiers}
              stateIdentifier={stateIdentifier}
              onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
              type="text"
              className={classNames({ ShcIdentityInput_textInput: true, 
                ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                
              })}
            />
            }
            
          </div>
        </div>
          


        <div className={classNames({ flex: true })}>

            {this.state.savingData && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                  <div>
                    <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
            }




            {this.props.verificationStatus && 
              <div className={classNames({ flex: true })}>
                <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                <div>
                  <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                  aria-label="Verified"
                  ></i>
                </div>
              </div>
            }

            {!this.props.verificationStatus && 
              <div className={classNames({ flex: true })}>
                <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                <div>
                  <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                  aria-label="Not Verified"
                  ></i>
                </div>
              </div>
            }






            

            

            {verificationStatus !== true && this.state.identityInputErrors && 
              <div className={classNames({ flex: true })}>
                <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                <div>
                  <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                    aria-label="Invalid Input"
                  ></i>
                </div>
              </div>
            }
            
    
        </div>


        {this.props.componentTargetUser !== "User" && (

          <div className={classNames({ aaaa: true })}>

              <div className={classNames({ flex: true })}>
                <CheckboxOptionCardV1
                  checkboxOptionsId="phoneNumberHasIVR"
                  checkboxText="Is there an IVR that is fronting this phone number?"
                  checkboxState={false}
                  onToggleChange={this.handleLocalCheckboxChange.bind(this, 'phoneNumberHasIVR', identifiers, stateIdentifier)}
                />
              </div>

              <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              </div>

              {this.state.phoneHasIvr && (
                <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                  <div>
                    <InputCardItem
                    id={['ivrMenuInputContainer', identityId].join('_')}
                    key={['ivrMenuInputContainer', identityId].join('_')}

                      headlineText="IVR Menu Selection"
                      headlineSubText="Format: 1,2,1,4"
                      additionalText="Please enter the IVR options for this phone number."
                      troubleshootingText=""
                    >
                      <div className={classNames({ padL15: false })}>

                        <div>
                          <div className={classNames({
                            flex: true,
                            flexJustifySpaceBetween: true,
                            flexAlignBaseline: true,
                            ShcIdentityInput_textInput_root: true,
                          })}
                          >
                            <div className={classNames({
                              ShcIdentityInput_textInput_child: true,
                              ShcIdentityInput_textInput_errors_icon: this.state.ivrMenuOptionsInputErrors,
                            })}
                            >
                              <input
                                key={['ivrMenuInputContainer', identityId].join('_')}

                                id="phoneIvrMenuSelection"
                                name="phoneIvrMenuSelection"
                                
                                margin="normal"
                                fullWidth
                                
                                value={this.state.ivrMenuOptions}
                                identifiers={identifiers}
                                stateIdentifier={stateIdentifier}
                                onChange={this.handleIvrMenuOptionsChange.bind(this, identifiers, stateIdentifier)}
                                type="text"
                                className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.ivrMenuOptionsInputErrors })}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.ivrMenuOptionsInputErrors && 
                          <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                            <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                            <div>
                              <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                                aria-label="Invalid Input"
                              ></i>
                            </div>
                          </div>
                        }

                      </div>
                    </InputCardItem>
                  </div>
                </div>
              )}
              
      
              <div className={classNames({ flex: true })}>
                <CheckboxOptionCardV1
                  checkboxOptionsId="phoneNumberHasQueueAndAgents"
                  checkboxText="Is there a queue and or agents who will answer the call?"
                  checkboxState={false}
                  onToggleChange={this.handleLocalCheckboxChange.bind(this, 'phoneNumberHasQueueAndAgents', identifiers, stateIdentifier)}
                />
              </div>
              
          </div>
        )}

      </div>

    );
  }
}




IdentityManagementPrimaryPhoneInputContainerV1.defaultProps = {
  identityLocked: false,
};


IdentityManagementPrimaryPhoneInputContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 


  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  updateUserIdentities,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementPrimaryPhoneInputContainerV1);
