import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions

// Functions
import {} from 'Helpers/Functions';


class SelectBoxV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      value: this.props.value,
      dbValue: this.props.dbValue, // The value stored in the db
      lastChangeTransactionId: '',
      errors: false,
      // pattern: this.props.pattern,
      // maxlength: this.props.maxlength,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let initValue = '';
    if (this.props.objectArray === true) {
      
      
      try {
        //objectValue - select box is returning objectLabel (need to resolve.)
        initValue = this.props.data[0][this.props.objectValue];
      } catch (e) {}
      console.log(`componentDidMount (objectArray) | initValue: ${initValue}`);
      try {
        this.props.onSelectInit(initValue);
      } catch (e) {}

    } else {
      initValue = this.props.data[0];
      console.log(`componentDidMount (value) | initValue: ${initValue}`);
      try {
        this.props.onSelectInit(initValue);
      } catch (e) {}
    }
    
    
  }

  onSelectChange(evt) {
    console.log("onSelectChange");
    console.log(`evt.target.value: ${evt.target.value}`);

    //this.setState({ fruit: evt.target.value });
    this.props.onSelectInputChange(evt.target.value);
  }

  render() {
    const {
      id, data, objectArray, objectLabel, labelPrefix, objectValue,
      returnAllData, onSelectInputChange,
      width,
    } = this.props;

    

    if (objectArray === true) {
      //console.log('Selectbox | need to analyze object');
    } else {
      //console.log('Selectbox | basic array to show');
    }

    return (
      <div className={classNames({
        flex: true,
        flexJustifySpaceBetween: true,
        flexAlignBaseline: true,
        ShcInput_textInput_root: true,
      })}
      >
        <div className={classNames({
          ShcInput_textInput_child: true,
          ShcInput_textInput_errors_icon: this.state.errors,
        })}
        >
          <select name={id} id={id}
          onChange={this.onSelectChange}
          className={classNames({ width75: width === 'width75', height30: true})}
          >

            {
            objectArray === true &&
            data.map(function(item, i){
              //console.log(i);
              //console.log(item);
              return (
                <option key={i} value={item[objectValue]}>{`${labelPrefix}${item[objectLabel]}`}</option>
              )
            })
            }

            {
            objectArray === false &&
            data.map(function(item, i){
              //console.log(i);
              //console.log(item);
              return (
                <option key={i} value={item}>{item}</option>
              )
            })
            }

          </select>

        </div>
      </div>
    );
  }
}

SelectBoxV1.propTypes = {

};
SelectBoxV1.defaultProps = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SelectBoxV1);

