import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';

import update from 'immutability-helper';

const ct = require('countries-and-timezones');


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, IdentityInputV1, IdentityInputV2, CheckboxOptionCardReduxV1, RadioOptionCardV1, LinkCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';

import { TableQueueLineHeader, TableQueueLineItem_SupportCase } from 'Components/ShcModulesSupportCentre';

import Select from 'react-select'

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { 
  syncSupportQueue,
  apiDataUpdate, reduxDataUpdate,
  deleteBusiness, 
toggleOverlayOnScreenResponsive, addBusinessContactIdentityToAccount } from 'Actions';


// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); //new dynamic

    this.handleSelectInputChange_startTime = this.handleSelectInputChange_startTime.bind(this);
    this.handleSelectInputChange_businessProfile_country = this.handleSelectInputChange_businessProfile_country.bind(this);

    this.createNewSupportCase = this.createNewSupportCase.bind(this);
    

    this.state = { 
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)

      redirect_createCase: false,


      businessName: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountBusinessDashboardPage");
    //console.log("prevState");
    //console.log(prevState);
    //console.log("nextProps");
    //console.log(nextProps);

    let updateState=false;

    if (nextProps.user !== prevState.user) {
      updateState=true;
    }

    if (updateState===true) {
      
      return {
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    const apiActivityId = "SyncSupportQueue";
    const apiUi = { apiActivityId };
    const data = {};
    this.props.syncSupportQueue({ data, apiUi });
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate");
    //console.log("forceUpdate()");
    this.forceUpdate();
   
  }

  createNewSupportCase(evt) {
    console.warn("createNewSupportCase");
    console.log(evt);

    //link to new support case page
    //browserHistory.push('/support/case/create');

    this.setState({redirect_createCase: true});
  }





  //NEW 201907
  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }


  handleSelectInputChange_startTime(evt) {
    //console.log("handleSelectInputChange_startTime");
    //console.log(evt);
    let value = evt.value;

    
    let receivedUpdateObj = {
      name: 'identities.businesses.$.accountOptions.identityNotificationStartTime',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "Feedback",
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }

  handleSelectInputChange_businessProfile_country() {

  }


  render() {
    if (this.state.redirect_createCase === true) {
      return <Redirect push to="/support/case/create" />;
    }

    const { businessId } = this.state;
    const { userBusinessRoutePermissions, businessIdentities, businessAccountPostClosure, businessAccountClosed } = this.props;

    //Get List of All Countries
    const countries = ct.getAllCountries();
    //console.log("======================countries=====================");
    //console.log(countries);
    let countryOptionList = [];
    for (let [key, value] of Object.entries(countries)) {
      countryOptionList.push({ value: `${value.id}`, label: `${value.name}` });
    }

    //Get List of All TimeZones
    const timezones = ct.getAllTimezones();
    //console.log(timezones);
    let timezoneOptionList = [];
    for (let [key, value] of Object.entries(timezones)) {
      timezoneOptionList.push({ value: `${value.name}`, label: `${value.name}` });
    }


    let timeOptions = [];
    for (let t = 0; t <= 24; t++) {
      let hour = `0${t}`.slice(-2);
      timeOptions.push({ value: `${hour}:00`, label: `${hour}:00` });
      if (t !== 24) {
        timeOptions.push({ value: `${hour}:30`, label: `${hour}:30` });
      }
    }



    let feedbackTopicOptions = [
      {value: 'website', label: 'Website'},
      {value: 'mobileApp', label: 'Mobile App'},
      {value: 'service', label: 'Service'},
    ];
    let feedbackCategoryOptions = [
      {value: 'general', label: 'General'},
      {value: 'improvement', label: 'Improvement'},
    ];


    let supportCaseOptions = [
      {value: 'accountBilling', label: 'Account and billing support'},
      {value: 'technicalSupport', label: 'Technical support'},
    ];

    

    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/support"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle=""
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText=""
                sectionTitle="Support Centre"
                linkGroupSelectionTF="true"
                linkGroupSelection="support"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            <div>
              <InputCard headlineText="Open Support Cases" headlineSubText=""
              showButtons={true}
              button1Handler={this.createNewSupportCase}
              button1ApiActivityId="CreateNewSupportCase"
              button1Text="Create Case"
              >
                {this.props.isAuthenticated === true ? (
                  <div>
                    <TableQueueLineHeader />
                      <div>

                        {
                        this.props.supportQueue &&
                        this.props.supportQueue.length > 0 ?
                          // Arrow function preserves "this"
                          this.props.supportQueue.map((item, i) => {
                            let id = '';
                            try {
                              id = item._id;
                            } catch(e) {}
                            let status = '';
                            try {
                              status = item.status;
                            } catch(e) {}
                            let createdAt = '';
                            try {
                              createdAt = item.createdAt;
                            } catch(e) {}



                            let subject = '';
                            try {
                              subject = item.subject;
                            } catch(e) {}
                            let supportGroup = '';
                            try {
                              supportGroup = item.supportGroup;
                            } catch(e) {}
                            let type = '';
                            try {
                              type = item.type;
                            } catch(e) {}

          
                            return (
                              <Link to={`support-centre/${id}`} className={classNames({ noLinkStyle: true })}>
                                <TableQueueLineItem_SupportCase
                                  id={id}

                                  type={supportGroup}
                                  status={status}
                                  createdAt={createdAt}

                                  description={`${type} | ${subject}`}
                                  datetime={createdAt}
                                />
                              </Link>
                            )}, this)
                        :(
                          <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                            <TextStyle size="h4" weight="normal">None.</TextStyle>
                          </div>
                        )}

                      
                      </div>
                  </div>
                ):(
                  <div>
                    Log in to create and track support cases.<br/>
                  </div>
                )}
              </InputCard>
            </div>

            <div>
              <InputCard headlineText="Important Notifications" headlineSubText="">
                
              </InputCard>
            </div>

            <div>
              <InputCard headlineText="Support Tools" headlineSubText="">
                <div class="shcGrid290">

                  <div class="shcGridItem">
                    <Link
                      to="health"
                      className={classNames({textDecoration: true, linkGroup_navigationLinks: true, 
                        linkGroup_navigationLinkHighlight: true })}
                    >
                      <LinkCardV1
                        key="1"
                        headlineText="Health"
                        secondaryText="View your local system health"
                      />
                    </Link>
                  </div>

                  

                </div>
              
              </InputCard>
            </div>


            <br/>

            
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let isAuthenticated = false;
  try {
    isAuthenticated = state.Auth.isAuthenticated;
  } catch (e) {
    isAuthenticated = false;
  }
  if (isAuthenticated === undefined) {
    isAuthenticated = false;
  }

  let selectedSupportCase = null;
  try {
    selectedSupportCase = state.UI.uiRadioOptions.find(radioOption => radioOption.businessId === businessId && radioOption.radioOptionsGroup === 'supportCaseSelection');
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the selected supportCaseSelection');
  }

  //console.log("selectedSupportCase");
  //console.log(selectedSupportCase);


  //Defaults
  if (selectedSupportCase === null || selectedSupportCase === undefined) {
    selectedSupportCase = {
      radioOptionsGroup: '',
      selectedRadioOptionsId: '',
    }
  }


  return {
    isAuthenticated,
    selectedSupportCase,


    user: state.User,  
  };

};


const mapDispatchToProps = {
  syncSupportQueue,
  reduxDataUpdate,
  apiDataUpdate,

  deleteBusiness,


  toggleOverlayOnScreenResponsive,
  addBusinessContactIdentityToAccount,


};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)
