
// Store
import { auth } from 'Store/client';

export async function firebaseOnAuthStateChange(props) {


  let promise_firebaseAuthStateChanged = new Promise((resolve, reject) => {
    
    //console.warn("====== FIREBASE ON AUTH STATE CHANGE | Identities page ======");
    let authFirebaseListener = auth().onAuthStateChanged(async (user) => {
      //This is only triggered on sign-in and sign-out as of verion 4.0.0
      console.warn("FIREBASE | onAuthStateChanged");
      console.log(user);
  
      if (user) {
        //User is signed in

      } else {
        console.warn("Firebse Auth State Change | Reset all state");
        resolve({
          status: false,
          action: 'logoutuser',
          authFirebaseListener: null,
        });
      }
  
      resolve({
        status: true,
        action: '',
        authFirebaseListener: authFirebaseListener,
      });
  
    });

  });
  let results_firebaseAuthStateChanged = await promise_firebaseAuthStateChanged;

  return results_firebaseAuthStateChanged;
}

