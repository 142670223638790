import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Dropdown from 'react-dropdown';



// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { NavigationCard, DashCard1, DashCard2, DashCard3, CardBasicV2, 
  GettingStartedCard,
  DemoCard, 
  InputCard, InputCardItem } from 'Components/ShcCards';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, CheckboxOptionCardReduxV1, InputTextV3 } from 'Components/ShcInput';


import uuid from 'uuid/v1';

// Actions
import { registerDemo, userDemoBusinessRegistersIntent, 
  userDemoBusinessMakesPhoneCall_validSecurityCode,
  userDemoBusinessMakesPhoneCall_invalidSecurityCode } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.handleSimulateBusinessCustomerConnect = this.handleSimulateBusinessCustomerConnect.bind(this);
    this.handleSimulateBusinessCallingCustomer_valid = this.handleSimulateBusinessCallingCustomer_valid.bind(this);
    this.handleSimulateBusinessCallingCustomer_invalid = this.handleSimulateBusinessCallingCustomer_invalid.bind(this);
    
    this.handleSelectInputChange_userIdentity = this.handleSelectInputChange_userIdentity.bind(this);

    

    this.state = {
      user: this.props.user,    //The main user object
      demos: this.props.demos,
      demoId: '',
      demoIdentity: '',

      demoSecurityCode: '',

      demoIdentities: [],
      demoComponent: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } else {
      return null;
    }
  }

  async componentDidMount(props) {
    let demoId = uuid();
    console.log(`Demo Page Ref: ${demoId}`);

    

    //Create the demo data object
    let demoComponent = {
      demoId: demoId,
    }
    this.setState({
      demoId: demoId,
    });

    this.props.registerDemo({demoComponent});


    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }


  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  async handleSimulateBusinessCustomerConnect(evt) {
    //const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let demoComponent = {
      demoId: this.state.demoId,
      identity: this.state.demoIdentity,
    }
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    
    let apiResponse = await this.props.userDemoBusinessRegistersIntent({demoComponent, apiUi});
    console.log("apiResponse");
    console.log(apiResponse);

    let demoSecurityCode = '';
    try {
      demoSecurityCode = apiResponse.data.connectTransaction.transactionData.tokenId;
    } catch (e) {
      demoSecurityCode = '';
    }
    if (demoSecurityCode === undefined) {
      demoSecurityCode = '';
    }
    this.setState({
      demoSecurityCode: demoSecurityCode,
    });

  }

  handleSimulateBusinessCallingCustomer_valid(evt) {
    //const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let demoComponent = {
      demoId: this.state.demoId,
    }
    this.props.userDemoBusinessMakesPhoneCall_validSecurityCode({demoComponent});
  }

  handleSimulateBusinessCallingCustomer_invalid(evt) {
    //const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let demoComponent = {
      demoId: this.state.demoId,
    }
    this.props.userDemoBusinessMakesPhoneCall_invalidSecurityCode({demoComponent});
  }


  
  handleSelectInputChange_userIdentity(evt) {
    let demoIdentity = evt.value;
    console.log("handleSelectInputChange_userIdentity");
    console.log(demoIdentity);
    this.setState({
      demoIdentity: demoIdentity,
    })

  }



  

  render() {
    let demoId = this.state.demoId;


    
    let userIdentities = [];
    /*
    for (let x = 0; x < this.props.demoIdentities.length; x++) {
      userIdentities.push({ value: this.props.demoIdentities.identity, label: this.props.demoIdentities.identity });
    }
    */
    /*
    for (let x = 0; x < this.props.demos.length; x++) {
      if (this.props.demos[x].id === demoId) {
        for (let y = 0; y < this.props.demos[x].length; y++) {
          userIdentities.push({ value: this.props.demos[x].identities[y].identity, label: this.props.demos[x].identities[y].identity });
        }
      }
    }
    */


    console.log('================= MAP ================');
    this.props.demos.map((value, index) => {
      console.warn(`${value.id}`);
      console.warn(`${this.state.demoId}`);

      if (value.id === this.state.demoId) {
        console.warn(`${JSON.stringify(value.identities)}`);
        try {
          if (value.identities.length > 0) {
            for (let x = 0; x < value.identities.length; x++) {
              try {
                userIdentities.push({ value: value.identities[x].identity, label: value.identities[x].identity });
              } catch (e) {
                console.warn(e);
              }
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    })

 
    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
            <div className={classNames({ flex: true })}>
              <div className={classNames({ aaaa: true })}>
                {this.props.title}
              </div>
              <div className={classNames({ text_s12: true, flexAlignSelfCenter: true, padL20: true })}>
                ({this.state.demoId})
              </div>
            </div>
          </div>
          
          <div className={classNames({ flexJustifyFlexStart: true })}>
            <div>
              <InputCard headlineText="Prerequisites" 
              headlineSubText="What you need to do first for a working demonstration.">
                <div className={classNames({ text_s14: true })}>
                  To use this demo, you will need to follow a few prerequisite steps.
                  <br/>
                  <ol>
                    <li>
                      Account Identities - Setup a new 'Mobile Number' identity. This process will 
                      include a verification step where we will verify that you own the phone.
                    </li>
                    <li>
                      Download the Guard Point App on iPhone or Android from the App Stores.
                    </li>
                    <li>
                      Log into the Guard Point App on your mobile with the same username and password 
                      as this account you have created.
                    </li>
                    <li>
                      Account Notifications - Check to see that your mobile has come up in the Account
                      Notifications section. This means your mobile app is linked to this account. 
                    </li>
                  </ol>              

                </div>

              </InputCard>

            </div>


            <div>
              <GettingStartedCard 
              headlineIcon="far fa-id-badge"
              headline1="Step 1 of 5" 
              headline2="Select your verified mobile number" 
              headlineDetail="This demo will simulate a business notifying you using Guard Point
              before they make contact with you. In this example, they will be contacting you via
              the mobile you select.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>
                      We request that you verify your number to reduce the chances of this demonstration
                      service being used for spam.
                      <br/><br/>

                      <div className={classNames({ minWidth110: true })}>
                        <Dropdown 
                        options={userIdentities} 
                        onChange={this.handleSelectInputChange_userIdentity} 
                        value={userIdentities.find(option => option.value === this.state.demoIdentity)}
                        placeholder="Select..." />

                      </div>
                    
                    </div>
                  </div>
                </div>
                <br/>
              </GettingStartedCard>
            </div>


            <div>
              <GettingStartedCard 
              headlineIcon="far fa-building"
              headline1="Step 2 of 5" 
              headline2="Business registers intent with Guard Point" 
              headlineDetail="A business will first register their intention to make contact with you via Guard Point.
              We ensure there contact information is verified and up to date before generating the security 
              codes and notifying you via the Guard Point mobile app.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>

                      Simulate the business registering an interaction with you via Guard Point platform.
                      <br/><br/>

                    
                      <StandardApiBtn
                      apiActivityId="Demo_simulateBusinessRegisteringCustomerConnect"
                      icon=""
                      iconLocation=""
                      text="Simulate Notification"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn shcbtn-primary"
                      handleClick={this.handleSimulateBusinessCustomerConnect}
                      />

                      <br/><br/>
                      {this.state.demoSecurityCode !== '' && (
                        <div className={classNames({ flex: true })}>
                          <div className={classNames({ minWidth70: true, text_s36: true, colour_success: true })}>
                            <i class="far fa-check-circle"></i>
                          </div>
                          <div className={classNames({ flexAlignSelfCenter: true, colour_success: true })}>
                            Interaction Sent - Continue to next step.
                          </div>
                          <br/><br/>
                        </div>
                      )}

                      
                      Having issues? Make sure you have logged in to the Guard Point mobile app and that 
                      your mobile device is visible in your 'Notifications' section.
                    
                    </div>
                  </div>
                  
                </div>
              </GettingStartedCard>
            </div>

            <div>
              <GettingStartedCard 
              headlineIcon="far fa-bell"
              headline1="Step 3 of 5" 
              headline2="View Interaction On Guard Point App" 
              headlineDetail="Open up the Guard Point mobile app to see the demo interaction.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>
                    Check the Guard Point app. <br/><br/>

                    On the 'Interactions' tab, you will see a secret one-time-use security code that 
                    you can use to verify the person making contact with you.<br/><br/>

                    {this.state.demoSecurityCode !== '' && (
                      <div className={classNames({ demoSecurityCodeContainer: true })}>
                        <div className={classNames({ demoSecurityTitle: true })}>
                          Guard Point Verification Code
                        </div>
                        <div className={classNames({ demoSecurityCode: true })}>
                          {this.state.demoSecurityCode}
                        </div>
                      </div>
                    )}
                    <br/>
      
                    Click on the interaction in the app to view more:
                    <ul>
                      <li>The business name.</li>
                      <li>A one-time security code that you can use to verify the caller.</li>
                      <li>All the business verified identities (Phone numbers, websites, email addresses, business registered details, etc)</li>
                      <li>Details about the interaction such as Status, time, who they were contacting.</li>
                      <li>You can see when the identities were last verified on the Guard Point platform.</li>
                    </ul>
                    
                    <br/>
                    Note, while we have verified the business identities, you will need to 
                    decide for yourself if you trust the person making contact with you!

                    </div>
                  </div>
                  
                </div>
              </GettingStartedCard>
            </div>

            <div>
              <GettingStartedCard 
              headlineIcon="fas fa-phone"
              headline1="Step 4 of 5" 
              headline2="Business will make contact with you" 
              headlineDetail="At this point in the process, the business will have received 
              the same one-time security code as you have. They will initiate contact with you
              via their chosen medium (Phone call, email, etc) ">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>
                      IMPORTANT: When the business makes contact with you, it is important that 
                      you DO NOT share your one-time security code!<br/>
                      You should request the business to provide the "Guard Point Security Code" 
                      every time a business makes contact with you. 
                      <br/>
                      If they are unable to provide the security code or the security code is incorrect, 
                      you should stop any further interactions.
                      <br/><br/>

                      To help you get an idea of how this would work, we have provided two scenarios
                      where our system will call your number and demonstrate a successful and unsuccessful 
                      interaction. 
                      <br/><br/>


                      <div className={classNames({ flex: true })}>
                        <StandardApiBtn
                        apiActivityId="Demo_simulateBusinessRegisteringCustomerConnectCorrect"
                        icon=""
                        iconLocation=""
                        text="Simulate Correct Security Code"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn shcbtn-primary"
                        handleClick={this.handleSimulateBusinessCallingCustomer_valid}
                        />
                        
                        <StandardApiBtn
                        apiActivityId="Demo_simulateBusinessRegisteringCustomerConnectIncorrect"
                        icon=""
                        iconLocation=""
                        text="Simulate Incorrect Security Code"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn shcbtn-primary"
                        handleClick={this.handleSimulateBusinessCallingCustomer_invalid}
                        />

                      </div>

                      
                    
                    </div>
                  </div>
                  
                </div>
                
              </GettingStartedCard>
            </div>

            <div>
              <GettingStartedCard 
              headlineIcon="fas fa-mobile-alt"
              headline1="Step 5 of 5" 
              headline2="Verify the business via the Guard Point App" 
              headlineDetail="On receiving a call, and being provided the Guard Point
              security code; Check the Guard Point app to confirm who you are speaking with.">
                
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ minWidth70: true, text_s36: true })}>
                    <i class="far fa-check-circle"></i>
                  </div>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ ddd: true })}>
                      Guard Point Code Matches?
                    </div>
                    <div className={classNames({ text_s14: true })}>
                      <ul>
                        <li>Check that the interaction has not expired. (This is a security risk if expired)</li>
                        <li>Check that the business has verified identites that you are familar with. This 
                          may be a email address, website or postal address. You should also look to make sure the
                          business has a verified Business Name and relevant registed business identifiers.
                        </li>
                      </ul>
                      If these look to appear correct and recognizable, you can proceed with the conversation keeping
                      in mind that you should always be vigilant.
                      <br/><br/>

                      Never give out your personal information to someone you don't know who is asking for it, especailly 
                      over the phone.
                    </div>
                  </div>
                  
                </div>

                <br/><br/>

                <div className={classNames({ flex: true, padT20: true })}>
                  <div className={classNames({ minWidth70: true, text_s36: true })}>
                    <i class="far fa-times-circle"></i>
                  </div>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ ddd: true })}>
                      Guard Point Code Does NOT Match?
                    </div>
                    <div className={classNames({ text_s14: true })}>
                      If the code does not match. You should hang up immediately.
                    </div>
                  
                  </div>
                  
                </div>
                
              </GettingStartedCard>
            </div>

            <br/><br/>

          </div>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  console.log("ownProps");
  console.log(ownProps);


  let demoId = null;
  try {
    demoId = ownProps.demoId;
  } catch (e) {}

  let demos = state.Demo.demo;

  console.log("demos");
  console.log(demos);


  let thisDemo = null;
  try {
    thisDemo = demos.find(demo => demo.id === demoId);
  } catch (e) {
    thisDemo = null;
  }

  let demoIdentities = [];
  if (thisDemo !== null && demoId !== undefined) {
    demoIdentities = thisDemo.identities;
  }

  console.log("demoId");
  console.log(demoId);
  console.log("demoIdentities");
  console.log(demoIdentities);


  return {
    demos: demos,
    demoId: demoId,
    demoIdentities: demoIdentities,

    user: state.User,

  };

};

const mapDispatchToProps = {
  registerDemo,
  userDemoBusinessRegistersIntent,
  userDemoBusinessMakesPhoneCall_validSecurityCode,
  userDemoBusinessMakesPhoneCall_invalidSecurityCode,

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);


/* WHEN WE HAD INTERACTION VIEW ONLINE
<div className={classNames({ flexJustifyFlexStart: true })}>
  <Grid
    container
    spacing={24}
    justify="flex-start"
    className={classNames({ flexGridAutoRows1fr: true })}
  >

    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to="/account/interactions/active" style={{ textDecoration: 'none' }}>
        <DashCard2
          height="100%"
          headlineSubText="Interactions"
          headlineSubIcon="fas fa-volume-up"
          headlineText="View your Interactions"
          secondaryText=""
          additionalText=""
          linkText=""
          linkPath=""
          />
      </Link>
    </Grid>
    
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to="/verify" style={{ textDecoration: 'none' }}>
        <DashCard2
          height="100%"
          headlineSubText="Interactions"
          headlineSubIcon="fas fa-check-double"
          headlineText="Verify an Interaction"
          secondaryText="Verify a business interaction"
          additionalText=""
          linkText=""
          linkPath=""
          />
      </Link>
    </Grid>
    

  </Grid>
</div>
*/