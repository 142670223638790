import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
// import {  } from 'Actions';


class BusinessDashboardCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
    //console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate.');
  }


  render() {
    const { classes, businessId } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
      height,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
    };

    const containerHeightBaseSpacing = {
      height: this.props.height ? `calc(${this.props.height} - 50px)` : 'auto',
    };


    return (
      <div >
        <div className={classNames({ ShcBusinessDashboardCard_dashboardContainer: true })}>
          <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>

            <div className={classNames({ padR10: true })}>
              <i
                className={`far fa-address-card" ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
                aria-label="Verified"
               />
            </div>

            <div className={classNames({ padR10: true })}>
              <i
                className={`fas fa-mask ${classNames({ ShcIdentityInput_notVerifiedIcon: true })}`}
                aria-label="Not Verified"
               />
            </div>

            <div className={classNames({ padR10: true })}>
              <i
                className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
                aria-label="Verification Expiring Soon"
               />
            </div>

            <div className={classNames({ padR10: true })}>
              <i
                className={`fas fa-comments ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
                aria-label="Verified"
               />
            </div>

          </div>
          <div className={classNames({ ShcBusinessDashboardCard_contentSection: true })}>

            <div className={classNames({ ShcBusinessDashboardCard_sectionTitle: true })}>
              {headlineText}
            </div>

            <div className={classNames({ aaaaaa: true })}>
              Messages: 0<br />
              unverified identities: 10<br />
              Notice: Account to be deleted. Learn more.<br />
              Subscription: Active<br />
            </div>

            <div className={classNames({ aaaaaa: true })}>
              Business ID: {businessId}
            </div>

            <div className={classNames({ TESTING: true })}>



              <div className={classNames({ flex: true, abc1: true })}>
                <div className={classNames({ a1: true })}>
                  Profiles
                </div>
                <div className={classNames({ b1: true })}>
                  10
                </div>
                <div className={classNames({ c1: true })}>
                  <i
                    className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
                    aria-label="Verification Expiring Soon"
                />
                </div>
              </div>




            </div>


          </div>

          <div className={classNames({ SchdashboardContainer_footer: true })}>
            <StandardBtn
              icon=""
              iconLocation=""
              text={linkText}
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
              />
          </div>







        </div>
      </div>

    );
  }
}

BusinessDashboardCard.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

BusinessDashboardCard.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessDashboardCard);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
