import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import Switch from "react-switch";


import { StandardApiBtn } from 'Components/ShcButtons';

// Actions
import { addLoginFactor, reinstateLoginFactor } from 'Actions';


class AuthLoginSelectionCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.handleAddLoginFactor = this.handleAddLoginFactor.bind(this);
    this.handleReinstateLoginFactor = this.handleReinstateLoginFactor.bind(this);
    
    this.state = {
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  async handleAddLoginFactor() {
    await this.props.addLoginFactor({identityId: this.props.identityId, type: this.props.type});
    this.props.onSelectLoginFactor(true);
  }

  async handleReinstateLoginFactor() {
    await this.props.reinstateLoginFactor({identityId: this.props.identityId, type: this.props.type});
    this.props.onSelectLoginFactor(true);
    console.log("handleReinstateLoginFactor");
  }

 
  
  
  render() {
    const { 
      key, identityId, type, displayText, additionalText, removable,
      onSelectLoginFactor,
      authenticatorFactorSetup = false,
      authenticatorFactorReInstateOption = false,

      children, activeScreen, activateAuth
    } = this.props;

    let displayIcon = <i class="fas fa-mobile-alt"></i>;
    if (type === 'mobile') {
      displayIcon = <i class="fas fa-mobile-alt"></i>;
    } else if (type === 'phone') {
      displayIcon = <i class="fas fa-phone-alt"></i>;
    } else if (type === 'email') {
      displayIcon = <i class="far fa-envelope"></i>;
    }

    return (
      <div className={classNames({ AuthLoginSelectionCard_container: true, 
      flex: true, cursorPointer: true, flexGrow: true })}>


        <div className={classNames({ AuthLoginSelectionCard_containerContent: true, })}>
          <div className={classNames({ flex: true, })}>
            <div className={classNames({ AuthLoginSelectionCard_containerTypeIcon: true, })}>
              <div className={classNames({ text_s16: true, })}>
                {displayIcon}
              </div>
            </div>
            <div className={classNames({ AuthLoginSelectionCard_containerPrimaryContent: true, })}>
              <div className={classNames({ text_s16: true, })}>
                  {displayText}
              </div>
              <div className={classNames({ text_s12: true, AuthLoginSelectionCard_containerPrimaryContentSecondaryText: true, })}>
                  {additionalText}
              </div>
            </div>
            <div className={classNames({  })}>
            
              {authenticatorFactorSetup === true ? (
                <div>
                  {authenticatorFactorReInstateOption === true ? (
                    <div className={classNames({ text_s12: true })}>
                      <StandardApiBtn
                      apiActivityId={`ReinstateLoginFactor_${identityId}`}
                      icon=""
                      iconLocation=""
                      text="Reinstate"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleReinstateLoginFactor}
                      />
                    </div>
                  ):(
                    <div className={classNames({ text_s12: true })}>Already Selected</div>
                  )}
                </div>
                
              ):(
                <StandardApiBtn
                apiActivityId={`SelectLoginFactor_${identityId}`}
                icon=""
                iconLocation=""
                text="Select"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.handleAddLoginFactor}
                />
              )}

            </div>
          </div>
        </div>
          

      </div>

    );
  }
}

AuthLoginSelectionCard.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {
  addLoginFactor,
  reinstateLoginFactor,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthLoginSelectionCard);
