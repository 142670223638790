import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

const uuidv1 = require('uuid/v1');

var countryTelData = require('country-telephone-data');

import { AsYouType, parsePhoneNumberFromString, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3, SelectBoxV2 } from 'Components/ShcInput';

// Actions

// Functions



class InputPhoneV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    

    this.handleCountryDialingCodeSelection = this.handleCountryDialingCodeSelection.bind(this);
    this.handleChangeV2 = this.handleChangeV2.bind(this);

    this.toggleCountryCodeSelection = this.toggleCountryCodeSelection.bind(this);

  
    this.state = {
      defaultCountry: this.props.defaultCountry ? this.props.defaultCountry : 61,

      countryDialingCodes: [],  
      countryCodeSelection: '',
      countryCodeSelectionBox: false,

      validPhoneNumber: '',
      validPhoneNumberCountry: '',
      validPhoneNumberNumber: '',
      validPhoneNumberType: '',
      validPhoneNumberInternational: '',

      latestNumberInput: {},    //Allows for the replay of the text input on country code change. InputTextV3 Object

      identityInputValue: '',


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

    let countryDialingCodes = [];


    console.log("===== countryTelData.allCountries ======");
    //console.log(countryTelData.allCountries);

    countryDialingCodes = countryTelData.allCountries;

    for (let x = 0; x < countryDialingCodes.length; x++) {
      if (countryDialingCodes[x].name.length >= 26) {
        countryDialingCodes[x].name = `${countryDialingCodes[x].name.substring(0, 26)}..`;
        
      }
    }


    this.setState({
      countryDialingCodes,
      countryCodeSelection: this.state.defaultCountry,
    });

  }


  handleCountryDialingCodeSelection(countryCodeSelection) {
    //console.log(`handleCountryDialingCodeSelection: ${countryCodeSelection}`);
    this.setState({
      countryCodeSelection: countryCodeSelection,
      countryCodeSelectionBox: false,
    }, () => {
      //Replay the latest input changes to see if one selection of
      //a country code the input becomes valid.
      this.handleChangeV2(this.state.latestNumberInput);
    });
  }



  //identifiers, stateIdentifier, event
  async handleChangeV2(receivedUpdateObj) {
    //console.log("handleChangeV2");
    //console.log(receivedUpdateObj);

    let identifiers = receivedUpdateObj.identifiers;
    let stateIdentifier = receivedUpdateObj.stateIdentifier;
    let identityInputValue = receivedUpdateObj.value;
    let name = receivedUpdateObj.name;

    if (identityInputValue === undefined) {
      return false;
    }

    let errors = false;
    let identityInputErrors = receivedUpdateObj.errors;    //new
    if (identityInputErrors === true) {
      errors = true;
    }

    //console.log("identityInputErrors");
    //console.log(identityInputErrors);

    //console.log("errors");
    //console.log(errors);









    //Check if a country code has been selected
    //e.g. +61
    //Use AsYouType to validate the number against the country
    //provide the user some info as to what we think the phone number looks like

    let countryDialCode = this.state.countryCodeSelection;  
    //console.log("========================= selected country code ==================");
    //console.log(countryDialCode);

    let countryDialingCodes = this.state.countryDialingCodes;


    //console.log("========================= this.state.countryDialingCodes; ==================");
    //console.log(this.state.countryDialingCodes);



    let countryCode = '';

    for (let x = 0; x < countryDialingCodes.length; x++) {
      if (countryDialingCodes[x].dialCode.toString() === countryDialCode.toString()) {
        //console.log('matched on dialling code, get country code');
        countryCode = countryDialingCodes[x].iso2;
      }
    }

    //console.log("========================= selected countryCode ==================");
    //console.log(countryCode);
    //console.log(countryCode.toUpperCase());
    

    let phoneNumber = '';

    if (countryCode === '') {
      //console.log("========================= parsePhoneNumberFromString ==================");
      phoneNumber = parsePhoneNumberFromString(identityInputValue);
      //console.log(`phoneNumber: ${JSON.stringify(phoneNumber)}`);

      let countryCallingCodeLib = '';
      try {
        countryCallingCodeLib = phoneNumber.countryCallingCode;
      } catch (e) {

      }

      //console.log(`countryCallingCodeLib: ${countryCallingCodeLib}`);



    } else {
      //console.log("========================= parsePhoneNumberFromString with Country Code ==================");
      phoneNumber = parsePhoneNumberFromString(identityInputValue, countryCode.toUpperCase());
      //console.log(`phoneNumber: ${JSON.stringify(phoneNumber)}`);
    }

    let expectedInternationalNumber = '';
    if (phoneNumber) {
      try {
        expectedInternationalNumber = phoneNumber.number;
      } catch (e) {
        expectedInternationalNumber = '';
      }
      if (expectedInternationalNumber === undefined) {
        expectedInternationalNumber = '';
      }
    }
    
    console.log(`GP | expectedInternationalNumber: ${expectedInternationalNumber}`);
    

    let possiblePhoneNumberError = '';
    if (expectedInternationalNumber === '') {
      //try and get the possible error message
      if (countryCode === '') {
        try {
          //phoneNumber = parsePhoneNumberWithError(identityInputValue, 'US')
          phoneNumber = parsePhoneNumberWithError(identityInputValue);
        } catch (error) {
            // Not a phone number, non-existent country, etc.
            //console.log('consider showing this to the user');
            if (error instanceof ParseError) {
              // Not a phone number, non-existent country, etc.
              //console.log(error.message);
              possiblePhoneNumberError = error.message;
            } else {
              throw error;
            }
        }
      } else {
        try {
          //phoneNumber = parsePhoneNumberWithError(identityInputValue, 'US')
          phoneNumber = parsePhoneNumberWithError(identityInputValue, countryCode.toUpperCase());
        } catch (error) {
            // Not a phone number, non-existent country, etc.
            //console.log('consider showing this to the user');
            if (error instanceof ParseError) {
              // Not a phone number, non-existent country, etc.
              console.log(error.message);
              possiblePhoneNumberError = error.message;
            } else {
              throw error;
            }
        }
      }

    }


    //console.log("========================= possiblePhoneNumberError ==================");
    //console.log(possiblePhoneNumberError);
    //console.log("========================= FINAL expectedInternationalNumber ==================");
    //console.log(expectedInternationalNumber);


    let validPhoneNumber = false;
    let validPhoneNumberCountry = '';
    let validPhoneNumberNumber = '';
    let validPhoneNumberType = '';
    let validPhoneNumberInternational = '';
    try {
      if (phoneNumber.isValid()) {
        validPhoneNumber = true;
        //console.log('VALIDATION STATUS: phone number appears to be valid');
        validPhoneNumberCountry = phoneNumber.country;
        validPhoneNumberNumber = phoneNumber.number;
        validPhoneNumberType = phoneNumber.getType();
        validPhoneNumberInternational = phoneNumber.formatInternational();
      } else {
        //console.log('VALIDATION STATUS: phone number doesnt appear to be valid');
      }
    } catch (e) {
      //console.log('VALIDATION STATUS: ERROR | phone number doesnt appear to be valid');
    }

    console.log(`GP | validPhoneNumber: ${validPhoneNumber}`);
    console.log(`GP | validPhoneNumberCountry: ${validPhoneNumberCountry}`);
    console.log(`GP | validPhoneNumberNumber: ${validPhoneNumberNumber}`);
    console.log(`GP | validPhoneNumberType: ${validPhoneNumberType}`);
    console.log(`GP | validPhoneNumberInternational: ${validPhoneNumberInternational}`);

    //OVERRIDE THE INPUT VALUE FOR THE PHONE NUMBERS
    identityInputValue = expectedInternationalNumber;

    if (validPhoneNumber === false) {
      this.setState({
        identityInputErrors: true,
        savingData: false,

        validPhoneNumber: validPhoneNumber,
        validPhoneNumberCountry: validPhoneNumberCountry,
        validPhoneNumberNumber: validPhoneNumberNumber,
        validPhoneNumberType: validPhoneNumberType,
        validPhoneNumberInternational: validPhoneNumberInternational,

        latestNumberInput: receivedUpdateObj,
      });
      this.props.validPhoneNumberInput(false);
      errors = true;
    } else {
      this.setState({
        identityInputErrors: false,
        savingData: true,

        validPhoneNumber: validPhoneNumber,
        validPhoneNumberCountry: validPhoneNumberCountry,
        validPhoneNumberNumber: validPhoneNumberNumber,
        validPhoneNumberType: validPhoneNumberType,
        validPhoneNumberInternational: validPhoneNumberInternational,

        latestNumberInput: receivedUpdateObj,
      });
      this.props.validPhoneNumberInput(true);
    }

    const lastChangeTransactionId = uuidv1();


    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        //errors = false;   //Do not override pervious error settings
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    

    if (errors === true) {
      //console.log('errors | set identityInputErrors');
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        savingData: false,
      });
      //Advise the parent component that the input status changed
      //this.props.identityInputValidationStatus(false);
    } else {
      //No errors experienced, save record
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value: identityInputValue,
        lastChangeTransactionId,
      };

      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////

      //Advise the parent component that the input status changed
      //this.props.identityInputValidationStatus(true);



      //New code for identity field updates
      //Remove spaces for mobile numbers
      identityInputValue = identityInputValue.replace(/\s/g, "");
      let data = {
        type: this.props.identityType,
        identity: identityInputValue,
        identityId: this.props.identityId,
      }

      

      //Advise the parent component that we are saving the input
      //this.props.recordIdentityInputValidationSaving(true);


      this.props.onPhoneInput(identityInputValue, false);


      /*
      let apiResponse = await this.props.updateUserIdentities({data});
      console.log('apiResponse');
      console.log(apiResponse);
      */

      //Advise the parent component that we have saved the input
      //this.props.recordIdentityInputValidationSaving(false);
      /*
      this.setState({
        savingData: false,
      });
      */


      

    } //end if errors
  }



  toggleCountryCodeSelection() {
    console.warn("toggleCountryCodeSelection");
    this.setState(prevState => ({
      countryCodeSelectionBox: !prevState.countryCodeSelectionBox
    }));
  }


  render() {
    const {
      id, name, label, identifiers, stateIdentifier,
      value, onChangeInputTextHandler, maxlength,
      dataType,
      pattern,
      dataErrorMessage,

      feature = '',
    } = this.props;


    return (
      <div className={classNames({flex: true, flexGrow: true, })}>
        {this.state.countryCodeSelectionBox === true ? (
          <div className={classNames({flex: true, flexGrow: true, })}>
            <SelectBoxV2 
              id="countryDialingCodes"
              data={this.state.countryDialingCodes}
              objectArray={true}
              objectLabel="name"
              labelPrefix=""
              objectValue="dialCode"
              returnAllData={true}
              onSelectInputChange={this.handleCountryDialingCodeSelection}

            />
          </div>
        ):(
          <div className={classNames({flex: true, flexGrow: true, flexAlignItemsCenter: true,
          inputPhoneErrors: this.state.identityInputErrors === true ? true : false,
          inputPhoneNoErrors: this.state.identityInputErrors !== true ? true : false,
          })}>

            <div onClick={this.toggleCountryCodeSelection}>
              <div className={classNames({width75: true, padR5: true, })}>
                <div className={classNames({flex: true, flexAlignItemsCenter: true, flexAlignItemsCenter: true,
                 ShcIdentityInput_textNoInput: true, ShcIdentityInput_textboxStyling: true, })}>


                  <div className={classNames({text_s16: true, })}>
                    +{this.state.countryCodeSelection}
                  </div>

                </div>
              </div> 
            </div>
            <div className={classNames({flexGrow: true, })}>
              <InputTextV3 
                id={name} 
                name={name} 
                identifiers={identifiers}
                stateIdentifier={stateIdentifier}
                label='' 
                value={this.state.identityInputValue}
                onChangeInputTextHandler={this.handleChangeV2} 
                dataType="phone" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                pattern=""
                feature=""    //AsYouType
                keyboardType="phone-pad"
              />
            </div> 
          </div>
        )}
      </div>
    );
  }
}

InputPhoneV2.propTypes = {

};
InputPhoneV2.defaultProps = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputPhoneV2);

