import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class LocationSearchInputV1 extends React.Component {
  constructor(props) {
    super(props);

    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);


    this.state = { 
      apiTimerCollection: [], // List of active timers.

      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,


      address: '',
    };
  }
    /* Attempt to get the address split into the following
    recipient.addressLine1 = "Address 1";
    recipient.addressLine2 = "Address 2";
    recipient.addressCity = "addressCity";
    recipient.addressState = "addressState";
    recipient.addressPostalCode = "code";
    recipient.addressCountry = "country";
    */
  
   handleLocationInputChange = address => {
    //console.log("Handle change from CHILD component (LocationSearchInputGoogleAutoComplete)")
    this.setState({ address });

    let returnObj = {
      identifiers: this.props.identifiers,
      name: "userIdentities.$.identity",
      stateIdentifier: this.props.stateIdentifier,
      value: this.state.identityNotificationEnabled,
    }
    //console.log(returnObj);

    this.updateData(returnObj);

  };
  
 



  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }
 
  render() {
    const { 
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,
      identityInputValue,
      icon, children } = this.props;


    return (
      
          <div>
            
            <div>
              <div className={classNames({
                flex: true,
                flexJustifySpaceBetween: true,
                flexAlignBaseline: true,
                ShcIdentityInput_textInput_root: true,
              })}
              >
                <div className={classNames({
                  ShcIdentityInput_textInput_child: true,
                  ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
                  ShcIdentityInput_textInput_notificationsEnabled_icon: 
                  this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
                  ShcIdentityInput_textInput_notificationsDisabled_icon: 
                  this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
                })}
                >
                  {this.state.identityNotificationOptionsAvailable &&
                    <div className={classNames({ 
                      ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
                    })}>
                      <div className={classNames({ 
                        ShcIdentityInput_textInput_notificationsClickOverlay: true,
                      })}
                      onClick={this.toggleIdentityNotification}>

                      </div>
                    </div>
                  }
                  {identityLocked === true ?
                  <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                    ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                    ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
                    {this.state.identityInputValue}
                  </div>
                  :
                    <div>
                      <LocationSearchInputGoogleAutoComplete
                      value={this.state.address}
                      onChange={this.handleLocationInputChange}
                      />
                    </div>
                  }
                  
                </div>
              </div>
            </div>
            





          </div>
        
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationSearchInputV1);
