import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { get } from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

import Moment from 'react-moment';
//import 'moment-timezone';

import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

// Routing

// Material UI
import Grid from '@material-ui/core/Grid';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { MessageHighlight } from 'Components/ShcText';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { InputText, InputTextV3, RadioOptionCardV1 } from 'Components/ShcInput';

import { BusinessConnectCardTokenView } from 'Components/ShcModulesConnect';

// Actions
import { createCustomerConnectCode, createCustomerConnectCode_inPerson, toggleOverlayOnScreenResponsive, } from 'Actions';


class BusinessConnectCardInPerson extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.onChangeMeetingLocationHandler = this.onChangeMeetingLocationHandler.bind(this);
    this.onChangeBusinessIdentityHandler = this.onChangeBusinessIdentityHandler.bind(this);
    this.onChangeCustomerIdentityHandler = this.onChangeCustomerIdentityHandler.bind(this);

    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this);
    this.generateCustomerConnectCode = this.generateCustomerConnectCode.bind(this);
   


    this.handleExpiredToken = this.handleExpiredToken.bind(this);

    this.viewCustomerConnectHistory = this.viewCustomerConnectHistory.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);


    this.state = {
      businessId: props.businessId,
      businessProfileId: this.props.selectedBusinessProfileId,


      meetingLocation: '',
      meetingLocationInputErrors: false,
      businessIdentity: '',    //e.g. mobile number
      businessIdentityInputErrors: false,
      customerIdentity: '',    //e.g. mobile number
      customerIdentityInputErrors: false,





      inputValue: '',       //input value from the textbox
      inputErrors: false,      //input errors

      activeConnectTransaction: this.props.activeConnectTransaction ? this.props.activeConnectTransaction : '',


      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkTextLeft: props.linkTextLeft,
      linkTextRight: props.linkTextRight,
      inputTextTitle1: props.inputTextTitle1,
      cardType: props.cardType,

      activePhoneToken: props.activePhoneToken,

      
      tokenObj: props.tokenObj,
      tokenObjHistory: props.tokenObjHistory,

      textInputNumber: '',
      inValidEntry: '',

      connectPhoneTime: 'now',


      transactionId: '',
      tokenId: '',
      tokenExpiryTimestamp: '',
      tokenGenerationTimestamp: '',

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps | BusinessConnectCardPhone");
    let updateState=false;


    let transaction = false;
    try {
      transaction = nextProps.activeConnectTransaction;
    } catch(e) {}

    let transactionData = false;
    try {
      transactionData = nextProps.transaction.transactionData;
    } catch(e) {}
    let tokenId = '';
    let businessProfileId = '';
    let tokenExpiryTimestamp = '';
    let tokenGenerationTimestamp = '';
    let transactionId = '';
    
    if (transactionData === false) {
      //No data available, everything blank
    } else {
      try {
        businessProfileId = transaction.businessProfileId;
        transactionId = transaction.transactionId;
        updateState=true;
      } catch(e) {}

      try {
        tokenId = transactionData.tokenId;
        tokenExpiryTimestamp = transactionData.tokenExpiryTimestamp;
        tokenGenerationTimestamp = transactionData.tokenGenerationTimestamp;
        updateState=true;
      } catch(e) {}
    }



    if (nextProps.selectedBusinessProfileId !== prevState.businessProfileId) {
      updateState=true;
    }

    if (updateState===true) {
      
      return {
        businessProfileId: nextProps.selectedBusinessProfileId,
        

        transactionId: transactionId,
        tokenId: tokenId,
        tokenExpiryTimestamp: tokenExpiryTimestamp,
        tokenGenerationTimestamp: tokenGenerationTimestamp,
      }
    }





    return null;
  }


  componentDidMount(props) {
    ////console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    ////console.log('componentDidUpdate.');
  }

  closeOverlayOnScreen = (overlayId) => {
    ////console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }
  viewCustomerConnectHistory = () => {
    let tableData=[];
    let tableItem;
    //console.log("this.state.tokenObjHistory");
    //console.log(this.state.tokenObj);
    //console.log(this.state.tokenObjHistory);

    if (this.state.tokenObjHistory.length > 0) {

      //console.log("viewCustomerConnectHistory");
      //console.log(this.state.tokenObjHistory);

      var timezone = new Date().getTimezoneOffset();
      //console.log(timezone);
      
      this.state.tokenObjHistory.map((item, i) => {
        //console.log(item.tokenId);
        let transactionTime = new Date(item.timestampReqTransaction);
        let expirytime = new Date(item.tokenExpiry);
        ////console.log(d);
        ////console.log(transactionTime.toLocaleString());
        //d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
        ////console.log(d);
        ////console.log(d.toLocaleString());
        let tableItem = {
          time: transactionTime.toLocaleString(),
          code: item.tokenId,
          expirytime: expirytime.toLocaleString(),
        };
        tableData.push(tableItem);
      });
      
      //console.log(JSON.stringify(tableData));

    } else {
      tableData="";
    }
    const data = {
      tableData: tableData,
    };

    this.props.toggleOverlayOnScreenResponsive('open', 'customerConnectPhoneViewHistory', data);
  }



  onChangeInputTextHandler = (event) => {
    if (this.state.inValidEntry!=='') {
      this.setState({
        [event.target.name]: event.target.value,
        inValidEntry: '',
      });
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  

  handleExpiredToken() {
    //console.log('Token has expired - remove/grey out token - they may be in the middle of talking to the person');
  }

  generateCustomerConnectCode(evt) {
    event.preventDefault();
    //console.log('Function: generateCustomerConnectCode');
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    /* Radio Values
    const selection = {
      businessId: this.props.businessId,
      radioOptionsGroup: this.state.radioOptionsGroup,
      radioOptionsId: this.state.radioOptionsId,
      radioSelectedState: this.props.radioSelectedState,
    };
    */

    let radioSelectedContactTime = '';
    if (this.props.uiRadioOptions) {
      for (let z = 0; z < this.props.uiRadioOptions.length; z++) {
        if (this.props.uiRadioOptions[z].businessId === this.state.businessId) {
          if (this.props.uiRadioOptions[z].radioOptionsGroup === "connectPhoneTime") { // This Radio Group
            radioSelectedContactTime = this.props.uiRadioOptions[z].selectedRadioOptionsId;
          }
        }
      }
    }

    //console.log("this.state.businessProfileId");
    //console.log(this.state.businessProfileId);
    //console.log("this.props.selectedBusinessProfileId");
    //console.log(this.props.selectedBusinessProfileId);

    //console.log("radioSelectedContactTime");
    //console.log(radioSelectedContactTime);


    const connectRequest = {
      businessId: this.state.businessId,
      businessProfileId: this.state.businessProfileId,
      businessInteractionIntentType: 'phone',  //phone, email, social, postal, inperson
      businessInteractionIntentTime: radioSelectedContactTime,    //relative time, now, 5mins, 10mins

      customerContactIdentity: this.state.customerIdentity,   //this is the identity +61419665808
      customerContactIdentityType: 'phone',   //the type of customer contact identity
        //e.g with tokenisation the above could be:
        //customerContactIdentity: tok_2389f89wf923gw97gf97wg39r7g,
        //customerContactIdentityType: 'phone',
        //customerContactIdentityDataType: 'token',
      customerContactIdentityDataType: 'phone',


      meetingLocation: this.state.meetingLocation,
      businessIdentity: this.state.businessIdentity,
      //customerIdentity: this.state.customerIdentity,      //<-- the customer value is being put into the existing fields

      /*
      contactIdentity: this.state.inputValue,
      contactType: 'phone',
      contactTime: radioSelectedContactTime,
      contactDataType: 'phone',     //this could be a token on the api. This field allows for determing the different data
      */
    }


    if (this.state.inputErrors) {
      console.warn("Input Errors | User to resolve.");
    } else {
      this.props.createCustomerConnectCode_inPerson({ connectRequest, apiUi });   //New Action
    }
    

  }

  

  onSelectRadioHandler(radioOptionsGroup, radioSelection, evt) {
    //console.log("Function: onSelectRadioHandler");
    this.setState({
      [radioOptionsGroup]: radioSelection
    });
  }

  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
        errors: true/false              <-- new requirement
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/
    this.setState({
      inputValue: recordsForUpdate[0].value,
      inputErrors: recordsForUpdate[0].errors,
    });

  }


  onChangeMeetingLocationHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
        errors: true/false              <-- new requirement
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/
    this.setState({
      meetingLocation: recordsForUpdate[0].value,
      meetingLocationInputErrors: recordsForUpdate[0].errors,
    });

  }

  
  onChangeBusinessIdentityHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
        errors: true/false              <-- new requirement
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/
    this.setState({
      businessIdentity: recordsForUpdate[0].value,
      businessIdentityInputErrors: recordsForUpdate[0].errors,
    });

  }



  onChangeCustomerIdentityHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
        errors: true/false              <-- new requirement
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/
    this.setState({
      customerIdentity: recordsForUpdate[0].value,
      customerIdentityInputErrors: recordsForUpdate[0].errors,
    });

  }



  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, 
      linkTextLeft, linkTextRight, inputTextTitle1, customTextProfile,
    } = this.state;


    const CustomerConnectHistoryTable =() => {
      if (this.props.overlayOnScreenResponsiveData !== '') {
        const {tableData} = this.props.overlayOnScreenResponsiveData;
        //console.log("CustomerConnectHistoryTable");
        //console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Time",
                        accessor: "time"
                      },
                      {
                        Header: "Token",
                        accessor: "code"
                      },
                      {
                        Header: "Token Expiry Time",
                        accessor: "expirytime"
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return "No data.";
        }
        return "No data.";
      }
      return null;
    }

    return (
      <div className={classNames({ pageWrapper: false })}>
        <CardBasicV2 className={classNames({ shcModulesConnectCard_container: true })}>
          <div>
            <div className={classNames({ shcModulesConnectCard_headlineSubTextPanel: true })}>
              <div className={classNames({ shcModulesConnectCard_headlineText: true })}>
                {headlineText}
              </div>
              <div className={classNames({ shcModulesConnectCard_headlineSubRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>

            <div className={classNames({ shcModulesConnectCard_headlineSubLeft: true })} component="h2">
              {headlineSubText}
            </div>

            <div className={classNames({ shcModulesConnectCard_headlineSubLeft: true })} component="h2">

              <InputCardItem headlineText="Meeting Location" headlineSubText="Enter a physical address" 
              additionalText="Both your business employee and customer will receive the address as part of the in-person verification.">
                <InputTextV3 
                  id="connectMeetingLocation" 
                  name="connectMeetingLocation" 
                  identifiers={[{'_id': this.state.businessViewId}]}
                  stateIdentifier="BusinessIdentities"
                  label='' 
                  value=''
                  onChangeInputTextHandler={this.onChangeMeetingLocationHandler} ///////
                  dataType="text" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                  pattern=""
                />
                {this.state.meetingLocationInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({shcModulesConnectCard_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({shcModulesConnectCard_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
                }
              </InputCardItem>
          
              <InputCardItem headlineText="Business Employee" headlineSubText="Enter your employees mobile number" 
              additionalText="">
                <InputTextV3 
                  id="businessIdentity" 
                  name="businessIdentity" 
                  identifiers={[{'_id': this.state.businessViewId}]}
                  stateIdentifier="BusinessIdentities"
                  label='' 
                  value=''
                  onChangeInputTextHandler={this.onChangeBusinessIdentityHandler} 
                  dataType="phone" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                  pattern=""
                  feature="AsYouType"
                />
                {this.state.businessIdentityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({shcModulesConnectCard_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({shcModulesConnectCard_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
                }
              </InputCardItem>

              <InputCardItem headlineText="Customer" headlineSubText="Enter the customers mobile number" additionalText="">
                <InputTextV3 
                  id="customerIdentity" 
                  name="customerIdentity" 
                  identifiers={[{'_id': this.state.businessViewId}]}
                  stateIdentifier="BusinessIdentities"
                  label='' 
                  value=''
                  onChangeInputTextHandler={this.onChangeCustomerIdentityHandler} 
                  dataType="phone" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                  pattern=""
                  feature="AsYouType"
                />
                {this.state.customerIdentityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({shcModulesConnectCard_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({shcModulesConnectCard_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
                }
              </InputCardItem>

              


              <div className={classNames({ flex: true, flexJustifySpaceBetween: true, })}>
                <div className={classNames({ flexGrow: true })}>
                  <RadioOptionCardV1
                  businessId={this.state.businessId}
                  radioOptionsGroup="connectPhoneTime"
                  radioOptionsId="now"
                  headlineText="Now"
                  secondaryText=""
                  onSelectHandler={this.onSelectRadioHandler.bind(this, 'connectPhoneTime', 'now')} 
                  />
                </div>
                <div className={classNames({ flexGrow: true })}>
                  <RadioOptionCardV1
                  radioOptionsGroup="connectPhoneTime"
                  radioOptionsId="1hr"
                  headlineText="1 hours"
                  secondaryText=""
                  onSelectHandler={this.onSelectRadioHandler.bind(this, 'connectPhoneTime', '1hr')} 
                  />
                </div>
                <div className={classNames({ flexGrow: true })}>
                  <RadioOptionCardV1
                  radioOptionsGroup="connectPhoneTime"
                  radioOptionsId="6hr"
                  headlineText="6 hours"
                  secondaryText=""
                  onSelectHandler={this.onSelectRadioHandler.bind(this, 'connectPhoneTime', '6hr')} 
                  />
                </div>
                <div className={classNames({ flexGrow: true })}>
                  <RadioOptionCardV1
                  radioOptionsGroup="connectPhoneTime"
                  radioOptionsId="24hr"
                  headlineText="24 hours"
                  secondaryText=""
                  onSelectHandler={this.onSelectRadioHandler.bind(this, 'connectPhoneTime', '24hr')} 
                  />
                </div>
              </div>

              <div>
                {this.state.inValidEntry != '' &&
                (
                  <MessageHighlight type="danger" key={this.state.inValidEntry}>
                    {this.state.inValidEntry}
                  </MessageHighlight>
                )
                }
              </div>
            </div>

            <div>
              <StandardApiBtn
              apiActivityId="BusinessConnectPhone"
              icon=""
              iconLocation=""
              text="Contact Now"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-fullwidth"
              handleClick={this.generateCustomerConnectCode}
              />
            </div>

            <div>
              <br />
            </div>

       
            {this.props.activeConnectTransaction !== '' && this.props.activeConnectTransaction !== {} &&
            (
              <div>
                <div>
                  The customer will ask you to provide the following code as verification:
                </div>
                <br />
                <BusinessConnectCardTokenView 
                subHeading="Token" 
                token={this.props.activeConnectTransaction}
                transaction={this.props.activeConnectTransaction}
                >
                  {
                  this.props.activeConnectTransaction &&
                    this.props.activeConnectTransaction.transactionData &&
                      this.props.activeConnectTransaction.transactionData.tokenId
                  }
                </BusinessConnectCardTokenView>
              </div>
            )
            }


            <div />
          </div>
          <div className={classNames({ shcModulesConnectCard_buttonContainer: true })}>

            

          </div>
        </CardBasicV2>




        <OverlayOnScreenResponsive
          id="customerConnectPhoneViewHistory"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="View Customer Connect Phone History"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Customer Connect History</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              Note: These are only the tokens you have generated in this session.

              <CustomerConnectHistoryTable/>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('customerConnectPhoneViewHistory')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="View business history"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={() => this.handleAddPermissionConfirmed(this.props.overlayOnScreenResponsiveData.id)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        
        

      </div>

    );
  }
}
// initialTime={60000 * 60 * 48 + 5000}

BusinessConnectCardInPerson.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkTextLeft: PropTypes.string,
  linkTextRight: PropTypes.string,
  inputTextTitle1: PropTypes.string,
  cardType: PropTypes.string,
};

BusinessConnectCardInPerson.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkTextLeft: '',
  linkTextRight: '',
  inputTextTitle1: '',
  cardType: '',
  activePhoneToken: '',
};



const mapStateToProps = (state, ownProps) => {
  let businessIdentity = "";
  let activeConnectProfileId = "";
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  let selectedBusinessProfileId = '';
  try {
    selectedBusinessProfileId = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId).localSelectedBusinessProfile;
  } catch (e) {
    console.error('mapStateToProps | Unable to get the selected business profile');
  }
  //console.log('mapStateToProps | selectedBusinessProfileId | '+selectedBusinessProfileId+' | *********');




  try {
    activeConnectProfileId = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId).local.connect.activeProfile;
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the active connect profile');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  

  let activeConnectTransaction = {
    transactionData: {}
  };
  try {
    activeConnectTransaction = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId).local.connect.activeInteractionsPhone;
    console.warn("activeConnectTransaction | has been set");
  } catch (e) {
    console.error('mapStateToProps | Unable to determine the active connect profile');
  }


  return {
    businessIdentity: Object.assign({}, businessIdentity),
    selectedBusinessProfileId: selectedBusinessProfileId,

    activeConnectProfileId: activeConnectProfileId,
    activeConnectTransaction: Object.assign({}, activeConnectTransaction),
    overlayOnScreenResponsiveData: state.CommonActions.overlayOnScreenResponsiveData,
    uiRadioOptions: Object.assign([], state.UI.uiRadioOptions),
  };

};

const mapDispatchToProps = {
  createCustomerConnectCode, 
  createCustomerConnectCode_inPerson,

  toggleOverlayOnScreenResponsive,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectCardInPerson);
