import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { CardBasicV2 } from 'Components/ShcCards';
import { InputText, InputCard, InputCardItem } from 'Components/ShcInput';
import { NavigationCard } from 'Components/ShcCards';
import { StandardBtn } from 'Components/ShcButtons';



// Actions
import { getPlatformServiceHealth,  } from 'Actions';

// Images
import guardPointLogoLong from 'Images/Logos/logo-long-grey-400x.jpg';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      serviceStatus: [],
    };
  }


  

  async componentDidMount(props) {
    //Consider running this like the reporting data management
    let serviceStatus = await this.props.getPlatformServiceHealth();

    console.log(`componentDidMount | serviceStatus`);
    console.log(serviceStatus);
    this.setState({
      serviceStatus: serviceStatus,
      loading: false,
    });
  }


  componentWillUnmount() {

  }






  render() {

    /*
    <InputCard headlineText="Guard Point Service Status" headlineSubText="Status of all core services on the Guard Point platform.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Loading
                  </div>
                </div>
              </InputCard>
    */




    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
          <img src={guardPointLogoLong} className={classNames({ aaaaa: true })} />
        </div>

        <div className={classNames({ pad20: true })}>

          {this.state.loading === true ? (
            <div className={classNames({ flex: true, flexJustifyCenter: true })}>
              <div className={classNames({ maxWidth800: true })}>
                <div className={classNames({ flex: true, flexJustifyCenter: true,
                flexColumn: true, flexAlignItemsCenter: true,})}>
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" /><br/>
                  </div>
                  <div className={classNames({ text_s20: true })}>
                    Loading
                  </div>
                </div>
              </div>
            </div>
          ):(
            <div className={classNames({ flex: true, flexJustifyCenter: true })}>
              <div className={classNames({ maxWidth800: true })}>
                <div class="Rtable Rtable--3cols Rtable--collapse">
                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'auth') {
                      return (
                        <div className={`Rtable-cell Rtable-cell--head ${classNames({ flex: true, flexColumn: true, })}`}>
                          <div className={classNames({ text_s16: true })}>
                            Authentication
                          </div>
                          <div className={classNames({ text_s12: true })}>
                            Service
                          </div>
                        </div>
                      )
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'auth') {
                      return item.regions.map((regionItem, regionIndex) => {
                        if (regionItem.status === true) {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_success: true })}`}>
                              <i className="far fa-check-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        } else {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_danger: true })}`}>
                              <i className="fas fa-times-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        }
                      });
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'user') {
                      return (
                        <div className={`Rtable-cell Rtable-cell--head ${classNames({ flex: true, flexColumn: true, })}`}>
                          <div className={classNames({ text_s16: true })}>
                            User
                          </div>
                          <div className={classNames({ text_s12: true })}>
                            Service
                          </div>
                        </div>
                      )
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'user') {
                      return item.regions.map((regionItem, regionIndex) => {
                        if (regionItem.status === true) {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_success: true })}`}>
                              <i className="far fa-check-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        } else {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_danger: true })}`}>
                              <i className="fas fa-times-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        }
                      });
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'business') {
                      return (
                        <div className={`Rtable-cell Rtable-cell--head ${classNames({ flex: true, flexColumn: true, })}`}>
                          <div className={classNames({ text_s16: true })}>
                            Business
                          </div>
                          <div className={classNames({ text_s12: true })}>
                            Service
                          </div>
                        </div>
                      )
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'business') {
                      return item.regions.map((regionItem, regionIndex) => {
                        if (regionItem.status === true) {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_success: true })}`}>
                              <i className="far fa-check-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        } else {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_danger: true })}`}>
                              <i className="fas fa-times-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        }
                      });
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'connect') {
                      return (
                        <div className={`Rtable-cell Rtable-cell--head ${classNames({ flex: true, flexColumn: true, })}`}>
                          <div className={classNames({ text_s16: true })}>
                            Connect
                          </div>
                          <div className={classNames({ text_s12: true })}>
                            Service
                          </div>
                        </div>
                      )
                    }
                  })}

                  {this.state.serviceStatus && this.state.serviceStatus.map((item, index) => {
                    if (item.service === 'connect') {
                      return item.regions.map((regionItem, regionIndex) => {
                        if (regionItem.status === true) {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_success: true })}`}>
                              <i className="far fa-check-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        } else {
                          return (
                            <div className={`Rtable-cell ${classNames({ colour_danger: true })}`}>
                              <i className="fas fa-times-circle" />&nbsp;{regionItem.regionName}
                            </div>
                          )
                        }
                      });
                    }
                  })}


                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    );
  }
}
//<i className="fas fa-times-circle" />
Template.propTypes = {
};

const mapStateToProps = state => ({
  identities: state.BusinessIdentities.identities,
});

const mapDispatchToProps = {
  getPlatformServiceHealth,

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
