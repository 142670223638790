import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { MessageTip, MessageHighlight, HighlightContainer } from 'Components/ShcText';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

// Actions


class ApiManagementDisplay extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,     //start collapsed
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }




  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { data } = this.props;

    const CurrentStatus = () => {
      return (
        <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
          <i class="fas fa-check"></i>
        </div>
      );
    }



    // dataObj - [{text1: text2}, {text3: text4}]


    const TableListRowHeader = () => (
      <div className={classNames({ UsersRolesDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesDisplay_TableRowStyles: true })}>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true })}>
            Name
          </div>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true, UsersRolesDisplay_contentText: true })}>
            Credentials
          </div>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true, UsersRolesDisplay_contentText: true })}>
            Actions
          </div>
        </div>
      </div>
    );

    const TableListRow = (companyName, apiDesc, created, lastUsed) => (
      <div className={classNames({ UsersRolesDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesDisplay_TableRowStyles: true })}>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true })}>
            <div className={classNames({ UsersRolesDisplay_titleText: true })}>
              {companyName}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              {apiDesc}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              {created}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              {lastUsed}
            </div>
            <div className={classNames({ UsersRolesDisplay_subTitleText: true })}>
              Expiry Date (less than 20 days - RED)<br />

            </div>
          </div>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true, UsersRolesDisplay_contentText: true })}>


            <div className={classNames({ flex: true })}>

              <div>API Key</div>
              <div>
                <div className={classNames({
                  flex: true,
                  flexJustifySpaceBetween: true,
                  flexAlignBaseline: true,
                })}
                >
                  <div className={classNames({
                      flexMargin5: true,
                      fullwidth: true,
                      shcMaterialTextFieldBaselinePadding: true,
                    })}
                  >
                    <TextField
                      className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                      id="apikey"
                      label="API Key"
                      margin="normal"
                      fullWidth
                      value={this.state.firstName}
                      onChange={this.handleChangeTextFirstName}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className={classNames({ flex: true })}>

              <div>New Key (Generator)</div>
              <div>
                <div className={classNames({
                  flex: true,
                  flexJustifySpaceBetween: true,
                  flexAlignBaseline: true,
                })}
                >
                  <div className={classNames({
                      flexMargin5: true,
                      fullwidth: true,
                      shcMaterialTextFieldBaselinePadding: true,
                    })}
                  >
                    <TextField
                      className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                      id="apikey"
                      label="API Key"
                      margin="normal"
                      fullWidth
                      value={this.state.firstName}
                      onChange={this.handleChangeTextFirstName}
                    />
                  </div>
                </div>
              </div>

            </div>


          </div>
          <div className={classNames({ UsersRolesDisplay_divTableCell: true, UsersRolesDisplay_contentText: true })}>
            actions<br />
            copy layout from Goog
            <br />
              https://console.cloud.google.com/apis/credentials?folder=&organizationId=&project=react-3bc09
          </div>
        </div>
      </div>
    );

    const TableList = () => (
      <div>
        <div className={classNames({ UsersRolesDisplay_divTable: true, UsersRolesDisplay_container: true })}>
          <div className={classNames({ UsersRolesDisplay_divTableBody: true })}>

            <TableListRowHeader />

            {data.map(obj => (
              TableListRow(obj.companyName, obj.apiDesc, obj.created, obj.lastUsed)
            ))}

          </div>
        </div>
      </div>
    );


    return (
      <div>
        <div className={classNames({ ShcAccountModulesContainer: true })}>
          <div className={classNames({ ShcAccountModulesContainerHeader: true, flex: true, cursorPointer: true })}
          onClick={this.collapseContainer}>

            <CurrentStatus/>
    
            <div className={classNames({ pad10: true, flexGrow: true })}>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div>
                  <div className={classNames({ flex: true, flexColumn: true })}>
                    <div className={classNames({ text_h6: true })}>Sales teams use this Key</div>
                    <div className={classNames({ text_h4: true })}>Primary Use API Key</div>
                    <div className={classNames({ text_h6: true })}>...</div>
                    
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                  width30: true, cursorPointer: true })}
                  >
                  <i class="fas fa-angle-down"></i>
                </div>
              </div>
            </div>
          </div>
          {this.state.collapse ?
          null
          :
          <div className={classNames({ ShcAccountModulesContainerBody: true })}>
            <div className={classNames({ flex: true})}>

              <div className={classNames({ padT10: true, flexGrow: true })}>
                
              </div>
              <div className={classNames({ padT10: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Delete"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />
              </div>
            </div>

            <MessageHighlight type="warning">
              To view an API Key, you need to click "view". We will then download this specific 
              API key to show you. This key will be automatically deleted if you change page 
              and/or after 60 seconds. This ensures your api key is not maintained in state on 
              this computer.
            </MessageHighlight>


            <div>

              <InputCard headlineText="API Key" headlineSubText="Status: Expired">

                <InputCardItem headlineText="API Key" headlineSubText="" additionalText="">
                  <InputTextV2 id="name" name="name" label="" value="8yhdsf98gh984ghp9rfa8hw9r8y239r9awrf98e993wry80weyrf"  />
                </InputCardItem>
                <CardBasicV2 padding="none" className={classNames({ contentPad10: true })}>

                  <InputCardItem headlineText="Generated" headlineSubText="" additionalText="">
                    <TextStyle size="h5" weight="normal">06/07/2019 04:32</TextStyle>
                  </InputCardItem>
                  <InputCardItem headlineText="Key Age" headlineSubText="How long the key has been in use." additionalText="We recommend you rotate your API keys monthly.">
                    <MessageHighlight type="warning">
                      This key has been active for 27 days. You should rotate this key.
                    </MessageHighlight>
                  </InputCardItem>

                </CardBasicV2>
                
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Expire this API Key"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Delete this API Key Now"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />

              </InputCard>

              <InputCard headlineText="API Key" headlineSubText="">

                <InputCardItem headlineText="API Key" headlineSubText="" additionalText="">
                  <InputTextV2 id="name" name="name" label="" value="8yhdsf98gh984ghp9rfa8hw9r8y239r9awrf98e993wry80weyrf"  />
                </InputCardItem>
                <InputCardItem headlineText="Generated" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">06/07/2019 04:32</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Status" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">Active</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Key Age" headlineSubText="How long the key has been in use." additionalText="We recommend you rotate your API keys monthly.">
                  <MessageHighlight type="warning">
                    This key has been active for 27 days. You should rotate this key.
                  </MessageHighlight>
                </InputCardItem>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Expire this API Key"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />

              </InputCard>

              <InputCard headlineText="API Key" headlineSubText="">

                <InputCardItem headlineText="API Key" headlineSubText="" additionalText="">
                  <InputTextV2 id="name" name="name" label="" value="8yhdsf98gh984ghp9rfa8hw9r8y239r9awrf98e993wry80weyrf"  />
                </InputCardItem>
                <InputCardItem headlineText="Generated" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">06/07/2019 04:32</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Status" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">Active</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Key Age" headlineSubText="How long the key has been in use." additionalText="We recommend you rotate your API keys monthly.">
                  <MessageHighlight type="warning">
                    This key has been active for 27 days. You should rotate this key.
                  </MessageHighlight>
                </InputCardItem>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Expire this API Key"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />

              </InputCard>

              


              


              <InputCard headlineText="Customer Connect View" headlineSubText="This is what the customer
              will see when you use this profile">

                <InputCardItem headlineText="Website" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">https://www.authchannel.com.au</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Website" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">https://www.authchannel.io</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Phone" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">11 22 44</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">info@authchannel.com.au</TextStyle>
                </InputCardItem>
                <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                  <TextStyle size="h5" weight="normal">hello@authchannel.com.au</TextStyle>
                </InputCardItem>

                <InputCardItem headlineText="Logo" headlineSubText="" additionalText="Sourced from website: https://www.authchannel.com.au">
                  <TextStyle size="h5" weight="normal">image view</TextStyle>
                </InputCardItem>

              </InputCard>

            </div>


          </div>
          }
          
        </div>
      </div>
    );
  }
}

ApiManagementDisplay.propTypes = {

};
ApiManagementDisplay.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiManagementDisplay);
