import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class NavigationCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }


  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
      height,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
    };

    const containerHeightBaseSpacing = {
      height: this.props.height ? `calc(${this.props.height} - 50px)` : 'auto',
    };


    return (
      <div >
        <CardBasic height={containerHeightBaseSpacing} className={classNames({ shcCardNavigationCard_container: true })}>
          <div>
            <div className={classNames({ shcCardNavigationCard_headlineSubTextPanel: true })}>
              <div className={classNames({ shcCardNavigationCard_headlineSubLeft: true })}>
                {headlineSubText}
              </div>
              <div className={classNames({ shcCardNavigationCard_headlineSubRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>

            <div className={classNames({ shcCardNavigationCard_headlineText: true })} component="h2">
              {headlineText}
            </div>
            <div className={classNames({ shcCardNavigationCard_secondaryText: true })}>
              { additionalText ? null : <br /> }
              {secondaryText}
            </div>
            <div className={classNames({ shcCardNavigationCard_additionalText: true })} component="p">
              {additionalText}
            </div>
          </div>
          <div className={classNames({ shcCardNavigationCard_buttonContainer: true })}>


            <StandardBtn
              icon=""
              iconLocation=""
              text={linkText}
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
            />


          </div>
        </CardBasic>
      </div>

    );
  }
}

NavigationCard.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

NavigationCard.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NavigationCard);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
