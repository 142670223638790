import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import Link from 'react-router-dom/Link';

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem } from 'Components/ShcCards';

// import { askForPermissioToReceiveNotifications } from 'Components/ShcPushNotify/push-notification';


// Actions
import { refreshAccessToken, updateIsAuthenticatedStatus, userAuth, userAuthEmail, userAuthLinkAccount, setuserlogout } from 'Actions';
import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      loginLoading: false,

      user: null,
      emailUsername: this.props.emailId,
      password: '',
      isAuthenticated: this.props.isAuthenticated,
      errorCode: '',
      errorMessage: '',
    };


    this.handleEmailLoginClick = this.handleEmailLoginClick.bind(this);
    this.handleEmailUsernameInput = this.handleEmailUsernameInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
  }



  componentDidMount(props) {
    //console.log('Loading template.');

    // Bind the variable to the instance of the class.
    this.authFirebaseListener = auth().onAuthStateChanged((user) => {
      //console.log('onAuthStateChanged');
      this.setState({
        loading: false, // For the loader maybe
        user, // User Details
        isAuth: true,
      });

    });

  }

  componentDidUpdate() {}

  handleEmailLoginClick(e) {
    //console.log('Link Account via Login');
    // Loading - no more clicking
    this.setState({ loginLoading: true });


    auth().signInWithEmailAndPassword(this.state.emailUsername, this.state.password)
      .then((user) => {
        this.setState({ user: user.user, loginLoading: true });

        this.props.userAuthLinkAccount({ user })  //This is standard login (this is not what we want here)
        .catch((error) => {
          console.warn('Initial auth successful. Second stage failure.');
          console.warn(error);
          this.setState({ loginLoading: false });
          toast.error('Error experienced during login.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        });
        /*
          this.props.userAuthEmail({ user })  //This is standard login (this is not what we want here)
          .catch((error) => {
            console.warn('Initial auth successful. Second stage failure.');
            console.warn(error);
            this.setState({ loginLoading: false });
            toast.error('Error experienced during login.', {
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              className: 'toastError',
              bodyClassName: 'grow-font-size',
              progressClassName: 'toastErrorProgress',
            });
          });
          */
      })
      .catch((error) => {
      // Handle Errors here.
        this.setState({ loginLoading: false });
        //console.log('handle the error');
        //console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log(errorCode);

        if (errorCode === 'auth/invalid-email') {
          this.setState({ errorCode: 'auth/invalid-email', errorMessage: 'Invalid email' });
          toast.error('Invalid email address.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        } else if (errorCode === 'auth/wrong-password') {
          this.setState({ errorCode: 'auth/wrong-password', errorMessage: 'Wrong password' });
          toast.error('Incorrect Password.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        } else if (errorCode === 'auth/user-disabled') {
          this.setState({ errorCode: 'auth/user-disabled', errorMessage: 'Account disabled' });
          toast.error('Your account is disabled.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        } else if (errorCode === 'auth/user-not-found') {
          this.setState({ errorCode: 'auth/user-not-found', errorMessage: 'User not found' });
          toast.error('User not found.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        } else {
          //console.log(errorMessage);
          this.setState({ errorCode: 'auth/other', errorMessage: 'An error has occured' });
          toast.error('An error has occured during login.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });
        }

      });
    //console.log('end of login call');
    //
  }


  
  handleEmailUsernameInput(e) {
    //console.log('setting username');
    this.setState({ emailUsername: e.target.value });
  }
  handlePasswordInput(e) {
    this.setState({ password: e.target.value });
  }

  componentWillUnmount() {
    //console.log('unmounting component');
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
  }




  render() {
    const { errorMessage } = this.state;
    const { emailId } = this.props;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          {this.state.loginLoading ?
          (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Login" headlineSubText="If you haven't got an account, you can register for free.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Loading
                  </div>
                </div>
              </InputCard>
            </div>
          ) : (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Link Account" headlineSubText="Enter a password">

                <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                  <div className={classNames({ ShcIdentityInput_textNoInput: true})}>
                    {emailId}
                  </div>
                </InputCardItem>

                <InputCardItem headlineText="Password" headlineSubText="" additionalText="">
                  <InputTextV2
                    id="Password"
                    name="Password"
                    value={this.state.password}
                    label="Password"
                    onChangeInputTextHandler={this.handlePasswordInput}
                    />
                </InputCardItem>

                { errorMessage !== '' && (
                <StandardText
                  icon=""
                  iconLocation=""
                  text={errorMessage}
                  tooltip=""
                  action=""
                  classes="shctext shctext-default-danger shctext-rounded shctext-medium shctext-fullwidth"
                      />
                  )
                  }

                <div>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Create linked account"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                    handleClick={this.handleEmailLoginClick}
                    />
                </div>

                


                

              </InputCard>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  apiAccessTokenExpiresOn: state.Auth.apiAccessTokenExpiresOn,
  apiRefreshTokenExpiresOn: state.Auth.apiRefreshTokenExpiresOn,
});

const mapDispatchToProps = {
  refreshAccessToken,
  updateIsAuthenticatedStatus,

  userAuth,
  //userAuthEmail,
  userAuthLinkAccount,  //New
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
