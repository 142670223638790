import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './Styles.css';

// Components
import { MessageTip, MessageHighlight } from 'Components/ShcText';
import { SelectInputChipsApi } from 'Components/ShcSelectInput';

// Actions
import { toggleOverlayOnScreen, togglePropertyPopupPanelInfo } from 'Actions';


// tip, message, action


class OverlayOnScreen extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickClose(evt) {
    console.log('onClick Close');
    // this.props.togglePropertyPopupPanelInfo();
    this.props.toggleOverlayOnScreen('close', this.props.id);
    evt.stopPropagation();
  }


  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
    console.log(evt);
  }


  handleAddChip(chip) {
    console.log('addign chip');
  }
  handleDeleteChip(chip, index) {
    console.log('deleting chip');
  }


  render() {
    const propertyPopupPanelInfo = this.props.propertyPopupPanelInfo;
    const overlayOnScreen = this.props.overlayOnScreen;


    const {
      id, classes, listener, width, height, anchor, title,
    } = this.props;

    // anchor    left/right/top/bottom/all

    const containerWidth = {
      width: `${width}`,
      webkitTransition: 'width 2s',
      transition: 'width 2s',
    };
    const containerHeight = {
      height: `${height}`,
    };
    let containerAnchor = {};
    if (anchor === 'all') {
      containerAnchor = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      };
    }
    if (anchor === 'left') {
      containerAnchor = {
        left: 0,
      };
    }
    if (anchor === 'right') {
      containerAnchor = {
        right: 0,
      };
    }
    if (anchor === 'top') {
      containerAnchor = {
        top: 0,
      };
    }
    if (anchor === 'bottom') {
      containerAnchor = {
        bottom: 0,
      };
    }


    let popupPanel;
    // if (propertyPopupPanelInfo) {
    if (overlayOnScreen === id) {
      popupPanel = (


        <div
          className={classNames({ OverlayOnScreen_Container: true })}
          style={Object.assign(containerWidth, containerHeight, containerAnchor)}
          onClick={(evt) => {
              evt.stopPropagation();
            }}
        >
          <div className={`${classNames({ OverlayOnScreen_Header: true })} ${classes}`}>
            <div className={classNames({ OverlayOnScreen_HeaderTitle: true })}>
              {title}
            </div>
            <div
              className={classNames({ OverlayOnScreen_HeaderClose: true })}
              onClick={(evt) => {
                  this.onClickClose(evt);
                }}
            >
              <i className="fas fa-times" />
            </div>
          </div>

          <div className={classNames({ OverlayOnScreen_ContainerContent: true })}>

            {this.props.children}

          </div>

        </div>

      );
    } else {
      popupPanel = null;
    }

    return (
      <div className={classNames({ OverlayScreenContainerBasePosition: true, OverlayScreenContainer: popupPanel !== null })}>
        <div
          className={classNames({ OverlayBaseBg: popupPanel !== null })}
          onClick={(evt) => {
            this.onClickClose(evt);
          }}
        >
          <ReactCSSTransitionGroup
            transitionName="OverlayOnScreen"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {popupPanel}
          </ReactCSSTransitionGroup>
        </div>
      </div>

    );
  }
}
// https://reactjs.org/docs/animation.html

OverlayOnScreen.propTypes = {

};

const mapStateToProps = state => ({
  propertyPopupPanelInfo: state.PropertyPanels.propertyPopupPanelInfo,
  overlayOnScreen: state.CommonActions.overlayOnScreen,
});

const mapDispatchToProps = {
  togglePropertyPopupPanelInfo,
  toggleOverlayOnScreen,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OverlayOnScreen);
