import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';


//Password Strenght
import ReactPasswordStrength from 'react-password-strength';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem, CardBasicV2  } from 'Components/ShcCards';



// Actions
import { userAuth, userSignupEmail, userSignupAuth, userSignUpEmail, setuserlogout } from 'Actions';

import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryString = require('query-string');

//?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy...&lang=fr

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.firebasePasswordReset = this.firebasePasswordReset.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handlePasswordStrengthInput = this.handlePasswordStrengthInput.bind(this);

    this.state = {
      userScreen: '',     //The screen to load (default needs to be a message)

      //Email Verification
      emailVerificationLoading: true,
      emailVerificationStatus: false,
      emailVerificationMessage: '',



      //PASSWORD
      password: '',
      passwordLength: 0,
      passwordIsValid: false,
      errorCode: '',
      errorMessage: '',

      //PASSWORD REQUIREMENTS
      passRequirementsValid: false,
      passLowerAndUpperCase: false,
      passHasNumber: false,
      passHasSpecialChar: false,

 
      actionCode: '',



      loginLoading: false,
      userDataRegion: '',     //Where the user data will be stored
      user: null,
      emailUsername: '',
      isAuthenticated: this.props.isAuthenticated,
    };

  }



  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      return {
      };
    }
    return null;
  }

  async componentDidMount(props) {
    console.warn("props");
    console.warn(this.props);


    let params = this.props.location.search;
    console.log(params);
    //=> '?foo=bar'
    const parsed = queryString.parse(params);
    console.log(parsed);
    //=> {foo: 'bar'}
     
    let firebaseMode = '';
    try {
      firebaseMode = parsed.mode;
    } catch (e) {
      firebaseMode = '';
    }
    let firebaseActionCode = '';
    try {
      firebaseActionCode = parsed.oobCode;
    } catch (e) {
      firebaseActionCode = '';
    }
    let firebaseApiKey = '';
    try {
      firebaseApiKey = parsed.apiKey;
    } catch (e) {
      firebaseApiKey = '';
    }
    let firebaseContinueUrl = '';
    try {
      firebaseContinueUrl = parsed.continueUrl;
    } catch (e) {
      firebaseContinueUrl = '';
    }
    let firebaseLang = '';
    try {
      firebaseLang = parsed.lang;
    } catch (e) {
      firebaseLang = '';
    }

    console.warn("firebaseMode");
    console.warn(firebaseMode);
    console.warn("firebaseActionCode");
    console.warn(firebaseActionCode);
    console.warn("firebaseApiKey");
    console.warn(firebaseApiKey);
    console.warn("firebaseContinueUrl");
    console.warn(firebaseContinueUrl);
    console.warn("firebaseLang");
    console.warn(firebaseLang);


    if (firebaseMode === 'resetPassword') {
      this.setState({
        userScreen: 'resetPassword',
      });
      this.firebasePasswordReset(firebaseActionCode, firebaseContinueUrl, firebaseLang);
    } else if (firebaseMode === 'verifyEmail') {
      this.setState({
        userScreen: 'verifyEmail',
      });
      this.firebaseEmailVerify(firebaseActionCode, firebaseContinueUrl, firebaseLang);
    }

  }

  componentDidUpdate() {
   
  }

  firebasePasswordReset(actionCode, continueUrl, lang) {
    console.warn('firebasePasswordReset');

    this.setState({
      actionCode: actionCode,
    });


    // Localize the UI to the selected language as determined by the lang
    // parameter.
    var accountEmail;
    // Verify the password reset code is valid.
    auth().verifyPasswordResetCode(actionCode).then(function(email) {
      var accountEmail = email;

      //Can setState here to show the correct screen.
     
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
  
 
    }).catch(function(error) {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }


  
  async firebaseEmailVerify(actionCode, continueUrl, lang) {
    console.warn('firebaseEmailVerify');
    this.setState({
      actionCode: actionCode,
    });

    let promise_verifyEmail = new Promise((resolve, reject) => {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      auth().applyActionCode(actionCode).then(function(resp) {
        //console.log("==== response from firebase ====");
        //console.log(resp);

        //Need to decide if I want to store this, then I need to have a verification code to pass back to me server
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        resolve({
          status: true,
          response: resp,
          error: null,
        });
      })
      .catch(function(error) {
        //console.log("==== ERROR response from firebase ====");
        //console.log(error);

        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        resolve({
          status: false,
          response: null,
          error: error,
        });
      });

    });
    let results = await promise_verifyEmail;

    console.log("results");
    console.log(results);


    if (results.status === true) {
      this.setState({
        emailVerificationLoading: false,
        emailVerificationStatus: true,
        emailVerificationErrorCode: '',
        emailVerificationErrorMessage: '',
      });

    } else if (results.status === false) {
      let errorCode = '';
      try {
        errorCode = results.error.code;
      } catch (e) {
        errorCode = '';
      }
      if (errorCode === undefined) {
        errorCode = '';
      }
      let errorMessage = '';
      try {
        errorMessage = results.error.message;
      } catch (e) {
        errorMessage = '';
      }
      if (errorMessage === undefined) {
        errorMessage = '';
      }
      this.setState({
        emailVerificationLoading: false,
        emailVerificationStatus: false,
        emailVerificationErrorCode: errorCode,
        emailVerificationErrorMessage: errorMessage,
      });
    }


  }



  async handleResetPassword() {
    if (this.state.passwordIsValid === false) {
      //Password is not valid
      this.setState({ 
        errorMessage: `Password does not meet the security requirements`, 
      });
    } else if (this.state.passwordIsValid === true) {
      //Password is valid
      console.warn('handleResetPassword');
      console.warn('this.state.actionCode');
      console.warn(this.state.actionCode);

      let promise_confirmPasswordReset = new Promise((resolve, reject) => {
        // Save the new password.
        auth().confirmPasswordReset(this.state.actionCode, this.state.password)
        .then((resp) => {
          // Password reset has been confirmed and new password updated.

          //WE DONT GET A RESPONSE... BUT AT THIS STAGE SHOW SUCCESS
          

          resolve({
            status: true,
            response: resp,
            error: null,
          });

          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          // auth.signInWithEmailAndPassword(accountEmail, newPassword);

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch(function(error) {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          resolve({
            status: false,
            response: null,
            error: error,
          });
        });
      });
      let results = await promise_confirmPasswordReset;

      console.log("results");
      console.log(results);


      if (results.status === true) {
        this.setState({
          userScreen: 'password-reset-complete',
          errorCode: '',
          errorMessage: '',
        });

      } else if (results.status === false) {
        let errorCode = '';
        try {
          errorCode = results.error.code;
        } catch (e) {
          errorCode = '';
        }
        if (errorCode === undefined) {
          errorCode = '';
        }
        let errorMessage = '';
        try {
          errorMessage = results.error.message;
        } catch (e) {
          errorMessage = '';
        }
        if (errorMessage === undefined) {
          errorMessage = '';
        }
        this.setState({
          errorCode: errorCode,
          errorMessage: errorMessage,
        });
      }


    } //end passwordIsValid
  }
















  handlePasswordStrengthInput(passwordStrength) {
    //Password Strength Input is used to guide the user.
    //Also require certain character types
    /*
    At least 1 uppercase character.
    At least 1 lowercase character.
    At least 1 digit.
    At least 1 special character.
    */

    let score = passwordStrength.score;
    let password = passwordStrength.password;
    let isValid = passwordStrength.isValid;

    //console.log(`score: ${score}`);
    //console.log(`password: ${password}`);
    //console.log(`isValid: ${isValid}`);


    let passwordLength = password.length;
    //console.log(passwordLength);

    //Does the password meet the Char Requirements?
    let passLowerAndUpperCase = false;
    if (password.match(/[a-zA-Z]/g)) {
      passLowerAndUpperCase = true;
    } else {
      passLowerAndUpperCase = false;
    }
    let passHasNumber = false;
    if (password.match(/[0-9]/g)) {
      passHasNumber = true;
    } else {
      passHasNumber = false;
    }
    /* Best practice says do not force.
    let passHasSpecialChar = false;
    //if (password.match(/[!@#$%^&*()_+-=\[\]{};':"|,.<>\/?]/g)) {
    if (password.match(/[!@#$%^&*()_+\-=[\]{}|;:<>?,./]/i)) {
      passHasSpecialChar = true;
    } else {
      passHasSpecialChar = false;
    }


    JSX
     {this.state.passHasSpecialChar === false && (
        <li>Password requires at least one special character</li>
      )}
    */


    let passRequirementsValid = false;
    if (passLowerAndUpperCase === true && passHasNumber === true) {   //&& passHasSpecialChar === true
      passRequirementsValid = true;
      this.setState({ 
        passRequirementsValid: passRequirementsValid,
        passLowerAndUpperCase: passLowerAndUpperCase,
        passHasNumber: passHasNumber,
        //passHasSpecialChar: passHasSpecialChar,
      });
    } else {
      passRequirementsValid = false;
      this.setState({ 
        passRequirementsValid: passRequirementsValid,
        passLowerAndUpperCase: passLowerAndUpperCase,
        passHasNumber: passHasNumber,
        //passHasSpecialChar: passHasSpecialChar,
      });
    }
    


    if (passRequirementsValid === true && isValid === true) {
      if (passwordLength > 128) {
        //Too long
        this.setState({ 
          passwordLength: passwordLength,
          passwordIsValid: false,
        });
      } else {
        this.setState({ 
          password: password,
          passwordLength: passwordLength,
          passwordIsValid: isValid,
        });
      }
    } else {
      this.setState({ 
        passwordLength: passwordLength,
        passwordIsValid: isValid,
      });
    }

  }



  componentWillUnmount() {

  }


  render() {

    const { errorMessage } = this.state;

    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true, maxWidth800: true })}>

          {this.state.userScreen === 'password-reset-complete' && (
            <div>
              <InputCard headlineText="Password Reset Complete" headlineSubText="Your password has been reset.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="far fa-check-circle" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Password reset has been completed.
                  </div>
                </div>
              </InputCard>
            </div>
          )}

          {this.state.userScreen === 'resetPassword' && (
            <div>
              {this.state.loginLoading ?
              (
                <div className={classNames({ maxWidth800: true })}>
                  <InputCard headlineText="ddp" headlineSubText="FREE user account. Sign up here.">
                    <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                      <br />
                      <div className={classNames({ text_s40: true })}>
                        <i className="fas fa-circle-notch fa-spin" />
                      </div>
                    </div>
                    <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                      <br />
                      <div className={classNames({ text_s20: true })}>
                      Loading
                      </div>
                    </div>
                  </InputCard>
                </div>
              ) : (
                <div className={classNames({ maxWidth800: true })}>
                  <InputCard headlineText="Reset Password" headlineSubText="">

                    <InputCardItem headlineText="Password" headlineSubText="" additionalText="">
                      <ReactPasswordStrength
                        className={`customClass ${classNames({ displayBlock: true })}`}
                        minLength={10}
                        minScore={3}
                        scoreWords={['weak', 'weak', 'good', 'strong', 'stronger']}
                        changeCallback={this.handlePasswordStrengthInput}
                        inputProps={{ name: "password_input", autoComplete: "off", className: "form-control" }}
                      />
                    </InputCardItem>

                    {this.state.passRequirementsValid === true && (
                      <div>
                        {this.state.passwordIsValid === true ? (
                          <div className={classNames({ text_s14: true, colour_success: true, })}>
                            <i class="far fa-check-circle"></i> Password meets security requirement.
                          </div>
                        ):(
                          <div>
                            {this.state.passwordLength > 0 && (
                              <div>
                                {this.state.passwordLength <= 128 ? (
                                  <div className={classNames({ text_s14: true, colour_danger: true, })}>
                                    <i class="far fa-times-circle"></i> Password does not meet security requirement.
                                  </div>
                                ):(
                                  <div className={classNames({ text_s14: true, colour_danger: true, })}>
                                    <i class="far fa-times-circle"></i> Password is too long.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    

                    {this.state.passRequirementsValid === false && (
                      <div className={classNames({ text_s14: true, colour_danger: true, })}>
                        <i class="far fa-times-circle"></i> Password does not meet character requirements:
                        <ul>
                        {this.state.passLowerAndUpperCase === false && (
                          <li>Password requires both uppercase and lowercase characters</li>
                        )}
                        {this.state.passHasNumber === false && (
                          <li>Password requires at least one number</li>
                        )}
                        </ul>


                      </div>
                    )}


                  
                    <br/>


                    { errorMessage !== '' && (
                    <StandardText
                      icon=""
                      iconLocation=""
                      text={errorMessage}
                      tooltip=""
                      action=""
                      classes="shctext shctext-default-danger shctext-rounded shctext-medium shctext-fullwidth"
                          />
                      )
                      }

                    <div>
                      <StandardBtn
                        icon=""
                        iconLocation=""
                        text="Reset Password"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                        handleClick={this.handleResetPassword}
                        />
                    </div>


                  </InputCard>
                </div>
              )}
            </div>
          )}

          {this.state.userScreen === 'verifyEmail' && (
            <div>
              <CardBasicV2>
                <div>
                  <div className={classNames({ text_h3: true })}>
                    Email Verification
                  </div>

                  {this.state.emailVerificationLoading ?
                  (
                    <div className={classNames({ text_h3: true })}>
                      Verifying...
                    </div>
                  ):(
                    <div>
                      {this.state.emailVerificationStatus ?
                      (
                        <div>Verification Successful</div>
                      ):(
                        <div>Verification Failed</div>
                      )}
                    </div>
                  )}


                </div>
              </CardBasicV2>
            </div>
          )}


        

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

const mapDispatchToProps = {
  userSignupEmail,

  userSignupAuth,
  userSignUpEmail,
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);

/*
<TextStyle size="h5">
  Our platform will hold some of your sensitive personal information and therefore we require you 
  to have a password that meets at least the "Strong" password category. You should aim for a password
  that is in the "Stronger" password category.
</TextStyle>

*/