import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";
import Moment from 'react-moment';
import Select from 'react-select';

//S3 Uploads
var ReactS3Uploader = require('react-s3-uploader');


// CSS
import componentStyles from './styles.css';

// Components
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';


import { VerifiedIdentityInput_business } from 'Components/ShcModulesIdentity';



// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { S3UploaderV1, InputTextV3, InputTextV4, CheckOptionItemV1 } from 'Components/ShcInput';

import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';

import { TableQueueLineHeader, TableQueueLineItem, AddressInputV1,
Instructions_businessName, Instructions_phoneNumber, Instructions_businessBrandWords, Instructions_businessBrandImages,
} from 'Components/ShcAdminModulesWorkQueues';

import { PlainTextTable } from 'Components/ShcTables';



//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { updateWorkQueueItemStatus, getWorkQueueItem, saveWorkQueueItem, processWorkQueueItem, 
  apiDataUpdate, reduxDataUpdate, } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';






class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    
    this.onAddressInputChange = this.onAddressInputChange.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.saveWorkQueueItem = this.saveWorkQueueItem.bind(this);
    this.processWorkQueueItem = this.processWorkQueueItem.bind(this);

    this.onDataUpdate = this.onDataUpdate.bind(this);

    this.handleSelectInputChange_itemStatus = this.handleSelectInputChange_itemStatus.bind(this);

    //S3 Upload
    this.documentSave = this.documentSave.bind(this);

    this.state = {
      workQueueItemId: this.props.match.params.workQueueItem,

      //S3 Uploads
      receiptData: '',
      companyData: '',
      businessNameHolderData: '',
      businessNameDetailData: '',

      //Postal Address Details
      country: '',
      attention: '',
      companyName: '',
      suiteUnitApt: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      state: '',
      postcode: '',

      //Business-Name
      businessNameData: {},


      //Phone Verification Status
      phoneVerifyStatus_providedCode: this.props.phoneVerifyStatus_providedCode,
      phoneVerifyStatus_agentDeclined: this.props.phoneVerifyStatus_agentDeclined,
      phoneVerifyStatus_phoneNumberIncorrect: this.props.phoneVerifyStatus_phoneNumberIncorrect,
      phoneVerifyStatus_ivrOptionsIncorrect: this.props.phoneVerifyStatus_ivrOptionsIncorrect,
      phoneVerifyStatus_waitTimeTooLong: this.props.phoneVerifyStatus_waitTimeTooLong,

      //Business Name Verification Status
      businessNameVerifyStatus_purchasedCompanyRecords: this.props.businessNameVerifyStatus_purchasedCompanyRecords,
      businessNameVerifyStatus_referredCompanyRecords: this.props.businessNameVerifyStatus_referredCompanyRecords,
      businessNameVerifyStatus_uploadedReceiptAndDocuments: this.props.businessNameVerifyStatus_uploadedReceiptAndDocuments,
      businessNameVerifyStatus_postedVerificationLetter: this.props.businessNameVerifyStatus_postedVerificationLetter,
      businessNameVerifyStatus_unableToFindBusinessDocuments: this.props.businessNameVerifyStatus_unableToFindBusinessDocuments,
  



      apiTimerCollection : [],  //List of active timers. (MANDATORY)

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      return {
      };
    }
    return null;
  }

  async componentDidMount(props) {

    const apiActivityId = "getWorkQueueItem";
    const apiUi = { apiActivityId };
    const data = {
      id: this.props.match.params.workQueueItem,
    };
    this.props.getWorkQueueItem({ data, apiUi });
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  componentWillUnmount() {
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  handleSelectInputChange_itemStatus(evt) {
    //Automatically update the job (so others know what the status of the job is)

    console.log("==== handleSelectInputChange_itemStatus ====");
    //API Call, Update state, update redux
    let value = evt.value;

    let receivedUpdateObj = {
      name: 'manualVerifications.$.status',
      identifiers: [{_id: this.state.workQueueItemId}],
      value: value,
      stateIdentifier: "AdminWork",
    }
    

    console.log("receivedUpdateObj");
    console.log(receivedUpdateObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);

    this.props.reduxDataUpdate({ recordsForUpdate });

    let data = {
      id: this.state.workQueueItemId,
      status: value,
    }

    this.props.updateWorkQueueItemStatus({data})


    /* AUTO SAVE NOT SETUP YET
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    */



  }

  saveWorkQueueItem() {
    //Collate all the information

    let postalFormat = '';
    if (this.state.country === 'Australia') {
      postalFormat = 'Australia';
    }



    let data = {
      workQueueItemId: this.state.workQueueItemId,
      businessId: this.props.businessId,
      identityId: this.props.identityId,
      verificationType: this.props.verificationType,
      businessDataRegion: this.props.businessDataRegion,


      //S3 Uploads
      receiptData: this.state.receiptData,
      companyData: this.state.companyData,
      businessNameHolderData: this.state.businessNameHolderData,
      businessNameDetailData: this.state.businessNameDetailData,

      //Postal Address Details
      postalFormat,

      country: this.state.country,
      attention: this.state.attention,
      companyName: this.state.companyName,
      suiteUnitApt: this.state.suiteUnitApt,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      suburb: this.state.suburb,
      state: this.state.state,
      postcode: this.state.postcode,

      //Business-Name
      businessNameData: this.state.businessNameData,


      //Phone Verification Status
      phoneVerifyStatus_providedCode: this.state.phoneVerifyStatus_providedCode,
      phoneVerifyStatus_agentDeclined: this.state.phoneVerifyStatus_agentDeclined,
      phoneVerifyStatus_phoneNumberIncorrect: this.state.phoneVerifyStatus_phoneNumberIncorrect,
      phoneVerifyStatus_ivrOptionsIncorrect: this.state.phoneVerifyStatus_ivrOptionsIncorrect,
      phoneVerifyStatus_waitTimeTooLong: this.state.phoneVerifyStatus_waitTimeTooLong,

      //Business Name Verification Status
      businessNameVerifyStatus_purchasedCompanyRecords: this.state.businessNameVerifyStatus_purchasedCompanyRecords,
      businessNameVerifyStatus_referredCompanyRecords: this.state.businessNameVerifyStatus_referredCompanyRecords,
      businessNameVerifyStatus_uploadedReceiptAndDocuments: this.state.businessNameVerifyStatus_uploadedReceiptAndDocuments,
      businessNameVerifyStatus_postedVerificationLetter: this.state.businessNameVerifyStatus_postedVerificationLetter,
      businessNameVerifyStatus_unableToFindBusinessDocuments: this.state.businessNameVerifyStatus_unableToFindBusinessDocuments,
  


    }


    //Save to API endpoint
    this.props.saveWorkQueueItem({data});

  }

  
  processWorkQueueItem() {
    //Collate all the information
    let data = {
      workQueueItemId: this.state.workQueueItemId,
      businessId: this.props.businessId,
      identityId: this.props.identityId,
    }

    //Save to API endpoint
    this.props.processWorkQueueItem({data});

  }


  onAddressInputChange(inputKeyValue, evt) {
    console.log("onAddressInputChange");
    console.log(inputKeyValue);
    console.log(evt);

    //If country is AU, then...
    this.setState(inputKeyValue);
  }

  onDataUpdate(data) {
    console.log("onDataUpdate");
    console.log(data);

    let value = data.value;
    let dataType = '';
    try {
      dataType = data.identifiers.name;
    } catch (e) {}

    console.log("dataType");
    console.log(dataType);
    
    console.log(value);
    console.log(this.state.businessNameData);
    console.log(JSON.stringify(this.state.businessNameData));

    if (dataType !== undefined && dataType !== null && dataType !== '') {
      let businessNameData = this.state.businessNameData;
      businessNameData[dataType] = value;
      if (dataType === 'abn') {
        this.setState({businessNameData});
      } else if (dataType === 'acn') {
        this.setState({"businessNameData.acn": value});
      } else if (dataType === 'holder') {
        this.setState({"businessNameData.holder": value});
      }
    } else {
      console.log('dataType is not set');
    }
   
  }
  




  documentSave(data, evt) {
    console.log("documentSave");
    console.log(data);


    if (data.uploaderId === 'businessNamePurchaseReceipt') {
      let receiptData = {
        documentType: 'receipt',
        bucketName: data.bucketName,
        filename: data.filename,
        path: '',
        url: `https://${data.bucketName}.s3-ap-southeast-2.amazonaws.com/${data.filename}-purchaseReceipt`,
      }
      console.log(JSON.stringify(receiptData));
      this.setState({
        receiptData: receiptData,
      });
    } else if (data.uploaderId === 'businessNameCompanyDocument') {
      let companyData = {
        documentType: 'registerDocument',
        bucketName: data.bucketName,
        filename: data.filename,
        path: '',
        url: `https://${data.bucketName}.s3-ap-southeast-2.amazonaws.com/${data.filename}-companyDocument`,
      }
      console.log(JSON.stringify(companyData));
      this.setState({
        companyData: companyData,
      });
    } else if (data.uploaderId === 'businessNameHolderDocument') {
      let businessNameHolderData = {
        documentType: 'registerDocument',
        bucketName: data.bucketName,
        filename: data.filename,
        path: '',
        url: `https://${data.bucketName}.s3-ap-southeast-2.amazonaws.com/${data.filename}-businessNameHolderDocument`,
      }
      console.log(JSON.stringify(businessNameHolderData));
      this.setState({
        businessNameHolderData: businessNameHolderData,
      });
    } else if (data.uploaderId === 'businessNameDetailDocument') {
      let businessNameDetailData = {
        documentType: 'registerDocument',
        bucketName: data.bucketName,
        filename: data.filename,
        path: '',
        url: `https://${data.bucketName}.s3-ap-southeast-2.amazonaws.com/${data.filename}-businessNameDetailDocument`,
      }
      console.log(JSON.stringify(businessNameDetailData));
      this.setState({
        businessNameDetailData: businessNameDetailData,
      });
    }



  }


  render() {
    const { userBusinessRoutePermissions,
    id, businessId, identityId, type, businessDataRegion, status, } = this.props;


    let itemStatusOptionList = [
      {
        value: 'open',
        label: 'Open',
      },
      {
        value: 'inProgress',
        label: 'In Progress',
      },
      {
        value: 'closed',
        label: 'Closed',
      },
    ];


    let abn = '';
    try {
      abn = this.state.businessNameData.abn;
    } catch (e) {}
    let acn = '';
    try {
      acn = this.state.businessNameData.acn;
    } catch (e) {}
    let holder = '';
    try {
      holder = this.state.businessNameData.holder;
    } catch (e) {}
    

    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText=""
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="admin"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>


            <div className={classNames({ aaa: true, aaa: true })}>
              <InputCard headlineText={`${this.state.workQueueItemId} - Status: ${this.props.status}`} 
              headlineSubText={this.props.createdAt}>


                <PlainTextTable
                title="Business Name"
                value={this.props.businessName} />

                <PlainTextTable
                title="Business Id"
                value={this.props.businessId} />

                <PlainTextTable
                title="Identity Id"
                value={this.props.identityId} />

                <PlainTextTable
                title="Verification Type"
                value={this.props.verificationType} />

                <PlainTextTable
                title="Business Data"
                value={this.props.businessDataRegion} />

                <PlainTextTable
                title="Active Subscription"
                value={this.props.activeSubscription} />

                <PlainTextTable
                title="Business Country"
                value={this.props.accountCountry} />

              

              

              </InputCard>


              <InputCard headlineText="" 
              headlineSubText="">

                <div className={classNames({ flex: true,})}>
                  <div className={classNames({ minWidth350: true })}>
                    <Select options={itemStatusOptionList} 
                    onChange={this.handleSelectInputChange_itemStatus} 
                    value={itemStatusOptionList.find(option => option.value === status)}
                    />
                  </div>
                </div>

                


              </InputCard>

              {this.props.verificationType === 'business-name' && (
                <div>
                  <InputCard headlineText={`Verification - ${this.props.verificationType}`} 
                  headlineSubText="">

                    <PlainTextTable
                    title="Registry"
                    value={this.props.businessNameRegistry} />

                    <PlainTextTable
                    title="Identity"
                    value={this.props.identity} />

                    <PlainTextTable
                    title="Verification Status"
                    value={this.props.verificationStatus} />


                  </InputCard>

                  <InputCard headlineText="Instructions" headlineSubText="Business Name verification instructions">
                    
                    <Instructions_businessName 
                    registryCountry=""
                    registry={this.props.businessNameRegistry}
                    identity={this.props.identity}
                    />
  
                    Note: This will create a postal document addressed to the business with the verification codes.
                    
                  </InputCard>

                  <InputCard headlineText="Documents" headlineSubText="">
    
                    <S3UploaderV1
                    uploaderId="businessNamePurchaseReceipt"
                    buttonText="Upload Receipt"
                    type="purchaseReceipt"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    onSave={this.documentSave}
                    />

                    <S3UploaderV1
                    uploaderId="businessNameCompanyDocument"
                    buttonText="Upload Company Document"
                    type="companyDocument"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    onSave={this.documentSave}
                    />

                    <S3UploaderV1
                    uploaderId="businessNameDetailDocument"
                    buttonText="Upload Business Name Details Document"
                    type="businessNameDetailDocument"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    onSave={this.documentSave}
                    />

                    <S3UploaderV1
                    uploaderId="businessNameHolderDocument"
                    buttonText="Upload Business Name Holder Document"
                    type="businessNameHolderDocument"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    onSave={this.documentSave}
                    />




                  </InputCard>

                  <InputCard headlineText="Postal Address" headlineSubText="Enter the postal address that appears on the company registered document">
                    <TextStyle size="h5">Important! The address entered must match the Registry
                    document exactly. </TextStyle><br/>


                    <AddressInputV1
                    businessId={this.props.businessId}
                    identityId="2222"
                    onInputChange={this.onAddressInputChange}

                    />

                    <hr/>
                    The address we will be sending to:<br/><br/>
                    ATTN: {this.state.attention}<br/>
                    {this.state.companyName}<br/> 
                    {this.state.suiteUnitApt} {this.state.addressLine1} <br/>
                    {this.state.addressLine2} <br/>
                    {this.state.suburb} {this.state.state} {this.state.postcode} <br/>
                    {this.state.country} <br/>

                  </InputCard>

                  <InputCard headlineText="Additional Company Information" 
                  headlineSubText="Enter in additional information found on the company document">
                    <InputCardItem headlineText="ABN" headlineSubText="Australian Business Number" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={{'name': 'abn'}}
                        stateIdentifier=""
                        label='' 
                        value={abn}
                        onChangeInputTextHandler={this.onDataUpdate} 
                      />
                    </InputCardItem>

                    <InputCardItem headlineText="ACN" headlineSubText="Australian Company Number" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={{'name': 'acn'}}
                        stateIdentifier=""
                        label='' 
                        value={acn}
                        onChangeInputTextHandler={this.onDataUpdate}  
                      />
                    </InputCardItem>

                    <InputCardItem headlineText="Holders Name" headlineSubText="This is the business name holders details. e.g. Guard Point Pty Ltd" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={{'name': 'holder'}}
                        stateIdentifier=""
                        label='' 
                        value={holder}
                        onChangeInputTextHandler={this.onDataUpdate} 
                      />
                    </InputCardItem>


                    

                  </InputCard>



                  <InputCard headlineText="Verification Status" headlineSubText="">
  
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`purchasedCompanyRecords`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_purchasedCompanyRecords}
                      businessId={this.props.businessId}
                      headlineText="Purchased new company records."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`referredCompanyRecords`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_referredCompanyRecords}
                      businessId={this.props.businessId}
                      headlineText="Referred to existing company records."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`uploadedReceiptAndDocuments`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_uploadedReceiptAndDocuments}
                      businessId={this.props.businessId}
                      headlineText="Uploaded receipt and company documents."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`postedVerificationLetter`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_postedVerificationLetter}
                      businessId={this.props.businessId}
                      headlineText="Posted verification letter."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`unableToFindBusinessDocuments`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_unableToFindBusinessDocuments}
                      businessId={this.props.businessId}
                      headlineText="Unable to find business documents."
                    />

                  </InputCard>



                  <InputCard headlineText="Complete Activity" 
                    headlineSubText="">
                    <TextStyle size="h5">
                      
                    </TextStyle><br/>



          
                    <div className={classNames({ flex: true, })}>
                      <StandardApiBtn
                        apiActivityId="saveWorkData"
                        icon=""
                        iconLocation=""
                        text="Save"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info"
                        handleClick={this.saveWorkQueueItem}
                      />

                      <StandardApiBtn
                        apiActivityId="sendVerificationLetter"
                        icon=""
                        iconLocation=""
                        text="Send Verification Letter"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info"
                        handleClick={this.processWorkQueueItem}
                      />

                      <StandardApiBtn
                        apiActivityId="saveWorkDataAndClose"
                        icon=""
                        iconLocation=""
                        text="Save & Close"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info"
                        handleClick={this.saveWorkQueueItem}
                      />
                    </div>


                  </InputCard>


                </div>
              )}
              

              {this.props.verificationType === 'business-brand-words' && (
                <div>
                  <InputCard headlineText={`Verification - ${this.props.verificationType}`} 
                  headlineSubText="">

                    <PlainTextTable
                    title="Registry"
                    value={this.props.businessNameRegistry} />

                    <PlainTextTable
                    title="Identity"
                    value={this.props.identity} />

                    <PlainTextTable
                    title="Verification Status"
                    value={this.props.verificationStatus} />


                  </InputCard>

                  <InputCard headlineText="Instructions" headlineSubText="Business Name verification instructions">
                    
                    <Instructions_businessBrandWords 
                    registryCountry=""
                    registry={this.props.businessNameRegistry}
                    identity={this.props.identity}
                    />
  
                    Note: This will create a postal document addressed to the business with the verification codes.
                    
                  </InputCard>

                  <InputCard headlineText="Documents" headlineSubText="">
    
                    <S3UploaderV1
                    uploaderId="businessBrandWordsPurchaseReceipt"
                    buttonText="Upload Receipt"
                    type="purchaseReceipt"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    />

                    <S3UploaderV1
                    uploaderId="businessBrandWordsCompanyDocument"
                    buttonText="Upload Company Document"
                    type="companyDocument"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    />




                  </InputCard>

                  <InputCard headlineText="Postal Address" headlineSubText="Enter the postal address that appears on the company registered document">
                    <TextStyle size="h5">Important! The address entered must match the Registry
                    document exactly. </TextStyle><br/>


                    <AddressInputV1
                    businessId="1111"
                    identityId="2222"
                    onInputChange={this.onAddressInputChange}

                    />

                    <hr/>
                    The address we will be sending to:<br/><br/>
                    ATTN: {this.state.attention}<br/>
                    {this.state.companyName}<br/> 
                    {this.state.suiteUnitApt} {this.state.addressLine1} <br/>
                    {this.state.addressLine2} <br/>
                    {this.state.suburb} {this.state.state} {this.state.postcode} <br/>
                    {this.state.country} <br/>

                  </InputCard>

                  <InputCard headlineText="Additional Company Information" 
                  headlineSubText="Enter in additional information found on the company document">
                    <InputCardItem headlineText="ABN" headlineSubText="Australian Business Number" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={[{'_id': this.state.businessId}]}
                        stateIdentifier=""
                        label='' 
                        //value={this.props.businessIdentity.businessProfile.businessName}
                        //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                      />
                    </InputCardItem>

                    <InputCardItem headlineText="ACN" headlineSubText="Australian Company Number" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={[{'_id': this.state.businessId}]}
                        stateIdentifier=""
                        label='' 
                        //value={this.props.businessIdentity.businessProfile.businessName}
                        //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                      />
                    </InputCardItem>

                    <InputCardItem headlineText="Holders Name" headlineSubText="This is the business name holders details. e.g. Guard Point Pty Ltd" additionalText="">
                      <InputTextV3
                        id="" 
                        name="" 
                        identifiers={[{'_id': this.state.businessId}]}
                        stateIdentifier=""
                        label='' 
                        //value={this.props.businessIdentity.businessProfile.businessName}
                        //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                      />
                    </InputCardItem>


                    

                  </InputCard>

                  <InputCard headlineText="Complete Activity" 
                    headlineSubText="">
                    <TextStyle size="h5">
                      <ol>
                        <li>Confirm the address is exactly what was on the Document</li>
                        <li>Confirm both receipt and document have been uploaded</li>
                        <li>Save the Record</li>
                        <li>Send the letter to the business</li>
                      </ol>  
                    </TextStyle><br/>





                  </InputCard>


                </div>
              )}

              {this.props.verificationType === 'business-brand-image' && (
                <div>
                  <InputCard headlineText={`Verification - ${this.props.verificationType}`} 
                  headlineSubText="">

                    <PlainTextTable
                    title="Registry"
                    value={this.props.businessNameRegistry} />

                    <PlainTextTable
                    title="Identity"
                    value={this.props.identity} />

                    <PlainTextTable
                    title="Verification Status"
                    value={this.props.verificationStatus} />


                  </InputCard>

                  <InputCard headlineText="Instructions" headlineSubText="Business Name verification instructions">
                    
                    <Instructions_businessBrandImages 
                    registryCountry=""
                    registry={this.props.businessNameRegistry}
                    identity={this.props.identity}
                    />
  
                    Note: This will create a postal document addressed to the business with the verification codes.
                    
                  </InputCard>

                  <InputCard headlineText="Documents" headlineSubText="">
    
                    <S3UploaderV1
                    uploaderId="businessNamePurchaseReceipt"
                    buttonText="Upload Receipt"
                    type="purchaseReceipt"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    />

                    <S3UploaderV1
                    uploaderId="businessNameCompanyDocument"
                    buttonText="Upload Company Document"
                    type="companyDocument"
                    bucketName="guardpointdocverificationrecords01"
                    s3SigningUrl="https://u9sz257n6b.execute-api.us-east-1.amazonaws.com/dev/storage/v1/business-names/access-url"
                    businessId={this.props.businessId}
                    businessName={this.props.businessName}
                    businessCountry={this.props.businessDataRegion}
                    />




                  </InputCard>

                  <InputCard headlineText="Postal Address" headlineSubText="Enter the postal address that appears on the company registered document">
                    <TextStyle size="h5">Important! The address entered must match the Registry
                    document exactly. </TextStyle><br/>


                    <AddressInputV1
                    businessId="1111"
                    identityId="2222"
                    onInputChange={this.onAddressInputChange}

                    />

                    <hr/>
                    The address we will be sending to:<br/><br/>
                    ATTN: {this.state.attention}<br/>
                    {this.state.companyName}<br/> 
                    {this.state.suiteUnitApt} {this.state.addressLine1} <br/>
                    {this.state.addressLine2} <br/>
                    {this.state.suburb} {this.state.state} {this.state.postcode} <br/>
                    {this.state.country} <br/>

                  </InputCard>

                  

                  <InputCard headlineText="Verification Status" headlineSubText="">
  
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`purchasedCompanyRecords`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_purchasedCompanyRecords}
                      businessId={this.props.businessId}
                      headlineText="Purchased new company records."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`referredCompanyRecords`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_referredCompanyRecords}
                      businessId={this.props.businessId}
                      headlineText="Referred to existing company records."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`uploadedReceiptAndDocuments`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_uploadedReceiptAndDocuments}
                      businessId={this.props.businessId}
                      headlineText="Uploaded receipt and company documents."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`postedVerificationLetter`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_postedVerificationLetter}
                      businessId={this.props.businessId}
                      headlineText="Posted verification letter."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`unableToFindBusinessDocuments`}
                      checkboxSelectedState={this.props.businessNameVerifyStatus_unableToFindBusinessDocuments}
                      businessId={this.props.businessId}
                      headlineText="Unable to find business documents."
                    />

                  </InputCard>



                  <InputCard headlineText="Complete Activity" 
                    headlineSubText="">
                    <TextStyle size="h5">
                      <ol>
                        <li>Confirm the address is exactly what was on the Document</li>
                        <li>Confirm both receipt and document have been uploaded</li>
                        <li>Save the Record</li>
                        <li>Send the letter to the business</li>
                      </ol>  
                    </TextStyle><br/>






                  </InputCard>


                </div>
              )}




              {this.props.verificationType === 'phone' && (
                <div>
                  <InputCard headlineText={`Verification - ${this.props.verificationType}`} 
                  headlineSubText="">

                    <PlainTextTable
                    title="Country"
                    value="" />

                    <PlainTextTable
                    title="Phone Number"
                    value={this.props.identity} />

                    <PlainTextTable
                    title="Has IVR"
                    value={this.props.phoneHasIVR} />

                    <PlainTextTable
                    title="IVR Selections"
                    value={this.props.phoneIvrMenuSelection} />

                    <PlainTextTable
                    title="Has Queue"
                    value={this.props.phoneHasQueue} />

                    
                    <PlainTextTable
                    title="Verification Status"
                    value={this.props.verificationStatus} />


                  </InputCard>

                  <InputCard headlineText="Instructions" headlineSubText="Phone number verification instructions">
                    
                    <Instructions_phoneNumber 
                    phoneNumber={this.props.identity}
                    phoneHasIVR={this.props.phoneHasIVR}
                    phoneIvrMenuSelection={this.props.phoneIvrMenuSelection}
                    phoneHasQueue={this.props.phoneHasQueue}
                    
                    />
 
                  </InputCard>

                  <InputCard headlineText="Verification Status" headlineSubText="">
  
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`providedCode`}
                      checkboxSelectedState={this.props.phoneVerifyStatus_providedCode}
                      businessId={this.props.businessId}
                      headlineText="Provided verification code to agent."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`agentDeclined`}
                      checkboxSelectedState={this.props.phoneVerifyStatus_agentDeclined}
                      businessId={this.props.businessId}
                      headlineText="Did not provide verification code to agent. Agent declined."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`phoneNumberIncorrect`}
                      checkboxSelectedState={this.props.phoneVerifyStatus_phoneNumberIncorrect}
                      businessId={this.props.businessId}
                      headlineText="Unable to provide verification code. Phone number incorrect."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`ivrOptionsIncorrect`}
                      checkboxSelectedState={this.props.phoneVerifyStatus_ivrOptionsIncorrect}
                      businessId={this.props.businessId}
                      headlineText="Unable to provide verification code. IVR operations incorrect."
                    />
                    <CheckOptionItemV1
                      checkboxOptionsGroup={`adminVerification_${this.props.businessId}_${this.props.identityId}`}
                      checkboxOptionsId={`waitTimeTooLong`}
                      checkboxSelectedState={this.props.phoneVerifyStatus_waitTimeTooLong}
                      businessId={this.props.businessId}
                      headlineText="Unable to provide verification code. Wait time too long."
                    />

                  </InputCard>

                  

                  

                  <InputCard headlineText="Complete Activity" 
                    headlineSubText="">
                    <TextStyle size="h5">
                      <ol>
                        <li>Ensure phone call has been made.</li>
                        <li>Ensure all fields have been updated.</li>
                        <li>Save the Record</li>
                        <li>Close the Record</li>
                      </ol>  
                    </TextStyle><br/>



                    <div className={classNames({ flex: true, })}>
                      <StandardApiBtn
                        apiActivityId="saveWorkData"
                        icon=""
                        iconLocation=""
                        text="Save"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info"
                        handleClick={this.saveWorkQueueItem}
                      />

                      <StandardApiBtn
                        apiActivityId="saveWorkDataAndClose"
                        icon=""
                        iconLocation=""
                        text="Save & Close"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info"
                        handleClick={this.saveWorkQueueItem}
                      />

                    </div>
                   


                  </InputCard>


                </div>
              )}


              

            </div>


            <br/><br/>


          
          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="businessIdentitiesAddItem"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add an Identity"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Identities</TextStyle><br/>
              <TextStyle size="h4">Public identifiers such as website, email, phone number.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
              Identities are used to create business profiles. A business profile is made up of
              multiple identites such as a website, email, phone number. Each identity needs to be
              verified before it can be added to a profile. Business profiles are used as the main
              contact card that a customer will see. This is your brand.
              </TextStyle>
              <br/>
            </div>
            <div className={classNames({ abc: true })}>
              Please select an identitiy you would like to add:<br/>

              <div className={classNames({ flex: true })}>

                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Email"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick=""
                />
                
              </div>
              <div className={classNames({ flex: true })}>
                
                
              </div>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('businessIdentitiesAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {

  let thisWorkQueueItem = ownProps.match.params.workQueueItem;
  let workQueueItems = state.AdminWork.manualVerifications;
  let workQueueItem = '';
  try {
    for (let x = 0; x < workQueueItems.length; x++) {
      if (workQueueItems[x].id === thisWorkQueueItem) {
        workQueueItem = workQueueItems[x];
      }
    }
  } catch (e) {}

  let businessId = '';
  try {
    businessId = workQueueItem.businessId;
  } catch (e) {}
  let identityId = '';
  try {
    identityId = workQueueItem.identityId;
  } catch (e) {}
  let verificationType = '';
  try {
    verificationType = workQueueItem.verificationType;
  } catch (e) {}
  let businessDataRegion = '';
  try {
    businessDataRegion = workQueueItem.businessDataRegion;
  } catch (e) {}
  let status = '';
  try {
    status = workQueueItem.status;
  } catch (e) {}
  let createdAt = '';
  try {
    createdAt = workQueueItem.createdAt;
  } catch (e) {}

  let businessData = '';
  try {
    businessData = workQueueItem.data;
  } catch (e) {}
  
  let businessName = '';
  try {
    businessName = businessData.businessName;
  } catch (e) {}
  let activeSubscription = '';
  try {
    activeSubscription = businessData.activeSubscription;
  } catch (e) {}
  let businessContacts = '';
  try {
    businessContacts = businessData.businessContacts;
  } catch (e) {}
  let accountCountry = '';
  try {
    accountCountry = businessData.accountCountry;
  } catch (e) {}
  let accountTimezone = '';
  try {
    accountTimezone = businessData.accountTimezone;
  } catch (e) {}
  //IDENTITY DETAILS
  let businessIdentity = '';
  try {
    businessIdentity = businessData.businessIdentity;
  } catch (e) {}
  let identity = '';
  try {
    identity = businessIdentity.identity;
  } catch (e) {}
  let verificationStatus = '';
  try {
    verificationStatus = businessIdentity.verificationStatus;
  } catch (e) {}

  //BUSINESS NAME
  
  let businessNameRegistry = '';
  if (verificationType === 'business-name') {
    try {
      businessNameRegistry = businessIdentity.businessNameAdditionalData.businessNameRegistry;
    } catch (e) {}
  }

  //PHONE
  let phoneHasIVR = '';
  let phoneIvrMenuSelection = '';
  let phoneHasQueue = '';
  if (verificationType === 'phone') {
    try {
      phoneHasIVR = businessIdentity.phoneHasIVR;
    } catch (e) {}
    try {
      phoneIvrMenuSelection = businessIdentity.phoneIvrMenuSelection;
    } catch (e) {}
    try {
      phoneHasQueue = businessIdentity.phoneHasQueue;
    } catch (e) {}
  }




  // Get the Phone Checkbox selections
  let checkboxPhoneGroupId = `adminVerification_${businessId}_${identityId}`;
  console.log("checkboxPhoneGroupId");
  console.log(checkboxPhoneGroupId);

  let phoneVerifyStatus_providedCode = false;
  let phoneVerifyStatus_agentDeclined = false;
  let phoneVerifyStatus_phoneNumberIncorrect = false;
  let phoneVerifyStatus_ivrOptionsIncorrect = false;
  let phoneVerifyStatus_waitTimeTooLong = false;

  try {
    for (let x = 0; x < state.UI.uiCheckboxOptions.length; x++) {
      if (state.UI.uiCheckboxOptions[x].checkboxOptionsGroup === checkboxPhoneGroupId) {
        if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "providedCode") {
          phoneVerifyStatus_providedCode = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "agentDeclined") {
          phoneVerifyStatus_agentDeclined = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "phoneNumberIncorrect") {
          phoneVerifyStatus_phoneNumberIncorrect = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "ivrOptionsIncorrect") {
          phoneVerifyStatus_ivrOptionsIncorrect = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "waitTimeTooLong") {
          phoneVerifyStatus_waitTimeTooLong = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        }
      }
    }
  } catch (e) {}



  let businessNameVerifyStatus_purchasedCompanyRecords = false;
  let businessNameVerifyStatus_referredCompanyRecords = false;
  let businessNameVerifyStatus_uploadedReceiptAndDocuments = false;
  let businessNameVerifyStatus_postedVerificationLetter = false;
  let businessNameVerifyStatus_unableToFindBusinessDocuments = false;

  try {
    for (let x = 0; x < state.UI.uiCheckboxOptions.length; x++) {
      if (state.UI.uiCheckboxOptions[x].checkboxOptionsGroup === checkboxPhoneGroupId) {
        if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "purchasedCompanyRecords") {
          businessNameVerifyStatus_purchasedCompanyRecords = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "referredCompanyRecords") {
          businessNameVerifyStatus_referredCompanyRecords = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "uploadedReceiptAndDocuments") {
          businessNameVerifyStatus_uploadedReceiptAndDocuments = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "postedVerificationLetter") {
          businessNameVerifyStatus_postedVerificationLetter = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        } else if (state.UI.uiCheckboxOptions[x].checkboxOptionsId === "unableToFindBusinessDocuments") {
          businessNameVerifyStatus_unableToFindBusinessDocuments = state.UI.uiCheckboxOptions[x].checkboxSelectedState;
        }
      }
    }
  } catch (e) {}





 
  return {
    accessToken: state.Auth.apiAccessToken,
    workQueueItem,
    businessId,
    identityId,
    verificationType,
    businessDataRegion,
    status,
    createdAt,
    businessData,
    businessName,
    activeSubscription,
    businessIdentity,
    businessContacts,
    accountCountry,
    accountTimezone,
    identity,
    verificationStatus,

    //Business Name
    businessNameRegistry,

    //Phone
    phoneHasIVR,
    phoneIvrMenuSelection,
    phoneHasQueue,

    //Phone Verification Status
    phoneVerifyStatus_providedCode,
    phoneVerifyStatus_agentDeclined,
    phoneVerifyStatus_phoneNumberIncorrect,
    phoneVerifyStatus_ivrOptionsIncorrect,
    phoneVerifyStatus_waitTimeTooLong,
  
    //Business Name Verificaiton Status
    businessNameVerifyStatus_purchasedCompanyRecords,
    businessNameVerifyStatus_referredCompanyRecords,
    businessNameVerifyStatus_uploadedReceiptAndDocuments,
    businessNameVerifyStatus_postedVerificationLetter,
    businessNameVerifyStatus_unableToFindBusinessDocuments,




  };

};

const mapDispatchToProps = {
  updateWorkQueueItemStatus,
  getWorkQueueItem,
  saveWorkQueueItem,
  processWorkQueueItem,

  reduxDataUpdate,
  apiDataUpdate,

};
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);