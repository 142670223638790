import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';
// Components
import { StandardBtn } from 'Components/ShcButtons';
import { OverlayOnScreen } from 'Components/ShcPopups';
// Actions
import { toggleOverlayOnScreen } from 'Actions';

class UsersRolesAddNewDisplay extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleAddNewUserRequest = this.handleAddNewUserRequest.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.handleAddNewUserConfirmed = this.handleAddNewUserConfirmed.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    };

    this.handleChangeTextFirstName = this.handleChangeTextFirstName.bind(this);
    this.handleChangeTextLastName = this.handleChangeTextLastName.bind(this);
    this.handleChangeTextEmail = this.handleChangeTextEmail.bind(this);
  }

  handleChangeTextFirstName(e) {
    this.setState({firstName: e.target.value});
    console.log(this.state.firstName);
  }
  handleChangeTextLastName(e) {
    this.setState({lastName: e.target.value});
    console.log(this.state.lastName);
  }
  handleChangeTextEmail(e) {
    this.setState({email: e.target.value});
  }

  handleAddNewUserRequest() {
    console.log('button to add a new user');
    const data = {};
    this.props.toggleOverlayOnScreen('open', 'UserRolesPermissionsAddNewUser', data);
  }

  closeOverlayOnScreen = (overlayId) => {
    console.log("close the overlay screen");
    this.props.toggleOverlayOnScreen('close', overlayId);
  }

  handleAddNewUserConfirmed() {
    console.log("add new user button confirmed");
    const {firstName, lastName, email} = this.state;
    console.log(firstName+" "+lastName+" "+email);
  }




  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {

    return (
      <div>
        <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
          <div>
            Max 5 user accounts

          </div>
          <div>
            <StandardBtn
              icon=""
              iconLocation=""
              text="Add New User"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary"
              handleClick={this.handleAddNewUserRequest}
            />
          </div>
        </div>

        <OverlayOnScreen
          id="UserRolesPermissionsAddNewUser"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add New User"
          classes=""
        >
          <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionContainer: true })}>
            <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle: true })}>
              Add a New User
            </div>
            


            <div className={classNames({
              flex: true,
              flexJustifySpaceBetween: true,
              flexAlignBaseline: true,
            })}
            >
              <div className={classNames({
                  flexMargin5: true,
                  fullwidth: true,
                  shcMaterialTextFieldBaselinePadding: true,
                })}
                >
                <TextField
                  className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                  id="firstname"
                  label="First Name"
                  margin="normal"
                  fullWidth
                  value={this.state.firstName}
                  onChange={this.handleChangeTextFirstName}
                  
                  />
              </div>
            </div>

            <div className={classNames({
              flex: true,
              flexJustifySpaceBetween: true,
              flexAlignBaseline: true,
            })}
            >
              <div className={classNames({
                  flexMargin5: true,
                  fullwidth: true,
                  shcMaterialTextFieldBaselinePadding: true,
                })}
                >
                <TextField
                  className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                  id="lastname"
                  label="Last Name"
                  margin="normal"
                  fullWidth
                  onChange={this.handleChangeTextLastName}
                  
                  />
              </div>
            </div>

            <div className={classNames({
                flex: true,
                flexJustifySpaceBetween: true,
                flexAlignBaseline: true,
              })}
            >
              <div className={classNames({
                  flexMargin5: true,
                  fullwidth: true,
                  shcMaterialTextFieldBaselinePadding: true,
                })}
                >
                <TextField
                  className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                  id="email"
                  label="Email Address"
                  margin="normal"
                  fullWidth
                  onChange={this.handleChangeTextEmail}
                  
                  />
              </div>
            </div>


            <br /><br />
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('UserRolesPermissionsAddNewUser')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Add New User"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={() => this.handleAddNewUserConfirmed(this.props.overlayOnScreenData.id)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreen>
      </div>
    );
  }
}

UsersRolesAddNewDisplay.propTypes = {

};
UsersRolesAddNewDisplay.defaultProps = {
};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,
});

const mapDispatchToProps = {
  toggleOverlayOnScreen,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UsersRolesAddNewDisplay);
