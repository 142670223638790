import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiIcon } from 'Components/ShcButtons';

import { IdentityViewV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class VerifiedIdentityView extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,



    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeItem(identityId, profileId, evt) {
    //console.log("removeItem");

    //console.log("identityId");
    //console.log(identityId);
    //console.log("profileId");
    //console.log(profileId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      identityId,
      profileId,
    }

    this.props.onRemoveItem({item, apiUi});
  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }


  render() {
    const { profileId, data, onRemoveItem, 
      stateIdentifier, identifiers, id, name, onChangeHandler
     } = this.props;

     //Get the reference if from this individual identity
     let identityReferenceId = "";
     try { identityReferenceId = data.identityReferenceId; } catch(e) {}
     
     //Locate all the details about this identity from the source.
     let businessProfileAssociatedIdentities = {};
      try { 
        for(let y=0; y < this.props.businessIdentity.businessIdentities.length; y++) {
          if ( this.props.businessIdentity.businessIdentities[y]._id === identityReferenceId) {
            businessProfileAssociatedIdentities = {
              _id: this.props.businessIdentity.businessIdentities[y]._id,
              type: this.props.businessIdentity.businessIdentities[y].type,
              identity: this.props.businessIdentity.businessIdentities[y].identity,
            };
          }
          
        }
      } catch(e) {
        console.error("Unable to process profile identities.");
      }


    let headlineText="";
    let headlineSubText="";
    let additionalText="";
    if (businessProfileAssociatedIdentities.type === "email") {
      headlineText="EMAIL ADDRESS";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "phone") {
      headlineText="PHONE NUMBER";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "mobile") {
      headlineText="MOBILE NUMBER";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "web") {
      headlineText="WEBSITE";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "website") {
      headlineText="WEBSITE";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "postal") {
      headlineText="POSTAL ADDRESS";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "business-name") {
      headlineText="BUSINESS NAME";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "business-brand-image") {
      headlineText="BRAND IMAGE";
      headlineSubText="";
    }
    if (businessProfileAssociatedIdentities.type === "business-brand-words") {
      headlineText="BRAND WORDS";
      headlineSubText="";
    }

    let inputDataType = "";
    if (["email", "phone", "mobile", "web", "postal", "business-name", "business-brand-image", "business-brand-words"].indexOf(businessProfileAssociatedIdentities.type) > -1) {
      inputDataType = businessProfileAssociatedIdentities.type;
    }

    return (
      <div className={classNames({ ShcModulesIdentityPrimaryContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ ShcModulesIdentityRemoveItem: true })}>

              <StandardApiIcon
                apiActivityId={`RemoveUserIdentity_${identityReferenceId}_onprofile_${profileId}`}
                icon="fas fa-times"
                classes=""
                handleClick={this.removeItem.bind(this, identityReferenceId, profileId)}
              />

            </div>
          </div>

          <div className={classNames({ flex: true, cursorPointer: true })}
          onClick={this.collapseContainer}>

          <InputCardItem
            headlineText={headlineText}
            headlineSubText={headlineSubText}
            additionalText={additionalText}
            customStyle="slim"
          >
            <IdentityViewV1
              stateIdentifier={stateIdentifier}
              dataType={inputDataType}  //regular expression input validation // email, phone, number, alphaNoSpace, alphaSpace, text, custom
              data={businessProfileAssociatedIdentities}
            />
          </InputCardItem>
    
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

VerifiedIdentityView.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
  };

};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifiedIdentityView);
