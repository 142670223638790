import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
// Actions
import { toggleOverlayOnScreenResponsive, 
  userNotificationsIdentitiesVerifyNow,
  
  
  businessIdentitiesVerifyNow, userNotificationsIdentitiesCodeVerifyNow, businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateWebsiteCode, businessIdentitiesGenerateWebsiteCode,
  userIdentitiesGenerateToken } from 'Actions';


// Functions
import {
  validateEmail, validateDomain, validateNumbersOnly,
  validateAlphaNoSpacesOnly, validateAlphaWithSpacesOnly,
  validateTextOnly, validateAlphaNumericNoSpacesOnly, validatePhoneNumbersOnly,
} from 'Helpers/Functions';


class IdentityInputV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);
    this.handleIdentityVerificationWebsite = this.handleIdentityVerificationWebsite.bind(this);

    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);

    this.handleUserDataRefresh = this.handleUserDataRefresh.bind(this);
    this.handleIdentityVerificationWebsiteCodeRequest = this.handleIdentityVerificationWebsiteCodeRequest.bind(this);

    


    this.collapseContainer = this.collapseContainer.bind(this);

    this.state = {
      identity: this.props.data,
      value: this.props.value,
      identityType: this.props.dataType,
      verificationCode: '',
      verificationCodeInputErrors: false,

      identityVerified: false,
      identityNotVerified: true,
      identityReVerify: false,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: false,

      identityLabel: this.props.data.identityLabel,    //Alternative name/ label for this identity
      identityLabelInputErrors: false,


      adjustIdentity: false,         //If the user changes an identity that was already verified, asked them if they really want to...

      verifiedStateValue: '',       //If the value is verified, keep a copy here. If the user changes, then changes back, don't have to re-verify - as long as we didn't update the DB value!
          //maybe have a confirmation that you are changing the identity - re-verification is required - proceed or cancel?


      verified: false,
      collapse: true,     //start collapsed

      data: this.props.data,

      webTokenCode: '', //web token for verification of a website

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - IdentityInputV2');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let identityVerified = prevState.identityVerified;
    let identityNotVerified = prevState.identityNotVerified;
    let identityReVerify = prevState.identityReVerify;

    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.


    let updateState = false;
    if (nextProps.data !== prevState.data) {
      updateState = true;
    }

    if (nextProps.value !== prevState.value) {
      updateState = true;
    }
    if (nextProps.data.identityLabel !== prevState.identityLabel) {
      updateState = true;
    }
    /*
    let identityNotificationEnabled = false;
    if (nextProps.data.notifyThisDevice === true || nextProps.data.notifyThisDevice === false) {
      if (nextProps.data.notifyThisDevice !== prevState.identityNotificationEnabled) {
        identityNotificationEnabled = nextProps.data.notifyThisDevice;
        updateState = true;
      }
      updateState = true;
    } else {
      identityNotificationEnabled = !prevState.identityNotificationEnabled;
    }
    */


    

    //was NOT verified and NOW verified = collapse.
    try {
      if (prevState.identityVerified === false && nextProps.data.identityVerificationStatus === true) {
        collapse=true;
      }
    } catch(e) {
      //no change to collapse
    }

    try {
      if (nextProps.data.identityVerificationStatus === true) {
        updateState = true;
  
        identityVerified = true;
        identityNotVerified = false;
        identityReVerify = false; //check
      } else {
        updateState = true;
        identityVerified = false;
        identityNotVerified = true;
        identityReVerify = false; //check
      }
    } catch(e) {
      //IF there is an error getting the verification status, mark it as not verified.
      updateState = true;
      identityVerified = false;
      identityNotVerified = true;
      identityReVerify = false; //check
    }
  
    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      //console.log('data');
      //console.log(nextProps.data);
      return {
        value: nextProps.value,
        identityLabel: nextProps.data.identityLabel,
        //identityNotificationEnabled: identityNotificationEnabled,
        data: nextProps.data,
        identityVerified,
        identityNotVerified,
        identityReVerify,
        collapse,
      };

    }
    return null;
  }

  handleUserDataRefresh() {
    //console.log("REFRESH USER DATA");
  }
  
  handleIdentityVerificationWebsiteCodeRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationWebsiteCodeRequest');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });     //userNotificationsIdentitiesVerifyNow
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        businessId: this.props.businessId,
      };
      this.props.userIdentitiesGenerateToken({ data, apiUi });      //businessIdentitiesVerifyNow
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }
  

  handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};

    data = {
      identity,
    };
    this.props.userNotificationsIdentitiesVerifyNow({ data, apiUi });

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }

  handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identity, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    const data = {
      identity,
      verificationCode: verificationCode,
    };
    this.props.userNotificationsIdentitiesCodeVerifyNow({ data, apiUi });
    
  }

  handleIdentityVerificationWebsite(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationWebsite');
    const { identity } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity,
      };
      this.props.userIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesWebsiteVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }
  
  

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let errors = false;
    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        if (validatePhoneNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(this.props.value);
        if (validateNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(this.props.value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);
    //console.log(typeof (identifiers));
    /* RETURN THE CALLER
    Attempt to return the caller the following data as an object.
    This will be used to push into an array for a state update.
      {
        name: 'test5.nested2.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
      }
    */

    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        //console.log(value);
        if (validatePhoneNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(value);
        if (validateNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }

    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value,
    };

    //console.log('=== onInputChange Return ===');
    //console.log(returnObj);


    this.props.onChangeInputTextHandler(returnObj);
    let textStyle;
    if (this.props.feature === 'AsYouType') {
      textStyle = new AsYouType().input(event.target.value);
      this.setState({
        value: textStyle,
        identityInputErrors: errors,
      });
    } else {
      this.setState({
        value: event.target.value,
        identityInputErrors: errors,
      });
    }
  }


  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    //console.log(this.state.identityNotificationEnabled);
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userNotifications.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log('=== onChangeInputTextHandler API Call ===');
      //console.log(returnObj);
  
      this.props.onChangeInputTextHandler(returnObj);

    }); //End setState Callback

  }
  

  


  render() {
    const {
      data,
      id, apiEndpoint, businessId, name, label, identifiers, stateIdentifier, challenge,
      value, onChangeInputTextHandler, maxlength,
      dataType,
      pattern,
      dataErrorMessage,

      feature = '',


    } = this.props;


    let identityLocked = false;
    try {
      identityLocked = data.identityLocked;
    } catch(e) {}


    return (
      <div>
        <div className={classNames({ ShcIdentityInput_inputSection: true, ShcIdentityInput_expandedContent: !this.state.collapse })}>
          <div>
            <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
            ShcIdentityInput_textInput_root: true,
          })}
          >
            <div className={classNames({
              ShcIdentityInput_textInput_child: true,
              ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
              ShcIdentityInput_textInput_notificationsEnabled_icon: 
              this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
              ShcIdentityInput_textInput_notificationsDisabled_icon: 
              this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
            })}
            
            >
                {this.state.identityNotificationOptionsAvailable &&
                  <div className={classNames({ 
                    ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
                  })}>
                    <div className={classNames({ 
                      ShcIdentityInput_textInput_notificationsClickOverlay: true,
                    })}
                    onClick={this.toggleIdentityNotification}>

                    </div>
                  </div>
                }
                {identityLocked === true ?
                <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                  ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                  ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
                  {this.state.value}
                </div>
                :
                <input
                  {...this.props}
                  id={id}
                  label={label}
                  margin="normal"
                  fullWidth
                  name={name}
                  value={this.state.value}
                  identifiers={identifiers}
                  stateIdentifier={stateIdentifier}
                  onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
                  type="text"
                  className={classNames({ ShcIdentityInput_textInput: true, 
                    ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                    ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                  })}
                />
                }
                
                
              </div>
            </div>

            
          </div>

          <div className={classNames({
            ShcIdentityInput_helpText: true,
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
          })}
          >
            <div className={classNames({ flex: true })}>

            
              {this.state.identityVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityNotVerified && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityReVerify && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }

              {this.state.adjustIdentity && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>CHANGE IDENTITY? </div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Change this Verified Identity"
                    ></i>
                  </div>
                  <div>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Yes, Save Changes"
                    tooltip=""
                    action=""
                    classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                    handleClick={this.collapseContainer}
                  />
                  </div>
                </div>
              }


            </div>
            <div>

            {this.state.collapse ?
              <StandardBtn
                icon="fas fa-angle-down"
                iconLocation="right"
                text="MORE"
                tooltip=""
                action=""
                classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                handleClick={this.collapseContainer}
              />
            :
              <StandardBtn
                icon="fas fa-angle-up"
                iconLocation="right"
                text="LESS"
                tooltip=""
                action=""
                classes="shctext-slim shctext-primary shctext-rounded shctext-fullwidth"
                handleClick={this.collapseContainer}
              />
            } 

              
            </div>
          </div>

          {this.state.collapse ?
            null
          : (
            <div>
              <div className={classNames({ ShcIdentityInput_dropdownContainer: true })}>
               

                {this.state.identityInputErrors && 
                  <div>
                    <TextStyle size="h4" weight="bold">
                      <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                      ></i>
                      INVALID INPUT
                    </TextStyle>
                    <TextStyle size="h5" weight="">
                      The input you entered is incorrect or does not meet the required input criteria. Please correct
                      before attempting to verify.
                      <br/>
                    </TextStyle>
                    <br/>
                  </div>
                }
                
                <TextStyle size="h4" weight="">
                  Add a Label
                </TextStyle>
                <div>
                  <div>
                    <div className={classNames({
                      flex: true,
                      flexJustifySpaceBetween: true,
                      flexAlignBaseline: true,
                      ShcIdentityInput_textInput_root: true,
                    })}
                    >
                      <div className={classNames({
                        ShcIdentityInput_textInput_child: true,
                        ShcIdentityInput_textInput_errors_icon: this.state.identityLabelInputErrors,
                      })}
                      >


                        <input
                          {...this.props}
                          id={id}
                          label={label}
                          margin="normal"
                          fullWidth
                          name="userNotifications.$.identityLabel"
                          value={this.state.identityLabel}
                          identifiers={identifiers}
                          stateIdentifier={stateIdentifier}
                          onChange={this.handleChange.bind(this, identifiers, stateIdentifier)}
                          type="text"
                          className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.identityLabelInputErrors })}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.identityLabelInputErrors && 
                    <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                      <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                      <div>
                        <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                          aria-label="Invalid Input"
                        ></i>
                      </div>
                    </div>
                  }
                </div>

                <br/>

                <div className={classNames({ flex: true })}>
                  <StandardApiBtn
                    apiActivityId="UserNotificationsVerifyIdentity"
                    icon=""
                    iconLocation=""
                    text="Send Verification Code"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleIdentityVerificationRequest}
                  />

                  <StandardApiBtn
                    apiActivityId="UserNotificationsTestIdentity"
                    icon=""
                    iconLocation=""
                    text="Test App Notifications"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleUserDataRefresh}
                  />

                </div>


                
                <div>
                  <br/>
                  <div className={classNames({ ShcIdentityInput_dropdownSecurityBox: true })}>
                    <TextStyle size="h4" weight="bold">
                      <i className={`fas fa-theater-masks ${classNames({ShcIdentityInput_VerifyIcon: true, padR10: true})}`}
                      aria-label="Verification Input"
                      ></i>
                    VERIFICATION
                    </TextStyle>
                    
                    <TextStyle size="h5" weight="">
                    Please enter in the 6 character code. Note, this code is only valid for 15 minutes.
                    </TextStyle>
                    <br/>



                    <div>
                      <div className={classNames({
                        flex: true,
                        flexJustifySpaceBetween: true,
                        flexAlignBaseline: true,
                        ShcIdentityInput_textInput_root: true,
                      })}
                      >
                        <div className={classNames({
                          ShcIdentityInput_textInput_child: true,
                          ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                        })}
                        >
                          <input
                            {...this.props}
                            id={id}
                            label={label}
                            margin="normal"
                            fullWidth
                            name={name}
                            value={this.state.verificationCode}
                            identifiers={identifiers}
                            stateIdentifier={stateIdentifier}
                            onChange={this.handleIdentityVerificationCodeInput}
                            type="text"
                            className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.verificationCodeInputErrors && 
                      <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                        <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                        <div>
                          <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                            aria-label="Invalid Input"
                          ></i>
                        </div>
                      </div>
                    }

                    <br/>
                      <StandardApiBtn
                        apiActivityId="UserNotificationIdentityVerifyConfirmationCode"
                        icon=""
                        iconLocation=""
                        text="Verify Mobile App"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-small shcbtn-primary"
                        handleClick={this.handleIdentityVerificationCodeSubmit}
                      />
                  </div>
                </div>
                


                

              </div>
            </div>
          )}

        </div>

      </div>
    );
  }
}

IdentityInputV2.propTypes = {

};
IdentityInputV2.defaultProps = {

};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,

});

const mapDispatchToProps = {
  toggleOverlayOnScreenResponsive,
  userNotificationsIdentitiesVerifyNow, 
  businessIdentitiesVerifyNow, 
  userNotificationsIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateWebsiteCode,
  businessIdentitiesGenerateWebsiteCode,
  userIdentitiesGenerateToken,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityInputV2);