import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import Countdown from 'react-countdown-now';
import Timer from 'react-compound-timer';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';

// Actions


class InfoCopyLabelPhone extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.state = {
      token: this.props.token,
      copySuccess: '',
      tokenExpired: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps - InfoCopyLabelPhone');
    console.log(`${nextProps.token} !== ${prevState.token}`);
    if (nextProps.token !== prevState.token) {
      return {
        tokenExpired: false,
        token: nextProps.token,
      };
    }

    return null;
  }


  componentDidMount() {
    //console.log('InfoCopyLabelPhone');
    let utcDateNow = new Date(Date.now());
    utcDateNow = Date.parse(utcDateNow.toUTCString());
    let timeLeftFortoken = this.state.token.tokenExpiry - utcDateNow;

    if (this.state.tokenExpired===false && timeLeftFortoken<=0) {
      console.log("set expired token to state");
      this.setState({tokenExpired: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //if (this.props !== prevProps) {
    //  console.log('componentDidUpdate - InfoCopyLabelPhone');
    //}
  }

  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.props.token.tokenId;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({ copySuccess: 'Copied' });
      setTimeout(function(){
      this.setState({ copySuccess: '' });
    }.bind(this),3000);
    
  };

  handleExpiredToken() {
    console.log('Token has expired - remove/grey out token - they may be in the middle of talking to the person');
  }


  render() {

    const { subHeading, token } = this.props;

    var newExpiryDateUtc = new Date(token.tokenExpiry);
    console.log("=== newExpiryDateUtc ===");
    console.log(newExpiryDateUtc);
    
    let utcDateNow = new Date(Date.now());
    utcDateNow = Date.parse(utcDateNow.toUTCString());

    console.log("=== utcDateNow ===");
    console.log(utcDateNow);
    
    let timeLeftFortoken = newExpiryDateUtc - utcDateNow;
    
    //console.log(utcDateNow);
    //console.log(utcDateNow.toTimeString());
    //console.log(utcDateNow.toUTCString());
    
    //console.log("utcDateNow");
    //console.log(utcDateNow);
    //console.log("tokenExpiry");
    //console.log(token.tokenExpiry);
    
    console.log("timeLeftFortoken");
    console.log(timeLeftFortoken);

    
    return (
      <div>
        <div className={classNames({ ShcTextInfoCopyLabelContainer: true })}>
          <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>

            <div className={classNames({ ShcTextInfoCopyLabelSubHeaderLeft: true })}>
              {subHeading}
            </div>
            <div className={classNames({ ShcTextInfoCopyLabelSubHeaderRight: true })}>
              {this.props.token &&
                <Timer
                  key={this.state.token.tokenId}
                  initialTime={timeLeftFortoken}    //{token.tokenVoiceExpirySeconds}    //300000 (5mins)
                  direction="backward"
                  checkpoints={[
                      {
                          time: 4000,
                          callback: () => console.log('4 seconds to go.'),
                      },
                      {
                          time: 1000,
                          callback: () => console.log('1 second to go'),
                      },
                      {
                          time: 0,
                          callback: () => {
                            console.log("token expired");
                            this.setState({tokenExpired: true})
                          },
                      },
                  ]}
              >
                  {(reset) => (
                    <React.Fragment>
                      <div>
                        <Timer.Minutes /> mins&nbsp;
                        <Timer.Seconds /> secs 
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              }
            </div>
          </div>
          <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>
            <div id="connectManuallyCardPhoneTokenField" name="connectManuallyCardPhoneTokenField" 
              className={classNames({ ShcTextInfoCopyLabelContent: true, flexGrow: true, wordWrapBreak: true })}>
              {this.props.children}
            </div>
            <div
              className={classNames({ ShcTextInfoCopyLabelControls: true })}
              onClick={this.copyToClipboard}
            >
              <i className="far fa-clone" />
            </div>
          </div>
        </div>


        <div>

          <div>
            {this.state.copySuccess!=='' &&
            <MessageHighlight>
              Token copied to clipboard.

            </MessageHighlight>
            }

            {this.state.tokenExpired &&
            <MessageHighlight type="danger" key={this.props.token.tokenId}>
              This token has expired. The customer will be prompted not to accept it.

            </MessageHighlight>
            }
          </div>

        </div>
      </div>

    );
  }
}

InfoCopyLabelPhone.propTypes = {

};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InfoCopyLabelPhone);
