import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class RegistryBrandWordsInputFormV1_Template1 extends React.Component {
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this);

    this.state = { 
      apiTimerCollection : [],  //List of active timers. (MANDATORY)

      identityInputValue: this.props.identityInputValue,

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
  
  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      console.log(timerReferenceGroup);
      console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }
  

 
  render() {
    const { registry, onChange, identity,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, 
      identityInputValue,
    } = this.props;

    return (
      <div>

        <InputCardItem headlineText="Brand Words" headlineSubText="Type in exactly how the words are described on 
        the trade mark website.">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.identity" 
            name="identities.businesses.$.businessIdentities.$.identity" 
            identifiers={[{'_id': businessId}, {'_id': identityId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={this.state.identityInputValue}
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Brand Image Trade Mark Number" headlineSubText="Enter in the trade mark number for the registered brand image" 
        additionalText="We will associate the image recorded on the trade mark site as this identity">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.businessBrandWordsAdditionalData.tradeMarkReference" 
            name="identities.businesses.$.businessIdentities.$.businessBrandWordsAdditionalData.tradeMarkReference" 
            identifiers={[{'_id': businessId}, {'_id': identityId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={this.state.identityInputValue}
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />

        </InputCardItem>

        
        <div className={classNames({ flex: true})}>
          <TextStyle size="h5" weight="normal">
            Verification of a business name requires that the countries business registy has the business name and address recorded. If we are unable 
            to access the business address as part of the business name lookup we will not be able to verify your business name. 
          </TextStyle>
        </div>


      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RegistryBrandWordsInputFormV1_Template1);
