import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class TroubleshootingAdminWindow extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    // const { } = this.props;

    const displaySection = () => {
      if (this.props.isDevAdmin) {
        return (
          <div className={classNames({
            text_h5: true,
            color_primary_dark: true,
            pad10: true,
          })}
          >
            {this.props.children}
          </div>
        );
      }
      return null;

    };


    return (
      <div>
        {displaySection}
      </div>
    );
  }
}

TroubleshootingAdminWindow.propTypes = {

};
TroubleshootingAdminWindow.defaultProps = {
};


const mapStateToProps = state => ({
  isDevAdmin: state.Auth.isDevAdmin,
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TroubleshootingAdminWindow);
