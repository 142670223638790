import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2, InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { apiDataUpdate, reduxDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';


class StepUserIdentity extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)

    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); //new dynamic
    this.onChangeDynamicInputTextHandler_PhoneNumbers = this.onChangeDynamicInputTextHandler_PhoneNumbers.bind(this);

    this.state = {
      apiTimerCollection : [],  //List of active timers. (MANDATORY)


      userIdentity: this.props.value,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps - StepBusinessWebsite");
    //We are comparing the inbound value (websiteStep) which is an object with the local state paramaters

    console.log(JSON.stringify(nextProps.value) +" !== "+ JSON.stringify(prevState.userIdentity));
    if (nextProps.value !== undefined && nextProps.value !== prevState.userIdentity) {
      return { userIdentity: nextProps.value };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - StepUserIdentity");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform up of state for StepUserIdentity");
      this.setState({
        userIdentity: this.props.value,
      });
    }
  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    console.log("===============================");
    console.log("SEND TO PARENT: inputTextChange");

    //Update local state (do not update fields that are shared with parent)
    this.setState({
      [evt.target.name]: evt.target.value,
    });

    let updateData= {
      field: evt.target.name,
      value: evt.target.value,
    }
    this.props.onChangeStepInputHandler(this.props.id, this.state.userIdentity, updateData);
    
  }

  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    console.log("onChangeDynamicInputTextHandler");
    

    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);

    console.log(JSON.stringify(recordsForUpdate));
        
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */

    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      console.log(timerReferenceGroup);
      console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }

  onChangeDynamicInputTextHandler_PhoneNumbers(receivedUpdateObj) {
    console.log("onChangeDynamicInputTextHandler_PhoneNumbers");
    
    console.log("FIRST CONFIRM IF THE NUMBER IF LEGITIMATE BEFORE TRIGGERING UPDATE");

    let allowAPIProcessingOfInput = false;
    let textInput = receivedUpdateObj.value;
    const phoneNumber = parsePhoneNumberFromString(textInput);
    if (phoneNumber) {
      if (phoneNumber.isValid() === true) {
        console.log("valid phone number");
        let phoneInternationalFormat = textInput.replace(/\s/g, '');  //Replace spaces: e.g. +61 4 9999 1111 > +61499991111
        console.log(phoneInternationalFormat);
        console.log(phoneNumber.country);
        console.log(phoneNumber.number);
        console.log(phoneNumber.isValid());
        console.log(phoneNumber.getType());

        allowAPIProcessingOfInput = true; //This record is now allowed to be send to the DB for recording.

      } else {
        console.log("invalid phone number");
      }
    }

    console.log("passed phone number checks");

    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);

    console.log(JSON.stringify(recordsForUpdate));
        
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */

    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    if (allowAPIProcessingOfInput) {    //Only allow storing of this once the number is in the correct format.
      for(let x=0; x<recordsForUpdate.length; x++) {
        //Get the "Name" which represents the dotPath for the update.
        //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
        let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
        console.log(timerReferenceGroup);
        console.log(JSON.stringify(recordsForUpdate[x]));
        //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
        this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
      }
    }
  }

  


  render() {
    const { id, name, onChangeStepInputHandler, value } = this.props;

    let activityType = this.state.userIdentity.type;
    let inputCardHeadlineText = "";
    let identityHeadlineText = "";
    let identityAdditionalText = "";
    if (activityType==="website") {
      inputCardHeadlineText="Website Address";
      identityHeadlineText = "Website";
      identityAdditionalText = "Format: https://";
    } else if (activityType==="email") {
      inputCardHeadlineText="Email Address";
      identityHeadlineText = "Email";
      identityAdditionalText = "Your business primary email address used for customer contact.";

    } else if (activityType==="phone") {
      inputCardHeadlineText="Phone Number";
      identityHeadlineText = "Phone Number";
      identityAdditionalText = "Your business primary phone number used for customer contact.";
    } else {
      inputCardHeadlineText="Unknown Identity Type"
    }


    const TextInput_Identity_Generic = () => {
      return (
        <InputCardItem headlineText={identityHeadlineText} headlineSubText="" additionalText={identityAdditionalText}>
          <InputTextV3 
            id="userIdentities.$.identity"
            name="userIdentities.$.identity" 
            identifiers={[{'_id': this.state.userIdentity._id}]}
            stateIdentifier="User"
            label='' 
            value={this.state.userIdentity.identity} 
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>
      );
    };

    const TextInput_Identity_Phone = () => {
      return (
        <InputCardItem headlineText="Phone Number" headlineSubText="" additionalText="We will never share these details without your consent.">
          <InputTextV3 feature="AsYouType"
            id="userIdentities.$.identity"
            name="userIdentities.$.identity" 
            identifiers={[{'_id': this.state.userIdentity._id}]}
            stateIdentifier="User"
            label='' 
            value={this.state.userIdentity.identity} 
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler_PhoneNumbers} 
          />
        </InputCardItem>
      );
    };

    const TextInput_Identity = () => {
      if (activityType=="phone") {
        return (
          <TextInput_Identity_Phone/>
        );
      } else {
        return (
          <TextInput_Identity_Generic/>
        );
      }
    };

  
    return (
      <InputCard headlineText={inputCardHeadlineText} headlineSubText="">

        <InputCardItem headlineText="First Name" headlineSubText="" additionalText="">
          <InputTextV3 
            id="userIdentities.$.identityFirstName" 
            name="userIdentities.$.identityFirstName" 
            identifiers={[{'_id': this.state.userIdentity._id}]}
            stateIdentifier="User"
            label='' 
            value={this.state.userIdentity.identityFirstName} 
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Last Name" headlineSubText="" additionalText="">
          <InputTextV3 
            id="userIdentities.$.identityLastName" 
            name="userIdentities.$.identityLastName" 
            identifiers={[{'_id': this.state.userIdentity._id}]}
            stateIdentifier="User"
            label='' 
            value={this.state.userIdentity.identityLastName} 
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <TextInput_Identity/>

        <InputCardItem headlineText="Label" headlineSubText="This is a private label you can attach to this identity." additionalText="Only you will see this.">
          <InputTextV3 
            id="userIdentities.$.identityLabel"
            name="userIdentities.$.identityLabel" 
            identifiers={[{'_id': this.state.userIdentity._id}]}
            stateIdentifier="User"
            label='' 
            value={this.state.userIdentity.identityLabel} 
            onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>


      </InputCard>
    );
  }
}

StepUserIdentity.propTypes = {
  
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  apiDataUpdate,
  reduxDataUpdate,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StepUserIdentity);

/*
 <div className={classNames({ flex: true, width200pix: true })} >
  {JSON.stringify(this.state.userIdentity)}
</div>


 <InputCardItem headlineText={identityHeadlineText} headlineSubText="" additionalText={identityAdditionalText}>
    <InputTextV2 id="identity" name="identity" label="" value={this.state.userIdentity.identity} 
    onChangeInputTextHandler={this.onChangeInputTextHandler} />
  </InputCardItem>

<InputCardItem headlineText="Label" headlineSubText="This is a private label you can attach to this identity." additionalText="Customers will not see this.">
    <InputTextV2 id="identityLabel" name="identityLabel" label="Private custom label" value={this.state.userIdentity.identityLabel} 
    onChangeInputTextHandler={this.onChangeInputTextHandler} />
  </InputCardItem>
*/