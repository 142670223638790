import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
//import {  } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class LinkCardV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - LinkCardV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  

  render() {
    const {
      businessId,
      headlineText,
      secondaryText,
    } = this.props;

    return (
      <div>
        <CardBasicV2
          padding="none"
          className={classNames({ contentPad10: false })}
        >
          <div
            className={classNames({ cursorPointer: true })}
            onClick={this.toggleSelectedItem}
          >

            <div className={classNames({ ShcRadioOptionCard_primaryText: true })}>
              {headlineText}
            </div>

            { secondaryText ?
              <div>
                <div className={classNames({ ShcRadioOptionCard_secondaryText: true })}>
                  {secondaryText}
                </div>
              </div>
            : null }

          </div>
        </CardBasicV2>
      </div>
    );
  }
}

LinkCardV1.propTypes = {

};
LinkCardV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
 
};

const mapDispatchToProps = {
  
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(LinkCardV1);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(LinkCardV1);
