import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// Routing
// import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { ShcSubAppBar, ShcSubSidebar } from './ShcSubHeaderComponents';


// Actions
// import {  } from 'Actions';





class ShcSubHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // <ShcParentSidebar/>

  render() {

 

    return (
      <div className={classNames({ shcSubHeaderContainer: true })}>
        <ShcSubAppBar />
        <div className={classNames({ width100p: true })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ShcSubHeader.propTypes = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ShcSubHeader);

/*
   const childContactMarginStyle = {
      width: '100%',
    };

<main style={childContactMarginStyle}>
  Props<br />
  {this.props.children}
</main>
*/