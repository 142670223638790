import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class TextStyle extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { size, weight } = this.props;


    const TextH1 = () => (
      <div className={classNames({
        text_h1: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH2 = () => (
      <div className={classNames({
        text_h2: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH3 = () => (
      <div className={classNames({
        text_h3: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH4 = () => (
      <div className={classNames({
        text_h4: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH5 = () => (
      <div className={classNames({
        text_h5: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH6 = () => (
      <div className={classNames({
        text_h6: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH7 = () => (
      <div className={classNames({
        text_h7: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH8 = () => (
      <div className={classNames({
        text_h8: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH9 = () => (
      <div className={classNames({
        text_h9: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    let headingStyle;
    if (size === 'h1') {
      headingStyle = <TextH1 />;
    } else if (size === 'h2') {
      headingStyle = <TextH2 />;
    } else if (size === 'h3') {
      headingStyle = <TextH3 />;
    } else if (size === 'h4') {
      headingStyle = <TextH4 />;
    } else if (size === 'h5') {
      headingStyle = <TextH5 />;
    } else if (size === 'h6') {
      headingStyle = <TextH6 />;
    } else if (size === 'h7') {
      headingStyle = <TextH7 />;
    } else if (size === 'h8') {
      headingStyle = <TextH8 />;
    } else if (size === 'h9') {
      headingStyle = <TextH9 />;
    }




    return (
      <div>
        {headingStyle}
      </div>
    );
  }
}

TextStyle.propTypes = {

};
TextStyle.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TextStyle);
