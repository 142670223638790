import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions

// Functions
import {
  validateEmail, validateDomain, validateNumbersOnly,
  validateAlphaNoSpacesOnly, validateAlphaWithSpacesOnly,
  validateTextOnly, validateAlphaNumericNoSpacesOnly, validatePhoneNumbersOnly,
} from 'Helpers/Functions';

const uuidv1 = require('uuid/v1');

class InputTextV3 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: this.props.value,
      dbValue: this.props.dbValue, // The value stored in the db
      lastChangeTransactionId: '',
      errors: false,
      // pattern: this.props.pattern,
      // maxlength: this.props.maxlength,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    let errors = false;
    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');
      //console.log(this.props.dataType);

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        if (validatePhoneNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(this.props.value);
        if (validateNumbersOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(this.props.value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(this.props.value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }
    this.setState({
      errors,
    });

  }


  handleChange(identifiers, stateIdentifier, fieldName, fieldRef, fieldStore, storeId, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;

    console.log('handleChange | event | inputTextV3');
    console.log(event);
    console.log(identifiers);
    console.log(stateIdentifier);
    console.log(name);
    console.log(`value: ${value}`);


    //console.log(identifiers);
    //console.log(stateIdentifier);
    //console.log(typeof (identifiers));
    /* RETURN THE CALLER
    Attempt to return the caller the following data as an object.
    This will be used to push into an array for a state update.
      {
        name: 'test5.nested2.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
      }
    */

    if (this.props.dataType != null && this.props.dataType != undefined) {
      //console.log('Data Input Assessment');

      // Options: email, phone, number, alphaNoSpace, alphaSpace, text, custom

      if (this.props.dataType === 'email') {
        //console.log('Case: Email');
        if (validateEmail(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'domain') {
        //console.log('Case: Domain');
        if (validateDomain(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'phone') {
        //console.log('Case: Phone');
        //console.log(value);
        if (validatePhoneNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'number') {
        //console.log('Case: Number');
        //console.log(value);
        if (validateNumbersOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaNoSpace') {
        //console.log('Case: alphaNoSpace');
        if (validateAlphaNoSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'alphaSpace') {
        //console.log('Case: alphaSpace');
        if (validateAlphaWithSpacesOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'text') {
        //console.log('Case: Text');
        if (validateTextOnly(value)) {
          errors = false;
        } else {
          errors = true;
        }
      } else if (this.props.dataType === 'custom') {
        //console.log('Case: Custom');
        if (this.props.pattern !== null && this.props.pattern !== undefined) {
          let isValidRegEx = true;
          try {
            new RegExp(this.props.pattern);
          } catch (e) {
            isValidRegEx = false;
          }
          // If the regex expression is valid, continue
          //console.log(`isValidRegEx: ${isValidRegEx}`);
          if (isValidRegEx) {
            const regExPattern = new RegExp(this.props.pattern);
            if (regExPattern.test(value)) {
              //console.log('false');
              errors = false;
            } else {
              //console.log('true');
              errors = true;
            }
          } else {
            //console.log('RegEx expression incorrect');
          }
        }
      }
    }

    const lastChangeTransactionId = uuidv1();

    
    console.log(`value (before additional features): ${value}`);

    // OTHER FEATURES
    let textStyle;
    let featurePhoneData = {};
    if (this.props.feature === 'AsYouType') {
      console.log('AsYouType feature');
      console.log(`value: ${value}`);
      textStyle = new AsYouType().input(value);
      console.log(`textStyle: ${textStyle}`);

      const phoneNumber = parsePhoneNumberFromString(value);
      console.log(`phoneNumber: ${phoneNumber}`);
      if (phoneNumber) {
        if (!phoneNumber.isValid()) {
          errors = true;
        } else {
          featurePhoneData = {
            country: phoneNumber.country,
            number: phoneNumber.number,
            type: phoneNumber.getType(),
            valid: phoneNumber.isValid(),
            formatInternational: phoneNumber.formatInternational(),
          };
        }
        console.log(`errors: ${errors}`);
        console.log(`featurePhoneData:`);
        console.log(featurePhoneData);
      } else {
        errors = true;
      }
      this.setState({
        value: textStyle,
        lastChangeTransactionId,
        errors,
      });
    } else {
      this.setState({
        value: value,
        lastChangeTransactionId,
        errors,
      });
    }

    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value,

      //NEW FIELDS
      fieldName, fieldRef, fieldStore, storeId,
      fieldValue: value,

      errors, // <-- Errors added on 2/08/19 for local component messaging on status
      lastChangeTransactionId,
    };

    //console.log('=== onInputChange Return ===');
    //console.log(returnObj);


    this.props.onChangeInputTextHandler(returnObj);


    /*
    //console.log("Regular Expression Matching Validatior");
    //console.log(this.state.pattern);
    //console.log(this.state.maxlength);
    //console.log(this.props.maxlength);
    let re = new RegExp(this.state.pattern, 'gi');
    let found = event.target.value.match(re);
    //console.log(found);
    //console.log("end regular expression matching");
    */
  }


  render() {
    const {
      id, name, label, identifiers, stateIdentifier,
      value, onChangeInputTextHandler, maxlength,
      dataType,
      pattern,
      dataErrorMessage,
      //NEW FIELDS
      fieldName = '', 
      fieldRef = '', 
      fieldStore = '', 
      storeId = '',

      feature = '',
    } = this.props;


    return (
      <div className={classNames({
        flex: true,
        flexJustifySpaceBetween: true,
        flexAlignBaseline: true,
        ShcInput_textInput_root: true,
      })}
      >
        <div className={classNames({
          ShcInput_textInput_child: true,
          ShcInput_textInput_errors_icon: this.state.errors,
        })}
        >
          <input
            {...this.props}
            id={id}
            label={label}
            margin="normal"
            fullWidth
            name={name}
            value={this.state.value}
            identifiers={identifiers}
            stateIdentifier={stateIdentifier}

            fieldName={fieldName}
            fieldRef={fieldRef}
            fieldStore={fieldStore}
            storeId={storeId}

            onChange={this.handleChange.bind(this, identifiers, stateIdentifier, fieldName, fieldRef, fieldStore, storeId)}
            type="text"
            maxlength="200"   //hardcoded max
            className={classNames({ ShcInput_textInput: true, ShcInput_textInput_errors: this.state.errors })}
          />
        </div>
      </div>
    );
  }
}

InputTextV3.propTypes = {

};
InputTextV3.defaultProps = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputTextV3);

