import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiIcon } from 'Components/ShcButtons';

//import { IdentityViewV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { SingleLineAccessKeyItem } from 'Components/ShcModulesApiManagement';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class ApiManagementKeyAdd extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,



    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  addItem(permissionName, evt) {
    //console.log("addItem");

    //console.log("permissionName");
    //console.log(permissionName);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      permissionName,
    }

    this.props.onAddItem({item, apiUi});
  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }


  render() {
    const { userId, businessId, permissionName, onRemoveItem,
      stateIdentifier, identifiers, id, name, onChangeHandler
     } = this.props;

    let headlineText=permissionName;
    let headlineSubText="";
    let additionalText="";
  

    return (
      <div className={classNames({ ShcAccountModulesChildContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ ShcModulesIdentityRemoveItem: true })}>

              <StandardApiIcon
                apiActivityId={`AddUserPermission_${permissionName}_fromUser_${userId}_onBusiness_${businessId}`}
                icon="far fa-plus-square"
                classes=""
                handleClick={this.addItem.bind(this, permissionName)}
              />

            </div>
          </div>

          <div className={classNames({ flex: true, cursorPointer: true })}
          onClick={this.collapseContainer}>

            <SingleLineAccessKeyItem
            headlineText={permissionName}
            referenceId="3284729384792834928434927324"
            />
    
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

ApiManagementKeyAdd.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiManagementKeyAdd);
