import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


import { VerifiedProfileInput } from 'Components/ShcModulesIdentity';

import { ApiLogLineHeader, ApiLogLineItem } from 'Components/ShcModulesReports';


// Actions
import { syncLogs } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.syncLogs = this.syncLogs.bind(this);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {


    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {

  }

  syncLogs(evt) {
    evt.preventDefault();
    //console.log('syncLogs');
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const data = {
      businessId: this.state.businessViewId,
    };
    this.props.syncLogs({ data, apiUi });

  }




  render() {
    const { userBusinessRoutePermissions } = this.props;

   
    function stripUnixMilliseconds(millisecondsUnixTimestamp) {
      return (millisecondsUnixTimestamp.slice(0, -3));
    }

    function userFilterOptions(object) {
      if (object.requestTime === 1567210620) {
        return object;
      } else {
        return object;
      }
    }

    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business/:routebusinessid/account"
        backTitle="Back to Account"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business/:routebusinessid/account"
                backTitle="Back to Account"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Reporting"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-reporting"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}

              <StandardApiBtn
                  apiActivityId="SyncLogs"
                  icon=""
                  iconLocation=""
                  text="Load Logs"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-small shcbtn-primary"
                  handleClick={this.syncLogs}
                />
            </div>
            

            <div className={classNames({ aaa: true, aaa: true })}>
              <InputCard headlineText="Logs" headlineSubText="">
                <ApiLogLineHeader />
                <div>

                  {
                  this.props.businessReportingLogs &&
                  this.props.businessReportingLogs.length > 0 ?
                  // Arrow function preserves "this"
                  this.props.businessReportingLogs.filter(userFilterOptions).sort((a, b) => b.requestTime - a.requestTime).map((item, i) => {

                    let id = '';
                    let status = '';
                    let method = '';
                    let url = '';
                    let time = '';
                    try {
                      id = item.id;
                    } catch(e) {}
                    try {
                      status = item.apiStatusCode;
                    } catch(e) {}
                    try {
                      method = item.requestMethod;
                    } catch(e) {}
                    try {
                      url = item.url;
                    } catch(e) {}
                    try {
                      //parse to an int (if this works, its a number)
                      time = parseInt(item.requestTime);
                      if (time.length >= 13) {
                        //time is in milliseconds, convert to seconds.
                        time = parseInt(stripUnixMilliseconds(time.toString()));
                      }
                    } catch(e) {
                      time = item.time;
                    }

                    let linkAddress = `/account/business/${this.state.businessViewId}/reporting/logs`;
                    try {
                      linkAddress = `/account/business/${this.state.businessViewId}/reporting/logs/${id}`;
                    } catch(e) {}

                    return (
                      <Link to={linkAddress} className={classNames({ textDecoration: true })}>
                        <ApiLogLineItem
                          statusCode={status}
                          method={method}
                          description={url}
                          datetime={time}
                        />
                      </Link>
                    )
                  }, this)
                  : null
                  }
                </div>
                
              </InputCard>

            </div>

          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  let businessReportingLogs = [];
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }


  try {
    businessReportingLogs = businessIdentity.businessReportingLogs;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business logs');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    businessReportingLogs: Object.assign([], businessReportingLogs),
    
    user: state.User,
  };

};

const mapDispatchToProps = {
   syncLogs,
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
