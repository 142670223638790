import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
//import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';
import Redirect from 'react-router-dom/Redirect';


// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem } from 'Components/ShcCards';

// import { askForPermissioToReceiveNotifications } from 'Components/ShcPushNotify/push-notification';


// Actions
import { loginMfaV2, removeMfaLoginRequired, refreshAccessToken, updateIsAuthenticatedStatus, userAuth, guardpointUserAuth, setuserlogout } from 'Actions';
import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function unixTimestampMillisecondsToSeconds(timestamp) {
  let num = timestamp;
  if (num.toString().length >=13) {
    //In milliseconds
    num = (num-(num%1000))/1000;
  }
  return num;
}

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      loginScreen: this.props.loginScreen,        //Main Login Screen
      mfaScreen: this.props.mfaScreen,         //MFA Password Screen
      redirectScreen: this.props.redirectScreen,    //Redirect Screen
      loginLoading: this.props.loginLoading,      //Transition state from LoginScreen
      mfaLoading: this.props.mfaLoading,        //Transition state from MFA screen

      //REDIRECTION CONTROL
      redirectToDashboard: false,     


      //loginLoading: false,          //Initial login loading for Firebase
      mfaSecurityRequired: false,   //Whther mfa login screen is required
      mfaSecurityCode: '',

      mfaRequiredFactors: [],

      user: null,
      emailUsername: '',
      password: '',
      isAuthenticated: this.props.isAuthenticated,
      errorCode: '',
      errorMessage: '',

      
    };

    this.handleMfaLogin = this.handleMfaLogin.bind(this);
    this.handleMfaCancel = this.handleMfaCancel.bind(this);
    //this.handleMfaSecurityCodeInput = this.handleMfaSecurityCodeInput.bind(this);

    this.handleEmailLoginClick = this.handleEmailLoginClick.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleEmailUsernameInput = this.handleEmailUsernameInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(`getDerivedStateFromProps`);
    console.log(nextProps);
    console.log(prevState);


    let updateState = false;

    let loginScreen = prevState.loginScreen;
    let mfaScreen = prevState.mfaScreen;
    let redirectScreen = prevState.redirectScreen;
    let loginLoading = prevState.loginLoading;
    let mfaLoading = prevState.mfaLoading;



    //Reset MFA screens if MFA is not required for this login
    if (prevState.mfaSecurityRequired === false && nextProps.mfaSecurityRequired === false) {
      updateState = true;
      mfaScreen = false;
      mfaLoading = false;
    }

    console.log(`loginLoading`);
    console.log(loginLoading);
    console.log(`nextProps.mfaSecurityRequired`);
    console.log(nextProps.mfaSecurityRequired);

    if (loginLoading === true && nextProps.mfaSecurityRequired === true) {
      console.warn('TRANSITION FROM LOGIN LOADING TO MFA SCREEN');
      updateState = true;
      loginLoading = false;
      mfaLoading = false;
      mfaScreen = true;
    }





    /*
    let loginLoadingValue = false;
    if (nextProps.loginLoading === undefined) {
      updateState = true;
      loginLoadingValue = false;
    }


    if (nextProps.loginLoading !== prevState.loginLoading && nextProps.loginLoading !== undefined) {
      console.log(`${nextProps.loginLoading} !== ${prevState.loginLoading}`);
      updateState = true;
      loginLoadingValue = nextProps.loginLoading;
    }

    


    if (prevState.mfaSecurityRequired === false && nextProps.mfaSecurityRequired === undefined &&
      nextProps.loginLoading === undefined && prevState.loginLoading === false
    ) {
      console.log("Initial login activity");
      console.log(`${nextProps.loginLoading} !== ${prevState.loginLoading}`);
      updateState = true;
      loginLoadingValue = nextProps.loginLoading;
    }

    
    console.log(`updateState: ${updateState}`);
    console.log(`loginLoadingValue: ${loginLoadingValue}`);
    */


    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      return {
        loginScreen,
        mfaScreen,
        redirectScreen,
        loginLoading,
        mfaLoading,

      };
    }
    return null;
    
  }


  async verifySecureRoute() {
    console.warn('====== Auth Route | VerifySecureRoute ======');
    ///// Get the current time
    const currentTimestamp = unixTimestampMillisecondsToSeconds(Date.now());
    //const currentTimestamp = currentDate.getTime();
    console.warn(`Route Authentication: Current: ${currentTimestamp}. AccessToken: ${this.props.apiAccessTokenExpiresOn}`);

    ///// Check - Is there an access token present
    if (this.props.apiAccessTokenExpiresOn == null || this.props.apiAccessTokenExpiresOn == undefined
      || this.props.apiRefreshTokenExpiresOn == null || this.props.apiRefreshTokenExpiresOn == undefined) {
        //There is no access tokens. Auth has failed, redirect the user to the login page
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
      return false;
    }

    ///// Check - Has the access token expired
    if (currentTimestamp >= this.props.apiAccessTokenExpiresOn) {
      console.warn(`Access Token has expired, attempt token refresh. Current: ${currentTimestamp} >= AccessToken: ${this.props.apiAccessTokenExpiresOn}`);
      
      ///// Check - Has the refresh token expired
      if (currentTimestamp >= this.props.apiRefreshTokenExpiresOn) {
        console.warn(`Refresh Token has expired. The user needs to log back in. Current: ${currentTimestamp} >= RefreshToken: ${this.props.apiRefreshTokenExpiresOn}`);
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
        return false;
      }
    } else {
      console.warn(`Route authorised. Access token valid.`);
      this.props.isAuthenticated = true;
      this.props.updateIsAuthenticatedStatus(true);
      return true;
    }
  }


  componentDidMount(props) {
    //console.log('Loading template.');

    this.props.removeMfaLoginRequired();

    if (this.props.isAuthenticated === true && this.verifySecureRoute()) {
      //window.location.href = '/account/dashboard';    //TEMP HOLD
      this.setState({
        redirectToDashboard: true,
      });
    }






    if (this.props.isAuthenticated === true) {
      // window.location.href = '/account/dashboard';
    }


  }

  componentDidUpdate() {
    if (this.props.isAuthenticated === true && this.verifySecureRoute()) {
      //console.log('Redirecting');
      //window.location.href = '/account/dashboard';    //TEMP HOLD
      this.setState({
        redirectToDashboard: true,
      });
    }
  }

  handleMfaSecurityCodeInput(itemId, evt) {
    console.log("itemId");
    console.log(itemId);
    console.log("evt");
    console.log(evt);

    let mfaRequiredFactors = this.state.mfaRequiredFactors;

    for (let x = 0; x < mfaRequiredFactors.length; x++) {
      if (mfaRequiredFactors[x].id === itemId) {
        mfaRequiredFactors[x].mfaCodeInput = evt.target.value;
      }
    }


    console.log("mfaRequiredFactors");
    console.log(mfaRequiredFactors);
    console.log(JSON.stringify(mfaRequiredFactors));




    //MFA code on input
    this.setState({ mfaRequiredFactors: mfaRequiredFactors });
  }

  async handleMfaLogin(evt) {
    console.log('mfa login');

    this.setState({
      loginScreen: false,
      loginLoading: false,
      mfaScreen: false,
      mfaLoading: true,
    });

    //send api request with all the data
    //console.log("handleMfaLogin");
    //console.log(`MFA CODE: ${this.state.mfaSecurityCode}`);

    //strip data not required for mfa login. 
    let mfaLoginData = [];
    for (let x = 0; x < this.state.mfaRequiredFactors.length; x++) {
      mfaLoginData.push({
        id: this.state.mfaRequiredFactors[x].id,
        mfaCodeInput: this.state.mfaRequiredFactors[x].mfaCodeInput,
      });
    }



    console.log('mfaLoginData');
    console.log(mfaLoginData);


    let data = {
      mfaLoginData: mfaLoginData,
    }

    this.setState({
      mfaSecurityCode: '',
    });

    let response = await this.props.loginMfaV2({data});
    console.log(`response from MFA call: ${response}`);

    if (response === false) {
      this.setState({
        loginScreen: true,
        loginLoading: false,
        mfaScreen: false,
        mfaLoading: false,
      });
    }

    
    

  }

  handleMfaCancel(evt) {
    //console.log("handleMfaCancel");
    this.props.removeMfaLoginRequired();
    this.setState({
      loginScreen: true,
      loginLoading: false,
      mfaScreen: false,
      mfaLoading: false,

    });
  }


  handleEmailLoginClick(e) {
    console.warn('INITIAL LOGIN REQUEST');
    //From the login screen, set the loading screen initially
    this.setState({ 
      loginScreen: false,
      loginLoading: true,
      //Reset
      mfaScreen: false,
      redirectScreen: false,
      mfaLoading: false,
      
    });

    console.warn(this.state.loginLoading);

    auth().signInWithEmailAndPassword(this.state.emailUsername.trim(), this.state.password)
    .then((user) => {
      this.setState({ user: user.user, loginLoading: true });
      //this.props.userAuthEmail({ user })
      this.props.guardpointUserAuth({ user })
      .then((response) => {
        console.log("guardpointUserAuth | response");
        console.log(response);

        let requiredFactors = [];
        try {
          requiredFactors = response.data.requiredFactors;
        } catch (e) {
          requiredFactors = [];
        }
        if (requiredFactors === undefined) {
          requiredFactors = [];
        }

        for (let x = 0; x < requiredFactors.length; x++) {
          requiredFactors[x].mfaCodeInput = '';
        }

        this.setState({
          mfaRequiredFactors: requiredFactors,
        });


      })
      .catch((error) => {
        console.warn('Initial auth successful. Second stage failure.');
        console.warn(error);
        this.setState({ 
          loginScreen: true,
          loginLoading: false,
        });
        toast.error('Error experienced during login.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      });
    })
    .catch((error) => {
    // Handle Errors here.
      this.setState({ 
        loginScreen: true,
        loginLoading: false,
      });
      console.log('handle the error');
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);

      if (errorCode === 'auth/invalid-email') {
        this.setState({ errorCode: 'auth/invalid-email', errorMessage: 'Invalid email' });
        toast.error('Invalid email address.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      } else if (errorCode === 'auth/wrong-password') {
        this.setState({ errorCode: 'auth/wrong-password', errorMessage: 'Wrong password' });
        toast.error('Incorrect Password.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      } else if (errorCode === 'auth/user-disabled') {
        this.setState({ errorCode: 'auth/user-disabled', errorMessage: 'Account disabled' });
        toast.error('Your account is disabled.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      } else if (errorCode === 'auth/user-not-found') {
        this.setState({ errorCode: 'auth/user-not-found', errorMessage: 'User not found' });
        toast.error('User not found.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      } else {
        console.log(errorMessage);
        this.setState({ errorCode: 'auth/other', errorMessage: 'An error has occured' });
        toast.error('An error has occured during login.', {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      }

    });
  console.log('end of login call');
  //
  }


  handleForgotPasswordClick(e) {
    console.log(`Forgot password process for ${this.state.emailUsername}`);
    if (this.state.emailUsername == '') {
      // Popup asking for email address.
      console.log('no email, popup and request user to enter email.');
      toast.error('No email found. Please enter your email in the form before requesting your password reset.', {
        closeOnClick: true,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        className: 'toastError',
        bodyClassName: 'grow-font-size',
        progressClassName: 'toastErrorProgress',
      });
    } else if (validateEmail(this.state.emailUsername)) {
      auth().sendPasswordResetEmail(this.state.emailUsername)
        .then((result) => {
          // Password reset email sent.
          toast.success('Password reset email sent.', {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastSuccess',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastSuccessProgress',
          });
        })
        .catch((error) => {
          console.log('Forgot password email send error.');
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);

          if (errorCode === 'auth/user-not-found') {
            toast.error('User not found', {
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              className: 'toastError',
              bodyClassName: 'grow-font-size',
              progressClassName: 'toastErrorProgress',
            });
          } else {
            toast.error('There was an error sending your password reset email. Please try again later.', {
              closeOnClick: true,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              className: 'toastError',
              bodyClassName: 'grow-font-size',
              progressClassName: 'toastErrorProgress',
            });
          }

        });

    } else {
      // Invalid email, notify to fix the issue.
      console.log('Forgot password invalid email.');
      toast.error('Invalid email address.', {
        closeOnClick: true,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        className: 'toastError',
        bodyClassName: 'grow-font-size',
        progressClassName: 'toastErrorProgress',
      });

    }
  }

  handleEmailUsernameInput(e) {
    console.log('setting username');
    this.setState({ emailUsername: e.target.value });
  }
  handlePasswordInput(e) {
    this.setState({ password: e.target.value });
  }
  componentWillUnmount() {
  }




  render() {
    const { errorMessage } = this.state;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>

        {this.state.redirectToDashboard === true && (
          <Redirect to={{
            pathname: '/account/dashboard',
          }}/>
        )}
          
        {this.state.loginScreen === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Login" headlineSubText="If you haven't got an account, you can register for free.">

              <InputCardItem headlineText="Username" headlineSubText="" additionalText="">
                <InputTextV2
                  id="emailusername"
                  name="emailusername"
                  value={this.state.emailUsername}
                  label="Username"
                  onChangeInputTextHandler={this.handleEmailUsernameInput}
                  />
              </InputCardItem>

              <InputCardItem headlineText="Password" headlineSubText="" additionalText="">
                <InputTextV2
                  id="Password"
                  name="Password"
                  value={this.state.password}
                  label="Password"
                  onChangeInputTextHandler={this.handlePasswordInput}
                  passwordField={true}
                  />
              </InputCardItem>

              { errorMessage !== '' && (
              <StandardText
                icon=""
                iconLocation=""
                text={errorMessage}
                tooltip=""
                action=""
                classes="shctext shctext-default-danger shctext-rounded shctext-medium shctext-fullwidth"
                    />
                )
                }

              <div>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Login with email"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                  handleClick={this.handleEmailLoginClick}
                  />
              </div>

              <div>
                <Link to="/forgot-password" className={classNames({ noLinkStyles: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Forgot password"
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
                  />
                </Link>
              </div>


              <div>
                <Link to="/sign-up" className={classNames({ noLinkStyles: true })}>
                  <StandardText
                    icon=""
                    iconLocation=""
                    text="Sign up here"
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
                    />
                </Link>
              </div>


            </InputCard>
          </div>
        )}
        {this.state.loginLoading === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Login" headlineSubText="Logging you into the system.">
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s40: true })}>
                  <i className="fas fa-circle-notch fa-spin" />
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s20: true })}>
                Logging you in
                </div>
              </div>
            </InputCard>
          </div>
        )}

        {this.state.mfaScreen === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Additional Security" headlineSubText="For added security, we need to further verify you">
              
            
              
              {
                this.state.mfaRequiredFactors.length > 0 ?
                // Arrow function preserves "this"
                this.state.mfaRequiredFactors.map((item, i) => {
                  let headlineSubText = "";
                  if (item.type === 'authenticator') {
                    return null;
                  } else {
                    headlineSubText = "We have sent a one-time security code to this identity.";
                    return (
                      <li key={item._id} className={classNames({ listNone: true })}>
                        <InputCardItem headlineText={`Enter Security Code (${item.identity})`} 
                        headlineSubText={headlineSubText} 
                        additionalText="">
                          <InputTextV2
                            id="mfaSecurityCode"
                            name="mfaSecurityCode"
                            value={item.mfaCodeInput}
                            label="MFA Security Code"
                            onChangeInputTextHandler={this.handleMfaSecurityCodeInput.bind(this, item.id)}
                            />
                        </InputCardItem>
                  
  
                      </li>
                    )
                  }
                }): (null)
              }

              {
                this.state.mfaRequiredFactors.length > 0 ?
                // Arrow function preserves "this"
                this.state.mfaRequiredFactors.map((item, i) => {
                  console.log("authenticator | Factors");
                  console.log(item);
                  let headlineSubText = "";
                  let additionalText = "";
                  if (item.type === "authenticator") {
                    headlineSubText = "Retrieve your code from your authenticator app.";
                    additionalText = "Ensure the code has not changed before pressing Login.";
                    return (
                      <li key={item._id} className={classNames({ listNone: true })}>
                        <InputCardItem headlineText={`Enter Security Code (${item.identity})`} 
                        headlineSubText={headlineSubText} 
                        additionalText={additionalText}>
                          <InputTextV2
                            id="mfaSecurityCode"
                            name="mfaSecurityCode"
                            value={item.mfaCodeInput}
                            label="MFA Security Code"
                            onChangeInputTextHandler={this.handleMfaSecurityCodeInput.bind(this, item.id)}
                            />
                        </InputCardItem>
                  
  
                      </li>
                    )

                  } else {
                    return null;
                  }
                }): (null)
              }
              
     
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Login"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                  handleClick={this.handleMfaLogin} 
                  />
                  
                </div>
                <div className={classNames({ flexGrow: true })}>
                  <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-negative shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                  handleClick={this.handleMfaCancel}
                  />
                  
                </div>
              </div>
              
            </InputCard>
          </div>
        )}

        {this.state.mfaLoading === true && (
          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="Additional Security" headlineSubText="Verifying additional security input.">
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s40: true })}>
                  <i className="fas fa-circle-notch fa-spin" />
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                <br />
                <div className={classNames({ text_s20: true })}>
                Verifying additional security input
                </div>
              </div>
            </InputCard>
          </div>
        )}

        </div>
        
      </div>
    );
  }
}

Template.propTypes = {
};






const mapStateToProps = (state, ownProps) => {
  let loginScreen = true;
  let mfaScreen = false;
  let redirectScreen = false;
  let loginLoading = false;
  let mfaLoading = false;

  


  return {
    loginScreen,
    mfaScreen,
    redirectScreen,
    loginLoading,
    mfaLoading,

    mfaSecurityRequired: state.Auth.mfaSecurityRequired,


    isAuthenticated: state.Auth.isAuthenticated,
    apiAccessTokenExpiresOn: state.Auth.apiAccessTokenExpiresOn,
    apiRefreshTokenExpiresOn: state.Auth.apiRefreshTokenExpiresOn,
  };

};


const mapDispatchToProps = {
  loginMfaV2,   //MFA Login
  removeMfaLoginRequired,   //MFA Login/Clear

  refreshAccessToken,
  updateIsAuthenticatedStatus,

  userAuth,
  guardpointUserAuth,
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);