import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';


// Routing
//import Routes from 'Core/Routes';

// Material UI (https://material.io/tools/icons/?style=baseline)
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';



// CSS
import componentStyles from './styles.css';

// Components


// Actions



class LinkGroupLayoutBasic extends React.Component {
  state = {
    open: false,
  };

  togglePanelNav = () => {
    console.log('togglePanelNav - toggle the open state');
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
    return (
      <div className={classNames({ PageFold_panel: true })}>
        <div className={classNames({ PageFold_navigation: true })}>
          <Hidden mdUp className={classNames({ PageFold_navigation_narrow: true })}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.togglePanelNav}
            >
              <MenuIcon className={classNames({ hide: this.state.open })} />
              <ClearIcon className={classNames({ hide: !this.state.open })} />
            </IconButton>
            <div className={classNames({ PageFold_navigation_expanded: true, hide: !this.state.open })}>
              {this.props.children[0]}
            </div>
          </Hidden>
          <Hidden smDown implementation="css" className={classNames({ PageFold_navigation_expanded: true })}>
            {this.props.children[0]}
          </Hidden>
        </div>
        <div className={classNames({ foldContent: true })}>
          {this.props.children[1]}
        </div>
      </div>
    );
  }
}

LinkGroupLayoutBasic.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(LinkGroupLayoutBasic);