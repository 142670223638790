import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import update from 'immutability-helper';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { removeBusinessProfileBusinessRegistryItem } from 'Actions';

function arrayPathToJsonObjUpdate(arr, val, operator) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    console.log(nextParent);
    if (i===0) {
      if (operator==="push") {
        childObj = {
          "$push": [val]
        };
      } else if (operator==="set") {
        childObj = {
          "$set": val
        };
      }
      
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

class BusinessProfileBusinessRegistryV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);
    this.handleBusinessProfileBusinessRegistryRemoveItem = this.handleBusinessProfileBusinessRegistryRemoveItem.bind(this);

    this.state = {
      businessRegistry: {},
      collapse: false,
    };
  }

  

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps - BusinessWebsiteVerificationSteps");
    

    let entireState = {
      businessRegistry: prevState.businessRegistry,
    }
    console.log(JSON.stringify(nextProps.value) +" !== "+ JSON.stringify(entireState));

    if (nextProps.value !== undefined && nextProps.value !== entireState) {
      console.log("Props does not equal entireState - needs to update");
      return { 
        businessRegistry: nextProps.value,
        };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - BusinessWebsiteVerificationSteps");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform update of state for BusinessWebsiteVerificationSteps");
      this.setState({
        websiteStep: this.props.value.website,

      });
    }
  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    const newItem = { [evt.target.name]: evt.target.value };
    let statePath = ["businessRegistry"];
    var stateJsonMappingObj = arrayPathToJsonObjUpdate(statePath, newItem, "set");
    console.log("Pushing to state");
    console.log(stateJsonMappingObj);
    console.log("Read state");
    console.log(this.state);
    
    const newData = update(this.state, stateJsonMappingObj);
    console.log("new Data");
    console.log(newData);
    console.log(newData.businessRegistry);
    /* EXAMPLE: don't set state, let it go around the loop through redux
    this.setState((prevState, props) => (newData.businessRegistry)
    ,() => {
      //this.props.setBusinessProfile(this.state.businessProfile);
      this.props.onChangeBusinessIdentityRegistryHandler(this.props.index, newData.businessRegistry);
    });
    */
    this.props.onChangeBusinessIdentityRegistryHandler(this.state.businessRegistry._id, this.props.index, newData.businessRegistry);


  }


  /*
  onChangeStepInputHandler = (id, sharedState, sharedStateUpdate) => {       //changing from evt to obj
    //Receive events from child inputs (InputText Component)
    console.log('parent receiving data - updating parent state');
    console.log(id);
    console.log(JSON.stringify(sharedState));
    console.log(JSON.stringify(sharedStateUpdate));

    sharedState[sharedStateUpdate.field] = sharedStateUpdate.value;

    this.setState({
      [id]: sharedState
    });

    //Pass to parent which is a state with an array with the data
    let entireState = {
      websiteStep: this.state.websiteStep,
      methodsStep: this.state.methodsStep,
      verifyStep: this.state.verifyStep,
      verificationStatus: this.state.verificationStatus,
    }
    let updateData = {
      id: id,
      field: sharedStateUpdate.field,
      value: sharedStateUpdate.value,
    }
    this.props.onChangeBusinessIdentityRegistryHandler(this.props.index, entireState, updateData);
  }
  */



  handleBusinessProfileBusinessRegistryRemoveItem = (evt) => { 
    console.log('remove this item from state');
    console.log(this.props.index);
    this.props.onRemoveBusinessIdentityRegistryHandler(this.state.businessRegistry._id, this.props.index);
  }


  render() {
    const { index, id, onChangeBusinessIdentityRegistryHandler, onRemoveBusinessIdentityRegistryHandler, value } = this.props;


    return (
      <div className={classNames({ ShcStepsContainer: true })}>
        <div className={classNames({ ShcStepsContainerHeader: true })}>
          <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
            <div>
              <div className={classNames({ flex: true, flexColumn: true })}>
                <div className={classNames({ text_h6: true })}>Business Registry</div>
                <div className={classNames({ text_h4: true })}>{this.state.businessRegistry.registryAbbrName}: {this.state.businessRegistry.registryId}</div>
              </div>
            </div>
            <div><i class="fas fa-angle-down"></i></div>
          </div>
        </div>
        <div className={classNames({ ShcStepsContainerBody: true })}>
          <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>
            <div className={classNames({ padT10: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Delete"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                handleClick={this.handleBusinessProfileBusinessRegistryRemoveItem}
              />
            </div>
          </div>

          <div>
            <InputCard headlineText="Business Registry" headlineSubText="Please add all registered business identities with the correct authorities.">
              <InputCardItem headlineText="Registry Name" headlineSubText="This is the Authority for the business registration." additionalText="">
                <InputTextV2 id="registryName" name="registryName" label="Business Registry Name (list)" value={this.state.businessRegistry.registryName} onChangeInputTextHandler={this.onChangeInputTextHandler} />
              </InputCardItem>

              <InputCardItem headlineText="Registry Abbr" headlineSubText="This would auto populate" additionalText="Some notes">
                <InputTextV2 id="registryAbbrName" name="registryAbbrName" label="Business Registry Abbreviated Name (list)" value={this.state.businessRegistry.registryAbbrName} onChangeInputTextHandler={this.onChangeInputTextHandler} />
              </InputCardItem>

              <InputCardItem headlineText="Registry ID" headlineSubText="" additionalText="">
                <InputTextV2 id="registryId" name="registryId" label="Business Registry ID" value={this.state.businessRegistry.registryId} onChangeInputTextHandler={this.onChangeInputTextHandler} />
              </InputCardItem>

              <InputCardItem headlineText="Registry Status" headlineSubText="" additionalText="">
                <InputTextV2 id="registryStatus" name="registryStatus" label="Business Registry Status" value={this.props.value.registryStatus} onChangeInputTextHandler={this.onChangeInputTextHandler} />
              </InputCardItem>

            </InputCard>
          </div>

          

        </div>
      </div>

    );
  }
}

BusinessProfileBusinessRegistryV2.propTypes = {
  
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  removeBusinessProfileBusinessRegistryItem
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessProfileBusinessRegistryV2);

/*
<TroubleshootingAdminWindow>
  <div className={classNames({ width50p: true })}>
    <TextStyle size="h5">
      {JSON.stringify(this.state.businessRegistry)}
      <br/>
    </TextStyle>
  </div>
</TroubleshootingAdminWindow>

<div className={classNames({ width50p: true })}>
  <TextStyle size="h5">
    {JSON.stringify(this.state.businessRegistry)}
    <br/>
  </TextStyle>
</div>
*/