import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiIcon } from 'Components/ShcButtons';

import { IdentityInputV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class VerifiedIdentityInput extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.
      collapse: true,



    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeItem(identityId, evt) {
    //console.log("removeItem");
    //console.log(identityId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
        lastChangeTransactionId: '129312931093210319203123',
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }


  render() {
    const { index, apiEndpoint, businessId, stateIdentifier, identifiers, id, name, data, onChangeHandler,
      onRemoveItem,
    } = this.props;
    
    
    //Extract all the required data.
    let identity="";
    let identityId="";
    let createdAt="";
    let modifiedAt="";
    let verificationStatus="";

    let communicationToken="";

    try {
      identity = data.identity;
    } catch(e) {}
    try {
      identityId = data._id;
    } catch(e) {}
    
    try {
      communicationToken = data.communicationToken;
    } catch(e) {}
    

    
    let inputDataType="";
    let dataType="";
    try {
      dataType = data.type;
    } catch(e) {}

    let headlineText="";
    let headlineSubText="";
    let additionalText="";
    let troubleshootingText="";
    if (dataType==="email") {
      headlineText="EMAIL ADDRESS";
      headlineSubText=`Please enter your email address.`;
      troubleshootingText=`${identityId}`;
    }
    if (dataType==="phone") {
      headlineText="PHONE NUMBER";
      headlineSubText=`Please enter your phone number in the international format including the plus sign (+).`;
      troubleshootingText=`${identityId}`;
    }
    if (dataType==="website") {
      headlineText="WEBSITE";
      headlineSubText="Your website address including the http://www";
      troubleshootingText=`${identityId}`;
    }
    if (dataType==="postal") {
      headlineText="POSTAL ADDRESS";
      headlineSubText="Your postal address. ";
      troubleshootingText=`${identityId}`;
    }
    if (dataType==="social") {
      headlineText="SOCIAL IDENTITY";
      headlineSubText="Your social identity. ";
      troubleshootingText=`${identityId}`;
    }



    if (["email", "phone", "website", "postal", "social"].indexOf(dataType) > -1) {
      inputDataType = dataType;
    }

    return (
      <div className={classNames({ ShcAccountModulesChildContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ ShcModulesIdentityRemoveItem: true })}>

              <StandardApiIcon
                apiActivityId={`RemoveUserIdentity_${identityId}`}
                icon="fas fa-times"
                classes=""
                handleClick={this.removeItem.bind(this, identityId)}
              />

            </div>
          </div>
          
                   
          <div className={classNames({ flex: true, cursorPointer: true })}
          onClick={this.collapseContainer}>

          <InputCardItem
            headlineText={headlineText}
            headlineSubText={headlineSubText}
            additionalText={additionalText}
            troubleshootingText={troubleshootingText}
          >
            <IdentityInputV2
              id={id}
              apiEndpoint={apiEndpoint}
              businessId={businessId}
              name={name}
              identifiers={identifiers}
              stateIdentifier={stateIdentifier}
              label=""
              value={identity}
              onChangeInputTextHandler={this.onChangeHandler}
              dataType={inputDataType}  //regular expression input validation // email, phone, number, alphaNoSpace, alphaSpace, text, custom
              pattern=""
              data={data}
              challenge={communicationToken}
            />
          </InputCardItem>
    
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

VerifiedIdentityInput.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifiedIdentityInput);
