import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
// import {  } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class ApiLogLineHeader extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }


  render() {
    const {
    } = this.props;

    return (
      <div className={classNames({ cursorPointer: true, flex: true })}>
        <div className={classNames({ flex: true, flexGrow: true })}>
          <div className={classNames({ width80: true })}>
          STATUS
          </div>
          <div className={classNames({ aaa: true })}>
          DESCRIPTION
          </div>
        </div>
        <div className={classNames({ aaa: true })}>
        DATE
        </div>
      </div>
    );
  }
}

ApiLogLineHeader.propTypes = {

};
ApiLogLineHeader.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = {

};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiLogLineHeader);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(ApiLogLineHeader);
