import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


import { BusinessUserProfile } from 'Components/ShcModulesBusinessUserManagement';


// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import {
  businessUsersAddUser,
  businessUsersRemoveUser,
  toggleOverlayOnScreenResponsive, 
} from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange, validateAlphaNumericNoSpacesOnly } from 'Helpers/Functions';





class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.businessUserRequestCreateItem = this.businessUserRequestCreateItem.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.handleNewBusinessUserInput = this.handleNewBusinessUserInput.bind(this);
    this.handleAddBusinessUserAccountSubmit = this.handleAddBusinessUserAccountSubmit.bind(this);
    this.removeBusinessUser = this.removeBusinessUser.bind(this);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      newUserAccountId: '',
      newUserAccountIdInputErrors: false,


      apiTimerCollection: [], // List of active timers. (MANDATORY)

      apiTimer: 'inactive',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {

  }



  businessUserRequestCreateItem() {
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'businessUsersAddUser', data);
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  removeBusinessUser(body = {}) {
    //console.log('Remove removeBusinessUser Profile Input');
    //console.log('Request to delete this item from the DB and then update/refresh - should be a loading icon while deleting');

    const { data = {}, apiUi = {} } = body;

    //console.log('removeItem');
    //console.log(JSON.stringify(data));

    this.props.businessUsersRemoveUser({ data, apiUi });
  }
  

  handleAddBusinessUserAccountSubmit(evt) {
    evt.preventDefault();
    //console.log('handleAddBusinessUserAccountSubmit');
    const { newUserAccountId, businessViewId } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    this.closeOverlayOnScreen('businessUsersAddUser');

    const data = {
      newUserAccountId: newUserAccountId,
      businessId: businessViewId,
    };
    this.props.businessUsersAddUser({ data, apiUi });


  }



  handleNewBusinessUserInput(event) {
    //console.log("handleNewBusinessUserInput");
    let errors = false;

    //ADD VALIDATION

    this.setState({
      newUserAccountId: event.target.value,
      newUserAccountIdInputErrors: errors,
    });
  }












  render() {
    const { userBusinessRoutePermissions } = this.props;

    //Permission requirements are either Owner or Admin.
    let requiredUserPermissions = ['owner', 'admin'];
    let userHasPermission = false;
    for (let p = 0; p < userBusinessRoutePermissions.length; p++) {
      //console.log(`if ${userBusinessRoutePermissions[p]} user permission is in the array for the route permissions, grant access`);
      if (requiredUserPermissions.includes(userBusinessRoutePermissions[p])) {
        userHasPermission = true;
      }
    }

    
      
    


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>



            {this.props.businessAccountClosed === true ?
            (
              <div>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Business Users</TextStyle>
                </div>
                <CardBasicV2>
                  This account is currently closed.
                </CardBasicV2>
              </div>
            ):(
              <div>
                <div className={classNames({ flex: true, maxWidth800: true,  })}>
                  <div className={classNames({ flexGrow: true })}>
                    <TextStyle size="h2">Business Users</TextStyle>
                  </div>
                  <div>
                    {userHasPermission && (
                      <StandardApiBtn
                        apiActivityId="AddNewBusinessUser"
                        icon=""
                        iconLocation=""
                        text="Add Business User"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-primary"
                        handleClick={this.businessUserRequestCreateItem}
                      />
                    )}
                    
                  </div>

                </div>



                {userHasPermission ? (
                  <div className={classNames({  maxWidth800: true })}>
                    {
                    this.props.businessIdentity &&
                    this.props.businessIdentity.businessAdministrators &&
                    this.props.businessIdentity.businessAdministrators.length > 0 ?
                      // Arrow function preserves "this"
                    this.props.businessIdentity.businessAdministrators.map((item, i) => {

                      return (
                        <li key={item._id} className={classNames({ listNone: true })}>
                          <BusinessUserProfile
                            key={['BusinessUsersProfile_', this.state.businessViewId, '_', item.userId].join('_')}
                            userId={item.userId}
                            businessId={this.state.businessViewId}
                            businessIdentities={this.props.businessIdentities}
                            stateIdentifier="BusinessIdentities"
                            identifiers={[{ _id: this.state.businessViewId }, { _id: item._id }]}
                            id="identities.businesses.$.businessAdministrators.$"
                            name="identities.businesses.$.businessAdministrators.$"
                            data={item}
                            onRemoveItem={this.removeBusinessUser}
                          />

                        </li>
                      )
                    }, this)
                    : null
                    }
                  </div>
                ):(
                  <CardBasicV2>
                    You are not authorised to view.
                  </CardBasicV2>
                )}

              </div>
            )}
          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="businessUsersAddUser"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add Business User"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Add User to this Business</TextStyle><br/>
              <TextStyle size="h4"></TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              Please enter an email address of a user who has a verified identity on our platform.<br/>

              <div>
                <div className={classNames({
                  flex: true,
                  flexJustifySpaceBetween: true,
                  flexAlignBaseline: true,
                  ShcIdentityInput_textInput_root: true,
                })}
                >
                  <div className={classNames({
                    ShcIdentityInput_textInput_child: true,
                    ShcIdentityInput_textInput_errors_icon: this.state.mobileAppLookupCodeInputErrors,
                  })}
                  >
                    <input
                      {...this.props}
                      label=""
                      margin="normal"
                      fullWidth
                      name="newUserAccountId"
                      value={this.state.newUserAccountId}
                      identifiers=""
                      stateIdentifier=""
                      onChange={this.handleNewBusinessUserInput}
                      type="text"
                      className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.mobileAppLookupCodeInputErrors })}
                    />
                  </div>
                </div>
              </div>
              {this.state.newUserAccountIdInputErrors && 
                <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }

              <br/>
              <StandardApiBtn
                apiActivityId="UserNotificationAddMobileApp"
                icon=""
                iconLocation=""
                text="Add User to Business"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.handleAddBusinessUserAccountSubmit}
              />

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('businessUsersAddUser')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>



      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
  };

};

const mapDispatchToProps = {
  
  
  businessUsersAddUser,
  businessUsersRemoveUser,

  toggleOverlayOnScreenResponsive,

};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
