import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';

import StepZilla from 'react-stepzilla';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText } from 'Components/ShcInput';
import { CardBasic } from 'Components/ShcCards';
import { StepUserOverview, StepUserIdentity, StepUserMethods, StepUserVerify } from 'Components/ShcSteps/UserIdentityVerification';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { updateBusinessIdentitiesItem, generateVerificationTokenWebsite, removeBusinessWebsiteVerificationItem } from 'Actions';


class UserIdentityVerificationSteps extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeStepInputHandler = this.onChangeStepInputHandler.bind(this);

    this.handleBusinessVerificationTokenWebsiteGen = this.handleBusinessVerificationTokenWebsiteGen.bind(this);
    this.handleBusinessVerificationRemoveItem = this.handleBusinessVerificationRemoveItem.bind(this);
    this.collapseContainer = this.collapseContainer.bind(this);

    this.state = {
      businessViewId: this.props.businessViewId,
      userIdentity: this.props.value,   //This specific identity (website, phone, etc)

      identityStep: {},
      methodsStep: {},
      verifyStep: {},
      verificationStatus: "In Progress",
      collapse: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== undefined && nextProps.value !== prevState.userIdentity) {
      console.log("Updating component state from props");
      return { 
        userIdentity: nextProps.value,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - UserIdentityVerificationSteps");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform update of state for UserIdentityVerificationSteps");
      //as of April 19 - this is an IdentityObject (businessIdentities)
      this.setState({
        identityStep: this.props.value.identity,       //website > identity
        methodsStep: this.props.value.methodsStep,
        verifyStep: this.props.value.verifyStep,
        verificationStatus: this.props.value.verificationStatus,

      });
    }
  }


  onChangeStepInputHandler = (id, userIdentity, updateData) => {       //changing from evt to obj
    //Receive events from child inputs (InputText Component)
    console.log('Parent: Receiving data from child element');
    console.log(id);
    console.log(JSON.stringify(userIdentity));
    console.log(JSON.stringify(updateData));

    //merge identity state with the update.

    let path = _.get(updateData, 'field');
    let value = _.get(updateData, 'value');

    let newState = _.assign(this.state.userIdentity, { [path]: value });
    console.log("newState");
    console.log(newState);
    

    //Update local state.
    this.setState({
      userIdentity: newState
    });

    //API TIMER STRATEGY REQUIRED

    updateData = {
      [updateData.field]: updateData.value,
    }

    let data = {
      index: this.props.index,
      state: newState,
    }


    this.props.onChangeUserIdentityVerificationHandler(this.state.userIdentity._id, this.props.index, updateData);



    //Update redux & api
    //this.props.updateBusinessIdentitiesItem({data});
    //updateBusinessIdentities


    //Update redux + api call for backend.
    //Redux will be used for loading with pre-filled data




    /* TEST REMOVING
    sharedState[sharedStateUpdate.field] = sharedStateUpdate.value;

    this.setState({
      [id]: sharedState
    });

    //Pass to parent which is a state with an array with the data
    let entireState = {
      identityStep: this.state.identityStep,
      methodsStep: this.state.methodsStep,
      verifyStep: this.state.verifyStep,
      verificationStatus: this.state.verificationStatus,
    }
    let updateData = {
      id: id,
      field: sharedStateUpdate.field,
      value: sharedStateUpdate.value,
    }
    
    */
  }

  handleBusinessVerificationTokenWebsiteGen(event) {
    event.preventDefault();
    console.log('getting verification token (website)');
    this.props.generateVerificationTokenWebsite();
  }

  handleBusinessVerificationRemoveItem = (evt) => { 
    console.log('remove this item from state');
    console.log(this.props.index);
    this.props.onRemoveUserIdentityVerificationHandler(this.state.userIdentity._id, this.props.index);

  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }


  render() {
    const { index, id, onChangeUserIdentityRegistryHandler, onRemoveUserIdentityVerificationHandler, value } = this.props;

    let steps = [];
      steps =
      [
        { name: 'Overview', component: <StepUserOverview value={this.state.userIdentity} activityType={this.state.userIdentity.type} /> },
        { name: 'Identity', component: <StepUserIdentity id="identityStep" name="identityStep" value={this.state.userIdentity} onChangeStepInputHandler={this.onChangeStepInputHandler} activityType={this.state.userIdentity.type} /> },
        { name: 'Methods', component: <StepUserMethods id="methodsStep" name="methodsStep" value={this.state.userIdentity} onChangeStepInputHandler={this.onChangeStepInputHandler} activityType={this.state.userIdentity.type} /> },
        { name: 'Verify', component: <StepUserVerify id="verifyStep" name="verifyStep" value={this.state.userIdentity} onChangeStepInputHandler={this.onChangeStepInputHandler} activityType={this.state.userIdentity.type} /> },
      ];
    

    
    const IdentityActivityHeading = () => {
      if (this.state.userIdentity.type==="website") {
        return (
          <TextStyle size="h2">Website Identity</TextStyle>
        );
      } else if (this.state.userIdentity.type==="email") {
        return (
          <TextStyle size="h2">Email Identity</TextStyle>
        );
      } else if (this.state.userIdentity.type==="phone") {
        return (
          <TextStyle size="h2">Phone Identity</TextStyle>
        );
      } else {
        <TextStyle size="h2">Unknown Identity Type</TextStyle>
      }
    }

    const CurrentStatus = () => {
      if (this.state.userIdentity.verificationStatus===true) {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
            <i class="fas fa-check"></i>
          </div>
        );
      } else {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_danger: true })}>
            <i class="fas fa-times"></i>
          </div>
        );
      }
    }

    return (
      <div className={classNames({ ShcStepsContainer: true })}>
        <div className={classNames({ ShcStepsContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>

          <CurrentStatus/>
  
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>{this.state.userIdentity.type}</div>
                  <div className={classNames({ text_h4: true })}>{this.state.userIdentity.identity}</div>
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true })}
                >
                <i class="fas fa-angle-down"></i>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcStepsContainerBody: true })}>
          <div className={classNames({ flex: true})}>

            <div className={classNames({ padT10: true, flexGrow: true })}>
              <IdentityActivityHeading/>
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Delete"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                handleClick={this.handleBusinessVerificationRemoveItem}
              />
            </div>
          </div>




          <div className={`step-progress ${classNames({ ShcStepsContainer: true })}`}>
            <StepZilla
              steps={steps}
              onStepChange={(step) => console.log(step)}
              nextButtonCls='shcbtn shcbtn-warning stepZilla-button-padding'
              backButtonCls='shcbtn shcbtn-warning stepZilla-button-padding'
              //nextTextOnFinalActionStep='Save'
              
              />
          </div>

          <TroubleshootingAdminWindow>
            <div>
              <TextStyle size="h5">
                {JSON.stringify(this.state.identityStep)}
                <br/>
                {JSON.stringify(this.state.methodsStep)}
                <br/>
                {JSON.stringify(this.state.verifyStep)}
              </TextStyle>
            </div>
          </TroubleshootingAdminWindow>

        </div>
        }
        
      </div>

    );
  }
}

UserIdentityVerificationSteps.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {
  updateBusinessIdentitiesItem,

  generateVerificationTokenWebsite,
  removeBusinessWebsiteVerificationItem
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserIdentityVerificationSteps);
