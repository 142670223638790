import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
import commonStyles from '../_CommonStyles/commonStyles.css';
// Components

// Actions


///////// NOT BUILT YET ///////////

class PlainTextCheckTable extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let { title, value, titleWidth } = this.props;

    let titleWidthCSS = '';
    if (titleWidth === undefined) {
      titleWidthCSS = '150px';
    } else {
      titleWidthCSS = `${titleWidth}`;
    }

    const titleStyle = {
      minWidth: titleWidthCSS,
    }

    if (value === true) {
      value = "true";
    } else if (value === false) {
      value = "false";
    }

    const TableItem = () => (
      <li className={classNames({ listNone: true, text_h5: true })}>
        <div className={classNames({ flex: true })}>
          <div style={titleStyle} className={classNames({ __ShcPlainTextCheckTable_key__: true })}>
            {title}
          </div>
          <div className={classNames({ __ShcPlainTextCheckTable_value__: true })}>
            {value}
          </div>
        </div>
      </li>
    );


    return (
      <div>
        <TableItem />
      </div>
    );
  }
}

PlainTextCheckTable.propTypes = {

};
PlainTextCheckTable.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PlainTextCheckTable);
