import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from 'react-switch';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
// import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


import { VerifiedProfileInput } from 'Components/ShcModulesIdentity';


// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import {
  addBusinessProfile,
  removeBusinessProfile,

} from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
function getRandomPhoneReferenceId() {
  const code = `${getRandomInt(9).toString() + getRandomInt(9).toString()} ${getRandomInt(9).toString()}${getRandomInt(9).toString()}`;
  return code.toString();
  /* let a = getRandomInt(9);
  let b = getRandomInt(9);
  let c = getRandomInt(9);
  let d = getRandomInt(9);
  return ""+a+b+" "+c+d; */
}

function arrayPathToJsonObjUpdate(arr, val, operator) {
  let stateJsonMappingObj = {};
  let childObj;
  let loop = 1;

  arr = arr.slice().reverse();
  for (let i = 0, len = arr.length; i < len; i++) {
    const nextParent = arr[i + 1];
    //console.log(nextParent);
    if (i === 0) {
      if (operator === 'push') {
        childObj = {
          $push: [val],
        };
      } else if (operator === 'set') {
        childObj = {
          $set: val,
        };
      }

      stateJsonMappingObj = { [arr[i]]: childObj };
      loop++;
      //console.log('next loop');
    } else {
      stateJsonMappingObj = {
        [arr[i]]: stateJsonMappingObj,
      };
      loop++;
    }
  }
  return stateJsonMappingObj;
}


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.addBusinessProfile = this.addBusinessProfile.bind(this);
    this.removeVerifiedProfileInput = this.removeVerifiedProfileInput.bind(this);

    this.state = {
      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      apiTimerCollection: [], // List of active timers. (MANDATORY)

      apiTimer: 'inactive',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessSettingsIdentityProfilePage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('businessIdentity');
      // console.log(businessIdentity);

      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {

  }

  removeVerifiedProfileInput(body = {}) {
    //console.log('Remove Verified Profile Input');
    //console.log('Request to delete this item from the DB and then update/refresh - should be a loading icon while deleting');

    const { removeItem = {} } = body;

    //console.log('removeItem');
    //console.log(JSON.stringify(removeItem));

    const apiUi = { apiActivityId: removeItem.apiActivityId };
    const business = {
      businessId: this.state.businessViewId,
      profileId: removeItem.profileId,
    };
    this.props.removeBusinessProfile({ business, apiUi });

  }

  addBusinessProfile(evt) {
    //console.log('addBusinessProfile');
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    const apiUi = { apiActivityId };
    const business = { businessId: this.state.businessViewId };
    this.props.addBusinessProfile({ business, apiUi });

  }
















  render() {
    const { userBusinessRoutePermissions } = this.props;


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>


            {this.props.businessAccountClosed === true ?
            (
              <div>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Business Profiles</TextStyle>
                </div>
                <CardBasicV2>
                  This account is currently closed.
                </CardBasicV2>
              </div>
            ):(
              <div>
                {this.props.activeSubscription === false ? 
                (
                  <CardBasicV2>
                    This business does not have an active subscription. Please sign up.
                  </CardBasicV2>
                ):(
                  <div>
                    <div className={classNames({ flex: true, maxWidth800: true })}>
                      <div className={classNames({ flexGrow: true })}>
                        <TextStyle size="h2">Business Profiles</TextStyle>
                      </div>
                      <div>
                        <StandardApiBtn
                          apiActivityId="AddNewBusinessProfile"
                          icon=""
                          iconLocation=""
                          text="Add Profile"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.addBusinessProfile}
                        />
                      </div>
                    </div>




                    <div className={classNames({  maxWidth800: true })}>
                      {
                      this.props.businessIdentity &&
                      this.props.businessIdentity.businessIdentityProfiles.length > 0 ?
                        // Arrow function preserves "this"
                      this.props.businessIdentity.businessIdentityProfiles.map((item, i) => {
                        let profileId = '';
                        try {
                          profileId = item._id;
                        } catch (e) {
                          profileId = '';
                        }
                        if (profileId === undefined) {
                          profileId = '';
                        }
                        return (
                          <li key={item._id} className={classNames({ listNone: true })}>
                            <VerifiedProfileInput
                              businessId={this.state.businessViewId}
                              profileId={profileId}
                              businessIdentities={this.props.businessIdentities}
                              stateIdentifier="BusinessIdentities"
                              identifiers={[{ _id: this.state.businessViewId }, { _id: item._id }]}
                              id="identities.businesses.$.businessProfile.$"
                              name="identities.businesses.$.businessProfile.$"
                              data={this.props.businessIdentity.businessIdentityProfiles[i]}
                              onRemoveItem={this.removeVerifiedProfileInput}
                            />

                          </li>
                        )

                      }, this)
                      : (
                        <div>

                          <CardBasicV2>
                            No records
                          </CardBasicV2>

                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);


  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }


  ///// GET SUBSCRIPTION DETAILS /////
  let businessSubscriptions = [];
  let activeSubscription = false;
  try {
    businessSubscriptions = businessIdentity.subscriptions.filter(sub => sub.active === true);

    if (businessSubscriptions.length > 0) {
      activeSubscription = true;
    }
  } catch (e) {
    //console.error('mapStateToProps | Failed to find subscription');
  }

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
    activeSubscription, 
  };

};

const mapDispatchToProps = {
  addBusinessProfile,
  removeBusinessProfile,

};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);
