import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

// Routing
// import Weblinks from 'Core/webroutes.js';
import Routes from 'Core/Routes';

// CSS
import componentStyles from './styles.css';

class HelmetSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.pageData = {
      metaTitle: '',
      metaDesc: '',
      metaCanonical: '',
      metaRobots: '',
      ogTitle: '',
      ogSitename: '',
      ogDesc: '',
      ogType: '',
      ogImage: '',
      ogURL: '',
    };

    Routes.forEach((obj) => {
      if (`/${obj.path}` == this.props.url) {
        //console.log('HELMET SEO');
        //console.log(JSON.stringify(obj));
        this.pageData.metaTitle = obj.metaTitle;
        this.pageData.metaDesc = obj.metaDesc;
        this.pageData.metaCanonical = obj.metaCanonical;
        this.pageData.metaRobots = obj.metaRobots;
        this.pageData.ogTitle = obj.ogTitle;
        this.pageData.ogSitename = obj.ogSitename;
        this.pageData.ogDesc = obj.ogDesc;
        this.pageData.ogType = obj.ogType;
        this.pageData.ogImage = obj.ogImage;
        this.pageData.ogURL = obj.ogURL;

      }
    });
  }

/*
Currently does not update the existing meta tags. 
Had to move the image to the top for facebook to load.
*/
  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <Helmet>
          <title>{this.pageData.metaTitle}</title>
          {this.pageData.metaDesc !== '' && <meta name="description" content={this.pageData.metaDesc} /> }
          {this.pageData.metaRobots == '' ?
            <meta name="robots" content="index,follow" />
            :
            <meta name="robots" content={this.pageData.metaRobots} />
          }

          {this.pageData.ogType !== '' && <meta property="og:type" content={this.pageData.ogType} /> }
          {this.pageData.ogTitle !== '' && <meta property="og:title" content={this.pageData.ogTitle} /> }
          {this.pageData.ogDesc !== '' && <meta property="og:description" content={this.pageData.ogDesc} /> }
          {this.pageData.ogImage !== '' && <meta property="og:image" content={this.pageData.ogImage} /> }
          {this.pageData.ogURL !== '' && <meta property="og:url" content={this.pageData.ogURL} /> }
          {this.pageData.ogSitename !== '' && <meta property="og:site_name" content={this.pageData.ogSitename} /> }

          {this.pageData.metaCanonical !== '' && <link rel="canonical" href={this.pageData.metaCanonical} /> }

        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(HelmetSEO);
