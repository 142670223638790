import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';





class IM_container extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.toggleScreenUp = this.toggleScreenUp.bind(this);
    this.toggleScreenDown = this.toggleScreenDown.bind(this);
    this.requestToRemoveIdentity = this.requestToRemoveIdentity.bind(this);
    
    this.state = {
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  toggleScreenUp() {
    this.props.toggleScreenUp();
  }
  toggleScreenDown() {
    this.props.toggleScreenDown();
  }
  requestToRemoveIdentity() {
    this.props.requestToRemoveIdentity();
  }


  
  
  
  render() {
    const { identityType, identitySectionTitle, icon, verificationStatus, verificationActionRequired, 
      identityTypeDisplayIcon, children, activeScreen
    } = this.props;

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    

    return (
      <div className={classNames({ ShcModulesIM2ExpandContainerHeader: true, 
      flex: true, cursorPointer: true, flexGrow: true })}>

        {
        activeScreen === 'ScreenSetupIdentity_input' ||
        activeScreen === 'ScreenConfirmationCheckEmail' ||
        activeScreen === 'ScreenConfirmationCheckSMS' ||
        activeScreen === 'ScreenConfirmationCheckPhoneCall' ||
        activeScreen === 'ScreenSetupIdentity_verifyIdentity' ||
        activeScreen === 'ScreenVerificationCodeUnsuccessful' ||
        activeScreen === 'ScreenSetupIdentity_verifyIdentityFailure' ?
        (
          <div className={classNames({ flexGrow: true, 
            ShcModulesIdentityManagementPrimaryLeftBorder_Blank: true
             })}>
            <div className={classNames({ padL10: true, padT20: true, 
              padR20: true, padB8: true, flex: true})}>
  
              <div className={classNames({ flex: true, flexAlignSelfStart: true, flexGrow: true})}>
                <div className={classNames({ flex: true})}>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
  
                      {identityTypeDisplayIcon}
  
                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true, padL10: true, })}>
  
                      {identitySectionTitle}
  
                    </div>
                  </div>
                  
                  {/* 
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenDown}>
  
                      <i className={`fas fa-angle-left ${classNames({color_primary_dark: true, 
                       padL10: true, text_s20: true, cursorPointer: true})}`}
                      aria-label="Left"
                      ></i>
  
                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenUp}>
  
                      <i className={`fas fa-angle-right ${classNames({color_primary_dark: true, 
                      padL10: true, padR10: true, text_s20: true, cursorPointer: true})}`}
                      aria-label="Right"
                      ></i>
  
                    </div>
                  </div>
                  */}

                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true})} onClick={this.requestToRemoveIdentity}>
  
                      <i className={`fas fa-times ${classNames({ text_color_grey_light: true })}`}></i>
  
                  </div>
                </div>
                
  
  
              </div>
            </div>
            <div className={classNames({ flexGrow: true })}>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <div className={classNames({ flex: true, flexColumn: true })}>
                      
                    {children}
  
                  </div>
                </div>
                
              </div>
  
            </div>
          </div>
        ):(
          <div className={classNames({ flexGrow: true, 
            ShcModulesIdentityManagementPrimaryLeftBorder_Verified: verificationStatus===true ? true : false,
            ShcModulesIdentityManagementPrimaryLeftBorder_ActionRequired: verificationActionRequired===true ? true : false,
            ShcModulesIdentityManagementPrimaryLeftBorder_Issues: verificationStatus===false ? true : false,
             })}>
            <div className={classNames({ padL10: true, padT20: true, 
              padR20: true, padB8: true, flex: true})}>
  
              <div className={classNames({ flex: true, flexAlignSelfStart: true, flexGrow: true})}>
                <div className={classNames({ flex: true})}>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})}>
  
                      {identityTypeDisplayIcon}
  
                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true, padL10: true, })}>
  
                      {identitySectionTitle}
  
                    </div>
                  </div>
                  {/* 
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenDown}>
  
                      <i className={`fas fa-angle-left ${classNames({color_primary_dark: true, 
                       padL10: true, text_s20: true, cursorPointer: true})}`}
                      aria-label="Left"
                      ></i>
  
                    </div>
                  </div>
                  <div>
                    <div className={classNames({fullHeight: true, flex: true, 
                      flexColumn: true, flexJustifyCenter: true})} onClick={this.toggleScreenUp}>
  
                      <i className={`fas fa-angle-right ${classNames({color_primary_dark: true, 
                      padL10: true, padR10: true, text_s20: true, cursorPointer: true})}`}
                      aria-label="Right"
                      ></i>
  
                    </div>
                  </div>
                  */}
                  
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true})}>
                  <div className={classNames({fullHeight: true, flex: true, 
                    flexColumn: true, flexJustifyCenter: true})} onClick={this.requestToRemoveIdentity}>
  
                      <i className={`fas fa-times ${classNames({ text_color_grey_light: true })}`}></i>
  
                  </div>
                </div>
                
  
  
              </div>
            </div>
            <div className={classNames({ flexGrow: true })}>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <div className={classNames({ flex: true, flexColumn: true })}>
                      
                    {children}
  
                  </div>
                </div>
                
              </div>
  
            </div>
          </div>
        )}
      </div>

    );
  }
}

IM_container.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IM_container);
