import React from 'react';
import { Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from './BusinessRegistrationCheckoutFormV2';
class BusinessRegistrationCheckout extends React.Component {
  render() {
    const { businessId, selectedPlanId } = this.props;
    return (
      <Elements>
        <InjectedCheckoutForm
        businessId={businessId}
        selectedPlanId={selectedPlanId} />
      </Elements>
    );
  }
}

export default BusinessRegistrationCheckout;
