import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardIcon } from 'Components/ShcButtons';

// Actions
import { setUiCheckboxOptions } from 'Actions';

const uuidv1 = require('uuid/v1');

class CheckboxOptionCardReduxV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      businessId: this.props.businessId,
      checkboxOptionsId: this.props.checkboxOptionsId,

      lastChangeTransactionId: '',
      errors: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - CheckboxOptionCardReduxV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem(identifiers, stateIdentifier, fieldName, fieldRef, fieldStore, storeId, event) {
    let errors = false;
    const lastChangeTransactionId = uuidv1();

    let name = this.props.name;

    //console.log('toggleSelectedItem');

    //console.log('RadioOptions | businessId');
    //console.log(this.state.businessId);

    const selection = {
      businessId: this.state.businessId,
      checkboxOptionsId: this.state.checkboxOptionsId,
      checkboxSelectedState: !this.props.checkboxSelectedState,
    };

 
    //console.log(selection);
    this.props.setUiCheckboxOptions(selection);

    const returnObj = {
      name,
      identifiers,
      stateIdentifier,
      value: !this.props.checkboxSelectedState,

      //NEW FIELDS
      fieldName, fieldRef, fieldStore, storeId,
      fieldValue: !this.props.checkboxSelectedState,

      errors, // <-- Errors added on 2/08/19 for local component messaging on status
      lastChangeTransactionId,
    };
    
    //This isn't always implemented, so we do expect some errors.
    try {
      this.props.onChangeInputHandler(returnObj);
    } catch (e) {}
    

  }

  render() {
    const {
      name, 
      onChangeInputHandler,
      businessId,
      checkboxOptionsId,
      checkboxText,

      //NEW FIELDS
      fieldName = '', 
      fieldRef = '', 
      fieldStore = '', 
      storeId = '',

    } = this.props;

    let {
      identifiers, stateIdentifier,
    } = this.props;

    if (identifiers === undefined) {
      identifiers = '';
    }
    if (stateIdentifier === undefined) {
      stateIdentifier = '';
    }




    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ padR15: true, cursorPointer: true })}>
          {this.props.checkboxSelectedState ?
            <StandardIcon
              icon="far fa-check-square"
              classes="colour_primary"
              identifiers={identifiers}
              stateIdentifier={stateIdentifier}

              fieldName={fieldName}
              fieldRef={fieldRef}
              fieldStore={fieldStore}
              storeId={storeId}
              
              handleClick={this.toggleSelectedItem.bind(this, identifiers, stateIdentifier, fieldName, fieldRef, fieldStore, storeId)}
            />
          :
            <StandardIcon
              icon="far fa-square"
              classes=""
              identifiers={identifiers}
              stateIdentifier={stateIdentifier}

              fieldName={fieldName}
              fieldRef={fieldRef}
              fieldStore={fieldStore}
              storeId={storeId}
              
              handleClick={this.toggleSelectedItem.bind(this, identifiers, stateIdentifier, fieldName, fieldRef, fieldStore, storeId)}
            />
          }
        </div>
        <div className={classNames({ aaa: true })}>
          { checkboxText ?
            <div>
              <div className={classNames({ ShcCheckboxOptionCard_checkboxText: true })}>
                {checkboxText}
              </div>
            </div>
          : null }
        </div>
      </div>
    );
  }
}

CheckboxOptionCardReduxV1.propTypes = {

};
CheckboxOptionCardReduxV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
  //console.log('mapStateToProps | CheckboxOptionCardReduxV1');
  
  let checkboxSelectedState = false;
  if (state.UI.uiCheckboxOptions) {
    //console.log('mapStateToProps | uiCheckboxOptions is true');
    for (let z = 0; z < state.UI.uiCheckboxOptions.length; z++) {
      //console.log(`mapStateToProps | seaching for business id: ${state.UI.uiCheckboxOptions[z].businessId} === ${ownProps.businessId}`);
      if (state.UI.uiCheckboxOptions[z].businessId.toString() === ownProps.businessId.toString()) {
        //console.log('mapStateToProps | Checkbox mapped to business id');
        if (state.UI.uiCheckboxOptions[z].checkboxOptionsId.toString() === ownProps.checkboxOptionsId.toString()) { // this ID
          //console.log('mapStateToProps | Checkbox mapped to checkbox id');
          checkboxSelectedState = state.UI.uiCheckboxOptions[z].checkboxSelectedState;
        }
      }
    }
  } else {
    checkboxSelectedState = false;
    //console.log('mapStateToProps | Checkbox state not found');
  }
  
  return {
    checkboxSelectedState,
    uiCheckboxOptions: Object.assign([], state.UI.uiCheckboxOptions),
  };
};

const mapDispatchToProps = {
  setUiCheckboxOptions,
};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(CheckboxOptionCardReduxV1);
