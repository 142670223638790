import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class CardMiniS2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { padding, height, margin } = this.props;

    return (
      <div className={classNames({ ShcCardMiniS2Container: true, pad20: padding !== 'none', mar10: margin !== 'none' })}>
        {this.props.children}
      </div>
    );
  }
}

CardMiniS2.propTypes = {

};
CardMiniS2.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardMiniS2);
