import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Moment from 'react-moment';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
// import {  } from 'Actions';


// Functions
import { getJsonStatusPhrase } from 'Helpers/Functions';


class BusinessSubscriptionInvoicesLogLineItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.state = {

    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  handleDownloadOfInvoice(invoice_pdf, event) {
    //console.log(JSON.stringify(invoice_pdf));
    //console.log(`Download invoice: ${invoice_pdf}`);
    //console.log(`Download invoice: ${event}`);
    setTimeout(() => {
      const response = {
        file: invoice_pdf,
      };
      window.location.href = response.file;
    }, 100);
  }


  render() {
    const {
      id,
      object,
      hosted_invoice_url,
      invoice_pdf,
      number,
      paid,
      period_start,
      period_end,
      status,
      subscription,
    } = this.props;

    let periodStartMoment = '';
    if (Number.isInteger(period_start)) {
      try {
        periodStartMoment = <Moment unix  format="DD/MM/YYYY">{period_start}</Moment>
      } catch (e) {
        console.error("dateitme not Int");
        console.error(e);
        periodStartMoment = period_start;
      }
    } else {
      periodStartMoment = period_start;
    }
    let periodEndMoment = '';
    if (Number.isInteger(period_end)) {
      try {
        periodEndMoment = <Moment unix  format="DD/MM/YYYY">{period_end}</Moment>
      } catch (e) {
        console.error("dateitme not Int");
        console.error(e);
        periodEndMoment = period_end;
      }
    } else {
      periodEndMoment = period_end;
    }

    

    const DownloadInvoicePDF = () => {
      return (
        <div className={`${classNames({ text_h3: true, color_primary_dark: true, displayInlineBlock: true })}`}>
          <i onClick={this.handleDownloadOfInvoice.bind(this, invoice_pdf)} class="fas fa-file-download"></i>
        </div>
      );
    };
    
    const PayInvoiceNow = () => {
      return (
        <div className={`${classNames({ text_h3: true, color_primary_dark: true, displayInlineBlock: true })}`}>
          <i onClick={() => window.open(`${hosted_invoice_url}`, "_blank")} class="fas fa-external-link-alt"></i>
        </div>
      );
    };

    const InvoiceStatus = () => {
      if (status !== 'draft') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            {status}
          </div>
        );
      }
    };


    const StatusCodeComponent = () => {
      if (status === '200') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            {status} OK
          </div>
        );
      }
      return (
        <div className={`shctext-slim shctext-rounded shctext-danger ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
          {status} ERR
        </div>
      );

    };

    return (
      <div className={classNames({ cursorPointer: true, flex: true, ShcModulesReports_apiLogLineItem_containerBorder: true })}>
        <div className={classNames({ flex: true, flexGrow: true })}>
          <div className={`width120 ${classNames({ text_h5: true })}`}>

            {number} 

          </div>
          <div className={classNames({ text_fs13: true, flex: true, flexAlignItemsCenter: true })}>
            
            <div className={classNames({ text_fs13: true, })}>
              {periodStartMoment}
            </div>
            <div className={classNames({ text_fs13: true, })}>
              &nbsp;to&nbsp; 
            </div>
            <div className={classNames({ text_fs13: true, })}>
              {periodEndMoment}
            </div>

          </div>
        </div>
        <div className={classNames({text_h5: true, flex: true, flexAlignItemsCenter: true })}>

          

        </div>
        <div className={`width80 ${classNames({ text_h5: true })}`}>

          <InvoiceStatus />

        </div>
        <div className={`width40 ${classNames({ text_h5: true })}`}>

          <DownloadInvoicePDF />

        </div>
        <div className={`width40 ${classNames({ text_h5: true })}`}>

          <PayInvoiceNow />

        </div>
      </div>
    );
  }
}

BusinessSubscriptionInvoicesLogLineItem.propTypes = {

};
BusinessSubscriptionInvoicesLogLineItem.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = {

};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessSubscriptionInvoicesLogLineItem);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(BusinessSubscriptionInvoicesLogLineItem);
