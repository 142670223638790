import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';

// Actions
import { reduxDataUpdate, apiDataUpdate, updateUserIdentities } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType, timeNow, validateAlphaNumericNoSpacesOnly } from 'Helpers/Functions';

class IM_email_verifyV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    
    
    
    
    
    
    
    
    
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);

    this.handleChangeV2 = this.handleChangeV2.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.

      verificationCode: '',
      verificationCodeInputErrors: false,








      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }


  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
    if (errors === false) {
      this.props.onChangeHandler(verificationCode);
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    let updateData = false;
    let updateObject = {};
    if (prevState.communicationTokenArray !== nextProps.communicationTokenArray) {
      updateData = true;
      updateObject['communicationTokenArray'] = nextProps.communicationTokenArray;
    }

    //console.log(updateObject);
    if (updateData === true) {
      return updateObject;
    } else {
      return null;
    }
  }


  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }

    //reValidationDueDate

    let reValidationDueDate = null;
    try {
      reValidationDueDate = this.props.dataSet.reValidationDueDate;
    } catch(e) {}
    if (reValidationDueDate === undefined) {
      reValidationDueDate = null;
    }
    try {
      reValidationDueDate = parseInt(reValidationDueDate);
    } catch (e) {}
    console.log("====== reValidationDueDate ======");
    console.log(reValidationDueDate);


    let timeNowObj = timeNow();
    let dateTimeNowSec = timeNowObj.dateTimestampSec;

    
    console.log("====== dateTimeNowSec ======");
    console.log(dateTimeNowSec);

    let verificationStatus = this.props.verificationStatus;
    if (dateTimeNowSec > reValidationDueDate) {
      console.log("THIS IDENTITY HAS EXPIRED");
      verificationStatus = false;
    } else {
      console.log("THIS IDENTITY IS STILL VALID FROM A TIME PERSPECTIVE");
    }



    this.setState({
      identityInputErrors: errors,
      verificationStatus,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }
  


  //identifiers, stateIdentifier, event
  async handleChangeV2(receivedUpdateObj) {
    console.log("handleChangeV2");
    console.log(receivedUpdateObj);

    let identifiers = receivedUpdateObj.identifiers;
    let stateIdentifier = receivedUpdateObj.stateIdentifier;
    let identityInputValue = receivedUpdateObj.value;
    let name = receivedUpdateObj.name;

    let errors = false;
    let identityInputErrors = receivedUpdateObj.errors;    //new
    if (identityInputErrors === true) {
      errors = true;
    }

    console.log("identityInputErrors");
    console.log(identityInputErrors);

    console.log("errors");
    console.log(errors);



    //const name = event.target.name;
    //const identityInputValue = event.target.value;
    
    const lastChangeTransactionId = uuidv1();

    //console.log(identifiers);
    //console.log(stateIdentifier);

    //console.log(this.props.identityType);
    //console.log(identityInputValue);

    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        //errors = false;   //Do not override pervious error settings
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    

    if (errors === true) {
      console.log('errors | set identityInputErrors');
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        savingData: false,
      });
      //Advise the parent component that the input status changed
      this.props.identityInputValidationStatus(false);
    } else {
      //No errors experienced, save record
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value: identityInputValue,
        lastChangeTransactionId,
      };


      


      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////
      this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.

      
      //Advise the parent component that the input status changed
      this.props.identityInputValidationStatus(true);


      //this.updateData(returnObj);   //ORIG

      //New code for identity field updates
      //Remove spaces for mobile numbers
      identityInputValue = identityInputValue.replace(/\s/g, "");
      let data = {
        type: this.props.identityType,
        identity: identityInputValue,
        identityId: this.props.identityId,
      }

      let apiResponse = await this.props.updateUserIdentities({data});
      console.log('apiResponse');
      console.log(apiResponse);

      this.setState({
        savingData: false,
      });


      

    } //end if errors
  }





  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }




  render() {
    const { 
      identityInputValidationStatus,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,
      identityInputValue,
      communicationTokenArray,
      icon, children 
    } = this.props;

    let { 
      identityMask,
    } = this.props;

    let businessIdentity = false;
    if (businessId === '' || businessId === undefined) {
      businessIdentity = false;
    } else {
      businessIdentity = true;
    }

    //This is used for transition of existing identities
    if (identityMask === '' || identityMask === undefined || identityMask === null) {
      identityMask = identityInputValue;
    }

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    





    return (
      <div>
        <div>
          <div className={classNames({
            padB10: true, text_s14: true,
          })}>
            Click on the verify link in the email we sent you. Check that the email is from 
            Guard Point by comparing the following communication code with what is in the email.
          </div>

          <div>
            {communicationTokenArray &&
              communicationTokenArray.length > 0 ?
                  // Arrow function preserves "this"
                  communicationTokenArray.map((item, i) => (
                  <div key={i} className={classNames({padL20: true,})}>
                    <div className={classNames({text_s18: true,})}>{item}</div>
                  </div>
                ), this)
              : null
              }
          </div>




        </div>
        <div className={classNames({
            hidden: true,
          })}
          >
          <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
            ShcIdentityInput_textInput_root: true,
          })}
          >
            <div className={classNames({
              ShcIdentityInput_textInput_child: true,
              ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
            })}
            >
              <input
                id={['verificationCodeInputField', identityId].join('_')}
                key={['verificationCodeInputField', identityId].join('_')}
                
                margin="normal"
                fullWidth
                name={name}
                value={this.state.verificationCode}
                identifiers={identifiers}
                stateIdentifier={stateIdentifier}
                onChange={this.handleIdentityVerificationCodeInput}
                type="text"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
              />
            </div>
          </div>
        </div>
        {verificationStatus !== true && this.state.verificationCodeInputErrors && 
          <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
            <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
            <div>
              <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                aria-label="Invalid Input"
              ></i>
            </div>
          </div>
        }

      </div>
    );
  }
}




IM_email_verifyV1.defaultProps = {
  identityLocked: false,
};


IM_email_verifyV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  
  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  updateUserIdentities,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IM_email_verifyV1);
