import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import uuid from 'uuid/v1';

//Graphing
import { ResponsiveLine } from '@nivo/line';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { registerReportingComponent, unRegisterReportingComponent, reportingComponentPing, reportingComponentRegister, userBusinessInvitationResponse } from 'Actions';

/***************************************************************************
 * HOW CHARTING WORKS
 * This chart when requested to load will define the dataset it is requesting. 
 * 1) Search for an existing dataset in the businessReporting STATE
 * 2) If exists, load
 * 3) If not, insert record with 'state:updating' (to stop another component attempting to create the data too)
 * 4) initate API call to retrieve the data
 * 5) update state of record to available, reset the updated timer to the current time
 * 6) request a global timer to be created for this record. (handle duplicate requests)
 * 
 * 
 * 

 */




class BusinessChartLine extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.componentRef = React.createRef();


    //Create a unique id for this component
    let componentId = uuid();
    console.log(`BusinessChartLine ref: ${componentId}`);

    //Create the component data object
    let registerComponent = {
      reportName: this.props.reportDatasetName,
      businessId: this.props.businessId,
      componentId: componentId,
    }

    /*
    function setIntervalAndExecute(fn, t) {
      fn();
      return(setInterval(fn, t));
    }
    var i = setIntervalAndExecute(dothis, 20000);
    */


    //Start immediately
    this.props.reportingComponentRegister(registerComponent);
    //Set timer for component ping/check-in (Every 30 seconds)
    let componentPingTimer = setInterval(function() {
      props.reportingComponentPing(registerComponent);
    }, 30000, registerComponent); 

    
    this.chartOnClick = this.chartOnClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    

    this.state = {
      componentId: componentId,
      intervalSeconds: 60,    //every minute execute query
      reportName: this.props.reportDatasetName,
      businessId: this.props.businessId,

      reportIntervals: this.props.reportIntervals,

      componentPingTimer: componentPingTimer,

      componentWidth: 0,
      width: 0, 
      height: 0,

      axisBottom: {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: '%b %d',
        tickValues: 'every 2 days',
        legend: 'Last 30 days',
        legendOffset: 36,
        legendPosition: 'middle'
      },

      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
    //Generate component unique reference id
    
    //Register component against reporting dataset
    let registerComponent = {
      reportName: this.state.reportName,
      businessId: this.state.businessId,
      componentId: this.state.componentId,
    }

    //TEMP HOLD
    this.props.registerReportingComponent(registerComponent);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


  }
  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate.');
  }

  componentWillUnmount() {
    console.log("BusinessChartLine is unmounting");

    clearInterval(this.state.componentPingTimer);

    let unRegisterComponent = {
      reportName: this.state.reportName,
      businessId: this.state.businessId,
      componentId: this.state.componentId,
    }

    //TEMP HOLD
    this.props.unRegisterReportingComponent(unRegisterComponent);

    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    console.log("updateWindowDimensions");
    console.log(this.componentRef);
    console.log(this.componentRef.current);
    console.log(this.componentRef.current.clientWidth);


    let componentWidth = this.componentRef.current.clientWidth;
    console.log(`Component Width: ${componentWidth}`);

    //Get the current axis configuration
    let axisBottom = this.state.axisBottom;

    if (componentWidth > 930) {
      axisBottom.tickValues = 'every 2 days';
    } else if (componentWidth <= 930 && componentWidth > 650) {
      axisBottom.tickValues = 'every 4 days';
    } else if (componentWidth <= 650 && componentWidth > 450) {
      axisBottom.tickValues = 'every 6 days'; 
    } else if (componentWidth <= 450 && componentWidth > 0) {
      axisBottom.tickValues = 'every 8 days'; 
    }

    console.log(` width: ${window.innerWidth}, height: ${window.innerHeight}`);
    this.setState({ 
      axisBottom,
      componentWidth,      
      width: window.innerWidth, 
      height: window.innerHeight,
    });
  }

  chartOnClick(point, event) {
    console.log("chartOnClick ======");
    console.log(point);
    console.log(event);
  }

  render() {
    const { } = this.props;

    /*
    let reportData = [
      {
        "id": "Total",
        "data": [
            {
                "x": "2020-03-27",
                "y": 0,
                "more": {
                    "_id": "2020-03-27",
                    "timeSeriesValue": "2020-03-27"
                }
            },
            {
                "x": "2020-03-28",
                "y": 0,
                "more": {
                    "_id": "2020-03-28",
                    "timeSeriesValue": "2020-03-28"
                }
            },
            {
                "x": "2020-03-29",
                "y": 0,
                "more": {
                    "_id": "2020-03-29",
                    "timeSeriesValue": "2020-03-29"
                }
            },
            {
                "x": "2020-03-30",
                "y": 0,
                "more": {
                    "_id": "2020-03-30",
                    "timeSeriesValue": "2020-03-30"
                }
            },
            {
                "x": "2020-03-31",
                "y": 0,
                "more": {
                    "_id": "2020-03-31",
                    "timeSeriesValue": "2020-03-31"
                }
            },
            {
                "x": "2020-04-01",
                "y": 0,
                "more": {
                    "_id": "2020-04-01",
                    "timeSeriesValue": "2020-04-01"
                }
            },
            {
                "x": "2020-04-02",
                "y": 0,
                "more": {
                    "_id": "2020-04-02",
                    "timeSeriesValue": "2020-04-02"
                }
            },
            {
                "x": "2020-04-03",
                "y": 0,
                "more": {
                    "_id": "2020-04-03",
                    "timeSeriesValue": "2020-04-03"
                }
            },
            {
                "x": "2020-04-04",
                "y": 0,
                "more": {
                    "_id": "2020-04-04",
                    "timeSeriesValue": "2020-04-04"
                }
            },
            {
                "x": "2020-04-05",
                "y": 0,
                "more": {
                    "_id": "2020-04-05",
                    "timeSeriesValue": "2020-04-05"
                }
            },
            {
                "x": "2020-04-06",
                "y": 0,
                "more": {
                    "_id": "2020-04-06",
                    "timeSeriesValue": "2020-04-06"
                }
            },
            {
                "x": "2020-04-07",
                "y": 0,
                "more": {
                    "_id": "2020-04-07",
                    "timeSeriesValue": "2020-04-07"
                }
            },
            {
                "x": "2020-04-08",
                "y": 0,
                "more": {
                    "_id": "2020-04-08",
                    "timeSeriesValue": "2020-04-08"
                }
            },
            {
                "x": "2020-04-09",
                "y": 0,
                "more": {
                    "_id": "2020-04-09",
                    "timeSeriesValue": "2020-04-09"
                }
            },
            {
                "x": "2020-04-10",
                "y": 0,
                "more": {
                    "_id": "2020-04-10",
                    "timeSeriesValue": "2020-04-10"
                }
            },
            {
                "x": "2020-04-11",
                "y": 0,
                "more": {
                    "_id": "2020-04-11",
                    "timeSeriesValue": "2020-04-11"
                }
            },
            {
                "x": "2020-04-12",
                "y": 0,
                "more": {
                    "_id": "2020-04-12",
                    "timeSeriesValue": "2020-04-12"
                }
            },
            {
                "x": "2020-04-13",
                "y": 0,
                "more": {
                    "_id": "2020-04-13",
                    "timeSeriesValue": "2020-04-13"
                }
            },
            {
                "x": "2020-04-14",
                "y": 0,
                "more": {
                    "_id": "2020-04-14",
                    "timeSeriesValue": "2020-04-14"
                }
            },
            {
                "x": "2020-04-15",
                "y": 0,
                "more": {
                    "_id": "2020-04-15",
                    "timeSeriesValue": "2020-04-15"
                }
            },
            {
                "x": "2020-04-16",
                "y": 0,
                "more": {
                    "_id": "2020-04-16",
                    "timeSeriesValue": "2020-04-16"
                }
            },
            {
                "x": "2020-04-17",
                "y": 0,
                "more": {
                    "_id": "2020-04-17",
                    "timeSeriesValue": "2020-04-17"
                }
            },
            {
                "x": "2020-04-18",
                "y": 6,
                "more": {
                    "_id": "2020-04-18",
                    "timeSeriesValue": "2020-04-18",
                    "businessId": "5e92beb3b34aa9000870a532",
                    "day": 18,
                    "month": 4,
                    "year": 2020,
                    "yearMonthDay": 20200418,
                    "total": 6,
                    "allRecords": [
                        {
                            "_id": "5e9a9f217ecb40000754651f",
                            "connectTransactionId": "uYpjMu3vcjjE3N1zdOOJLiOmFPBt8tydwt7ZgOjp3Ds",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5e9aa4a413dd530007465f11",
                            "connectTransactionId": "W2P0bq8iectJrMJLsoJlMc7OMkyOl0CdBvUxdKvRGz9",
                            "customerContactIdentityType": "phone"
                        },
                    ]
                }
            },
            {
                "x": "2020-04-19",
                "y": 0,
                "more": {
                    "_id": "2020-04-19",
                    "timeSeriesValue": "2020-04-19"
                }
            },
            {
                "x": "2020-04-20",
                "y": 3,
                "more": {
                    "_id": "2020-04-20",
                    "timeSeriesValue": "2020-04-20",
                    "businessId": "5e92beb3b34aa9000870a532",
                    "day": 20,
                    "month": 4,
                    "year": 2020,
                    "yearMonthDay": 20200420,
                    "total": 3,
                    "allRecords": [
                        {
                            "_id": "5e9d549cef5a7300080fdbce",
                            "connectTransactionId": "61m2hil1IkTjv8YkQxwjAeleDr9HAuEjCeIBOsxlnjn",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5e9e305ebdff4b00073f091e",
                            "connectTransactionId": "o5dZdHqwKIXQQjo7koCfUHi0KczKqD87lxhFaQrWF1E",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5e9e308bbdff4b00073f0943",
                            "connectTransactionId": "5R9eExizS1GeasA0QS1ytp4MYTwLqjL0RxWy2hFa00c",
                            "customerContactIdentityType": "phone"
                        }
                    ]
                }
            },
            {
                "x": "2020-04-21",
                "y": 17,
                "more": {
                    "_id": "2020-04-21",
                    "timeSeriesValue": "2020-04-21",
                    "businessId": "5e92beb3b34aa9000870a532",
                    "day": 21,
                    "month": 4,
                    "year": 2020,
                    "yearMonthDay": 20200421,
                    "total": 17,
                    "allRecords": [
                        {
                            "_id": "5e9e3a5cd217060007609b97",
                            "connectTransactionId": "k7kjmvv46f7ZnGCbJE8Jtrej8zz5Ji8a8tVbguANQDN",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5e9e3af2d217060007609bc5",
                            "connectTransactionId": "jDNPYIVvKhqtYXRpWv6oCfCAmLQyCAoBFRiW3T4YgmI",
                            "customerContactIdentityType": "phone"
                        },
                    ]
                }
            },
            {
                "x": "2020-04-22",
                "y": 2,
                "more": {
                    "_id": "2020-04-22",
                    "timeSeriesValue": "2020-04-22",
                    "businessId": "5e92beb3b34aa9000870a532",
                    "day": 22,
                    "month": 4,
                    "year": 2020,
                    "yearMonthDay": 20200422,
                    "total": 2,
                    "allRecords": [
                        {
                            "_id": "5ea0c42c85eca30008f2ef83",
                            "connectTransactionId": "2ISIFIhJ8hrMQchQFroq28CMpojlCVlk2lq0V8SDLsg",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5ea0c5e8fe53fa000776f7cc",
                            "connectTransactionId": "7R2RsgJ6g1FvkPc3BYBac7i6NH0VLz4RpTyehgA3Bnx",
                            "customerContactIdentityType": "phone"
                        }
                    ]
                }
            },
            {
                "x": "2020-04-23",
                "y": 38,
                "more": {
                    "_id": "2020-04-23",
                    "timeSeriesValue": "2020-04-23",
                    "businessId": "5e92beb3b34aa9000870a532",
                    "day": 23,
                    "month": 4,
                    "year": 2020,
                    "yearMonthDay": 20200423,
                    "total": 38,
                    "allRecords": [
                        {
                            "_id": "5ea0e498d36a23000847897c",
                            "connectTransactionId": "AyQa9uRyOEOxeRuS87Fu7f0v0X7mxltnEMqOHs1VRhm",
                            "customerContactIdentityType": "phone"
                        },
                        {
                            "_id": "5ea0e8aa1d79fa0007a22d80",
                            "connectTransactionId": "vAmpwXY4r9VR9VTi7APyj27WkwfuPheMyg9QgpGzOPk",
                            "customerContactIdentityType": "phone"
                        },
                    ]
                }
            },
            {
                "x": "2020-04-24",
                "y": 0,
                "more": {
                    "_id": "2020-04-24",
                    "timeSeriesValue": "2020-04-24"
                }
            },
            {
                "x": "2020-04-25",
                "y": 0,
                "more": {
                    "_id": "2020-04-25",
                    "timeSeriesValue": "2020-04-25"
                }
            }
        ]
      }
    ];
    */
    
    const containerHeight = {
      minHeight: '200px',
      height: 'auto',
    };

    return (
      <div className={classNames({shcModulesBusinessReporting_containerHeight: true, })} ref={this.componentRef}>
        <div data-componentid={this.state.componentId} className={classNames({ shcModulesBusinessReporting_graphWrapper: true })}>
          
        <ResponsiveLine
        onClick={this.chartOnClick}
        data={this.props.reportData}
        margin={{ top: 50, right: 60, bottom: 90, left: 30 }}
        xFormat="time:%Y-%m-%d"
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={this.state.axisBottom}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Interactions',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
          
          
          
          
          
          
          
          {/* 
          <ResponsiveLine
            onClick={this.chartOnClick}
            data={this.props.reportData}
            margin={{ top: 50, right: 60, bottom: 90, left: 30 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={this.state.axisBottom}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Interactions',
              legendOffset: -40,
              legendPosition: 'middle'
          }}
            colors={{ scheme: 'nivo' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
          />
          */}
        </div>
      </div>

    );
  }
}

BusinessChartLine.propTypes = {
  displayType: PropTypes.string,
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

BusinessChartLine.defaultProps = {
  displayType: 'normal',
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};





const mapStateToProps = (state, ownProps) => {
  const businessId = ownProps.businessId;
  let reportData = [];
  let indexOfReportingDataset = ownProps.reportDatasetsByBusinessId.findIndex(rep => rep.businessId === businessId &&
  rep.datasetName === ownProps.reportDatasetName);
  //console.log(`COMPONENT | REPORTING | indexOfReportingDataset: ${indexOfReportingDataset}`);

  try {
    reportData = ownProps.reportDatasetsByBusinessId[indexOfReportingDataset].data;
    //console.log(`COMPONENT | REPORTING | reportData: ${JSON.stringify(reportData)}`);
  } catch (e) {}
  if (reportData === undefined) {
    reportData = [];
  }


  console.log('mapStateToProps | reportData');
  console.log(reportData);

  return {
    reportData: Object.assign([], reportData),

  
  };

};

const mapDispatchToProps = {
  registerReportingComponent,
  unRegisterReportingComponent,
  reportingComponentPing,
  reportingComponentRegister,

  userBusinessInvitationResponse,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessChartLine);


/*
Messages: 0<br />
unverified identities: 10<br />
Notice: Account to be deleted. Learn more.<br />
Subscription: Active<br />

<div className={classNames({ TESTING: true })}>
  <div className={classNames({ flex: true, abc1: true })}>
    <div className={classNames({ a1: true })}>
      Profiles
    </div>
    <div className={classNames({ b1: true })}>
      10
    </div>
    <div className={classNames({ c1: true })}>
      <i
        className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
        aria-label="Verification Expiring Soon"
    />
    </div>
  </div>
</div>
*/


/*
Links at top of card

<div className={classNames({ padR10: true })}>
  <i
    className={`far fa-address-card" ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
    aria-label="Verified"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-mask ${classNames({ ShcIdentityInput_notVerifiedIcon: true })}`}
    aria-label="Not Verified"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
    aria-label="Verification Expiring Soon"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-comments ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
    aria-label="Verified"
    />
</div>
*/