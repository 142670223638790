import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';
import { BusinessConnectInteractionItemContentContainerV1 } from 'Components/ShcModulesConnect';


// Actions
import { reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,

  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class BusinessConnectInteractionAddItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    //Item selected
    this.onItemSelect = this.onItemSelect.bind(this);

    this.state = {

      apiTimerCollection: [], // List of active timers.
      identityData: '',    //All the identity data

      //review
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }



  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }

  onItemSelect() {
    this.props.onSelect();
  }

  
  render() {
    const {onSelect, } = this.props;


    return (
      <div className={classNames({ ShcModulesBusinessConnectInteractionItemAddViewContainerHeader: true, 
        flex: true, flexJustifyCenter: true})}
        onClick={this.onItemSelect}>
        
        <div className={classNames({ flex: true, flexAlignItemsCenter: true, flexJustifyCenter: true })}>
          <div className={classNames({ text_h4: true, text_color_grey_light: true, padR10: true })}>
            <i className={`fas fa-plus ${classNames({ text_color_grey_navlinks: true })}`}></i>
          </div>
          <div className={classNames({ text_h5: true, text_color_grey_light: true })}>
            New Connection
          </div>
        </div>
            
      </div>

    );
  }
}

BusinessConnectInteractionAddItem.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 


  return {

  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectInteractionAddItem);
