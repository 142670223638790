import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';





class IdentityManagementExpandedContentContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

 
  
  render() {
    const { icon, children } = this.props;

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    


    return (
      <div className={classNames({ ShcModulesIdentityManagementExpandContainerHeader: true, flex: true, cursorPointer: true, flexGrow: true })}>
        <div className={classNames({ flexGrow: true, ShcModulesIdentityManagementExpandLeftBorderHightlight: true })}>
          <div className={classNames({ pad10: true, flex: true})}>
              
            {children[0]}

            <div className={classNames({ flexGrow: true })}>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <div className={classNames({ flex: true, flexColumn: true })}>
                      
                    {children[1]}

                    {children[2]}

                  </div>
                </div>
                
              </div>

            </div>
          </div>
          <div className={classNames({ flexGrow: true, ShcModulesIdentityManagementViewExpirySet_panel: false })}>

            

          </div>
        </div>
      </div>

    );
  }
}

IdentityManagementExpandedContentContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementExpandedContentContainerV1);
