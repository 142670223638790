import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');

// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class IdentityManagementPrimaryLocationInputContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.


      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,
      identityInputErrors: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    
    

    //console.log("nextProps.dataSavingReference");
    //console.log(nextProps);
    //console.log(nextProps.dataSavingReference);

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
    

    /*
    let savingData = prevState.savingData;
    let apiCompleted = '';
    try {
      apiCompleted = nextProps.dataSavingReference.apiCompleted;
    } catch(e) {
      ////console.log("unable to get apiCompleted in getDerivied");
    }
    */
    /*
    let apiTransactionId = '';
    try {
      apiTransactionId = nextProps.dataSavingReference.apiTransactionId;
    } catch(e) {
      ////console.log("unable to get lastChangeTransactionId in getDerivied");
    }
    */
   /*
    if (prevState.savingData === false && apiCompleted === true) {
      if (prevState.lastChangeTransactionId !== '' && 
      prevState.lastChangeTransactionId === apiTransactionId) {
        ////console.log("SET THE SYNCSTATUS TO TRUE NOW!!!");
        savingData = false;
      } else {
        ////console.log("CAREFUL | this is not actually the id we are watching for");
      }
    }
    */

    //console.log(`SET SAVING DATA to ${savingData}`);
    //console.log(`UPDATE STATE: ${updateState}`);

    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }
    this.setState({
      identityInputErrors: errors,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }


  handleLocationInputChange = address => {
    //console.log("Handle change from CHILD component (LocationSearchInputGoogleAutoComplete)")
    this.setState({ identityInputValue: address });

    let returnObj = {
      identifiers: this.props.identifiers,
      name: this.props.name,
      stateIdentifier: this.props.stateIdentifier,
      value: address,
    }
    //console.log(returnObj);

    this.updateData(returnObj);

  };



  handleChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const identityInputValue = event.target.value;
    let errors = false;
    const lastChangeTransactionId = uuidv1();

    //console.log(identifiers);
    //console.log(stateIdentifier);

    //console.log(this.props.identityType);
    //console.log(identityInputValue);

    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');

      if (validateDataType(this.props.identityType, identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
    }




    if (errors === true) {
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        savingData: false,
      });
    } else {
      //No errors experienced, save record
      this.setState({
        identityInputErrors: errors,
        identityInputValue,
        lastChangeTransactionId,
        savingData: true,
      });


      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value: identityInputValue,
        lastChangeTransactionId,
      };

      this.updateData(returnObj);
      /*
      const recordsForUpdate = [];
      recordsForUpdate.push(returnObj);

      ////////////////////////////////////////////////////
      //// UPDATE REDUX
      ////////////////////////////////////////////////////
      this.props.reduxDataUpdate({ recordsForUpdate });

      ////////////////////////////////////////////////////
      //// UPDATE VIA THE API
      ////////////////////////////////////////////////////
      // Traverse through every record that is being updated.
      for (let x = 0; x < recordsForUpdate.length; x++) {
        // Get the "Name" which represents the dotPath for the update.
        // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
        const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
        //console.log(timerReferenceGroup);
        //console.log(JSON.stringify(recordsForUpdate[x]));

        this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
      }
      */

      ////////////////////////////////////////////////////
      //// UPDATE PARENT WITH EVENT ACTION
      ////////////////////////////////////////////////////
      this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
      

    } //end if errors
  }


  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "businessIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }




  render() {
    const { 
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,

      identityInputValue,

      icon, children } = this.props;

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    
  

    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
            ShcIdentityInput_textInput_notificationsEnabled_icon: 
            this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
            ShcIdentityInput_textInput_notificationsDisabled_icon: 
            this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ ShcIdentityInput_textNoInput: true, 
              ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
              ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
              {this.state.identityInputValue}
            </div>
            :
              <div>
                <LocationSearchInputGoogleAutoComplete
                name={name}
                value={this.state.identityInputValue}
                onChange={this.handleLocationInputChange}
                />
              </div>
            }
            
          </div>
        </div>
          


          <div className={classNames({ flex: true })}>

              {this.state.savingData && 
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                    <div>
                      <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                      aria-label="Verified"
                      ></i>
                    </div>
                  </div>
              }




              {this.props.verificationStatus && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
              }

              {!this.props.verificationStatus && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>
                </div>
              }





  
             

              {this.state.identityReVerify && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }
              
     
            </div>



      </div>

    );
  }
}




IdentityManagementPrimaryLocationInputContainerV1.defaultProps = {
  identityLocked: false,
};


IdentityManagementPrimaryLocationInputContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  /***************************************
   * Saving data. We only track the last 
   * change tranaction id. 
   **************************************/
  //console.log("ownProps");
  //console.log(ownProps);
  /*
  let savingData = ownProps.savingData;
  let dataSavingReference = '';
  const identityReference = ownProps.lastChangeTransactionId;
  //console.log(`identityReference we are looking for: ${identityReference}`);
  try {
    dataSavingReference = state.Sync.dataRecordUpdates.find(data => {
      if (data.apiTransactionId === identityReference) {
        return data;
      }
    });
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  */
  /*
  try {
    dataSavingReference = state.Sync.dataRecordUpdates.find(data => {
      if (data.reference === identityReference && JSON.stringify(data.identifiers) === JSON.stringify(ownProps.identifiers)) {
        return data;
      }
    });
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  */

  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  /*  
  if (dataSavingReference !== '' && dataSavingReference !== undefined) {

    //console.log("mapStateToProps | dataSavingReference");
    //console.log(dataSavingReference);
    //console.log(dataSavingReference.apiCompleted);
    //console.log(JSON.stringify(dataSavingReference));
    if (dataSavingReference.apiCompleted === true) {
      savingData = false;
    } else {
      savingDataError = true;
    }
  }
  */

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementPrimaryLocationInputContainerV1);
