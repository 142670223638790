import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


class BusinessConnectInteractionItemContentContainerV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { icon, interactionActiveStatus, interactionActionRequired, children } = this.props;

  
    return (
      <div className={classNames({ ShcModulesBusinessConnectInteractionItemExpandContainerHeader: true, 
        ShcModulesBusinessConnectInteractionExpiredBackground: interactionActiveStatus===true ? false : true,
        flex: true, cursorPointer: true, flexGrow: true })}>
        <div className={classNames({ flexGrow: true, 
          ShcModulesBusinessConnectInteractionItemLeftBorder_Verified: interactionActiveStatus===true ? true : false,
          ShcModulesBusinessConnectInteractionItemLeftBorder_ActionRequired: interactionActionRequired===true ? true : false,
          ShcModulesBusinessConnectInteractionItemLeftBorder_Issues: interactionActiveStatus===false ? true : false,
           })}>
          <div className={classNames({ pad10: true, flex: true})}>
              
            {children[0]}

            <div className={classNames({ flexGrow: true })}>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <div className={classNames({ flex: true, flexColumn: true })}>
                      
                    {children[1]}

                  </div>
                </div>
                
              </div>

            </div>

            {children[2]}

          </div>
        </div>
      </div>

    );
  }
}

BusinessConnectInteractionItemContentContainerV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectInteractionItemContentContainerV1);
