
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class SingleLineAccessKeyItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.revealSecretKey = this.revealSecretKey.bind(this);

    this.state = {
      revealedKey: '',
      maskedKey: props.maskedKey,
      businessId: props.businessId,
      keyId: props.keyId,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }

  revealSecretKey(evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const data = {
      keyId: this.props.keyId,
      keyItemId: this.props.keyItemId,
    }
    const business = {
      businessId: this.state.businessId,
    }


    console.log("API Call, get secret key and show only for while this window isopen");
    console.log(`maskedKey: ${this.state.maskedKey}`);
    console.log(`businessId: ${this.state.businessId}`);
    console.log(`keyId: ${this.state.keyId}`);

    this.props.revealSecretKey({business, data, apiUi})
  }


  render() {
    const { secretKey, maskedKey, businessId, keyId, keyItemId, revealSecretKey } = this.props;
    let showKey = false;
    if (secretKey !== '' && secretKey.length > 15) {
      showKey = true;
    } else {
      showKey = false;
    }


    return (
      <div >
        <div className={classNames({ ShcModulesApiMgt_container: true })}>
          {showKey === true ? (
            <div className={classNames({ ShcModulesApiMgt_keyText_small: true })}>
              {secretKey}
            </div>
          ):(
            <div className={classNames({ ShcModulesApiMgt_keyText: true })}>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ ShcModulesApiMgt_maskedKey: true })}>
                  {maskedKey}
                </div>
                <div className={classNames({ ShcModulesApiMgt_maskedKeyReveal: true })}>
                  <StandardApiBtn
                  apiActivityId={`RevealKey_${businessId}_${keyId}_${keyItemId}`}
                  icon=""
                  iconLocation=""
                  text="Show Key"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-small shcbtn-outline-info"
                  handleClick={this.revealSecretKey}
                  />
                </div>
              </div>
              
            </div>
          )}
        </div>
      </div>

    );
  }
}

SingleLineAccessKeyItem.propTypes = {
  maskedKey: PropTypes.string,
};

SingleLineAccessKeyItem.defaultProps = {
  maskedKey: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SingleLineAccessKeyItem);
