import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI
import Divider from '@material-ui/core/Divider';
// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';

// Actions

// APIs


class simpleDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,

      divRenderedForGetBounding: false,
      divXtoLeft: 0,
      divXtoRight: 0,
      divYtoTop: 0,
      divYtoBottom: 0,
      divWidth: 0,
      divHeight: 0,
      divX: 0,
      divY: 0,
      menuWidth: this.props.menuWidth,

      debug: this.props.debug,


      positionMenuToLeft: false,

    };

    const menuStyle = {};

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }


  componentWillMount(props) {
    //if (this.props.debug) { //console.log('Will Mount menu card.'); }

    // const menuBounding = this.dropdownMenu.getBoundingClientRect();
  }

  componentDidUpdate() {
    ////console.log('component updated');
  }

  componentDidMount() {
    // If we haven't rendered and got the bounding for the menu button, get the data.
    if (this.state.divRenderedForGetBounding === false) {
      const divBounding = this.divElement.getBoundingClientRect();
      ////console.log(divBounding);

      ////console.log(`divXtoLeft: ${divBounding.left}`);
      ////console.log(`menuWidth: ${this.state.menuWidth}`);
      ////console.log(`innerWidth: ${window.innerWidth}`);

      ////console.log(`debug: ${this.state.debug}`);
      ////console.log(`menuWidth: ${this.state.menuWidth}`);


      let positionMenuToLeft = false;
      if (this.state.menuWidth === 0) {

      }
      if (divBounding.left + this.state.menuWidth > window.innerWidth) {
        positionMenuToLeft = true;
      }


      this.setState({
        divXtoLeft: divBounding.left,
        divXtoRight: divBounding.right,
        divYtoTop: divBounding.top,
        divYtoBottom: divBounding.bottom,
        divWidth: divBounding.width,
        divHeight: divBounding.height,
        divX: divBounding.x,
        divY: divBounding.y,
        positionMenuToLeft,
        divRenderedForGetBounding: true,
      }, () => {
        this.menuStyle = {
          width: `${this.state.menuWidth}px`,
        };
      });
    }
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (event !== undefined && this.dropdownMenu !== undefined && this.dropdownMenu !== null) { // Added validation for undefined
      try {
        if (!this.dropdownMenu.contains(event.target)) {
          this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
          });
        }
      } catch(e) {}
    }
  }


  render() {
    const MenuItems = () =>
      this.props.menuItems.map((value, index) => {
        if (this.props.menuFormat === 'icons') {
          // <icon> <text>
          return (
            <div key={index} >
              {value.divideBefore ?
                <Divider className={classNames({ shcMenuSimpleDropdownItem_divide: true })} />
              :
              null
              }
              <Link to={value.link} className={classNames({ textDecoration: true})}>
                <div className={classNames({ shcMenuSimpleDropdownItem: true, shcMenuSimpleDropdownFormatIcons: true })}>
                  <div key={index} className={classNames({ text_h5: true, shcMenuSimpleDropdownItem_icon: true, color_primary_dark: true })}>
                    <i className={value.icon} />
                  </div>
                  <div key={index} className={classNames({ text_h5: true, shcMenuSimpleDropdownItem_text: true, color_primary_dark: true })}>
                    {value.title}
                  </div>
                </div>
              </Link>
              {value.divideAfter ?
                <Divider className={classNames({ shcMenuSimpleDropdownItem_divide: true })} />
              :
              null
              }
            </div>
          );
        }
        // <text>
        return (
          <div key={index} >
            {value.divideBefore ?
              <Divider className={classNames({ shcMenuSimpleDropdownItem_divide: true })} />
            :
            null
            }
            <Link to={value.link} className={classNames({ textDecoration: true})}>
              <div className={classNames({ shcMenuSimpleDropdownItem: true, shcMenuSimpleDropdownFormatIcons: true })}>
                <div key={index} className={classNames({ text_h5: true, shcMenuSimpleDropdownItem_text: true, color_primary_dark: true })}>
                  {value.title}
                </div>
              </div>
            </Link>
            {value.divideAfter ?
              <Divider className={classNames({ shcMenuSimpleDropdownItem_divide: true })} />
            :
            null
            }
          </div>
        );
      })

      /* //console.log('RENDER MENU DATA');
      //console.log(value);
      //console.log(value.title); */
    ;

    return (
      <div
        className={classNames({ shcMenuContainer: true })}
      >
        <div
          ref={divElement => this.divElement = divElement}
          onClick={this.showMenu}
        >
          {this.props.children}
        </div>

        {
          this.state.showMenu
            ? (
              <div
                className={classNames({
                  shcMenu: true,
                  shcMenuSimpleDropdown: true,
                  shcMenuSimpleDropdownMinWidth: true,
                  shcMenuPositionLeft: this.state.positionMenuToLeft,
                })}
                //style={this.menuStyle}
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <MenuItems />
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}

simpleDropdown.propTypes = {
  width: PropTypes.integer,
};

simpleDropdown.defaultProps = {
  debug: false,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(simpleDropdown);
