import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';



// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2 } from 'Components/ShcCards';

// Actions
import { businessIdentityVerification } from 'Actions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.verifyIdentity = this.verifyIdentity.bind(this);

    this.state = {
      verificationLoading: true,
      verificationStatus: false,
      verificationMessage: '',
    };


    

  
  }

  componentDidMount(props) {
    //console.log("Admin Verification Email - On Mount, gather url paramaters, send to api endpoint for verification");
    this.verifyIdentity();

  }

  verifyIdentity() {
    //console.log("PARAMS (construct)");
    //console.log(this.props.match.params.businessId);
    //console.log(this.props.match.params.identityId);
    //console.log(this.props.match.params.verificationCode);

    let businessId = '';
    try {
      businessId = this.props.match.params.businessId;
    } catch (e) {}
    let identityId = '';
    try {
      identityId = this.props.match.params.identityId;
    } catch (e) {}
    let verificationCode = '';
    try {
      verificationCode = this.props.match.params.verificationCode;
    } catch (e) {}

    let data = {
      businessId,
      identityId,
      verificationCode,
    }
    console.log(data);

    this.props.businessIdentityVerification({data})
    .then((result) => {
      //console.log("result");
      //console.log(result);

      this.setState({
        verificationLoading: false,
        verificationStatus: true,
        verificationMessage: 'Already Verified',
      });

    })
    .catch((error) => {
      //console.log("ERROR EXPERIENCED");
      this.setState({
        verificationLoading: false,
        verificationStatus: false,
        verificationMessage: 'Already Verified',
      });
    })
  }


  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ pad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
            {this.props.title}
          </div>

          <div>
            <CardBasicV2>
              <div>
                <div className={classNames({ text_h3: true })}>
                  Email Verification
                </div>

                {this.state.verificationLoading ?
                (
                  <div className={classNames({ text_h3: true })}>
                    Verifying...
                  </div>
                ):(
                  <div>
                    {this.state.verificationStatus ?
                    (
                      <div>Verification Successful</div>
                    ):(
                      <div>Verification Failed</div>
                    )}
                  </div>
                )}


              </div>
            </CardBasicV2>
          </div>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  businessIdentityVerification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
