import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';

// Routing
//import Routes from 'Core/Routes';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class ShcFooterBaseSocial extends React.Component {
  state = {
  };
  render() {

    const linkedInSquare = "fab fa-linkedin";
    const facebookSquare = "fab fa-facebook-square";
    const youtube = "fab fa-youtube";
    const twitter = "fab fa-twitter-square";
    
    return (
      <div className={classNames({shcFooterBaseSocial:true})}>
        <div className={classNames({shcFooterBaseSocial_container:true, flex: true})}>

    
       
          <div className={classNames({ flex: true, flexJustifyCenter: true, flexGrow: true })}>
            <div className={classNames({flex: true, flexJustifyCenter:true, flexAlignItemsCenter:true})}>
                
                <div>
                  <a href={`https://www.linkedin.com/company/guardpoint`} target='_blank'><i className={`${linkedInSquare} ${classNames({text_30:true, colour_white:true})} `}/></a>
                </div>
                <div className={classNames({shcFooterBaseSocial_pipeSpacers: true, })}>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </div>
                <div>
                  <a href={`https://www.facebook.com/GuardPoint.me`} target='_blank'><i className={`${facebookSquare} ${classNames({text_30:true, colour_white:true})} `}/></a>
                </div>
                <div className={classNames({shcFooterBaseSocial_pipeSpacers: true, })}>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </div>
                <div>
                  <a href={`https://www.youtube.com/channel/UCbp9Xd4ZWC8_ZKVqUPsukWw`} target='_blank'><i className={`${youtube} ${classNames({text_30:true, colour_white:true})} `}/></a>
                </div>
                <div className={classNames({shcFooterBaseSocial_pipeSpacers: true, })}>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </div>
                <div>
                  <a href={`https://twitter.com/GuardPointCyber`} target='_blank'><i className={`${twitter} ${classNames({text_30:true, colour_white:true})} `}/></a>
                </div>

              </div>
          </div>




        </div>
      </div>
    );
  }
}

ShcFooterBaseSocial.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcFooterBaseSocial);