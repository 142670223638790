// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI

// CSS

// Components

// Actions


const hasOwnProperty = Object.prototype.hasOwnProperty;

function isEmpty(obj) {

  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

class SidebarSubNavBackLink extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const {
      title, urlmatch, isAuthenticated, isAdmin,
    } = this.props;
    let { to } = this.props;

    //console.log(`to: ${to}`);

    let escapeRE;
    let toPath;
    //console.log(urlmatch);
    const urlparams = urlmatch.params;
    if (!isEmpty(urlparams)) {
      // If params are not empty
      toPath = Object.keys(urlparams).map((key) => {
        //console.log(`looking at: ${key} ${urlparams[key]}`);
        escapeRE = new RegExp(`/:${key}`);
        //console.log(escapeRE);
        // console.log(to.replace(escapeRE, `/${urlparams[key]}`));
        return to.replace(escapeRE, `/${urlparams[key]}`);
        // console.log(to);
        // console.log(`path replacement: ${urlparams[key]}`);
      });

      //console.log(`toPath: ${toPath}`);
      if (toPath === '' || toPath === undefined) {
        // to will remain as it is unaltered
      } else {
        to = toPath;
      }
    }

    if (Array.isArray(to)) {
      to = to[0];
    }

    return (
      <div>
        <div>
          <Link
            to={to}
            className={classNames({textDecoration: true, linkGroup_navigationLinks: true, linkGroup_navigationLinkHighlight: true })}
          >
            <div className={classNames({ linkGroup_listItem: true, linkGroup_listItemParent: true })}>
              <div className={classNames({ linkGroup_listBackItem: true })}>
                <div className={classNames({ linkGroup_extendedSideBarBackIconLeft: true })}>
                  <i className="fas fa-arrow-left" />
                </div>
                <div className={classNames({ linkGroup_extendedSideBarBackTitle: true })}>
                  {title}
                </div>
                
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


SidebarSubNavBackLink.propTypes = {

};

export default compose(connect(mapStateToProps))(SidebarSubNavBackLink);
