import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import { } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class CheckboxOptionCardV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      checkboxState: this.props.checkboxState,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('getDerivedStateFromProps - CheckboxOptionCardV2');

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');
    //console.log(this.state.checkboxState);

    const newState = !this.state.checkboxState;

    this.setState(prevState => ({
      checkboxState: !prevState.checkboxState,
    }));

    const returnObj = {
      name: this.props.name,
      identifiers: this.props.identifiers,
      stateIdentifier: this.props.stateIdentifier,
      value: newState,
      errors: false,
      lastChangeTransactionId: '',
    };

    this.props.onChangeHandler(returnObj);
    //this.props.onChangeHandlerAction2(returnObj); //Additional action (e.g. popup on tickbox being selected)

    

  }

  render() {
    const {
      businessId,
      checkboxText,
      checkboxState,
      id,
      name,
      identifiers,
      stateIdentifier,
      onChangeHandler,
      onChangeHandlerAction2,
    } = this.props;

    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ aaa: true })}>
          {this.state.checkboxState ?
            <StandardIcon
              icon="far fa-check-square"
              classes="colour_primary"
              handleClick={this.toggleSelectedItem}
            />
          :
            <StandardIcon
              icon="far fa-square"
              classes=""
              handleClick={this.toggleSelectedItem}
            />
          }
        </div>
        <div className={classNames({ padL10: true, flex: true, flexAlignItemsCenter: true, })}>
          { checkboxText ?
            <div>
              <div className={classNames({ ShcCheckboxOptionCard_checkboxText: true })}>
                {checkboxText}
              </div>
            </div>
          : null }
        </div>
      </div>
    );
  }
}

CheckboxOptionCardV2.propTypes = {

};
CheckboxOptionCardV2.defaultProps = {

};


const mapStateToProps = (state, ownProps) => ({
  });

const mapDispatchToProps = {
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckboxOptionCardV2);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(CheckboxOptionCardV2);
