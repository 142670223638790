import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';


// Routing
//import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions

class ShcHeader extends React.Component {
  state = {
  };

  render() {
    const { height } = this.props;

    const containerHeight = {
      height: `${height}`,
    };

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";
    
    return (
      <div className={classNames({shcHeaderSubPanel:true, shcHeaderSubPanelContainerHeight: true})}
      >
        <div className={classNames({
          shcHeaderSubPanel_AppBar:true,
          })}
        >
          <div className={classNames({
            shcHeaderSubPanel_toolbar:true, 
            shcHeaderSubPanel_contentMarginPermanent: true
            })}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <div className={classNames({shcHeaderSubPanel_contentContainer:true})}>
              {this.props.children}
            </div> 
          </div>
        </div>
      </div>
    );
  }
}

ShcHeader.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcHeader);