


//addTimeToDateTime("addTimeToNow", "min", 30)
export function addTimeToDateTime(req, addFormat, addValue, fromValue = '') {
  var dateNow;
  var dateObj;
  var newDate;
  
  if (req === "addTimeToNow") {
    dateNow = Date.now();
    dateObj = new Date(dateNow); 

    if (addFormat === "secs") {
      newDate = new Date(dateObj.getTime() + addValue * 1000);  
                                            //seconds 
    } else if (addFormat === "mins") {
      newDate = new Date(dateObj.getTime() + addValue * 60 * 1000);  
                                            //minutes
    } else if (addFormat === "hrs") {
      newDate = new Date(dateObj.getTime() + addValue * 60 * 60 * 1000);  
                                            //hours
    } else if (addFormat === "days") {
      newDate = new Date(dateObj.getTime() + addValue * 24 * 60 * 60 * 1000);  
                                            //days
    }

    let dateData = {
      dateString: newDate, 
      dateTimestampMs: Date.parse(newDate),
      dateTimestampSec: unixTimestampMillisecondsToSeconds(Date.parse(newDate)),
    }
    return dateData;

  } else if (req === "minusTimeFromValue") {

    //fromValue - make sure its in miliseconds
    fromValue = unixTimestampSecondsToMilliseconds(fromValue);
    dateObj = new Date(fromValue); 

    if (addFormat === "secs") {
      newDate = new Date(dateObj.getTime() - addValue * 1000);  
                                            //seconds 
    } else if (addFormat === "mins") {
      newDate = new Date(dateObj.getTime() - addValue * 60 * 1000);  
                                            //minutes
    } else if (addFormat === "hrs") {
      newDate = new Date(dateObj.getTime() - addValue * 60 * 60 * 1000);  
                                            //hours
    } else if (addFormat === "days") {
      newDate = new Date(dateObj.getTime() - addValue * 24 * 60 * 60 * 1000);  
                                            //days
    }

    let dateData = {
      dateString: newDate, 
      dateTimestampMs: Date.parse(newDate),
      dateTimestampSec: unixTimestampMillisecondsToSeconds(Date.parse(newDate)),
    }
    return dateData;

  } else if (req === "addTimeFromValue") {

    //fromValue - make sure its in miliseconds
    fromValue = unixTimestampSecondsToMilliseconds(fromValue);
    dateObj = new Date(fromValue); 

    if (addFormat === "secs") {
      newDate = new Date(dateObj.getTime() + addValue * 1000);  
                                            //seconds 
    } else if (addFormat === "mins") {
      newDate = new Date(dateObj.getTime() + addValue * 60 * 1000);  
                                            //minutes
    } else if (addFormat === "hrs") {
      newDate = new Date(dateObj.getTime() + addValue * 60 * 60 * 1000);  
                                            //hours
    } else if (addFormat === "days") {
      newDate = new Date(dateObj.getTime() + addValue * 24 * 60 * 60 * 1000);  
                                            //days
    }

    let dateData = {
      dateString: newDate, 
      dateTimestampMs: Date.parse(newDate),
      dateTimestampSec: unixTimestampMillisecondsToSeconds(Date.parse(newDate)),
    }
    return dateData;

  } else {
    return {};
  }

}

export function unixTimestampMillisecondsToSeconds(timestamp) {
  let num = timestamp;
  if (num.toString().length >=13) {
    //In milliseconds
    num = (num-(num%1000))/1000;
  }
  return num;
}
export function unixTimestampSecondsToMilliseconds(timestamp) {
  let num = timestamp;
  if (num.toString().length >=13) {
  } else {
    //In milliseconds
    num = num*1000;
  }
  return num;
}

export function timeNow() {
  var dateNow;
  var dateObj;
  var newDate;
  
  dateNow = Date.now();
  dateObj = new Date(dateNow); 
  newDate = new Date(dateObj.getTime());  

  let dateData = {
    dateString: newDate, 
    dateTimestampMs: Date.parse(newDate),
    dateTimestampSec: unixTimestampMillisecondsToSeconds(Date.parse(newDate)),
  }
  return dateData;
}


