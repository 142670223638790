import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class InputTextV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: this.props.value,
      // pattern: this.props.pattern,
      // maxlength: this.props.maxlength,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('getDerivedStateFromProps - ShcInput');
    // console.log(`${nextProps.value} !== ${prevState.value}`);
    // if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
    // return { value: nextProps.value };
    // }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate - ShcInput');
    // console.log(`${prevProps.value} !== ${this.props.value}`);
    // if (prevProps.value !== this.props.value) {
    // Perform some operation here
    // this.setState({ value: this.props.value });
    // }
  }

  componentDidMount() {
    // console.log('componentDidMount - ShcInput');
    // if (this.props.value !== this.state.value) {
    // }
  }

  handleChange(event) {
    this.props.onChangeInputTextHandler(event);
    let textStyle;
    if (this.props.feature === 'AsYouType') {
      textStyle = new AsYouType().input(event.target.value);
      this.setState({ value: textStyle });
    } else {
      this.setState({ value: event.target.value });
    }

    /*
    //console.log("Regular Expression Matching Validatior");
    //console.log(this.state.pattern);
    //console.log(this.state.maxlength);
    //console.log(this.props.maxlength);
    let re = new RegExp(this.state.pattern, 'gi');
    let found = event.target.value.match(re);
    //console.log(found);
    //console.log("end regular expression matching");
    */
  }

  render() {
    const {
      id, name, label, onChangeInputTextHandler, maxlength, pattern,
      feature = '', passwordField = false,
    } = this.props;

    // AsYouType().input(

    return (
      <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
        })}
      >
        {passwordField === true ? (
          <input 
          {...this.props}
          id={id}
          label={label}
          margin="normal"
          fullWidth
          name={name}
          value={this.state.value}
          onChange={this.handleChange}
          type="text" 
          maxlength="200"   //hardcoded max
          type="password"
          className={classNames({ ShcInputContainer_textInput: true })} 
        />
        ):(
          <input 
          {...this.props}
          id={id}
          label={label}
          margin="normal"
          fullWidth
          name={name}
          value={this.state.value}
          onChange={this.handleChange}
          type="text" 
          maxlength="200"   //hardcoded max
          className={classNames({ ShcInputContainer_textInput: true })} 
        />
        )}
      </div>
    );
  }
}
/*
InputProps={{
  inputProps: {
    maxLength: this.state.maxLength,
    pattern: this.state.pattern,
  }
}}
*/

InputTextV2.propTypes = {

};
InputTextV2.defaultProps = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputTextV2);

