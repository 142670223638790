import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';

// Routing
//import Routes from 'Core/Routes';


// CSS
import componentStyles from './styles.css';

// Components
import { MenuAccount, MenuAdmin } from 'Components/Menu';
import ListItemCollapse from 'Components/ListItemCollapse';
import { SidebarNavLinks } from 'Components/ShcHeader';
import { SimpleDropdown } from 'Components/ShcMenus';
import { StandardBtn } from 'Components/ShcButtons';
import { StandardText } from 'Components/ShcText';

// Actions
import { sidebarOpenState } from 'Actions';


class FooterNoBrandingLinks extends React.Component {
  state = {
  };
  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";
    
    return (
      <div className={classNames({shcFooterPrimaryLinks:true})}>
        <div className={classNames({shcFooterPrimaryLinks_container:true, flex: true,})}>

          <div className={classNames({ flex: true, flexJustifyCenter: true, flexGrow: true })}>
            <div className={classNames({ text_s14: true })}>
              Guard Point Pty Ltd | ACN: 639 579 025
            </div>
          </div>

        </div>
      </div>
    );
  }
}

FooterNoBrandingLinks.propTypes = {

};


const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
  forceUserRegistration: state.User.forceUserRegistration,

})

const mapDispatchToProps = {
  sidebarOpenState,
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(FooterNoBrandingLinks);