import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { StepBusinessOverview, StepBusinessIdentity, StepBusinessMethods, StepBusinessVerify } from 'Components/ShcSteps/BusinessIdentityVerification';


// Actions
//import {removeBusinessWebsiteVerificationItem } from 'Actions';


class BusinessConnectProfileSelectionItems extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.state = {
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }


  render() {
    //const {  } = this.props;


    /*
    const IdentityActivityHeading = () => {
      if (this.state.businessIdentity.type==="website") {
        return (
          <TextStyle size="h2">Website Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="email") {
        return (
          <TextStyle size="h2">Email Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="phone") {
        return (
          <TextStyle size="h2">Phone Identity</TextStyle>
        );
      } else {
        <TextStyle size="h2">Unknown Identity Type</TextStyle>
      }
    }

    const CurrentStatus = () => {
      if (this.state.businessIdentity.verificationStatus===true) {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
            <i class="fas fa-check"></i>
          </div>
        );
      } else {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_danger: true })}>
            <i class="fas fa-times"></i>
          </div>
        );
      }
    }
    */

    return (
      <div className={classNames({ ShcAccountModulesChildContainer: true })}>
        <div className={classNames({ ShcAccountModulesChildContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>458345807234907234823</div>
                  <div className={classNames({ text_h4: true })}>Auth Channel</div>
                  <div className={classNames({ text_h6: true })}>website, phone, email</div>
                  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
                <i class="fas fa-hand-pointer"></i>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcAccountModulesChildContainerBody: true })}>
          No expanding
        </div>
        }
        
      </div>

    );
  }
}

BusinessConnectProfileSelectionItems.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectProfileSelectionItems);
