import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

// Routing

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setuserlogout } from 'Actions';

// Notes



class MenuAccount extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onClickLogout = () => {
    //console.log('onClickLogout');
    this.props.setuserlogout();
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classNames({ ShcMenuItem_Button: true, })}
        >
          {this.props.children}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <div className={classNames({ShcMenuItemHeaderContainer: true})}>
              <div className={classNames({ShcMenuItem_Name: true})}>Selby</div>
              <div className={classNames({ShcMenuItem_Email: true})}>Selby.Steer@gmail.com</div>
            </div>
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <Link to="/account/dashboard" className={classNames({textDecoration: true, ShcMenuItem: true})}>Account</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <Link to="/property/search/leaflet" className={classNames({textDecoration: true,})}>Search Leaflet</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <Link to="/property/search/google" className={classNames({textDecoration: true,})}>Search Google</Link>
          </MenuItem>

          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            My Property Portfolio
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            Saved Properties
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            Saved Searches & Alerts
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            My Profile
          </MenuItem>
          <MenuItem onClick={this.onClickLogout}>Logout</MenuItem>

          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <Link to="/sign-up" className={classNames({textDecoration: true,})}>Sign Up</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose} className={classNames({ShcMenuItem: true})}>
            <Link to="/login" className={classNames({textDecoration: true,})}>Login</Link>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

MenuAccount.propTypes = {
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

const mapDispatchToProps = {
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MenuAccount);