import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Select from 'react-select';

// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2 } from 'Components/ShcCards';

// Actions
import { authReinstatement } from 'Actions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.authReinstatement = this.authReinstatement.bind(this);

    this.state = {
      verificationLoading: true,
      verificationStatus: false,
      verificationMessage: '',
    };


    

  
  }

  componentDidMount(props) {
    //console.log("Admin Verification Email - On Mount, gather url paramaters, send to api endpoint for verification");
    this.authReinstatement();

  }

  authReinstatement() {
    //console.log("PARAMS (construct)");
    //console.log(this.props.match.params.userId);
    //console.log(this.props.match.params.userIdentityId);
    //console.log(this.props.match.params.verificationCode);

    let reinstatementCode = '';
    try {
      reinstatementCode = this.props.match.params.reinstatementCode;
    } catch (e) {
      reinstatementCode = '';
    }

    let userDataRegion = '';
    try {
      userDataRegion = this.props.match.params.userDataRegion;
    } catch (e) {
      userDataRegion = '';
    }
    

    this.props.authReinstatement({reinstatementCode, userDataRegion})
    .then((result) => {
      console.log("authReinstatement result");
      console.log(result);

      if (result.appStatus === true) {
        this.setState({
          verificationLoading: false,
          verificationStatus: true,
        });
      } else {
        this.setState({
          verificationLoading: false,
          verificationStatus: false,
        });
      }
    })
    .catch((error) => {
      //console.log("ERROR EXPERIENCED");
      this.setState({
        verificationLoading: false,
        verificationStatus: false,
      });
    })
  }


  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ pad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
            {this.props.title}
          </div>

          <div>
            <CardBasicV2>
              <div>
                <div className={classNames({ text_h3: true })}>
                  Authentation Reinstatement
                </div>

                {this.state.verificationLoading ?
                (
                  <div className={classNames({ text_h3: true })}>
                    Processing...
                  </div>
                ):(
                  <div>
                    {this.state.verificationStatus ?
                    (
                      <div>Reinstated Authentcation Factor Successfully</div>
                    ):(
                      <div>Reinstated Authentcation Factor Failed</div>
                    )}
                  </div>
                )}


              </div>
            </CardBasicV2>
          </div>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  authReinstatement,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
