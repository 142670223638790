import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setUIApiActivity } from 'Actions';


class StandardApiBtn extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      apiActivityId: this.props.apiActivityId,
      apiLoading: false,
      apiStatusSuccess: false,
      apiStatusFailure: false,
      apiMessage: '',
    };
    this.handleClickAction = this.handleClickAction.bind(this);
  }

  // uiApiActivity
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('getDerivedStateFromProps - StandardApiBtn');
    // console.log('prevState');
    // console.log(prevState);
    // console.log('nextProps');
    // console.log(nextProps);

    try {
      // console.log(nextProps.uiApiActivity.find(e => e.id === nextProps.apiActivityId));
    } catch (e) {
      // console.log('unable to find apiActivityId in uiApiActivity');
    }


    // console.log(prevState.apiActivityId);

    let updateState = false;
    let apiLoadingState = prevState.apiLoading;
    let apiStatusSuccessState = prevState.apiStatusSuccess;
    let apiStatusFailureState = prevState.apiStatusFailure;
    try {
      if (nextProps.uiApiActivity.find(e => e.id === nextProps.apiActivityId) !== prevState.apiActivityId) {
        // console.log('MATCHED - apiActivityId');
        try {
          if (nextProps.uiApiActivity.find(e => e.id === nextProps.apiActivityId).status === 'successful') {
            // console.log('Found Status to Match: Successful');
            apiLoadingState = false;
            apiStatusSuccessState = true;
            updateState = true;
          } else if (nextProps.uiApiActivity.find(e => e.id === nextProps.apiActivityId).status === 'failure') {
            // console.log('Found Status to Match: Failure');
            apiLoadingState = false;
            apiStatusSuccessState = false;
            apiStatusFailureState = true;
            updateState = true;
          }
        } catch (e) {
          // no actions
          // console.log('REMOVE - apiActivityId - stop this one loading');
          apiLoadingState = false;
          apiStatusSuccessState = false;
          apiStatusFailureState = false;
          updateState = true;
        }
      }
    } catch (e) {
      // console.log('unable to find apiActivityId in uiApiActivity');
    }

    if (updateState === true) {
      // console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      // console.log('uiApiActivity');
      // console.log(nextProps.uiApiActivity);
      return {
        apiLoading: apiLoadingState,
        apiStatusSuccess: apiStatusSuccessState,
        apiStatusFailure: apiStatusFailureState,
      };
    }
    return null;
  }


  handleClickAction(evt) {
    // console.log('=== On Button: handleClickAction ===');
    // console.log('Activate Spinner Icon. Register API ID and watch for the change');

    this.setState({
      apiLoading: true,
    });

    // action for adding UI API event
    // UI Components
    /*
    ACTION
    - pass the apiActivityId through as "API STARTED"
    */
    this.props.setUIApiActivity({ id: this.state.apiActivityId });

  }


  render() {
    const { classes, handleClick, apiActivityId, fullWidth } = this.props;
    // split by space, remove spaces and map a function for name: true and print

    let fullWidthBtn = false;
    if (fullWidth === true || fullWidth === "true") {
      fullWidthBtn = true;
    }

    const icon = this.props.icon;
    // console.log('icon...');
    // console.log(icon);
    const text = this.props.text;
    // console.log('text...');
    // console.log(text);
    const iconLocation = this.props.iconLocation;
    // console.log('iconLocation...');
    // console.log(iconLocation);

    let displayIcon = false;
    if (icon != null && icon != '') {
      displayIcon = true;
    }

    let iconLocationLeft = false;
    let iconLocationRight = false;
    if (iconLocation === 'left') {
      iconLocationLeft = true;
    } else if (iconLocation === 'right') {
      iconLocationRight = true;
    }

    const tooltip = this.props.tooltip;
    const buttonActive = false;

    const ButtonContent = () => {
      if (displayIcon) {
        if (!text) {
          return (
            <div>
              <span className={classNames({ outlineBtnAttrHidden: !iconLocationLeft })}><i className={icon} /></span>
            </div>
          );
        }
        if (iconLocation === 'left') {
          return (
            <div>
              <span className={classNames({ outlineBtnAttrHidden: !iconLocationLeft, outlineBtnLeftIcon: iconLocationLeft })}><i className={icon} /></span>
              {text}
            </div>
          );
        }
        return (
          <div>
            {text}
            <span className={classNames({ outlineBtnAttrHidden: !iconLocationRight, outlineBtnRightIcon: iconLocationRight })}><i className={icon} /></span>
          </div>
        );
      }
      return (
        <div>
          {text} {this.state.apiLoading === true ? (
            <span className={classNames({ outlineBtnRightIcon: true })}> <i className="fas fa-circle-notch fa-spin" /> </span>
          ) : null}

          {this.state.apiStatusSuccess === true ? (
            <span className={classNames({ outlineBtnRightIcon: true })}> <i className="fas fa-check-circle" /> </span>
          ) : null}

          {this.state.apiStatusFailure === true ? (
            <span className={classNames({ outlineBtnRightIcon: true })}> <i className="fas fa-exclamation-triangle" /> </span>
          ) : null}


        </div>
      );
    };


    return (
      <div className={classNames({ outlineBtnWrapper: true, width100p: fullWidthBtn })}>
        <button
          data-apiactivityid={apiActivityId}
          className={`${classes} 
          ${classNames({
          })}`}
          // onClick={this.handleClickAction}
          onClick={(evt) => {
            this.handleClickAction(evt);
            handleClick(evt);
          }}
          // onMouseLeave={handleOnMouseLeave}
          // onMouseOut={handleOnMouseOut}
          // onMouseOver={handleOnMouseOver}
          /*
          onMouseLeave={(evt) => {
            this.onMouseLeave(evt);
          }}
          onMouseOut={(evt) => {
            this.onMouseOut(evt);
          }}
          onMouseOver={(evt) => {
            this.onMouseOver(evt);
          }}
          */
        >
          <ButtonContent />

        </button>


      </div>
    );
  }
}

StandardApiBtn.propTypes = {

};

StandardApiBtn.defaultProps = {
  handleOnMouseLeave: null,
  handleOnMouseOut: null,
  handleOnMouseOver: null,
};

const mapStateToProps = state => ({
  uiApiActivity: state.UI.uiApiActivity,
});

const mapDispatchToProps = {
  setUIApiActivity,
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(StandardApiBtn);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(StandardApiBtn);
