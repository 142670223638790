import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link from 'react-router-dom/Link';
import Redirect from 'react-router-dom/Redirect';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { userBusinessInvitationResponse, businessUsersRemoveCurrentUser } from 'Actions';


class BusinessDashboardCardV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.businessUserAcceptInvitation = this.businessUserAcceptInvitation.bind(this);
    this.businessUserDeclineInvitation = this.businessUserDeclineInvitation.bind(this);
    this.businessUserRemoveAccount = this.businessUserRemoveAccount.bind(this);
    this.handleOpenBusiness = this.handleOpenBusiness.bind(this);
    

    this.state = {
      redirectToBusinessDashboard: false,

      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkText: props.linkText,
      height: props.height,
    };
  }

  componentDidMount(props) {
    //console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate.');
  }

  businessUserAcceptInvitation(evt) {
    //console.log("businessUserAcceptInvitation");
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let apiUi = {
      apiActivityId,
    }
    let data = {
      businessId: this.props.businessId,
      userInvitationResponse: 'accept'
    }
    this.props.userBusinessInvitationResponse({data, apiUi});
  }

  businessUserDeclineInvitation(evt) {
    //console.log("businessUserDeclineInvitation");
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let apiUi = {
      apiActivityId,
    }
    let data = {
      businessId: this.props.businessId,
      userInvitationResponse: 'decline'
    }
    this.props.userBusinessInvitationResponse({data, apiUi});
  }

  businessUserRemoveAccount(evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let apiUi = {
      apiActivityId,
    }
    let data = {
      businessId: this.props.businessId,
    }

    this.props.businessUsersRemoveCurrentUser({ data, apiUi });
  }

  handleOpenBusiness() {
    //Redirect the user to the business url
    this.setState({
      redirectToBusinessDashboard: true,
    })

  }


  render() {
    const { classes, displayType, businessId, 
      headlineSubIcon,
      headlineText, secondaryText,
      additionalText, linkText,
      linkPath,
      
      businessAccountClosed = false,
      businessAccountPostClosure = false,
      } = this.props;

    const ChildContent = () => {
      if (displayType === 'invite') {
        //Invitation details (accept/ Decline)

        return (
          <div className={classNames({ aaa: true })}>
            <div className={classNames({ ShcBusinessDashboardCard_displayTypeHeadline: true })}>
              Invitation
              <div className={classNames({ text_s14: true })}>
                Business ID: {businessId}
              </div>
            </div>
            
            <div className={classNames({ flex: true, flexGrow: true, })}>

              <div className={classNames({ flex: true, flexGrow: true, mar5: true})}>
                <StandardApiBtn
                  apiActivityId={`AcceptBusinessInvitation_${businessId}`}
                  icon=""
                  iconLocation=""
                  text="Accept"
                  tooltip=""
                  action=""
                  classes="shctext shctext-primary shctext-fullwidth"
                  fullWidth={true}
                  handleClick={this.businessUserAcceptInvitation}
                />
              </div>

              <div className={classNames({ flex: true, flexGrow: true, mar5: true})}>
                <StandardApiBtn
                  apiActivityId={`DeclineBusinessInvitation_${this.state.businessId}`}
                  icon=""
                  iconLocation=""
                  text="Decline"
                  tooltip=""
                  action=""
                  classes="shctext shctext-primary shctext-fullwidth"
                  fullWidth={true}
                  handleClick={this.businessUserDeclineInvitation}
                />
              </div>

            </div>
          </div>
        )

      }

      if (displayType === 'normal') {
        //Normal display of a business card

        return (
          <div className={classNames({ businessDashboardCardContent: true })}>
            

          </div>
        )

      }

      if (displayType === 'disabled') {
        //This user has their account disabled (reason why displayed)

        return (
          <div className={classNames({ aaa: true })}>
            <div className={classNames({ ShcBusinessDashboardCard_displayTypeHeadline: true })}>
              Account Disabled
            </div>
            <div className={classNames({ aaaa: true })}>
              
              Your account is currently disabled. Please contact the business administrator.
              <br/>
              Reason: Away from work.
            </div>
          </div>
        )

      }

      return (
        <div className={classNames({ aaaaaa: true })}>
          Not set.
        </div>
      )


    }

    

    const containerHeight = {
      minHeight: '200px',
      height: 'auto',
    };

   

    return (
      <div >
        {this.state.redirectToBusinessDashboard === true && (
          <Redirect to={{
            pathname: `/account/business/${this.props.businessId}/account`,
          }}/>
        )}

        <div className={classNames({ ShcBusinessDashboardCard_dashboardContainer: true, flex: true, flexColumn: true })}>

          <div className={classNames({ ShcBusinessDashboardCard_contentSection: true })}>
            <Link to={`/account/business/${this.props.businessId}/account`} className={classNames({textDecoration: true, color_primary_dark: true, })}>
              <div className={classNames({ ShcBusinessDashboardCard_sectionTitle: true })}>
                {headlineText}
              </div>
            </Link>

            <ChildContent/>

          </div>
          <div className={classNames({ ShcBusinessDashboardCard_flexStart: true })}>
            <div className={classNames({ text_h5: true })}>
              Business ID: {businessId}
            </div>
          </div>
          {businessAccountClosed === true ? (
            <div className={classNames({ ShcBusinessDashboardCard_flexStart: true })}>
              <div className={classNames({ text_h5: true })}>
                Business Status: Closed                
              </div>
            </div>
          ):(
            <div>
              {businessAccountPostClosure === true ? (
                <div className={classNames({ ShcBusinessDashboardCard_flexStart: true })}>
                  <div className={classNames({ text_h5: true })}>
                  Business Status: Post-Closure (Limited Access) 
                  </div>
                </div>
              ):(
                <div className={classNames({ ShcBusinessDashboardCard_flexStart: true })}>
                  <div className={classNames({ text_h5: true })}>
                  Business Status: Active
                  </div>
                </div>
              )}
            </div>
          )} 

          <div className={classNames({ ShcBusinessDashboardCard_flexBetween: true })}>
            <div className={classNames({ bbbb: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text={linkText}
                tooltip=""
                action=""
                classes="shctext shctext-primary shctext-fullwidth"
                handleClick={this.handleOpenBusiness}
              />
            </div>
            <div className={classNames({ bbbb: true })}>
              <StandardApiBtn
                apiActivityId={`Remove_My_Account_${businessId}`}
                icon=""
                iconLocation=""
                text="Remove My Account"
                tooltip=""
                action=""
                classes="shctext shctext-outline-primary shctext-fullwidth"
                fullWidth={true}
                handleClick={this.businessUserRemoveAccount}
              />
            </div>
          </div>



        

          



        </div>
      </div>

    );
  }
}

BusinessDashboardCardV2.propTypes = {
  displayType: PropTypes.string,
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkText: PropTypes.string,
};

BusinessDashboardCardV2.defaultProps = {
  displayType: 'normal',
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  userBusinessInvitationResponse,
  businessUsersRemoveCurrentUser,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessDashboardCardV2);


/*
Messages: 0<br />
unverified identities: 10<br />
Notice: Account to be deleted. Learn more.<br />
Subscription: Active<br />

<div className={classNames({ TESTING: true })}>
  <div className={classNames({ flex: true, abc1: true })}>
    <div className={classNames({ a1: true })}>
      Profiles
    </div>
    <div className={classNames({ b1: true })}>
      10
    </div>
    <div className={classNames({ c1: true })}>
      <i
        className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
        aria-label="Verification Expiring Soon"
    />
    </div>
  </div>
</div>
*/


/*
Links at top of card

<div className={classNames({ padR10: true })}>
  <i
    className={`far fa-address-card" ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
    aria-label="Verified"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-mask ${classNames({ ShcIdentityInput_notVerifiedIcon: true })}`}
    aria-label="Not Verified"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-info-circle ${classNames({ ShcIdentityInput_reVerifyIcon: true })}`}
    aria-label="Verification Expiring Soon"
    />
</div>

<div className={classNames({ padR10: true })}>
  <i
    className={`fas fa-comments ${classNames({ ShcIdentityInput_verifiedIcon: true })}`}
    aria-label="Verified"
    />
</div>
*/