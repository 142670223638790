import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Moment from 'react-moment';
import Link from 'react-router-dom/Link';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
// import {  } from 'Actions';


// Functions
import { getJsonStatusPhrase } from 'Helpers/Functions';


class TableQueueLineItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }


  render() {
    const {
      status,
      type,
      id,
      additionalInfo,
      businessId,
      identityId,
      businessDataRegion,

      description,
      datetime,
    } = this.props;

    let datetimeJSX = '';
    if (Number.isInteger(datetime)) {
      try {
        datetimeJSX = <Moment unix>{datetime}</Moment>
      } catch (e) {
        console.error("dateitme not Int");
        console.error(e);
        datetimeJSX = datetime;
      }
    } else {
      datetimeJSX = datetime;
    }

    
    let linkAddress = `/work-queue/${id}`;


    const StatusComponent = () => {
      if (status === 'open') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            OPEN
          </div>
        );
      }
      if (status === 'closed') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            CLOSED
          </div>
        );
      }
      if (status === 'inprogress' || status === 'inProgress') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            PROGRESS
          </div>
        );
      }
      return (
        <div className={`shctext-slim shctext-rounded shctext-danger ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
          ERR
        </div>
      );

    };

    const TypeComponent = () => {
      if (type === 'business-name') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            BIZ-NAME
          </div>
        );
      }
      if (type === 'business-brand-image') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            BRAND-IMAGE
          </div>
        );
      }
      if (type === 'business-brand-words') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            BRAND-WORDS
          </div>
        );
      }
      if (type === 'phone') {
        return (
          <div className={`shctext-slim shctext-rounded shctext-secondary ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
            PHONE
          </div>
        );
      }
      return (
        <div className={`shctext-slim shctext-rounded shctext-danger ${classNames({ fontSize12px: true, displayInlineBlock: true })}`}>
          ERR
        </div>
      );

    };

    return (
      <div className={classNames({ cursorPointer: true, flex: true, ShcModulesReports_apiLogLineItem_containerBorder: true })}>
        <div className={classNames({ flex: true, flexGrow: true })}>
          <div className={classNames({ width90: true })}>

            <StatusComponent />

          </div>
          <div className={classNames({ width130: true })}>

            <TypeComponent />

          </div>
          <div className={classNames({ aaa: true })}>
            {additionalInfo}

          </div>
        </div>
        <div className={classNames({ fontSize12px: true, flex: true, flexAlignItemsCenter: true })}>
          {datetimeJSX}
        </div>
      </div>
    );
  }
}

TableQueueLineItem.propTypes = {

};
TableQueueLineItem.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = {

};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(TableQueueLineItem);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(TableQueueLineItem);
