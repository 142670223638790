import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessIdentitiesProfileItems } from 'Components/ShcAccountModules';

// Actions
//import {removeBusinessWebsiteVerificationItem } from 'Actions';


class BusinessIdentitiesProfiles extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    
    this.state = {
      collapse: true,     //start collapsed
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }


  render() {
    //const {  } = this.props;

    /*


    */


    /*
    const IdentityActivityHeading = () => {
      if (this.state.businessIdentity.type==="website") {
        return (
          <TextStyle size="h2">Website Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="email") {
        return (
          <TextStyle size="h2">Email Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="phone") {
        return (
          <TextStyle size="h2">Phone Identity</TextStyle>
        );
      } else {
        <TextStyle size="h2">Unknown Identity Type</TextStyle>
      }
    }

    const CurrentStatus = () => {
      if (this.state.businessIdentity.verificationStatus===true) {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
            <i class="fas fa-check"></i>
          </div>
        );
      } else {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_danger: true })}>
            <i class="fas fa-times"></i>
          </div>
        );
      }
    }
    */


    /* CurrentStatus
    If one of the selected identities are expired. Mark as "WARNING".
    If there are no identities that are valid. Mark as "DANGER".
      - also note that this profile is unable to be used until at least one identity is validated.
    */
    const CurrentStatus = () => {
      return (
        <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
          <i class="fas fa-check"></i>
        </div>
      );
    }



    return (
      <div className={classNames({ ShcAccountModulesContainer: true })}>
        <div className={classNames({ ShcAccountModulesContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>

          <CurrentStatus/>
  
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>458345807234907234823</div>
                  <div className={classNames({ text_h4: true })}>Auth Channel</div>
                  <div className={classNames({ text_h6: true })}>website, phone, email</div>
                  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}
                >
                <i class="fas fa-angle-down"></i>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcAccountModulesContainerBody: true })}>
          <div className={classNames({ flex: true})}>

            <div className={classNames({ padT10: true, flexGrow: true })}>
              
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Delete"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-info"
                //handleClick={this.handleBusinessVerificationRemoveItem}
              />
            </div>
          </div>


          <div>

            <InputCard headlineText="Profile" headlineSubText="458345807234907234823">

              <InputCardItem headlineText="Profile Name" headlineSubText="" additionalText="This is for internal use only.">
                <InputTextV2 id="name" name="name" label="" value="Auth Channel"  />
              </InputCardItem>

              <InputCardItem headlineText="Profile Description" headlineSubText="" additionalText="This is for internal use only.">
                <InputTextV2 id="name" name="name" label="" value="Main branding for new business"  />
              </InputCardItem>

              

              <InputCardItem headlineText="Information" headlineSubText="" additionalText="">
                <MessageHighlight type="warning">
                  This profile has a website expiring soon. Ensure that all identities are maintained and verified.
                  We will automatically verify before the expiring date.
                </MessageHighlight>
              </InputCardItem>

            </InputCard>

            


            <div className={classNames({ flex: true})}>
              <div className={classNames({ padT10: true, flexGrow: true })}>
                <TextStyle size="h2" weight="normal">Identities</TextStyle>
              </div>
              <div className={classNames({ padT10: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Add New Identity"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  //handleClick={this.handleBusinessVerificationRemoveItem}
                />
              </div>
            </div>

            

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessIdentitiesProfileItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessIdentitiesProfileItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessIdentitiesProfileItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessIdentitiesProfileItems/>
            </CardBasicV2>
            

            <InputCard headlineText="Customer Connect View" headlineSubText="This is what the customer
            will see when you use this profile">

              <InputCardItem headlineText="Website" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">https://www.authchannel.com.au</TextStyle>
              </InputCardItem>
              <InputCardItem headlineText="Website" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">https://www.authchannel.io</TextStyle>
              </InputCardItem>
              <InputCardItem headlineText="Phone" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">11 22 44</TextStyle>
              </InputCardItem>
              <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">info@authchannel.com.au</TextStyle>
              </InputCardItem>
              <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                <TextStyle size="h5" weight="normal">hello@authchannel.com.au</TextStyle>
              </InputCardItem>

              <InputCardItem headlineText="Logo" headlineSubText="" additionalText="Sourced from website: https://www.authchannel.com.au">
                <TextStyle size="h5" weight="normal">image view</TextStyle>
              </InputCardItem>

              


            </InputCard>

          </div>







        </div>
        }
        
      </div>

    );
  }
}

BusinessIdentitiesProfiles.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessIdentitiesProfiles);
