import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV3 } from 'Components/ShcInput';

import { 
  AuthenticatorAppContainerV1,
  IM_email_statusV1,
  IM_email_inputV1,
  IM_email_verifyV1,

} from 'Components/ShcModulesAuthOptions';




// Actions
import { 
  authenticatorSetup,
  authenticatorSetupVerify,



  syncUser, 
  syncBusiness,
  reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,
  
  cancelUserVerification,
  cancelBusinessVerification,

  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class GoogleAuthV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    this.toggleScreenUp = this.toggleScreenUp.bind(this);
    this.toggleScreenDown = this.toggleScreenDown.bind(this);
    this.activateAuth = this.activateAuth.bind(this);

    this.handleSetupAuthenticator = this.handleSetupAuthenticator.bind(this); //new
    this.handleVerifyAuthenticator = this.handleVerifyAuthenticator.bind(this); //new
    this.goToScreenVerifyAuthenticator = this.goToScreenVerifyAuthenticator.bind(this); //new
    this.handleInputChange = this.handleInputChange.bind(this); //new

    
    
    

    //Change handler for verification code input
    this.onIdentityVerificationCodeInput = this.onIdentityVerificationCodeInput.bind(this);
    //Submit verification code
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);

    //Resync data (as the user has clicked on the link in the email for verification)
    this.handleIdentityVerificationViaEmail = this.handleIdentityVerificationViaEmail.bind(this);

    //Feedback from the child component as to the status of the identity
    this.recordIdentityInputValidationStatus = this.recordIdentityInputValidationStatus.bind(this);
    this.recordIdentityInputValidationSaving = this.recordIdentityInputValidationSaving.bind(this);

    
    this.handleCancelVerificationAndGoBack = this.handleCancelVerificationAndGoBack.bind(this);
 
    //On Change from input box
    this.onIdentityInputHandler = this.onIdentityInputHandler.bind(this);
    //Input of Verification Code
    
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    //Verify Request Button
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    //View a Verify Request Button
    this.handleIdentityVerificationView = this.handleIdentityVerificationView.bind(this);


    this.activateAndCollapseAuthenticatorConfig = this.activateAndCollapseAuthenticatorConfig.bind(this);
    
    //toggle the notification of the identity
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    

    this.state = {
      apiTimerCollection: [], // List of active timers.

      authEnabled: this.props.authEnabled,
      authQrcode: '',                           //QR Code for display to the user. 
      authenticatorQrCode: '',
      authenticatorCode: '',
      authCodeInput: '',                        //Auth Code input for verification.

      activeScreen: 'ScreenOverallStatus',
      screensList: [
        {
          order: 1,
          screenComponent: 'ScreenOverallStatus',
        },
        {
          order: 2,
          screenComponent: 'ScreenSetup_qrcode',
        },
        {
          order: 3,
          screenComponent: 'ScreenSetup_verifyCode',
        },
        { //for UI confirmation
          order: 4,
          screenComponent: 'ScreenSetup_confirmation',
        },
        

      ],

  
    };
  }

  handleInputChange(receivedUpdateObj) {
    console.log("handleInputChange");
    let authCodeInput = receivedUpdateObj.value;

    console.log(authCodeInput);
    
    let errors = false;
    let identityInputErrors = receivedUpdateObj.errors;    //new
    if (identityInputErrors === true) {
      errors = true;
    } else {
      this.setState({
        authCodeInput: authCodeInput,
      });
    }
  }


  async handleSetupAuthenticator() {
    console.log('handleSetupAuthenticator');
    /*************************
     * Show loading screen
     * API call to generate code/qr etc
     * Show qrcode and instructions
     *************************/

    let authQrcode = '';
    let authenticatorQrCode = '';
    let authenticatorCode = '';
    let authSetup = await this.props.authenticatorSetup();
    if (authSetup.appStatus === true) {
      console.log('successfully for qr code');
      authQrcode = authSetup.authenticatorQrCode;
      authenticatorQrCode = authSetup.authenticatorQrCode;
      authenticatorCode = authSetup.authenticatorCode;



      this.setState({
        authenticatorQrCode: authenticatorQrCode,
        authenticatorCode: authenticatorCode,
        authQrcode: authQrcode,
        activeScreen: 'ScreenSetup_qrcode'
      });

    } else {
      console.log('ERROR'); //advise user

      this.setState({
        authenticatorQrCode: '',
        authenticatorCode: '',
        authQrcode: '',
        activeScreen: 'ScreenSetup_qrcode_error'
      });

      //wait 4 seconds and go back to main screen

    }
  }

  async goToScreenVerifyAuthenticator() {
    this.setState({
      activeScreen: 'ScreenSetup_verifyCode'
    });
  }

  

  async handleVerifyAuthenticator() {
    console.log('handleVerifyAuthenticator');
    /*************************
     * Request the user to enter in an authenticator code
     * Verify with API
     * ON error, try again
     * Only allow a few tries for the account holder.
     *************************/

    console.log(`handleVerifyAuthenticator | authCodeInput: ${this.state.authCodeInput}`);


     this.setState({
      activeScreen: 'ScreenSetup_verifyingInput'
    });



    let authSetupVerify = await this.props.authenticatorSetupVerify({authCodeInput: this.state.authCodeInput});
    if (authSetupVerify.authenticatorVerified === true) {
      console.log('successfully verified auth code');
      
       this.setState({
        activeScreen: 'ScreenSetup_verificationSuccessful'
      },() => {
        this.changeScreensAfterTimer = setTimeout(() => { 
          this.setState({activeScreen: 'ScreenOverallStatus'});
          this.activateAndCollapseAuthenticatorConfig();

        }, 3000);
      });

    } else {
      console.log('failed to verify auth code');

  
      this.setState({
        activeScreen: 'ScreenSetup_verificationFailure'
      },() => {
        this.changeScreensAfterTimer = setTimeout(() => { 
          this.setState({activeScreen: 'ScreenSetup_verifyCode'});
        }, 2000);
      });

    }
  }

  
  async activateAndCollapseAuthenticatorConfig() {
    /* Sync will update data and ui, collape to happen*/
    console.log('activateAndCollapseAuthenticatorConfig | collapse, close editing and activate');

    this.props.onRequestClosure();





  }









  async handleCancelVerificationAndGoBack() {
    console.warn(`handleCancelVerificationAndGoBack`);
    const { identityData } = this.state;
    let data = {}
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      //Cancel verification and go back
      //Version 1 - just remove the awaitingVerification from the local store
      await this.props.cancelUserVerification({data});
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      //Cancel verification and go back
      //Version 1 - just remove the awaitingVerification from the local store
      await this.props.cancelBusinessVerification({data});
    }

    
    console.warn(`update activeScreen`);

    this.setState({
      activeScreen: 'ScreenSetupIdentity_input',
    });
    
    
    //this.goToScreen.bind(this, 'ScreenSetupIdentity_input');

  }

  async handleIdentityVerificationViaEmail(apiActivityId) {
    //The user should have clicked on the link by now. 
    //Pressing OK will resync the user data so they
    //should see the green success.

    console.warn(`handleIdentityVerificationViaEmail: apiActivityId | ${apiActivityId}`);
    
    const apiUi = { apiActivityId };
    let data = {}
    if (this.props.apiEndpoint==="User") {
      try {
        let apiResponse = await this.props.syncUser({data, apiUi});
        console.log('identity management | syncUser response');
      } catch (e) {}
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        businessId: this.props.businessId,
      };
      try {
        let apiResponseBusiness = await this.props.syncBusiness({data, apiUi});
        console.log('identity management | syncBusiness response');
      } catch (e) {}
    }
    
    console.warn(`handleIdentityVerificationViaEmail: gotoscreen | ScreenOverallStatus`);
    //this.goToScreen.bind(this, 'ScreenOverallStatus');
    this.setState({
      activeScreen: 'ScreenOverallStatus',
    });
  }

  goToScreen(screenName, evt) {
    console.log("goToScreen");
    console.log(screenName);
    console.log(evt);
    this.setState({
      activeScreen: screenName,
    });
  }

  activateAuth(authEnabled) {
    console.log('activateAuth | Google Auth');
    console.log(`authEnabled: ${authEnabled}`);


    this.setState({
      authEnabled: authEnabled,
    });
  }

  onIdentityVerificationCodeInput(verificationCode) {
    this.setState({
      verificationCode: verificationCode,
    });
  }


  toggleScreenUp() {
    console.log('toggleScreenUp');

    let activeScreen = this.state.activeScreen;
    let screensList = this.state.screensList;

    /**
     * Get active screen, get that position, find the position up and roll over at end
     */

    let activeScreenOrder = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].screenComponent === activeScreen) {
        activeScreenOrder = screensList[x].order;
      }
    }
    console.log(`activeScreenOrder: ${activeScreenOrder}`);

    if (activeScreenOrder === undefined || activeScreenOrder === '' || activeScreenOrder === null) {
      activeScreenOrder = screensList.length;   //Move up to position 1
    }

    let nextScreenOrderTarget = '';
    nextScreenOrderTarget = activeScreenOrder + 1;

    console.log(`nextScreenOrderTarget: ${nextScreenOrderTarget}`);

    if (nextScreenOrderTarget >= screensList.length + 1) {
      nextScreenOrderTarget = 1;
    }
    
    console.log(`nextScreenOrderTarget (going up) (updated): ${nextScreenOrderTarget}`);

    let nextScreen = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].order === nextScreenOrderTarget) {
        nextScreen = screensList[x].screenComponent;
      }
    }
    
    this.setState({
      activeScreen: nextScreen,
    });

  }

  toggleScreenDown() {
    console.log('toggleScreenDown');

    let activeScreen = this.state.activeScreen;
    let screensList = this.state.screensList;

    /**
     * Get active screen, get that position, find the position up and roll over at end
     */

    let activeScreenOrder = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].screenComponent === activeScreen) {
        activeScreenOrder = screensList[x].order;
      }
    }
    console.log(`activeScreenOrder: ${activeScreenOrder}`);

    if (activeScreenOrder === undefined || activeScreenOrder === '' || activeScreenOrder === null) {
      activeScreenOrder = 2;    //Move down to position 1
    }

    let nextScreenOrderTarget = '';
    nextScreenOrderTarget = activeScreenOrder - 1;

    console.log(`nextScreenOrderTarget: ${nextScreenOrderTarget}`);

    if (nextScreenOrderTarget <= 0) {
      nextScreenOrderTarget = screensList.length;
    }
    
    console.log(`nextScreenOrderTarget (going down) (updated): ${nextScreenOrderTarget}`);

    let nextScreen = '';
    for (let x = 0; x < screensList.length; x++) {
      if (screensList[x].order === nextScreenOrderTarget) {
        nextScreen = screensList[x].screenComponent;
      }
    }
    
    this.setState({
      activeScreen: nextScreen,
    });

  }





































  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    
    if (updateState === true) {
      return {
      };
    }
    return null;
  }


  componentDidUMount() {
  }

  
  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  recordIdentityInputValidationStatus(inputStatus) {
    console.warn(`recordIdentityInputValidationStatus | UPDATING TO | ${inputStatus}`);
    this.setState({
      inputStatus: inputStatus
    });
  }

  recordIdentityInputValidationSaving(inputSaving) {
    console.warn(`recordIdentityInputValidationSaving | UPDATING TO | ${inputSaving}`);
    this.setState({
      inputSaving: inputSaving
    });
  }

  




  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }


  handleGeneralInputChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);

    if (validateAlphaNumericWithSpacesOnly(value)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === false) {
      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value,
      };
      //console.log('=== onInputChange Return ===');
      //console.log(returnObj);

      this.updateData(returnObj);
      
    }

    //Advise that there is an error with the input and we are not saving it.
  }


  onIdentityInputHandler(event) {
    //Update the length for improved ui experience

    let identityInputValue = '';
    try {
      identityInputValue = event.target.value;
    } catch (e) {
      identityInputValue = '';
    }
    if (identityInputValue === undefined) {
      identityInputValue = '';
    }
    
    let identityInputLength = 0;
    try {
      identityInputLength = identityInputValue.length;
    } catch (e) {
      identityInputLength = 0;
    }
    if (identityInputLength === undefined) {
      identityInputLength = 0;
    }
    this.setState({
      identityInputLength: identityInputLength,
    });

  }

  removeItem(identityId, evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }

  
  async handleIdentityVerificationView(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationView');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    console.log("API Request Dataset");
    console.log(identityData);

    
    let data = {};
    if (this.props.apiEndpoint==="User") {
      //NOT SETUP / NOT REQUIRED CURRENTLY
    } else if (this.props.apiEndpoint==="Business") {

      this.setState({
        activeScreen: 'ScreenSetupIdentity_verifyIdentity',
      });

    } else {
      console.error("handleIdentityVerificationView | No specified endpoint.");
    }

  }

  async handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    console.log("API Request Dataset | handleIdentityVerificationRequest");
    console.log(identityData);

    
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      let identityVerificationResponse = await this.props.userIdentitiesVerifyNow({ data, apiUi });

      if (identityVerificationResponse.status === true) {
        console.log('received success message from api call.');
        if (this.state.identityType === 'email') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckEmail',
          });
         
        } else if (this.state.identityType === 'mobile') { 
          this.setState({
            activeScreen: 'ScreenConfirmationCheckSMS',
          });
        } else if (this.state.identityType === 'phone') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckPhoneCall',
          });
        }
  
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);

      } else {
        console.log('received an error message from api call.');
        let errorMessage = '';
        try {
          errorMessage = identityVerificationResponse.message;
        } catch (e) {
          errorMessage = '';
        }
        if (errorMessage === undefined) {
          errorMessage = '';
        }

        this.setState({
          activeScreen: 'ScreenSetupIdentity_verifyIdentityFailure',
        });

        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_input',
          });
        }.bind(this), 2500);

      }




    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      console.log('Verify Now: businessIdentitiesVerifyNow');
      let identityVerificationResponse = await this.props.businessIdentitiesVerifyNow({ data, apiUi });

      if (identityVerificationResponse.status === true) {
        console.log('received success message from api call.');
        if (this.state.identityType === 'email') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckEmail',
          });
         
        } else if (this.state.identityType === 'mobile') { 
          this.setState({
            activeScreen: 'ScreenConfirmationCheckSMS',
          });
        } else if (this.state.identityType === 'phone') {
          this.setState({
            activeScreen: 'ScreenConfirmationCheckPhoneCall',
          });
        } else if (this.state.identityType === 'business-name') {
          this.setState({
            activeScreen: 'ScreenConfirmationBusinessNameVerificationInProgress',
          });
        }
  




        //Variable times for reading the messages
        let timeout = null;
        if (this.state.identityType === 'email' || this.state.identityType === 'mobile'
        || this.state.identityType === 'phone') {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 2500);
        } else if (this.state.identityType === 'business-name') {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 5500);
        } else {
          timeout = setTimeout(function () {
            this.setState({
              activeScreen: 'ScreenSetupIdentity_verifyIdentity',
            });
          }.bind(this), 4000);
        }


        

      } else {
        console.log('received an error message from api call.');
        let errorMessage = '';
        try {
          errorMessage = identityVerificationResponse.message;
        } catch (e) {
          errorMessage = '';
        }
        if (errorMessage === undefined) {
          errorMessage = '';
        }

        this.setState({
          activeScreen: 'ScreenSetupIdentity_verifyIdentityFailure',
        });

        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_input',
          });
        }.bind(this), 2500);

      }

    } else {
      console.error("handleIdentityVerificationRequest | No specified endpoint.");
    }

  }





  async handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identityData, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
      };
      let verificationCodeResponse = await this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
      console.warn("verificationCodeResponse");
      console.warn(verificationCodeResponse);

      if (verificationCodeResponse.status === true) {
        console.warn('verification code successful - sync data');
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessfulSyncData',
        });

        //Sync the data as we will not return data from a verification activity.
        let apiResponse = await this.props.syncUser({data, apiUi});

        
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenOverallStatus',
          });
        }.bind(this), 2500);
      } else {
        console.warn('verification code invalid');
        this.setState({
          activeScreen: 'ScreenVerificationCodeUnsuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);
      }



    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };

      let verificationCodeResponse = {status: false}
      if (this.state.identityType === 'website') {
        verificationCodeResponse = await this.props.businessIdentitiesVerificationActivity_website({ data, apiUi });
      } else {
        verificationCodeResponse = await this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
      }
 
      console.warn("verificationCodeResponse");
      console.warn(verificationCodeResponse);

      if (verificationCodeResponse.status === true) {
        console.warn('verification code successful - sync data');
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessfulSyncData',
        });

        //Sync the data as we will not return data from a verification activity.
        let apiResponse = await this.props.syncBusiness({data, apiUi});

        
        this.setState({
          activeScreen: 'ScreenVerificationCodeSuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenOverallStatus',
          });
        }.bind(this), 2500);
      } else {
        console.warn('verification code invalid');
        this.setState({
          activeScreen: 'ScreenVerificationCodeUnsuccessful',
        });
        let timeout = setTimeout(function () {
          this.setState({
            activeScreen: 'ScreenSetupIdentity_verifyIdentity',
          });
        }.bind(this), 2500);
      }

    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }
































  
  render() {
    const { 
      authEnabled,


     } = this.props;



    return (
      <div className={classNames({ AuthenticatorAppContainerHeader: true })}>
        <AuthenticatorAppContainerV1
        authEnabled={this.state.authEnabled}
        activeScreen={this.state.activeScreen}
        toggleScreenUp={this.toggleScreenUp}
        toggleScreenDown={this.toggleScreenDown}
        activateAuth={this.activateAuth}
        >


          {this.state.activeScreen === 'ScreenOverallStatus' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              {authEnabled === true ? (
                <div className={classNames({ })}>
                  <div className={classNames({ flex: true, flexGrow: true, })}>
                    ACTIVATED
                  </div>
                  <div className={classNames({ flex: true, flexGrow: true, })}>
                    <StandardApiBtn
                      apiActivityId=""
                      icon=""
                      iconLocation=""
                      text="Edit"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-primary"
                      handleClick={this.handleIdentityVerificationRequest}
                    />
                  </div>
                </div>
              ):(
                <div className={classNames({ flex: true,})}>
                  <div className={classNames({ flexGrow: true, })}>
                    First, ensure you have an authenticator app installed on your smart phone. <br/>
                    If you haven't got one, you can use one of the below which are well known:
                    <ul>
                      <li><a href="https://authy.com/features/setup/" target="_blank">Authy</a></li>
                      <li>Google Authenticator</li>
                      <li>LastPass Authenticator</li>
                      <li>Microsoft Authenticator</li>
                    </ul>
                    Once ready with your authentiator app, continue by selecting next.
                  </div>
                </div>
              )}

              <div className={classNames({ flex: true,})}>
                <div className={classNames({ flex: true, flexGrow: true, })}>
                  &nbsp;
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true })}>
                  <StandardApiBtn
                    apiActivityId=""
                    icon=""
                    iconLocation=""
                    text="Next"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleSetupAuthenticator}
                  />
                </div>
              </div>

            </div>
          )}
          
          
          {this.state.activeScreen === 'ScreenSetup_qrcode' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              <div className={classNames({ flex: true, })}>
                <div className={classNames({ padB10: true, })}>
                  <div className={classNames({  })}>
                    Scan QR Code
                  </div>
                  <img src={this.state.authenticatorQrCode} />
                  <div>
                    <div className={classNames({  })}>
                      OR
                    </div>
                    <div className={classNames({  })}>
                      Enter Code
                    </div>
                    <div className={classNames({ maxWidth300: true, wordWrapBreak: true, borderBlack1: true, pad4: true })}>
                      {this.state.authenticatorCode}
                    </div>
                    
                  </div>  


                </div>
                <div className={classNames({ flexGrow: true, })}>
                  
                  <ol>
                    <li>Open your authenticator app.</li>
                    <li>Scan the QR code into the app or enter in the seed code.</li>
                    <li>You should see Guard Point item in the Authenticator app.</li>
                  </ol>
                  Once visible in your Authenticator app, click next.

                </div>
              </div>
              <div className={classNames({ flex: true,})}>
                <div className={classNames({ flex: true, flexGrow: true, })}>
                  <StandardApiBtn
                    apiActivityId=""
                    icon=""
                    iconLocation=""
                    text="Cancel"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleVerifyAuthenticator}
                  />
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true })}>
                  <StandardApiBtn
                    apiActivityId=""
                    icon=""
                    iconLocation=""
                    text="Next"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.goToScreenVerifyAuthenticator}
                  />
                </div>
              </div>
                
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetup_qrcode_error' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              Error experienced setting up. 
            </div>
          )}



          {this.state.activeScreen === 'ScreenSetup_verifyCode' && (
            <div className={classNames({ padL10: true, padR10: true, padB10: true,})}>
              
              <div>
                <div className={classNames({ padB20: true, })}>
                  Before we activate this on your account, enter in the authenticator code for Guard Point
                  so we can be sure it works. Ensure the token hasn't expired when you press 'Verify'. <br/>
                </div>
                <div>
                  Enter Authenticator Code
                </div>
                <div className={classNames({ flex: true, padB20: true })}>
                  <div className={classNames({ flexGrow: true })}>
                    <InputTextV3 
                      id="authCode" 
                      name="authCode" 
                      identifiers=""
                      stateIdentifier=""
                      label='' 
                      value={this.state.authCodeInput}
                      onChangeInputTextHandler={this.handleInputChange} 
                      dataType="number" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
                      pattern=""
                      feature=""
                    />
                  </div>
                </div>
              </div>
              <div className={classNames({ flex: true,})}>
                <div className={classNames({ flex: true, flexGrow: true, })}>
                  <StandardApiBtn
                    apiActivityId=""
                    icon=""
                    iconLocation=""
                    text="Cancel"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleVerifyAuthenticator}
                  />
                </div>
                <div className={classNames({ flex: true, flexGrow: true, flexJustifyFlexEnd: true })}>
                  <StandardApiBtn
                    apiActivityId=""
                    icon=""
                    iconLocation=""
                    text="Verify"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.handleVerifyAuthenticator}
                  />
                </div>
              </div>
              
                
            </div>
          )}




          
          {this.state.activeScreen === 'ScreenSetup_verifyCode_success' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              ScreenSetup_verifyCode_success.
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetup_confirmation' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              <div>
                <div>Verification Successful</div>
              </div>
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetup_verifyingInput' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_gp_primary_text: true })}>
              <div>
                <div>Verifying Code</div>
              </div>
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetup_verificationSuccessful' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_success: true })}>
              <div>
                <div>Verification Successful</div>
              </div>
            </div>
          )}

          {this.state.activeScreen === 'ScreenSetup_verificationFailure' && (
            <div className={classNames({ pad10: true, flex: true, padT30: true, 
            flexJustifyCenter: true, colour_danger: true })}>
              <div>
                <div>Verification Failed</div>
                <div>Please try again.</div>
              </div>
            </div>
          )}






  
  


        </AuthenticatorAppContainerV1>

      </div>

    );
  }
}

GoogleAuthV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  //console.log("ownProps.identityId");
  //console.log(ownProps.identityId);

  let businessId = '';
  try {
    businessId = ownProps.businessId;
  } catch (e) {
  }

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `identityOptionsTabGroup_${ownProps.identityId}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  return {
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  authenticatorSetup,
  authenticatorSetupVerify,



  syncUser,
  syncBusiness,
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,

  cancelUserVerification,
  cancelBusinessVerification,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(GoogleAuthV1);
