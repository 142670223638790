import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';




import JSONPretty from 'react-json-pretty';

// CSS
import componentStyles from './styles.css';

// Components - CORE
import { ErrorMessageBasic } from 'Components/ShcErrors';

// Components - OTHER
import { StandardText } from 'Components/ShcText';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextV4, IdentityInputV1, IdentityInputV2, 
  CheckboxOptionCardReduxV1, CheckboxOptionCardV1, ToggleOptionCardV1, 
  ToggleOptionCardReduxV1, RadioOptionCardV1, LocationSearchInputV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { VerifiedProfileInput } from 'Components/ShcModulesIdentity';

import { ApiLogLineHeader, ApiLogLineItem } from 'Components/ShcModulesReports';


import Grid from '@material-ui/core/Grid';


// Actions
import {
  apiDataUpdate, resetReduxState, changeReduxState, testUpdateReduxState, test2UpdateReduxState, test2aUpdateReduxAction, test3UpdateReduxAction, test4UpdateReduxAction,
  RemoveReduxState, InsertIntoRedux,


  updateUIApiActivity,

} from 'Actions';

// Functions
import { validateEmail, apiDataTransferTimer } from 'Helpers/Functions';


// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
MUTATING
- Update/modify redux state
- Delete/clear state - Action -> Reducer(root) -> state=undefined

- Update/modify local state
- Delete/clear local state

- Redux - modify nested property
- Redux - modify array with nested properties

- DB - modify

- UI - only collate information required for updates
- Actions - Pass only values to API - API layer to read and create updates (via approved field updates)


- local state
- remote state
- sync updates
- override all updates
- refresh state/delete state if required

*/

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      hasError: false, // Error boundary (update UI to error message)

      changeState: false,

      core: this.props.core,


      apiTimerCollection: [], // List of active timers.

    };
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    // this.apiDataUpdateAction = this.apiDataUpdateAction.bind(this);

    this.magicButton = this.magicButton.bind(this);

    this.createErrorMessage = this.createErrorMessage.bind(this); // create local error
    this.changeState = this.changeState.bind(this); // alter local state
    this.resetReduxState = this.resetReduxState.bind(this); // delete/reset redux state
    this.changeReduxState = this.changeReduxState.bind(this); // delete/reset redux state

    this.testUpdate1 = this.testUpdate1.bind(this);
    this.testUpdate2 = this.testUpdate2.bind(this);
    this.testUpdate3 = this.testUpdate3.bind(this);
    this.testUpdate4 = this.testUpdate4.bind(this);

    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);
    this.removeItemsFromRedux = this.removeItemsFromRedux.bind(this);
    this.insertItemsIntoRedux = this.insertItemsIntoRedux.bind(this);


  }

  /** **************************************************
   * REACT LIFECYCLE COMPONENTS
   *************************************************** */

  componentDidMount() {
    const thisComponent = this.constructor.name;
    console.log(`componentDidMount: ${thisComponent}`);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const thisComponent = this.constructor.name;
    console.log(`componentDidUpdate: ${thisComponent}`);
  }
  componentWillUnmount() {
    console.log('componentWillUnmount');
    const thisComponent = this.constructor.name;
    console.log(`componentWillUnmount: ${thisComponent}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps');
    // const thisComponent = this.constructor.name;
    // console.log(`Component getDerivedStateFromProps: ${thisComponent}`);
    return null;
  }
  static getDerivedStateFromError(error) {
    console.log('getDerivedStateFromError');
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  /*
  apiDataUpdateAction(apiDataObj) {
    console.log(">>>>> apiDataUpdateAction CALLED SUCCESSFULLY");
    //MAY NOT NEED THIS DEPENDING IF I MADE A MISTAKE IN THE REFERENCE FOR apiDataUpdate

    this.props.apiDataUpdate({
      apiDataObj
    });
  }
  */

  /** **************************************************
   * COMPONENT FUNCTIONS
   *************************************************** */

  apiDataTimer(timerReferenceGroup, nextTargetState, nextDotNotation) {
    const apiTimerCollection = this.state.apiTimerCollection;
    console.log('================ apiTimerCollection (Current Status) =====================');
    console.log(JSON.stringify(apiTimerCollection));
    let foundExistingField = false;
    // let newTimer=null;
    for (let x = 0; x < apiTimerCollection.length; x++) {
      if (_.get(apiTimerCollection[x], 'reference') == timerReferenceGroup) {
        // There is a field for one we are wanting to update.
        foundExistingField = true;

        // GET EXISTING TIMER DATA SET FOR UPDATES
        // merge the existing dataset with this specific field update.

        const existingTimerDataSet = apiTimerCollection[x].dataSet;
        const existingTimerDataSetDot = apiTimerCollection[x].dataSetDot;

        // console.log("existing DataSet");
        // console.log(existingTimerDataSet);
        // console.log("merge with:");
        // console.log(targetState);

        const targetState = _.merge(existingTimerDataSet, nextTargetState);
        const dotNotation = _.merge(existingTimerDataSetDot, nextDotNotation);
        console.log('dotNotation');
        console.log(dotNotation);
        // console.log("POST merge:");
        // console.log(reduxNextState);

        // Cancel the existing timer.
        clearTimeout(apiTimerCollection[x].timer);
        // console.log("Cleared the current timer");


        // Create the new timer
        /*
        let newTimer = setTimeout(() => {
          //Update this timer to mark as inactive when the timer is reached.
          let position=null;
          for (let x=0; x<this.state.apiTimerCollection.length; x++) {
            //Traverse the array
            if (_.get(this.state.apiTimerCollection[x], 'reference') == timerReferenceGroup) {
              //Found the reference
              position = x;
            }
          }
          if (position==null) {
            console.log("ERROR: unable to find timer position");
          } else {
            //On timer finish, update redux and the API endpoint
            let action = {
              task: "reduxApi",    //redux/reduxApi (both)
              reference: timerReferenceGroup,
            }
            //CALL UPDATE ACTION
            console.log("==== API CALL TO BACKEND (2) ====");
            this.props.updateUser({
              action,
              targetState,
              dotNotation,
            });

            //Find this timer and remove from the timer group (so we don't have a never ending large object)
            //clearTimeout(apiTimerCollection[position].timer);

          }
        }, 4000, timerReferenceGroup, targetState, dotNotation);
        */
        const newTimer = setTimeout(() => {
          // Create a new timer with the following goals.
          // When we create this timer, we dont know where were are in the apiTimer array.
          // When this timer runs, we need to know the array location so we can delete this reference after we have updated.
          let position = null;
          for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
            if (_.get(this.state.apiTimerCollection[x], 'reference') == timerReferenceGroup) {
              position = x;
            }
          }
          const action = {
            task: 'reduxApi', // redux/reduxApi (both)
            reference: timerReferenceGroup,
          };
          // CALL UPDATE ACTION
          console.log('==== API CALL TO BACKEND (2) ====');
          this.props.updateUser({
            action,
            targetState,
            dotNotation,
          });
          console.log(`==== REMOVE THIS TIMER (position: ${position}) ====`);
          if (position !== null) {
            const apiTimerCollection = this.state.apiTimerCollection;
            apiTimerCollection.splice(position, 1);
            this.setState({
              apiTimerCollection,
            });
          }
        }, 4000, timerReferenceGroup, targetState, dotNotation);

        // Create the new fields
        const newTimerData = {
          reference: timerReferenceGroup,
          dataSet: targetState, // This contains the current updated object. If a change happens to the same parent, we will merge it all together for the update.
          dataSetDot: dotNotation,
          status: 'active',
          timer: newTimer,
        };

        // Update this clone of state
        apiTimerCollection[x] = newTimerData;

        // override the current state
        this.setState({
          apiTimerCollection,
        });
        // console.log("updated state with the new timer");
        console.log('================ apiTimerCollection =====================');
        console.log(JSON.stringify(apiTimerCollection));
        return '1';
      }
    }
    if (foundExistingField == false) {
      const targetState = nextTargetState;
      const dotNotation = nextDotNotation;
      // insert a new timer field
      // console.log("create new timer");
      /*
      let newTimer = setTimeout(() => {
        //Update this timer to mark as inactive when the timer is reached.
        let position=null;
        let apiTimeDataSet = targetState;
        for (let x=0; x<this.state.apiTimerCollection.length; x++) {
          //Traverse the array
          if (_.get(this.state.apiTimerCollection[x], 'reference') == timerReferenceGroup) {
            //Found the reference
            position = x;
          }
        }
        if (position==null) {
          console.log("ERROR: unable to find timer position");
        } else {
          //On timer finish, update redux and the API endpoint
          let action = {
            task: "reduxApi",    //redux/reduxApi (both)
            reference: timerReferenceGroup,
          }
          //CALL UPDATE ACTION
          console.log("==== API CALL TO BACKEND ====");
          this.props.updateUser({
            action,
            apiTimeDataSet,
          });
          //Find this timer and remove from the timer group (so we don't have a never ending large object)
          //clearTimeout(apiTimerCollection[position].timer);

        }
      }, 4000);
      */
      const newTimer = setTimeout(() => {
        // Update this timer to mark as inactive when the timer is reached.
        // On timer finish, update redux and the API endpoint
        let position = null;
        for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
          if (_.get(this.state.apiTimerCollection[x], 'reference') == timerReferenceGroup) {
            position = x;
          }
        }
        const action = {
          task: 'reduxApi', // redux/reduxApi (both)
          reference: timerReferenceGroup,
        };
        // CALL UPDATE ACTION
        console.log('==== API CALL TO BACKEND (1) ====');
        this.props.updateUser({
          action,
          targetState,
          dotNotation,
        });
        console.log(`==== REMOVE THIS TIMER (position: ${position}) ====`);
        if (position !== null) {
          const apiTimerCollection = this.state.apiTimerCollection;
          apiTimerCollection.splice(position, 1);
          this.setState({
            apiTimerCollection,
          });
        }

      }, 4000, timerReferenceGroup, targetState, dotNotation);


      // Create the new fields
      const newTimerData = {
        reference: timerReferenceGroup,
        dataSet: targetState, // This contains the current updated object. If a change happens to the same parent, we will merge it all together for the update.
        dataSetDot: dotNotation,
        status: 'active',
        timer: newTimer,
      };

      // Insert this clone of state
      apiTimerCollection.push(newTimerData);

      // override the current state
      this.setState({
        apiTimerCollection,
      });
      // console.log("updated state with the new timer");
      // console.log("new timer has been added.");
      console.log('================ apiTimerCollection =====================');
      console.log(JSON.stringify(apiTimerCollection));
      return '1';

    }
  }

  magicButton(evt) {
    console.log('==== PARENT - MAGIC BUTTON PRESSED ====');
    console.log(evt);

    // Wait 5 seconds and then clear the api timer for this test
    setTimeout(() => {
      this.props.updateUIApiActivity({
        id: 'MagicButton1',
        status: 'failure',
      });
    }, 10000);
  }




  createErrorMessage() {
    this.setState(prevState => ({
      hasError: !prevState.hasError,
    }));
  }
  changeState() {
    this.setState(prevState => ({
      changeState: !prevState.changeState,
    }));
  }
  changeReduxState() {
    console.log('Changing redux state');
    this.props.changeReduxState();
  }
  resetReduxState() {
    console.log('Resetting redux state');
    this.props.resetReduxState();
  }

  testUpdate1() {
    console.log('testUpdate1');
    this.props.testUpdateReduxState();
  }
  testUpdate2() {
    console.log('testUpdate2');
    this.props.test2UpdateReduxState();
  }
  testUpdate3() {
    console.log('testUpdate2a');
    this.props.test2aUpdateReduxAction();
  }
  testUpdate4() {
    console.log('testUpdate4');
    this.props.test3UpdateReduxAction();
  }


  /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */

  /*
    apiCollection = [
      {state: User, data: [
        {"reference":"test5.nested2.arr3.$.prop1","status":"active","timer":45},
        {"reference":"test5.nested2.arr3.$.prop2","status":"active","timer":48}
      ]},
      {state: Core, data: [

      ]},

    ]
  */
  /*
[
   {"state":"Core",
   "data":[
     {"reference":"test5.nested2.arr3.$.prop1",
     "dataSet":{"name":"test5.nested2.arr3.$.prop1","identifiers":[{"name":"a"}],"stateIdentifier":"Core","value":"bbb56"},
     "status":"active","timer":64},
     {"reference":"test5.nested2.arr3.$.prop2",
     "dataSet":{"name":"test5.nested2.arr3.$.prop2","identifiers":[{"name":"a"}],"stateIdentifier":"Core","value":"56"},
     "status":"active","timer":68}
    ]
  }
]
*/


  apiDTimer(timerReferenceGroup, dataSet) {
    const apiTimerCollection = this.state.apiTimerCollection;
    console.log('===== apiTimerCollection (Current) ======');
    console.log(JSON.stringify(apiTimerCollection));

    let foundExistingStateField = false;
    let foundExistingStateFieldPosition = null;
    let foundExistingField = false;
    let foundExistingFieldPosition = null;
    // let newTimer=null;

    /*
    Search the apiTimerCollection for the relevant "state" identifer we updating.
    This allows the grouping of API calls for efficency.
    */
    for (let x = 0; x < apiTimerCollection.length; x++) {
      if (_.get(apiTimerCollection[x], 'state') == timerReferenceGroup) { // e.g. state: User or state: Core
        // This is the state object we are updating.
        foundExistingStateField = true;
        foundExistingStateFieldPosition = x;

        console.log(`==== STATE: ${timerReferenceGroup}`);
        console.log(`==== REF (TARGET): ${dataSet.name}`);

        // Traverse all the data items.
        for (let y = 0; y < apiTimerCollection[x].data.length; y++) {
          if (_.get(apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
            // This is the item object we are updating.
            foundExistingField = true;
            foundExistingFieldPosition = y;

            console.log(`==== REF (MATCH): ${dataSet.name}`);

            // Cancel the existing timer.( NEW STATE TIMER )
            console.log(`Clear timer for: ${apiTimerCollection[x].timer}`);
            clearTimeout(apiTimerCollection[x].timer);

            // Create the new fields
            const newTimerData = {
              reference: dataSet.name,
              dataSet,
            };

            const newStateTimer = setTimeout(() => {
              // Create a new timer with the following goals.
              // When we create this timer, we dont know where were are in the apiTimer array.
              // When this timer runs, we need to know the array location so we can delete this reference after we have updated.

              const timerQuery = new Promise((resolve, reject) => {
                console.log('Promise (initial)');
                let positionX = null;
                let positionY = null;
                let apiDataObj = null;
                for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
                  if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
                    positionX = x;
                    apiDataObj = this.state.apiTimerCollection[x];
                    for (let y = 0; y < this.state.apiTimerCollection[x].data.length; y++) {
                      if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
                        positionY = y;
                        // COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
                        console.log('go through all the records and create an object ofr updating the back end');
                      }
                    }

                  }
                }
                const resolveDataSet = {
                  positionX,
                  positionY,
                  apiDataObj,
                };
                console.log('resolveDataSet');
                console.log(resolveDataSet);
                resolve(resolveDataSet);
              });

              Promise.all([timerQuery])
                .then((values) => {
                  console.log('Promise (API Action)');
                  console.log('values');
                  console.log(values[0]);
                  const apiDataObj = values[0].apiDataObj;

                  // CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
                  console.log('==== API CALL TO BACKEND FOR DB STORAGE (STATE TIMER) ====');
                  console.log('apiDataObj');
                  console.log(JSON.stringify(apiDataObj));
                  /* this.props.TEST_apiDataUpdate({
                  action,
                  dataSet
                }); */
                  return values;
                })
                .then((values) => {
                  console.log('Promise (clear data)');
                  console.log('values');
                  console.log(values[0]);
                  const positionX = values[0].positionX;
                  const positionY = values[0].positionY;
                  const apiDataObj = values[0].apiDataObj;

                  console.log(`==== REMOVE THIS TIMER (position: ${positionX}, ${positionY}) ====`);
                  if (positionX !== null && positionY !== null) {
                    const apiTimerCollection = this.state.apiTimerCollection;
                    apiTimerCollection[positionX].data = [];
                    apiTimerCollection[positionX].timer = ''; // When this runs, all of the entries are processed - so reset all the values
                    this.setState({
                      apiTimerCollection,
                    });
                  }
                  console.log('==== apiTimerCollection (after api call) ====');
                  console.log(JSON.stringify(apiTimerCollection));
                });

            }, 4000, timerReferenceGroup, dataSet);

            // Update the state timer of the clone object
            apiTimerCollection[x].timer = newStateTimer;

            // Set the new value for the array position
            apiTimerCollection[x].data[y] = newTimerData;

            // override the current state
            this.setState({
              apiTimerCollection,
            });
            // console.log("updated state with the new timer");
            console.log('================ apiTimerCollection =====================');
            console.log(JSON.stringify(apiTimerCollection));
            return '1';


          }
        }
      }
    }

    // Create a new timer record. Check if the parent was found.
    if (foundExistingField == false) {
      // The field was not found.
      console.log('CREATE NEW TIMER FIELD');


      // Create the new fields
      const newTimerData = {
        reference: dataSet.name,
        dataSet,
      };


      const newStateTimer = setTimeout(() => {
        // Create a new timer with the following goals.
        // When we create this timer, we dont know where were are in the apiTimer array.
        // When this timer runs, we need to know the array location so we can delete this reference after we have updated.

        const timerQuery = new Promise((resolve, reject) => {
          console.log('Promise (initial)');
          let positionX = null;
          let positionY = null;
          let apiDataObj = null;
          for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
            if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
              positionX = x;
              apiDataObj = this.state.apiTimerCollection[x];
              for (let y = 0; y < this.state.apiTimerCollection[x].data.length; y++) {
                if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
                  positionY = y;
                  // COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
                  console.log('go through all the records and create an object ofr updating the back end');
                }
              }

            }
          }
          const resolveDataSet = {
            positionX,
            positionY,
            apiDataObj,
          };
          console.log('resolveDataSet');
          console.log(resolveDataSet);
          resolve(resolveDataSet);
        });

        Promise.all([timerQuery])
          .then((values) => {
            console.log('Promise (API Action)');
            console.log('values');
            console.log(values[0]);
            const apiDataObj = values[0].apiDataObj;

            // CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
            console.log('==== API CALL TO BACKEND FOR DB STORAGE (STATE TIMER) ====');
            console.log('apiDataObj');
            console.log(JSON.stringify(apiDataObj));
            /* this.props.TEST_apiDataUpdate({
            action,
            dataSet
          }); */
            return values;
          })
          .then((values) => {
            console.log('Promise (clear data)');
            console.log('values');
            console.log(values[0]);
            const positionX = values[0].positionX;
            const positionY = values[0].positionY;
            const apiDataObj = values[0].apiDataObj;

            console.log(`==== REMOVE THIS TIMER (position: ${positionX}, ${positionY}) ====`);
            if (positionX !== null && positionY !== null) {
              const apiTimerCollection = this.state.apiTimerCollection;
              apiTimerCollection[positionX].data = [];
              apiTimerCollection[positionX].timer = ''; // When this runs, all of the entries are processed - so reset all the values
              this.setState({
                apiTimerCollection,
              });
            }
            console.log('==== apiTimerCollection (after api call) ====');
            console.log(JSON.stringify(apiTimerCollection));
          });






        /*
        let positionX=null;
        let positionY=null;
        let apiDataObj=null;
        for (let x=0; x<this.state.apiTimerCollection.length; x++) {
          if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
            positionX = x;
            apiDataObj = this.state.apiTimerCollection[x];

            for (let y=0; y<this.state.apiTimerCollection[x].data.length; y++) {
              if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
                positionY = y;
                //COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
                console.log("go through all the records and create an object ofr updating the back end");
              }
            }

          }
        }
        let action = {
          reference: timerReferenceGroup,
        }
        *//*
        //CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
        console.log("==== API CALL TO BACKEND FOR DB STORAGE ( STATE TIMER ..)====");
        console.log("apiDataObj");
        console.log(JSON.stringify(apiDataObj));
        /*this.props.TEST_apiDataUpdate({
          action,
          dataSet
        }); *//*
        console.log("==== REMOVE THIS TIMER (position: "+positionX+", "+positionY+") ====");
        if (positionX!==null && positionY!==null) {
          let apiTimerCollection = this.state.apiTimerCollection;
          //apiTimerCollection[positionX].data = [];
          apiTimerCollection[positionX].timer = "";
          this.setState({
            apiTimerCollection: apiTimerCollection,
          });
        }
        console.log("==== apiTimerCollection (after api call) ====");
        console.log(JSON.stringify(apiTimerCollection));
        */
      }, 4000, timerReferenceGroup, dataSet);

      if (foundExistingStateField == false) {
        // The state field (parent array) was not found. PUSH to array.

        const newParentObj = {
          state: timerReferenceGroup,
          data: [newTimerData], // This is all new, so simply set it.
          timer: newStateTimer, // The primary timer for the state object
        };

        // Insert this clone of state
        apiTimerCollection.push(newParentObj);


      } else {
        // The state field (parent array) was found. UPDATE the array.

        // Cancel the existing timer.( NEW STATE TIMER )
        console.log(`Clear timer for: ${apiTimerCollection[foundExistingStateFieldPosition].timer}`);
        clearTimeout(apiTimerCollection[foundExistingStateFieldPosition].timer);

        // Insert this clone of state
        apiTimerCollection[foundExistingStateFieldPosition].data.push(newTimerData); // Push the new field
        apiTimerCollection[foundExistingStateFieldPosition].timer = newStateTimer; // Set the new timer
      }

      // override the current state
      this.setState({
        apiTimerCollection,
      });
      // console.log("updated state with the new timer");
      // console.log("new timer has been added.");
      console.log('===== apiTimerCollection (end of call) =======');
      console.log(JSON.stringify(apiTimerCollection));
      return '1';


    }
  }

  onChangeInputTextHandler(receivedUpdateObj) {
    console.log('onChangeInputTextHandler');

    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);

    console.log(JSON.stringify(recordsForUpdate));

    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */

    this.props.test4UpdateReduxAction({ recordsForUpdate });

    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)

      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      console.log(timerReferenceGroup);
      console.log(JSON.stringify(recordsForUpdate[x]));

      // this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }







  insertItemsIntoRedux() {
    console.log('insertItemsIntoRedux');

    const recordsForInsertion = [

      { // success (remains as null)
        name: 'test6',
        identifiers: [],
        value: 'test6-value',
        stateIdentifier: 'Core',
      },
      { // success (remains as null)
        name: 'test7',
        identifiers: [],
        value: [555],
        stateIdentifier: 'Core',
      },
      { // success (remains as null)
        name: 'test7',
        identifiers: [],
        value: [666],
        stateIdentifier: 'Core',
      },
      { // success (remains as null)
        name: 'test7',
        identifiers: [],
        value: 999,
        stateIdentifier: 'Core',
      },

      { // success
        name: 'test2',
        identifiers: [],
        value: '6',
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test2',
        identifiers: [],
        value: { 'test2-key': 'test2-value' },
        stateIdentifier: 'Core',
      },

      { // success
        name: 'test4.$.desc2',
        identifiers: [{ desc1: 'b' }],
        value: { 'new-name': 'selby' },
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test4.$',
        identifiers: [{ desc2: 'd' }],
        value: { 'new-other': 'sarah' },
        stateIdentifier: 'Core',
      },


    ];
    this.props.InsertIntoRedux({ recordsForInsertion });
  }

  removeItemsFromRedux() {
    console.log('removeItemsFromRedux');

    const recordsForRemoval = [
      { // success (remains as null)
        name: 'test5.nested1.arr1.$',
        identifiers: [0],
        value: '',
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test1',
        identifiers: [],
        value: '',
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test3.one',
        identifiers: [],
        value: '',
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test4.$.item1',
        identifiers: [{ item1: 'a' }],
        value: '',
        stateIdentifier: 'Core',
      },
      { // success
        name: 'test4.$.desc2',
        identifiers: [{ item2: 'c' }],
        value: '',
        stateIdentifier: 'Core',
      },

    ];
    this.props.RemoveReduxState({ recordsForRemoval });
  }


  render() {
    if (this.state.hasError) {
      return <ErrorMessageBasic />;
    }


    const yourData = {
      email: 'REDACTED',
      firstName: 'REDACTED',
      tokenId: 'REDACTED',
      id: 'abc',
      array: [
        { name: 'a' },
        { name: 'b' },
      ],
      number: 1234567234,
      object: {
        a: 1234,
        b: 4556,
        c: 6949,
        d: 3939,
        nest1: {
          next2: {
            next3: {
              next4: {
                z: 1,
                y: 2,
                x: 3,
              },
            },
          },
        },
      },
      ghi: {
        j: 'a',
        k: 'b',
        l: 'c',
      },
      other: true,
      extra: false,
      mno: {
        aaa: [
          { name: 'a', team: 'aaaa' },
          { name: 'b', team: 'bbbb' },
        ],
        ddd: {
          a: 'aaaaaaaa',
          b: 'bbbbbbbb',
        },
      },
      todo: true,
    };

    const responseBody = {
      id: 'sub_FgecgXEGCGgSoz',
      object: 'subscription',
      application_fee_percent: null,
      billing: 'charge_automatically',
      billing_cycle_anchor: 1566722570,
      billing_thresholds: null,
      cancel_at: null,
      cancel_at_period_end: false,
      canceled_at: null,
      collection_method: 'charge_automatically',
      created: 1566722570,
      current_period_end: 1566808970,
      current_period_start: 1566722570,
      customer: 'cus_FgecvF1NVFY8lW',
      days_until_due: null,
      default_payment_method: null,
      default_source: null,
      default_tax_rates: [
      ],
      discount: null,
      ended_at: null,
      items: {
        object: 'list',
        data: [
          {
            id: 'si_FgecBJOfIJ56ta',
            object: 'subscription_item',
            billing_thresholds: null,
            created: 1566722570,
            metadata: {
            },
            plan: {
              id: 'plan_Euwlhi5b89jrSN',
              object: 'plan',
              active: true,
              aggregate_usage: 'sum',
              amount: null,
              amount_decimal: null,
              billing_scheme: 'tiered',
              created: 1555719376,
              currency: 'aud',
              interval: 'day',
              interval_count: 1,
              livemode: false,
              metadata: {
              },
              nickname: 'Usage Plan Tier',
              product: 'prod_EuwaGePkDnXO7f',
              tiers: [
                {
                  amount: 0,
                  flat_amount: 1000,
                  flat_amount_decimal: '1000',
                  unit_amount_decimal: '0',
                  up_to: 5,
                },
                {
                  amount: 0,
                  flat_amount: 2000,
                  flat_amount_decimal: '2000',
                  unit_amount_decimal: '0',
                  up_to: 10,
                },
                {
                  amount: 100,
                  flat_amount: 0,
                  flat_amount_decimal: '0',
                  unit_amount_decimal: '100',
                  up_to: null,
                },
              ],
              tiers_mode: 'graduated',
              transform_usage: null,
              trial_period_days: null,
              usage_type: 'metered',
            },
            subscription: 'sub_FgecgXEGCGgSoz',
            tax_rates: [
            ],
          },
        ],
        has_more: false,
        total_count: 1,
        url: '/v1/subscription_items?subscription=sub_FgecgXEGCGgSoz',
      },
      latest_invoice: 'in_1FBHJGF3qIr5i0iicyQ46nWa',
      livemode: false,
      metadata: {
      },
      pending_setup_intent: null,
      plan: {
        id: 'plan_Euwlhi5b89jrSN',
        object: 'plan',
        active: true,
        aggregate_usage: 'sum',
        amount: null,
        amount_decimal: null,
        billing_scheme: 'tiered',
        created: 1555719376,
        currency: 'aud',
        interval: 'day',
        interval_count: 1,
        livemode: false,
        metadata: {
        },
        nickname: 'Usage Plan Tier',
        product: 'prod_EuwaGePkDnXO7f',
        tiers: [
          {
            amount: 0,
            flat_amount: 1000,
            flat_amount_decimal: '1000',
            unit_amount_decimal: '0',
            up_to: 5,
          },
          {
            amount: 0,
            flat_amount: 2000,
            flat_amount_decimal: '2000',
            unit_amount_decimal: '0',
            up_to: 10,
          },
          {
            amount: 100,
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount_decimal: '100',
            up_to: null,
          },
        ],
        tiers_mode: 'graduated',
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'metered',
      },
      quantity: 1,
      schedule: null,
      start: 1566722570,
      start_date: 1566722570,
      status: 'active',
      tax_percent: null,
      trial_end: null,
      trial_start: null,
    };


    return (
      <div className={classNames({ bg_colour_white: true, pad10: true })}>


        <ul>
          <li>PUSH NOTIFICATIONS</li>
          <li>When configuring a device, get the app unique id (this becomes the password for encryption and or signing JWT</li>

          <li>Take the full payload as is, sign it with the device key. They can then verify the payload hasn't changed when it comes in</li>
          <li>Encrypt the CODE so that the data can't be intercepted in transit and replayed for a push notification and phone call</li>
          <li></li>
          <li></li>
          <li></li>





          <li>API - BUSINESS - Update all endpoints to not accept changes if the business is in Post-Closure or Closed state.</li>
          <li>Notifications to the desktop on errors</li>
          <li>Business no subscription, update pages for not available without subscription component view</li>
          <li>Make sure an identity cannot be updated if the identity is 'identityLocked: true'</li>

          <li>Handling a db server in a cluster dying while lambda is cached</li>
          
          <li>Update business identity re-verification script to handle all types of reminders (not just website)</li>
          <li>Build similar to business identity re-verification for users - this is just as important</li>


          <li>BACKEND - Add api overview and detailed logging for easy troubleshooting and data collation</li>
          <li>Fix auth token issue when page doesn't refresh - times out and doesn't notify</li>
          <li>Toast popup messages for api responses</li>
          <li>Connect landing page (with no permissions, show message describing no access)</li>
          <li>Connect - Allow for ticking success if the interaction has been successfully completed</li>
          <li>Connect - Update Inbound capability (even if this says its currently unavailable on this subscription)</li>
          <li>Connect - Remove the links Connect/Send</li>
          <li>Connect - Create Connect help page to describe how to use this page.</li>
          <li>Connect - Dashboard link - Determine what this is and what it should show, this might only be 
            shown for owners and admins, but no the users who are connecting with customers. 
          </li>
          <li>Supporters Directory - Allow a business to add their business to the supporters directory (only verified contacts are allowed)</li>
          <li>FEATURE - Provide support for registered trade mark and or business name</li>
          <li>FEATURE - Provide support for registered logo or graphic </li>

          <li>Account - Feedback section</li>
          <li>Account - Support section</li>
          <li>Subscriptions (item) - If none, show message that there is no subscriptions</li>
          <li>Subscriptions (item) - Switch Plans - explain how the user can do this</li>
          <li>Subscriptions (item) - Cancel subscription - what happens when you don't have any subscriptions</li>
          <li>Subscriptions (item) - Terms and conditions of cancellation</li>
          <li>Subscriptions (item) - Process for handling a dispute</li>

          <li>Subscriptions - Only show payment options if the plan is currently not active</li>
          <li>Invoices - Check that the status is correct. Initial seems to be incorrect.</li>

          <li>Invoices - If no invoices available, show a message in the centre staying None</li>
          
          <li>Business Identities - Update the Add an Identity popup box. Simplify wording and handle longer names. Remove
            features that are not available yet, social</li>
          <li>Business Profile - set a landing screen about how and why to setup a profile. Once setup
            this can be hidden.</li>
          <li>Reporting - Determine what should be displayed on the page.
            Logs should be updated to only show logs for the connect transactions. Not all logs. All logs is for the
            platform management team for troubleshooting or assessing attacks</li>
          <li>Reporting - Logs - Move button and update to "Load Logs". This will stop opening one record, returning and loading all the logs again. (add 
            new react debounce to this)
          </li>
          <li>Legal - This is for business legal. This needs to be outside of a business as they need to agree to it beforehand.
            Possible that this section could be for holding legal related documents between this business and the platform  </li>
          <li>PLATFORM NOTIFICATIONS - Provide a section for business notifications - This would provide an update of the status
            of this account. e.g. it was locked by SYSTEM. Or that there is scheduled maintenance. Reports of spam/scam etc</li>
          <li>Business Relationships - Get a shell of what this is and how it works especially for how 3rd parties would use it</li>
          <li>API Management - this is similar to Business Relationships.
            - Need to allow this business to access the api services via api keys that automatically rotate (2 active for a small window)
            - can disable keys
            - can provide keys to 3rd parties to work on behalf. Need to describe relationship. 
            </li>



          <li>Delete a business (only user with owner permissions). Take snapshot to allow for customer interaction lookups, and delete all the data</li>
          <li></li>
          <li>Delete a user account</li>
          <li>Add a business user and adjust permissions (Invite Accept & Decline)</li>
          <li>Disable a business user (while away from work) - permissions are removed. this could be the disabled state (no permissions)</li>
          <li>Remove a business user (ensure there is at least 1 owners)</li>
          <li>Verify an interaction and provide the status (via log in and annomyous)</li>
          <li>Add stats to the dashboard (or simplify for launch)</li>
          <li>View interactions and filter based on categories</li>
          <li>Delete an interaction</li>
          <li>Report an interaction</li>
          <li>Report a business</li>
          <li>Flag a bad interaction</li>
          <li>Flag a positive interaction</li>
          <li>Flag if an interaction happened</li>
          <li>Flag is an interaction did not happen</li>
          <li>Flag if the business was unable to provide the verification code</li>
          <li>Notification devices - How to add other peoples devices (add via reference code)</li>
          <li>Mobile app page, launch from qr code or alike - refernce google or apple</li>
          <li>Support page, provide contact details to log issues. Business support is additional costs</li>
          <li>Link to the health page for support</li>
          <li>Footer Navigration, Update and check links</li>
          <li>Consider format for social media platforms and supporters page</li>
          <li>Home page - Update links, content, single page scroll etc</li>

        </ul>








        <LocationSearchInputV1
         />
        <br /><br />

        <InputCard headlineText="Request POST body" headlineSubText="">
          <div className={classNames({ aaa: true })}>
            <JSONPretty id="json-pretty" data={yourData} />
          </div>
        </InputCard>

        <br />

        <InputCard headlineText="Response body" headlineSubText="">
          <div className={classNames({ aaa: true })}>
            <JSONPretty id="json-pretty" data={responseBody} />
          </div>
        </InputCard>


        <br /><br /><br />

        <ApiLogLineHeader />
        <ApiLogLineItem
          statusCode="200"
          method="POST"
          description="/v1/subscriptions"
          datetime="25/08/19 6:42:50 PM"
        />
        <ApiLogLineItem
          statusCode="422"
          method="POST"
          description="/v1/subscriptions"
          datetime="25/08/19 6:42:50 PM"
        />
        <ApiLogLineItem
          statusCode="400"
          method="PATCH"
          description="/v1/subscriptions"
          datetime="25/08/19 6:42:50 PM"
        />
        <ApiLogLineItem
          statusCode="200"
          method="GET"
          description="/v1/subscriptions"
          datetime="25/08/19 6:42:50 PM"
        />













        <br /><br /><br />

        <StandardApiBtn
          apiActivityId="MagicButton1"
          icon=""
          iconLocation=""
          text="Set Local State Error"
          tooltip=""
          action=""
          classes="shctext shctext-primary shctext-rounded shctext-medium"
          handleClick={this.magicButton}
        />


        <InputCardItem
          headlineText="Label"
          headlineSubText="This is a private label you can attach to this identity."
          additionalText="Only you will see this."
        >
          <InputTextV3
            id="test5.nested2.arr3.$.prop100"
            name="test5.nested2.arr3.$.prop100"
            identifiers={[{ name: 'a' }]}
            stateIdentifier="Core"
            label=""
            value="iiiii"
            // onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
            onChangeInputTextHandler={this.onChangeInputTextHandler}
            dataType="number" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
            pattern=""
          />
        </InputCardItem>


        <InputCardItem
          headlineText="SMART INPUT"
          headlineSubText="This is a private label you can attach to this identity."
          additionalText="Only you will see this."
        >
          <IdentityInputV1
            id="test5.nested2.arr3.$.prop100"
            name="test5.nested2.arr3.$.prop100"
            identifiers={[{ name: 'a' }]}
            stateIdentifier="Core"
            label=""
            value="iiiii"
            // onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
            onChangeInputTextHandler={this.onChangeInputTextHandler}
            dataType="number" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
            pattern=""
          />
        </InputCardItem>






        <InputCardItem
          headlineText="EMAIL ADDRESS"
          headlineSubText="This is your email address."
          additionalText=""
        >
          <IdentityInputV2
            id="test5.nested2.arr3.$.prop100"
            name="test5.nested2.arr3.$.prop100"
            identifiers={[{ name: 'a' }]}
            stateIdentifier="Core"
            label=""
            value="iiiii"
            // onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
            onChangeInputTextHandler={this.onChangeInputTextHandler}
            dataType="email" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
            pattern=""
          />
        </InputCardItem>




        <InputCardItem
          headlineText="PHONE NUMBER (validate)"
          headlineSubText="Phone numbers are in the international format starting with a plus sign (+)"
          additionalText=""
        >
          <IdentityInputV2
            id="test5.nested2.arr3.$.prop100"
            name="test5.nested2.arr3.$.prop100"
            identifiers={[{ name: 'a' }]}
            stateIdentifier="Core"
            label=""
            value="iiiii"
            // onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
            onChangeInputTextHandler={this.onChangeInputTextHandler}
            dataType="phone" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
            pattern=""
          />
        </InputCardItem>

        <br /><br />


        <CheckboxOptionCardV1
          checkboxOptionsId="billing"
          checkboxText="Assistance with account and billing related enquiries"
          checkboxState={false}
        />

        <CheckboxOptionCardReduxV1
          businessId="11111111"
          checkboxOptionsId="billing1234"
          checkboxText="Assistance with account and billing related enquiries"
        />

        <ToggleOptionCardV1
          toggleText="Notify this contact address"
          toggleState={false}
        />

        <ToggleOptionCardReduxV1
          toggleText="Notify this contact address"
          toggleState={false}
        />








        <br /><br />


        <Grid container spacing={24} justify="center" >
          <Grid item xs={12} sm={6} md={4} lg={4} >
            <RadioOptionCardV1
              radioOptionsGroup="subscriptionSelection"
              radioOptionsId="billing"
              headlineText="Account and billing support"
              secondaryText="Assistance with account and billing related enquiries"
            />

          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} >
            <RadioOptionCardV1
              radioOptionsGroup="subscriptionSelection"
              radioOptionsId="bbbbb"
              headlineText="Account and billing support"
              secondaryText="Assistance with account and billing related enquiries"
            />

          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} >
            <RadioOptionCardV1
              radioOptionsGroup="subscriptionSelection"
              radioOptionsId="ccccc"
              headlineText="Account and billing support"
              secondaryText="Assistance with account and billing related enquiries"
            />

          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} >
            <RadioOptionCardV1
              radioOptionsGroup="subscriptionSelection"
              radioOptionsId="ddddd"
              headlineText="Account and billing support"
              secondaryText="Assistance with account and billing related enquiries"
            />

          </Grid>
        </Grid>

        <br /><br />














        <StandardBtn
          icon=""
          iconLocation=""
          text="Set Local State Error"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.createErrorMessage}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Change State"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.changeState}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Delete Redux State"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.resetReduxState}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Change Redux State"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.changeReduxState}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Sync State"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
        />






        <StandardBtn
          icon=""
          iconLocation=""
          text="Test 1"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.testUpdate1}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Test 2"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.testUpdate2}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Test 3"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.testUpdate3}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Test 4"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.testUpdate4}
        />
        <StandardBtn
          icon=""
          iconLocation=""
          text="Test 4"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.insertItemsIntoRedux}
        />


        <InputTextV3
          id="test5.nested2.arr3.$.prop1"
          name="test5.nested2.arr3.$.prop1"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="label 1"
          value="bbb888"
          onChangeInputTextHandler={this.onChangeInputTextHandler}
          dataType="email" // custom=use pattern, email, phone, text, number, etc
          pattern=""

        />

        <InputTextV3
          id="test5.nested2.arr3.$.prop2"
          name="test5.nested2.arr3.$.prop2"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="label 2"
          value=""
          onChangeInputTextHandler={this.onChangeInputTextHandler}
        />

        <InputTextV3
          id="test5.nested2.arr3.$.core2222"
          name="test5.nested2.arr3.$.core2222"
          identifiers={[{ name: '222222' }]}
          stateIdentifier="Core2"
          label="label 222222"
          value=""
          onChangeInputTextHandler={this.onChangeInputTextHandler}
        />

        <StandardBtn
          icon=""
          iconLocation=""
          text="Remove items from Redux State"
          tooltip=""
          action=""
          classes="shctext shctext-default-primary shctext-rounded shctext-medium shctext-fullwidth"
          handleClick={this.removeItemsFromRedux}
        />


        <InputTextV3
          id="test5.nested2.arr3.$.prop10"
          name="test5.nested2.arr3.$.prop10"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="Number"
          value="Number"
          onChangeInputTextHandler={this.onChangeInputTextHandler}
          dataType="number" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
          pattern=""
        />
        <InputTextV3
          id="test5.nested2.arr3.$.prop11"
          name="test5.nested2.arr3.$.prop11"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="alphaNoSpace"
          value="alphaNoSpace"
          onChangeInputTextHandler={this.onChangeInputTextHandler}
          dataType="alphaNoSpace" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
          pattern=""
        />
        <InputTextV3
          id="test5.nested2.arr3.$.prop11"
          name="test5.nested2.arr3.$.prop11"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="email"
          value="email@a.com"
          onChangeInputTextHandler={this.onChangeInputTextHandler}
          dataType="email" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
          pattern=""
        />
        <InputTextV3
          id="test5.nested2.arr3.$.prop11"
          name="test5.nested2.arr3.$.prop11"
          identifiers={[{ name: 'a' }]}
          stateIdentifier="Core"
          label="custom"
          value="custom"
          onChangeInputTextHandler={this.onChangeInputTextHandler}
          dataType="custom" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
          pattern="^[A-Z]+$"
          dataErrorMessage="Please enter valid uppercase characters."
        />


      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = state => ({
  core: state.Core,
});

const mapDispatchToProps = {
  apiDataUpdate,
  resetReduxState,
  changeReduxState,
  testUpdateReduxState,
  test2UpdateReduxState,
  test2aUpdateReduxAction,
  test3UpdateReduxAction,
  test4UpdateReduxAction,
  RemoveReduxState,
  InsertIntoRedux,


  updateUIApiActivity,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
