import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Material UI
import Grid from '@material-ui/core/Grid';

// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { CardBasicV2 } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { InputText } from 'Components/ShcInput';
import { NavigationCard } from 'Components/ShcCards';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';

// Actions
// import { } from 'Actions';

// Images
//import LandingPageImage from 'Images/landing1.jpg';
//import PrimaryBackgroundImage from 'Images/Security/antique-business-classic-1364700-1920.jpg';
import PrimaryBackgroundImage from 'Images/Security/person-holding-a-smartphone-3740314.jpg';

import imagePersonHoldingPhone from 'Images/Security/person-holding-white-and-black-card-3740759.jpg';
import imagePersonHoldingBusinessCard from 'Images/Security/close-up-of-hand-holding-text-over-black-background-326576.jpg';
import imageCreditCardHandOver from 'Images/Security/shopping-business-money-pay-50987.jpg';
import imageWomanOnPhone from 'Images/Security/blurry-photo-of-a-woman-977840.jpg';

import HowItWorks from 'Images/Home/HowItWorksV1.jpg';

import primaryImage0_330 from 'Images/Home/launch1_0_330.jpg';
import primaryImage330_700 from 'Images/Home/launch1_330_700.jpg';
import primaryImage700_1250 from 'Images/Home/launch1_700_1250.jpg';
import primaryImage1250plus from 'Images/Home/launch1_1250_plus.jpg';

import guardPointLogoLong from 'Images/Logos/logo-long-grey-400x.jpg';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.verifyTokenCodeNow = this.verifyTokenCodeNow.bind(this);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);
    this.state = {
      verifyToken: '',
    };
  }

  verifyTokenCodeNow = () => {
    //console.log("verifyTokenCodeNow");
  }
  
  onChangeInputTextHandler = (evt) => {
    //console.log("input Handler v2");
    //console.log(evt.target.name);
    //FAIL //this.setState({[evt.target.name]: evt.target.value});

  }

  render() {

    const primaryImage = {
      backgroundImage: `url(${PrimaryBackgroundImage})`,
      width: '100%',
      height: 'auto',
    };

    const SectionContentSearchInteractionCode = () => (
      <div id="top">
        <div className={classNames({ publicHomePage100vhContainer: true })}>
          <div className={classNames({ publicHomePagePrimaryImageContainer: true })}>
            <img src={PrimaryBackgroundImage} className={classNames({ webImageSettings: true })} />
          </div>

          <div className={classNames({ publicHomePagePrimaryContentContainer: true })}>

            <div className={classNames({ publicHomePagePrimaryContent: true })}>
              <CardBasicV2>
                <div className={classNames({ publicHomePagePrimaryContent_head: true })}>
                
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ flexGrow: true })}>
                      <i class="far fa-question-circle"></i>
                      <i class="far fa-question-circle"></i>
                      <i class="far fa-question-circle"></i>

                    </div>
                  </div>

                  <TextStyle size="h2">
                    Consumer Identity Protection Service
                  </TextStyle>
                  <TextStyle size="h3">
                    Get protected from Cyber Security attacks! Learn more.
                  </TextStyle>
                  <TextStyle size="h4">
                    If you've been provided an interaction code, enter it below. We will advise you
                    of the identity of the business who has initiated this interaction. 

                  </TextStyle>
                </div>
                <div className={classNames({ publicHomePagePrimaryContent_body: true })}>

                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ flexGrow: true })}>
                      <InputText id="verifyToken" name="verifyToken" label="Enter Your Interaction Code" value={this.state.verifyToken ? this.state.verifyToken : ''} onChangeInputTextHandler={this.onChangeInputTextHandler} />
                    </div>
                  </div>
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ flexGrow: true })}>
                      
                      <StandardBtn
                        icon=""
                        iconLocation=""
                        text="Verify Interaction Code Now"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-info shcbtn-fullwidth"
                        handleClick={this.verifyTokenCodeNow}
                      />
                      
                    </div>
                  </div>

                </div>
                <div className={classNames({ publicHomePagePrimaryContent_foot: true })}>
                  What is this? Why? Who, How?
                </div>
              </CardBasicV2>




            </div>

          </div>
        </div>
      </div>
    );


    ///////////////////////////////////////////////////////////////////////////////////
    // PRIMARY LANDING - PITCH
    ///////////////////////////////////////////////////////////////////////////////////
    //<img src={PrimaryBackgroundImage} className={classNames({ webImageSettings: true })} />
    const SectionContentPrimary1 = () => (
      <div id="top">
        <div className={classNames({ publicHomePage100vhContainer: true })}>
          <div className={classNames({ publicHomePagePrimaryImageContainer: true })}>
            <img src={primaryImage0_330} className={classNames({ primaryImage0_330: true })} />
            <img src={primaryImage330_700} className={classNames({ primaryImage330_700: true })} />
            <img src={primaryImage700_1250} className={classNames({ primaryImage700_1250: true })} />
            <img src={primaryImage1250plus} className={classNames({ primaryImage1250plus: true })} />
          </div>

          <div className={classNames({ publicHomePagePrimaryContentContainer: true })}>

            <div className={classNames({ publicHomePagePrimaryContent: false, publicHomePagePrimaryContentV2: true })}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>

                  
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>

                  
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div className={classNames({ publicHomePagePrimaryContent_head: false })}>
                  
                  <div className={classNames({ text_s24: true, colour_white: true })}>
                    Know who you are talking to?
                  </div>
                  <div className={classNames({ text_s20: true, colour_white: true })}>
                    <ul>
                      <li>Concerned about identity theft?</li>
                      <li>Concerned about being scammed?</li>
                    </ul>
                    Join Guard Point for FREE where our only purpose is to provide 
                    consumers of business services cyber protection.
                  </div>
                  
    
                </div>
                <div className={classNames({ publicHomePagePrimaryContent_body: true })}>
                  
     
                </div>
                <div className={classNames({ publicHomePagePrimaryContent_foot: true })}>
                  
                </div> 
    
                </Grid>
              </Grid>

            </div>

          </div>
        </div>
      </div>
    );



    const SectionContentTemp = () => (
      <div id="top">
        <div className={classNames({ publicHomePage100vhContainer: true })}>
          <div className={classNames({ publicHomePagePrimaryImageContainer: true })}>
            <img src={PrimaryBackgroundImage} className={classNames({ webImageSettings: true })} />
          </div>

          <div className={classNames({ publicHomePagePrimaryContentContainer: true })}>

            <div className={classNames({ publicHomePagePrimaryContent: true })}>
              <CardBasicV2>
                <div className={classNames({ publicHomePagePrimaryContent_head: true })}>
                
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ flexGrow: true })}>

                    </div>
                  </div>

                  <TextStyle size="h2">
                    Under Construction
                  </TextStyle>
                  <TextStyle size="h3">
                    
                  </TextStyle>
                  <TextStyle size="h4">
                    We are currently under construction. Please check back later.

                  </TextStyle>
                </div>
              </CardBasicV2>

            </div>

          </div>
        </div>
      </div>
    );

    const SectionContentTwoSides = () => (
      <div id="top">

        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        You - As a Consumer
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        <ul>
                          <li>Do you receive phone calls and unsure of who they are?</li>
                          <li>Are you unable to verify the authenticity of a phone call?</li>
                          <li>Are you scared of being scammed?</li>
                          <li>Have you got concerns about identity theft?</li>
                          <li>Do you want to help stop identity theft, scams and spam?</li>
                        </ul>
                      </div>
                      <div className={classNames({ text_s24: true, })}>
                        Guard Point Cyber Protection
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Guard Point was developed with the sole purpose to protect consumers
                        with an enhanced level of personal security when interating with businesses.<br/><br/>
                        
                        Create a FREE account where we will provide an additional layer of security
                        to help protect your identity and reduce the risk of scams and spam. 

                        <br/><br/>

                        <Link
                        to={{pathname: "/sign-up",}}
                        className={classNames({ textDecoration: true, })}
                        >
                          <StandardBtn
                          icon=""
                          iconLocation=""
                          text="Sign Up for FREE"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-info shcbtn-fullwidth"
                          
                          />
                        </Link>
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        Businesses
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        <ul>
                          <li>Customers not answering your phone calls?</li>
                          <li>Customers concerned with scams impacting customer relationship building?</li>
                          <li>Unable to allow a customer to verify your business?</li>
                          <li>Having trouble proving your business identity to a customer?</li>
                          <li>Outbound customer calling connection rates extremely low?</li>
                          <li>Finding it hard to build a proactive relationship with customers?</li>
                          <li>Looking to improve your proactive customer engagement?</li>
                          <li>Want to protect your brand?</li>
                        </ul>
                      </div>
                      <div className={classNames({ text_s24: true, })}>
                        Guard Point Cyber Protection
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Developed to protect consumers of business services, Guard Point can help your business 
                        engage with your customers by providing:
                        <ul>
                          <li>Notification to your customer in advance of a business interaction.</li>
                          <li>Provide your customer a verified business contact profile.</li>
                          <li>Allow your customer to verify the authenticity of your business over multiple mediums.</li>
                          <li>Reduce the potential for illegitimate businesses to scam your customers masquerading as your business.</li>
                          <li>Reduce scammers masquerading as your business from attacks on your customers.</li>
                        </ul>

                        Businesses that truely value their customers cyber protection will use Guard Point 
                        as an additional layer of security for their customers.

                        <br/><br/>

                        Sign up your business now and join the fight
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>

          
          
        </div>

      </div>
    );


    const SectionContentHowItWorks = () => (
      <div id="top">

        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        <ol>
                          <li>A business advises Guard Point of an interaction attempt with you.</li>
                          <li>We ensure the business is verified and we send you a notification of their 
                            intention to communicate with you.
                            <ul>
                              <li>We also send you all the verified business contact details</li>
                              <li>A one-time-security-code that you can ask the business to advise you to prove their identity.</li>
                            </ul>  
                          </li>
                          <li>The business will make contact with you and will provide you with the one-time-security-code as proof 
                            that they are from the business notified to you via the Guard Point App.
                          </li>
                        </ol>


                        Guard Point verified businesses register their intention to make contact with you on our platform. 
                        Out platform sends you a notification of this business intention via the Guard Point App and provides you a one-time code that you
                        can use to verify the business. 
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                       
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    );

    const SectionContentValuePropConsumers = () => (
      <div id="top">
        
        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Managing your identity with Guard Point allows our platform to 
                        arm you with the information you need to decide who is a friend or foe.<br/>
                        We exist with the sole purpose of protecting your identity and to reduce the
                        chance of your being scammed.<br/>

                        <br/>
                        Our job is to ensure businesses verify their identities and that you are provided 
                        all the necessary information prior to a business interaction. 
                        <br/>
                        Learn more about the process and how it can help protect you.

                        CALL TO ACTION


                      </div>
                      <div className={classNames({ text_s16: true, })}>
                       
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    );

    const SectionContentValuePropBusinesses = () => (
      <div id="top">
        
        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Using Guard Point is demonstrating to your customers that you 
                        value their cyber protection. <br/>
                        With customers being notified in advance of you initating an interaction your
                        business will have a higher chance in making a successful connection.<br/>
                        With an increase of scammers using your business brands to initate attacks on your
                        customers, Guard Point will assist in protecting customers from people masquerading as
                        your brand.<br/>


                      </div>
                      <div className={classNames({ text_s16: true, })}>
                       
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    );


    const SectionContentWhyWeExist = () => (
      <div id="top">
        
        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Guard Point 
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                       
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        
      </div>
    );

    const SectionContentResources = () => (
      <div id="top">
        
        <div className={classNames({ flex: true, centerOnPage80vw: true})}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
                <div className={classNames({ pad10: true, })}>
                  <CardBasicV2 padding='none'>
                    <div className={classNames({ bgWhite: true, pad10: true, })}>
                      <div className={classNames({ text_s24: true, })}>
                        
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                        Resources
                        <ul>
                          <li>https://www.scamwatch.gov.au/</li>
                          <li>https://www.idcare.org</li>
                          <li>https://www.cyber.gov.au</li>
                          <li>https://www.staysmartonline.gov.au</li>
                        </ul>
                      </div>
                      <div className={classNames({ text_s16: true, })}>
                       
                      </div>
                    </div>
                  </CardBasicV2>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        
      </div>
    );


    const OverlayScreen = () => (
      <div>
          Verify a Business Interaction
      </div>
    );


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div>
          <SectionContentPrimary1/>
        </div>

        <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
          <img src={guardPointLogoLong} className={classNames({ aaaaa: true })} />
        </div>




        <div className={classNames({ pad20: true })}>

          <div className={classNames({ flex: true, flexJustifyCenter: true, contentContainerSmallScreenColumn: true })}>
            <div className={classNames({ marR10: true, bgWhite: true, contentContainer: true, maxWidth350: true,
              flexEvenChild: true, flexGrow: true, })}>
              
              <div className={classNames({ flex: true })}>
                <div className={classNames({ padR20: true, text_s36: true })}>
                  <i class="fas fa-user-circle"></i>
                </div>
                <div className={classNames({ ccc: true })}>
                  <div className={classNames({ text_s20: true })}>
                    Consumer
                  </div>
                  <div className={classNames({ text_s12: true })}>
                    Guard Point is a consumer protection service aimed
                    at providing you and your family with protection from
                    scams, spam and identity theft.
                  </div>
                </div>

              </div>

            </div>
            <div className={classNames({ marL10: true,  bgWhite: true, contentContainer: true, maxWidth350: true, 
              flexEvenChild: true, flexGrow: true, })}>
              
              <div className={classNames({ flex: true })}>
                <div className={classNames({ padR20: true, text_s36: true })}>
                  <i class="fas fa-building"></i>
                </div>
                <div className={classNames({ ccc: true })}>
                  <div className={classNames({ text_s20: true })}>
                    Businesses
                  </div>
                  <div className={classNames({ text_s12: true })}>
                    Guard Point provides protection against threats to your 
                    customers such as scams, spam and identity theft. 
                    
                  </div>
                </div>
              </div>

            </div>
          </div>

          


        </div>

        <br/><br/>



        


        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreenColumnReverse: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: true, colour_white: true })}>
              <div className={classNames({ pad40: true, })}>
                <div className={classNames({ text_s24: true, })}>
                  What we are all concerned about...
                </div>
                <div className={classNames({ text_s14: true, })}>
                  <ul>
                    <li>Receiving phone calls, not knowing who is calling.</li>
                    <li>How to verify the authenticity of a phone call.</li>
                    <li>Whether I am being scammed. Whether my identity or money is as risk.</li>
                  </ul>
                </div>
                <div className={classNames({ text_s14: true, })}>
                  In today's world, we are all concerned with protecting our identity and our hard earned
                  money. Scammers are adapting their attacks daily, outpacing the security practices being implemented
                  to stop the attacks. <br/><br/>
                </div>
                <div className={classNames({ text_s14: true, })}>
                  The Guard Point platform is looking at the problem through a different lense with a focus
                  on protecting the consumer (That's you!).<br/><br/>

                  
                  <div className={classNames({ text_s16: true, })}>
                    How we do this differently?
                  </div>
                  <ul>
                    <li>We focus on the identity verification of a business on your behalf.</li>
                    <li>Businesses must register an interaction intention with Guard Point first.</li>
                    <li>Guard Point advises all the parties (You).</li>
                    <li>We provide a mechanism for you to verify the business over any medium.</li>
                  </ul>
                </div>

                <div className={classNames({ text_s24: true, })}>
                  Guard Point is changing the way Businesses interact with You.
                </div>

                <div className={classNames({ text_s14: true, })}>
                  Guard Point was developed with the sole purpose to protect consumers
                  with an enhanced level of personal security when interating with businesses.<br/><br/>
                  
                  Create a FREE account where we will provide an additional layer of security
                  to help protect your identity and reduce the risk of scams and spam. 

                  <br/><br/>

                  Sign up for FREE
                </div>
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, gradientBg: true })}>
              <div className={classNames({ pad10: true,  
              flex: true, flexColumn: true, flexJustifyCenter: true, text_s36: true, colour_white: true,
              flexAlignItemsCenter: true, height100p: true })}>
                
                Consumers - This is You<br/>
                <div className={classNames({ text_s16: true, })}>
                  Who's looking after you?
                </div>

              </div>
            </div>
          </div>
        </div>

        <br/><br/>

        <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
          <img src={guardPointLogoLong} className={classNames({ aaaaa: true })} />
        </div>
        

        <br/><br/>

        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: true, colour_white: true })}>
              <div className={classNames({ pad40: true, })}>
                
                <div className={classNames({ text_s24: true, })}>
                  Businesses
                </div>
                <div className={classNames({ text_s14: true, })}>
                  <ul>
                    <li>Customers not answering your phone calls?</li>
                    <li>Customers concerned with scams impacting customer relationship building?</li>
                    <li>Unable to allow a customer to verify your business?</li>
                    <li>Having trouble proving your business identity to a customer?</li>
                    <li>Outbound customer calling connection rates extremely low?</li>
                    <li>Finding it hard to build a pro active relationship with customers?</li>
                    <li>Looking to improve your proactive customer engagement?</li>
                    <li>Want to protect your brand?</li>
                  </ul>
                </div>
                <div className={classNames({ text_s24: true, })}>
                  Guard Point Cyber Protection
                </div>
                <div className={classNames({ text_s16: true, })}>
                  Developed to protect consumers of business services, Guard Point can help your business 
                  engage with your customers by providing:
                  <ul>
                    <li>Notification to your customer in advance of a business interaction.</li>
                    <li>Provide your customer a verified business contact profile.</li>
                    <li>Allow your customer to verify the authenticity of your business over multiple mediums.</li>
                    <li>Reduce the potential for illegitimate businesses to scam your customers masquerading as your business.</li>
                    <li>Reduce scammers masquerading as your business from attacks on your customers.</li>
                  </ul>

                  Businesses that truely value their customers cyber protection will use Guard Point 
                  as an additional layer of security for their customers.

                  <br/><br/>

                  Sign up your business now and join the fight
                </div>

              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, gradientBg: true })}>
              <div className={classNames({ pad10: true,  
              flex: true, flexColumn: true, flexJustifyCenter: true, text_s36: true, colour_white: true,
              flexAlignItemsCenter: true, height100p: true })}>
                
                Businesses
                <div className={classNames({ text_s16: true, })}>
                  Customers best interest at heart?
                </div>

              </div>
            </div>
          </div>
        </div>


        <br/><br/>

        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: true, colour_white: true })}>
              <div className={classNames({ pad10: true, })}>
                How we work IMAGE
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ contentContainerImageMax450: true, })}>
                  <img src={imagePersonHoldingPhone} className={classNames({ contentImage: true })} />
                </div>
            </div>
          </div>
        </div>

        
        
        <br/>

        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: false, color_primary_dark: true })}>
              <div className={classNames({ pad10: true, })}>
                When the general advice from crime prevention groups is to 
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ contentContainerImageMax450: true, contentContainerRoundedImageOnLeft: true })}>
                <img src={primaryImage700_1250} className={classNames({ contentImage: true })} />
              </div>
            </div>
          </div>
        </div>
        
        <br/>
        

        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: false, color_primary_dark: true })}>
              <div className={classNames({ pad10: true, })}>
                When the general advice from crime prevention groups is to 
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ contentContainerImageMax450: true, contentContainerRoundedImageOnLeft: true })}>
                <img src={imagePersonHoldingBusinessCard} className={classNames({ contentImage: true })} />
              </div>
            </div>
          </div>
        </div>
        
        <br/>
        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: false, color_primary_dark: true })}>
              <div className={classNames({ pad10: true, })}>
                $142 million has been lost to scams in Australia  - 2019<br/>
                $64 million has been lost to scams in Australia - In the first 5 months of 2020<br/><br/>

                During 2020 phone scams rated second highest to email scams in the number of reported incidents and
                topped the amount of money lost by consumers at $19 million.
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ contentContainerImageMax450: true, contentContainerRoundedImageOnLeft: true })}>
                <img src={imageCreditCardHandOver} className={classNames({ contentImage: true })} />
              </div>
            </div>
          </div>
        </div>
        
        <br/>
        <div className={classNames({ contentEdgeToEdgeContainer: true, })}>
          <div className={classNames({ flex: true, centerOnPage100vw: true, minHeight450: true, contentContainerSmallScreen: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, bg_color_grey_dark: false, color_primary_dark: true })}>
              <div className={classNames({ pad10: true, })}>
                When the general advice from crime prevention groups is to 
              </div>
            </div>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ contentContainerImageMax450: true, contentContainerRoundedImageOnLeft: true })}>
                <img src={imageWomanOnPhone} className={classNames({ contentImage: true })} />
              </div>
            </div>
          </div>
        </div>
        
        <br/>






        <div className={classNames({ pad20: true })}>

          <div className={classNames({ flex: true, centerOnPage80vw: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ pad10: true, })}>
                <div className={classNames({ text_s36: true, flex: true, flexJustifyCenter: true })}>
                <i className="fas fa-route" /> &nbsp;
                  How it works
                </div>
              </div>
            </div>
          </div>

          


          <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
            <img src={HowItWorks} className={classNames({ aaaaa: true })} />
          </div>




          <div>
            <SectionContentHowItWorks/>
          </div>

          <div className={classNames({ flex: true, centerOnPage80vw: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ pad10: true, })}>
                <div className={classNames({ text_s36: true, flex: true, flexJustifyCenter: true })}>
                  Value for Consumers - You
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionContentValuePropConsumers/>
          </div>

          <div className={classNames({ flex: true, centerOnPage80vw: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ pad10: true, })}>
                <div className={classNames({ text_s36: true, flex: true, flexJustifyCenter: true })}>
                  Value for Businesses
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionContentValuePropBusinesses/>
          </div>





          <div className={classNames({ flex: true, centerOnPage80vw: true})}>
            <div className={classNames({ flexEvenChild: true, flexGrow: true, })}>
              <div className={classNames({ pad10: true, })}>
                <div className={classNames({ text_s24: true, flex: true, flexJustifyCenter: true })}>
                  Why we exist
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionContentWhyWeExist/>
          </div>

          <div>
            <SectionContentResources/>
          </div>

          
        
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
  identities: state.BusinessIdentities.identities,
});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
