import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { generateVerificationTokenWebsite } from 'Actions';


class StepUserOverview extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child
    this.handleBusinessVerificationTokenWebsiteGen = this.handleBusinessVerificationTokenWebsiteGen.bind(this);


    this.state = {
      sharedState: {},  //state object
      userIdentity: this.props.value,

      
      identity: '',
      identitylabel: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps - StepBusinessWebsite");
    //We are comparing the inbound value (websiteStep) which is an object with the local state paramaters



    console.log(JSON.stringify(nextProps.value) +" !== "+ JSON.stringify(prevState.sharedState));
    if (nextProps.value !== undefined && nextProps.value !== prevState.sharedState) {
      return { sharedState: nextProps.value };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - StepBusinessWebsite");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform up of state for StepBusinessWebsite");
      this.setState({
        sharedState: this.props.value,
        identity: this.props.value.identity,
        identityLabel: this.props.value.identityLabel,

      });
    }
  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    console.log("===============================");
    console.log("SEND TO PARENT: inputTextChange");

    //Update local state (do not update fields that are shared with parent)
    this.setState({
      [evt.target.name]: evt.target.value,
    });

    let updateData= {
      field: evt.target.name,
      value: evt.target.value,
    }
    this.props.onChangeStepInputHandler(this.props.id, this.state.sharedState, updateData);
  }

  handleBusinessVerificationTokenWebsiteGen(event) {
    event.preventDefault();
    console.log('getting verification token (website)');
    this.props.generateVerificationTokenWebsite();
  }

  


  render() {

    const { value, activityType } = this.props;
    //console.log("THE VALUE FROM PARENT: "+JSON.stringify(value));

    
    const ActivityType = () => {
      if (activityType=="website") {
        return (
          <TextStyle size="h3" weight="normal">Website verification process</TextStyle>   
        )
      } else if (activityType=="email") {
        return (
          <TextStyle size="h3" weight="normal">Email verification process</TextStyle>
        )
      } else if (activityType=="phone") {
        return (
          <TextStyle size="h3" weight="normal">Phone verification process</TextStyle>
        )
      } else {
        return (
          <TextStyle size="h3" weight="normal">Unknown verification process</TextStyle>
        )
      }
      
    }


    const GetStarted = () => {
      return (
        <div>
          Follow these steps to create and verify an identity.<br/>
          Once created, you will be able to assign this identity to a business profile.
          
        </div>
      )
    }

    const CurrentStatus = () => {
      if (this.state.userIdentity.verificationStatus===true) {
        return (
          <div>
            <TextStyle size="h3" weight="bold">Current Status</TextStyle>
            <div>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <i class="fas fa-check"></i> The identity ({this.state.userIdentity.identity}) is verified. 
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <TextStyle size="h3" weight="bold">Current Status</TextStyle>
            <div>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <i class="fas fa-times"></i> The identity ({this.state.userIdentity.identity}) is NOT verified. 
                </div>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          <TextStyle size="h3" weight="bold">Current Status</TextStyle>
          <div>
            <br />
            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })}>
                <i class="fas fa-check"></i> The identity ({this.state.userIdentity.identity}) is verified. 
              </div>
            </div>

            <div className={classNames({ flex: true })}>
              <div className={classNames({ flexGrow: true })}>
                <i class="fas fa-times"></i> The identity ({this.state.userIdentity.identity}) is NOT verified. 
              </div>
            </div>
          </div>
        </div>
      )
    }




    return (
      <InputCard headlineText="Overview" headlineSubText="">
        <ActivityType/>
        <br/>
        <GetStarted/>
        <br/><br/>
        <CurrentStatus/>
        <br/><br/>
        
        <br/><br/>
        
        <TroubleshootingAdminWindow>
          <div>
            <TextStyle size="h5">
              {JSON.stringify(this.state.sharedState)}
            </TextStyle>
          </div>
        </TroubleshootingAdminWindow>


      </InputCard>
    );
  }
}

StepUserOverview.propTypes = {
  
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  generateVerificationTokenWebsite
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StepUserOverview);
