import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardIcon } from 'Components/ShcButtons';

// Actions
import { setUiCheckboxOptions } from 'Actions';

class CheckOptionItemV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      checkboxOptionsGroup: this.props.checkboxOptionsGroup,
      checkboxOptionsId: this.props.checkboxOptionsId,
      checkboxSelectedState: this.props.checkboxSelectedState,

      businessId: this.props.businessId,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - CheckOptionItemV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');

    //console.log('RadioOptions | businessId');
    //console.log(this.state.businessId);

    const selection = {
      businessId: this.state.businessId,
      checkboxOptionsGroup: this.state.checkboxOptionsGroup,
      checkboxOptionsId: this.state.checkboxOptionsId,
      checkboxSelectedState: !this.props.checkboxSelectedState,    //from props

      overrideCheckboxOptionGroup: this.props.overrideCheckboxOptionGroup,  //Allow override of Option Group selections
    };

 
    //console.log(selection);
    this.props.setUiCheckboxOptions(selection);



  }

  render() {
    const {
      businessId,
      checkboxOptionsId,
      overrideCheckboxOptionGroup,
      headlineText,
    } = this.props;

    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ padR15: true, cursorPointer: true })}>
          {this.props.checkboxSelectedState ?
            <StandardIcon
              icon="far fa-check-square"
              classes="colour_primary"
              handleClick={this.toggleSelectedItem}
            />
          :
            <StandardIcon
              icon="far fa-square"
              classes=""
              handleClick={this.toggleSelectedItem}
            />
          }
        </div>
        <div className={classNames({ aaa: true })}>
          { headlineText ?
            <div>
              <div className={classNames({ ShcCheckboxOptionCard_checkboxText: true })}>
                {headlineText}
              </div>
            </div>
          : null }
        </div>
      </div>
    );
  }
}

CheckOptionItemV1.propTypes = {

};
CheckOptionItemV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
  //console.warn('mapStateToProps | CheckOptionItemV1');
  //console.warn(state.UI.uiCheckboxOptions);
  
  let checkboxSelectedState = false;
  if (state.UI.uiCheckboxOptions) {
    //console.log('mapStateToProps | uiCheckboxOptions is true');
    for (let z = 0; z < state.UI.uiCheckboxOptions.length; z++) {
      //console.warn(state.UI.uiCheckboxOptions[z]);
      //console.warn(JSON.stringify(state.UI.uiCheckboxOptions[z]));
      //console.log(`mapStateToProps | seaching for business id: ${state.UI.uiCheckboxOptions[z].businessId} === ${ownProps.businessId}`);
      
      let businessId = '';
      try {
        businessId = state.UI.uiCheckboxOptions[z].businessId;
      } catch (e) {}
      if (businessId === undefined) {
        businessId = '';
      }
      
      if (businessId !== '') {
        if (state.UI.uiCheckboxOptions[z].businessId.toString() === ownProps.businessId.toString()) {
          //console.log('mapStateToProps | Checkbox mapped to business id');

          if (state.UI.uiCheckboxOptions[z].checkboxOptionsId.toString() === ownProps.checkboxOptionsId.toString()) { // this ID
            //console.log('mapStateToProps | Checkbox mapped to checkbox id');
            //console.log(state.UI.uiCheckboxOptions[z].checkboxSelectedState);
            checkboxSelectedState = state.UI.uiCheckboxOptions[z].checkboxSelectedState;
          }
        }
      } else {
        if (state.UI.uiCheckboxOptions[z].checkboxOptionsId.toString() === ownProps.checkboxOptionsId.toString()) { // this ID
          //console.log('mapStateToProps | Checkbox mapped to checkbox id');
          //console.log(state.UI.uiCheckboxOptions[z].checkboxSelectedState);
          checkboxSelectedState = state.UI.uiCheckboxOptions[z].checkboxSelectedState;
        }
      }
    }
  } else {
    checkboxSelectedState = false;
    //console.log('mapStateToProps | Checkbox state not found');
  }
  
  return {
    checkboxSelectedState,
    uiCheckboxOptions: Object.assign([], state.UI.uiCheckboxOptions),
  };
};

const mapDispatchToProps = {
  setUiCheckboxOptions,
};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(CheckOptionItemV1);
