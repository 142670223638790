import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Dropdown from 'react-dropdown';



// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, CheckboxOptionCardReduxV1 } from 'Components/ShcInput';
import { NavigationCard, DashCard1, DashCard2, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessDashboardCard, BusinessDashboardCardV3 } from 'Components/ShcModulesBusiness';


// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { setUiCheckboxOptions, toggleOverlayOnScreenResponsive, createBusiness, syncDataBusiness, addGlobalToastMessage } from 'Actions';



// Functions
import { equal } from 'fast-deep-equal';
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


//Documents
import BusinessSaaSTermsDocumentUrl from 'Documents/Guard Point Pty Ltd - Saas Terms.pdf';




class Template extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.addNewBusinessSetup = this.addNewBusinessSetup.bind(this);
    this.addNewBusiness = this.addNewBusiness.bind(this);
    this.syncBusinesses = this.syncBusinesses.bind(this);

    this.addToastNotification = this.addToastNotification.bind(this);
    this.handleSelectInputBusinessDataRegion = this.handleSelectInputBusinessDataRegion.bind(this);

    this.state = {
      businessDataRegion: '',


      businessViewId: this.props.match.params.routebusinessid,
      user: this.props.user, // The main user object

      apiTimerCollection: [], // List of active timers. (MANDATORY)

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - AccountBusinessPage');
    //console.log('prevState');
    //console.log(prevState);
    //console.log('nextProps');
    //console.log(nextProps);

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      //console.log('Updating state from getDerivedStateFromProps');
      // const businessIdentity = nextProps.allIdentities.businesses.find(business => business.id === prevState.businessViewId);
      return {
        user: nextProps.user,
      };
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('shouldComponentUpdate');

    this.forceUpdate();

  }

  componentDidUpdate(prevProps) {
    //console.log('componentDidUpdate');
    /*
    if(!equal(this.props.allIdentities, prevProps.allIdentities)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
     //console.log("****** COMPONENT DID UPDATE ********");
    }
    */
  }
  syncBusinesses(evt) {
    //console.log('function: syncBusinesses');
    // Call action to create business.
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    this.props.syncDataBusiness({ apiUi });
  }

  addToastNotification() {
    let rand = Math.floor(Math.random() * 905); 
    let randId = Math.floor(Math.random() * 94823); 
    let data = {
      id: randId,
      message: `Test Toast: ${rand}`,
      type: 'success',
    }
    this.props.addGlobalToastMessage(data);
  }


  addNewBusinessSetup(evt) {
    //Reset the SaaS terms selection to false (unchecked)
    
    if (this.props.saasTermsAcceptance === true) {
      //Reset the value
      const selection = {
        businessId: '',
        checkboxOptionsId: "businessAccountCreationGuardPointSaasTermsAgreement",
        checkboxSelectedState: false,
      };
      this.props.setUiCheckboxOptions(selection);
    }

    /*
    By checking this box I confirm that I have read and agree to be bound by the Apple Developer Program License Agreement above. If I am agreeing on behalf of my company, I represent and warrant that I have legal authority to bind my company to the terms of such Agreement above. I also confirm that I am of the legal age of majority in the jurisdiction in which I reside (at least 18 years of age in many countries).
    */


    this.props.toggleOverlayOnScreenResponsive('open', "createBusinessSettings");
  }



  addNewBusiness(evt) {
    //console.log('function: addNewBusiness');
    let allowCreationOfBusiness = true;

    if (this.props.saasTermsAcceptance === false) {
      allowCreationOfBusiness = false;
      //TOAST
      let randId = Math.floor(Math.random() * 94823); 
      let data = {
        id: randId,
        message: `Please read and accept the GuardPoint SaaS Terms to create a business`,
        type: 'error',
      }
      this.props.addGlobalToastMessage(data);
    }


    let businessDataRegion = this.state.businessDataRegion;
    if (businessDataRegion === '' || businessDataRegion === undefined || businessDataRegion === null) {
      allowCreationOfBusiness = false;
      //TOAST
      let randId = Math.floor(Math.random() * 94823); 
      let data = {
        id: randId,
        message: `Please select a region to store your business data`,
        type: 'error',
      }
      this.props.addGlobalToastMessage(data);
    }

    if (allowCreationOfBusiness === true) {
      const apiActivityId = evt.currentTarget.dataset.apiactivityid;
      const apiUi = { apiActivityId };
      const data = {
        businessDataRegion: this.state.businessDataRegion,
      }
      console.warn(`businessDataRegion: ${this.state.businessDataRegion}`);
      this.props.createBusiness({ data, apiUi });
      this.closeOverlayOnScreen('createBusinessSettings');
    }
  }




  
  handleSelectInputBusinessDataRegion(evt) {
    //console.log("handleSelectInputBusinessDataRegion");
    //console.log(evt);
    
    let value = evt.value;

    //console.log("value");
    //console.log(value);

    this.setState({
      businessDataRegion: value,
    });

    
    
  }



  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  render() {

    let dataRegions = [];
    dataRegions.push({value: 'aus', label: 'Australia'});
    //dataRegions.push({value: 'usa', label: 'United States'});     //currently disabled

    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ flex: true })}>
            <div className={classNames({ flexGrow: true })}>
              <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
                {this.props.title}
              </div>
              <div className={classNames({  })}>
                These are businesses that you own or manage.
              </div>
            </div>
            <div>
              <StandardApiBtn
                apiActivityId="AddNewBusiness"
                icon=""
                iconLocation=""
                text="Add Business"
                tooltip=""
                action=""
                classes="shctext shctext-primary"
                handleClick={this.addNewBusinessSetup}
              />
            </div>

     

          </div>

          <br />


          <div className={classNames({ flexJustifyFlexStart: true })}>
            <div class="shcGrid290">
              {this.props.businessIdentities &&
              this.props.businessIdentities.businesses &&
              this.props.businessIdentities.businesses.length > 0 ?
                // Arrow function preserves "this"
              this.props.businessIdentities.businesses.map((item, i) => {
                let invitationCard = false;
                let disabledAccountCard = false;
                try {
                  if (item.uiDisplayType === 'Invitation') {
                    invitationCard = true;
                  }
                } catch (e) {}
                try {
                  if (item.uiDisplayType === 'Disabled') {
                    disabledAccountCard = true;
                  }
                } catch (e) {}

                let businessAccountClosed = '';
                try {
                  businessAccountClosed = item.accountClosed;
                } catch (e) {
                  businessAccountClosed = '';
                }
                if (businessAccountClosed === undefined) {
                  businessAccountClosed = '';
                }

                let businessAccountPostClosure = '';
                try {
                  businessAccountPostClosure = item.accountPostClosure;
                } catch (e) {
                  businessAccountPostClosure = '';
                }
                if (businessAccountPostClosure === undefined) {
                  businessAccountPostClosure = '';
                }

                if (invitationCard === true) {
                  return (
                    <div class="shcGridItem">
                      <Link to={`/account/business/`} className={classNames({textDecoration: true,})}>
                        <BusinessDashboardCardV3
                          displayType="invite"

                          businessId={item._id}
                          headlineSubText=""
                          headlineSubIcon="fas fa-user-circle"
                          headlineText={this.props.businessIdentities.businesses[i].businessProfile.businessName === '' ? 'Business Name Not Set' : `${this.props.businessIdentities.businesses[i].businessProfile.businessName}`}
                          secondaryText=''
                          additionalText={`Business ID: ${item._id}`}
                          linkText=""
                          linkPath=""
                          index={i}
                          
                          />
                      </Link>
                    </div>
                  )
                } else if (disabledAccountCard === true) {
                  return (
                    <div class="shcGridItem">
                      <Link to={`/account/business/`} className={classNames({textDecoration: true,})}>
                        <BusinessDashboardCardV3
                          displayType="disabled"

                          businessId={item._id}
                          headlineSubText=""
                          headlineSubIcon="fas fa-user-circle"
                          headlineText={this.props.businessIdentities.businesses[i].businessProfile.businessName === '' ? 'Business Name Not Set' : `${this.props.businessIdentities.businesses[i].businessProfile.businessName}`}
                          secondaryText=''
                          additionalText={`Business ID: ${item._id}`}
                          linkText=""
                          linkPath=""
                          index={i}
                          
                          />
                      </Link>
                    </div>
                  )
                } else {
                  return (
                    <div class="shcGridItem">
                      {/* 
                      <Link to={`/account/business/${this.props.businessIdentities.businesses[i]._id}/customer-connect`} className={classNames({textDecoration: true,})}>
                      */}
                        <BusinessDashboardCardV3
                          displayType="normal"

                          businessId={this.props.businessIdentities.businesses[i]._id}
                          headlineSubText=""
                          headlineSubIcon="fas fa-user-circle"
                          headlineText={this.props.businessIdentities.businesses[i].businessProfile.businessName === '' ? 'Set up your business now' : `${this.props.businessIdentities.businesses[i].businessProfile.businessName}`}
                          secondaryText={`${this.props.businessIdentities.businesses[i].businessProfile.primaryWebsite}`}
                          additionalText={`Business ID: ${this.props.businessIdentities.businesses[i]._id}`}
                          linkText="Open Business"
                          linkPath=""
                          index={i}
                          businessAccountClosed={businessAccountClosed}
                          businessAccountPostClosure={businessAccountPostClosure}
                          // onChangeBusinessProfileBusinessRegistryHandler={this.onChangeBusinessProfileBusinessRegistryHandler}
                          // businessIdentityRemoveItem={this.businessIdentityRemoveItem}
                          // value={this.props.businessProfile.businessRegistry[i]}
                          
                          />
                      {/*
                      </Link>
                      */}
                    </div>
                  )
                }

              }, this)
              :
              (
                <InputCard headlineText="Setup a Business to Connect with People" headlineSubText="">

                  Allow your customers to be notified when you are making contact with them across
                  a variety of mediums. 

               


                </InputCard>
              )
              }
            </div>
          </div>



          <br/><br/>

          








          <OverlayOnScreenResponsive
            id="createBusinessSettings"
            type="default"
            width="50%"
            height="100vh"
            anchor="right"
            title="New Business Settings"
            classes=""
          >
            <div className={classNames({ pad10: true })}>
              <InputCard headlineText="Business Setup" 
              headlineSubText="These settings cannot be changed once set.">

                <InputCardItem headlineText="GuardPoint SaaS Terms" 
                headlineSubText="Please read and agree to the GuardPoint software-as-a-service 
                terms before continuing">
                  <div className={classNames({ flex: true,})}>
                    <div className={classNames({ minWidth350: true, padL30: true })}>

                      <TextStyle size="h4">
                      <a href={BusinessSaaSTermsDocumentUrl} target='_blank'>GuardPoint SaaS Terms</a>
                      </TextStyle>
                      <br/>

                      <CheckboxOptionCardReduxV1
                      businessId=""
                      checkboxOptionsId="businessAccountCreationGuardPointSaasTermsAgreement"
                      checkboxText="On behalf of my business I accept the GuardPoint SaaS terms."
                      />

                    </div>
                  </div>
                </InputCardItem>

                <InputCardItem headlineText="Business Data" 
                headlineSubText="Select a region where we will set up your account and store your business data.">
                  <div className={classNames({ flex: true,})}>
                    <div className={classNames({ minWidth350: true })}>
                      <Dropdown 
                      options={dataRegions} 
                      onChange={this.handleSelectInputBusinessDataRegion} 
                      value={dataRegions.find(option => option.value === this.state.businessDataRegion)}
                      placeholder="Select an option" />
                    </div>
                  </div>
                </InputCardItem>

                <br/>

                <div className={classNames({ flexGrow: true })}>
                  <StandardApiBtn
                  apiActivityId="CreateNewBusiness"
                  icon=""
                  iconLocation=""
                  text="Create Business"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                  handleClick={this.addNewBusiness}
                  />
                
 
                  
                </div>


              </InputCard>
  
              <br/>
              <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
                <div className={classNames({ abc: true })}>
                  <StandardBtn
                    icon=""
                    iconLocation=""
                    text="Close"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-secondary"
                    handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                  />
                </div>
                <div className={classNames({ abc: true })}>
                  
                </div>
              </div>

            </div>
          </OverlayOnScreenResponsive>

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentities = null;
  try {
    businessIdentities = state.BusinessIdentities.identities;
  } catch (e) {
    console.error('mapStateToProps | Unable to list all business identities');
  }

  let uiCheckboxOptions = null;
  try {
    uiCheckboxOptions = state.UI.uiCheckboxOptions;
  } catch (e) {}

  let saasTermsAcceptance = false;
  for (let x = 0; x < uiCheckboxOptions.length; x++) {
    if (uiCheckboxOptions[x].checkboxOptionsId === 'businessAccountCreationGuardPointSaasTermsAgreement') {
      if (uiCheckboxOptions[x].checkboxSelectedState === true) {
        saasTermsAcceptance = true;
      } else {
        saasTermsAcceptance = false;
      }
    }
  }



  return {
    businessIdentities: Object.assign({}, businessIdentities),
    saasTermsAcceptance: saasTermsAcceptance,
    user: state.User,
  };

};

const mapDispatchToProps = {
  setUiCheckboxOptions,
  toggleOverlayOnScreenResponsive,
  createBusiness,
  syncDataBusiness,
  addGlobalToastMessage,
};


// TODO: This page when loaded needs to resync all the businesses or provide an option to resync.
// sometimes when creating a new tab, state isn't transferred across so it looks like some businesses don't exist yet.

/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);

/*
<StandardApiBtn
apiActivityId="SyncBusinesses"
icon=""
iconLocation=""
text="Sync"
tooltip=""
action=""
classes="shctext shctext-primary"
handleClick={this.syncBusinesses}

/>



<div>
  <Grid container spacing={24}>
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Link to={`/account/business/`} className={classNames({textDecoration: true,})}>
        <BusinessDashboardCardV3
          displayType="invite"

          businessId="0000000"
          headlineSubText=""
          headlineSubIcon="fas fa-user-circle"
          headlineText={`Bank Bli Bli 32`}
          secondaryText={``}
          additionalText={`Business ID: 2342342344324`}
          linkText=""
          linkPath=""
          
          />
      </Link>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Link to={`/account/business/`} className={classNames({textDecoration: true,})}>
        <BusinessDashboardCardV3
          displayType="disabled"

          businessId="0000000"
          headlineSubText=""
          headlineSubIcon="fas fa-user-circle"
          headlineText={`Bank Bli Bli 32`}
          secondaryText={``}
          additionalText={`Business ID: 2342342344324`}
          linkText=""
          linkPath=""
          
          />
      </Link>
    </Grid>

    
  </Grid>
</div>
*/