// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS

// Components
import CollapsableNavListItem from 'Components/CollapsableNavListItems';

// Actions


class shcParentSidebarNavLinks extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { customLinks, routeList, isAuthenticated, isAdmin } = this.props;

    let customRouteList = [];
    let customLinkList = false;


    if (customLinks !== null && customLinks !== '' && customLinks !== undefined) {
      //console.log("customLinks");
      //console.log(customLinks);
      

      customRouteList = Routes.filter(link => {
        //console.log("assessing custom routes");
        //console.log(link.path);
        //console.log(customLinks);
        //console.log(customLinks.includes(link.path));
        return customLinks.includes(link.path);
      });
  
      //console.log("customRouteList NAv Links Component");
      //console.log(customRouteList);

    }

    let RouteSort = [];
    if (customRouteList.length > 0) {
      if (customRouteList.length > 1) {
        RouteSort = customRouteList.sort((a, b) => a.navPosition - b.navPosition);
        customLinkList = true;
      } else {
        RouteSort = customRouteList;
        customLinkList = true;
      }
    } else if (routeList.length > 0) {
      if (routeList.length > 1) {
        RouteSort = routeList.sort((a, b) => a.navPosition - b.navPosition);
      } else {
        RouteSort = routeList;
      }
    }

    
    let navDropDownItemsUserData;

    const ListItemCustomControl = (props, state) => {
      const {
        path, exact, permission, navTitleCollapsed,
        navTitleExpanded, navTitle, displayicon, navVisible,
        navDropDownItems, navDropDownUserState,
        navPosition,
      } = props.navData;
      const { customLinkList } = props;

      if (customLinkList === false) {
        if (!navVisible) return null;
      }

      // console.log('----------START--------------');
      // console.log(props.adminRoute);
      // console.log(isAdmin);
      // console.log(props.authRoute);
      // console.log(isAuthenticated);
      // console.log('-----');

      // console.log(`${props.adminRoute} && ${!isAdmin}`);
      // console.log(`${props.authRoute} && ${!isAuthenticated}`);

      if (props.adminRoute && !isAdmin) return null;
      if (props.authRoute && !isAuthenticated) return null;

      if (typeof navDropDownItems !== 'undefined') {
        if (navDropDownItems.length > 0) {
          // console.log('Nav has drop down items');
          return (
            <CollapsableNavListItem hasChildren navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navDropDownItems={navDropDownItems} navDropDownUserState={navDropDownUserState} navPosition={navPosition} />
          );
        }
      }
      return (
        <CollapsableNavListItem to={`/${path}`} hasChildren={false} navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navPosition={navPosition} />

      );
    };
    //Changed key from singleRoute.key to singleRoute.path
    return (
      <div>

        {
          RouteSort.map(singleRoute => (

            <li key={singleRoute.path} data-item={singleRoute.navPosition}>
              {
                <ListItemCustomControl
                  navData={singleRoute}
                  adminRoute={singleRoute.permission.includes('admin')}
                  authRoute={singleRoute.permission.includes('auth')}
                  customLinkList={customLinkList}
                />
              }
            </li>
            )).sort((a, b) => a.navPosition - b.navPosition)
          }
          
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


shcParentSidebarNavLinks.propTypes = {

};

export default compose(connect(mapStateToProps))(shcParentSidebarNavLinks);
