import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Select from 'react-select';


// CSS
import componentStyles from './styles.css';

// Components
import { Breadcrumb } from 'Components/ShcNavs';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2 } from 'Components/ShcCards';

// Actions
import {  } from 'Actions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(props) {
  }


  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ pad20: true, flex: true, flexDirectionColumn: true, flexAlignSelfCenter: true })}>

          <div className={classNames({ text_s36: true, padT40: true })}>
            Page not found
          </div>

          <div className={classNames({ text_s24: true })}>
            We can't seem to find the page you're looking for.
          </div>

          <div className={classNames({ text_s16: true, padB40: true })}>
            Error code: 404
          </div>

          <div className={classNames({ ddddd: true })}>
            Here are some helpful links instead:
            <ul className={classNames({ eeee: true })}>
              <li className={classNames({ listNone: true })}>
                <Link to="/home" className={classNames({textDecoration: true, color_primary_dark: true })}>
                Home
                </Link>
              </li>
              <li className={classNames({ listNone: true })}>
                <Link to="/contact-us" className={classNames({textDecoration: true, color_primary_dark: true })}>
                Contact
                </Link>
              </li>
              <li className={classNames({ listNone: true })}>
                <Link to="/login" className={classNames({textDecoration: true, color_primary_dark: true })}>
                Login
                </Link>
              </li>
              <li className={classNames({ listNone: true })}>
                <Link to="/sign-up" className={classNames({textDecoration: true, color_primary_dark: true })}>
                Sign-up
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
