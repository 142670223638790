import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectStripe, CardElement } from 'react-stripe-elements';
import classNames from 'classnames';

import AddressSection from './AddressSection';
import UpdateCardForm from './CardSection';

// Material UI

// CSS
import componentStyles from './AddressSection.css';

// Components
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2, InputTextV3, CheckboxOptionCardReduxV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';


// Actions
import { updateBusinessSubscriptionCard, createAdhocChange, createPaymentCharge, reduxDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class BusinessSubscriptionUpdateCreditCardFormV1 extends React.Component {
  constructor(props) {
    super(props);
    this.handleBusinessPaymentCardUpdate = this.handleBusinessPaymentCardUpdate.bind(this);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);

    this.state = {
      businessId: this.props.businessId,
      businessSubscriptionId: this.props.businessSubscriptionId,
      businessName: '',
      firstName: '',
      lastName: '',
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_country: '',
      address_state: '',
      address_zip: '',
      apiTimerCollection: [], // List of active timers. (MANDATORY)

    };
  }
  // https://stripe.com/docs/recipes/elements-react

  onChangeInputTextHandler(receivedUpdateObj) {
    console.log('onChangeInputTextHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /** ************ LOCAL STATE DATA UPDATES ************ */
    // In this version, get the id and update local state only.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      const name = recordsForUpdate[x].name;
      const value = recordsForUpdate[x].value;
      console.log(`${name} : ${value}`);
      this.setState({
        [name]: value,
      });
    }
  }

  async handleBusinessPaymentCardUpdate(evt) {
    // We don't want to let default form submission happen here, which would refresh the page.
    evt.preventDefault();
    console.log('Payment Update Card Handler');

    console.log(JSON.stringify(this.state));

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };


    const businessId = this.state.businessId;
    const businessSubscriptionId = this.state.businessSubscriptionId;

    console.log(`businessId: ${businessId}`);
    console.log(`businessSubscriptionId: ${businessSubscriptionId}`);

    const tokenData = {
      name: `${this.state.firstName} ${this.state.lastName}`,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      address_city: this.state.address_city,
      address_state: this.state.address_country,
      address_zip: this.state.address_state,
      address_country: this.state.address_zip,
      currency: 'aud',

      // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    };
    this.props.stripe.createToken(tokenData)
    .then((result) => {
      console.log("RESULT FROM CREATE TOKEN");
      console.log(result);
      let errors = false;
      let errorObj = null;
      try {
        if (result.error !== undefined) {
          errorObj = result.error;
        }
      } catch (e) {}
      if (errorObj !== null) {
        errors = true;
      }

      if (errors) {
        console.error('ERRORS | CREATE TOKEN');
        console.error(errorObj);

        toast.error(errorObj.message, {
          closeOnClick: true,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          className: 'toastError',
          bodyClassName: 'grow-font-size',
          progressClassName: 'toastErrorProgress',
        });
      } else {
        console.warn('NO ERRORS FOUND');
        let token = null;
        try {
          if (result.token !== undefined) {
            token = result.token;
          }
        } catch (e) {}

        if (token === null) {
          console.error('ERROR READING TOKEN');
        } else {
        // PROCESS THIS RECORD
          console.error('PROCESS THIS RECORD');

          const subscriptionPaymentUpdateObj = {
            // BusinessId
            businessId,
            // Selected Business Subscription Id
            businessSubscriptionId,
            // Stripe Token
            stripeToken: token,
            // Timestamp
            timestamp: Date.now(),

            businessName: this.state.businessName,

            address: {
              name: `${this.state.firstName} ${this.state.lastName}`,
              address_line1: this.state.address_line1,
              address_line2: this.state.address_line2,
              address_city: this.state.address_city,
              address_state: this.state.address_country,
              address_zip: this.state.address_state,
              address_country: this.state.address_zip,
            },

          };

          console.log(subscriptionPaymentUpdateObj);

          //Data is used for populating the API call
          const data = {
            businessId,
          }

          this.props.updateBusinessSubscriptionCard({ data, apiUi, subscriptionPaymentUpdateObj });


        }
      }

    })
    .catch((err) => {
      console.log("err");
      console.log(err);
    });




    /*
    const tokenData = {
      name: `${firstName} ${lastName}`,
      // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    };

    this.props.stripe.createToken(tokenData)
      .then((result) => {
        console.log('response from createToken');
        console.log(result);

        let errors = false;
        let errorObj = null;
        try {
          if (result.error !== undefined) {
            errorObj = result.error;
          }
        } catch (e) {}
        if (errorObj !== null) {
          errors = true;
        }

        if (errors) {
          console.error('ERRORS | CREATE TOKEN');
          console.error(errorObj);

          toast.error(errorObj.message, {
            closeOnClick: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            className: 'toastError',
            bodyClassName: 'grow-font-size',
            progressClassName: 'toastErrorProgress',
          });

        // code: "incomplete_number"
        // message: "Your card number is incomplete."
        // type: "validation_error"
        /*
        code: "incomplete_cvc"
        message: "Your card's security code is incomplete."
        type: "validation_error"

        code: "incomplete_expiry"
        message: "Your card's expiration date is incomplete."
        type: "validation_error"


        code: "incomplete_number"
        message: "Your card number is incomplete."
        type: "validation_error"


        *//*
        } else {
          console.warn('NO ERRORS FOUND');
          let token = null;
          try {
            if (result.token !== undefined) {
              token = result.token;
            }
          } catch (e) {}

          if (token === null) {
            console.error('ERROR READING TOKEN');
          } else {
          // PROCESS THIS RECORD
            console.error('PROCESS THIS RECORD');

            
            const timestamp = Date.now();

            

            const subscriptionPaymentUpdateObj = {
              // BusinessId
              businessId,
              // Selected Plan
              selectedPlan,
              // Stripe Token
              stripeToken: token,
              // User
              userFirebaseUserId: firebaseUserId,
              userUid,
              // Timestamp
              timestamp: Date.now(),

            };

            console.log(subscriptionPaymentUpdateObj);

            //Data is used for populating the API call
            const data = {
              businessId,
            }

            console.log("CALL ACTION FOR UPDATING CREDIT CARD");
            //this.props.updateBusinessSubscriptionCard({ data, apiUi, subscriptionPaymentUpdateObj });


          }
        }


      })
      .catch((err) => {
        console.log('ERROR');
        console.log(err);
      });
    */

  }

  render() {
    const { businessId } = this.props;


    const CheckoutPayNow = () => (
      <div>
        <div className={classNames({
            flex: true,
            flexJustifySpaceBetween: true,
            flexAlignBaseline: true,
           })}
          >
          <div className={classNames({
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}
            >


            <StandardApiBtn
              apiActivityId="handleBusinessPaymentCardUpdate"
              icon="far fa-credit-card"
              iconLocation=""
              text="Update Card"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary shcbtn-fullwidth"
              handleClick={this.handleBusinessPaymentCardUpdate}
            />
          </div>
        </div>
      </div>
    );




    return (
      <form onSubmit={this.handleBusinessPaymentCardUpdate}>
        <div>
          <TextStyle size="h2">Updated Payment Information</TextStyle>
          <br/>

          <InputCardItem headlineText="Business Name" headlineSubText="" additionalText="">
            <InputTextV3
              id="businessName"
              name="businessName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.businessName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="First Name" headlineSubText="Name on Card" additionalText="">
            <InputTextV3
              id="firstName"
              name="firstName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.firstName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Last Name" headlineSubText="Name on Card" additionalText="">
            <InputTextV3
              id="lastName"
              name="lastName"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.lastName}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Address" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_line1"
              name="address_line1"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_line1}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Apartment, suite, etc" headlineSubText="Optional" additionalText="">
            <InputTextV3
              id="address_line2"
              name="address_line2"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_line2}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="City" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_city"
              name="address_city"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_city}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Country" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_country"
              name="address_country"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_country}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="State" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_state"
              name="address_state"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_state}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <InputCardItem headlineText="Postcode" headlineSubText="" additionalText="">
            <InputTextV3
              id="address_zip"
              name="address_zip"
              identifiers={[{ _id: this.state.businessId }]}
              stateIdentifier="BusinessIdentities"
              label=""
              value={this.state.address_zip}
              onChangeInputTextHandler={this.onChangeInputTextHandler}
            />
          </InputCardItem>

          <UpdateCardForm />
          <CheckoutPayNow />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = {
  updateBusinessSubscriptionCard,
  createAdhocChange,
  createPaymentCharge,
  reduxDataUpdate,
};


// export default injectStripe(BusinessSubscriptionUpdateCreditCardFormV1);
export default compose(
  connect(mapStateToProps, mapDispatchToProps), injectStripe,
)(BusinessSubscriptionUpdateCreditCardFormV1);
