
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiIcon, StandardApiBtn  } from 'Components/ShcButtons';
import { InputTextV3, SelectBoxV1 } from 'Components/ShcInput';
import { CardBasic, CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

// Actions
import { addSystemIntegrationToAgent, removeSystemIntegrationFromAgent } from 'Actions';


class AgentConfigCardItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleSystemSelection = this.handleSystemSelection.bind(this);

    this.handleAddNewIntegration = this.handleAddNewIntegration.bind(this);

    this.state = {
      collapse: true,

      businessId: this.props.businessId,

      gpAgentId: this.props.gpAgentId,                    //_id
      gpAgentIdentifier: this.props.gpAgentIdentifier,    //email address

      integratedSystemAgentId: '',
      integratedSystem: '',

    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  handleTextInputChange(receivedUpdateObj) {
    //console.log("handleTextInputChange");
    //console.log(receivedUpdateObj);

    let value = '';
    try {
      value = receivedUpdateObj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }

    this.setState({
      integratedSystemAgentId: value,
    });

  }

  
  handleSystemSelection(selectedSystem) {
    
    console.log("handleSystemSelection");
    console.log(selectedSystem);
    this.setState({
      integratedSystem: selectedSystem,
    });
  }

  handleAddNewIntegration(apiActivityId) {
    const { businessId, integratedSystemAgentId, integratedSystem, gpAgentId } = this.state;
    console.log(`integratedSystemAgentId: ${integratedSystemAgentId}`);
    console.log(`integratedSystem: ${integratedSystem}`);

    const apiUi = { apiActivityId };
    const data = {
      businessId,
      gpAgentId,
      integratedSystem,
      integratedSystemAgentId,
    }


    this.props.addSystemIntegrationToAgent({data, apiUi, });
  }

  handleRemoveIntegration(gpAgentIntegrationId, apiActivityId) {
    
    console.log("handleRemoveIntegration");
    console.log(`gpAgentIntegrationId: ${gpAgentIntegrationId}`);
    console.log(`apiActivityId: ${apiActivityId}`);

    const { businessId, gpAgentId } = this.state;

    const apiUi = { apiActivityId };
    const data = {
      businessId,
      gpAgentIntegrationId,
      gpAgentId,
    }


    this.props.removeSystemIntegrationFromAgent({data, apiUi, });
  }



  

  render() {
    const { gpAgentIdentifier, removeAgentHandler, gpAgentIntegrations, gpAgentId } = this.props;

    let integratedSystems = [
      {
        label: 'AWS Connect',
        value: 'awsConnect',
      },
      /*
      {
        label: 'Genesys',
        value: 'genesys',
      },
      {
        label: 'Avaya',
        value: 'avaya',
      }
      */
    ];
 

    return (
      <div className={classNames({ ShcModulesAgentConfigContainer: true })}>
        <div className={classNames({ ShcModulesAgentConfigContainerHeader: true, 
        flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, })}>
              <div className={classNames({ flexGrow: true })}>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_s16: true })}>{gpAgentIdentifier}</div>
                </div>
              </div>

              <div className={classNames({ flex: true, })}>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                  width30: true, cursorPointer: true })}>
                  {this.state.collapse ?
                    <div><i class="fas fa-angle-down"></i></div>
                  :
                  <div><i class="fas fa-angle-up"></i></div>
                  }
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
   
                  <StandardApiIcon
                    apiActivityId={`removeAgentEmail_${gpAgentIdentifier}`}
                    icon="fas fa-times"
                    classes=""
                    handleClick={this.props.removeAgentHandler.bind(this, gpAgentIdentifier)}
                  />

                    
                </div>
              </div>

            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityStatusContainerBody: true })}>
          

          <div className={classNames({ aaa: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              
              {
              this.props.gpAgentIntegrations && 
              this.props.gpAgentIntegrations.length > 0 ?
                // Arrow function preserves "this"
              this.props.gpAgentIntegrations
              .map((item, i) => {

                let gpAgentIntegrationId = '';
                try {
                  gpAgentIntegrationId = item._id;
                } catch (e) {
                  gpAgentIntegrationId = '';
                }
                if (gpAgentIntegrationId === undefined) {
                  gpAgentIntegrationId = '';
                }

                let agentId = '';
                try {
                  agentId = item.agentId;
                } catch (e) {
                  agentId = '';
                }
                if (agentId === undefined) {
                  agentId = '';
                }

                let system = '';
                try {
                  system = item.system;
                } catch (e) {
                  system = '';
                }
                if (system === undefined) {
                  system = '';
                }

                return (
                  <li key={item.emailAddress} className={classNames({ listNone: true })}>
                    <div className={classNames({ flex: true })}>
                      <div className={classNames({ flex: true, flexAlignSelfCenter: true, minWidth30: true, })}>
                        
                        <StandardApiIcon
                          apiActivityId={`removeAgentIntegration_${agentId}_${system}`}
                          icon="fas fa-times"
                          classes=""
                          handleClick={this.handleRemoveIntegration.bind(this, gpAgentIntegrationId)}
                        />
                        
                      </div>
                      <div className={classNames({ flex: true, minWidth150: true, padR20: true, })}>
                        <InputCardItem headlineText="Agent ID" 
                        headlineSubText="" additionalText="">
                          <span className={classNames({ text_s12: true })}>{agentId}</span>
                        </InputCardItem>
                      </div>
                      <div className={classNames({ flex: true })}>
                        <InputCardItem headlineText="System" 
                        headlineSubText="" additionalText="">
                          <span className={classNames({ text_s12: true })}>{system}</span>
                        </InputCardItem>
                      </div>
                      
                    </div>

                  </li>

                  )
              }, this)
              : (
                <div>

                  <CardBasicV2>
                    No configured integrations
                  </CardBasicV2>

                </div>
              )}

              
            </div>
          </div>

          <div className={classNames({ borderTLight: true, marLRMinus10: true })}/>

          <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
            <div className={classNames({ text_s18: true})}>Add Integration</div>
            <div className={classNames({ text_s12: true})}>Link an agent account 
            between Guard Point and a 3rd party system. </div>
          </div>
          
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true,  })}>
              
              <SelectBoxV1 
              id="selectedSystem"
              data={integratedSystems}
              objectArray={true}
              objectLabel="label"
              labelPrefix=""
              objectValue="value"
              returnAllData={true}
              onSelectInputChange={this.handleSystemSelection}
              onSelectInit={this.handleSystemSelection}
              />

            </div>
            <div className={classNames({ padT10: true, padL10: true, flexGrow: true })}>
              
              <InputTextV3 
              id="" 
              name="" 
              identifiers=""
              stateIdentifier=""
              label='' 
              value={this.state.integratedSystemAgentId}
              onChangeInputTextHandler={this.handleTextInputChange} 
              dataType="text" // email, phone, number, alphaNoSpace, alphaSpace, text, custom
              pattern=""
              feature=""    //AsYouType
              />

            </div>
          </div>
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
  
            </div>
            <div className={classNames({ padT10: true, padL10: true })}>
              
              <StandardApiBtn
              apiActivityId={`addNewSystemIntegration_${gpAgentId}`}
              icon=""
              iconLocation=""
              text="Add New Integration"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-primary"
              handleClick={this.handleAddNewIntegration}
              />


            </div>
          </div>



          
          

        </div>
        }


      </div>

    );
  }
}

AgentConfigCardItem.propTypes = {
  headlineText: PropTypes.string,
  referenceId: PropTypes.string,
};

AgentConfigCardItem.defaultProps = {
  headlineText: '',
  referenceId: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  addSystemIntegrationToAgent, 
  removeSystemIntegrationFromAgent,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AgentConfigCardItem);
