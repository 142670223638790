import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import classNames from 'classnames';

import AddressSection from './AddressSection';
import CheckoutPaymentInformation from './CardSection';

// Material UI
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

// CSS
import componentStyles from './AddressSection.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';

// Actions





class CheckoutForm extends React.Component {
  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({name: 'Jenny Rosen'}).then(({token}) => {
      console.log('Received Stripe token:', token);
    });

    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});
  };


  

  render() {

    


    const CheckoutContactInformation = () => {
      return (
        <div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
          })}>
            <div className={classNames({ heading_h3: true, color_primary_dark: true })}>
              Contact information
            </div>
            <div className={classNames({ text_h6: true, color_primary_medium: true })}>
              Already have an account? Log in
            </div>
          </div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ 
                  shcMaterialTextFieldMarginNarrow: true, 
                  shcMaterialTextField: true,
                  })}
                error={true}
                id="email"
                label="Email"
                margin="normal"
                fullWidth
                helperText="At least 6 characters"
              />
            </div>
          </div>
          <div className={classNames({ text_h6: true, color_primary_medium: true })}>
            <Checkbox
              value="marketing"
            /> Keep me up to date on news and exclusive offers
          </div>
        </div>
      )
    }

    const CheckoutSubscriptionUnderstanding = () => {
      return (
        <div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
            })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              If you choose to sign up for an auto-renewable subscription you 
              understand and authorize that your payment will automatically renew at the end 
              of the subscription period, unless you cancel your subscription 
              before the end of the current subscription period, and give permission 
              for such billing transactions to take place.<br/><br/>
              ref: https://yousician.zendesk.com/hc/en-us/articles/202797992-Payment-terms
            </div>
          </div>
        </div>
      )
    }

    const CheckoutBillingInformation = () => {
      return (
        <div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
          })}>
            <div className={classNames({ heading_h3: true, color_primary_dark: true })}>
              Billing information
            </div>
            <div className={classNames({ text_h6: true, color_primary_medium: true })}>
              &nbsp;
            </div>
          </div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="firstname"
                label="First Name"
                margin="normal"
                fullWidth
                helperText="At least 6 characters"
              />
            </div>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="lastname"
                label="Last Name"
                margin="normal"
                fullWidth
                helperText="At least 6 characters"
              />
            </div>
          </div>

          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="addressLine1"
                label="Address"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="addressLineOther"
                label="Apartment, suite, etc. (optional)"
                margin="normal"
                fullWidth
                helperText="Optional"
              />
            </div>
          </div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="city"
                label="City"
                margin="normal"
                fullWidth
              />
            </div>
          </div>

          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="country"
                label="Country"
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="state"
                label="State/territory"
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <TextField
                className={classNames({ shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
                id="postcode"
                label="Postcode"
                margin="normal"
                fullWidth
              />
            </div>
          </div>

          <div className={classNames({ text_h6: true, color_primary_medium: true })}>
            <CheckoutSubscriptionUnderstanding/>
          </div>
        </div>
      )
    }

    

    const CheckoutPayNow = () => {
      return (
        <div>
          <div className={classNames({ 
            flex: true, 
            flexJustifySpaceBetween: true, 
            flexAlignBaseline: true 
           })}>
            <div className={classNames({ 
              flexMargin5: true,
              fullwidth: true,
              shcMaterialTextFieldBaselinePadding: true,
            })}>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Pay $82.90"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary shcbtn-rounded shcbtn-medium shcbtn-fullwidth"
                handleClick={(evt) => {
                  console.log('clicked sign up button');
                }}
              />
            </div>
          </div>
        </div>
      )
    }

  const CheckoutReturnToCart = () => {
    return (
      <div>
        <div className={classNames({ 
          flex: true, 
          flexJustifySpaceBetween: true, 
          flexAlignBaseline: true 
          })}>
          <div className={classNames({ 
            flexMargin5: true,
            fullwidth: true,
            shcMaterialTextFieldBaselinePadding: true,
          })}>
            <i class="fas fa-angle-left"></i> Return to cart
          </div>
        </div>
      </div>
    )
  }


    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <CheckoutContactInformation />

          <CheckoutBillingInformation/>

          <CheckoutPaymentInformation/>

          <CheckoutPayNow/>

          <CheckoutReturnToCart/>

 
        </div>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);