import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';



// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { NavigationCard, DashCard1, DashCard2, DashCard3, CardBasicV2, 
  GettingStartedCard, 
  InputCard, InputCardItem } from 'Components/ShcCards';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, CheckboxOptionCardReduxV1, InputTextV3 } from 'Components/ShcInput';


import uuid from 'uuid/v1';
import Select from 'react-select';

// Actions
import {  } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);


    this.state = {
      user: this.props.user,    //The main user object
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } else {
      return null;
    }
  }

  async componentDidMount(props) {

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }


  componentWillUnmount() {
    
    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }











  

  render() {
    

 
    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>
          <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
            <div className={classNames({ flex: true })}>
              <div className={classNames({ aaaa: true })}>
                {this.props.title}
              </div>
              <div className={classNames({ text_s12: true, flexAlignSelfCenter: true, padL20: true })}>

              </div>
            </div>
          </div>
          
          <div className={classNames({ flexJustifyFlexStart: true })}>
            <div>
              <InputCard headlineText="Guard Point Terminology" 
              headlineSubText="">
                <div className={classNames({ text_s14: true })}>
                  To help you get the most out of your account, we'll step you through the setup process
                  and explain some of the terminology we use. 
                  <br/><br/>

                  <div className={classNames({ text_s16: true })}>
                    Identities
                  </div>
                  <div className={classNames({ text_s14: true })}>
                    These are your personal identifiers such as your email address, or your phone number. 
                  </div>

                  <br/><br/>

                  <div className={classNames({ text_s16: true })}>
                    Notifications
                  </div>
                  <div className={classNames({ text_s14: true })}>
                    When we refer to 'notifications', these are the methods of how we notify you of a
                    business interacting with you which may be via the Guard Point mobile app. You can have multiple 
                    notifications (e.g. two mobile phone apps) or you may have a family member or vulnerable person that
                    you receive notifications for.  
                  </div>
                          
                </div>

              </InputCard>

            </div>


            <div>
              <GettingStartedCard 
              headlineIcon="far fa-id-badge"
              headline1="Step 1 of 3" 
              headline2="Setup your identity" 
              headlineDetail="First setup an identity such as your mobile number of home phone.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>

                      {this.props.identityVerifiedTotalCount === 0 ? (
                        <div>
                          <div className={classNames({ flex: true, padT20: true })}>
                            <div className={classNames({ minWidth70: true, text_s36: true, colour_warning: true })}>
                              <i class="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className={classNames({ ddd: true })}>
                              
                              <div className={classNames({ colour_warning: true })}>
                              You will need to configure at least 1 verified identity.
                              To use the demo, please configure a mobile phone number.<br/><br/>
                              </div>

          
                              You can configure your identities here: <br/>

                              <Link to="/account/identities" className={classNames({textDecoration: true,})}>
                                <StandardBtn
                                icon=""
                                iconLocation=""
                                text="Configure an identity"
                                tooltip=""
                                action=""
                                classes="shcbtn shcbtn-secondary"
                                //handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                                />
                              </Link>

                              <br/>
                              Currently configured in your account:
                              <ul>
                                <li>{this.props.identityVerifiedMobileCount} mobile identities setup and verified.</li>
                                <li>{this.props.identityVerifiedPhoneCount} phone identities setup and verified.</li>
                              </ul>

                            </div>
                          </div>
                        </div>
                      ):(
                        <div>
                          <div className={classNames({ flex: true, padT20: true })}>
                            <div className={classNames({ minWidth70: true, text_s36: true, colour_success: true })}>
                              <i class="far fa-check-circle"></i>
                            </div>
                            <div className={classNames({ ddd: true })}>
                              <div className={classNames({ text_s14: true })}>

                                <div className={classNames({ colour_success: true })}>
                                  You have a valid identity setup. You can continue to step 2.<br/><br/>
                                </div>

                                Currently configured in your account:
                                <ul>
                                  <li>{this.props.identityVerifiedMobileCount} mobile identities setup and verified.</li>
                                  <li>{this.props.identityVerifiedPhoneCount} phone identities setup and verified.</li>
                                </ul>
                                
                                <br/>

                                <Link to="/account/identities" className={classNames({textDecoration: true,})}>
                                  <StandardBtn
                                  icon=""
                                  iconLocation=""
                                  text="View your identites"
                                  tooltip=""
                                  action=""
                                  classes="shcbtn shcbtn-secondary"
                                  //handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
                <br/>
              </GettingStartedCard>
            </div>

            <div>
              <GettingStartedCard 
              headlineIcon="far fa-bell"
              headline1="Step 2 of 3" 
              headline2="Setup notification method" 
              headlineDetail="This is the Guard Point mobile app.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>

                      {this.props.notificationMethodCount === 0 ? (
                        <div>
                          <div className={classNames({ flex: true, padT20: true })}>
                            <div className={classNames({ minWidth70: true, text_s36: true, colour_warning: true })}>
                              <i class="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className={classNames({ ddd: true })}>
                              <div>

                                <div className={classNames({ colour_warning: true })}>
                                You will need to setup a notification method for Guard Point to contact you.<br/> 
                                This is as simple as downloading and installing the Guard Point mobile app 
                                which will automatically link to this account.<br/><br/>
                                </div>

                                

                                Setting up a notification method:
                                <ul>
                                  <li>Download and install the Guard Point Mobile App. &nbsp;
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">Android</a>
                                    &nbsp;&nbsp;
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=me.guardpoint">Apple iOS</a></li>
                                  
                                  <li>Log into the Guard Point Mobile App with the same account.</li>
                                </ul>

                                Simply logging in via the mobile app will automatically link the Guard Point Mobile App to this account.


                                <Link to="/account/notifications" className={classNames({textDecoration: true,})}>
                                  <StandardBtn
                                  icon=""
                                  iconLocation=""
                                  text="View notification methods"
                                  tooltip=""
                                  action=""
                                  classes="shcbtn shcbtn-secondary"
                                  //handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ):(
                        <div>
                          <div className={classNames({ flex: true, padT20: true })}>
                            <div className={classNames({ minWidth70: true, text_s36: true, colour_success: true })}>
                              <i class="far fa-check-circle"></i>
                            </div>
                            <div className={classNames({ ddd: true })}>
                              <div className={classNames({ text_s14: true })}>

                                <div className={classNames({ colour_success: true })}>
                                  Your notification methods are setup. You can continue to step 3.<br/><br/>
                                </div>
                                

                                Currently configured in your account:
                                <ul>
                                  <li>{this.props.notificationMethodCount} notification methods setup.</li>
                                </ul>
                                
                                <br/>

                                <Link to="/account/notifications" className={classNames({textDecoration: true,})}>
                                  <StandardBtn
                                  icon=""
                                  iconLocation=""
                                  text="View notification methods"
                                  tooltip=""
                                  action=""
                                  classes="shcbtn shcbtn-secondary"
                                  //handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

  
                    </div>
                  </div>
                  
                </div>
              </GettingStartedCard>
            </div>

            <div>
              <GettingStartedCard 
              headlineIcon="fas fa-mobile-alt"
              headline1="Step 3 of 3" 
              headline2="Perform a test" 
              headlineDetail="You can test the Guard Point service on the Demo page.">
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ ddd: true })}>
                    <div className={classNames({ text_s14: true })}>
                      
                      At this point, you should receive Guard Point notifications when a business registered on 
                      Guard Point attempts to contact you on the identity you have configured. 
                      <br/><br/>

                      Once you are comfortable with our platform, we encourage you to setup your other identities
                      to ensure you get notifications and verification options for all business interactions.
                      <br/><br/>

                      Now you can visit our Demo page to perform a test and confirm Guard Point is working correctly.
                      <br/><br/>
       
                      <Link to="/account/demo" className={classNames({textDecoration: true,})}>
                        <StandardBtn
                        icon=""
                        iconLocation=""
                        text="Start Demo"
                        tooltip=""
                        action=""
                        classes="shcbtn shcbtn-secondary"
                        //handleClick={() => this.closeOverlayOnScreen('createBusinessSettings')}
                        />
                      </Link>

                      
                      
                    
                    </div>
                  </div>
                  
                </div>
                
              </GettingStartedCard>
            </div>

            

            <br/><br/>

          </div>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => {
  let identityCount = 0;
  let notificationMethodCount = 0;

  try {
    identityCount = state.User.userIdentities.length;
  } catch (e) {
    identityCount = 0;
  }
  if (identityCount === undefined) {
    identityCount = 0;
  }

  try {
    notificationMethodCount = state.User.userNotifications.length;
  } catch (e) {
    notificationMethodCount = 0;
  }
  if (notificationMethodCount === undefined) {
    notificationMethodCount = 0;
  }


  let identityVerifiedMobileCount = 0;
  let identityVerifiedPhoneCount = 0;
  let identityVerifiedTotalCount = 0;

  let identities = state.User.userIdentities;
  for (let x = 0; x < identities.length; x++) {
    if (identities[x].verificationStatus === true) {
      if (identities[x].type === 'mobile') {
        identityVerifiedMobileCount++;
      }
      if (identities[x].type === 'phone') {
        identityVerifiedPhoneCount++;
      }
    }
  }

  identityVerifiedTotalCount = identityVerifiedMobileCount + identityVerifiedPhoneCount;



  return {
    identityCount,
    notificationMethodCount,
    identityVerifiedMobileCount,
    identityVerifiedPhoneCount,
    identityVerifiedTotalCount,
    user: state.User,
  };

};

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
