
export function apiDataTransferTimer(timerReferenceGroup, dataSet) {
  const apiTimerCollection = this.state.apiTimerCollection;
  console.log('===== apiTimerCollection (Current) ======');
  console.log(JSON.stringify(apiTimerCollection));

  let foundExistingStateField = false;
  let foundExistingStateFieldPosition = null;
  let foundExistingField = false;
  let foundExistingFieldPosition = null;
  // let newTimer=null;

  /*
  Search the apiTimerCollection for the relevant "state" identifer we updating.
  This allows the grouping of API calls for efficency.
  */
  for (let x = 0; x < apiTimerCollection.length; x++) {
    if (_.get(apiTimerCollection[x], 'state') == timerReferenceGroup) { // e.g. state: User or state: Core
      // This is the state object we are updating.
      foundExistingStateField = true;
      foundExistingStateFieldPosition = x;

      console.log('==== STATE: ' + timerReferenceGroup);
      console.log('==== REF (TARGET): ' + dataSet.name);

      // Traverse all the data items.
      for (let y = 0; y < apiTimerCollection[x].data.length; y++) {
        if (_.get(apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
          // This is the item object we are updating.
          foundExistingField = true;
          foundExistingFieldPosition = y;

          console.log('==== REF (MATCH): ' + dataSet.name);

          // Cancel the existing timer.( NEW STATE TIMER )
          console.log('Clear timer for: ' + apiTimerCollection[x].timer);
          clearTimeout(apiTimerCollection[x].timer);

          // Create the new fields
          const newTimerData = {
            reference: dataSet.name,
            dataSet,
          };

          const newStateTimer = setTimeout(() => {
            // Create a new timer with the following goals.
            // When we create this timer, we dont know where were are in the apiTimer array.
            // When this timer runs, we need to know the array location so we can delete this reference after we have updated.

            const timerQuery = new Promise((resolve, reject) => {
              console.log('Promise (initial)');
              let positionX = null;
              let positionY = null;
              let apiDataObj = null;
              for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
                if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
                  positionX = x;
                  apiDataObj = this.state.apiTimerCollection[x];
                  for (let y = 0; y < this.state.apiTimerCollection[x].data.length; y++) {
                    if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
                      positionY = y;
                      // COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
                      console.log('go through all the records and create an object ofr updating the back end');
                    }
                  }

                }
              }
              const resolveDataSet = {
                positionX,
                positionY,
                apiDataObj,
              };
              console.log('resolveDataSet');
              console.log(resolveDataSet);
              resolve(resolveDataSet);
            });

            Promise.all([timerQuery])
              .then((values) => {
                console.log('Promise (API Action)');
                console.log('values');
                console.log(values[0]);
                const apiDataObj = values[0].apiDataObj;

                // CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
                console.log('==== API CALL TO BACKEND FOR DB STORAGE (STATE TIMER) ====');
                console.log('apiDataObj');
                console.log(JSON.stringify(apiDataObj));
                this.props.apiDataUpdate({
                  apiDataObj,
                });
                return values;
              })
              .then((values) => {
                console.log('Promise (clear data)');
                console.log('values');
                console.log(values[0]);
                const positionX = values[0].positionX;
                const positionY = values[0].positionY;
                const apiDataObj = values[0].apiDataObj;

                console.log('==== REMOVE THIS TIMER (position: ' + positionX + ', ' + positionY + ') ====');
                if (positionX !== null && positionY !== null) {
                  const apiTimerCollection = this.state.apiTimerCollection;
                  apiTimerCollection[positionX].data = [];
                  apiTimerCollection[positionX].timer = ''; // When this runs, all of the entries are processed - so reset all the values
                  this.setState({
                    apiTimerCollection,
                  });
                }
                console.log('==== apiTimerCollection (after api call) ====');
                console.log(JSON.stringify(apiTimerCollection));
              });

          }, 4000, timerReferenceGroup, dataSet);

          // Update the state timer of the clone object
          apiTimerCollection[x].timer = newStateTimer;

          // Set the new value for the array position
          apiTimerCollection[x].data[y] = newTimerData;

          // override the current state
          this.setState({
            apiTimerCollection,
          });
          // console.log("updated state with the new timer");
          console.log('================ apiTimerCollection =====================');
          console.log(JSON.stringify(apiTimerCollection));
          return '1';


        }
      }
    }
  }

  // Create a new timer record. Check if the parent was found.
  if (foundExistingField == false) {
    // The field was not found.
    console.log('CREATE NEW TIMER FIELD');


    // Create the new fields
    const newTimerData = {
      reference: dataSet.name,
      dataSet,
    };


    const newStateTimer = setTimeout(() => {
      // Create a new timer with the following goals.
      // When we create this timer, we dont know where were are in the apiTimer array.
      // When this timer runs, we need to know the array location so we can delete this reference after we have updated.

      const timerQuery = new Promise((resolve, reject) => {
        console.log('Promise (initial)');
        let positionX = null;
        let positionY = null;
        let apiDataObj = null;
        for (let x = 0; x < this.state.apiTimerCollection.length; x++) {
          if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
            positionX = x;
            apiDataObj = this.state.apiTimerCollection[x];
            for (let y = 0; y < this.state.apiTimerCollection[x].data.length; y++) {
              if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
                positionY = y;
                // COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
                console.log('go through all the records and create an object ofr updating the back end');
              }
            }

          }
        }
        const resolveDataSet = {
          positionX,
          positionY,
          apiDataObj,
        };
        console.log('resolveDataSet');
        console.log(resolveDataSet);
        resolve(resolveDataSet);
      });

      Promise.all([timerQuery])
        .then((values) => {
          console.log('Promise (API Action)');
          console.log('values');
          console.log(values[0]);
          const apiDataObj = values[0].apiDataObj;

          // CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
          console.log('==== API CALL TO BACKEND FOR DB STORAGE (STATE TIMER) ====');
          console.log('apiDataObj');
          console.log(JSON.stringify(apiDataObj));

          // this.apiDataUpdateAction(apiDataObj);   //This not needed. As the import of this function will work with a prop call directly

          this.props.apiDataUpdate({
            apiDataObj,
          });
          return values;
        })
        .then((values) => {
          console.log('Promise (clear data)');
          console.log('values');
          console.log(values[0]);
          const positionX = values[0].positionX;
          const positionY = values[0].positionY;
          const apiDataObj = values[0].apiDataObj;

          console.log('==== REMOVE THIS TIMER (position: ' + positionX + ', ' + positionY + ') ====');
          if (positionX !== null && positionY !== null) {
            const apiTimerCollection = this.state.apiTimerCollection;
            apiTimerCollection[positionX].data = [];
            apiTimerCollection[positionX].timer = ''; // When this runs, all of the entries are processed - so reset all the values
            this.setState({
              apiTimerCollection,
            });
          }
          console.log('==== apiTimerCollection (after api call) ====');
          console.log(JSON.stringify(apiTimerCollection));
        });






      /*
      let positionX=null;
      let positionY=null;
      let apiDataObj=null;
      for (let x=0; x<this.state.apiTimerCollection.length; x++) {
        if (_.get(this.state.apiTimerCollection[x], 'state') == timerReferenceGroup) {
          positionX = x;
          apiDataObj = this.state.apiTimerCollection[x];

          for (let y=0; y<this.state.apiTimerCollection[x].data.length; y++) {
            if (_.get(this.state.apiTimerCollection[x].data[y], 'reference') == dataSet.name) {
              positionY = y;
              //COLLATE ALL THE VALUES TO BE UPDATED AND SEND AS PART OF A STATE UPDATE
              console.log("go through all the records and create an object ofr updating the back end");
            }
          }

        }
      }
      let action = {
        reference: timerReferenceGroup,
      }
      *//*
      //CALL UPDATE ACTION (//COMMENTED OUT FOR TESTING)
      console.log("==== API CALL TO BACKEND FOR DB STORAGE ( STATE TIMER ..)====");
      console.log("apiDataObj");
      console.log(JSON.stringify(apiDataObj));
      /*this.props.TEST_apiDataUpdate({
        action,
        dataSet
      }); *//*
      console.log("==== REMOVE THIS TIMER (position: "+positionX+", "+positionY+") ====");
      if (positionX!==null && positionY!==null) {
        let apiTimerCollection = this.state.apiTimerCollection;
        //apiTimerCollection[positionX].data = [];
        apiTimerCollection[positionX].timer = "";
        this.setState({
          apiTimerCollection: apiTimerCollection,
        });
      }
      console.log("==== apiTimerCollection (after api call) ====");
      console.log(JSON.stringify(apiTimerCollection));
      */
    }, 4000, timerReferenceGroup, dataSet);

    if (foundExistingStateField == false) {
      // The state field (parent array) was not found. PUSH to array.

      const newParentObj = {
        state: timerReferenceGroup,
        data: [newTimerData], // This is all new, so simply set it.
        timer: newStateTimer, // The primary timer for the state object
      };

      // Insert this clone of state
      apiTimerCollection.push(newParentObj);


    } else {
      // The state field (parent array) was found. UPDATE the array.

      // Cancel the existing timer.( NEW STATE TIMER )
      console.log('Clear timer for: ' + apiTimerCollection[foundExistingStateFieldPosition].timer);
      clearTimeout(apiTimerCollection[foundExistingStateFieldPosition].timer);

      // Insert this clone of state
      apiTimerCollection[foundExistingStateFieldPosition].data.push(newTimerData); // Push the new field
      apiTimerCollection[foundExistingStateFieldPosition].timer = newStateTimer; // Set the new timer
    }

    // override the current state
    this.setState({
      apiTimerCollection,
    });
    // console.log("updated state with the new timer");
    // console.log("new timer has been added.");
    console.log('===== apiTimerCollection (end of call) =======');
    console.log(JSON.stringify(apiTimerCollection));
    return '1';


  }
}

