import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';
import { NotificationManagementPrimaryContentContainerV1, 
  NotificationManagementExpandedContentContainerV1 } from 'Components/ShcModulesNotificationManagement';
  

// Actions
import { reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class RemoteNotificationManagementPanelV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    //Toggle the notification option for this device
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);

    //On Change from input box
    this.onIdentityInputHandler = this.onIdentityInputHandler.bind(this);
    //Input of Verification Code
    this.handleIdentityVerificationCodeSubmit = this.handleIdentityVerificationCodeSubmit.bind(this);
    this.handleIdentityVerificationCodeInput = this.handleIdentityVerificationCodeInput.bind(this);

    //Verify Request Button
    this.handleIdentityVerificationRequest = this.handleIdentityVerificationRequest.bind(this);
    
    this.state = {

      apiTimerCollection: [], // List of active timers.

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,


      identityLabel: this.props.identityLabel,

      identityData: this.props.dataSet,    //All the identity data



      //review
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let collapse = prevState.collapse;  //we want to collapse if we have transitioned to Verified.
    let updateState = false;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }
    
    if (updateState === true) {
      return {
        collapse,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userRemoteNotifications.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }


  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }


  handleGeneralInputChange(identifiers, stateIdentifier, event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = false;
    //console.log(identifiers);
    //console.log(stateIdentifier);

    if (validateAlphaNumericWithSpacesOnly(value)) {
      errors = false;
    } else {
      errors = true;
    }

    if (errors === false) {
      const returnObj = {
        name,
        identifiers,
        stateIdentifier,
        value,
      };
      //console.log('=== onInputChange Return ===');
      //console.log(returnObj);

      this.updateData(returnObj);
      
    }

    //Advise that there is an error with the input and we are not saving it.
  }


  onIdentityInputHandler(event) {
    //console.log("Received change event from identity input");
  }

  removeItem(identityId, evt) {
    //console.log("removeItem");
    //console.log(identityId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }


  handleIdentityVerificationRequest(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationRequest');
    const { identityData } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      data = {
        identity: identityData,
      };
      this.props.userIdentitiesVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }





  handleIdentityVerificationCodeSubmit(evt) {
    evt.preventDefault();
    //console.log('handleIdentityVerificationCodeSubmit');
    const { identityData, verificationCode } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    let data = {};
    if (this.props.apiEndpoint==="User") {
      const data = {
        identity: identityData,
        verificationCode: verificationCode,
      };
      this.props.userIdentitiesCodeVerifyNow({ data, apiUi });
    } else if (this.props.apiEndpoint==="Business") {
      data = {
        identity: identityData,
        verificationCode: verificationCode,
        businessId: this.props.businessId,
      };
      this.props.businessIdentitiesCodeVerifyNow({ data, apiUi });
    } else {
      console.error("IdentityInputV2 | No specified endpoint.");
    }

  }

  handleIdentityVerificationCodeInput(event) {
    //console.log("handleIdentityVerificationCodeInput");
    let errors = false;
    if (validateAlphaNumericNoSpacesOnly(event.target.value)) {
      errors = false;
    } else {
      errors = true;
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeInputErrors: errors,
    });
  }


  
  render() {
    const { identityId, identityLabel, identityInputValue, identityLocked, dataSet, index, apiEndpoint, businessId,
    stateIdentifier, identifiers, id, name, onRemoveItem, challenge } = this.props;

    //Extract the data
    let remoteUserId = '';
    try {
      remoteUserId = dataSet.remoteUserId;
    } catch(e) {}
    let appGeneratedId="";
    try {
      appGeneratedId = dataSet.appGeneratedId;
    } catch(e) {}
    let deviceIdentityId="";
    try {
      deviceIdentityId = dataSet.identityId;
    } catch(e) {}
    let appDeviceInfo = '';
    try {
      appDeviceInfo = JSON.parse(dataSet.appDeviceInfo);
    } catch(e) {}

    let appDeviceBrand = '';
    try {
      appDeviceBrand = appDeviceInfo.brand;
    } catch(e) {}
    let appDeviceSystemName = '';
    try {
      appDeviceSystemName = appDeviceInfo.systemName;
    } catch(e) {}









    //Set the Icon
    let identityTypeDisplayIcon = <div><i className={`fas fa-mobile-alt`}></i></div>;

    //Set the headline & subheadline
    let headlineText="";
    let headlineSubText="";
    let headlineAdditionalText="";
    let additionalText="";
    headlineText = "SELBY STEER";
    headlineSubText = "selby.steer@gmail.com";
    headlineAdditionalText = `${identityLabel}`;
  
    let troubleshootingText="";
    troubleshootingText=`${identityId}`;

    //////////////////////////////////////////////////////////////////////////////////

    try {
      identity = dataSet.identity;
    } catch(e) {}

 


    return (
      <div className={classNames({ ShcModulesIdentityManagementViewContainerHeader: true, marB30: !this.state.collapse })}>
        
        
        <NotificationManagementPrimaryContentContainerV1
        verificationStatus=""
        verificationActionRequired={false}
        div1="iconSection" div2="headerSection" div3="bodySection">
            <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
              <div className={classNames({ flexGrow: true })}>
                {identityTypeDisplayIcon}
              </div>
              <div>
                  <div></div>
              </div>
            </div>
            
            <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h3: true, text_color_grey_light: true })}> {headlineText} </div>
              <div className={classNames({ text_h5: true, text_color_grey_light: true })}> 
                <StandardApiIcon
                  apiActivityId={`RemoveUserNotification_${identityId}`}
                  icon="fas fa-times"
                  classes=""
                  handleClick={this.removeItem.bind(this, identityId)}
                />
              </div>
            </div>
            
            <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ text_h5: true, text_color_grey_soft: true })}> {headlineSubText} </div>

             
              <div>
                <div className={classNames({
                  flex: true,
                  flexJustifySpaceBetween: true,
                  flexAlignBaseline: true,
                  ShcIdentityInput_textInput_root: true,
                })}
                >
                  <div className={classNames({
                    ShcIdentityInput_textInput_child: true,
                    ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
                    ShcIdentityInput_textInput_notificationsEnabled_icon: 
                    this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
                    ShcIdentityInput_textInput_notificationsDisabled_icon: 
                    this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
                  })}
                  >
                    {this.state.identityNotificationOptionsAvailable &&
                      <div className={classNames({ 
                        ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
                      })}>
                        <div className={classNames({ 
                          ShcIdentityInput_textInput_notificationsClickOverlay: true,
                        })}
                        onClick={this.toggleIdentityNotification}>

                        </div>
                      </div>
                    }
                    
                    <div className={classNames({ ShcIdentityInput_textNoInput: true, 
                      ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                      ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
                      
                      {this.state.identityNotificationEnabled === true ? (
                        <div>
                          Notifications: On
                        </div>
                      ):(
                        <div>
                          Notifications: Off
                        </div>
                      )}
                    
                    </div>
                    
                  </div>
                </div>
                  


                  <div className={classNames({ flex: true })}>

                    <div className={classNames({ flex: true, width100p: true })}>
                      <div className={classNames({text_h6: true, text_color_grey_soft: true, flexGrow: true,})}>
                        {additionalText}
                      </div>
                      <div className={classNames({text_h6: true, text_color_grey_soft: true})}>
                        {troubleshootingText}
                      </div>
                    </div>
                    
                  </div>



              </div>


              <div>
                <div className={classNames({ flex: true })}>
                  {this.state.collapse ?
                    <StandardBtn
                      icon="fas fa-angle-down"
                      iconLocation="right"
                      text="EXPAND"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-small shcbtn-primary"
                      handleClick={this.collapseContainer}
                    />
                  :
                    <StandardBtn
                      icon="fas fa-angle-up"
                      iconLocation="right"
                      text="COLLAPSE"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-small shcbtn-primary"
                      handleClick={this.collapseContainer}
                    />
                  } 
                </div>
              </div>


            </div>
          </NotificationManagementPrimaryContentContainerV1>

        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityManagementViewContainerBody: false })}>
          <div className={classNames({ flex: false})}>

            
            
            <NotificationManagementExpandedContentContainerV1
            div1="iconSection" div2="headerSection" div3="bodySection">
              <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <i class="fas fa-heartbeat"></i>
                </div>
                <div className={classNames({ aaa: true })}>
                  
                </div>
              </div>
              
              <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ text_h3: true })}>REMOTE USER</div>
                <div className={classNames({ text_h5: true })}> <i class="fas fa-info-circle"></i> </div>
              </div>
              
              <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                            

                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true, })}>
                    <i class="far fa-user"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                    User ID: 
                  </div>
                  <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                    &nbsp;
                    {remoteUserId}
                  </div>
                </div>

                

              </div>
            </NotificationManagementExpandedContentContainerV1>


            <NotificationManagementExpandedContentContainerV1
            div1="iconSection" div2="headerSection" div3="bodySection">
              <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <i class="fas fa-heartbeat"></i>
                </div>
                <div className={classNames({ aaa: true })}>
                  
                </div>
              </div>
              
              <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ text_h3: true })}>MY DEVICE</div>
                <div className={classNames({ text_h5: true })}> <i class="fas fa-info-circle"></i> </div>
              </div>
              
              <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>

                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true, })}>
                    <i class="fas fa-info-circle"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                    Business interactions destined for you will alert on this 
                    device via a push notification. 
                  </div>
                </div>  

                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true, })}>
                    <i class="fas fa-mobile-alt"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                    Device:
                  </div>
                  <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                    &nbsp;
                      {`${appDeviceBrand} | ${appDeviceSystemName}`}
                  </div>
                </div>       

                <div id="FlexGroup" className={classNames({ flex: true })}>
                  <div className={classNames({ text_h5: true, })}>
                    <i class="fas fa-mobile-alt"></i>
                  </div>
                  <div className={classNames({ text_h5: true, padL5: true, padL10: true, })}>
                    Device ID:
                  </div>
                  <div className={classNames({ text_h5: true, fontWeightBold: true, })}>
                    &nbsp;
                    {deviceIdentityId}
                  </div>
                </div>

    

              </div>
            </NotificationManagementExpandedContentContainerV1>

            <NotificationManagementExpandedContentContainerV1
            div1="iconSection" div2="headerSection" div3="bodySection">
              <div div1="iconSection" className={classNames({ width30: true, minWidth30: true,  flex: true, flexColumn: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <i class="fas fa-tag"></i>
                </div>
              </div>
              
              <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ text_h3: true })}>LABEL</div>
                <div className={classNames({ text_h5: true })}> <i class="fas fa-info-circle"></i> </div>
              </div>
              
              <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
                <div className={classNames({ text_h6: true })}>
                Add a label for this identity
                </div>



                <div>
                  <hr/>
                  <InputCardItem
                  id={['notificationLabelInputContainer', identityId].join('_')}
                  key={['notificationLabelInputContainer', identityId].join('_')}

                    headlineText="Label"
                    headlineSubText=""
                    additionalText=""
                    troubleshootingText=""
                  >
                    <div className={classNames({ padL15: false })}>

                      <div>
                        <div className={classNames({
                          flex: true,
                          flexJustifySpaceBetween: true,
                          flexAlignBaseline: true,
                          ShcIdentityInput_textInput_root: true,
                        })}
                        >
                          <div className={classNames({
                            ShcIdentityInput_textInput_child: true,
                            ShcIdentityInput_textInput_errors_icon: this.state.verificationCodeInputErrors,
                          })}
                          >
                            <input
                              id={['notificationLabelInputField', identityId].join('_')}
                              key={['notificationLabelInputField', identityId].join('_')}
                              margin="normal"
                              fullWidth
                              name="userNotifications.$.identityLabel"
                              value={this.state.identityLabel}
                              identifiers={identifiers}
                              stateIdentifier={stateIdentifier}
                              onChange={this.handleGeneralInputChange.bind(this, identifiers, stateIdentifier)}
                              type="text"
                              className={classNames({ ShcIdentityInput_textInput: true, ShcIdentityInput_textInput_errors: this.state.verificationCodeInputErrors })}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.verificationCodeInputErrors && 
                        <div className={classNames({ flex: true, flexAlignItemsCenter: true })}>
                          <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                          <div>
                            <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                              aria-label="Invalid Input"
                            ></i>
                          </div>
                        </div>
                      }


                    </div>
                  </InputCardItem>
                </div>
              </div>

            </NotificationManagementExpandedContentContainerV1>
          </div>
        </div>
        }
        
      </div>

    );
  }
}

RemoteNotificationManagementPanelV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  //console.log("ownProps.identityId");
  //console.log(ownProps.identityId);

  let businessId = '';
  try {
    businessId = ownProps.match.params.routebusinessid;
  } catch (e) {
  }

  let selectedTabOption = null;
  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === `notificationDevicesTabGroup_${ownProps.identityId}`);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  let userId = '';
  try {
    userId = state.User.id;
  } catch (e) {}


  return {
    userId,
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RemoteNotificationManagementPanelV1);
