import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Material UI
import Grid from '@material-ui/core/Grid';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { NavigationCard, DashCard1, DashCard2, DashCard3 } from 'Components/ShcCards';

// Actions
//import {  } from 'Actions';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,    //The main user object

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== undefined && nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } else {
      return null;
    }
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }


  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>

          <div className={classNames({ Dashboard_primaryHeader: true })}>
            Account Dashboard
          </div>

          <div className={classNames({ flexJustifyFlexStart: true })}>
           
            <div class="shcGrid290">
              <div class="shcGridItem">
                <Link to="/account" className={classNames({ textDecoration: true })}>
                  <DashCard3
                    height="100%"
                    headlineSubText="User Account"
                    headlineSubIcon="fas fa-user-cog"
                    headlineText="View Account"
                    secondaryText="Manage your identities, notifications and account."
                    additionalText=""
                    linkText=""
                    linkPath=""
                    />
                </Link>
              </div>

              <div class="shcGridItem">
                <Link to="/account/business" className={classNames({ textDecoration: true })}>
                  <DashCard3
                    height="100%"
                    headlineSubText="Business Accounts"
                    headlineSubIcon="fas fa-building"
                    headlineText="Business Account"
                    secondaryText="Operate a business where you want to securely connect with customers?"
                    additionalText=""
                    linkText=""
                    linkPath=""
                    />
                </Link>
              </div>
              
              
            </div>

          </div>

          

          <hr className={classNames({ Dashboard_horizontalDivide: true,})}/>


          <div className={classNames({ Dashboard_primaryHeader: true, Dashboard_primaryHeader_marginTop: true })}>
            Setting Up Guard Point
          </div>

          <div className={classNames({ flexJustifyFlexStart: true })}>
           
            <div class="shcGrid290">
              <div class="shcGridItem">
                <Link to="/account/getting-started" className={classNames({ textDecoration: true })}>
                  <DashCard3
                    height="100%"
                    headlineSubText="Account Setup"
                    headlineSubIcon="fas fa-user-cog"
                    headlineText="Setting up Your Account"
                    secondaryText="Step by step setup of your account."
                    additionalText=""
                    linkText=""
                    linkPath=""
                    />
                </Link>
              </div>

              {this.props.verifiedIdentities === 0 && (
                <div class="shcGridItem">
                  <Link to="/account/identities" className={classNames({ textDecoration: true })}>
                    <DashCard3
                      height="100%"
                      headlineSubText="Setup your Account"
                      headlineSubIcon="fas fa-user-cog"
                      headlineText="Create an Identity"
                      secondaryText="Verified identities allow you to receive a notification before a verified business contacts you."
                      additionalText=""
                      linkText=""
                      linkPath=""
                      />
                  </Link>
                </div>
              )}

              {this.props.verifiedIdentitiesMobile === 0 && (
                <Link to="/account/identities" className={classNames({ textDecoration: true })}>
                  <DashCard3
                    height="100%"
                    headlineSubText="Setup your Account"
                    headlineSubIcon="fas fa-user-cog"
                    headlineText="Create a Mobile Identity"
                    secondaryText="Mobile numbers are the most common contact method for businesses. Setup your mobile identity to ensure you are notified of business interactions."
                    additionalText=""
                    linkText=""
                    linkPath=""
                    />
                </Link>
              )}
                
              {this.props.mobileAppInstalls === 0 ? (
                <div class="shcGridItem">
                  <Link to="/account/mobile-app" className={classNames({ textDecoration: true })}>
                    <DashCard3
                      height="100%"
                      headlineSubText="Setup your Account"
                      headlineSubIcon="fas fa-user-cog"
                      headlineText="Install the Guard Point App"
                      secondaryText="Notifications are delivered by our Guard Point App. This is available on Android and IOS"
                      additionalText=""
                      linkText=""
                      linkPath=""
                      />
                  </Link>
                </div>
              ): null
              }
              
            
              <div class="shcGridItem">
                <Link to="/account/demo" className={classNames({ textDecoration: true })}>
                  <DashCard3
                    height="100%"
                    headlineSubText="See How it Works"
                    headlineSubIcon="far fa-play-circle"
                    headlineText="Guard Point Demo"
                    secondaryText="Not sure how Guard Point works? See it in action with a real-time demo."
                    additionalText=""
                    linkText=""
                    linkPath=""
                    />
                </Link>
              </div>


             
              
              
            </div>

          </div>

          

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};


const mapStateToProps = (state, ownProps) => { 
  let userIdentities = [];
  try {
    userIdentities = state.User.userIdentities;
  } catch (e) {
    userIdentities = [];
  }
  if (userIdentities === undefined) {
    userIdentities = [];
  }
  let verifiedIdentities = 0;
  let verifiedIdentitiesEmail = 0;
  let verifiedIdentitiesMobile = 0;
  let verifiedIdentitiesPhone = 0;
  for (let x = 0; x < userIdentities.length; x++) {
    //Count the verified identities
    if (userIdentities[x].verificationStatus === true) {
      verifiedIdentities++;
    }
    //Count the email identities
    if (userIdentities[x].type === 'email' && userIdentities[x].verificationStatus === true) {
      verifiedIdentitiesEmail++;
    }
    //Count the mobile identities
    if (userIdentities[x].type === 'mobile' && userIdentities[x].verificationStatus === true) {
      verifiedIdentitiesMobile++;
    }
    //Count the phone identities
    if (userIdentities[x].type === 'phone' && userIdentities[x].verificationStatus === true) {
      verifiedIdentitiesPhone++;
    }
  }

  let userNotifications = [];
  try {
    userNotifications = state.User.userNotifications;
  } catch (e) {
    userNotifications = [];
  }
  if (userNotifications === undefined) {
    userNotifications = [];
  }

  let mobileAppInstalls = 0;
  try {
    mobileAppInstalls = userNotifications.length;
  } catch (e) {
    mobileAppInstalls = 0;
  }
  if (mobileAppInstalls === undefined) {
    mobileAppInstalls = 0;
  }


  return {
    user: state.User,
    userIdentities: Object.assign([], userIdentities),
    verifiedIdentities: verifiedIdentities,
    verifiedIdentitiesEmail: verifiedIdentitiesEmail,
    verifiedIdentitiesMobile: verifiedIdentitiesMobile,
    verifiedIdentitiesPhone: verifiedIdentitiesPhone,
    mobileAppInstalls: mobileAppInstalls, 

  };

};

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);


/* WHEN WE HAD INTERACTION VIEW ONLINE
<div className={classNames({ flexJustifyFlexStart: true })}>
  <Grid
    container
    spacing={24}
    justify="flex-start"
    className={classNames({ flexGridAutoRows1fr: true })}
  >

    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to="/account/interactions/active" style={{ textDecoration: 'none' }}>
        <DashCard2
          height="100%"
          headlineSubText="Interactions"
          headlineSubIcon="fas fa-volume-up"
          headlineText="View your Interactions"
          secondaryText=""
          additionalText=""
          linkText=""
          linkPath=""
          />
      </Link>
    </Grid>
    
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to="/verify" style={{ textDecoration: 'none' }}>
        <DashCard2
          height="100%"
          headlineSubText="Interactions"
          headlineSubIcon="fas fa-check-double"
          headlineText="Verify an Interaction"
          secondaryText="Verify a business interaction"
          additionalText=""
          linkText=""
          linkPath=""
          />
      </Link>
    </Grid>
    

  </Grid>
</div>
*/