import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');

import Dropdown from 'react-dropdown';

var countryTelData = require('country-telephone-data');

import { AsYouType, parsePhoneNumberFromString, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3, SelectBoxV1 } from 'Components/ShcInput';
import { LocationInputInternationalFormatV1 } from 'Components/ShcModulesIdentityManagement';

// Actions
import { reduxDataUpdate, apiDataUpdate, updateUserIdentities, updateBusinessIdentities } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType, timeNow } from 'Helpers/Functions';

class IM_postal_inputV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);

    this.handleCountryDialingCodeSelection = this.handleCountryDialingCodeSelection.bind(this);

    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);


    this.state = {
      apiTimerCollection: [], // List of active timers.

      postageCountry: '',

      countryDialingCodes: [],  
      countryCodeSelection: '',

      validPhoneNumber: '',
      validPhoneNumberCountry: '',
      validPhoneNumberNumber: '',
      validPhoneNumberType: '',
      validPhoneNumberInternational: '',

      latestNumberInput: {},    //Allows for the replay of the text input on country code change. InputTextV3 Object






      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }





  handleCountryDialingCodeSelection(countryCodeSelection) {
    console.log(`handleCountryDialingCodeSelection: ${countryCodeSelection}`);
    this.setState({
      countryCodeSelection: countryCodeSelection,
    }, () => {
      //Replay the latest input changes to see if one selection of
      //a country code the input becomes valid.
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    
    

    //console.log("nextProps.dataSavingReference");
    //console.log(nextProps);
    //console.log(nextProps.dataSavingReference);

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
    
    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {
    let errors = false;
    
    let postageCountry = '';
    try {
      postageCountry = this.props.dataSet.postageAdditionalData.country;
    } catch (e) {}

    if (postageCountry === undefined) {
      postageCountry = '';
    }

    let identityLocked = false;
    try {
      identityLocked = this.props.dataSet.identityLocked;
    } catch (e) {}

    if (identityLocked === undefined) {
      identityLocked = false;
    }

    let identityInputValue = this.props.dataSet.identityInputValue;
    if (identityLocked === true) {
      let singleLineAddress = '';

      let attention = '';
      try {
        attention = this.props.dataSet.postageAdditionalData.attention;
      } catch (e) {}
      let suiteUnitApartment = '';
      try {
        suiteUnitApartment = this.props.dataSet.postageAdditionalData.suiteUnitApartment;
      } catch (e) {}
      let addressLine1 = '';
      try {
        addressLine1 = this.props.dataSet.postageAdditionalData.addressLine1;
      } catch (e) {}
      let addressLine2 = '';
      try {
        addressLine2 = this.props.dataSet.postageAdditionalData.addressLine2;
      } catch (e) {}
      let suburb = '';
      try {
        suburb = this.props.dataSet.postageAdditionalData.suburb;
      } catch (e) {}
      let state = '';
      try {
        state = this.props.dataSet.postageAdditionalData.state;
      } catch (e) {}
      let postcode = '';
      try {
        postcode = this.props.dataSet.postageAdditionalData.postcode;
      } catch (e) {}
      let country = '';
      try {
        country = this.props.dataSet.postageAdditionalData.country;
      } catch (e) {}

   

      singleLineAddress = `ATTN: ${attention}. ${suiteUnitApartment} ${addressLine1} ${addressLine2}, ${suburb}, ${state} ${postcode} ${country}`;



      identityInputValue = singleLineAddress;
    }





    //reValidationDueDate

    let reValidationDueDate = null;
    try {
      reValidationDueDate = this.props.dataSet.reValidationDueDate;
    } catch(e) {}
    if (reValidationDueDate === undefined) {
      reValidationDueDate = null;
    }
    try {
      reValidationDueDate = parseInt(reValidationDueDate);
    } catch (e) {}
    console.log("====== reValidationDueDate ======");
    console.log(reValidationDueDate);


    let timeNowObj = timeNow();
    let dateTimeNowSec = timeNowObj.dateTimestampSec;

    
    console.log("====== dateTimeNowSec ======");
    console.log(dateTimeNowSec);

    let verificationStatus = this.props.verificationStatus;
    if (dateTimeNowSec > reValidationDueDate) {
      console.log("THIS IDENTITY HAS EXPIRED");
      verificationStatus = false;
    } else {
      console.log("THIS IDENTITY IS STILL VALID FROM A TIME PERSPECTIVE");
    }



    this.setState({
      identityInputErrors: errors,
      verificationStatus,
      
      identityInputValue,
      postageCountry,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }
  

  handleLocationInputChange = address => {
    //console.log("Handle change from CHILD component (LocationSearchInputGoogleAutoComplete)")
    this.setState({ identityInputValue: address });

    let returnObj = {
      identifiers: this.props.identifiers,
      name: this.props.name,
      stateIdentifier: this.props.stateIdentifier,
      value: address,
    }
    //console.log(returnObj);

    this.updateData(returnObj);

  };


  handleSelectInputChange(evt) {
    //console.log("handleSelectInputChange");
    //console.log(evt);
    let value = evt.value;

    this.setState({postageCountry: value});
    let receivedUpdateObj = {};
    if (this.props.componentTargetUser === 'Business') {
      receivedUpdateObj = {
        name: 'identities.businesses.$.businessIdentities.$.postageAdditionalData.country',
        identifiers: [{_id: this.props.businessId}, {_id: this.props.identityId}],
        value: value,
        stateIdentifier: "BusinessIdentities",
      }

    } else if (this.props.componentTargetUser === 'User') {
      receivedUpdateObj = {
        name: 'userIdentities.$.postageAdditionalData.country',
        identifiers: [{_id: this.props.identityId}],
        value: value,
        stateIdentifier: "User",
      }

    }

    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /////////this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    //This is a catch as the Postal Address form may need multiple field inputs.
    this.props.onChangeHandler(evt);    //Advise the identity management screen that a change happened.
  
    
  }


  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  
  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");
    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {

      let returnObj = {
        identifiers: this.props.identifiers,
        name: "userIdentities.$.notifyThisDevice",
        stateIdentifier: this.props.stateIdentifier,
        value: this.state.identityNotificationEnabled,
      }
      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }




  render() {
    const { 
      identityInputValidationStatus,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,
      identityInputValue,
      icon, children 
    } = this.props;

    let { 
      identityMask,
    } = this.props;

    let businessIdentity = false;
    if (businessId === '' || businessId === undefined) {
      businessIdentity = false;
    } else {
      businessIdentity = true;
    }

    //This is used for transition of existing identities
    if (identityMask === '' || identityMask === undefined || identityMask === null) {
      identityMask = identityInputValue;
    }

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    
  
    const options = [
      { value: 'australia', label: 'Australia' },
      { value: 'other', label: 'Other Country' },
    ];

    let postalCountry = '';
    try {
      postalCountry = dataSet.postageAdditionalData.country;
    } catch (e) {}


    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: identityLocked === true ? false : this.state.identityInputErrors,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ ShcIdentityInput_textNoInput: true, 
              ShcIdentityInput_textInput_errors: false,
              })}>
              {businessIdentity === true ? (
                this.state.identityInputValue
              ):(
                identityMask
              )}
            </div>
            :
            <div className={classNames({ })}>
              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true, })}>
                  <Dropdown 
                  options={options} 
                  onChange={this.handleSelectInputChange} 
                  value={options.find(option => option.value === postalCountry)}
                  placeholder="Select an option" />
                </div>
              </div>
              <div className={classNames({ })}>
                <div className={classNames({ flexGrow: true })}>
                  <LocationInputInternationalFormatV1
                  componentTargetUser={this.props.componentTargetUser}
                  countryFormLayout={this.state.postageCountry}
                  onChange={this.handleLocationInputChange}

                  identityId={identityId}
                  identityInputValue={identityInputValue}
                  identityType={identityType} 
                  identityLocked={identityLocked} 
                  verificationStatus={verificationStatus}
                  notifyThisDevice={notifyThisDevice}
                  dataSet={dataSet}
                  apiEndpoint={apiEndpoint}
                  businessId={businessId}
                  stateIdentifier={stateIdentifier}
                  identifiers={identifiers}
                  identityInputValidationStatus={identityInputValidationStatus}
                  //this.props.identityInputValidationStatus(true);
                  />
                </div>
              </div>
            </div>
            }
            
          </div>
        </div>
          
  
        <div className={classNames({ flex: true, minHeight25: true })}>

          {this.state.savingData && 
              <div className={classNames({ flex: true })}>
                <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                <div>
                  <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                  aria-label="Saving"
                  ></i>
                </div>
              </div>
          }


        </div>
        {/* 
        <div className={classNames({ flex: true })}>
          <div className={classNames({eee: true})}>
            {this.state.validPhoneNumber}
          </div>
          <div className={classNames({eee: true})}>
            {this.state.validPhoneNumberCountry}
          </div>
          <div className={classNames({eee: true})}>
            {this.state.validPhoneNumberNumber}
          </div>
          <div className={classNames({eee: true})}>
            {this.state.validPhoneNumberType}
          </div>
          <div className={classNames({eee: true})}>
            {this.state.validPhoneNumberInternational}
          </div>
        </div>
        */}


        {this.state.validPhoneNumber === true && (
          <div className={classNames({ flex: true, padT10: true })}>
            <div>
              <i className={`fas fa-check-circle ${classNames({colour_success: true, padR10: true})}`}
                aria-label=""
              ></i>
            </div>
            <div className={classNames({ ttt: true })}>
              <div className={classNames({colour_success: true})}>
                Phone number appears to be valid.
              </div>
              <div className={classNames({ flex: true })}>
                <div className={classNames({colour_success: true, padR10: true})}>
                  {this.state.validPhoneNumberCountry}
                </div>
                <div className={classNames({colour_success: true, padR10: true})}>
                  {this.state.validPhoneNumberInternational}
                </div>
                <div className={classNames({colour_success: true, padR10: true})}>
                  {this.state.validPhoneNumberType}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.validPhoneNumber === false && (
          <div className={classNames({ flex: true, padT10: true })}>
            <div>
              <i className={`fas fa-exclamation-triangle ${classNames({colour_danger: true, padR10: true})}`}
                aria-label=""
              ></i>
            </div>
            <div className={classNames({ ttt: true })}>
              <div className={classNames({colour_danger: true})}>
                Phone number appears to be incorrect.
              </div>
              <div className={classNames({ flex: true })}>
                
              </div>
            </div>
          </div>
        )}
        




      </div>

    );
  }
}




IM_postal_inputV1.defaultProps = {
  identityLocked: false,
};


IM_postal_inputV1.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  /***************************************
   * Saving data. We only track the last 
   * change tranaction id. 
   **************************************/
  //console.log("ownProps");
  //console.log(ownProps);
  /*
  let savingData = ownProps.savingData;
  let dataSavingReference = '';
  const identityReference = ownProps.lastChangeTransactionId;
  //console.log(`identityReference we are looking for: ${identityReference}`);
  try {
    dataSavingReference = state.Sync.dataRecordUpdates.find(data => {
      if (data.apiTransactionId === identityReference) {
        return data;
      }
    });
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  */
  /*
  try {
    dataSavingReference = state.Sync.dataRecordUpdates.find(data => {
      if (data.reference === identityReference && JSON.stringify(data.identifiers) === JSON.stringify(ownProps.identifiers)) {
        return data;
      }
    });
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  */

  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }
  /*  
  if (dataSavingReference !== '' && dataSavingReference !== undefined) {

    //console.log("mapStateToProps | dataSavingReference");
    //console.log(dataSavingReference);
    //console.log(dataSavingReference.apiCompleted);
    //console.log(JSON.stringify(dataSavingReference));
    if (dataSavingReference.apiCompleted === true) {
      savingData = false;
    } else {
      savingDataError = true;
    }
  }
  */

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  updateUserIdentities,
  updateBusinessIdentities,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IM_postal_inputV1);
