import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { get } from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

import Moment from 'react-moment';
//import 'moment-timezone';

import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { InfoCopyLabelPhone, MessageHighlight } from 'Components/ShcText';
import { CardBasicV2 } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { InputText } from 'Components/ShcInput';

// Actions
import { generateBusinessConnectTokenPhone, toggleOverlayOnScreenResponsive, } from 'Actions';


class ConnectManuallyCardPhone extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleGenerateCommunicationsToken = this.handleGenerateCommunicationsToken.bind(this);
    this.handleExpiredToken = this.handleExpiredToken.bind(this);

    this.viewCustomerConnectHistory = this.viewCustomerConnectHistory.bind(this);
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);

    this.viewCustomerConnectWhatHappens = this.viewCustomerConnectWhatHappens.bind(this);
    
    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      linkTextLeft: props.linkTextLeft,
      linkTextRight: props.linkTextRight,
      inputTextTitle1: props.inputTextTitle1,
      cardType: props.cardType,

      activePhoneToken: props.activePhoneToken,

      businessId: props.businessId,
      businessProfileId: props.businessProfileId,
      tokenObj: props.tokenObj,
      tokenObjHistory: props.tokenObjHistory,

      textInputNumber: '',
      inValidEntry: '',

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.identities && nextProps.identities.businesses.length > 0) {
      console.log('getDerivedStateFromProps');
      console.log(nextProps.businessId);
      console.log(prevState.businessId);
      console.log("businessProfileId");
      console.log(nextProps.businessProfileId);
      console.log(prevState.businessProfileId);


      const business = nextProps.identities.businesses.find(aBusiness => aBusiness._id === nextProps.businessId);
      
      let phoneTokenActive = get(business, 'businessConnect.phoneTokenActive') !== undefined ? get(business, 'businessConnect.phoneTokenActive') : {};
      let phoneTokens = get(business, 'businessConnect.phoneTokens') !== undefined ? get(business, 'businessConnect.phoneTokens') : [];
      if (phoneTokenActive !== prevState.tokenObj) {
        return {
          tokenObj: phoneTokenActive,
          tokenObjHistory: phoneTokens,
        };
      }
    }
    return null;
  }


  componentDidMount(props) {
    //console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate.');
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }
  viewCustomerConnectHistory = () => {
    let tableData=[];
    let tableItem;
    console.log("this.state.tokenObjHistory");
    console.log(this.state.tokenObj);
    console.log(this.state.tokenObjHistory);

    if (this.state.tokenObjHistory.length > 0) {

      console.log("viewCustomerConnectHistory");
      console.log(this.state.tokenObjHistory);

      var timezone = new Date().getTimezoneOffset();
      console.log(timezone);
      
      this.state.tokenObjHistory.map((item, i) => {
        console.log(item.tokenId);
        let transactionTime = new Date(item.timestampReqTransaction);
        let expirytime = new Date(item.tokenExpiry);
        //console.log(d);
        //console.log(transactionTime.toLocaleString());
        //d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
        //console.log(d);
        //console.log(d.toLocaleString());
        let tableItem = {
          time: transactionTime.toLocaleString(),
          code: item.tokenId,
          expirytime: expirytime.toLocaleString(),
        };
        tableData.push(tableItem);
      });
      
      console.log(JSON.stringify(tableData));

    } else {
      tableData="";
    }
    const data = {
      tableData: tableData,
    };

    this.props.toggleOverlayOnScreenResponsive('open', 'customerConnectPhoneViewHistory', data);
  }

  viewCustomerConnectWhatHappens = () => {
    const data = {};

    this.props.toggleOverlayOnScreenResponsive('open', 'customerConnectPhoneWhatHappens', data);
  }

  onChangeInputTextHandler = (event) => {
    if (this.state.inValidEntry!=='') {
      this.setState({
        [event.target.name]: event.target.value,
        inValidEntry: '',
      });
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  handleGenerateCommunicationsToken(event) {
    event.preventDefault();
    console.log('Generating a communication token');


    console.log(this.state.textInputNumber);
    let textInput = this.state.textInputNumber;

    console.log("textInput: "+textInput);

    if (textInput!='' && textInput.length>5) {
      console.log("textInput passed basic criteria");
      const phoneNumber = parsePhoneNumberFromString(textInput);

      if (phoneNumber) {
        if (phoneNumber.isValid() === true) {
          console.log("valid phone number");

          let phoneInternationalFormat = textInput.replace(/\s/g, '');  //Replace spaces: e.g. +61 4 9999 1111 > +61499991111
          console.log(phoneInternationalFormat);
          const apiAccessToken = {
            id: this.props.apiAccessToken,
          };

          const userDetails = {
            contactIdentity: phoneInternationalFormat,
            contactIdentityType: "phone",       //The data type for contactIdentity
          };

          const businessDetails = {
            businessId: this.state.businessId,
            businessProfileId: this.state.businessProfileId,
            //UserId is recorded from the Access Token used
          };

          const contactDetails = {
            contactTargetTime: "now",         //When the business will make contact
            contactTargetMethod: "phone",     //What type of medium the business will use
          };


          if (this.state.cardType == 'phone') {
            console.log('phone token');
            console.log('user');
            console.log(userDetails);
            console.log(businessDetails);
            this.props.generateBusinessConnectTokenPhone({
              contactDetails, businessDetails, userDetails, apiAccessToken,
            });
          } else if (this.state.cardType == 'email') {
            console.log('email token');
            this.props.generateBusinessConnectTokenEmail({
              contactDetails, businessDetails, userDetails, apiAccessToken,
            });
          }

        } else {
          console.log("invalid phone number");
          this.setState({inValidEntry: "Invalid phone number."});
        }

        console.log(phoneNumber.country);
        console.log(phoneNumber.number);
        console.log(phoneNumber.isValid());
        console.log(phoneNumber.getType());
        
      }
 
    } else {
      console.log("invalid phone number");
        this.setState({inValidEntry: "Invalid phone number."});
    }  
  }

  handleExpiredToken() {
    console.log('Token has expired - remove/grey out token - they may be in the middle of talking to the person');
  }




  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, 
      linkTextLeft, linkTextRight, inputTextTitle1, customTextProfile,
    } = this.state;

    let customTextInstructions = '';
    if (customTextProfile == 'instructionsEmail') {
      customTextInstructions = 'Instructions for Email';
    } else if (customTextProfile == 'instructionsPhone') {
      customTextInstructions = 'Instructions for Phone';
    }

    const CustomerConnectHistoryTable =() => {
      if (this.props.overlayOnScreenResponsiveData !== '') {
        const {tableData} = this.props.overlayOnScreenResponsiveData;
        console.log("CustomerConnectHistoryTable");
        console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Time",
                        accessor: "time"
                      },
                      {
                        Header: "Token",
                        accessor: "code"
                      },
                      {
                        Header: "Token Expiry Time",
                        accessor: "expirytime"
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return "No data.";
        }
        return "No data.";
      }
      return null;
    }

    return (
      <div className={classNames({ pageWrapper: false })}>
        <CardBasicV2 className={classNames({ shcCardConnectManuallyCard_container: true })}>
          <div>
            <div className={classNames({ shcCardConnectManuallyCard_headlineSubTextPanel: true })}>
              <div className={classNames({ shcCardConnectManuallyCard_headlineText: true })}>
                {headlineText}
              </div>
              <div className={classNames({ shcCardConnectManuallyCard_headlineSubRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>

            <div className={classNames({ shcCardConnectManuallyCard_headlineSubLeft: true })} component="h2">
              {headlineSubText}
            </div>

            <div className={classNames({ shcCardConnectManuallyCard_headlineSubLeft: true })} component="h2">

              <div className={classNames({ flex: true })}>
                <div className={classNames({ flexGrow: true })}>
                  <InputText id="textInputNumber" name="textInputNumber" label={inputTextTitle1} feature="AsYouType" value={this.state.textInputNumber} onChangeInputTextHandler={this.onChangeInputTextHandler} />
                </div>
              </div>
              <div>
                {this.state.inValidEntry != '' &&
                (
                  <MessageHighlight type="danger" key={this.state.inValidEntry}>
                    {this.state.inValidEntry}
                  </MessageHighlight>
                )
                }
              </div>
            </div>

            <div>
              <StandardBtn
                icon=""
                iconLocation=""
                text="Start Customer Interaction"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary shcbtn-rounded"
                handleClick={this.handleGenerateCommunicationsToken}
              />
            </div>

            <div>
              <br />
            </div>

            {this.state.tokenObj != '' && this.state.tokenObj != {} &&
            (
              <div>
                <InfoCopyLabelPhone subHeading="Token" token={this.state.tokenObj}>
                  {this.state.tokenObj.tokenId}
                </InfoCopyLabelPhone>
              </div>
            )
            }


            <div />


            <div className={classNames({ shcCardConnectManuallyCard_additionalText: true })} component="p">
              {customTextInstructions}
            </div>

            <div className={classNames({ shcCardConnectManuallyCard_secondaryText: true })}>
              { additionalText ? null : <br /> }
              {secondaryText}
            </div>
            <div className={classNames({ shcCardConnectManuallyCard_additionalText: true })} component="p">
              {additionalText}
            </div>
          </div>
          <div className={classNames({ shcCardConnectManuallyCard_buttonContainer: true })}>

            <StandardBtn
              icon=""
              iconLocation=""
              text="What happens?"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
              handleClick={this.viewCustomerConnectWhatHappens}
            />

            <StandardBtn
              icon=""
              iconLocation=""
              text={linkTextLeft}
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
            />

            <StandardBtn
              icon=""
              iconLocation=""
              text={linkTextRight}
              tooltip=""
              action=""
              classes="shcbtn shcbtn-default-primary"
              handleClick={this.viewCustomerConnectHistory}
            />


          </div>
        </CardBasicV2>




        <OverlayOnScreenResponsive
          id="customerConnectPhoneViewHistory"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="View Customer Connect Phone History"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Customer Connect History</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              Note: These are only the tokens you have generated in this session.

              <CustomerConnectHistoryTable/>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('customerConnectPhoneViewHistory')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="View business history"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={() => this.handleAddPermissionConfirmed(this.props.overlayOnScreenResponsiveData.id)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="customerConnectPhoneWhatHappens"
          type="default"
          title="Customer Connect - Phone - What happens?"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">How it works - Phone Codes</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h4">
                After typing in a contact number, we will attempt to contact the customer and advise them
                of your identity; that you are making contact via phone and provide them with the one-time-use code as a record of this interaction. 
              </TextStyle>
              <br/>

              <TextStyle size="h3">What do I do?</TextStyle>
              <TextStyle size="h4">
                <ol>
                  <li>Once you are ready to contact a customer, simply type in their phone number and press
                      the button "Start Customer Interaction".</li>
                  <li>We will send them your identity and a unique code for this interaction.</li>
                  <li>There is a time limit for how long the code is available before it expires (visible once you start the interaction).</li>
                  <li>You can then make contact with the customer and supply them the code to prove your identity.</li>
                  <li>The customer can verify the code via the Auth Channel app or via the AuthChannel.com.au website.</li>
                </ol>
              </TextStyle>
              <br/>
            </div>

            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('customerConnectPhoneViewHistory')}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        

      </div>

    );
  }
}
// initialTime={60000 * 60 * 48 + 5000}

ConnectManuallyCardPhone.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  linkTextLeft: PropTypes.string,
  linkTextRight: PropTypes.string,
  inputTextTitle1: PropTypes.string,
  cardType: PropTypes.string,
};

ConnectManuallyCardPhone.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
  secondaryText: '',
  additionalText: '',
  linkTextLeft: '',
  linkTextRight: '',
  inputTextTitle1: '',
  cardType: '',
  activePhoneToken: '',
};


const mapStateToProps = state => ({
  //activePhoneToken: state.BusinessConnect.ActivePhoneToken,
  apiAccessToken: state.Auth.apiAccessToken,
  identities: state.BusinessIdentities.identities,

  overlayOnScreenResponsive: state.CommonActions.overlayOnScreenResponsive,
  overlayOnScreenResponsiveData: state.CommonActions.overlayOnScreenResponsiveData,
});

const mapDispatchToProps = {
  generateBusinessConnectTokenPhone,
  toggleOverlayOnScreenResponsive,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ConnectManuallyCardPhone);

/*
<Button
  size="small"
  className={classNames({ shcCardConnectManuallyCard_button: true })}
>{linkText}
</Button>
*/
