import React from 'react';
import { CardElement } from 'react-stripe-elements';
import classNames from 'classnames';

// Material UI

// CSS
import componentStyles from './CardSection.css';

// Components

// Actions

class CardSection extends React.Component {
  render() {
    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          })}
        >
          <div className={classNames({
            flexMargin5: true,
            fullwidth: true,
            shcMaterialTextFieldBaselinePadding: true,
          })}
          >
            <CardElement
              hidePostalCode
              style={{
              base: {
                color: '#32325d',
                fontFamily: '"Poppins", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: '#000d11',
                },
              },
              invalid: {
                color: '#aaa',
                iconColor: '#aaa',
              },
            }}
              className={classNames({ StripeElement: true, shcMaterialTextFieldMarginNarrow: true, shcMaterialTextField: true })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CardSection;
