import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class CardBasicV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      padding, height, margin,
      onActiveStyle, onActive,
    } = this.props;

    let onActivePrimary = false;
    let onActiveDanger = false;
    if (onActive === true) {
      if (onActiveStyle === 'primary') {
        onActivePrimary = true;
      }
      if (onActiveStyle === 'danger') {
        onActiveDanger = true;
      }
    }


    return (
      <div
        className={classNames({
        ShcCardBasicV2Container: padding !== 'none' ? true : false,
        ShcCardBasicV2Container_slim: padding === 'none' ? true : false,
        pad20: padding !== 'none',
        mar10: margin !== 'none',
        ShcCardBasicV2ContainerHighlight_primary: onActivePrimary === true,
        ShcCardBasicV2ContainerHighlight_danger: onActiveDanger === true,
      })}
      >
        {this.props.children}
      </div>
    );
  }
}

CardBasicV2.propTypes = {

};
CardBasicV2.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardBasicV2);
