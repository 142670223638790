import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import fetchProgress from 'fetch-progress';
import axios from 'axios';
// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { PlainTextTable } from 'Components/ShcTables';


// Actions

// Functions
import {
} from 'Helpers/Functions';

const uuidv1 = require('uuid/v1');


class S3UploaderV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.createTargetFilename = this.createTargetFilename.bind(this);
    this.createTargetFileTags = this.createTargetFileTags.bind(this);

    /*
    this.onUploadStart = this.onUploadStart.bind(this);
    this.onSignedUrl = this.onSignedUrl.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.uploadInput = this.uploadInput.bind(this);
    this.cleanFilename = this.cleanFilename.bind(this);
    */
    
    this.state = {
      errors: false,
      started: false,
      inProgress: false,
      success: false,
      failure: false,

      uploadedFileName: '',
      savedFileName: '',


      progress: '',

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    

  }
  
  createTargetFilename() {
    //format: <YYYYMMDD-HHMMSS>-<businessId>-<businessName>
    var d = new Date();
    var year = d.getUTCFullYear();
    var month = (d.getUTCMonth() + 1);
    month = ("0" + month.toString()).slice(-2);
    var day = d.getUTCDate();
    day = ("0" + day.toString()).slice(-2);
    var hour = d.getUTCHours();
    hour = ("0" + hour.toString()).slice(-2);
    var minute = d.getUTCMinutes();
    minute = ("0" + minute.toString()).slice(-2);
    var second = d.getUTCSeconds();
    second = ("0" + second.toString()).slice(-2);

    let dateNowString = `${year}${month}${day}-${hour}${minute}${second}`;
    let businessId = this.props.businessId;
    businessId = businessId.replace(/[^a-zA-Z0-9]/g, '');

    let businessName = this.props.businessName;
    businessName = businessName.replace(/[^a-zA-Z0-9-\.]/g, '');

    let businessCountry = this.props.businessCountry;
    businessCountry = businessCountry.replace(/[^a-zA-Z0-9]/g, '');

    //filename = filename.replace(/[^\w\d_\-.]+/ig, '');


    let newFileName = `${dateNowString}-${businessCountry}-${businessId}-${businessName}-${this.props.type}`;

    return newFileName;
  }

  createTargetFileTags() {
    let businessId = this.props.businessId;
    businessId = businessId.replace(/[^a-zA-Z0-9]/g, '');
    let businessName = this.props.businessName;
    businessName = businessName.replace(/[^a-zA-Z0-9 -\.]/g, '');
    let businessCountry = this.props.businessCountry;
    businessCountry = businessCountry.replace(/[^a-zA-Z0-9]/g, '');

    let fileTag = `type=${this.props.type}&country=${businessCountry}&businessId=${businessId}&businessName=${businessName}`;

    return fileTag;
  }

  
  async uploadFileToS3(event) {
    this.setState({started: true, inProgress: true, success: false, failure: false});

    let exitUpload = false;

    ///// GET FILES
    var files = event.target.files;
    var objectName = files[0].name;
    var contentType = files[0].type;
    console.log("objectName");
    console.log(objectName);
    console.log("contentType");
    console.log(contentType);

    ///// CREATE TARGET FILENAME
    let targetFilename = this.createTargetFilename();

    this.setState({
      uploadedFileName: objectName, 
      savedFileName: targetFilename, 
    });

    

    ///// CREATE OBJECT TAGGING 
    let filenameTagging = this.createTargetFileTags();

    //let targetFilename = "SELBY-TEST-FILE";
    //let filenameTagging = `businessId=111&registry=ASIC&registryID=1823183012313&registryType=ACN`;
    

    let signedURL = '';
    
    let promise_getSignedURL = new Promise((resolve, reject) => {     
      fetch(`${this.props.s3SigningUrl}?objectName=${targetFilename}&contentType=${contentType}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((json) => {
        console.log(json);
        signedURL = json.data.awsSignedURL;
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        resolve(false);
      })
      
    });
    
    let results_getSignedURL = await promise_getSignedURL;

    if (results_getSignedURL === true) {
      console.log("success");
    } else {
      console.log("failure");
      this.setState({started: true, inProgress: false, success: false, failure: true});
      return false;
    }

    if (signedURL === undefined || signedURL === '' || signedURL === null) {
      exitUpload = true;
      this.setState({started: true, inProgress: false, success: false, failure: true});
      return false;
    }



    console.log("signedURL");
    console.log(signedURL);


    let promise_uploadFile = new Promise((resolve, reject) => {
      fetch(signedURL, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        Bucket: this.props.bucketName,
        Key: targetFilename,
        Expires: 60,
        ContentType: contentType,
        body: files[0],
        Tagging: filenameTagging
      })
      .then((response) => {
        console.log(response);
        return response.body;
      })
      .then((body) => {
        console.log(body);
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        resolve(false);
      });

    });

    
    let results_uploadFile = await promise_uploadFile;

    if (results_uploadFile === true) {
      this.setState({started: true, inProgress: false, success: true, failure: false});
      this.props.onSave({
        uploaderId: this.props.uploaderId,
        bucketName: this.props.bucketName,
        filename: targetFilename,
      });
    } else {
      this.setState({started: true, inProgress: false, success: false, failure: true});
    }


  }



  render() {
    const { uploaderId, buttonText, type, bucketName, s3SigningUrl, onSave,
    } = this.props;


    return (
      <div>
        <div>
          <label for={uploaderId} class="custom-file-upload">
            <div className={classNames({ flex: true, })}>
              <div className={classNames({ flex: true, })}>
                <div>
                  <i class="fas fa-cloud-upload-alt"></i> &nbsp;
                </div>
                <div>
                  {buttonText} &nbsp;
                </div>
              </div>
              <div>
                {this.state.inProgress === true && (
                  <i class="fas fa-circle-notch fa-spin" />
                )}
                {this.state.success === true && (
                  <i class="fas fa-check-circle"></i>
                )}
                {this.state.failure === true && (
                  <i class="fas fa-times-circle"></i>
                )}

              </div>
            </div>
              
          </label>
          <input name="s3FileUploader" id={uploaderId} type="file" onChange={this.uploadFileToS3.bind(this)}/>
        </div>
        <div>
          <div>
            <PlainTextTable
            title="Uploaded Filename:"
            value={this.state.uploadedFileName} />
          </div>
          <div>
            <PlainTextTable
            title="Saved Filename:"
            value={this.state.savedFileName} />
          </div>

        </div>
      </div>
    );
  }
}

S3UploaderV1.propTypes = {

};
S3UploaderV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
 
  return {
    accessToken: state.Auth.apiAccessToken,
  };

};

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(S3UploaderV1);

