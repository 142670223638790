import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import NavCard from 'Components/Cards/navCard';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextareaV1, IdentityInputV1, IdentityInputV2, CheckboxOptionCardReduxV1, 
  RadioOptionCardV1, CheckboxOptionCardV2, LinkCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';

// Actions
import {  } from 'Actions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(props) {
  }



  render() {


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>
          <div>
            <div>
              <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
            </div>
          </div>

          <div className={classNames({ maxWidth800: true })}>
            <InputCard headlineText="How to sign up as a business" headlineSubText="">
              Simply sign up as a user. Once verified, you can setup your business account. 
              

            </InputCard>
          </div>

        </div>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
