import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class AddressInputFormV1_Australia extends React.Component {
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this);

    this.state = { 
      apiTimerCollection : [],  //List of active timers. (MANDATORY)



      country: this.props.country,
      singleLineAddress: '',
      attention: '',
      companyName: '',
      suiteUnitApt: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
      

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
  
  onChangeInputTextHandler(receivedUpdateObj) {
    console.log("receivedUpdateObj");
    console.log(receivedUpdateObj);
    
    let name = '';
    try {
      name = receivedUpdateObj.name;
    } catch (e) {}
    let value = '';
    try {
      value = receivedUpdateObj.value;
    } catch (e) {}
    
    if (name !== '' && name !== undefined && value !== undefined) {
      this.setState({
        [name]: value,
      });
      this.props.onInputChange({[name]: value});
    } else {

    }

    
    

  }
  

 
  render() {

    const { businessId, identityId, onInputChange, } = this.props;

 
    return (
      <div>

        <InputCardItem headlineText="Attention" headlineSubText="" additionalText="">
          <InputTextV3 
            id="attention" 
            name="attention" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Company Name" headlineSubText="" additionalText="">
          <InputTextV3 
            id="companyName" 
            name="companyName" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Suite/Unit/Apartment" headlineSubText="" additionalText="">
          <InputTextV3 
            id="suiteUnitApartment" 
            name="suiteUnitApartment" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>


        <InputCardItem headlineText="Address Line 1" headlineSubText="" additionalText="">
          <InputTextV3 
            id="addressLine1" 
            name="addressLine1" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Address Line 2" headlineSubText="" additionalText="">
          <InputTextV3 
            id="addressLine2" 
            name="addressLine2" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>


        <InputCardItem headlineText="Suburb" headlineSubText="" additionalText="">
          <InputTextV3 
            id="suburb" 
            name="suburb" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>

        <div className={classNames({ flex: true})}>

          <InputCardItem headlineText="State" headlineSubText="" additionalText=""
          className={classNames({ padR5: true})}>
            <InputTextV3 
              id="state" 
              name="state" 
              identifiers=""
              stateIdentifier=""
              label='' 
              value=''
              onChangeInputTextHandler={this.onChangeInputTextHandler} 
            />
          </InputCardItem>

          <InputCardItem headlineText="Postcode" headlineSubText="" additionalText=""
          className={classNames({ padL5: true})}>
            <InputTextV3 
              id="postcode" 
              name="postcode" 
              identifiers=""
              stateIdentifier=""
              label='' 
              value=''
              onChangeInputTextHandler={this.onChangeInputTextHandler} 
            />
          </InputCardItem>
          
        </div>

        <InputCardItem headlineText="Country" headlineSubText="" additionalText="">
          <InputTextV3 
            id="country" 
            name="country" 
            identifiers=""
            stateIdentifier=""
            label='' 
            value=''
            onChangeInputTextHandler={this.onChangeInputTextHandler} 
          />
        </InputCardItem>


      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddressInputFormV1_Australia);
