import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import { } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class ToggleOptionCardV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      toggleState: this.props.toggleState,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // //console.log('getDerivedStateFromProps - ToggleOptionCardV1');

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');
    //console.log(this.state.toggleState);

    this.setState(prevState => ({
      toggleState: !prevState.toggleState,
    }));


    // //console.log('Checkbox Selection');
    // //console.log(selection);
    // this.props.999999.(selection);

  }

  render() {
    const {
      businessId,
      toggleText,
      toggleState,
    } = this.props;

    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ aaa: true })}>
          {this.state.toggleState ?
            <StandardIcon
              icon="fas fa-toggle-on"
              classes="colour_primary"
              handleClick={this.toggleSelectedItem}
            />
          :
            <StandardIcon
              icon="fas fa-toggle-off"
              classes=""
              handleClick={this.toggleSelectedItem}
            />
          }
        </div>
        <div className={classNames({ padL10: true, flex: true, flexAlignItemsCenter: true })}>
          { toggleText ?
            <div>
              <div className={classNames({ ShcCheckboxOptionCard_toggleText: true })}>
                {toggleText}
              </div>
            </div>
          : null }
        </div>
      </div>
    );
  }
}

ToggleOptionCardV1.propTypes = {

};
ToggleOptionCardV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(ToggleOptionCardV1);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(ToggleOptionCardV1);
