import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing


// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setUiTabOptions } from 'Actions';



function TabContainer({ children }) {
  return (
    <div>
    { children }
    </div>
  );
}

class TabContainerV3 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      businessId: this.props.businessId,
    };
  }

  handleChange = (child, value, event) => {
    //console.log("tab handleChange");
    //console.log(child);
    //console.log(value);
    //console.log(event);

    let tabOptionsId = child.props.tabOptionsId;

    //tabOptionsId needs to come from the child element

    const selection = {
      businessId: this.props.businessId,
      tabOptionsGroup: this.props.tabOptionsGroup,
      tabOptionsId: tabOptionsId,
      tabSelectedState: true,
    };
    //console.log('Tab Selection');
    //console.log(selection);
    this.props.setUiTabOptions(selection); // Redux management across multiple components




    this.setState({ value });
  };
  handleChangeIndex = index => {
    //console.log("tab handleChangeIndex");
    this.setState({ value: index });
  };

  render() {
    const { businessId, tabOptionsGroup, classes } = this.props;

    if (this.props.selectedTabOption.selectedTabOptionsId === undefined || 
      this.props.selectedTabOption.selectedTabOptionsId === '' || 
      this.props.selectedTabOption.selectedTabOptionsId === null) {
        console.log("COMPONENT: Tab | selectedTabOptionsId is NULL");
        //call action to set default to security tab for this item.
        //look for business id, and then tha tabOptionGroup
        //If not there, insert a new record.
        //identityOptionsTabGroup_5f0ebff6e7662200083212c3
    }

    return (
      <div className={classNames({ width100p: true })}>
        <div className={classNames({ flex: true })}>
          { this.props.children &&
            this.props.children.map((child, i) => {
              //console.log('========= TABS ==========');
              //console.log(child);
              //console.log(i);

              //console.log(child.props.tabOptionsId);
              //console.log(this.props.selectedTabOption);
              //console.log(this.props.selectedTabOption.selectedTabOptionsId);
              //i === this.state.value

              if (child.props.tabOptionsId === this.props.selectedTabOption.selectedTabOptionsId) {
                return (
                  <div onClick={this.handleChange.bind(this, child, i)} 
                  className={classNames({ ShcModulesIdentityManagementExpandTabbedOption: i !== this.state.value,
                    ShcModulesIdentityManagementExpandTabbedOptionSelected: true,
                    flex: true, flexGrow: true,
                    })}>
                    {child}
                  </div>
                )
              } else {
                return (
                  <div onClick={this.handleChange.bind(this, child, i)} 
                  className={classNames({ ShcModulesIdentityManagementExpandTabbedOption: i !== this.state.value,
                    ShcModulesIdentityManagementExpandTabbedOptionSelected: false,
                    flex: true, flexGrow: true, })}>
                    {child}
                  </div>
                )
              }
              
              
            })
          }
        </div>
        
      </div>
    );
  }
}

TabContainerV3.propTypes = {

};
TabContainerV3.defaultProps = {
};


const mapStateToProps = (state, ownProps) => { 

  let businessId = '';
  try {
    businessId = ownProps.businessId;
  } catch (e) {
  }

  let selectedTabOption = null;

  try {
    //console.log(`Tab - Searching for Business ID: ${businessId}`)
  }catch (e) {
  }


  try {
    selectedTabOption = state.UI.uiTabOptions.find(tabOption => tabOption.businessId === businessId 
      && tabOption.tabOptionsGroup === ownProps.tabOptionsGroup);
  } catch (e) {
    console.error('mapStateToProps | Unable to find the tab options');
  }

  return {
    selectedTabOption: Object.assign([], selectedTabOption),
    
  };
};

const mapDispatchToProps = {
  setUiTabOptions,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TabContainerV3);
