import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

// Routing

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setuserlogout } from 'Actions';

// Notes


class MenuAdmin extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  onClickLogout = () => {
    //console.log('onClickLogout');
    this.props.setuserlogout();
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classNames({ ShcMenuItem_Button: true, })}
        >
          {this.props.children}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>Admin</MenuItem>
          <MenuItem onClick={this.handleClose}>My Admin</MenuItem>
          <MenuItem onClick={this.onClickLogout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

MenuAdmin.propTypes = {
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

const mapDispatchToProps = {
  setuserlogout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MenuAdmin);