import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import ReCAPTCHA from "react-google-recaptcha";


import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';

import update from 'immutability-helper';

const ct = require('countries-and-timezones');


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextareaV1, IdentityInputV1, IdentityInputV2, CheckboxOptionCardReduxV1, RadioOptionCardV1, LinkCardV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';


import Select from 'react-select'

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { recordContactUsInteraction, } from 'Actions';


// Functions
import { validateEmail, apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)

    this.handleSelectInputChange_contactUsType = this.handleSelectInputChange_contactUsType.bind(this);
    this.handleSelectInputChange_contactUsCategory = this.handleSelectInputChange_contactUsCategory.bind(this);

    this.setRequesterName = this.setRequesterName.bind(this);
    this.setRequesterEmail = this.setRequesterEmail.bind(this);

    this.messageSubject = this.messageSubject.bind(this);
    this.messageDescription = this.messageDescription.bind(this);
    
    this.logContactUs = this.logContactUs.bind(this);

    //RECAPTCHA
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
    this.onReCaptchaExpired = this.onReCaptchaExpired.bind(this);
    this.onReCaptchaErrored = this.onReCaptchaErrored.bind(this);




    this.state = { 
      captchaStatus: false,

      contactUsLogged: false,   
      
      //CUSTOMER DETAILS
      customerIsAuth: this.props.isAuthenticated,        //Whether a logged in user or not

      //CUSTOMER AUTHED
      customerLoggedInId: this.props.userId,             //This will be available on the case lodgement JWT (which is where we will verify it from)
      customerLoggedInFirstName: this.props.userFirstName,

      //CUSTOMER NOT AUTHED
      contactUs_customerContactName: '',
      contactUs_customerContactEmail: '',

      //FEEDBACK INPUT
      contactUs_type: '',
      contactUs_category: '',
      contactUsMessageSubject: '',
      contactUsMessageDescription: '',

      contactUsInputErrors: false,
      contactUsInputErrorList: [],


      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState=false;

    if (nextProps.user !== prevState.user) {
      updateState=true;
    }

    if (updateState===true) {
      
      return {
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    this.setState({
      captchaStatus: false,
    });



  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate");
    //console.log("forceUpdate()");
    this.forceUpdate();
   
  }

  onReCaptchaChange(value) {
    //console.log("Captcha value:", value);
    this.setState({
      captchaStatus: true,
    });
  }
  onReCaptchaExpired() {
    this.setState({
      captchaStatus: false,
    });
  }
  onReCaptchaErrored() {
    this.setState({
      captchaStatus: false,
    });
  }
 

  handleSelectInputChange_contactUsType(evt) {
    let value = evt.value;
    this.setState({
      contactUs_type: value,
    });
  }

  handleSelectInputChange_contactUsCategory(evt) {
    let value = evt.value;
    this.setState({
      contactUs_category: value,
    });
  }


  setRequesterName(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      contactUs_customerContactName: value,
    });
  }

  setRequesterEmail(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      contactUs_customerContactEmail: value,
    });
  }


  messageSubject(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      contactUsMessageSubject: value,
    });
  }

  messageDescription(obj) {
    let value = '';
    try {
      value = obj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }
    this.setState({
      contactUsMessageDescription: value,
    });
  }






  logContactUs(evt) {
    //Need to cancel the apiActivityId via redux if the form fails out.
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    
    console.log("==== log contactUs =====");
    let errors = [];
    let allowContactUsLodgement = true;


    if (this.state.captchaStatus === true) {

      let customerIsAuth = this.state.customerIsAuth;
      let contactUs_customerContactName = this.state.contactUs_customerContactName;
      let contactUs_customerContactEmail = this.state.contactUs_customerContactEmail;
      if (customerIsAuth === false) {
        //Ensure we have some contact details. 
        if (contactUs_customerContactName.length <= 1) {
          errors.push({
            message: 'Please enter a valid customer name',
          });
          allowContactUsLodgement = false;
        }
        if (contactUs_customerContactEmail.length <= 3) {
          errors.push({
            message: 'Please enter a valid email address. Too short.',
          });
          allowContactUsLodgement = false;
        } else {
          if (validateEmail(contactUs_customerContactEmail) === false) {
            errors.push({
              message: 'Please enter a valid email address',
            });
            allowContactUsLodgement = false;
          }
        }
      }
    
      if (this.state.contactUsMessageSubject === "") {
        errors.push({
          message: 'Please provide a message subject',
        });
        allowContactUsLodgement = false;
      }
      if (this.state.contactUsMessageDescription === "") {
        errors.push({
          message: 'Please provide a message description',
        });
        allowContactUsLodgement = false;
      }

      if (allowContactUsLodgement === true) {
        this.setState({
          contactUsInputErrors: false,
          contactUsInputErrorList: [],
        });
        //Call Action
        this.props.recordContactUsInteraction({
          apiUi,
          messageSubject: this.state.contactUsMessageSubject,
          messageDescription: this.state.contactUsMessageDescription,

          //customerIsAuth: this.state.customerIsAuth,
          //customerLoggedInId: this.state.customerLoggedInId,
          //customerLoggedInFirstName: this.state.customerLoggedInFirstName,
    
          contactName: this.state.contactUs_customerContactName,
          contactEmail: this.state.contactUs_customerContactEmail,
          contactPhone: '',   //Not yet implemented
        });



      } else {
        this.setState({
          contactUsInputErrors: true,
          contactUsInputErrorList: errors,
        });
      }
    } else {
      errors.push({
        message: 'Please complete Captcha Security.',
      });
      this.setState({
        contactUsInputErrors: true,
        contactUsInputErrorList: errors,
      });
    }



  }




















  render() {

    const { businessId } = this.state;
    const { userBusinessRoutePermissions, businessIdentities, businessAccountPostClosure, businessAccountClosed } = this.props;


    //////////////////////////////////////////
    // ACCOUNT & BILLING

    let contactUs_type_Classification_Options = [
      {value: 'improvement', label: 'Improvement'},
      {value: 'opportunity', label: 'Opportunity'},
      {value: 'general', label: 'General'},
    ]

    let contactUs_category_Classification_Options = [
      {value: 'website', label: 'Website'},
      {value: 'mobileApp', label: 'Mobile App'},
      {value: 'process', label: 'Process'},
      {value: 'other', label: 'Other'},
    ];






    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/support"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business/:routebusinessid"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle=""
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Support Centre"
                linkGroupSelectionTF="true"
                linkGroupSelection="support"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            <div>
            
              <InputCard headlineText="Requester details" headlineSubText="">
                {this.props.isAuthenticated === true ? (
                  <div>
                    <InputCardItem headlineText="Requester" headlineSubText="" additionalText="">
                      {this.state.customerLoggedInFirstName} (ID: {this.state.customerLoggedInId})
                    </InputCardItem>
                  </div>
                ):(
                  <div>
                    <InputCardItem headlineText="Name" headlineSubText="" additionalText="">
                      <InputTextV3
                        id="name" 
                        name="name" 
                        identifiers=""
                        stateIdentifier=""
                        label='' 
                        value={this.state.contactUs_customerContactName}
                        onChangeInputTextHandler={this.setRequesterName} 
                        />
                    </InputCardItem>
                    <InputCardItem headlineText="Email" headlineSubText="" additionalText="">
                      <InputTextV3
                        id="email" 
                        name="email" 
                        identifiers=""
                        stateIdentifier=""
                        label='' 
                        value={this.state.contactUs_customerContactEmail}
                        onChangeInputTextHandler={this.setRequesterEmail} 
                        />
                    </InputCardItem>
                  </div>
                )}

              </InputCard>

              <InputCard headlineText="Contact Us" headlineSubText="">

                <InputCardItem headlineText="Subject" headlineSubText="" additionalText="">
    
                  <InputTextV3
                  id="subject" 
                  name="subject" 
                  identifiers=""
                  stateIdentifier=""
                  label='' 
                  value=""
                  onChangeInputTextHandler={this.messageSubject} 
                  />
                
                </InputCardItem>

                <InputCardItem headlineText="Message" headlineSubText="" additionalText="">
    
                  
                  <InputTextareaV1
                  id="message" 
                  name="message" 
                  identifiers=""
                  stateIdentifier=""
                  label='' 
                  value=""
                  onChangeInputTextHandler={this.messageDescription} 
                  />
                
                </InputCardItem>

                <div className={classNames({ text_s14: true, colour_danger: true, })}>
                  {this.state.contactUsInputErrors === true && (
                    <div className={classNames({ text_s20: true, colour_danger: true, })}>
                      <i class="fas fa-exclamation-triangle"></i> There are items that require your attention
                    </div>
                  )}

                  {this.state.contactUsInputErrors === true && (
                    <div>
                      <ul>
                      {this.state.contactUsInputErrorList.map((item, index) => (
                        <li>{item.message}</li>
                      ))}
                      </ul>
                      <hr/>
                      <br/>
                    </div>
                  )}


                </div>

                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ flex: true, flexJustifyCenter: true, padB20: true })}>
                    <ReCAPTCHA
                      sitekey="6Lf7YK0ZAAAAAH-AXpnH5pcZe2I09rTdA2J2UjDR"
                      onChange={this.onReCaptchaChange}
                      onExpired={this.onReCaptchaExpired}
                      onErrored={this.onReCaptchaErrored}
                    />
                  </div>
                  <div>
                    <StandardApiBtn
                    apiActivityId="logContactUs"
                    icon=""
                    iconLocation=""
                    text="Send Message"
                    tooltip=""
                    action=""
                    classes="shctext shctext-primary shctext-rounded shctext-medium shctext-fullwidth"
                    handleClick={this.logContactUs}
                    />
                  </div>
                </div>


              </InputCard>
              
            </div>
          


            <br/>
  
              

            
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  
  let isAuthenticated = false;
  try {
    isAuthenticated = state.Auth.isAuthenticated;
  } catch (e) {
    isAuthenticated = false;
  }
  if (isAuthenticated === undefined) {
    isAuthenticated = false;
  }

  let userFirstName = 'Name not set.';
  try {
    userFirstName = state.User.userProfile.firstName;
  } catch (e) {
    userFirstName = 'Name not set.';
  }
  if (userFirstName === undefined) {
    userFirstName = 'Name not set.';
  }
  let userId = '';
  try {
    userId = state.User.id;
  } catch (e) {
    userId = '';
  }
  if (userId === undefined) {
    userId = '';
  }


  return {
    isAuthenticated,          //How we display the UI
    userFirstName,
    userId,
    user: state.User,
  };

};


const mapDispatchToProps = {
  recordContactUsInteraction,

};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)
