import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './Styles.css';

// Components
import { MessageTip, MessageHighlight } from 'Components/ShcText';
import { SelectInputChipsApi } from 'Components/ShcSelectInput';

// Actions
import { togglePropertyPopupPanelInfo } from 'Actions';


// tip, message, action


class OverlayFullScreen extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickClose(evt) {
    //console.log('onClick Close');
    this.props.togglePropertyPopupPanelInfo();
    evt.stopPropagation();
  }


  onMouseOver(evt) {
    //console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    //console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    //console.log('onMouseOut (button)');
  }

  onClick(evt) {
    //console.log('onClick (button)');
    //console.log(evt);
  }


  handleAddChip(chip) {
    //console.log('addign chip');
  }
  handleDeleteChip(chip, index) {
    //console.log('deleting chip');
  }


  render() {
    const propertyPopupPanelInfo = this.props.propertyPopupPanelInfo;
    const yourChips = ['test chip', 'plus one'];

    let popupPanel;
    if (propertyPopupPanelInfo) {
      popupPanel = (
        <div
          className={classNames({ MapSearchControlsModalContainer: true })}
          onClick={(evt) => {
            this.onClickClose(evt);
          }}
        >
          <div
            className={classNames({ MapSearchControlsModal: true })}
            onClick={(evt) => {
            evt.stopPropagation();
          }}
          >
            <div className={classNames({ MapSearchControlsModalHeader: true })}>
              <div className={classNames({ MapSearchControlsModalTitle: true })}>
                Property Panel Info
              </div>
              <div
                className={classNames({ MapSearchControlsModalClose: true })}
                onClick={(evt) => {
                this.onClickClose(evt);
              }}
              >
                <i className="fas fa-times" />
              </div>
            </div>

            <div className={classNames({ MapSearchControlsModalBody: true })}>
              <MessageHighlight>Select amenitites and services that you would like to have near your property. We will identifiy all properties (both on-market and off-market) that match these preferences.</MessageHighlight>


              <MessageTip>The following is a tip that we can provide to the user</MessageTip>


            </div>

            <div className={classNames({ MapSearchControlsModalFooter: true })}>

              <div className={classNames({ MapSearchControlsModalFooterLeft: true })}>
                <button
                  className={classNames({ btn: true, btnSecondary: true })}
                  onClick={(evt) => {
                    this.onClickClose(evt);
                  }}
                >Close
                </button>
              </div>
              <div className={classNames({ MapSearchControlsModalFooterRight: true })}>
                <button className={classNames({ btn: true, btnPrimary: true })}>Continue</button>
              </div>
            </div>


          </div>
        </div>
      );
    } else {
      popupPanel = null;
    }

    return (
      <div className={classNames({ popupFullScreenPanel: true })}>
        {popupPanel}
      </div>

    );
  }
}

OverlayFullScreen.propTypes = {

};

const mapStateToProps = state => ({
  propertyPopupPanelInfo: state.PropertyPanels.propertyPopupPanelInfo,
});

const mapDispatchToProps = {
  togglePropertyPopupPanelInfo,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OverlayFullScreen);
