

//NEW IDENTITY MANAGEMENT (USER AND BUSINESS)
export { default as IdentityManagementPanelV2 } from './IdentityManagementPanelV2';

export { default as IM_container } from './IM_container';


export { default as IM_mobile_statusV1 } from './IM_mobile_statusV1';
export { default as IM_mobile_inputV1 } from './IM_mobile_inputV1';
export { default as IM_mobile_verifyV1 } from './IM_mobile_verifyV1';
export { default as IM_email_statusV1 } from './IM_email_statusV1';
export { default as IM_email_inputV1 } from './IM_email_inputV1';
export { default as IM_email_verifyV1 } from './IM_email_verifyV1';
export { default as IM_phone_statusV1 } from './IM_phone_statusV1';
export { default as IM_phone_inputV1 } from './IM_phone_inputV1';
export { default as IM_phone_verifyV1 } from './IM_phone_verifyV1';

export { default as IM_website_statusV1 } from './IM_website_statusV1';
export { default as IM_website_inputV1 } from './IM_website_inputV1';
export { default as IM_website_verifyV1 } from './IM_website_verifyV1';

export { default as IM_business_name_statusV1 } from './IM_business_name_statusV1';
export { default as IM_business_name_inputV1 } from './IM_business_name_inputV1';
export { default as IM_business_name_verifyV1 } from './IM_business_name_verifyV1';

export { default as IM_postal_statusV1 } from './IM_postal_statusV1';
export { default as IM_postal_inputV1 } from './IM_postal_inputV1';
export { default as IM_postal_verifyV1 } from './IM_postal_verifyV1';





export { default as IdentityManagementExpandedContentContainerV1 } from './IdentityManagementExpandedContentContainerV1';
export { default as IdentityManagementPrimaryContentContainerV1 } from './IdentityManagementPrimaryContentContainerV1';


export { default as IdentityManagementPrimaryInputContainerV1 } from './IdentityManagementPrimaryInputContainerV1';
export { default as IdentityManagementPrimaryLocationInputContainerV1 } from './IdentityManagementPrimaryLocationInputContainerV1';
export { default as IdentityManagementPrimaryLocationInputContainerV2} from './IdentityManagementPrimaryLocationInputContainerV2';

export { default as IdentityManagementPrimaryPhoneInputContainerV1} from './IdentityManagementPrimaryPhoneInputContainerV1';

export { default as IdentityManagementPrimaryBusinessNameInputContainerV2} from './IdentityManagementPrimaryBusinessNameInputContainerV2';

export { default as IdentityManagementVerificationCodeInputContainerV1 } from './IdentityManagementVerificationCodeInputContainerV1';

export { default as LocationInputInternationalFormatV1 } from './LocationInputInternationalFormatV1';
export { default as AddressInputFormV1_Australia } from './AddressInputForms/AddressInputFormV1_Australia';


export { default as RegistryInputInternationalFormatV1 } from './RegistryInputInternationalFormatV1';                   // Business Name
export { default as RegistryInputFormV1_Unknown } from './RegistryInputForms/RegistryInputFormV1_Unknown';              // Unknown
export { default as RegistryInputFormV1_Template1 } from './RegistryInputForms/RegistryInputFormV1_Template1';          // Primary Template


export { default as IdentityManagementPrimaryBusinessBrandImageInputContainerV2 } from './IdentityManagementPrimaryBusinessBrandImageInputContainerV2';    
export { default as IdentityManagementPrimaryBusinessBrandWordsInputContainerV2 } from './IdentityManagementPrimaryBusinessBrandWordsInputContainerV2';    
export { default as RegistryBrandWordsInputInternationalFormatV1 } from './RegistryBrandWordsInputInternationalFormatV1';
export { default as RegistryBrandImageInputInternationalFormatV1 } from './RegistryBrandImageInputInternationalFormatV1';

export { default as RegistryBrandImageInputFormV1_Unknown } from './RegistryBrandImageInputForms/RegistryBrandImageInputFormV1_Unknown';          // Primary Template
export { default as RegistryBrandImageInputFormV1_Template1 } from './RegistryBrandImageInputForms/RegistryBrandImageInputFormV1_Template1';          // Primary Template

export { default as RegistryBrandWordsInputFormV1_Unknown } from './RegistryBrandWordsInputForms/RegistryBrandWordsInputFormV1_Unknown';          // Primary Template
export { default as RegistryBrandWordsInputFormV1_Template1 } from './RegistryBrandWordsInputForms/RegistryBrandWordsInputFormV1_Template1';          // Primary Template
