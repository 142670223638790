import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Material UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  secondaryText: {
    minHeight: '40px',
  },
};

function NavCard(props) {
  const { classes } = props;
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary">
            <br />
          </Typography>
          <Typography variant="headline" component="h2">
            {props.headlineText}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            <br />
          </Typography>
          <Typography component="p" className={classes.secondaryText}>
            {props.secondaryText}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">{props.linkText}</Button>
        </CardActions>
      </Card>
    </div>
  );
}

NavCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavCard);
