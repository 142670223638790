import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';
// Components
import { StandardBtn } from 'Components/ShcButtons';
import { OverlayOnScreen } from 'Components/ShcPopups';
// Actions
import { toggleOverlayOnScreen } from 'Actions';


class UsersRolesPermissionsDisplay extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleRemovePermissionRequest = this.handleRemovePermissionRequest.bind(this);
    this.handleRemovePermissionConfirmed = this.handleRemovePermissionConfirmed.bind(this);

    this.handleAddPermissionRequest = this.handleAddPermissionRequest.bind(this);
    this.handleAddPermissionConfirmed = this.handleAddPermissionConfirmed.bind(this);
    this.handleChangePermissionsSelection = this.handleChangePermissionsSelection.bind(this);

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.state = {
      overlayOnScreen: '',
      overlayOnScreenData: '',
      UsersRolesPermissionsSelection: 'customerConnect',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("overlayOnScreenData");
    console.log(this.props.overlayOnScreenData);
  }

  closeOverlayOnScreen = (overlayId) => {
    console.log("close the overlay screen");
    this.props.toggleOverlayOnScreen('close', overlayId);
  }

  
  
  handleRemovePermissionRequest = (userId, userName, userEmail, permissionName) => {
    console.log('Remove permission');
    console.log(userId+', '+userName+', '+userEmail+', '+permissionName);
    // popup - are you sure you want to remove this permission?
    const data = {
      id: userId,
      name: userName,
      email: userEmail,
      permission: permissionName,
      action: 'remove',
    };

    this.props.toggleOverlayOnScreen('open', 'UserRolesPermissionsRemovePermission', data);
  }

  handleRemovePermissionConfirmed = (userId, permissionName) => {
    console.log('remove permission - confirmed - action');
    console.log('remove permission - '+userId+' '+permissionName);

    console.log('sending to back end to process and remove for:')
    console.log(userId+' removing permission '+permissionName);

  }

  handleChangePermissionsSelection = (e) => {
    this.setState({UsersRolesPermissionsSelection: e.target.value});
  }

  handleAddPermissionConfirmed = (userId) => {
    console.log('remove permission - confirmed - action');
    console.log('remove permission - '+userId);

    const {UsersRolesPermissionsSelection} = this.state;

    console.log('sending to back end to process and add for:')
    console.log(userId+' adding permission '+UsersRolesPermissionsSelection);

  }



  handleAddPermissionRequest = (userId, userName) => {
    console.log('Add permission');
    const data = {
      id: userId,
      name: userName,
      action: 'add',
    };
    this.props.toggleOverlayOnScreen('open', 'UserRolesPermissionsAddPermission', data);
  }


  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { data, userId, userName, userEmail } = this.props;
    console.log('permissions on user roles permissions display');
    console.log(data);
    console.log(data.userId);
    console.log(data.userName);
    console.log(data.userEmail);

    const TableListRow = permissionName => (
      <div className={classNames({ UsersRolesPermissionDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesPermissionDisplay_divTableCell: true, UsersRolesPermissionDisplay_contentText: true })}>

          <div
            className={classNames({ UsersRolesPermissionDisplay_permissionItemStyle: true })}
            onClick={() => this.handleRemovePermissionRequest(data.userId, data.userName, data.userEmail, permissionName)}
          >
            {permissionName}

            <div className={classNames({ UsersRolesPermissionDisplay_permissionItemStyleRemove: true })}>
              <i className={`fas fa-times ${classNames({ UsersRolesPermissionDisplay_permissionItemStyleRemove: true })}`} />
            </div>

          </div>

        </div>
      </div>
    );

    const TableListRowAddControl = () => (
      <div className={classNames({ UsersRolesPermissionDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesPermissionDisplay_divTableCell: true, UsersRolesPermissionDisplay_addControlsText: true })}>

          <StandardBtn
            icon=""
            iconLocation=""
            text="Add Permission"
            tooltip=""
            action=""
            classes="shcbtn shcbtn-outline-primary"
            handleClick={() => {this.handleAddPermissionRequest(data.userId, data.userName)}}
          />

        </div>
      </div>
    );

    const TableListRowHeading = () => (
      <div className={classNames({ UsersRolesPermissionDisplay_divTableRow: true })}>
        <div className={classNames({ UsersRolesPermissionDisplay_divTableCell: true, UsersRolesPermissionDisplay_tableHeader: true })}>
          User Permissions
        </div>
      </div>
    );

    const TableList = () => (
      <div>
        <div className={classNames({ UsersRolesPermissionDisplay_divTable: true, UsersRolesPermissionDisplay_container: true })}>
          <div className={classNames({ UsersRolesPermissionDisplay_divTableBody: true })}>

            <TableListRowHeading />

            {data.data.map(obj => (
              TableListRow(obj.name)
            ))}

            <TableListRowAddControl />

          </div>
        </div>
      </div>
    );
    
    const RemovePermissionFromUserDisplay = () => {
      if (this.props.overlayOnScreenData !== '') {
        const {permission, name} = this.props.overlayOnScreenData;
        console.log('state overlay data');
        console.log(this.props.overlayOnScreenData);
        return (
          <div>
            Are you sure you want to remove permission 
            (<font className={classNames({ fontWeightBold: true })}>{permission}</font>)
            from user 
            (<font className={classNames({ fontWeightBold: true })}>{name}</font>)?
          </div>
        )
      }
      return "";
    };

    const AddPermissionToUserDisplayInnerTitle = () => {
      if (this.props.overlayOnScreenData !== undefined && this.props.overlayOnScreenData.name !== undefined) {
        const {name} = this.props.overlayOnScreenData;
        return (
          <div>
          
            Add permissions to user
          </div>
        )
      
      }
      return "";
    };

    const AddPermissionToUserDisplay = () => {
      if (this.props.overlayOnScreenData !== undefined && 
          this.props.overlayOnScreenData.name !== undefined &&
          this.props.overlayOnScreenData.id !== undefined
          ) {
        const {name, id} = this.props.overlayOnScreenData; 
        return (
          <div>
            <div>
              Adding permission for: {name}
            </div>
            <div>
              <select id="UsersRolesPermissionsSelection" name="UsersRolesPermissionsSelection" 
                onChange={this.handleChangePermissionsSelection}
                defaultValue={this.state.UsersRolesPermissionsSelection}>
                <option value="customerConnect">Customer-Connect</option>
                <option value="developer">Developer</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
        )
      }
      return "";
    };


    return (
      <div>
        <TableList />

        <OverlayOnScreen
          id="UserRolesPermissionsRemovePermission"
          width="50%"
          height="100vh"
          anchor="right"
          title="Remove User Permission"
          classes=""
        >
          <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionContainer: true })}>
            <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle: true })}>
              Are you sure?
            </div>
            <RemovePermissionFromUserDisplay/>

            <br/><br/>
            <div className={classNames({ flex: true, 
            flexJustifySpaceBetween: true, 
            UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('UserRolesPermissionsRemovePermission')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Remove Permission"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-danger"
                  handleClick={() => this.handleRemovePermissionConfirmed(this.props.overlayOnScreenData.id, this.props.overlayOnScreenData.permission)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreen>

        <OverlayOnScreen
          id="UserRolesPermissionsAddPermission"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add User Permission"
          classes=""
        >
          <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionContainer: true })}>
            <div className={classNames({ UsersRolesPermissionDisplay_popupRemovePermissionInnerTitle: true })}>
              <AddPermissionToUserDisplayInnerTitle/>
            </div>
            <AddPermissionToUserDisplay/>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Cancel"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('UserRolesPermissionsAddPermission')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Add Permission"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-primary"
                  handleClick={() => this.handleAddPermissionConfirmed(this.props.overlayOnScreenData.id)}
                />
              </div>
            </div>

          </div>
        </OverlayOnScreen>


      </div>
    );
  }
}

UsersRolesPermissionsDisplay.propTypes = {

};
UsersRolesPermissionsDisplay.defaultProps = {
};


const mapStateToProps = state => ({
  overlayOnScreen: state.CommonActions.overlayOnScreen,
  overlayOnScreenData: state.CommonActions.overlayOnScreenData,
});

const mapDispatchToProps = {
  toggleOverlayOnScreen,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UsersRolesPermissionsDisplay);
