import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI


// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class StatCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubIcon: props.headlineSubIcon,
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }


  render() {
    const { classes } = this.props;
    const {
      headlineSubIcon, headlineSubText, headlineText, secondaryText, additionalText, linkText,
    } = this.state;


    return (
      <div className={classNames({ pageWrapper: false })}>
        <CardBasic className={classNames({ shcCardStatCard_container: true })}>
          <div>
            <div className={classNames({ shcCardStatCard_headlineSubTextPanel: true })}>
              <div className={classNames({ shcCardStatCard_headlineSubLeft: true })}>
                {headlineSubText}
              </div>
              <div className={classNames({ shcCardStatCard_headlineSubRight: true })}>
                <i className={headlineSubIcon} />
              </div>
            </div>

            <div className={classNames({ shcCardStatCard_headlineText: true })} component="h2">
              {headlineText}
            </div>
          </div>
        </CardBasic>
      </div>

    );
  }
}

StatCard.propTypes = {
  headlineSubIcon: PropTypes.string,
  headlineSubText: PropTypes.string,
  headlineText: PropTypes.string,
};

StatCard.defaultProps = {
  headlineSubIcon: '',
  headlineSubText: '',
  headlineText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StatCard);
