import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";


// CSS
import componentStyles from './styles.css';

// Components
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';


import { VerifiedIdentityInput_business } from 'Components/ShcModulesIdentity';



// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV3, } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem, AgentConfigCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';


//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { 
  businessAgentsAddAgent,
  businessAgentsRemoveAgent,
  
  addBusinessIdentity,
  removeBusinessIdentity,
  changeBusinessIdentity,

  
  toggleOverlayOnScreenResponsive, verifyUserPhoneNumberWithCall, setFirebaseAuthObj } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange, 
  validateEmail, } from 'Helpers/Functions';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
function getRandomPhoneReferenceId() {
  let code = getRandomInt(9).toString()+getRandomInt(9).toString()+" "+getRandomInt(9).toString()+getRandomInt(9).toString();
  return code.toString();
  /*let a = getRandomInt(9);
  let b = getRandomInt(9);
  let c = getRandomInt(9);
  let d = getRandomInt(9);
  return ""+a+b+" "+c+d;*/
}


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.updateAgentEmailInput = this.updateAgentEmailInput.bind(this);
    this.handleAddAgentsToContactCentre = this.handleAddAgentsToContactCentre.bind(this);
    this.removeAgentEmail = this.removeAgentEmail.bind(this);


  
    this.state = {
      businessViewId: this.props.match.params.routebusinessid,  //Every Business page must have this in state.
      user: this.props.user,    //The main user object

      newAgentEmails: '',


      thisBusinessIdentity: {},                                 //temp?
      businessIdentities: this.props.businessIdentities,                        //temp?
    

      apiTimer: "inactive",
    };
  }


  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  componentDidUpdate(prevProps, prevState) {
    
  }


  updateAgentEmailInput(receivedUpdateObj) {
    let value = '';
    try {
      value = receivedUpdateObj.value;
    } catch (e) {
      value = '';
    }
    if (value === undefined) {
      value = '';
    }

    console.log("updateAgentEmailInput VALUE");
    console.log(value);

    let inputErrors = false;
    let inputErrorDetail = [];

    let emailArray = value.split(';');

    console.log("emailArray");
    console.log(emailArray);

    for (let x = 0; x < emailArray.length; x++) {
      if (emailArray[x] === undefined || emailArray[x] === null || 
      emailArray[x] === '') {
        //Ignore the data. It might be a ; or ;;
      } else {
        if (validateEmail(emailArray[x]) === false) {
          inputErrors = true;
          inputErrorDetail.push(emailArray[x]);
        }
      }
      
    }

    console.log("inputErrors");
    console.log(inputErrors);
    console.log("inputErrorDetail");
    console.log(inputErrorDetail);





    this.setState({
      newAgentEmails: value,
      inputErrors: inputErrors,
      inputErrorDetail: inputErrorDetail,
    });



    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */


  }


  handleAddAgentsToContactCentre(evt) {
    const { newAgentEmails, businessViewId } = this.state;

    console.log("newAgentEmails");
    console.log(newAgentEmails);
    console.log("businessViewId");
    console.log(businessViewId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    const data = {
      newAgentEmails: newAgentEmails,
      businessId: businessViewId,
    };
    this.props.businessAgentsAddAgent({ data, apiUi });


  }

  removeAgentEmail(emailAddress, evt) {
    const { businessViewId } = this.state;

    console.log("emailAddress");
    console.log(emailAddress);
    console.log("businessViewId");
    console.log(businessViewId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };

    const data = {
      emailAddress: emailAddress,
      businessId: businessViewId,
    };
    this.props.businessAgentsRemoveAgent({ data, apiUi });


  }






 





  render() {
    const { userBusinessRoutePermissions } = this.props;
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity && this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
  
            {this.props.businessAccountClosed === true ?
            (
              <div>
                <div className={classNames({ flexGrow: true })}>
                  <TextStyle size="h2">Business Contact Centre Agents</TextStyle>
                </div>
                <CardBasicV2>
                  This account is currently closed.
                </CardBasicV2>
              </div>
            ):(
              <div>
                {this.props.activeSubscription === false ? 
                (
                  <CardBasicV2>
                    This business does not have an active subscription. Please sign up.
                  </CardBasicV2>
                ):(
                  <div>
                    <div className={classNames({ flex: true, maxWidth800: true, })}>
                      <div className={classNames({ flexGrow: true })}>
                        <TextStyle size="h2">Business Contact Centre Agents</TextStyle>
                      </div>
                      <div>

                      </div>
                    </div>

                    <div className={classNames({ flex: true, maxWidth800: true, })}>
                      
                      <div className={classNames({ flexGrow: true })}>
                        <InputCardItem headlineText="Add agent email address" 
                        headlineSubText="Separate multiples with a semi-colon ';'" additionalText="">
                          <InputTextV3
                            id="newAgentEmails" 
                            name="newAgentEmails" 
                            identifiers={[]}
                            stateIdentifier=""
                            label='' 
                            value={this.state.newAgentEmails}
                            onChangeInputTextHandler={this.updateAgentEmailInput} 
                            
                          />
                        </InputCardItem>
                      
                      </div>
                      <div className={classNames({ flexAlignSelfFlexEnd: true, padB10: true, })}>
                        <StandardApiBtn
                          apiActivityId="AddAgentsToContactCentre"
                          icon=""
                          iconLocation=""
                          text="Add Agents"
                          tooltip=""
                          action=""
                          classes="shcbtn shcbtn-primary"
                          handleClick={this.handleAddAgentsToContactCentre}
                        />
                      
                      </div>
                        
                    </div>

                    {this.state.inputErrors === true && (
                      <div className={classNames({ flex: true, maxWidth800: true, })}>
                        
                        <div className={classNames({ flexGrow: true })}>
                          <InputCardItem headlineText="Errors in Agent Email Input" 
                          headlineSubText="" additionalText="">
                            <div className={classNames({ flex: true })}>

                              {this.state.inputErrorDetail && 
                              this.state.inputErrorDetail.map((item, i) => (
                                <div className={classNames({ errorAgentInput: true })}>
                                  {item}
                                </div>
                              ))
                              }

                            </div>
                          </InputCardItem>
                        </div>
      
                      </div>
                    )}
                    

                    <div className={classNames({ flexGrow: true })}>
                      <TextStyle size="h2">Active Contact Centre Agents</TextStyle>
                    </div>

                    <div className={classNames({  maxWidth800: true })}>
                      {
                      this.props.businessAgents && 
                      this.props.businessAgents.length > 0 ?
                        // Arrow function preserves "this"
                      this.props.businessAgents
                      .sort((a, b) => a.emailAddress < b.emailAddress)
                      .map((item, i) => {

                        let gpAgentIntegrations = [];
                        try {
                          gpAgentIntegrations = item.integrations;
                        } catch (e) {
                          gpAgentIntegrations = [];
                        }
                        if (gpAgentIntegrations === undefined) {
                          gpAgentIntegrations = [];
                        }

                        let gpAgentId = '';
                        try {
                          gpAgentId = item._id;
                        } catch (e) {
                          gpAgentId = '';
                        }
                        if (gpAgentId === undefined) {
                          gpAgentId = '';
                        }

                        return (
                          <li key={item.emailAddress} className={classNames({ listNone: true })}>
                            <AgentConfigCardItem 
                            businessId={this.state.businessViewId}
                            gpAgentId={gpAgentId}
                            gpAgentIdentifier={item.emailAddress}
                            removeAgentHandler={this.removeAgentEmail}
                            gpAgentIntegrations={gpAgentIntegrations}
                            />
                          </li>

                          )
                      }, this)
                      : (
                        <div>

                          <CardBasicV2>
                            No records
                          </CardBasicV2>

                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </SidebarSubNav>

        
        
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch(e) {}
  //console.log("ownProps");
  //console.log(ownProps);
  let businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  //console.log("mapStateToProps | businessIdentity");
  //console.log(businessIdentity);


  let businessAgents = [];
  try {
    businessAgents = businessIdentity.businessAgents;
  } catch (e) {
    businessAgents = [];
  }
  if (businessAgents === undefined) {
    businessAgents = [];
  }


  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }

  ///// GET SUBSCRIPTION DETAILS /////
  let businessSubscriptions = [];
  let activeSubscription = false;
  try {
    businessSubscriptions = businessIdentity.subscriptions.filter(sub => sub.active === true);

    if (businessSubscriptions.length > 0) {
      activeSubscription = true;
    }
  } catch (e) {
    //console.error('mapStateToProps | Failed to find subscription');
  }

  return {
    businessAccountClosed,
    activeSubscription, 
    businessIdentity: Object.assign({}, businessIdentity),
    businessAgents: Object.assign([], businessAgents),
    user: state.User,
  };

};

const mapDispatchToProps = {
  businessAgentsAddAgent, 
  businessAgentsRemoveAgent,

  addBusinessIdentity,
  removeBusinessIdentity,
  changeBusinessIdentity,




  toggleOverlayOnScreenResponsive,
  
  verifyUserPhoneNumberWithCall,
  setFirebaseAuthObj
};
/*
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);

