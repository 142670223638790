import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";

// CSS
import componentStyles from './styles.css';

// Components
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';


import { VerifiedIdentityInput_business } from 'Components/ShcModulesIdentity';



// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';

import { TableQueueLineHeader, TableQueueLineItem } from 'Components/ShcAdminModulesWorkQueues';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { syncWorkQueue, } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.state = {


      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      return {
      };
    }
    return null;
  }

  async componentDidMount(props) {
    this.authFirebaseListener = await firebaseOnAuthStateChange();

    const apiActivityId = "SyncWorkQueue";
    const apiUi = { apiActivityId };
    const data = {};
    this.props.syncWorkQueue({ data, apiUi });
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  componentWillUnmount() {
    //console.log('unmounting component');
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }








  render() {
    const { userBusinessRoutePermissions } = this.props;
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText=""
                sectionTitle="Admin"
                linkGroupSelectionTF="true"
                linkGroupSelection="admin"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>


            <div className={classNames({ aaa: true, aaa: true })}>
              <InputCard headlineText="Dashboard" headlineSubText="">
                Admin Dashboard Page
                
              </InputCard>

            </div>




          
          </div>
        </SidebarSubNav>

        
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
 
  return {
    adminWorkQueue: Object.assign([], state.AdminWork.manualVerifications),
  };

};

const mapDispatchToProps = {
  syncWorkQueue,

};
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);