import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class CardMiniS1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      padding, height, margin, headline, headlineSubIcon, size, weight,
    } = this.props;

    const TextH1 = () => (
      <div className={classNames({
        text_h1: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH2 = () => (
      <div className={classNames({
        text_h2: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH3 = () => (
      <div className={classNames({
        text_h3: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH4 = () => (
      <div className={classNames({
        text_h4: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    const TextH5 = () => (
      <div className={classNames({
        text_h5: true,
        color_primary_dark: true,
        fontWeightBold: weight === 'bold',
      })}
      >
        {this.props.children}
      </div>
    );

    let headingStyle;
    if (size === 'h1') {
      headingStyle = <TextH1 />;
    } else if (size === 'h2') {
      headingStyle = <TextH2 />;
    } else if (size === 'h3') {
      headingStyle = <TextH3 />;
    } else if (size === 'h4') {
      headingStyle = <TextH4 />;
    } else if (size === 'h5') {
      headingStyle = <TextH5 />;
    } else {
      headingStyle = this.props.children;
    }

    return (
      <div className={classNames({ ShcCardMiniS1Container: true, pad20: padding !== 'none', mar10: margin !== 'none' })}>
        <div className={classNames({ ShcCardMiniS1Container_section: true })}>
          <div className={classNames({ ShcCardMiniS1Container_sectionSplit: true })}>
            <div className={classNames({ ShcCardMiniS1Container_headlineSubTextLeft: true })}>
              {headline}
            </div>
            <div className={classNames({ ShcCardMiniS1Container_headlineSubTextRight: true })}>
              <i className={headlineSubIcon} />
            </div>
          </div>

          <div className={classNames({ ShcdashboardContainerDash2_headlineText: true })}>
            {headingStyle}
          </div>
        </div>
      </div>
    );
  }
}

CardMiniS1.propTypes = {

};
CardMiniS1.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardMiniS1);
