import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import Switch from "react-switch";

// CSS
import componentStyles from './styles.css';

// Components
import { IdentityManagementPanelV1 } from 'Components/ShcModulesIdentityManagementBusiness';


import { VerifiedIdentityInput_business } from 'Components/ShcModulesIdentity';



// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import LinkGroups from 'Components/LinkGroups';
//import LinkGroupLayoutBasic from 'Components/LinkGroupLayoutBasic';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { UserIdentityVerificationSteps } from 'Components/ShcSteps';

import { TableQueueLineHeader, TableQueueLineItem } from 'Components/ShcAdminModulesWorkQueues';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { syncWorkQueue, } from 'Actions';

// Store
import { auth } from 'Store/client';

// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';



class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);

    this.state = {


      apiTimer: "inactive",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let updateState = false;
    if (nextProps.user !== prevState.user) {
      updateState = true;
    }

    if (updateState === true) {
      return {
      };
    }
    return null;
  }

  async componentDidMount(props) {
    this.authFirebaseListener = await firebaseOnAuthStateChange();

    const apiActivityId = "SyncWorkQueue";
    const apiUi = { apiActivityId };
    const data = {};
    this.props.syncWorkQueue({ data, apiUi });
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  componentWillUnmount() {
    //console.log('unmounting component');
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }








  render() {
    const { userBusinessRoutePermissions } = this.props;
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText=""
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="admin"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>


            <div className={classNames({ aaa: true, aaa: true })}>
              <InputCard headlineText="Work Queue" headlineSubText="">
                <TableQueueLineHeader />
                <div>

                  {
                  this.props.adminWorkQueue &&
                  this.props.adminWorkQueue.length > 0 ?
                    // Arrow function preserves "this"
                    this.props.adminWorkQueue.map((item, i) => {
                      let id = '';
                      try {
                        id = item._id;
                      } catch(e) {}
                      let businessId = '';
                      try {
                        businessId = item.businessId;
                      } catch(e) {}
                      let identityId = '';
                      try {
                        identityId = item.identityId;
                      } catch(e) {}
                      let verificationType = '';
                      try {
                        verificationType = item.verificationType;
                      } catch(e) {}
                      let businessDataRegion = '';
                      try {
                        businessDataRegion = item.businessDataRegion;
                      } catch(e) {}
                      let status = '';
                      try {
                        status = item.status;
                      } catch(e) {}
                      let createdAt = '';
                      try {
                        createdAt = item.createdAt;
                      } catch(e) {}

                      //Get Business Name identity
                      let businessIdentity = '';
                      try {
                        businessIdentity = item.data.businessIdentity.identity;
                      } catch(e) {}
                      if (businessIdentity === undefined || businessIdentity === null) {
                        businessIdentity = '';
                      }


                      let additionalInfo = '';
                      if (verificationType === 'business-name') {
                        additionalInfo = businessIdentity;
                      }


                      return (
                        <Link to={`work-queue/${id}`} className={classNames({ noLinkStyle: true })}>
                          <TableQueueLineItem
                            id={id}
                            businessId={businessId}
                            identityId={identityId}
                            type={verificationType}
                            additionalInfo={additionalInfo}
                            businessDataRegion={businessDataRegion}
                            status={status}
                            createdAt={createdAt}

                            description={`${businessDataRegion} - ${businessId} - ${identityId}`}
                            datetime={createdAt}
                          />
                        </Link>
                      )}, this)
                  :(
                    <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                      <TextStyle size="h4" weight="normal">None.</TextStyle>
                    </div>
                  )}

                 
                </div>
                
              </InputCard>

            </div>




          
          </div>
        </SidebarSubNav>

        <OverlayOnScreenResponsive
          id="businessIdentitiesAddItem"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add an Identity"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Identities</TextStyle><br/>
              <TextStyle size="h4">Public identifiers such as website, email, phone number.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h5">
              Identities are used to create business profiles. A business profile is made up of
              multiple identites such as a website, email, phone number. Each identity needs to be
              verified before it can be added to a profile. Business profiles are used as the main
              contact card that a customer will see. This is your brand.
              </TextStyle>
              <br/>
            </div>
            <div className={classNames({ abc: true })}>
              Please select an identitiy you would like to add:<br/>

              <div className={classNames({ flex: true })}>

                <StandardApiBtn
                  apiActivityId="AddNewBusinessIdentity"
                  icon=""
                  iconLocation=""
                  text="Email"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-info"
                  handleClick=""
                />
                
              </div>
              <div className={classNames({ flex: true })}>
                
                
              </div>

            </div>

            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('businessIdentitiesAddItem')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>

        <OverlayOnScreenResponsive
          id="userAccountProfileVerifyPhone"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Verify your phone number"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ text_h5: true })}>
              We verify your phone number to ensure any business interactions
              that are destined for you, are only delivered to you and no one else.
            </div>
            <br/>
            <div className={classNames({ text_h5: true })}>
              We will be calling +61 419 665 808.
              <br/>
              For your protection, we will quote the following reference number on the call: 45 67
            </div>
            <br/>
            <div className={classNames({ text_h5: true })}>
              With your phone ready, we will call you and request that your enter in the following
              code.
              <br/><br/>
              Code: 12 56 78 32
            </div>
            <br/><br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('userAccountProfileVerifyPhone')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Request call now"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick=""
                />
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
 
  return {
    adminWorkQueue: Object.assign([], state.AdminWork.manualVerifications),
  };

};

const mapDispatchToProps = {
  syncWorkQueue,

};
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template);