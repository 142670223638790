import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Link from 'react-router-dom/Link';



// CSS
import componentStyles from './styles.css';

class ShcFooterPrimaryContent extends React.Component {
  state = {
  };
  render() {

    return (
      <div className={classNames({shcFooterPrimaryContent:true})}>
        <div className={classNames({shcFooterPrimaryContent_container:true})}>


          <div className={classNames({shcGrid290: true, })}>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/home" className={classNames({textDecoration: true, colour_white:true})}>
              Home<br/>
              </Link>
              <Link to="/home" className={classNames({textDecoration: true, colour_white:true})}>
              How it works<br/>
              </Link>
              <Link to="/pricing" className={classNames({textDecoration: true, colour_white:true})}>
              Pricing<br/>
              </Link>
              <Link to="/home" className={classNames({textDecoration: true, colour_white:true})}>
              Connect<br/>
              </Link>
              <br/>
            </div>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/sign-up"  className={classNames({textDecoration: true, colour_white:true})}>
              Create an Account<br/>
              </Link>
              <Link to="/login" className={classNames({textDecoration: true, colour_white:true})}>
              Login<br/>
              </Link>
              <Link to="/support"  className={classNames({textDecoration: true, colour_white:true})}>
              Support<br/>
              </Link>
              <Link to="/feedback"  className={classNames({textDecoration: true, colour_white:true})}>
              Feedback<br/>
              </Link>
              <Link to="/contact-us"  className={classNames({textDecoration: true, colour_white:true})}>
              Contact Us<br/>
              </Link>
            </div>
            <div className={classNames({ flex: true, maxWidth400: true, flexJustifyCenter: true, flexColumn: true, })}>
              <Link to="/privacy" className={classNames({textDecoration: true, colour_white:true})}>
              Privacy Policy<br/>
              </Link>
              <Link to="/terms-of-use" className={classNames({textDecoration: true, colour_white:true})}>
              Terms of Use<br/>
              </Link>
              <Link to="/service-status" className={classNames({textDecoration: true, colour_white:true})}>
              Service Status<br/>
              </Link>
              <Link to="/home"  className={classNames({textDecoration: true, colour_white:true})}>
              Partners<br/>
              </Link>
            </div>
          </div>




        </div>
      </div>
    );
  }
}

ShcFooterPrimaryContent.propTypes = {

};


const mapStateToProps = state => ({

})

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcFooterPrimaryContent);