import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// Routing
//import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { setSubSidebarState } from 'Actions';


class ShcSubAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.state = {
      subSidebarOpenState: this.props.subSidebarOpenState,


      open: false,
      auth: true,
      anchorEl: null,
      isAuthenticated: null,
      isAdmin: null,
    };
  }
  

  componentDidMount() {
    console.log("componentDidMount - ShcSubAppBar");
    console.log("appbarHeight: "+ this.state.appbarHeight);
    this.props.setSubSidebarState(false);
  }
  


  handleDrawerOpen = () => {
    console.log('handleDrawerOpen - set to open:true');
    this.setState({ open: true });
    this.props.setSubSidebarState(true);
  };

  handleDrawerClose = () => {
    console.log('handleDrawerClose - set to open:false');
    this.setState({ open: false });
    this.props.setSubSidebarState(false);
  };

  render() {
    const { subSidebarOpenState, title } = this.props;
    
    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";


    return (
      <div className={classNames({shcSubHeaderAppbarContainer:true, shcSubHeaderAppbarContainerMarginTop: true})}>
        <div className={classNames({shcSubHeader_AppBar:true})}>
        <div className={classNames({shcSubHeader_appbarContainer:true})}>
        
            <div className={classNames({shcSubHeader_iconContainer:true})}>
              
              <i 
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={`${classIconBars} ${classNames({shcHeader_NavIcon: true,  hide: this.state.open})}`}
              ></i>

              <i 
              aria-label="close drawer"
              onClick={this.handleDrawerClose}
              className={`${classIconAngleLeft} ${classNames({shcHeader_NavIcon: true, hide: !this.state.open})}`}
              ></i>

            </div>

            <div className={classNames({shcSubHeader_Title:true})}>
              {title}
            </div>

          </div>
        </div>
      </div>
    );
  }
}

ShcSubAppBar.propTypes = {

};


const mapStateToProps = state => {
  console.log("mapStateToProps - ShcSubAppBar");
  console.log(state);
  return {
    subSidebarOpenState: state.UI.subSidebarOpen,
    appbarHeight: state.UI.appbarHeight,
  }
}

const mapDispatchToProps = {
  setSubSidebarState,
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcSubAppBar);