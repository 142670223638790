// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS

// Components
import LinkGroupListItem from 'Components/LinkGroupListItem';

// Actions


class LinkGroups extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const linkGroupSelection = this.props.linkGroupSelection;
    const activeUrl = this.props.url;
    const RoutesSort = Routes.sort((a, b) => a.navPosition - b.navPosition);




    let navDropDownItemsUserData;

    const LinkGroupList = (props, state) => {
      const {
        path, exact, permission, navTitleCollapsed, navTitleExpanded, navTitle, displayicon, navVisible, navDropDownItems, navDropDownUserState,
      } = props.navData;
      let linkHighlight = false;

      if (props.adminRoute && !isAdmin) return null;
      if (props.authRoute && !isAuthenticated) return null;
      console.log('linkGroup check:');
      console.log(props.linkGroup);
      console.log(linkGroupSelection);
      if (!props.linkGroup) return null;

      console.log(`${activeUrl} === ${path}`);
      if (activeUrl === `/${path}`) {
        linkHighlight = true;
      } else {
        linkHighlight = false;
      }

      if (typeof navDropDownItems !== 'undefined') {
        if (navDropDownItems.length > 0) {
          // console.log('Nav has drop down items');
          return (
            <LinkGroupListItem hasChildren navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navDropDownItems={navDropDownItems} navDropDownUserState={navDropDownUserState} linkHighlight={linkHighlight} />
          );
        }
      }
      return (
        <LinkGroupListItem to={`/${path}`} hasChildren={false} navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} linkHighlight={linkHighlight} />

      );
    };
    return (
      <div>

        {
          RoutesSort.map(singleRoute => (

            <li key={singleRoute.key}>
              {
                <LinkGroupList
                  navData={singleRoute}
                  adminRoute={singleRoute.permission.includes('admin')}
                  authRoute={singleRoute.permission.includes('auth')}
                  linkGroup={singleRoute.linkGroups.includes(linkGroupSelection)}
                />
              }
            </li>
            ),
        ).sort((a, b) => a.navPosition - b.navPosition)}
      </div>
    );
  }
}
// ListItemCustomControl
// CollapsableNavListItem
const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


LinkGroups.propTypes = {

};

export default compose(
  connect(mapStateToProps),
)(LinkGroups);
