import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import HelmetSEO from 'Components/HelmetSEO';
import { SiteHeader, SiteHeaderSubPanel, ShcHeader, ShcSubHeader } from 'Components/ShcHeader';
import { FooterPrimaryLayout, FooterPrimaryLinks, FooterPrimaryContent, FooterBaseSocial } from 'Components/ShcFooter';
import Template from './Template';

// Helpers

// Actions


class RouteNotFound extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    },
    this.pageData = {
      contentTitle: '',
      displayicon: '',
      breadcrumb: '',
      rightcrumb: '',
    };


  }


  render() {
    return (
      <div className={classNames({ pageWrapper: true })}>
        <HelmetSEO url="" />

        <ShcHeader title="">
          <Template {...this.props} title="" breadcrumb="" url="" />
        </ShcHeader>

      </div>
    );
  }
}

RouteNotFound.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(RouteNotFound);

