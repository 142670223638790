import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { RegistryInputFormV1_Unknown, RegistryInputFormV1_Template1  } from 'Components/ShcModulesIdentityManagementBusiness';
// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class RegistryInputInternationalFormatV1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      address: this.props.value,

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
   
  
  handleChange = address => {
    //console.log("Handle change from PlacesAutoComplete")
    this.setState({ address });
    this.props.onChange(address);
  };
  
  handleSelect = address => {
    //console.log(address);
    this.setState({ address });
    this.props.onChange(address);
  };

  
 
  render() {
    const { registry, onChange, 
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, 
      identityInputValue,
    } = this.props;

    //load the correct form based on the country.
    //registry === australia

    return (
      <div>

        {registry === 'other' ? (
          <RegistryInputFormV1_Unknown
          registry={registry}

          identityId={identityId}
          identityInputValue={identityInputValue}
          identityType={identityType} 
          identityLocked={identityLocked} 
          verificationStatus={verificationStatus}
          notifyThisDevice={notifyThisDevice}
          dataSet={dataSet}
          apiEndpoint={apiEndpoint}
          businessId={businessId}
          stateIdentifier={stateIdentifier}
          identifiers={identifiers}

          />
        ):(
          <RegistryInputFormV1_Template1
          country={registry}

          identityId={identityId}
          identityInputValue={identityInputValue}
          identityType={identityType} 
          identityLocked={identityLocked} 
          verificationStatus={verificationStatus}
          notifyThisDevice={notifyThisDevice}
          dataSet={dataSet}
          apiEndpoint={apiEndpoint}
          businessId={businessId}
          stateIdentifier={stateIdentifier}
          identifiers={identifiers}

          />
        )}
        

      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RegistryInputInternationalFormatV1);
