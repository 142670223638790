import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class PreferenceUserControls extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: true,
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }


  render() {
    // State
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;

    // Props
    const title = this.props.title;
    const desc = this.props.desc;
    const info = this.props.info;
    let toExpandText = this.props.toExpandText;
    let toCollapseText = this.props.toCollapseText;
    let titleSize = this.props.titleSize; // small, medium, large

    if (!titleSize) {
      titleSize = 'large';
    }
    if (!toExpandText) {
      toExpandText = 'Change';
    }
    if (!toCollapseText) {
      toCollapseText = 'Close';
    }


    return (
      <div className={classNames({ PreferenceUserControls_container: true, expander: true, expanded: !this.state.collapse })}>
        <div onClick={this.toggle} className={classNames({ PreferenceUserControls_contentRow: true })}>
          <div
            button
            onClick={this.toggle}
            className={classNames({
            PreferenceUserControls_title_large: titleSize == 'large',
            PreferenceUserControls_title_medium: titleSize == 'medium',
            PreferenceUserControls_title_small: titleSize == 'small',
            })}
          >{title}
          </div>
          <div button onClick={this.toggle} className={classNames({ PreferenceUserControls_change: true })}>
            {this.state.collapse ? (
              <div>{toExpandText}</div>
          ) : (
            <div>{toCollapseText}</div>
          )}
          </div>
        </div>
        <div onClick={this.toggle} className={classNames({ PreferenceUserControls_contentRow: true, PreferenceUserControls_contentRowBottom: true })}>
          <div button onClick={this.toggle} className={classNames({ PreferenceUserControls_desc: true })}>{desc}</div>
          <div button onClick={this.toggle} className={classNames({ PreferenceUserControls_info: true })}>{info}</div>
        </div>
        <div className={classNames({ PreferenceUserControls_content: true, PreferenceUserControls_hide: this.state.collapse })} isOpen={this.state.collapse}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});
const mapDispatchToProps = {

};
export default compose(connect(mapStateToProps, mapDispatchToProps))(PreferenceUserControls);
