import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { AsYouType } from 'libphonenumber-js';

import DOMPurify from 'dompurify';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn, StandardApiBtn, StandardIcon } from 'Components/ShcButtons';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { TextStyle } from 'Components/ShcTextStyles';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import { setUiRadioOptions } from 'Actions';


// Functions
// import {} from 'Helpers/Functions';


class RadioOptionCardV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      radioOptionsGroup: this.props.radioOptionsGroup,
      radioOptionsId: this.props.radioOptionsId,
      radioSelectedState: this.props.radioSelectedState,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps - RadioOptionCardV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');

    //console.log('RadioOptions | businessId');
    //console.log(this.props.businessId);

    const selection = {
      businessId: this.props.businessId,
      radioOptionsGroup: this.state.radioOptionsGroup,
      radioOptionsId: this.state.radioOptionsId,
      radioSelectedState: this.props.radioSelectedState,
    };
    //console.log('Radio Selection');
    //console.log(selection);
    this.props.setUiRadioOptions(selection); // Redux management across multiple components

    if (typeof(this.props.onSelectHandler) === 'function') {
      this.props.onSelectHandler(selection); // Parent component (if required)
    }
  }

  render() {
    const {
      businessId,
      headlineText,
      secondaryText,
      onSelectHandler,
    } = this.props;

    return (
      <div>
        <CardBasicV2
          onActiveStyle="primary"
          onActive={this.props.radioSelectedState}
          padding="none"
          className={classNames({ contentPad10: false })}
          onClick={this.toggleSelectedItem}
        >
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ ShcRadioOptionCard_selectIcon: true })}>
              {this.props.radioSelectedState ?
                <StandardIcon
                  icon="fas fa-dot-circle"
                  classes="colour_primary"
                  handleClick={this.toggleSelectedItem}
              />
            :
                <StandardIcon
                  icon="far fa-circle"
                  classes=""
                  handleClick={this.toggleSelectedItem}
              />
            }


            </div>
          </div>

          <div
            className={classNames({ cursorPointer: true })}
            onClick={this.toggleSelectedItem}
          >

            <div className={classNames({ ShcRadioOptionCard_primaryText: true })}>
              {headlineText}
            </div>

            { secondaryText ?
              <div>
                <div className={classNames({ ShcRadioOptionCard_secondaryText: true })}>
                  {secondaryText}
                </div>
              </div>
            : null }

          </div>
        </CardBasicV2>
      </div>
    );
  }
}

RadioOptionCardV1.propTypes = {

};
RadioOptionCardV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
  let radioSelectedState = false;
  if (state.UI.uiRadioOptions) {
    for (let z = 0; z < state.UI.uiRadioOptions.length; z++) {
      if (state.UI.uiRadioOptions[z].businessId === ownProps.businessId) {
        if (state.UI.uiRadioOptions[z].radioOptionsGroup === ownProps.radioOptionsGroup) { // This Radio Group
          if (state.UI.uiRadioOptions[z].selectedRadioOptionsId === ownProps.radioOptionsId) { // this ID
            radioSelectedState = true;
            //console.log(`RADIO MATCH | ${state.UI.uiRadioOptions[z].selectedRadioOptionsId}`);
          } else {
            //console.log(`RADIO NO-MATCH | ${state.UI.uiRadioOptions[z].selectedRadioOptionsId}`);
          }
        }
      }
    }
  } else {
    radioSelectedState = false;
  }

  return {
    radioSelectedState,
    uiRadioOptions: Object.assign([], state.UI.uiRadioOptions),
  };

};

const mapDispatchToProps = {
  setUiRadioOptions,
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(RadioOptionCardV1);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(RadioOptionCardV1);
