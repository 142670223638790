import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');
import Dropdown from 'react-dropdown';


// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';
import { LocationInputInternationalFormatV1 } from 'Components/ShcModulesIdentityManagementBusiness';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class IdentityManagementPrimaryLocationInputContainerV2 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.toggleIdentityNotification = this.toggleIdentityNotification.bind(this);
    this.updateData = this.updateData.bind(this);
    
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);

    this.state = {
      apiTimerCollection: [], // List of active timers.

      postageCountry: '',

      identityInputValue: this.props.identityInputValue,

      identityLocked: this.props.identityLocked,
      identityInputErrors: false,


      identityReVerify: false,

      identityNotificationOptionsAvailable: true,   //Whether this identity has the ability to have notifications (e.g. user can flag this identity to have push notifications)
      identityNotificationEnabled: this.props.notifyThisDevice,

      identityLabelInputErrors: false,


      //Use the value provided or set it to false.
      savingData: this.props.savingData ? this.props.savingData : false,
      savingDataError: false,
      lastChangeTransactionId: '',

      //this is old
      syncStatus: this.props.syncStatus ? this.props.syncStatus : true,
      

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateState = false;

    let savingData = prevState.savingData;
    let savingDataError = prevState.savingDataError;

    if (nextProps.data !== prevState.data) {
      updateState = true;
    }


    if (nextProps.verificationStatus !== prevState.verificationStatus) {
      updateState = true;
    }

    const identityReference = prevState.lastChangeTransactionId;
    let dataSavingMatch = '';
    try {
      dataSavingMatch = nextProps.dataSavingReference.find(data => {
        if (data.apiTransactionId === identityReference) {
          return data;
        }
      });
    } catch (e) {
    }
    if (dataSavingMatch !== '' && dataSavingMatch !== undefined) {
      //console.log("getDerivedStateFromProps | dataSavingMatch");
      //console.log(dataSavingMatch);
      //console.log(dataSavingMatch.apiCompleted);
      //console.log(JSON.stringify(dataSavingMatch));
      updateState = true;
      if (dataSavingMatch.apiCompleted === true) {
        savingData = false;
      } else {
        savingDataError = true;
      }
    }
   
    if (updateState === true) {
      return {
        data: nextProps.data,
        savingData,
        savingDataError,
        verificationStatus: nextProps.verificationStatus,
      };
    }
    return null;
  }

  componentDidMount() {
    let errors = false;
    if (this.props.identityType != null && this.props.identityType != undefined) {
      //console.log('Data Input Assessment');
      /* None currently available for Postal
      if (validateDataType(this.props.identityType, this.props.identityInputValue)) {
        //console.log("validateDataType = true");
        errors = false;
      } else {
        //console.log("validateDataType = false");
        errors = true;
      }
      */
    }

    //console.log("======== dataSet ======");
    //console.log(this.props.dataSet);
    //console.log(JSON.stringify(this.props.dataSet));

    let postageCountry = '';
    try {
      postageCountry = this.props.dataSet.postageAdditionalData.country;
    } catch (e) {}

    if (postageCountry === undefined) {
      postageCountry = '';
    }

    let identityLocked = false;
    try {
      identityLocked = this.props.dataSet.identityLocked;
    } catch (e) {}

    if (identityLocked === undefined) {
      identityLocked = false;
    }

    let identityInputValue = this.props.dataSet.identityInputValue;
    if (identityLocked === true) {
      let singleLineAddress = '';

      let attention = '';
      try {
        attention = this.props.dataSet.postageAdditionalData.attention;
      } catch (e) {}
      let suiteUnitApartment = '';
      try {
        suiteUnitApartment = this.props.dataSet.postageAdditionalData.suiteUnitApartment;
      } catch (e) {}
      let addressLine1 = '';
      try {
        addressLine1 = this.props.dataSet.postageAdditionalData.addressLine1;
      } catch (e) {}
      let addressLine2 = '';
      try {
        addressLine2 = this.props.dataSet.postageAdditionalData.addressLine2;
      } catch (e) {}
      let suburb = '';
      try {
        suburb = this.props.dataSet.postageAdditionalData.suburb;
      } catch (e) {}
      let state = '';
      try {
        state = this.props.dataSet.postageAdditionalData.state;
      } catch (e) {}
      let postcode = '';
      try {
        postcode = this.props.dataSet.postageAdditionalData.postcode;
      } catch (e) {}
      let country = '';
      try {
        country = this.props.dataSet.postageAdditionalData.country;
      } catch (e) {}

   

      singleLineAddress = `ATTN: ${attention}. ${suiteUnitApartment} ${addressLine1} ${addressLine2}, ${suburb}, ${state} ${postcode} ${country}`;



      identityInputValue = singleLineAddress;
    }







    this.setState({
      identityInputValue,
      identityInputErrors: errors,
      postageCountry,
    });

  }

  componentDidUpdate(prevProps, prevState) {
  }


  handleLocationInputChange = address => {
    //console.log("Handle change from CHILD component (LocationSearchInputGoogleAutoComplete)")
    this.setState({ identityInputValue: address });

    let returnObj = {
      identifiers: this.props.identifiers,
      name: this.props.name,
      stateIdentifier: this.props.stateIdentifier,
      value: address,
    }
    //console.log(returnObj);

    this.updateData(returnObj);

  };

  handleSelectInputChange(evt) {
    //console.log("handleSelectInputChange");
    //console.log(evt);
    let value = evt.value;

    this.setState({postageCountry: value});
    let receivedUpdateObj = {};
    if (this.props.componentTargetUser === 'Business') {
      receivedUpdateObj = {
        name: 'identities.businesses.$.businessIdentities.$.postageAdditionalData.country',
        identifiers: [{_id: this.props.businessId}, {_id: this.props.identityId}],
        value: value,
        stateIdentifier: "BusinessIdentities",
      }

    } else if (this.props.componentTargetUser === 'User') {
      receivedUpdateObj = {
        name: 'userIdentities.$.postageAdditionalData.country',
        identifiers: [{_id: this.props.identityId}],
        value: value,
        stateIdentifier: "User",
      }

    }

    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    /////////this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    //This is a catch as the Postal Address form may need multiple field inputs.
    this.props.onChangeHandler(evt);    //Advise the identity management screen that a change happened.
  
    
  }
  


  updateData(returnObj) {
    //console.log("updateData");
    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    ////////////////////////////////////////////////////
    //// UPDATE PARENT WITH EVENT ACTION
    ////////////////////////////////////////////////////
    this.props.onChangeHandler(event);    //Advise the identity management screen that a change happened.
  

    //Advise the parent component that the input status changed
    this.props.identityInputValidationStatus(true);

  }




 
  toggleIdentityNotification(evt) {
    //console.log("toggleIdentityNotification");

    this.setState(prevState => ({
      identityNotificationEnabled: !prevState.identityNotificationEnabled
    }),
    () => {


      let returnObj = {};
      if (this.props.componentTargetUser === 'Business') {
        returnObj = {
          identifiers: this.props.identifiers,
          name: "identities.businesses.$.businessIdentities.$.notifyThisDevice",
          stateIdentifier: this.props.stateIdentifier,
          value: this.state.identityNotificationEnabled,
        }

      } else if (this.props.componentTargetUser === 'User') {
        returnObj = {
          identifiers: this.props.identifiers,
          name: "userIdentities.$.notifyThisDevice",
          stateIdentifier: this.props.stateIdentifier,
          value: this.state.identityNotificationEnabled,
        }

      }


      //console.log(returnObj);
  
      this.updateData(returnObj);

    }); //End setState Callback

  }

  /*
  <LocationSearchInputGoogleAutoComplete
  name={name}
  value={this.state.identityInputValue}
  onChange={this.handleLocationInputChange}
  />
  */


  render() {
    const { 
      componentTargetUser,
      identityId, identityType, identityLocked, verificationStatus, notifyThisDevice, dataSet, apiEndpoint,
      businessId, stateIdentifier, identifiers, id, name, onChangeHandler,

      identityInputValue,

      icon, children } = this.props;

    const primaryIcon = <div><i className={`${icon} ${classNames({colour_white: true, padR10: true})}`}></i> They contacted me</div>;
    
  
    const options = [
      { value: 'australia', label: 'Australia' },
      { value: 'other', label: 'Other Country' },
    ];

    let postalCountry = '';
    try {
      postalCountry = dataSet.postageAdditionalData.country;
    } catch (e) {}
    

  
    return (
      <div>
        <div className={classNames({
          flex: true,
          flexJustifySpaceBetween: true,
          flexAlignBaseline: true,
          ShcIdentityInput_textInput_root: true,
        })}
        >
          <div className={classNames({
            ShcIdentityInput_textInput_child: true,
            ShcIdentityInput_textInput_errors_icon: this.state.identityInputErrors,
            ShcIdentityInput_textInput_notificationsEnabled_icon: 
            this.state.identityNotificationOptionsAvailable && this.state.identityNotificationEnabled,
            ShcIdentityInput_textInput_notificationsDisabled_icon: 
            this.state.identityNotificationOptionsAvailable && !this.state.identityNotificationEnabled,
          })}
          >
            {this.state.identityNotificationOptionsAvailable &&
              <div className={classNames({ 
                ShcIdentityInput_textInput_notificationsClickOverlayRelative: true,
              })}>
                <div className={classNames({ 
                  ShcIdentityInput_textInput_notificationsClickOverlay: true,
                })}
                onClick={this.toggleIdentityNotification}>

                </div>
              </div>
            }
            {identityLocked === true ?
            <div className={classNames({ ShcIdentityInput_textNoInput: true, 
              ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
              ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable })}>
              {this.state.identityInputValue}
            </div>
            :
              <div>

                <div className={classNames({ flex: true, cursorPointer: true })}>
                  <div className={classNames({ flexGrow: true })}>
                    <Dropdown 
                    options={options} 
                    onChange={this.handleSelectInputChange} 
                    value={options.find(option => option.value === postalCountry)}
                    placeholder="Select an option" />
                  </div>
                </div>

                <LocationInputInternationalFormatV1
                componentTargetUser={this.props.componentTargetUser}
                countryFormLayout={this.state.postageCountry}
                onChange={this.handleLocationInputChange}

                identityId={identityId}
                identityInputValue={identityInputValue}
                identityType={identityType} 
                identityLocked={identityLocked} 
                verificationStatus={verificationStatus}
                notifyThisDevice={notifyThisDevice}
                dataSet={dataSet}
                apiEndpoint={apiEndpoint}
                businessId={businessId}
                stateIdentifier={stateIdentifier}
                identifiers={identifiers}
                />
              </div>
            }
            
          </div>
        </div>
          


          <div className={classNames({ flex: true })}>

              {this.state.savingData && 
                  <div className={classNames({ flex: true })}>
                    <div className={classNames({ShcIdentityInput_verifiedText: true})}>SAVING</div>
                    <div>
                      <i className={`fas fa-circle-notch fa-spin ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                      aria-label="Verified"
                      ></i>
                    </div>
                  </div>
              }




              {this.props.verificationStatus && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_verifiedText: true})}>VERIFIED</div>
                  <div>
                    <i className={`fas fa-check-circle ${classNames({ShcIdentityInput_verifiedIcon: true})}`}
                    aria-label="Verified"
                    ></i>
                  </div>
                </div>
              }

              {!this.props.verificationStatus && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>NOT VERIFIED</div>
                  <div>
                    <i className={`fas fa-exclamation-circle ${classNames({ShcIdentityInput_notVerifiedIcon: true})}`}
                    aria-label="Not Verified"
                    ></i>
                  </div>
                </div>
              }





  
             

              {this.state.identityReVerify && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_reVerifyText: true})}>RE-VERIFY by 18/07/2019</div>
                  <div>
                    <i className={`fas fa-info-circle ${classNames({ShcIdentityInput_reVerifyIcon: true})}`}
                    aria-label="Verification Expiring Soon"
                    ></i>
                  </div>
                </div>
              }

              {this.state.identityInputErrors && 
                <div className={classNames({ flex: true })}>
                  <div className={classNames({ShcIdentityInput_notVerifiedText: true})}>INVALID INPUT</div>
                  <div>
                    <i className={`fas fa-exclamation-triangle ${classNames({ShcIdentityInput_notVerifiedIcon: true, padR10: true})}`}
                      aria-label="Invalid Input"
                    ></i>
                  </div>
                </div>
              }
              
     
            </div>



      </div>

    );
  }
}




IdentityManagementPrimaryLocationInputContainerV2.defaultProps = {
  identityLocked: false,
};


IdentityManagementPrimaryLocationInputContainerV2.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 

  let dataSavingReference = '';    //we cannot access state to determine which transaction here.
  try {
    dataSavingReference = state.Sync.dataRecordUpdates;
  } catch (e) {
    //console.error('mapStateToProps | Unable to determine the status of the sync data');
  }

  return {
    dataSavingReference,
  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IdentityManagementPrimaryLocationInputContainerV2);
