import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';


// Routing
//import Routes from 'Core/Routes';

// Material UI
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components
import { MenuAccount, MenuAdmin } from 'Components/Menu';
import ListItemCollapse from 'Components/ListItemCollapse';
import { SidebarNavLinks } from 'Components/ShcHeader';

import { SimpleDropdown } from 'Components/ShcMenus';
import { StandardBtn } from 'Components/ShcButtons';
import { StandardText } from 'Components/ShcText';

// Actions
import { sidebarOpenState } from 'Actions';





const drawerWidth = 240;


class ShcHeader extends React.Component {
  state = {
    open: false,
    auth: true,
    anchorEl: null,
    isAuthenticated: null,
    isAdmin: null,
  };

  componentDidMount() {
    this.props.sidebarOpenState(false);
  }
  


  handleDrawerOpen = () => {
    //console.log('handleDrawerOpen - set to open:true');
    this.setState({ open: true });
    this.props.sidebarOpenState(true);
  };

  handleDrawerClose = () => {
    //console.log('handleDrawerClose - set to open:false');
    this.setState({ open: false });
    this.props.sidebarOpenState(false);
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = event => {
    //let anchor = this.props.anchor;
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    //let anchor = this.props.anchor;
    this.setState({ anchorEl: null });
  };

  onMouseEnter = event => {
    //console.log('onMouseEnter');
    if (this.state.open === false) {
      //this.handleDrawerOpen();
    }
    return;
  };
  onMouseLeave = () => {
    if (this.state.open === true) {
      //this.handleDrawerClose();
    }
    return;
  };

  render() {
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const forceUserRegistration = this.props.forceUserRegistration;



    const fixedView = this.props.fixedView;
    const contentPadding = this.props.contentPadding;


    const mainAdminMenu = [
      {
        title: 'Admin Dashboard',
        link: '/admin/dashboard',
      },
      {
        title: 'Test Page',
        link: '/test',
      },
      {
        title: 'testing-graphs',
        link: '/testing-graphs',
      },
      {
        title: 'uitesting',
        link: '/uitesting',
      },
      {
        title: 'ui styles',
        link: '/admin/settings/ui/styles',
      },
      {
        title: 'admin settings',
        link: '/admin/settings',
      },
      {
        title: 'login',
        link: '/login',
      },
      {
        title: 'account dashboard',
        link: '/account/dashboard',
      },
      {
        title: 'property-portfolio',
        link: '/property-portfolio',
      },
      {
        title: 'dev plans',
        link: '/pricing/real-estate-developer-plans',
      },
      {
        title: 'biz plans',
        link: '/pricing/business-plans',
      },
      {
        title: 'investor plans',
        link: '/pricing/investor-plans',
      },
      {
        title: 'agency plans',
        link: '/pricing/agency-plans',
      },
      {
        title: 'pricing',
        link: '/pricing',
      },
      {
        title: 'checkout',
        link: '/checkout',
      },
      {
        title: 'cart',
        link: '/cart',
      },
      {
        title: 'catalogue/item',
        link: '/catalogue/item',
      },
      {
        title: 'catalogue',
        link: '/catalogue',
      },
      {
        title: 'landing',
        link: '/landing',
      },
    ];

    const MenuItemUserAccount = () => {
      return (
        <div className={classNames({ShcMenuItemHeaderContainer: true})}>
          <div className={classNames({text_h4: true, color_primary_dark: true})}>Selby</div>
          <div className={classNames({text_h5: true, color_primary_dark: true})}>Selby.Steer@gmail.com</div>
        </div>
      )
    }

    const mainUserMenuAuth = [
      {
        title: <MenuItemUserAccount/>,
        link: '/account/dashboard',
        icon: 'fas fa-user-circle',
        divideAfter: true,
      },
      {
        title: 'Property Search',
        link: '/property/search/leaflet',
        icon: 'fas fa-search',
      },
      {
        title: 'Messages',
        link: '/account/dashboard',
        icon: 'far fa-envelope',
      },
      {
        title: 'My Portfolio',
        link: '/property-portfolio',
        icon: 'fas fa-warehouse',
      },
      {
        title: 'Saved Searches & Alerts',
        link: '/account/dashboard',
        icon: 'fas fa-home',
      },
      {
        title: 'My Profile',
        link: '/account/profile',
        icon: 'fas fa-user-tag',
      },
      {
        title: 'Sign Out',
        link: '/account/dashboard',
        icon: 'fas fa-home',
        divideBefore: true,
      },
    ];

    const mainUserMenuNoAuth = [
      {
        title: 'Features',
        link: '/property/search/leaflet',
        icon: 'fas fa-search',
      },
      {
        title: 'Pricing',
        link: '/property/search/leaflet',
        icon: 'fas fa-search',
      },
      {
        title: 'Insights',
        link: '/property/search/leaflet',
        icon: 'fas fa-search',
      },
      {
        title: 'Connect',
        link: '/property/search/leaflet',
        icon: 'fas fa-search',
        divideAfter: true,
      },
      {
        title: 'Create a free account',
        link: '/account/dashboard',
        icon: 'fas fa-user-circle',
      },
      {
        title: 'Free property listing',
        link: '/account/dashboard',
        icon: 'fas fa-home',
      },
      {
        title: 'Log In / Sign Up',
        link: '/login',
        icon: 'fas fa-user-circle',
        divideBefore: true,
      },
    ];

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";
    
    return (
      <div className={classNames({shcHeader:true})}>
        <div className={classNames({shcHeader_AppBar:true})}>
          <div className={classNames({shcHeader_toolbar:true})} disableGutters={!this.state.open}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          >
            <div className={classNames({shcHeader_iconContainer:true})}>
              <i 
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={`${classIconBars} ${classNames({shcHeader_NavIcon: true,  hide: this.state.open})}`}
              ></i>

              <i 
              aria-label="close drawer"
              onClick={this.handleDrawerClose}
              className={`${classIconAngleLeft} ${classNames({shcHeader_NavIcon: true, hide: !this.state.open})}`}
              ></i>
            </div>

            <div className={classNames({shcHeader_Title:true})}>
              TrustID
            </div>
        


            {isAuthenticated ? (
              <div className={classNames({shcHeader_navBarControls:true, text_h5: true})}>

                {forceUserRegistration ? "FILL OUT YOUR PROFILE!" : ""}

                <StandardText
                  icon="fas fa-bell"
                  iconLocation=""
                  text=""
                  tooltip=""
                  action=""
                  classes="shctext shctext-default-white shctext-rounded text_s16"
                />

                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuNoAuth}>
                  <StandardText
                    icon="fas fa-th"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>

                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuAuth}>
                  <StandardText
                    icon="fas fa-user-circle"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>

                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="" 
                  menuTheme="primaryUserAccount" menuItems={mainAdminMenu}>
                  <StandardText
                    icon="fas fa-unlock-alt"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>
                
                {isAdmin && (
                  <SimpleDropdown 
                    className={classNames({text_h3: true})}
                    debug={false} menuWidth={220} menuFormat="" 
                    menuTheme="primaryUserAccount" menuItems={mainAdminMenu}>
                    <StandardText
                      icon="fas fa-unlock-alt"
                      iconLocation=""
                      text=""
                      tooltip=""
                      action=""
                      classes="shctext shctext-default-white shctext-rounded text_s16"
                    />
                  </SimpleDropdown>
              )}
              </div>
            ) : (
              <div className={classNames({shcHeader_navBarControls:true, text_h5: true})}>
                
                <Button>Sign Up</Button>

                <SimpleDropdown 
                  className={classNames({text_h3: true})}
                  debug={false} menuWidth={220} menuFormat="icons" 
                  menuTheme="primaryUserAccount" menuItems={mainUserMenuNoAuth}>
                  <StandardText
                    icon="fas fa-th"
                    iconLocation=""
                    text=""
                    tooltip=""
                    action=""
                    classes="shctext shctext-default-white shctext-rounded text_s16"
                  />
                </SimpleDropdown>
              </div>
            )}

          </div>
        </div>










        
        <Hidden mdUp>
          <div className={classNames({shcHeader_drawerPaperTemporary:this.state.open, shcHeader_drawerPaperTemporaryClose: !this.state.open})}
          variant="temporary"
          open={this.state.open}
          >
            <Scrollbars 
              className={classNames({shcHeader_navigationSideBar: true, scrollbarsStyle: true})}
              renderThumbVertical={({ style, ...props }) =>
                <div {...props} style={{ ...style,}} />
              }
              renderTrackHorizontal={props => <div {...props} className={classNames({shcHeader_sidebarTrackHorizontal:true})}/>}
              >
              <Divider />
              <List><SidebarNavLinks/></List>
              <Divider />
              
              <List></List>
            </Scrollbars>
          </div>
        </Hidden>
        <Hidden smDown implementation="css">
            <div className={classNames({shcHeader_drawerPaper:true, drawerPaperPermanent:true, shcHeader_drawerPaperClose: !this.state.open})}
            variant="permanent"
            open={this.state.open}
            >
              <Scrollbars 
                className={classNames({shcHeader_navigationSideBar:true, scrollbarsStyle: true, shcHeader_drawerPaperClose: !this.state.open})}
                renderThumbVertical={({ style, ...props }) =>
                  <div {...props} style={{ ...style,}} />
                }
                renderTrackHorizontal={props => <div {...props} className={classNames({shcHeader_sidebarTrackHorizontal:true})}/>}
                hideTracksWhenNotNeeded
                >
                <Divider />
                <List><SidebarNavLinks/></List>
                <Divider />
                
                <List></List>
              </Scrollbars>
              
            </div>
            
        </Hidden>
        
        <main className={classNames({
          shcHeader_contentSection: true,
          shcHeader_contentMarginPermanent:true, 
          shcHeader_contentPadding: !this.props.fixedView, 
          shcHeader_fixedView: this.props.fixedView, 
          shcHeader_contentMarginOpen: this.state.open})}>
          <div className={classNames({shcHeader_toolbarDrawer:true})}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave} />
            
            {this.props.children}
        </main>
      </div>
    );
  }
}

ShcHeader.propTypes = {

};


const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
  forceUserRegistration: state.User.forceUserRegistration,

})

const mapDispatchToProps = {
  sidebarOpenState,
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcHeader);