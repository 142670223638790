import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

// Routing

// Material UI

// CSS
import componentStyles from './Styles.css';

// Components

// Actions

// Store


class ReactTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentWillMount() {
    // console.log('WillUpdate - UserSelectionDropdown');
  }

  componentDidMount() {
    // console.log('DidMount - UserSelectionDropdown');
  }
  componentWillUpdate() {
    // console.log('WillUpdate - UserSelectionDropdown');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('DidUpdate - UserSelectionDropdown');
  }

  render() {
    // State

    // Props

    const data = [
      {
        name: 'Tanner Linsley',
        age: 26,
        friend: {
          name: 'Jason Maurer',
          age: 26,
        },
      },
      {
        name: 'Tanner Linsley',
        age: 27,
        friend: {
          name: 'Jason Maurer',
          age: 27,
        },
      },
      {
        name: 'Tanner Linsley',
        age: 28,
        friend: {
          name: 'Jason Maurer',
          age: 23,
        },
      },
      {
        name: 'Tanner Linsleyz',
        age: 29,
        friend: {
          name: 'Lason Maurer',
          age: 20,
        },
      },
      {
        name: 'Tanner Linsley',
        age: 26,
        friend: {
          name: 'Jason Brown',
          age: 20,
        },
      },
      {
        name: 'Aanner Linsley',
        age: 26,
        friend: {
          name: 'Jason Maurer',
          age: 23,
        },
      },
      {
        name: 'Banner Linsley',
        age: 26,
        friend: {
          name: 'Zason Maurer',
          age: 23,
        },
      },
      {
        name: 'Canner Linsley',
        age: 26,
        friend: {
          name: 'Dason Maurer',
          age: 23,
        },
      },
    ];

    const columns = [{
      Header: 'Name',
      accessor: 'name',
      maxWidth: 200,
    }, {
      Header: 'Age',
      accessor: 'age',
      Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      maxWidth: 200,
    }, {
      id: 'friendName', // Required because our accessor is not a string
      Header: 'Friend Name',
      accessor: d => d.friend.name, // Custom value accessors!
    }, {
      Header: props => <span>Friend Age</span>, // Custom header components!
      accessor: 'friend.age',
    }];

    return (
      <div>
        <ReactTable
          data={data}
          columns={columns}
          filterable
          defaultPageSize={10}
        />
      </div>
    );
  }
}

ReactTables.defaultProps = {

};

const mapStateToProps = state => ({
});
const mapDispatchToProps = {

};
export default compose(connect(mapStateToProps, mapDispatchToProps))(ReactTables);
