import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

// Routing


// CSS
import componentStyles from './styles.css';

// Components
import { SidebarSubNavBack } from 'Components/ShcHeader';

// Actions



class SidebarSubNav extends React.Component {
  state = {
    open: false,
  };

  togglePanelNav = () => {
    //console.log('togglePanelNav - toggle the open state');
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";

    const {
      urlmatch, backLinkTF, backTo, backTitle
    } = this.props;

    const BackLinkSection = (props) => {
      if (backLinkTF === 'true') {
        return (
          <SidebarSubNavBack to={backTo} title={backTitle} urlmatch={urlmatch} showText={props.showText} layout={props.layout} opened={props.opened} MenuCloseIconSmall={MenuCloseIconSmall} />
        );
      }
      return null;
    };

    const BankLinkIconSmallText = () => {
      if (backLinkTF === 'true') {
        return (
          <div className={classNames({SidebarSubNav_navIconContainerAndText:true})}>
            <div className={classNames({SidebarSubNav_iconItem:true, flexGrow: this.state.open})}>
              <BackLinkSection showText={true}/>
            </div>
          </div>
        );
      }
      return null;
    }

    const BankLinkIconSmall = (props) => {
      if (backLinkTF === 'true') {
        return (
          <div className={classNames({SidebarSubNav_iconContainerClosed: !this.state.open, SidebarSubNav_iconContainerOpened: this.state.open})}>
            <div className={classNames({SidebarSubNav_iconItem:true, flexGrow: this.state.open})}>
              <BackLinkSection showText={props.showText} layout={props.layout} opened={this.state.open}/>
            </div>
          </div>
        );
      }
      return null;
    }

    const BankLinkIconLarge = (props) => {
      if (backLinkTF === 'true') {
        return (
          <div className={classNames({SidebarSubNav_iconContainerClosed: false, SidebarSubNav_iconContainerOpened: true})}>
            <div className={classNames({SidebarSubNav_iconItem:true, flexGrow: this.state.open})}>
              <BackLinkSection showText={props.showText} layout={props.layout} opened={true}/>
            </div>
          </div>
        );
      }
      return null;
    }

    


    const MenuOpenIconSmall = () => {
      return (
        <div className={classNames({SidebarSubNav_iconContainerClosed:true})}>
          <div className={classNames({SidebarSubNav_iconItem:true})}
            onClick={this.togglePanelNav} >
            <i 
            aria-label="open drawer"
            className={`${classIconBars} ${classNames({text_s20: true,  hide: this.state.open})}`}
            ></i>
          </div>
        </div>
      );
    }

    const MenuCloseIconSmall = () => {
      return (
        <div className={classNames({SidebarSubNav_iconContainerClosed:true})}>
          <div className={classNames({SidebarSubNav_iconItem:true})}
            onClick={this.togglePanelNav} >
            <i 
            aria-label="close drawer"
            className={`${classIconAngleLeft} ${classNames({text_s20: true,  hide: !this.state.open})}`}
            ></i>
          </div>
        </div>
      );
    }


    
    

    return (
      <div>
        {this.props.windowWidth < 460 ? (
          <div>
            <div className={classNames({ SidebarSubNav_panel_small: true })}>
              <div className={classNames({ SidebarSubNav_navigation_small: true, SidebarSubNav_navigation_overlayContainer_small: this.state.open })}>
                <div className={classNames({SidebarSubNav_display: true, SidebarSubNav_navigation_narrow: true })}>

                  <div className={classNames({SidebarSubNav_iconContainerAll:true, flex:true})}>

                    {this.state.open === true ?
                    (
                      <div className={classNames({displayInherit: true})}>
                        <MenuCloseIconSmall/>
                        <BankLinkIconSmallText/>
                      </div>
                    )
                    :
                    (
                      <div className={classNames({displayInherit: true})}>
                        <MenuOpenIconSmall/>
                        <BankLinkIconSmallText/>
                      </div>
                    )}
                  </div>

                  
                </div>

                <div className={classNames({ SidebarSubNav_navigation_expanded_small: true, hide: !this.state.open })}>
                    {this.props.children[0]}
                  </div>

              </div>
            </div>
            <div className={classNames({ SidebarSubNav_foldContent: true })}>
              {this.props.children[1]}
            </div>
          </div>
        ):(
          <div>
            {this.props.windowWidth >= 960 ? (
              <div className={classNames({ selbyGreater960: true, SidebarSubNav_panel_large: true })}>
                
                <div className={classNames({ SidebarSubNav_navigation_container: true, SidebarSubNav_navigation_container_small: false, SidebarSubNav_navigation_container_large: true, })}>

                
                  <div className={classNames({ SidebarSubNav_navigation_narrow: false, SidebarSubNav_navigation_expanded_large: true, })}>

                    <div className={classNames({SidebarSubNav_iconContainerAll:true, flex:true})}>
                      <div className={classNames({displayInherit: true})}>
                        <BankLinkIconLarge showText={true}/>
                      </div>
                    </div>


                    <div className={classNames({ aaa: true, })}>
                      {this.props.children[0]}
                    </div>
                  </div>
                </div>
                
                <div className={classNames({ SidebarSubNav_foldContent: true })}>
                  {this.props.children[1]}
                </div>
              </div>
            ):(
              <div className={classNames({ selbyLessThan960: true, SidebarSubNav_panel_large: true })}>
                {this.state.open === true ? (
                  <div className={classNames({ selbyLessThan960_open: true, SidebarSubNav_navigation_container: true, SidebarSubNav_navigation_container_small: false, SidebarSubNav_navigation_container_large: true, })}>

                  
                    <div className={classNames({ SidebarSubNav_navigation_narrow: false, SidebarSubNav_navigation_expanded_large: true, })}>

                      <div className={classNames({SidebarSubNav_iconContainerAll:true})}>

                        {this.state.open === true ?
                        (
                          <div className={classNames({displayInherit: true})}>
                            <BankLinkIconSmall layout="vertical" showText={true}/>
                
                          </div>
                        )
                        :
                        (
                          <div className={classNames({displayInherit: true})}>
                            <BankLinkIconSmall layout="vertical" showText={false}/>
                            <MenuOpenIconSmall/>
                            
                          </div>
                        )}

                      </div>


                      <div className={classNames({ aaa: true, })}>
                        {this.props.children[0]}
                      </div>
                    </div>
                  </div>
                ):(
                  <div className={classNames({ selbyLessThan960_closed: true, 
                  SidebarSubNav_navigation_container: true, 
                  SidebarSubNav_navigation_container_small: true, 
                  SidebarSubNav_navigation_container_large: false, })}>
                    <div className={classNames({ SidebarSubNav_navigation_expanded_large: false, 
                      SidebarSubNav_navigation_narrow: true, })}>
                    
                    
                      {this.state.open === true ?
                      (
                        <div className={classNames({displayInherit: true})}>
                          <BankLinkIconSmall layout="vertical" showText={true}/>
              
                          {this.props.children[0]}
                        </div>
                      ):(
                        <div className={classNames({displayInherit: true})}>
                          <BankLinkIconSmall layout="vertical" showText={false}/>
                          <MenuOpenIconSmall/>
                          
                        </div>
                      )}



                    
                  </div>
                </div>
                )}
                <div className={classNames({ SidebarSubNav_foldContent: true })}>
                  {this.props.children[1]}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
 
    )
  }
}

SidebarSubNav.propTypes = {

};


const mapStateToProps = (state, ownProps) => {
  let windowSizeWidth = 720;
  let windowSizeHeight = 360;
  try {
    windowSizeWidth = state.UI.windowSize.windowWidth;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window width');
  }
  try {
    windowSizeHeight = state.UI.windowSize.windowHeight;
  } catch (e) {
    console.error('mapStateToProps | Unable to get window height');
  }

  return {
    windowWidth: windowSizeWidth,
    windowHeight: windowSizeHeight,  
  };

};

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(SidebarSubNav);


/*
{this.state.open === true ?
(
  <div className={classNames({SidebarSubNav_iconContainerOpened:true, flex: true})}>
    <div className={classNames({aaa:true})}>
      <BackLinkSection/>
    </div>
    <div className={classNames({aaa:true})}>
      <div className={classNames({SidebarSubNav_iconItem:true})}
        onClick={this.togglePanelNav} >
        <i 
        aria-label="close drawer"
        className={`${classIconAngleLeft} ${classNames({SidebarSubNav_NavIcon: true,  hide: !this.state.open})}`}
        ></i>
      </div>
    </div>
  </div>
)
:
(
  <div className={classNames({SidebarSubNav_iconContainerClosed:true})}>
    <div className={classNames({SidebarSubNav_iconItem:true})}
      onClick={this.togglePanelNav} >
      <i 
      aria-label="open drawer"
      className={`${classIconBars} ${classNames({SidebarSubNav_NavIcon: true,  hide: this.state.open})}`}
      ></i>
    </div>
  </div>
)}
*/