import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class MessageTip extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className={classNames({ ShcTextMessageTip: true })}>
        {this.props.children}
      </div>

    );
  }
}

MessageTip.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MessageTip);
