import React, { useContext } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import Moment from 'react-moment';


import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';


// CSS
import componentStyles from './styles.css';

// Components - CORE
import { ErrorMessageBasic } from 'Components/ShcErrors';

// Components - OTHER
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { StandardText } from 'Components/ShcText';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardApiBtn } from 'Components/ShcButtons';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';

import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, CardMiniS1, CardMiniS2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
import {
  apiDataUpdate, resetReduxState, changeReduxState, testUpdateReduxState, test2UpdateReduxState, test2aUpdateReduxAction, test3UpdateReduxAction, test4UpdateReduxAction,
  RemoveReduxState, InsertIntoRedux,

  socketConnection,
  socketSend,
} from 'Actions';

// Functions
import { validateEmail, apiDataTransferTimer } from 'Helpers/Functions';


// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/********************************************
 * HEALTH MONITORING
 * Code Version (Need an endpoint for the current version, next update, etc - 
 * could be handled via Auth Endpoint as we are already doing regular updates)
 * 
 * 
 * 
 ********************************************/




class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);

    this.stripUnixMilliseconds = this.stripUnixMilliseconds.bind(this);

    this.state = {
      hasError: false, // Error boundary (update UI to error message)
      apiTimerCollection: [], // List of active timers.
    };

  }

  /** **************************************************
   * REACT LIFECYCLE COMPONENTS
   *************************************************** */

  componentDidMount() {
    const thisComponent = this.constructor.name;
    //console.log(`componentDidMount: ${thisComponent}`);

    
    this.props.socketConnection();



    

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const thisComponent = this.constructor.name;
    //console.log(`componentDidUpdate: ${thisComponent}`);
  }
  componentWillUnmount() {
    //console.log('componentWillUnmount');
    const thisComponent = this.constructor.name;
    //console.log(`componentWillUnmount: ${thisComponent}`);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('getDerivedStateFromProps');
    // const thisComponent = this.constructor.name;
    // console.log(`Component getDerivedStateFromProps: ${thisComponent}`);
    return null;
  }
  static getDerivedStateFromError(error) {
    //console.log('getDerivedStateFromError');
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  stripUnixMilliseconds(millisecondsUnixTimestamp) {
    //console.log(millisecondsUnixTimestamp);
    let timeValue = millisecondsUnixTimestamp;
    try {
      //parse to an int (if this works, its a number)
      timeValue = parseInt(millisecondsUnixTimestamp);
      if (timeValue.length >= 13) {
        //time is in milliseconds, convert to seconds.
        timeValue = timeValue.slice(0, -3);
        //console.log(timeValue);
      }
    } catch(e) {
    }

    return timeValue;
  }

  wssSendMessage() {
    this.props.socketSend(); 
  }

  /** **************************************************
   * COMPONENT FUNCTIONS
   *************************************************** */

  render() {
    const { apiFailureCount, ApiErrors } = this.props.apiActions;

    const api4xx = ApiErrors.E400 + ApiErrors.E401 + ApiErrors.E403 +
    ApiErrors.E404 + ApiErrors.E405 + ApiErrors.E408 + ApiErrors.E429;

    const api5xx = ApiErrors.E500 + ApiErrors.E502 + ApiErrors.E503 +
    ApiErrors.E504;

    //console.log("============= CONTEXT ==============");
    //console.log(this.context);

    let value = this.context;
    //console.log(value);

    if (this.state.hasError) {
      return <ErrorMessageBasic />;
    }

    let timeNow = '';
    try {
      //parse to an int (if this works, its a number)
      timeNow = parseInt(Date.now());
      if (timeNow.length >= 13) {
        //time is in milliseconds, convert to seconds.
        timeNow = parseInt(this.stripUnixMilliseconds(timeNow.toString()));
      }
    } catch(e) {
      timeNow = Date.now();
    }
    //console.log("TimeNow");
    //console.log(timeNow);


    //Should be a minimum of 8 characters for a salt.
    let sha512_1 = sha512("selbs01");
    let sha512_2 = sha512("selbs01:GUARD");
    let sha512_3 = sha512("12birkallastreetbulimbaqld4171australia");
    let sha512_4 = sha512("12birkallastreetbulimbaqld4171australia:GUARD");
    let sha512_5 = sha512("12birkallastreetbulimbaqld4171australia:GUARDPOINT");

    console.log("================ HASHES ================");
    console.log(sha512_1);
    console.log(sha512_2);
    console.log(sha512_3);
    console.log(sha512_4);

    console.log("================ HASHES (stringify) ================");
    console.log(Base64.stringify(sha512_1));
    console.log(Base64.stringify(sha512_2));
    console.log(Base64.stringify(sha512_3));
    console.log(Base64.stringify(sha512_4));
    console.log(Base64.stringify(sha512_5));

    /*
    Round 1 - sha512
    mzi9ihEeBzR3Q/g8g3N31m9NjgPEO9qqcWwd+Lp5t5A=
    9OkR0TkkPhfZyNky5hwHzIuTKt34qlByJpLZkvWNA/s=
    U7PPG2Rpb0nxXf2xeL1jD1B879QDvuFgLGZBYKlIcKk=
    cyVLzXgQpv2kn1zf6x7v1YXjSlMeza7xMd6iBda0gyo=

    Round 2 - sha512 (consisent)
    mzi9ihEeBzR3Q/g8g3N31m9NjgPEO9qqcWwd+Lp5t5A=
    9OkR0TkkPhfZyNky5hwHzIuTKt34qlByJpLZkvWNA/s=
    U7PPG2Rpb0nxXf2xeL1jD1B879QDvuFgLGZBYKlIcKk=
    cyVLzXgQpv2kn1zf6x7v1YXjSlMeza7xMd6iBda0gyo=
    Ums7cldS6p40ChBAUvkgTWhpX6JiQGrByXsMNn6OWME=
  

    */


    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/support"
        backTitle="Back"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/"
                backTitle="Back"
                smallTitleTF="true"
                smallTitle=""
                headlineTitleTF="true"
                headlineTitle=""
                sublineTextTF="true"
                sublineText=""
                sectionTitle="Support Centre"
                linkGroupSelectionTF="true"
                linkGroupSelection="support"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>

            <div>
              <InputCard headlineText="Connectivity" headlineSubText="">
                
                <div className={classNames({ flex: true })}>
                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="Connectivity"
                    className={classNames({ contentPad10: true })}
                  >
                    {this.props.apiActions.ApiFailureCount}
                  </CardMiniS1>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="Connectivity Test"
                    className={classNames({ contentPad10: true })}
                    size="h4"
                    weight="bold"
                  >
                    <StandardApiBtn
                      apiActivityId="HealthConnectivityTest"
                      icon=""
                      iconLocation=""
                      text="Run Test"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-small shcbtn-primary"
                      handleClick={this.wssSendMessage.bind(this)}
                    />
                  </CardMiniS1>
                </div>
              
              </InputCard>
            </div>

            <div>
              <InputCard headlineText="API Statistics" headlineSubText="">
                
                <div className={classNames({ flex: true })}>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="API Successes"
                    className={classNames({ contentPad10: true })}
                  >
                    {this.props.apiActions.ApiSuccessCount}
                  </CardMiniS1>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="API Failures"
                    className={classNames({ contentPad10: true })}
                  >
                    {this.props.apiActions.ApiFailureCount}
                  </CardMiniS1>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="API 4XX Error Counts"
                    className={classNames({ contentPad10: true })}
                  >
                    {api4xx}
                  </CardMiniS1>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="API 5XX Error Counts"
                    className={classNames({ contentPad10: true })}
                  >
                    {api5xx}
                  </CardMiniS1>

                </div>
              
              </InputCard>
            </div>

            <div>
              <InputCard headlineText="Authentication" headlineSubText="">
                
                <div className={classNames({ flex: true })}>
                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="Access Token Expires"
                    className={classNames({ contentPad10: true })}
                    size="h4"
                    weight="bold"
                  >
                    {this.props.auth.apiAccessTokenExpiresOn !== '' ?
                      <Moment unix>{this.stripUnixMilliseconds(this.props.auth.apiAccessTokenExpiresOn)}</Moment>
                    :
                      <div>Not set</div>
                    }

                  </CardMiniS1>

                  <CardMiniS1
                    padding="none"
                    margin=""
                    headline="Refresh Token Expires"
                    className={classNames({ contentPad10: true })}
                    size="h4"
                    weight="bold"
                  >
                    {this.props.auth.apiRefreshTokenExpiresOn !== '' ?
                      <Moment unix>{this.stripUnixMilliseconds(this.props.auth.apiRefreshTokenExpiresOn)}</Moment>
                    :
                      <div>Not set</div>
                    }
                  </CardMiniS1>


                </div>
                  
              
              </InputCard>
            </div>


            <br/><br /><br />

            
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

/*
Round 1 (random salt)
$2a$10$oTf4US4JWB6u8Hbv7fI9IuhmeJsEQ4CC2SUvyTmufeCWVC3lNbdQO
$2a$10$oTf4US4JWB6u8Hbv7fI9IuVTYO436wKFeJRlguiEgP/kCwjvpTq2i
$2a$10$oTf4US4JWB6u8Hbv7fI9Iu7JXpeb.YPj3rF1utI8xgEMPYyaqw57y
$2a$10$oTf4US4JWB6u8Hbv7fI9IueJu6pmgjTnix03PViqKFn3Bo.cg.v6u

Round 2 (random salt)
$2a$10$8vbaxjtNwgcVQxnxev3wSOe6dbE9QdHG.9qGcxEr95p7r6UlYvn7u
$2a$10$8vbaxjtNwgcVQxnxev3wSOqVGri7gKrF/P9EL4o1XQ0229.snbSZe
$2a$10$8vbaxjtNwgcVQxnxev3wSOPcb/SlG/2iuhLNJvDjB/ktQmbYVb9v2
$2a$10$8vbaxjtNwgcVQxnxev3wSOrPWibDAgjgbx1jcPFcVp9PKjvvrFT5u


*/


Template.propTypes = {
};


const mapStateToProps = state => ({
  apiActions: state.ApiActions,
  auth: state.Auth,
});

const mapDispatchToProps = {
  socketConnection,
  socketSend,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
