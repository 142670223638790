import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';


// Routing

// Material UI
import TextField from '@material-ui/core/TextField';

// CSS
import componentStyles from './styles.css';

// Components


// Actions
import { mapSearchPopupPanelSuburb, MapSearchApi } from 'Actions';

// APIs
//import { MapSearch } from 'Api';




// tip, message, action

//https://stackoverflow.com/questions/42036865/react-how-to-navigate-through-list-by-arrow-keys

/*
TO DO
> on click of value list item, reposition focus to the text box


> basic tag list, use comma to separate tags automatically (simple Select Input)
> Chips have ID's that can be used to do future searches (id, text, formatting style (bold) etc)
> Pass in paramaters for the component
  > chipTextListApi     (controlled input for lookups)
  > chipText            (free text for tagging)
  > ?others?

> Pass through styling on the component for some basic theme options (e.g. for header bar, page content, small size)
> Options for size of text, layout and other data fields (Photo - name - phone number) <-- example


*/
/*
<SelectInputChips 
  placeholderText="Enter some text"
  maxChips=10
  maxChipDisplay=2
  prePopulatedTextField=""

  
  minTextBeforeSearch=3
  searchApi=getSuburbListByTextSearch
  
  />

  handler to get the data from the chips when required.

*/

class SelectInputChips extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    
    this.state = {         
      chips: [],                //Array of chips / tags / search selections
      maxChipListItems: 2,      //Max chips that will be displayed, before hiding
      expandValueList: false,   //Displaying the search results panel
      valueListData: [],        //Search results data provided back from api
      popupChipList: false,     //Popup Chip List for small displays
      cursor: -1,               //Cursor position in list. -1 is textbox.
      smartSelectTextField: "", //Text Input
      placeholderText: "Enter some text",     //Paramater passed in via props
    };
  }

  componentDidMount(props) {
    console.log('componentDidMount.');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate.');
  }

  
  
  onClickRemoveChip(evt, itemIndex) {
    console.log('remove chip. Index: '+itemIndex);
    console.log(evt);
    let chipsArray = [...this.state.chips]; // make a separate copy of the array
    chipsArray.splice(itemIndex, 1);
    this.setState({chips: chipsArray});
    evt.stopPropagation();
  }

  onChange = (evt) => {
    console.log('onChange event');
    this.setState({
      cursor: -1,
      smartSelectTextField: evt.target.value,
      popupChipList: false,
    });    

    console.log(evt.target.value);
    console.log('attempting to run function getSuburbListByTextSearch');
    console.log(MapSearchApi.getSuburbListByTextSearch(evt.target.value));
    console.log('passed the running of getSuburbListByTextSearch....');

    MapSearchApi.getSuburbListByTextSearch(evt.target.value)
    .then ((results) => {
      console.log("got results back");
      console.log(results.data);
      this.setState({
        valueListData: results.data,
        expandValueList: true
      });

    })

    console.log(this.smartSelectTextFieldRef);
  } 


  addItemToChips(chipItem) {
    let chipsArray = [...this.state.chips]; // make a separate copy of the array
    chipsArray.push(chipItem);
    this.setState({
      chips: chipsArray,
      smartSelectTextField: '',
      cursor: -1,
      expandValueList: false,
    });
  }

  toggleValueList = (evt) => {
    this.setState(prevState => ({
      expandValueList: !prevState.expandValueList
    }));
  }

  toggleChipPopupList = (evt) => {
    console.log('toggle the popup list');
    console.log(this.state.popupChipList);
    this.setState(prevState => ({
      popupChipList: !prevState.popupChipList
    }));
  }


  
  handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      console.log('do validate');

      if (this.state.cursor >=0) {
        console.log('Enter pressed, and user has a list item selected, create chip and remove text from textbox');
        console.log( this.state.valueListData.slice(this.state.cursor, this.state.cursor+1) );
        let selectedValueListItem = this.state.valueListData.slice(this.state.cursor, this.state.cursor+1);
        let selectedValueListItemName = selectedValueListItem[0].name;

        this.addItemToChips(selectedValueListItemName);
        
      } else {

        // VALIDATION
        // LOOKUP
        //this.props.mapSearchLookupSuburbName(evt.target.value);

        // ADD CHIP
        console.log(evt.target.value);
        console.log(evt);

        this.addItemToChips(evt.target.value);
        evt.target.value = "";

      }
    }
  }



  handleKeyDown = (evt) => {
    console.log('handleKeyDown event. Arrows & Cursor');
    const { cursor, valueListData } = this.state
    // arrow up/down button should select next/previous list element
    console.log(evt.keyCode);
    console.log(cursor);
    console.log(valueListData);


    if (evt.keyCode === 38 && cursor === 0) {
      console.log('Cursor returning to text box');
      //after setting state, move cursor to end of text
      console.log('up: WAS: '+ this.state.cursor);
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }), 
        () => {
          console.log(evt);
        }
      )
    }

    if (evt.keyCode === 38 && cursor > 0) {
      console.log('up: WAS: '+ this.state.cursor);
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }),
        () => {
          console.log('up: NOW: '+ this.state.cursor);
          this.updateTextFieldFromList(this.state.cursor);
        }
      )
      
    } else if (evt.keyCode === 40 && cursor < valueListData.length - 1) {
      console.log('down: WAS: '+ this.state.cursor);
      this.setState( prevState => ({
        cursor: prevState.cursor + 1,
      }),
        () => {
          console.log('down: NOW: '+ this.state.cursor);
          this.updateTextFieldFromList(this.state.cursor);
        }
      )
    }
  }



  updateTextFieldFromList(cursor) {
    let value = this.state.valueListData.slice(cursor, cursor+1);
    let valueName = value[0].name;
    console.log('update the text box with : '+valueName);
  }
  
  onClickValueListItem = (evt, index) => {
    console.log('clicked on item from value list');

    console.log( this.state.valueListData.slice(index, index+1) );
    let selectedValueListItem = this.state.valueListData.slice(index, index+1);
    let selectedValueListItemName = selectedValueListItem[0].name;

    this.addItemToChips(selectedValueListItemName);
  }


  

  render() {
    const { classes } = this.props;
    const {chips, valueListData, cursor, maxChipListItems, popupChipList } = this.state;

    const ListOfChips = () => {
      return chips.map((value, index) => {
        return (
          <div key={index} className={classNames({smartSelectChipItem: true})}>
            <div className={classNames({smartSelectChipItemText: true})}>
              {value}
            </div>
            <div className={classNames({smartSelectChipItemIcon: true})}
             onClick={(evt) => {
              this.onClickRemoveChip(evt, index);
            }}
            >
              <i class="fas fa-times-circle"></i>
            </div>
          </div>
        )
        
      })
    } 


    const TooManyChips = () => {
      return (
        <div className={classNames({smartSelectChipsPopupView: true})}>
          <div className={classNames({smartSelectChipPopupIcon: true})}>
            {this.state.popupChipList ?
              <i class="fas fa-times-circle"></i>
            :
              chips.length
            }
          </div>
        </div>
      )
    } 

    const TooManyChipsPopupList = () => {
      return chips.map((value, index) => {
        return (
          <li key={index}>
            <div  className={classNames({smartSelectChipItem: true})}>
              <div className={classNames({smartSelectChipItemText: true})}>
                {value}
              </div>
              <div className={classNames({smartSelectChipItemIcon: true})}
              onClick={(evt) => {
                this.onClickRemoveChip(evt, index);
              }}
              >
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          </li>
        )
        
      })
    }

    const ValueList = () => {
      console.log(valueListData);
      let highlight = false;
      return valueListData.map((value, index) => {
        return (
          <li key={index} className={classNames({smartSelectValueListItem: true, smartSelectValuelistItemActive: cursor === index }) }
          onClick={(evt) => {
            this.onClickValueListItem(evt, index);
          }}
          data-id={value['id']}
          >
            {value['name']}
          </li>
        )
      })
    }


  const ChipPanel = () => {
    return (
      <div className={classNames({smartSelectChips: true})}>
        {chips.length > maxChipListItems ?
          <div 
            onClick={(evt) => {
              this.toggleChipPopupList(evt);
            }}
          >
            <TooManyChips/>
          </div>
        :
          <ListOfChips/>
        }
      </div>
    )
  }

  const TextPanel = () => {
    return (
      <div className={classNames({smartSelectText: true})}>
        <TextField 
          className={classNames({smartSelectTextStyle: true})}
          fullwidth
          value={this.state.smartSelectTextField}
          InputProps={{
            disableUnderline: true,
            autoFocus: true,
            placeholder: this.state.placeholderText,
          }}
          onKeyPress={(evt) => {
            this.handleKeyPress(evt);
          }}
          onChange={(evt) => {
            this.onChange(evt);
          }}
          onKeyDown={(evt) => {
            this.handleKeyDown(evt);
          }}
        />
      </div>
    )
  }

  const ListViewControlPanel = () => {
    return (
      <div className={classNames({smartSelectControls: true})}
        onClick={(evt) => {
          this.toggleValueList(evt);
        }}
      >
      {this.state.expandValueList ?
        <i class="fas fa-angle-up"></i>
      :
        <i class="fas fa-angle-down"></i>
      }
      </div>
    )
  }

  const ValueListDropdownPanel = () => {
    return (
      <div className={classNames({hidden: !this.state.expandValueList, smartSelectWrapperValueList: true})}>
        <ul className={classNames({smartSelectValueListCollection: true})}>
          <ValueList/>
        </ul>
      </div>
    )
  }

  const ChipPopupListPanel = () => {
    return (
      <div className={classNames({hidden: !this.state.popupChipList, smartSelectWrapperChipPopupList: true,  hidden_ruleMinChipsInPopup: chips.length <= maxChipListItems })}>
          <ul className={classNames({smartSelectChipPopupListCollection: true})}>
            <TooManyChipsPopupList/>
          </ul>
      </div>
    )
  }


    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({smartSelectWrapper: true, smartSelectExpander: true})}>
          <ChipPanel/>
          <TextPanel/>
          <ListViewControlPanel/>
          <ValueListDropdownPanel/>
          <ChipPopupListPanel/>
        </div>
      </div>

    );
  }
}

SelectInputChips.propTypes = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SelectInputChips);
