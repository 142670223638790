import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class LocationSearchInputV1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      address: this.props.value,

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
    /* Attempt to get the address split into the following
    recipient.addressLine1 = "Address 1";
    recipient.addressLine2 = "Address 2";
    recipient.addressCity = "addressCity";
    recipient.addressState = "addressState";
    recipient.addressPostalCode = "code";
    recipient.addressCountry = "country";
    */
  
  handleChange = address => {
    //console.log("Handle change from PlacesAutoComplete")
    this.setState({ address });
    this.props.onChange(address);
  };
  
  handleSelect = address => {
    //console.log(address);
    this.setState({ address });
    this.props.onChange(address);





    //For geocoding
    /*
    geocodeByAddress(address)
    .then(results => {
      //console.log(results);
      
      getLatLng(results[0]);
    })
    .then(latLng => console.log('Success', latLng))
    .catch(error => console.error('Error', error));
    */
  };

  
 
  render() {
    const { onChange } = this.props;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={600}
        shouldFetchSuggestions={this.state.address.length > 5}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              className={classNames({ ShcIdentityInput_textInput: true, 
                  ShcIdentityInput_textInput_errors: this.state.identityInputErrors,
                  ShcIdentityInput_textInput_iconPaddingLeft: this.state.identityNotificationOptionsAvailable,
                })}
            />
            <div
            className={`autocomplete-dropdown-container ${classNames({ ShcLocationSearchInputDropdownStyle: true, 
            })}`}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}  
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationSearchInputV1);
