import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// CSS
import componentStyles from './styles.css';

// Components
import { StandardIcon } from 'Components/ShcButtons';

// Actions
import { setUiToggleOptions } from 'Actions';

class ToggleOptionCardReduxV1 extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.toggleSelectedItem = this.toggleSelectedItem.bind(this);

    this.state = {
      businessId: this.props.businessId,
      toggleOptionsId: this.props.toggleOptionsId,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // //console.log('getDerivedStateFromProps - ToggleOptionCardReduxV1');
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {

  }

  toggleSelectedItem() {
    //console.log('toggleSelectedItem');

    //console.log('RadioOptions | businessId');
    //console.log(this.state.businessId);

    const selection = {
      redux: this.props.redux,
      businessId: this.state.businessId,
      toggleOptionsId: this.state.toggleOptionsId,
      toggleSelectedState: !this.props.toggleSelectedState,
    };


    //console.log(selection);
    this.props.setUiToggleOptions(selection);


  }

  render() {
    const {
      businessId,
      toggleOptionsId,
      toggleText,
      redux,
    } = this.props;

    return (
      <div className={classNames({ flex: true })}>
        <div className={classNames({ aaa: true })}>
          {this.props.toggleSelectedState ?
            <StandardIcon
              icon="fas fa-toggle-on"
              classes="colour_primary"
              handleClick={this.toggleSelectedItem}
            />
          :
            <StandardIcon
              icon="fas fa-toggle-off"
              classes=""
              handleClick={this.toggleSelectedItem}
            />
          }
        </div>
        <div className={classNames({ aaa: true })}>
          { toggleText ?
            <div>
              <div className={classNames({ ShcToggleOptionCard_toggleText: true })}>
                {toggleText}
              </div>
            </div>
          : null }
        </div>
      </div>
    );
  }
}

ToggleOptionCardReduxV1.propTypes = {

};
ToggleOptionCardReduxV1.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
  // //console.log('mapStateToProps | ToggleOptionCardReduxV1');

  let toggleSelectedState = false;
  let foundMatch = false;
  // //console.log(ownProps.toggleOptionsId);
  if (state.UI.uiToggleOptions) {
    for (let z = 0; z < state.UI.uiToggleOptions.length; z++) {
      if (state.UI.uiToggleOptions[z].businessId === ownProps.businessId) {
        if (state.UI.uiToggleOptions[z].toggleOptionsId === ownProps.toggleOptionsId) { // this ID
          toggleSelectedState = state.UI.uiToggleOptions[z].toggleSelectedState;
          foundMatch = true;
        }
      }
    }
    if (foundMatch === false) {
      // //console.log('mapStateToProps | searching within user');
      // Now search without matching on buinsessId
      for (let z = 0; z < state.UI.uiToggleOptions.length; z++) {
        if (state.UI.uiToggleOptions[z].toggleOptionsId === ownProps.toggleOptionsId) { // this ID
          toggleSelectedState = state.UI.uiToggleOptions[z].toggleSelectedState;
          foundMatch = true;
        }
      }
    }
  } else {
    toggleSelectedState = false;
  }
  // //console.log('mapStateToProps | Return values');
  return {
    toggleSelectedState,
    uiToggleOptions: Object.assign([], state.UI.uiToggleOptions),
  };
};

const mapDispatchToProps = {
  setUiToggleOptions,
};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(ToggleOptionCardReduxV1);
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(ToggleOptionCardReduxV1);
