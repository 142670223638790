import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
//import { FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, LinkedInLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';

import Link from 'react-router-dom/Link';


// CSS
import componentStyles from './styles.css';

// Components
import { StandardText } from 'Components/ShcText';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { InputCard, InputCardItem } from 'Components/ShcCards';

// import { askForPermissioToReceiveNotifications } from 'Components/ShcPushNotify/push-notification';


// Actions
import { mobileAppAutoLogin, 
   removeMfaLoginRequired, updateIsAuthenticatedStatus, userAuth, } from 'Actions';
import { auth } from 'Store/client';

// Functions
import { validateEmail } from 'Helpers/Functions';

// Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function unixTimestampMillisecondsToSeconds(timestamp) {
  let num = timestamp;
  if (num.toString().length >=13) {
    //In milliseconds
    num = (num-(num%1000))/1000;
  }
  return num;
}

class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, context) {
    super(props);
    this.state = {
      autoLoginFailed: false,

      user: null,
      isAuthenticated: this.props.isAuthenticated,
      errorCode: '',
      errorMessage: '',
    };

    this.handleAutoAppLogin = this.handleAutoAppLogin.bind(this);

  }


  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }


  async verifySecureRoute() {
    console.warn('====== Auth Route | VerifySecureRoute ======');
    ///// Get the current time
    const currentTimestamp = unixTimestampMillisecondsToSeconds(Date.now());
    //const currentTimestamp = currentDate.getTime();
    console.warn(`Route Authentication: Current: ${currentTimestamp}. AccessToken: ${this.props.apiAccessTokenExpiresOn}`);

    ///// Check - Is there an access token present
    if (this.props.apiAccessTokenExpiresOn == null || this.props.apiAccessTokenExpiresOn == undefined
      || this.props.apiRefreshTokenExpiresOn == null || this.props.apiRefreshTokenExpiresOn == undefined) {
        //There is no access tokens. Auth has failed, redirect the user to the login page
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
      return false;
    }

    ///// Check - Has the access token expired
    if (currentTimestamp >= this.props.apiAccessTokenExpiresOn) {
      console.warn(`Access Token has expired, attempt token refresh. Current: ${currentTimestamp} >= AccessToken: ${this.props.apiAccessTokenExpiresOn}`);
      
      ///// Check - Has the refresh token expired
      if (currentTimestamp >= this.props.apiRefreshTokenExpiresOn) {
        console.warn(`Refresh Token has expired. The user needs to log back in. Current: ${currentTimestamp} >= RefreshToken: ${this.props.apiRefreshTokenExpiresOn}`);
        this.props.isAuthenticated = false;
        this.props.updateIsAuthenticatedStatus(false);
        return false;
      }
    } else {
      console.warn(`Route authorised. Access token valid.`);
      this.props.isAuthenticated = true;
      this.props.updateIsAuthenticatedStatus(true);
      return true;
    }
  }


  componentDidMount(props) {
    this.props.removeMfaLoginRequired();
    





    //When logging in within the inAppBrowser, we need to make sure the app account is the same as the inAppBrowser logged in account.
    let currentLoggedInFirebaseUserId = '';
    try {
      currentLoggedInFirebaseUserId = this.props.match.params.firebaseUserId;
    } catch (e) {}
    //For Mobile App Auto Login, we need to get the provided security token from the param.
    let firebaseToken = '';
    try {
      firebaseToken = this.props.match.params.firebaseToken;
    } catch (e) {}
    this.handleAutoAppLogin(currentLoggedInFirebaseUserId, firebaseToken);

  }

  componentDidUpdate() {
    /*
    if (this.props.isAuthenticated === true && this.verifySecureRoute()) {
      //console.log('Redirecting');
      window.location.href = '/account/dashboard';
    }
    */
  }

  async handleAutoAppLogin(currentLoggedInFirebaseUserId, firebaseToken) {
    let userAlreadyAuthed = false;
    if (this.props.isAuthenticated === true && this.verifySecureRoute()) {
      //Make sure its the same user
      if (currentLoggedInFirebaseUserId === this.props.webLoggedInFirebaseUserId) {
        userAlreadyAuthed = true;
      }
    }
    let authFailed = false;
    if (userAlreadyAuthed === false) {
      let promise_loginUser = new Promise((resolve, reject) => {
        resolve(this.props.mobileAppAutoLogin({firebaseToken}));
      });
      let results_loginUser = await promise_loginUser;
      try {
        if (results_loginUser.status === false) {
          authFailed = true;
        } else {
          if (results_loginUser.data.appStatus === false) {
            authFailed = true;
          }
          if (results_loginUser.data.authStatus === false) {
            authFailed = true;
          }
        }
      } catch (e) {
        authFailed = true;
      }
      if (authFailed === true) {
        console.warn("============== authFailed ================");
        console.log(results_loginUser);
        this.setState({
          autoLoginFailed: true,
        });
      } else {
        userAlreadyAuthed = true;
      }
    }

    
    console.warn("============== Auth Successful ================");
    console.warn(`${authFailed} === false && ${userAlreadyAuthed} === true`);

    if (authFailed === false && userAlreadyAuthed === true) {
      console.log("Redirect user to requested url");
      //:targetPath1/:targetPath2/:targetPath3/:targetPath4/:targetPath5

      let targetPath1 = '';
      try {
        targetPath1 = this.props.match.params.targetPath1;
      } catch (e) {
        targetPath1 = null;
      }
      let targetPath2 = '';
      try {
        targetPath2 = this.props.match.params.targetPath2;
      } catch (e) {
        targetPath2 = null;
      }
      let targetPath3 = '';
      try {
        targetPath3 = this.props.match.params.targetPath3;
      } catch (e) {
        targetPath3 = null;
      }
      let targetPath4 = '';
      try {
        targetPath4 = this.props.match.params.targetPath4;
      } catch (e) {
        targetPath4 = null;
      }
      let targetPath5 = '';
      try {
        targetPath5 = this.props.match.params.targetPath5;
      } catch (e) {
        targetPath5 = null;
      }

      let redirectUrl = '';
      if (targetPath1 === null || targetPath1 === '' || targetPath1 === undefined) {

      } else {
        redirectUrl = `${redirectUrl}/${targetPath1}`;
      }
      console.log(redirectUrl);
      if (targetPath2 === null || targetPath2 === '' || targetPath2 === undefined) {
        
      } else {
        redirectUrl = `${redirectUrl}/${targetPath2}`;
      }
      console.log(redirectUrl);
      if (targetPath3 === null || targetPath3 === '' || targetPath3 === undefined) {
        
      } else {
        redirectUrl = `${redirectUrl}/${targetPath3}`;
      }
      console.log(redirectUrl);
      if (targetPath4 === null || targetPath4 === '' || targetPath4 === undefined) {
        
      } else {
        redirectUrl = `${redirectUrl}/${targetPath4}`;
      }
      console.log(redirectUrl);
      if (targetPath5 === null || targetPath5 === '' || targetPath5 === undefined) {
        
      } else {
        redirectUrl = `${redirectUrl}/${targetPath5}`;
      }
      console.warn("==== redirectUrl ====");
      console.warn(redirectUrl);

      window.location.href = redirectUrl;

      if (this.props.isAuthenticated === true && this.verifySecureRoute()) {
        window.location.href = redirectUrl;
      }

    }
  }

  componentWillUnmount() {
  }


  render() {
    const { errorMessage } = this.state;

    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ contentPad20: true })}>

          {this.state.autoLoginFailed !== true && (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Login" headlineSubText="Logging you into the system.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true })}>
                  Logging you in
                  </div>
                </div>
              </InputCard>
            </div>
          )}

          {this.state.autoLoginFailed === true && (
            <div className={classNames({ maxWidth800: true })}>
              <InputCard headlineText="Mobile App Login Failed" headlineSubText="There was an error during the mobile app login. Please try again.">
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s40: true })}>
                    <i className="fas fa-times" />
                  </div>
                </div>
                <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                  <br />
                  <div className={classNames({ text_s20: true, flex: true, flexColumn: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                    <div className={classNames({ text_s20: true, flexJustifyCenter: true, flexAlignItemsCenter: true })}>
                    Login Failed
                    </div>
                    <div className={classNames({ text_s14: true, textAlignCenter: true, })}>
                    We experienced an error when trying to log you in via the Guard Point mobile app.
                    Please re-login to the Guard Point mobile app and try again. 
                    </div>
                  </div>
                  
                </div>
              </InputCard>
            </div>
          )}



        </div>
      </div>
    );
  }
}

Template.propTypes = {
};






const mapStateToProps = (state, ownProps) => {

  let webLoggedInFirebaseUserId = '';
  try {
    webLoggedInFirebaseUserId = state.User.firebaseUserId;
  } catch (e) {
    webLoggedInFirebaseUserId = '';
  }
  if (webLoggedInFirebaseUserId === undefined) {
    webLoggedInFirebaseUserId = '';
  }

  return {
    isAuthenticated: state.Auth.isAuthenticated,
    
    webLoggedInFirebaseUserId: webLoggedInFirebaseUserId,
    loggedInUserId: state.Auth.id,
    apiAccessTokenExpiresOn: state.Auth.apiAccessTokenExpiresOn,
    apiRefreshTokenExpiresOn: state.Auth.apiRefreshTokenExpiresOn,
  };

};


const mapDispatchToProps = {
  mobileAppAutoLogin,       //Mobile App Auto Login
  removeMfaLoginRequired,   //MFA Login/Clear

  updateIsAuthenticatedStatus,

  userAuth,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);