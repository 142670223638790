import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// CSS
import componentStyles from './styles.css';

import 'Fonts/mazeletter-path.ttf';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';

// Actions
// import { } from 'Actions';

// Images
import PrimaryBackgroundImage from 'Images/Security/person-holding-a-smartphone-3740314.jpg';

import imagePersonHoldingPhone from 'Images/Security/person-holding-white-and-black-card-3740759.jpg';
import imagePersonHoldingBusinessCard from 'Images/Security/close-up-of-hand-holding-text-over-black-background-326576.jpg';
import imageCreditCardHandOver from 'Images/Security/shopping-business-money-pay-50987.jpg';
import imageWomanOnPhone from 'Images/Security/blurry-photo-of-a-woman-977840.jpg';


import primaryImage0_330 from 'Images/Home/launch1_0_330.jpg';
import primaryImage330_700 from 'Images/Home/launch1_330_700.jpg';
import primaryImage700_1250 from 'Images/Home/launch1_700_1250.jpg';
import primaryImage1250plus from 'Images/Home/launch1_1250_plus.jpg';

import guardPointLogoLong from 'Images/Logos/logo-long-grey-400x.jpg';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    

    return (
      <div className={classNames({ pageWrapper: true })}>
        
        <div className={classNames({ flex: true, flexJustifyCenter: true, })}>
          <img src={guardPointLogoLong} className={classNames({ guardPointLogo_inlineMax300: true })} />
        </div>

        

      
        <div className={classNames({ pad20: true })}>

          <div className={classNames({shcGrid290: true, })}>

            <div className={classNames({ flex: true, maxWidth400: true, flexJustifySelfStart: true, consumerBusinessBoxDisplay: true })}>
              <div className={classNames({ padR20: true, text_s36: true })}>
                <i class="fas fa-building"></i>
              </div>
              <div className={classNames({ ccc: true })}>
                <div className={classNames({ text_s20: true })}>
                  The following businesses support Guard Point in providing consumer protection services.

                </div>
                <div className={classNames({ text_s14: true })}>
                  
                  
                </div>
              </div>
            </div>

          </div>

        </div>


        <br/><br/>



        <br/><br/><br/>

        <div className={classNames({positionRelative: true, height400: true, maxHeight400: true,})}>
          <div className={classNames({height400: true, maxHeight400: true,})}>
            SECURE YOUR IDENTITY
          </div>
          <div className={classNames({shcPatternsPath1: true, shcPatternsAbsolute: true, shcPatternsThemeDark: true, height400: true, maxHeight400: true,})}>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
            aBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcDaBcD<br/>
            cBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaDcBaD<br/>
          </div>
        </div>
        
        
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Template);
