import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { MessageTip, MessageHighlight, HighlightContainer } from 'Components/ShcText';

import { PreferenceUserControls } from 'Components/ShcDropdowns';

// Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { userIdentitiesGenerateToken, generateVerificationTokenWebsite } from 'Actions';


class StepUserMethods extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleUserIdentitiesGenerateToken = this.handleUserIdentitiesGenerateToken.bind(this);
    this.transform = this.transform.bind(this);

    // this.onChangeStepActionHandler = this.onChangeStepActionHandler.bind(this);
    this.handleBusinessIdentitiesGenerateTokenWebsite = this.handleBusinessIdentitiesGenerateTokenWebsite.bind(this);

    this.state = {
      userIdentity: this.props.value,


      token: this.props.value.verificationToken,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps - StepBusinessWebsite');
    // We are comparing the inbound value (websiteStep) which is an object with the local state paramaters
    console.log(`${JSON.stringify(nextProps.value)} !== ${JSON.stringify(prevState.userIdentity)}`);
    if (nextProps.value !== undefined && nextProps.value !== prevState.userIdentity) {
      console.log('Updating component state from props');
      return {
        userIdentity: nextProps.value,
        token: nextProps.value.verificationToken,
      };
    }
    return null;

  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate - StepUserMethods');
    console.log(`${JSON.stringify(prevProps.value) } !== ${ JSON.stringify(this.props.value)}`);
    if (prevProps.value !== this.props.value) {
      // Perform some operation here
      console.log('perform up of state for StepUserMethods');
      this.setState({
        userIdentity: this.props.value,
      });
    }
  }

  // componentDidUpdate(prevProps, prevState) {

  /*
    console.log("componentDidUpdate - StepBusinessWebsite");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));

    //onChangeStepActionHandler(this.props.token);
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform up of state for StepBusinessWebsite");
      this.setState({
        businessIdentity: this.props.value,
        token: this.props.value.token,
      });
    }

    if (this.props.token !== this.state.token) {
      //console.log("===== TOKEN MISMATCH - FIRE EVENT =======");
      //console.log(this.props.token);
      //console.log(this.state.token);
      this.onChangeStepActionHandler(this.props.token);
    }
    */
  // }

  /*
  onChangeStepActionHandler = (value) => {
    this.setState({
      token: value,
    });
    let updateData= {
      field: 'token',
      value: value,
    }
    this.props.onChangeStepInputHandler(this.props.id, this.state.businessIdentity, updateData);
  }
  */






  // NEW
  handleUserIdentitiesGenerateToken(event) {
    event.preventDefault();
    console.log('Generate user identities token - email');
    const { userIdentity } = this.state;
    const data = {
      userIdentity,
    };
    this.props.userIdentitiesGenerateToken({ data });
  }


  // OLD
  handleBusinessIdentitiesGenerateTokenWebsite(event) {
    event.preventDefault();
    console.log('Generate verification token - website');

    const data = {
      dataType: 'businessIdentities',
      businessId: this.state.businessViewId,
      businessIdentitiesId: this.state.userIdentity._id,
    };

    this.props.generateVerificationTokenWebsite({ data });
  }

  transform(string) {
    let visualToken = '';
    if (string == null) {
      return '';
    }
    if (string.length > 10) {
      let t1 = null;
      let t2 = null;
      let t3 = null;
      let ubaRecords = null;
      try {
        t1 = string.split('VERIFYINTERACTION5');
      } catch (err) {

      }
      try {
        t2 = t1[1].split('VERIFYEND');
      } catch (err) {

      }
      try {
        t3 = t2[0].split('UBA');
        ubaRecords = t3;
      } catch (err) {

      }
      if (t1 !== null && t2 != -null && t3 !== null) {
        visualToken = 'VERIFYINTERACTION5' + '\n';
        for (let x = 0; x < ubaRecords.length; x++) {
          if (ubaRecords[x] === '' || ubaRecords[x] === null || ubaRecords[x] === undefined) {

          } else {
            visualToken += 'UBA' + ubaRecords[x];
            if (x + 1 !== ubaRecords.length) {
              visualToken += '\n'; // No linebreak on last line
            }
          }

        }
        visualToken += 'VERIFYEND';
      } else {
        console.log('Unable to create view for communication token');
      }


    }
    return visualToken;
  }


  render() {

    const {
      id, name, onChangeStepInputHandler, value,
    } = this.props;

    const communicationToken = this.state.userIdentity.communicationToken;
    const visualToken = this.transform(this.state.userIdentity.communicationToken);
    /*
    let visualToken = '';
    if (verificationToken.length > 10) {
      let t1=null;
      let t2=null;
      let t3=null;
      try {
        t1 = verificationToken.split("VERIFYINTERACTION5");
      } catch(err) {

      }
      try {
        t2 = t1[1].split("VERIFYEND");
      } catch(err) {

      }
      try {
        t3 = t2[0].split("UBA");
      } catch(err) {

      }
      if (t1!==null && t2!=-null && t3!==null) {
        visualToken = "VERIFYINTERACTION5"+"\r\n";
        for (let x=0; x<t3.length; x++) {
          visualToken += "UBA"+t3[x]+"\r\n";
        }
        visualToken += "VERIFYEND"+"\r\n";
      } else {
        console.log("Unable to create view for communication token");
      }

    } */



    let displayVerificationTokenWebsite;
    if (this.state.token == '') {
      displayVerificationTokenWebsite = '';
    } else {
      displayVerificationTokenWebsite = `${this.state.token}`;
    }

    let displayVerificationTokenWebsite_filename;
    if (this.state.token == '') {
      displayVerificationTokenWebsite_filename = '';
    } else {
      displayVerificationTokenWebsite_filename = `authchannel-${this.state.token}.html`;
    }

    let displayVerificationTokenWebsite_filecontent;
    if (this.state.token == '') {
      displayVerificationTokenWebsite_filecontent = '';
    } else {
      displayVerificationTokenWebsite_filecontent = `authchannel-site-verification-${this.state.token}`;
    }

    let displayVerificationTokenWebsite_metatag;
    if (this.state.token == '') {
      displayVerificationTokenWebsite_metatag = '';
    } else {
      displayVerificationTokenWebsite_metatag = `${String.fromCharCode('0x003c')}meta name="authchannel-site-verification" content="${this.state.token}"/>`;
    }

    // Activity (website, phone, email)
    const activityType = this.state.userIdentity.type;

    const VerificationMethods = () => {
      if (activityType === 'website') {
        return (
          <ActivityWebsite />
        );
      } else if (activityType === 'email') {
        return (
          <ActivityEmail />
        );
      } else if (activityType === 'phone') {
        return (
          <ActivityPhone />
        );
      }
      return (
        <div>
            Unknown Identity Activity - Error.
        </div>
      );

    };

    const ActivityWebsite = () => (
      <div>
        <NamedTextStyle name="para" >
            The following describes the available processes to verify your website. To verify, you will need to generate a token that is linked
            with your account proving you have ownership/administrative access to the website. <br /><br />
            click the "Generate Token" button below to have your verification methods pre-populated ready for you to configure on your website.
          <br /><br />
        </NamedTextStyle>

        <hr className={classNames({ dividerHorizontalRule: true })} />

        <div>
          <div className={classNames({ padL20: true })}>
              <div>

                <br />
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Generate Token"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-warning"
                  handleClick={this.handleBusinessIdentitiesGenerateTokenWebsite}

              />

                <br />
                <HighlightContainer subHeading="Token" fontSize="16">
                  {displayVerificationTokenWebsite}
                </HighlightContainer>

                <br />
              </div>

              <PreferenceUserControls title="HTML file upload" titleSize="medium" desc="" info="" toExpandText="Open" toCollapseText="Close">
                <div className={classNames({ businessVerificationPreferenceControlsContainer: true })}>
                  <div>
                  You can verify ownership of a site by uploading a special HTML file to your site.
                  This file is tied to a specific business account. You will need to follow the below instructions to verify your website.
                  Removing your verification file from your site can cause you to lose verification for the site.<br /><br />

                  INSTRUCTIONS<br />

                    <ol>
                      <li>
                      <div>
                        Generate a token to place on your website.
                        <br />
                      </div>
                      <div className={classNames({
                        fontWeightBold: true,
                      })}
                      >
                        {displayVerificationTokenWebsite}
                        <br /><br />
                      </div>
                    </li>
                      <li>
                      <div>
                        Create a file with the name matching the token string that has been generated.<br />
                        <div className={classNames({
                          fontWeightBold: true,
                        })}
                        >
                          {displayVerificationTokenWebsite_filename}
                          <br /><br />
                        </div>
                      </div>
                    </li>
                      <li>
                      <div>
                        The contents of the file must contain only the following text:<br />
                        <div className={classNames({
                          fontWeightBold: true,
                        })}
                        >
                          {displayVerificationTokenWebsite_filecontent}
                        </div>
                        <br />
                        <br />
                        For example, if a user owns the site http://www.example.com/, and the returned token is smarthome12cfc68677988bb4.html,
                        then you simply need to create a file at http://www.example.com/smarthome12cfc68677988bb4.html
                        (at the top level of their site), with these contents:
                        smarthome-site-verification: smarthome12cfc8677988bb4.html
                      </div>
                    </li>
                    </ol>

                  </div>
                </div>
              </PreferenceUserControls>

              <PreferenceUserControls title="HTML tag" titleSize="medium" desc="" info="" toExpandText="Open" toCollapseText="Close">
                <div className={classNames({ businessVerificationPreferenceControlsContainer: true })}>
                  <div>
                  You can verify your ownership of a site by adding a Meta tag to the HTML of a specified page.
                  We'll verify that the meta tag exists in the correct location. If we can't find the tag, we'll give you information
                  about the error we encountered. This tag is tied to a specific business account.<br />
                  Removing your verification Meta tag from your site can cause you to lose verification for the site.<br /><br />

                  INSTRUCTIONS<br />

                    <ol>
                      <li>
                      <div>
                        Generate a token to place in a Meta tag on your website.
                        <br /><br />

                      </div>
                    </li>
                      <li>
                      <div>
                        Create a Meta tag within the Head element of the default file (index.html, default.html, etc) at the top
                        level of the owner's site. A HTML file with a Meta verification token might look like this:<br />
                        <br />

                        <div className={classNames({
                          fontWeightBold: true,
                        })}
                        >
                          {displayVerificationTokenWebsite_metatag}
                          <br />
                        </div>

                      </div>
                    </li>
                    </ol>

                  </div>
                </div>
              </PreferenceUserControls>

              <hr className={classNames({ dividerHorizontalRule: true })} />
              <br /><br />
              After completing the configuration on your website, in the next step you can verify your ownership where we will check the website
              to verify.<br />
            </div>
        </div>
      </div>
    ); // End ActivityWebsite

    const ActivityEmail = () => (
      <div>
        <NamedTextStyle name="para" >

          Requirement<br />

            We will send an email to this email address. You will need to click on the email link to verify.<br /><br />

          <br />
          <StandardBtn
              icon=""
              iconLocation=""
              text="Generate Token"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-warning"
              handleClick={this.handleUserIdentitiesGenerateToken}

            />
          <br /><br />

          Verification<br />
          For security reasons, you can verify our email by using our mobile app or
          by visually comparing the security code with the following one:
          <br /><br />
            ----------------------------------<br />
          <div className={classNames({ whiteSpacePreWrap: true })}>
              {visualToken}
            </div>
          <br />
            -----------------------------------<br />

          <br /><br />
            Patented verification seal
        </NamedTextStyle>
      </div>
    ); // End ActivityEmail


    const ActivityPhone = () => (
      <div>
        <NamedTextStyle name="para" >
            In order to verify a phone number, we will need to make contact and request a code to be verified.<br /><br />

            Mobile Numbers<br />
            There are two options:<br />
            1. SMS code will be sent.<br />
            2. Automated phone call requesting you to enter a code.<br />

          <br /><br />
            Non-Mobile Telephone Numbers<br />
            For phone numbers that connect directly with people:<br />
            1. Automated phone call requesting you to enter a code.<br />
          <br />
            For phone numbers where an automated IVR system answers the calls:<br />
            1. A human will call to verify.

          <br /><br />
            For security reasons, we will provide you a call reference number that will be referenced during the contact.
            These reference numbers expire after 30 minutes.

        </NamedTextStyle>
      </div>

    ); // End ActivityEmail


    return (
      <InputCard headlineText="Verification Methods" headlineSubText="">
        <VerificationMethods />
      </InputCard>
    );
  }
}

StepUserMethods.propTypes = {


};

/*
const getThisBusinessIdentityToken = (identities, businessViewId, businessIdentityId) => {
  console.log("getThisBusinessIdentityToken");
  console.log(businessViewId);
  console.log(businessIdentityId);
  if (identities.length > 0) {
    const business = identities.find(aBusiness => aBusiness._id === businessViewId);
    const businessIdentity = business.find(aIdentity => aIdentity._id === businessIdentityId);
    if (businessIdentity!==null) {
      console.log("businessIdentity.verificationToken");
      console.log(businessIdentity.verificationToken);
      return businessIdentity.verificationToken;
    }
  }
  return null;
}
*/

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = {
  userIdentitiesGenerateToken, // NEW
  generateVerificationTokenWebsite, // OLD
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StepUserMethods);
