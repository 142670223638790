// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

// Routing

// Material UI

// CSS

// Components

// Actions
//NEW CODE

const hasOwnProperty = Object.prototype.hasOwnProperty;

function isEmpty(obj) {

  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

class SidebarSubNavBackLink extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const {
      title, urlmatch, isAuthenticated, isAdmin,
      showText, layout, opened, MenuCloseIconSmall
    } = this.props;
    let { to } = this.props;

    //console.log(`to: ${to}`);

    let escapeRE;
    let toPath;
    //console.log(urlmatch);
    const urlparams = urlmatch.params;
    if (!isEmpty(urlparams)) {
      // If params are not empty
      toPath = Object.keys(urlparams).map((key) => {
        //console.log(`looking at: ${key} ${urlparams[key]}`);
        escapeRE = new RegExp(`/:${key}`);
        //console.log(escapeRE);
        // console.log(to.replace(escapeRE, `/${urlparams[key]}`));
        return to.replace(escapeRE, `/${urlparams[key]}`);
        // console.log(to);
        // console.log(`path replacement: ${urlparams[key]}`);
      });

      //console.log(`toPath: ${toPath}`);
      if (toPath === '' || toPath === undefined) {
        // to will remain as it is unaltered
      } else {
        to = toPath;
      }
    }

    if (Array.isArray(to)) {
      to = to[0];
    }

    return (
      <div>
        {layout === "vertical" ?
        (
          <div className={classNames({ flex: true })}>
            <Link
              to={to}
              className={classNames({textDecoration: true, flexGrow: true })}
            >
              <div className={classNames({ SidebarSubNavBack_iconPosition: true })}>
                <div className={classNames({ SidebarSubNavBack_listBackItem: true })}>
                  <div className={classNames({ SidebarSubNavBack_extendedSideBarBackIcon: !showText, SidebarSubNavBack_extendedSideBarBackIconLeft: showText })}>
                    <i className="fas fa-arrow-left" />
                  </div>
                  {showText === true ?
                  (
                    <div className={classNames({ SidebarSubNavBack_extendedSideBarBackTitle: true })}>
                      {title}
                    </div>
                  )
                  :
                  (
                    null
                  )}

                  
                  
                  
                </div>
              </div>
            </Link>
            {opened === true ?
            (
              <div className={classNames({ SidebarSubNavBack_extendedSideBarBackTitle: true })}>
                <MenuCloseIconSmall/>
              </div>
            )
            :
            (
              null
            )}
          </div>
        )
        :
        (
          <div>
            <Link
              to={to}
              className={classNames({textDecoration: true, SidebarSubNavBack_navigationLinks: true, SidebarSubNavBack_navigationLinkHighlight: true })}
            >
              <div className={classNames({ SidebarSubNavBack_listItem: true, SidebarSubNavBack_listItemParent: true })}>
                <div className={classNames({ SidebarSubNavBack_listBackItem: true })}>
                  <div className={classNames({ SidebarSubNavBack_extendedSideBarBackIconLeft: true })}>
                    <i className="fas fa-arrow-left" />
                  </div>
                  {showText === true ?
                  (
                    <div className={classNames({ SidebarSubNavBack_extendedSideBarBackTitle: true })}>
                      {title}
                    </div>
                  )
                  :
                  (
                    null
                  )}
                  
                  
                </div>
              </div>
            </Link>
          </div>
        )}
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


SidebarSubNavBackLink.propTypes = {

};

export default compose(connect(mapStateToProps))(SidebarSubNavBackLink);
