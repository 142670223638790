import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class MessageHighlight extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { type } = this.props;

    let colourStyle;
    if (type === 'danger') {
      colourStyle = {
        borderLeft: '3px solid #e74c3c',
      };
    } else if (type === 'warning') {
      colourStyle = {
        borderLeft: '3px solid #d35400',
      };
    } else if (type === 'info') {
      colourStyle = {
        borderLeft: '3px solid #8e44ad',
      };
    } else if (type === 'success') {
      colourStyle = {
        borderLeft: '3px solid #16a085',
      };
    }

    return (
      <div style={colourStyle} className={classNames({ ShcTextMessageHighlight: true })}>
        {this.props.children}
      </div>

    );
  }
}

MessageHighlight.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MessageHighlight);
