import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class HighlightContainer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    const { subHeading, fontSize } = this.props;

    const fontStyle = {
      fontSize: `${{ fontSize }}px !important;`,
    };

    return (
      <div className={classNames({ ShcHighlightContainerContainer: true, wordWrapBreak: true })}>
        <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>

          <div className={classNames({ ShcHighlightContainerSubHeaderLeft: true })}>
            {subHeading}
          </div>
          <div className={classNames({ ShcHighlightContainerSubHeaderRight: true })} />
        </div>
        <div className={classNames({ flex: true, flexJustifyFlexStart: true })}>
          <div className={`${fontStyle} ${classNames({ ShcHighlightContainerContent: true, flexGrow: true })}`}>
            {this.props.children}
          </div>
          <div className={classNames({ ShcHighlightContainerControls: true })}
           />
        </div>
      </div>

    );
  }
}

HighlightContainer.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(HighlightContainer);
