import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import Moment from 'react-moment';


var moment = require('moment');
moment().format();


import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { PreferenceUserControls } from 'Components/ShcDropdowns';

import { TabContainerV2, TabContainerV3 } from 'Components/ShcTabs';
import { BusinessConnectInteractionItemContentContainerV1 } from 'Components/ShcModulesConnect';


// Actions
import { reduxDataUpdate, apiDataUpdate, 
  toggleOverlayOnScreenResponsive, 
  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,

  setConnectActiveProfile } from 'Actions';

  // Functions
import { apiDataTransferTimer, validateDataType, validateAlphaNumericNoSpacesOnly, validateAlphaNumericWithSpacesOnly } from 'Helpers/Functions';





class BusinessConnectInteractionItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.updateData = this.updateData.bind(this);

    //Item selected
    this.onItemSelect = this.onItemSelect.bind(this);


    this.state = {

      apiTimerCollection: [], // List of active timers.
      identityData: '',    //All the identity data

      //review
      verificationCode: '',
      verificationCodeInputErrors: false,
      
      collapse: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
  }



  closeOverlayOnScreen = (overlayId) => {
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  updateData(returnObj) {
    //console.log("updateData");

    //console.log("returnObj");
    //console.log(returnObj);

    const recordsForUpdate = [];
    recordsForUpdate.push(returnObj);

    

    ////////////////////////////////////////////////////
    //// UPDATE REDUX
    ////////////////////////////////////////////////////
    this.props.reduxDataUpdate({ recordsForUpdate });

    ////////////////////////////////////////////////////
    //// UPDATE VIA THE API
    ////////////////////////////////////////////////////
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

    
  }




  removeItem(identityId, evt) {
    //console.log("removeItem");
    //console.log(identityId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      identityId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }



  onItemSelect(transactionId, event) {
    //console.log("==== transactionId ======");
    //console.log(transactionId);
    let data = {
      transactionId,
    }
    try {
      this.props.onSelect(data);
    } catch (e) {
      //console.log("Error props.onSelect");
    }
    
  }



  
  render() {
    const { transactionStatus, customerContactIdentityMasked, tokenGenerationTimestamp, tokenExpiryTimestamp,
      transactionId,
      onSelect,
      interactionShortMessageRequest,
 
      identityType, dataSet,
    } = this.props;

    //let interactionCreationTime = moment(tokenGenerationTimestamp).format("HH:mm A");
    let interactionCreationTime = <Moment unix  format="hh:mm A">{tokenGenerationTimestamp}</Moment>;





    ////// IDENTITY DISPLAY FORMATTING //////
    let identitySectionTitle = "Unknown";
    let identitySectionSubTitle = "Unknown";
    let identityTypeDisplayIcon = <div><i className={`fas fa-question-circle`}></i></div>;
    
    if (identityType === "phone") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;

    } else if (identityType === "email") {
      identityTypeDisplayIcon = <div><i className={`far fa-envelope`}></i></div>;

    } else if (identityType === "website") {
      identityTypeDisplayIcon = <div><i className={`fas fa-network-wired`}></i></div>;
      
    } else if (identityType === "social") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
    
    } else if (identityType === "postal") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;

    } else if (identityType === "inperson") {
      identityTypeDisplayIcon = <div><i className={`fas fa-phone`}></i></div>;
      
    } 


    return (
      <div className={classNames({ ShcModulesBusinessConnectInteractionItemViewContainerHeader: true,
       })}
       onClick={() => this.onItemSelect(transactionId)}
       >
        
        
        <BusinessConnectInteractionItemContentContainerV1
        interactionActiveStatus={transactionStatus}
        interactionActionRequired={false}
        div1="iconSection" div2="headerSection" div3="bodySection">
            <div div1="iconSection" className={classNames({ width30: true, flex: true, flexColumn: true })}>
              <div className={classNames({ flexGrow: true })}>

                
                {identityTypeDisplayIcon}

                {interactionShortMessageRequest === true ?
                  <i className={`far fa-comment-dots ${classNames({ colour_warning: true })}`}></i>
                :
                  null
                }
                
                
              </div>
              <div>
                  <div></div>
              </div>
            </div>
            
            <div div2="headerSection" className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div className={classNames({ zzzz: true })}>

                <div className={classNames({ text_h6: true, text_color_grey_light: true })}>
                  {interactionCreationTime}
                </div>
                <div className={classNames({ text_h4: true, text_color_grey_light: true })}>
                  {customerContactIdentityMasked} 
                </div>
                <div className={classNames({ text_h5: true, text_color_grey_light: true })}>
                  
                </div>

              </div>
              
            </div>
            
            <div div3="bodySection" className={classNames({ flex: true, flexColumn: true, flexJustifySpaceBetween: true })}>
              
              <div className={classNames({ flexGrow: true })}>

              </div>
              <div className={`hidden ${classNames({ flex: false, flexColumn: true, flexJustifySpaceBetween: true })}`}>
                {false === true ?
                  <i className={`far fa-check-square ${classNames({ text_color_grey_navlinks: true, text_22: true })}`}></i>
                :
                  <i className={`far fa-square ${classNames({ text_color_grey_navlinks: true, text_22: true })}`}></i>
                }
              </div>
                
            </div>
          </BusinessConnectInteractionItemContentContainerV1>

      </div>

    );
  }
}

BusinessConnectInteractionItem.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 


  return {

  };
};

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  toggleOverlayOnScreenResponsive, 

  userIdentitiesVerifyNow,
  businessIdentitiesVerifyNow,
  userIdentitiesCodeVerifyNow,
  businessIdentitiesCodeVerifyNow,
  userIdentitiesGenerateToken,
  userIdentitiesWebsiteVerifyNow,
  businessIdentitiesVerificationActivity_website,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectInteractionItem);
