import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// CSS
import componentStyles from './styles.css';

// Components

// Actions




class Breadcrumb extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.breadcrumbs = '';
    this.rightcrumbs = '';
  }

  componentDidMount(props) {
    //console.log('component mounted');
    //console.log(JSON.stringify(this.props.breadcrumbData));
  }


  render() {

    if (typeof this.props.breadcrumbData !== 'undefined' && this.props.breadcrumbData !== '') {
      //console.log('prop is available and set');
      /*
      this.props.breadcrumbData.map((breadcrumb, i) => {
        this.breadcrumbs = `${this.breadcrumbs} <li key="${i}"><a href="${breadcrumb.url}">${breadcrumb.title}</a></li>`;
      });
      */
      this.breadcrumbs = this.props.breadcrumbData.map((breadcrumb, i) => (
        <li key={i}><a href={breadcrumb.url}>{breadcrumb.title}</a></li>
      ));

      //console.log(this.breadcrumbs.toString());
    } else {
      //console.log('prop not available');
    }

    if (typeof this.props.rightcrumbData !== 'undefined' && this.props.rightcrumbData !== '') {
      this.rightcrumbs = this.props.rightcrumbData.map((rightcrumb, i) => (
        <li key={i}><a href={rightcrumb.url}>{rightcrumb.title}</a></li>
      ));
    }






    return (
      <div className={classNames({ breadcrumbWrapper: true })}>
        <div className={classNames({ flex_container: true })}>
          <div className={classNames({ flex_item: true })}>
            <ul className={classNames({ breadcrumbtrail: true })}>
              {this.breadcrumbs}
            </ul>
          </div>
          <div className={classNames({ flex_item: true })}>
            <ul className={classNames({ list_horizontal: true })}>
              {this.rightcrumbs}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Breadcrumb.propTypes = {
};

export default Breadcrumb;
