import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing

// Material UI
import Button from '@material-ui/core/Button';

// CSS
import componentStyles from './styles.css';

// Components

// Actions


class StandardText extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
  }

  render() {
    const { classes } = this.props;
    // split by space, remove spaces and map a function for name: true and print


    const icon = this.props.icon;
    // console.log('icon...');
    // console.log(icon);
    const text = this.props.text;
    // console.log('text...');
    // console.log(text);
    const iconLocation = this.props.iconLocation;
    // console.log('iconLocation...');
    // console.log(iconLocation);

    let displayIcon = false;
    if (icon != null && icon != '') {
      displayIcon = true;
    }

    let iconLocationLeft = false;
    let iconLocationRight = false;
    if (iconLocation === 'left') {
      iconLocationLeft = true;
    } else if (iconLocation === 'right') {
      iconLocationRight = true;
    }

    const tooltip = this.props.tooltip;
    const buttonActive = false;

    const ButtonContent = () => {
      if (displayIcon) {
        if (!text) {
          return (
            <div>
              <span><i className={icon} /></span>
            </div>
          );
        }
        if (iconLocation === 'left') {
          return (
            <div>
              <span className={classNames({ outlineBtnAttrHidden: !iconLocationLeft, outlineBtnLeftIcon: iconLocationLeft })}><i className={icon} /></span>
              {text}
            </div>
          );
        }
        return (
          <div>
            {text}
            <span className={classNames({ outlineBtnAttrHidden: !iconLocationRight, outlineBtnRightIcon: iconLocationRight })}><i className={icon} /></span>
          </div>
        );
      }
      return (
        <div>
          {text}
        </div>
      );
    };


    return (
      <div className={classNames({ outlineBtnWrapper: true })}>
        <div
          className={`${classes} 
          ${classNames({
          })}`}
        >
          <ButtonContent />
        </div>


      </div>
    );
  }
}

StandardText.propTypes = {

};
StandardText.defaultProps = {
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StandardText);
