// This file is shared across the demos.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Routing
import Routes from 'Core/Routes';

// Material UI

// CSS

// Components
import LinkGroupListItem from 'Components/LinkGroupListItem';

// Actions


const hasOwnProperty = Object.prototype.hasOwnProperty;

function isEmpty(obj) {

  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

class SidebarSubNavLinks extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;
    const linkGroupSelection = this.props.linkGroupSelection;
    const activeUrl = this.props.url;
    const urlmatch = this.props.urlmatch;
    const RoutesSort = Routes.sort((a, b) => a.navPosition - b.navPosition);


    let navDropDownItemsUserData;

    const LinkGroupList = (props, state) => {
      const {
        exact, permission, navTitleCollapsed, navTitleExpanded,
        navTitle, displayicon, navVisible, navDropDownItems, navDropDownUserState,
        navPosition,
      } = props.navData;
      let { path } = props.navData;
      let toPath;
      let linkHighlight = false;

      if (props.adminRoute && !isAdmin) return null;
      if (props.authRoute && !isAuthenticated) return null;
      //console.log('linkGroup check:');
      //console.log(props.linkGroup);
      //console.log(linkGroupSelection);
      if (!props.linkGroup) return null;

      //console.log(`${activeUrl} === ${path}`);


      let escapeRE;
      const urlparams = urlmatch.params;
      if (!isEmpty(urlparams)) {
        toPath = Object.keys(urlparams).map((key) => {
          escapeRE = new RegExp(`/:${key}`);
          return path.replace(escapeRE, `/${urlparams[key]}`);
        });
        if (toPath === '' || toPath === undefined) {
          // to will remain as it is unaltered
        } else {
          path = toPath;
        }
      }
      if (Array.isArray(path)) {
        path = path[0];
      }

      if (activeUrl === `/${path}`) {
        linkHighlight = true;
      } else {
        linkHighlight = false;
      }

      if (typeof navDropDownItems !== 'undefined') {
        if (navDropDownItems.length > 0) {
          // console.log('Nav has drop down items');
          return (
            <LinkGroupListItem hasChildren navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} navDropDownItems={navDropDownItems} navDropDownUserState={navDropDownUserState} linkHighlight={linkHighlight} navPosition={navPosition} />
          );
        }
      }
      return (
        <LinkGroupListItem to={`/${path}`} hasChildren={false} navTitleCollapsed={navTitleCollapsed} navTitleExpanded={navTitleExpanded} displayicon={displayicon} linkHighlight={linkHighlight} navPosition={navPosition} />

      );
    };
    return (
      <div>
        {
          RoutesSort.map(singleRoute => {
            /*
            try {
              if (!singleRoute.linkGroups.includes(linkGroupSelection)) {
                return null;
              }
            } catch (e) {
              return null;
            }
            */
            
            return (
              <li key={singleRoute.path} data-item={singleRoute.navPosition} className={classNames({listNone: true,})}>
                {
                  <LinkGroupList
                  navData={singleRoute}
                  adminRoute={singleRoute.permission.includes('admin')}
                  authRoute={singleRoute.permission.includes('auth')}
                  linkGroup={singleRoute.linkGroups.includes(linkGroupSelection)}
                />
                }
              </li>
            )
          })
          }
      </div>
    );
  }
}
// ListItemCustomControl
// CollapsableNavListItem
const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
});


SidebarSubNavLinks.propTypes = {

};

export default compose(connect(mapStateToProps))(SidebarSubNavLinks);
