

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

//Desktop Messaging
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Routing

// CSS
import componentStyles from './Styles.css';

// Components

// Actions



class Toast extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickClose(evt) {
    console.log('onClick Close');
    this.props.togglePropertyPopupPanelInfo();
    evt.stopPropagation();
  }


  onMouseOver(evt) {
    console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    console.log('onMouseOut (button)');
  }

  onClick(evt) {
    console.log('onClick (button)');
    console.log(evt);
  }


  handleAddChip(chip) {
    console.log('addign chip');
  }
  handleDeleteChip(chip, index) {
    console.log('deleting chip');
  }


  success = () => {
    console.log("notify fired");

    toast.success("Success Notification !", {
      closeOnClick:true,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 20000,
      className: 'toastSuccess',
      bodyClassName: "grow-font-size",
      progressClassName: 'toastSuccessProgress'
    });
    /*
    toast.error("Error Notification !", {
      position: toast.POSITION.TOP_LEFT
    });

    toast.warn("Warning Notification !", {
      position: toast.POSITION.BOTTOM_LEFT
    });

    toast.info("Info Notification !", {
      position: toast.POSITION.BOTTOM_CENTER
    });

    toast("Custom Style Notification with css class!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'foo-bar'
    });
    */
    
  }
  warning = () => {
    console.log("notify fired");

    toast.warn("Warning Notification !", {
      closeOnClick:true,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 20000,
      className: 'toastWarning',
      bodyClassName: "grow-font-size",
      progressClassName: 'toastWarningProgress'
    });
  }
  error = () => {
    console.log("notify fired");

    toast.error("Error Notification !", {
      closeOnClick:true,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 20000,
      className: 'toastError',
      bodyClassName: "grow-font-size",
      progressClassName: 'toastErrorProgress'
    });
  }

  render() {
    

    return (
      <div className={classNames({ popupFullScreenPanel: true })}>
        {popupPanel}
      </div>

    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Toast);
