import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
const uuidv1 = require('uuid/v1');
import Select from 'react-select';


// CSS
import componentStyles from './styles.css';

// Components
import { LocationSearchInputGoogleAutoComplete } from 'Components/ShcInput';
import { AddressInputLayoutsInternationalV1 } from 'Components/ShcAdminModulesWorkQueues';
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';

class Instructions_businessBrandWords extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)

    this.state = {
      apiTimerCollection: [], // List of active timers.
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { 
      registryCountry, registry, identity
    } = this.props;

    let registryName = '';
    let registryURL = '';
    let registrySearchTerm = '';
    if (registry === "asic-australia") {
      registryName = 'ASIC';
      registryURL = "https://asic.gov.au/";
      registrySearchTerm = identity;
    }
 
  
    return (
      <div>
        <TextStyle size="h5">
        <ol>
          <li>BRAND WORDS <a href={registryURL}>{registryURL}</a></li>
          <li>Use the Search Registers to lookup the business name</li>
          <li>Search for "{registrySearchTerm}" exactly under the business name search.</li>
          <li>If there is a "Holder", then view the Holder Record.</li>
          <li>Purchase the "Current Company Information" for approximately $9.00</li>
          <li>Upload receipt to this page</li>
          <li>Upload company document to this page</li>
          <li>Type in the address exactly how it appears under "Current Principal Place of Business Address"</li>
          <li>Click Send Postal Document</li>
        </ol>
        </TextStyle>
      </div>
    );
  }
}




Instructions_businessBrandWords.defaultProps = {
};


Instructions_businessBrandWords.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => { 
  return {
    
  };
};

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Instructions_businessBrandWords);
