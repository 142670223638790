import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { IdentityInputV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessUserPermissionsView, BusinessUserPermissionsAdd } from 'Components/ShcModulesIdentity';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';


// Actions
import { businessUsersRemoveUser, addBusinessUserPermission, removeBusinessUserPermission, toggleOverlayOnScreenResponsive, reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class BusinessUserProfile extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
   
    //Add and remove permissions
    this.removeBusinessUserPermission = this.removeBusinessUserPermission.bind(this);
    this.addBusinessUserPermission = this.addBusinessUserPermission.bind(this);


    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,

      key: this.props.key,
      businessId: this.props.businessId,
      userId: this.props.userId,
      businessIdentities: [],


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidMount() {
    console.log(`ComponentDidMount | BusinessUserProfile | ${this.state.userId}`);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  removeBusinessUserItem(userId, evt) {
    //REMOVE THE BUSINESS USER
    //console.log("removeBusinessUserItem");
    //console.log(userId);
    //console.log("evt");
    //console.log(evt);
    const { businessId } = this.state;
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let apiUi = {
      apiActivityId
    }

    let data = {
      businessId,
      userId,
    }
    //console.log("data for removing user");
    //console.log(data);
    console.warn(`ACTION | onRemoveItem`);
    this.props.businessUsersRemoveUser({data, apiUi});
  }
  
  addBusinessPermissionToUser(userId, evt) {
    const data = {
      userId,
      //tableData: tableData,
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectBusinessUserPermissionsToAdd', data);

  }
  removeBusinessUserPermission(body = {}) {
    //console.log("removeBusinessUserPermission | remove the permission from this user");
    const { apiUi, item } = body;
    const permissionName = item.permissionName;

    const businessId = this.state.businessId;
    const userId = this.props.data.userId;


    const business = { 
      businessId,
      userId,
      permissionName,
    };

    this.props.removeBusinessUserPermission({business, apiUi});

  }
  addBusinessUserPermission(body = {}) {
    //console.log("addBusinessUserPermission | remove the permission from this user");
    const { apiUi, item } = body;
    const permissionName = item.permissionName;

    const businessId = this.state.businessId;
    const userId = this.props.data.userId;


    const business = { 
      businessId,
      userId,
      permissionName,
    };

    this.props.addBusinessUserPermission({business, apiUi});

  }

  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }

  addBusinessPermissionToUserFromTable(rowDetails, businessUserAccountUserId, evt) {
    //console.log("addBusinessPermissionToUserFromTable");
    //console.log(rowDetails);

    this.props.toggleOverlayOnScreenResponsive('close', "selectBusinessUserPermissionsToAdd");

    let selectedRecord = rowDetails.original;

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    //console.log('businessId');
    //console.log(this.state.businessId);    

    //console.log('UserId?');
    //console.log(this.props.data.userId);

    //console.log('permissionID');
    //console.log(selectedRecord.permission);            

    
    console.log('businessUserAccountUserId');
    console.log(businessUserAccountUserId); 
    console.log('userId');
    console.log(this.state.userId); 
    console.log('key');
    console.log(this.state.key);

    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessId,
      userId: businessUserAccountUserId,
      permissionName: selectedRecord.permission
    };

    //console.log('========= business object =========');
    //console.log(business);

    this.props.addBusinessUserPermission({business, apiUi});
  }

  closeOverlayOnScreen = (overlayId) => {
    ////console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  render() {
    const { userId, businessId, businessIdentities, stateIdentifier, identifiers, id, name, data, onChangeHandler,
      onRemoveItem,
    } = this.props;

    let activeBusinessUserAccount = true;
    let businessUserAccountStatusDescription = '';
    try {
      if (data.userBusinessActive === false) {
        activeBusinessUserAccount = false;
        businessUserAccountStatusDescription = data.userBusinessActiveNote;
      }
    } catch (e) {}

    let activeBusinessUserAccountText = '';
    if (activeBusinessUserAccount === true) {
      activeBusinessUserAccountText = 'Active';
    } else {
      activeBusinessUserAccountText = 'Inactive';
    }

    let businessUserAccountUserId = '';
    try {
      businessUserAccountUserId = userId;
    } catch (e) {}

    let userIdentity = '';    //e.g. an email address
    try {
      userIdentity = data.userIdentity;
    } catch (e) {}

    let businessUserAccountInvitedByUserId = '';    
    try {
      businessUserAccountInvitedByUserId = data.userInvitedByUserId;
    } catch (e) {}
 
    

    let businessUserPermissions = data.userPermissions;

    let profileAttributes = "";
    try { profileAttributes = data.profileAttributes; } catch(e) {}   //this is all the identities linked.
     

    let headlineUserId = userId;
    let headlineProfileIdentityTypes = "";


    const BusinessPermissionsList = (businessUserAccountUserId) => {
      if (businessIdentities !== '') {
        let tableData = [
          {
            permission: "owner",
            desc: "Full access to this business."
          },
          {
            permission: "admin",
            desc: "Administrator access to this business."
          },
          {
            permission: "connect",
            desc: "Interact with customers."
          },
        ];
        //console.log("BusinessPermissionsList UI Element");
        //console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                key={this.state.key}
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Permission",
                        accessor: "permission"
                      },
                      {
                        Header: "Description",
                        accessor: "desc"
                      },
                      {
                        Header: "Action",
                        Cell: row => (
                          <div className={classNames({ flex: true, flexJustifyCenter: true })}>
                            
                            <StandardApiBtn
                              key={`addUserButton_${this.state.userId}`}
                              apiActivityId="addBusinessPermissionToUser"
                              icon=""
                              iconLocation=""
                              text="Add"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.addBusinessPermissionToUserFromTable.bind(this, row, businessUserAccountUserId)}
                            />
                            
                          </div>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return "No data.";
        }
        return "No data.";
      }
      return null;
    }

    return (
      <div className={classNames({ ShcModulesIdentityContainer: true })}>
        <div className={classNames({ ShcModulesIdentityContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>Account: {activeBusinessUserAccountText}</div>
                  {businessUserAccountStatusDescription !== '' &&
                  (
                    <div className={classNames({ text_h6: true })}>{businessUserAccountStatusDescription}</div>
                  )}
                  <div className={classNames({ text_h3: true })}>{userIdentity}</div>
                  <div className={classNames({ text_h6: true })}>User ID: {businessUserAccountUserId}</div>
                  {this.props.loggedInUserId === businessUserAccountUserId &&
                  (
                    <div className={classNames({ text_h6: true })}>Current logged in user account</div>
                  )}
  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
                {this.state.collapse ?
                  <div><i class="fas fa-angle-down"></i></div>
                :
                <div><i class="fas fa-angle-up"></i></div>
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityStatusContainerBody: true })}>
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Account Details</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardApiBtn
                apiActivityId={`RemoveBusinessUser_${userId}`}
                icon=""
                iconLocation=""
                text="Delete User"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-small shcbtn-secondary"
                handleClick={this.removeBusinessUserItem.bind(this, userId)}
              />
            </div>
          </div>



          <div className={classNames({ aaa: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              
              <TextStyle size="h5" weight="normal">Account: {activeBusinessUserAccountText} </TextStyle>
              {businessUserAccountStatusDescription !== '' && businessUserAccountStatusDescription !== undefined &&
              businessUserAccountStatusDescription !== null &&
              (
                <TextStyle size="h5" weight="normal">Reason: {businessUserAccountStatusDescription}</TextStyle>
              )}
              <TextStyle size="h5" weight="normal">User Id: {businessUserAccountUserId}</TextStyle>
              {businessUserAccountInvitedByUserId !== '' && businessUserAccountInvitedByUserId !== undefined &&
              businessUserAccountInvitedByUserId !== null &&
              (
                <TextStyle size="h5" weight="normal">Invited by: {businessUserAccountInvitedByUserId}</TextStyle>
              )}
            </div>
          </div>

          <div className={classNames({ borderTLight: true, marLRMinus10: true })}/>

          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">User Permissions</TextStyle>
              <TextStyle size="h5" weight="normal">This user has the following permissions.</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              
            </div>
          </div>
          <div>
            <div>
              {
              businessUserPermissions && 
              businessUserPermissions.length > 0 ?
                // Arrow function preserves "this"
                businessUserPermissions.map((item, i) => (

                <li key={item._id} className={classNames({ listStyleNone: true })}>
                  <BusinessUserPermissionsView
                    businessId={businessId}
                    userId={userId}
                    data={businessUserPermissions[i]}
                    onRemoveItem={this.removeBusinessUserPermission}
                  />

                </li>

                ), this)
              : (
                <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                  <TextStyle size="h4" weight="normal">No permissions associated</TextStyle>
                  <TextStyle size="h5" weight="normal">This user does not have any permissions to this business.</TextStyle>
                  
                </div>
              )
              }
              

            </div>
          </div>


          <div className={classNames({ borderTLight: true, marLRMinus10: true })}/>

          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Add Permissions</TextStyle>
              <TextStyle size="h5" weight="normal">Add additional permissions to this user</TextStyle>
            </div>
          </div>
          <div>
            <div>
              {businessUserPermissions.includes('owner') === false && (
                <BusinessUserPermissionsAdd
                businessId={businessId}
                userId={userId}
                permissionName="owner"
                onAddItem={this.addBusinessUserPermission}
                />
              )}
              {businessUserPermissions.includes('admin') === false && (
                <BusinessUserPermissionsAdd
                businessId={businessId}
                userId={userId}
                permissionName="admin"
                onAddItem={this.addBusinessUserPermission}
                />
              )}
              {businessUserPermissions.includes('connect') === false && (
                <BusinessUserPermissionsAdd
                businessId={businessId}
                userId={userId}
                permissionName="connect"
                onAddItem={this.addBusinessUserPermission}
                />
              )}
           
            </div>
          </div>

          

        </div>
        }






        <OverlayOnScreenResponsive
          id="selectBusinessUserPermissionsToAdd"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add Permissions"
          classes=""
          key={this.state.key}
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Permissions</TextStyle>
              <TextStyle size="h5">Select a permission to add to this user.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>

              <BusinessPermissionsList/>

            </div>

     
            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectBusinessUserPermissionsToAdd')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>

    );
  }
}

BusinessUserProfile.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  //console.log("ownProps");
  //console.log(ownProps);


  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    loggedInUserId: state.User.id,
  };

};


const mapDispatchToProps = {
  businessUsersRemoveUser, 
  addBusinessUserPermission,
  removeBusinessUserPermission,

  toggleOverlayOnScreenResponsive,

  reduxDataUpdate,
  apiDataUpdate
};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessUserProfile);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(BusinessUserProfile);

/* OLD ADD PERMISSIONS BUTTON
<StandardApiBtn
                apiActivityId="addBusinessPermissionToUser"
                icon=""
                iconLocation=""
                text="Add Permission"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.addBusinessPermissionToUser.bind(this, this.state.userId)}
              />

*/