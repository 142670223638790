import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';


// Routing
//import Routes from 'Core/Routes';

// Material UI
import List from '@material-ui/core/List';
// CSS
import componentStyles from './styles.css';

// Components
import { ShcPublicSidebarNavLinks, ShcPublicParentSidebarNavLinks } from 'Components/ShcPublicHeader';
// Actions
//import {  } from 'Actions';


class ShcParentSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.state = {
      sidebarOpenState: this.props.sidebarOpenState,
      appbarHeight: this.props.appbarHeight,

      open: this.props.sidebarOpenState,
      auth: true,
      anchorEl: null,
      isAuthenticated: null,
      isAdmin: null,
    };
  }
  

  componentDidMount() {
    //console.log("componentDidMount - ShcParentSidebar");
    //this.props.sidebarOpenState(false);
  }
  


  handleDrawerOpen = () => {
    //console.log('handleDrawerOpen - set to open:true');
    this.setState({ open: true });
    //this.props.sidebarOpenState(true);
  };

  handleDrawerClose = () => {
    //console.log('handleDrawerClose - set to open:false');
    this.setState({ open: false });
    //this.props.sidebarOpenState(false);
  };

  

  render() {
    const { sidebarOpenState } = this.props;

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";

    //Set the margin off the top (based on AppBar)
    const appbarHeightStyle = {
      marginTop: this.props.appbarHeight ? this.props.appbarHeight : '45px',
    }

    const sidebarPermanentStyle = {
      width: this.props.sidebarOpenState ? '240px' : '72px',
    }

    const sidebarTemporaryStyle = {
      width: this.props.sidebarOpenState ? '240px' : '0',
    }


    
    return (
      <div>

        <div className={classNames({
        shcHeader_parentSidebarPermanent: true,
        })}>

          <div
            className={classNames({
            shcHeader_appbarHeightStyle: true,
            shcHeader_parentSidebarPermanentOpen: this.props.sidebarOpenState,
            shcHeader_parentSidebarPermanentClose: !this.props.sidebarOpenState,
            shcHeader_parentSidebarMain: true,
            shcHeader_parentSidebarSmall: !this.props.sidebarOpenState,
            })}
            variant="permanent"
            open={this.props.sidebarOpenState}
            >
            


              <Scrollbars 
                className={classNames({shcHeader_navigationSideBar:true, scrollBars_style: true})}
                renderThumbVertical={({ ...props }) =>
                  <div {...props} />
                }
                renderTrackHorizontal={props => <div {...props} className={classNames({shcHeader_sidebarTrackHorizontal:true})}/>}
                >
                
                <ul className={classNames({listNoMarginPadding:true})}>
                  <ShcPublicParentSidebarNavLinks/>
                </ul>

                <hr className={classNames({
                shcHeader_parentSidebarSectionDivider: true,
                })}/>

                <ul className={classNames({listNoMarginPadding:true})}>
                  <ShcPublicSidebarNavLinks customLinks={["home", "logout" ]}/>
                </ul>


                <div className={classNames({ shcHeader_parentSidebarFooterPadding: true })}/>

              </Scrollbars>





          </div>
          <div className={classNames({
            shcHeader_parentSidebarPermanentOpen: this.props.sidebarOpenState,
            shcHeader_parentSidebarPermanentClose: !this.props.sidebarOpenState,
          })}>&nbsp;
          </div>


        </div>

        <div className={classNames({
        shcHeader_parentSidebarTemporary: true,
        flex: true,

        })}>

          <div
            className={classNames({
            shcHeader_appbarHeightStyle: true,
            shcHeader_parentSidebarTemporaryOpen: this.props.sidebarOpenState,
            shcHeader_parentSidebarTemporaryClose: !this.props.sidebarOpenState,
            shcHeader_parentSidebarMain: true,
            shcHeader_parentSidebarSmall: !this.props.sidebarOpenState,
            })}
            variant="temporary"
            open={this.props.sidebarOpenState}
            >
            



              <Scrollbars 
                className={classNames({shcHeader_navigationSideBar:true, scrollBars_style: true, shcHeader_drawerPaperClose: !this.state.open})}
                renderThumbVertical={({ ...props }) =>
                  <div {...props} />
                }
                renderTrackHorizontal={props => <div {...props} className={classNames({shcHeader_sidebarTrackHorizontal:true})}/>}
                hideTracksWhenNotNeeded
                >

                <ul className={classNames({listNoMarginPadding:true})}>
                  <ShcPublicParentSidebarNavLinks/>
                </ul>
                
                
                <hr className={classNames({
                shcHeader_parentSidebarSectionDivider: true,
                })}/>
                

                <ul className={classNames({listNoMarginPadding:true})}>
                  <ShcPublicSidebarNavLinks customLinks={["home", "logout" ]}/>
                </ul>


                <div className={classNames({ shcHeader_parentSidebarFooterPadding: true })}/>

              </Scrollbars>





          </div>
          <div>
            {this.props.children}
          </div>

        </div>

      </div>
    );
  }
}

ShcParentSidebar.propTypes = {

};


const mapStateToProps = state => {
  return {
    sidebarOpenState: state.UI.sidebarOpen,
    appbarHeight: state.UI.appbarHeight,
    user: state.User.userType,
  }
}

const mapDispatchToProps = {
  
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcParentSidebar);