import React from 'react';
import classNames from 'classnames';

// Material UI
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

// CSS
import componentStyles from './AddressSection.css';

// Components

// Actions


class AddressSection extends React.Component {
  render() {
    return (
      <div className={classNames({ stripeAddressSection: true })}>

        <div className={classNames({ stripeFormContainerRecord: true })}>

          <div className={classNames({ flexContainerBaseline: true })}>
            <div className={classNames({ stripeFormSectionTitle: true })}>
              Contact information
            </div>
            <div className={classNames({ stripeFormSectionOptions: true })}>
              Already have an account? Log in
            </div>
          </div>
          <div className={classNames({ stripeFormFlexGrid: true })}>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="email"
                label="Email"
                margin="normal"
                fullWidth
                helperText="At least 6 characters"
              />
            </div>
          </div>
          <div className={classNames({ stripeFormSectionOptions: true })}>
            <Checkbox
              value="marketing"
            /> Keep me up to date on news and exclusive offers
          </div>


          <div className={classNames({ stripeFormSectionTitle: true })}>
            Billing address
          </div>

          <div className={classNames({ fullwidth: true })}>
            <div className={classNames({
              flex: true,
              flexJustifySpaceBetween: true,
              flexAlignBaseline: true,
            })}
            >
              <div className={classNames({
                flexMargin5: true,
                fullwidth: true,
                shcMaterialTextFieldBaselinePadding: true,
              })}
              >
                <TextField
                  className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                  id="firstname"
                  label="First Name"
                  margin="normal"
                  fullWidth
                  helperText="At least 6 characters"
                />
              </div>
              <div className={classNames({
                flexMargin5: true,
                fullwidth: true,
                shcMaterialTextFieldBaselinePadding: true,
              })}
              >
                <TextField
                  className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                  id="lastname"
                  label="Last Name"
                  margin="normal"
                  fullWidth
                  helperText="At least 6 characters"
                />
              </div>

            </div>
          </div>
          <div className={classNames({ stripeFormFlexGrid: true })}>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="addressLine1"
                label="Address"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className={classNames({ stripeFormFlexGrid: true })}>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="addressLineOther"
                label="Apartment, suite, etc. (optional)"
                margin="normal"
                fullWidth
                helperText="Optional"
              />
            </div>
          </div>
          <div className={classNames({ stripeFormFlexGrid: true })}>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="city"
                label="City"
                margin="normal"
                fullWidth
              />
            </div>
          </div>

          <div className={classNames({ stripeFormFlexGrid: true })}>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="country"
                label="Country"
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="state"
                label="State/territory"
                margin="normal"
                fullWidth
              />
            </div>
            <div className={classNames({ shcMaterialTextFieldContainer: true })}>
              <TextField
                className={classNames({ materialTextFieldNarrow: true, shcMaterialTextField: true })}
                id="postcode"
                label="Postcode"
                margin="normal"
                fullWidth
              />
            </div>
          </div>


        </div>


      </div>
    );
  }
}

export default AddressSection;
