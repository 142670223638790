import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';


import Moment from 'react-moment';
var moment = require('moment');
moment().format();



// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn, StandardApiIcon } from 'Components/ShcButtons';

//import { IdentityViewV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { SingleLineAccessKeyItem } from 'Components/ShcModulesApiManagement';




// Actions
import { reduxDataUpdate, apiDataUpdate, revealSecretKey } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class ApiManagementIpAddressItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
   

    this.state = {
      apiTimerCollection: [], // List of active timers.
      collapse: true,

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  removeItem(keyId, value, evt) {
    //console.log("removeItem");

    //console.log("keyId");
    //console.log(keyId);

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    const apiUi = { apiActivityId };
    const item = {
      keyId,
      value,
    }

    this.props.onRemoveItem({item, apiUi});
  }



  render() {
    let { businessId, keyId, value, onRemoveItem, } = this.props;

    return (
      <div className={classNames({ ShcAccountModulesChildContainer: true })}>
        <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
          <div className={classNames({ positionRelative: true })}>
            <div className={classNames({ flex: true, flexJustifyFlexEnd: true })}>

              <div className={classNames({ ShcModulesApiMgt_removeItem: true })}>
                <StandardApiBtn
                  apiActivityId={`RemoveApiKey_${keyId}_${value}_onBusiness_${businessId}`}
                  icon=""
                  iconLocation=""
                  text="Delete"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-small shcbtn-outline-danger"
                  handleClick={this.removeItem.bind(this, keyId, value)}
                />
              </div>

            </div>
          </div>

          <div className={classNames({ flex: true, cursorPointer: true, padB10: true })}>

            <div className={classNames({ ShcModulesApiMgt_container: true })}>
              <div className={classNames({ ShcModulesApiMgt_keyText: true })}>
                {value}
              </div>
            </div>
    
          </div>
        </CardBasicV2>
      </div>

    );
  }
}

ApiManagementIpAddressItem.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,
  revealSecretKey,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ApiManagementIpAddressItem);
