import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';


// CSS
import componentStyles from './styles.css';

// Components
import { MenuAccount, MenuAdmin } from 'Components/Menu';
import ListItemCollapse from 'Components/ListItemCollapse';
import { SidebarNavLinks } from 'Components/ShcHeader';
import { SimpleDropdown } from 'Components/ShcMenus';
import { StandardBtn } from 'Components/ShcButtons';
import { StandardText } from 'Components/ShcText';

// Actions
import { sidebarOpenState } from 'Actions';


class ShcFooterPrimaryLayout extends React.Component {
  state = {
  };
  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const isAdmin = this.props.isAdmin;

    const classIconBars = "fas fa-bars";
    const classIconClose = "fas fa-times";
    const classIconAngleLeft = "fas fa-angle-left";
    
    return (
      <div className={classNames({shcFooterPrimaryLayout:true})}>
        <div className={classNames({shcFooterPrimaryLayout_content:true})}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ShcFooterPrimaryLayout.propTypes = {

};


const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  isAdmin: state.Auth.isAdmin,
  forceUserRegistration: state.User.forceUserRegistration,

})

const mapDispatchToProps = {
  sidebarOpenState,
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps)
)(ShcFooterPrimaryLayout);