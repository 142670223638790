import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
//import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { MessageHighlight } from 'Components/ShcText';
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';

import { BusinessConnectProfileSelectionItems } from 'Components/ShcAccountModules';

// Actions
//import {removeBusinessWebsiteVerificationItem } from 'Actions';


class BusinessConnectProfileSelection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    

    this.state = {
     
      collapse: true,     //If not active selection, expand for the user to select a profile
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }


  render() {
    //const {  } = this.props;


    /*
    const IdentityActivityHeading = () => {
      if (this.state.businessIdentity.type==="website") {
        return (
          <TextStyle size="h2">Website Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="email") {
        return (
          <TextStyle size="h2">Email Identity</TextStyle>
        );
      } else if (this.state.businessIdentity.type==="phone") {
        return (
          <TextStyle size="h2">Phone Identity</TextStyle>
        );
      } else {
        <TextStyle size="h2">Unknown Identity Type</TextStyle>
      }
    }

    const CurrentStatus = () => {
      if (this.state.businessIdentity.verificationStatus===true) {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_success: true })}>
            <i class="fas fa-check"></i>
          </div>
        );
      } else {
        return (
          <div className={classNames({ ShcStepsStatusContainer: true, bg_colour_danger: true })}>
            <i class="fas fa-times"></i>
          </div>
        );
      }
    }
    */

    return (
      <div className={classNames({ ShcAccountModulesBusinessConnectContainer: true })}>
        <div className={classNames({ ShcAccountModulesBusinessConnectContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>Selected Business Profile</div>
                  <div className={classNames({ text_h3: true })}>Auth Channel</div>
                  <div className={classNames({ text_h6: true })}>458345807234907234823</div>
                  
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width80: true, cursorPointer: true })}>
                <div className={classNames({ flex: true, flexJustifyCenter: true, 
                flexAlignItemsCenter: true, flexColumn: true })}>
                  <TextStyle size="h4" weight="normal">CHANGE</TextStyle>
                  <i class="fas fa-angle-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcAccountModulesBusinessConnectContainerBody: true })}>
          <div className={classNames({ flex: true})}>

            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Select a Business Profile</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              
            </div>
          </div>


          <div>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessConnectProfileSelectionItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessConnectProfileSelectionItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessConnectProfileSelectionItems/>
            </CardBasicV2>

            <CardBasicV2 padding="none" className={classNames({ contentPad10: false })}>
              <BusinessConnectProfileSelectionItems/>
            </CardBasicV2>


          </div>


        </div>
        }
        
      </div>

    );
  }
}

BusinessConnectProfileSelection.propTypes = {
  
};

const mapStateToProps = state => ({
  //token: state.BusinessVerification.businessVerify_tokenWebsite,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BusinessConnectProfileSelection);
