import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const uuidv1 = require('uuid/v1');


// CSS
import componentStyles from './styles.css';

// Components
import { InputTextV3 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';

// Actions
import { reduxDataUpdate, apiDataUpdate } from 'Actions';

// Functions
import { apiDataTransferTimer, validateDataType } from 'Helpers/Functions';


class LocationInputInternationalFormatV1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      address: this.props.value,

      identityInputErrors: false,
      identityNotificationOptionsAvailable: true,

    };
  }
    /* Attempt to get the address split into the following
    recipient.addressLine1 = "Address 1";
    recipient.addressLine2 = "Address 2";
    recipient.addressCity = "addressCity";
    recipient.addressState = "addressState";
    recipient.addressPostalCode = "code";
    recipient.addressCountry = "country";
    */
  
  handleChange = address => {
    //console.log("Handle change from PlacesAutoComplete")
    this.setState({ address });
    this.props.onChange(address);
  };
  
  handleSelect = address => {
    //console.log(address);
    this.setState({ address });
    this.props.onChange(address);





    //For geocoding
    /*
    geocodeByAddress(address)
    .then(results => {
      //console.log(results);
      
      getLatLng(results[0]);
    })
    .then(latLng => //console.log('Success', latLng))
    .catch(error => console.error('Error', error));
    */
  };

  
 
  render() {
    const { onChange } = this.props;

    return (
      <div>
        <InputCardItem headlineText="Attention" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Unit/Apartment" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Company Name" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Street Address" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Town" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="City" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Province" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="State" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        <InputCardItem headlineText="Postcode / Zip" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>
        
        <InputCardItem headlineText="Country" headlineSubText="" additionalText="">
          <InputTextV3 
            id="identities.businesses.$.businessIdentities.$.postal_attention" 
            name="identities.businesses.$.businessIdentities.$.postal_attention" 
            identifiers={[{'_id': this.state.businessId}, {'_id': this.state.businessId}]}
            stateIdentifier="BusinessIdentities"
            label='' 
            value={true}
            //onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
          />
        </InputCardItem>

        


      </div>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationInputInternationalFormatV1);
