import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import StepZilla from 'react-stepzilla';


// Routing

// Material UI

// CSS
import componentStyles from '../styles.css';

// Components
import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputText, InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';
import { MessageTip, MessageHighlight, HighlightContainer } from 'Components/ShcText';
import { PreferenceUserControls } from 'Components/ShcDropdowns';
import { TableListFacing } from 'Components/ShcTables';

//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';

// Actions
import { businessIdentitiesVerifyNow } from 'Actions';


class StepBusinessVerify extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.onChangeInputTextHandler = this.onChangeInputTextHandler.bind(this); //child


    //New - handle the verify identity now
    this.handleBusinessVerificationCheckWebsite = this.handleBusinessVerificationCheckWebsite.bind(this);

    this.state = {
      sharedState: {},  //state object
      businessIdentity: this.props.value,
      businessViewId: this.props.businessViewId,

      
      website: '',
      websitelabel: '',

      token: '',
      status: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps - StepBusinessWebsite");
    //We are comparing the inbound value (websiteStep) which is an object with the local state paramaters

    console.log(JSON.stringify(nextProps.value) +" !== "+ JSON.stringify(prevState.sharedState));
    if (nextProps.value !== undefined && nextProps.value !== prevState.sharedState) {
      return { sharedState: nextProps.value };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - StepBusinessWebsite");
    console.log(JSON.stringify(prevProps.value) +" !== "+ JSON.stringify(this.props.value));
    if(prevProps.value!==this.props.value){
      //Perform some operation here
      console.log("perform up of state for StepBusinessWebsite");
      this.setState({
        sharedState: this.props.value,
        website: this.props.value.website,
        websitelabel: this.props.value.websitelabel,

      });
    }
  }

  onChangeInputTextHandler = (evt) => {
    //Receive events from child inputs (InputText Component)
    console.log("===============================");
    console.log("SEND TO PARENT: inputTextChange");

    //Update local state (do not update fields that are shared with parent)
    this.setState({
      [evt.target.name]: evt.target.value,
    });

    let updateData= {
      field: evt.target.name,
      value: evt.target.value,
    }


    this.props.onChangeStepInputHandler(this.props.id, this.state.sharedState, updateData);

  }

  handleBusinessVerificationCheckWebsite(event) {
    event.preventDefault();
    console.log('verify this identity now.');
    let data = {
      businessId: this.state.businessViewId,
      businessIdentitiesId: this.state.businessIdentity._id,
    }
    this.props.businessIdentitiesVerifyNow({data});
  }


  render() {

    const { id, name, onChangeStepInputHandler, value } = this.props;
    /*
    const dataJSON_website = [
      { id: 1, title: 'Website', content: 'https://www.smarthome.cloud' },
    ];
    */
    const VerificationStatus = () => {
      let verificationStatusDate = this.state.businessIdentity.verificationStatusDate;
      let verificationAttemptDate = this.state.businessIdentity.verificationAttemptDate;
      if (this.state.businessIdentity.verificationStatus===true) {
        return (
          <TextStyle size="h4" weight="normal">Status: Verified ({verificationStatusDate})</TextStyle>   
        )
      } else {
        let notVerifiedDate = "";
        if (verificationAttemptDate!=="") {
          notVerifiedDate = verificationAttemptDate;
        } else {
          notVerifiedDate = "Not attempted";
        }
        return (
          <div>
            <TextStyle size="h4" weight="bold">Status:</TextStyle> <TextStyle size="h4" weight="normal">Not Verified ({notVerifiedDate})</TextStyle>
            <TextStyle size="h5" weight="normal">You have until 30/04/2019 to verify this 
            identity before it is automatically removed.</TextStyle>
          </div>
        )
      } 
    }

    const NextVerificationDate = () => {
      let nextRequiredVerificationDate = this.state.businessIdentity.nextRequiredVerificationDate;
      return (
        <TextStyle size="h4" weight="normal">We will attempt to verify this identity on 
        {nextRequiredVerificationDate} or nearby to this date.<br/>
        If this identity fails to verify, it will be automatically removed from your business
        profiles and marked as not verified. 
        
        </TextStyle>   
      )
    }

    

    console.log("THE VALUE FROM PARENT: "+JSON.stringify(value));

    return (
      <InputCard headlineText="Verification" headlineSubText="">
        <div>

          <VerificationStatus/><br/>
          <TextStyle size="h4" weight="bold">Verification for:</TextStyle>
          <TableListFacing data={[{id: 1, title: this.state.businessIdentity.type, content: this.state.businessIdentity.identity}]} />
          <br/>
          <NextVerificationDate/><br/>
 
          <hr className={classNames({ dividerHorizontalRule: true })} />
          <br/>
          <div>
            <StandardBtn
              icon=""
              iconLocation=""
              text="Verify now"
              tooltip=""
              action=""
              classes="shcbtn shcbtn-info"
              handleClick={this.handleBusinessVerificationCheckWebsite}
            />
          </div>
          <br/><br/>
          <hr className={classNames({ dividerHorizontalRule: true })} />
          <TextStyle size="h3" weight="normal">Having Problems?</TextStyle>          
          <NamedTextStyle name="para" >
            Once you have completed the verification steps which includes adding the relevant code to your website, you can then click the verify button.
            <br/><br/>
            We will attempt to make contact with the below website address to verify your identity. Please ensure the below address is correct.
          </NamedTextStyle >
        </div>
      </InputCard>
    );
  }
}

StepBusinessVerify.propTypes = {
  
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  businessIdentitiesVerifyNow
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StepBusinessVerify);
