import React from 'react';
import { Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from './BusinessRegistrationCheckoutForm';
// import InjectedCheckoutForm from './BusinessRegistrationCheckoutFormV2';
class BusinessRegistrationCheckout extends React.Component {
  render() {
    return (
      <Elements>
        <InjectedCheckoutForm />
      </Elements>
    );
  }
}

export default BusinessRegistrationCheckout;
