import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';

import Select from 'react-select';
import Dropdown from 'react-dropdown';

import Moment from 'react-moment';


import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';

import update from 'immutability-helper';

const ct = require('countries-and-timezones');


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextV4, CheckboxOptionCardReduxV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';



//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { 
  apiDataUpdate, reduxDataUpdate,
  deleteBusiness, requestImmediateBusinessDeletion, 
  removeBusinessContactIdentityFromAccount,
toggleOverlayOnScreenResponsive, addBusinessContactIdentityToAccount } from 'Actions';


// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';









/* below worked from an array perspective - however didn't do what I needed for state change.
function arrayPathToJson(arr, val) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;
  arr.slice().reverse().forEach(function(element) {
    //console.log(element);
    if (loop ===1) {
      childObj = {[element]:val};
      stateJsonMappingObj = childObj;
      loop++;
    } else {
      stateJsonMappingObj = {[element]:stateJsonMappingObj};
      loop++;
    }
  });
  return stateJsonMappingObj;
};

function arrayPathToJsonState(arr, val, prevState) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    //console.log("Next Parent");
    //console.log(nextParent);
    if (i===0) {
      childObj = {
        ...prevState[nextParent],
        [element]:val
      };
      stateJsonMappingObj = childObj;
      loop++;
      //console.log("next loop");
    } else {
      stateJsonMappingObj = {
        ...prevState[nextParent],
        [element]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};
*/

//Need to update to support arrays or objects ($push, $set)

function arrayPathToJsonStateUpdater(arr, val) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    //console.log(nextParent);
    if (i===0) {
      childObj = {
        "$set": val
      };
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      //console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

function arrayPathToJsonObjUpdate(arr, val, operator) {
  var stateJsonMappingObj = {};
  var childObj;
  var loop = 1;

  arr = arr.slice().reverse();
  for (var i = 0, len = arr.length; i < len; i++) {
    var nextParent = arr[i+1];
    //console.log(nextParent);
    if (i===0) {
      if (operator==="push") {
        childObj = {
          "$push": [val]
        };
      } else if (operator==="set") {
        childObj = {
          "$set": val
        };
      }
      
      stateJsonMappingObj = {[arr[i]] : childObj};
      loop++;
      //console.log("next loop");
    } else {
      stateJsonMappingObj = {
        [arr[i]]:stateJsonMappingObj
        };
      loop++;
    }
  }
  return stateJsonMappingObj;
};

function arrayPathToMongoDBQuery(arr, val, operator) {
  let parentObj;
  let childObj;
  let fieldPath;
  for (var i = 0, len = arr.length; i < len; i++) {
    //Create path to DB update field
    if (i===0) {
      fieldPath=""+arr[i];
    } else {
      fieldPath+="."+arr[i];
    }
  }

  childObj = {[fieldPath]: val};
  parentObj = {
    "$set": childObj
  }

  return parentObj;
};

function arrayPathToMongoDBQueryArrayPush(arr, val, operator) {
  let parentObj;
  let childObj;
  let fieldPath;
  for (var i = 0, len = arr.length; i < len; i++) {
    //Create path to DB update field
    if (i===0) {
      fieldPath=""+arr[i];
    } else {
      fieldPath+="."+arr[i];
    }
  }

  childObj = {[fieldPath]: val};
  parentObj = {
    "$push": childObj
  }

  return parentObj;
};

//New Mongodb set to array position
function arrayPathToMongoDBArrayPositionSet(arr, val, operator) {
  let parentObj;
  let childObj;
  let fieldPath;
  let valKey = Object.keys(val);
  let valVal = Object.values(val);
  for (var i = 0, len = arr.length; i < len; i++) {
    //Create path to DB update field (add key first)
    if (i===0) {
      fieldPath=""+arr[i];
    } else {
      fieldPath+="."+arr[i];
    }
  }
  fieldPath+="."+valKey;

  childObj = {[fieldPath]: valVal};
  parentObj = {
    "$set": childObj
  }

  return parentObj;
};

//New Mongodb set to array position (PULL FROM ARRAY)
function arrayPathToMongoDBArrayPositionPull(arr, val, operator) {
  let parentObj;
  let childObj;
  let fieldPath;
  for (var i = 0, len = arr.length; i < len; i++) {
    //Create path to DB update field (add key first)
    if (i===0) {
      fieldPath=""+arr[i];
    } else {
      fieldPath+="."+arr[i];
    }
  }

  childObj = {[fieldPath]: val};
  parentObj = {
    "$pull": childObj
  }

  return parentObj;
};




function RegExFieldPath(string) {
  var results = [];
  //get local state field
  var regex = /[a-zA-Z]+/g;
  var found = string.match(regex);
  results[0] = found[0]; //For State object, we only want the first field.

  //get local state path to update
  var path = string.replace(found[0], "");    //replace the field field that was found. (we may end up with a leading full stop)
  var regex = /^[.]/g;  //Remove leading fullstop.
  path = path.replace(regex, '');
  results[1] = path; //This is the remaining path

  //get the next path (item 2 which will be used for redux state)
  var regex = /[a-zA-Z]+/g;
  var found = path.match(regex);
  results[2] = found[0]; //For State object, we only want the first field.

  return results;
}


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)
    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); //new dynamic

    this.handleSelectInputChange_startTime = this.handleSelectInputChange_startTime.bind(this);
    this.handleSelectInputChange_endTime = this.handleSelectInputChange_endTime.bind(this);
    this.handleSelectInputChange_accountOptions_country = this.handleSelectInputChange_accountOptions_country.bind(this);
    this.handleSelectInputChange_accountOptions_timezone = this.handleSelectInputChange_accountOptions_timezone.bind(this);
    this.handleSelectInputChange_businessProfile_country = this.handleSelectInputChange_businessProfile_country.bind(this);

    
    //Supporters Directory
    this.handleCheckboxInput_dynamicUpdate = this.handleCheckboxInput_dynamicUpdate.bind(this);
    
    this.deleteBusiness = this.deleteBusiness.bind(this);
    this.requestImmediateBusinessDeletion = this.requestImmediateBusinessDeletion.bind(this);

    this.addBusinessContactIdentity = this.addBusinessContactIdentity.bind(this);
    this.addBusinessContactIdentityToAccountFromTable = this.addBusinessContactIdentityToAccountFromTable.bind(this);

    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
    this.removeBusinessContactFromAccount = this.removeBusinessContactFromAccount.bind(this);

    this.state = { 
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)




      businessName: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountBusinessDashboardPage");
    //console.log("prevState");
    //console.log(prevState);
    //console.log("nextProps");
    //console.log(nextProps);

    let updateState=false;

    if (nextProps.user !== prevState.user) {
      updateState=true;
    }

    if (updateState===true) {
      
      return {
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate");
    //console.log("forceUpdate()");
    this.forceUpdate();
   
  }

  deleteBusiness(evt) {
    //console.log('deleteBusiness');
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removalAgreementAcceptance1 = false;
    let removalAgreementAcceptance2 = false;
    let removalAgreementAcceptance3 = false;
    if (this.props.businessUiCheckboxOptionsValues.length > 0) {
      for (let x = 0; x < this.props.businessUiCheckboxOptionsValues.length; x++) {
        if (this.props.businessUiCheckboxOptionsValues[x].checkboxOptionsId === 'deleteBusiness_agreement1') {
          removalAgreementAcceptance1 = true;
        }
        if (this.props.businessUiCheckboxOptionsValues[x].checkboxOptionsId === 'deleteBusiness_agreement2') {
          removalAgreementAcceptance2 = true;
        }
        if (this.props.businessUiCheckboxOptionsValues[x].checkboxOptionsId === 'deleteBusiness_agreement3') {
          removalAgreementAcceptance3 = true;
        }
      }
    }

    const apiUi = { apiActivityId };
    const business = { businessId: this.state.businessId };
    const acceptance = {
      removalAgreementAcceptance1,
      removalAgreementAcceptance2,
      removalAgreementAcceptance3,
    };
    this.props.deleteBusiness({ business, acceptance, apiUi });

  }

  
  requestImmediateBusinessDeletion(evt) {
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;

    const apiUi = { apiActivityId };
    const business = { businessId: this.state.businessId };

    this.props.requestImmediateBusinessDeletion({ business, apiUi });

  }



  handleCheckboxInput_dynamicUpdate(receivedUpdateObj) {
    console.log("handleCheckboxInput_dynamicUpdate");
    console.log("receivedUpdateObj");
    console.log(receivedUpdateObj);

    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    this.props.reduxDataUpdate({recordsForUpdate});

    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
  }
  








































//{this.props.allIdentities.businesses.find(business => business.id === this.state.businessId).businessProfile.businessName}










  //NEW 201907
  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }

  addBusinessContactIdentity() {
    //console.log("addBusinessContactIdentity | Add a business contact");
    const data = {
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectBusinessContactIdentitiesItemToAdd', data);
  }

  addBusinessContactIdentityToAccountFromTable(rowDetails, evt) {
    //console.log("addBusinessContactIdentityToAccountFromTable");
    //console.log(rowDetails);

    this.props.toggleOverlayOnScreenResponsive('close', "selectBusinessContactIdentitiesItemToAdd");

    let selectedRecord = rowDetails.original;

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    /*
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    //console.log('businessId');
    //console.log(this.state.businessId);

    //console.log('profileId');
    //console.log(this.props.data._id);

    //console.log('identityId');
    //console.log(selectedRecord._id);
    */

    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessId,
      identityId: selectedRecord._id,
    };

    //console.log("ADD BUSINESS CONTACT IDENTITY TO ACCOUNT:");
    //console.log(business);
    this.props.addBusinessContactIdentityToAccount({business, apiUi});   //new Account Contact API
  }

  closeOverlayOnScreen = (overlayId) => {
    //console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }


  removeBusinessContactFromAccount(body = {}) {
    console.log("removeBusinessContactFromAccount");
    console.log(body);
    console.log(JSON.stringify(body));

    const { item = {}, apiUi = {} } = body;

    let business = {
      businessId: this.state.businessId,
      identityId: item.identityReferenceId,
    }
    this.props.removeBusinessContactIdentityFromAccount({business, apiUi});





  }

  /***********************************************************************
   * Identity Verification Notification Times - Temp Removed
   * 
   
  <InputCardItem headlineText="Identity Verification Notification Times" 
    headlineSubText="Select the time you would like to receive the automatic re-verification notifications">
      <div className={classNames({ flex: true })}>
        <div className={classNames({ minWidth110: true })}>
          <Select options={timeOptions} 
          onChange={this.handleSelectInputChange_startTime} 
          value={timeOptions.find(option => option.value === this.props.identityNotificationStartTime)}
          />
        </div>
        <div className={classNames({ minWidth110: true })}>
          <Select options={timeOptions} 
          onChange={this.handleSelectInputChange_endTime} 
          value={timeOptions.find(option => option.value === this.props.identityNotificationEndTime)}
          />
        </div>
      </div>
    </InputCardItem>


   */

  handleSelectInputChange_startTime(evt) {
    //console.log("handleSelectInputChange_startTime");
    //console.log(evt);
    let value = evt.value;

    
    let receivedUpdateObj = {
      name: 'identities.businesses.$.accountOptions.identityNotificationStartTime',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'accountOptions.identityNotificationStartTime',
      fieldValue: value,
      fieldRef: [],
      fieldStore: 'BusinessIdentities',
      storeId: [{_id: this.state.businessId}],
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }


  handleSelectInputChange_endTime(evt) {
    //console.log("handleSelectInputChange_endTime");
    //console.log(evt);
    let value = evt.value;

    let receivedUpdateObj = {
      name: 'identities.businesses.$.accountOptions.identityNotificationEndTime',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'accountOptions.identityNotificationEndTime',
      fieldValue: value,
      fieldRef: [],
      fieldStore: 'BusinessIdentities',
      storeId: [{_id: this.state.businessId}],
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }




  
  handleSelectInputChange_accountOptions_country(evt) {
    //console.log("handleSelectInputChange_accountOptions_country");
    //console.log(evt);
    let value = evt.value;

    let receivedUpdateObj = {
      name: 'identities.businesses.$.accountOptions.accountCountry',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'accountOptions.accountCountry',
      fieldValue: value,
      fieldRef: [],
      fieldStore: 'BusinessIdentities',
      storeId: [{_id: this.state.businessId}],
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }


  
  handleSelectInputChange_accountOptions_timezone(evt) {
    //console.log("handleSelectInputChange_accountOptions_timezone");
    //console.log(evt);
    let value = evt.value;

    let receivedUpdateObj = {
      name: 'identities.businesses.$.accountOptions.accountTimezone',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'accountOptions.accountTimezone',
      fieldValue: value,
      fieldRef: [],
      fieldStore: 'BusinessIdentities',
      storeId: [{_id: this.state.businessId}],
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }

  
  handleSelectInputChange_businessProfile_country(evt) {
    //console.log("handleSelectInputChange_businessProfile_country");
    //console.log(evt);
    let value = evt.value;

    let receivedUpdateObj = {
      name: 'identities.businesses.$.businessProfile.headOfficeCountry',
      identifiers: [{_id: this.state.businessId}],
      value: value,
      stateIdentifier: "BusinessIdentities",

      fieldName: 'accountOptions.headOfficeCountry',
      fieldValue: value,
      fieldRef: [],
      fieldStore: 'BusinessIdentities',
      storeId: [{_id: this.state.businessId}],
    }


    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }
    
  }






  render() {

    const { businessId } = this.state;
    const { userBusinessRoutePermissions, businessIdentities, businessAccountPostClosure, businessAccountClosed } = this.props;

    //Get List of All Countries
    const countries = ct.getAllCountries();
    //console.log("======================countries=====================");
    //console.log(countries);
    let countryOptionList = [];
    for (let [key, value] of Object.entries(countries)) {
      countryOptionList.push({ value: `${value.id}`, label: `${value.name}` });
    }

    //Get List of All TimeZones
    const timezones = ct.getAllTimezones();
    //console.log(timezones);
    let timezoneOptionList = [];
    for (let [key, value] of Object.entries(timezones)) {
      timezoneOptionList.push({ value: `${value.name}`, label: `${value.name}` });
    }


    let timeOptions = [];
    for (let t = 0; t <= 24; t++) {
      let hour = `0${t}`.slice(-2);
      timeOptions.push({ value: `${hour}:00`, label: `${hour}:00` });
      if (t !== 24) {
        timeOptions.push({ value: `${hour}:30`, label: `${hour}:30` });
      }
    }




    const BusinessIdentityList =() => {
      if (businessIdentities !== '') {
        let tableData = businessIdentities;
        //console.log("BusinessIdentityList UI Element");
        //console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Type",
                        accessor: "type"
                      },
                      {
                        Header: "Identity",
                        accessor: "identity"
                      },
                      {
                        Header: "Action",
                        Cell: row => (
                          <div>
                            
                            <StandardApiBtn
                              apiActivityId="AddBusinessContactIdentityToAccount"
                              icon=""
                              iconLocation=""
                              text="Add"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.addBusinessContactIdentityToAccountFromTable.bind(this, row)}
                            />
                            
                          </div>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return (
            <div className={classNames({ padT20: true })}>
              No identities found.<br/>
              Please add and verify an identity in the Identities section.
            </div>
          );
        }
        return (
          <div className={classNames({ padT20: true })}>
            No identities found.<br/>
            Please add and verify an identity in the Identities section.
          </div>
        );
      }
      return null;
    }

    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>


            
            <div>
              <InputCard headlineText="Business Profile" headlineSubText="This is your primary business details. Identities, trading names, etc are setup in the Identities section.">

                <InputCardItem headlineText="Business Name" headlineSubText="Please enter your business name" additionalText="">
                  <InputTextV3

                    id="identities.businesses.$.businessProfile.businessName" 

                    name="identities.businesses.$.businessProfile.businessName" 
                    identifiers={[{'_id': this.state.businessId}]}
                    stateIdentifier="BusinessIdentities"
                    fieldName='businessProfile.businessName'
                    fieldRef={[]}
                    fieldStore="BusinessIdentities"
                    storeId={this.state.businessId}
                    label='' 
                    value={this.props.businessIdentity.businessProfile.businessName}
                    onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                    
                  />
                </InputCardItem>

                <InputCardItem headlineText="Head Office Business Address" headlineSubText="" additionalText="">
                  <InputTextV3 
                    id="identities.businesses.$.businessProfile.businessAddress" 
                    name="identities.businesses.$.businessProfile.businessAddress" 
                    identifiers={[{'_id': this.state.businessId}]}
                    stateIdentifier="BusinessIdentities"
                    fieldName='businessProfile.businessAddress'
                    fieldRef={[]}
                    fieldStore="BusinessIdentities"
                    storeId={this.state.businessId}
                    label='' 
                    value={this.props.businessIdentity.businessProfile.businessAddress}
                    onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                  />
                </InputCardItem>

                <InputCardItem headlineText="Head Office Business Location" headlineSubText="Which country is your business head office located in?" additionalText="">
    
                    <div className={classNames({ flex: true,})}>
                      <div className={classNames({ minWidth350: true })}>
                        <Dropdown 
                        options={countryOptionList} 
                        onChange={this.handleSelectInputChange_businessProfile_country} 
                        value={countryOptionList.find(option => option.value === this.props.businessIdentity.businessProfile.headOfficeCountry)}
                        placeholder="Select an option" />
                      </div>
                    </div>
                  
                </InputCardItem>

                <InputCardItem headlineText="Primary Country Services Provided To" headlineSubText="Which country do you primarily provide services to?" 
                additionalText="If you provide services to multiple countries, please select 'Global'.">
                  <InputTextV3 
                    id="identities.businesses.$.businessProfile.businessServicesProvidedToCountry" 
                    name="identities.businesses.$.businessProfile.businessServicesProvidedToCountry" 
                    identifiers={[{'_id': this.state.businessId}]}
                    stateIdentifier="BusinessIdentities"
                    fieldName='businessProfile.businessServicesProvidedToCountry'
                    fieldRef={[]}
                    fieldStore="BusinessIdentities"
                    storeId={this.state.businessId}
                    label='' 
                    value={this.props.businessIdentity.businessProfile.businessServicesProvidedToCountry}
                    onChangeInputTextHandler={this.onChangeDynamicInputTextHandler} 
                  />
                </InputCardItem>


              </InputCard>
            </div>
            
            <div>
              <InputCard headlineText="Business Contacts" headlineSubText="Your primary business contacts related to this business.">
                <div className={classNames({ padT10: true })}>
                  To ensure the smooth operation of your businesss on this platform, please ensure you have email addresses
                  assigned to a Primary, Billing and Technical contact.<br/>
                </div>
                <div className={classNames({ padT10: true })}>
                  <StandardApiBtn
                    apiActivityId="AddNewBusinessContactIdentity"
                    icon=""
                    iconLocation=""
                    text="Add Business Contact"
                    tooltip=""
                    action=""
                    classes="shcbtn shcbtn-primary"
                    handleClick={this.addBusinessContactIdentity}
                  />
                  <br/>
                </div>

                {
                this.props.businessIdentity.businessContacts.length > 0 ?
                    // Arrow function preserves "this"
                  this.props.businessIdentity.businessContacts.map((item, i) => {
                    //console.log("Print business Contacts");
                    //console.log(i);
                    //console.log(JSON.stringify(item));
                    //console.log(JSON.stringify(this.props.businessIdentity.businessContacts));

                    ///////////let dataValue = this.props.businessIdentity.businessContacts.find(contact => contact._id === item._id);
                    //console.log(dataValue);

                    /////////let identityReferenceId = dataValue.identityReferenceId;
                    ////////let identityContactType = dataValue.identityContactType;
                    let identityReferenceId = item.identityReferenceId;
                    let identityContactType = item.identityContactType;

                    let identityDetails = this.props.businessIdentity.businessIdentities.find(businessIdentity => businessIdentity._id === item.identityReferenceId);
                    //console.log(dataValue);

                    console.log("============= identityDetails ==============");
                    console.log(identityDetails);

                    let dataSet = {};
                    if (identityDetails !== null && identityDetails !== undefined) {
                      //API CALL - REMOVE THIS Business Contact - It has no active mapping to a identity.

                      //TODO:


                      dataSet = {
                        businessContactsId: item._id,
                        identityContactType: identityContactType,  //Primary Contact, Billing Contact etc
                        identityReferenceId: identityDetails._id,
                        identity: identityDetails.identity,
                        type: identityDetails.type,
                        verificationStatus: identityDetails.verificationStatus, 
                      }
                    }

                    


                    return (
                    <li key={i} className={classNames({ listNone: true })}>
                       <BusinessContactsIdentitySelection
                        businessId={this.state.businessId}
                        identityReferenceId={identityReferenceId}
                        identityContactType={identityContactType}
                        data={dataSet}
                        onRemoveItem={this.removeBusinessContactFromAccount}
                      />
                    </li>
                    )

                  }, this)
                : null
                }

                

              </InputCard>
            </div>


            <div>
              <InputCard headlineText="Account Settings" headlineSubText="">
                <div className={classNames({ padT10: true })}>

                </div>

                <InputCardItem headlineText="Supporters Directory" 
                headlineSubText="Add your busines to our supporters directory">
                  <div className={classNames({ flex: true })}>

                    <CheckboxOptionCardReduxV1
                    businessId={this.state.businessId}
                    checkboxOptionsId="accountSettings_supportersDirectory"
                    checkboxText="Add my business to the platforms supporters directory"
                   
                    name="identities.businesses.$.accountOptions.platformSupportersPage"
                    identifiers={[{'_id': this.state.businessId}]}
                    stateIdentifier="BusinessIdentities"
                    fieldName='accountOptions.platformSupportersPage'
                    fieldRef={[]}
                    fieldStore="BusinessIdentities"
                    storeId={this.state.businessId}

                    onChangeInputHandler={this.handleCheckboxInput_dynamicUpdate}
                    />
                    
                  </div>
                </InputCardItem>

                <InputCardItem headlineText="Business Directory" 
                headlineSubText="Add your busines to the business directory. Customers can lookup to see your business listing.">
                  <div className={classNames({ flex: true })}>

                    <CheckboxOptionCardReduxV1
                    businessId={this.state.businessId}
                    checkboxOptionsId="accountSettings_businessDirectory"
                    checkboxText="Add my business to the platforms business directory listings"
                   
                    name="identities.businesses.$.accountOptions.platformBusinessDirectory"
                    identifiers={[{'_id': this.state.businessId}]}
                    stateIdentifier="BusinessIdentities"
                    fieldName='accountOptions.platformBusinessDirectory'
                    fieldRef={[]}
                    fieldStore="BusinessIdentities"
                    storeId={this.state.businessId}

                    onChangeInputHandler={this.handleCheckboxInput_dynamicUpdate}
                    />

                  </div>
                </InputCardItem>

               
                

              </InputCard>
            </div>

            
            <div>
              <InputCard headlineText="Your Business Data" headlineSubText="Where we store your business data">
                <TextStyle size="h4">You have selected to store your information in the following region:</TextStyle>
                <br/>
                <div className={classNames({ ShcIdentityInput_textNoInput: true, })}>
                  {this.props.businessDataStorageRegion}
                </div>
                <br/>
                <TextStyle size="h5">To change regions, you will need to delete the business account and re-create it.</TextStyle>
              </InputCard>
            </div>


            <div>
              <InputCard headlineText="Business Account Status" headlineSubText="Your business will either be in the state of Active, Post-Closure or Closed.">

                {this.props.businessIdentity.accountClosed !== true &&
                this.props.businessIdentity.accountPostClosure === true && (
                  <div>
                    <div> 
                      <TextStyle size="h3">Account Status: Post-Closure</TextStyle>
                    </div>
                    <br/>
                    <div>Business Requested Closure: &nbsp;
                      <Moment unix  format="DD/MM/YYYY hh:mm A">{this.props.businessIdentity.accountPostClosureDate}</Moment>  
                    </div>
                    <div>Business Removal Date: &nbsp;
                      <Moment unix  format="DD/MM/YYYY hh:mm A">{this.props.businessIdentity.accountClosureTargetDate}</Moment>        
                    </div>
                    <br/>
                    <div> 
                      <TextStyle size="h5">This business account is currently in the Post-Closure phase. 
                      This means the business account is in the process of being removed. During the 
                      Post-Closure phase you will be able to access the account information and invoices as is 
                      however you will not be able to change any data or use our services.
                      </TextStyle>
                      <TextStyle size="h5">
                        You are unable to re-activate this account.
                      </TextStyle>
                    </div>
                    <br/>
                    

                  </div>
                )}
                {this.props.businessIdentity.accountClosed === true && (
                  <div>
                    <div> 
                      <TextStyle size="h3">Account Status: Closed</TextStyle>
                    </div>
                    <br/>
                    <div>Business Requested Immediate Closure: &nbsp;
                      <Moment unix  format="DD/MM/YYYY hh:mm A">{this.props.businessIdentity.accountClosedDate}</Moment>  
                    </div>
                    <div>Business Removal Date by: &nbsp;
                      <Moment unix  format="DD/MM/YYYY hh:mm A">{this.props.businessIdentity.accountClosureTargetDate}</Moment>        
                    </div>
                    <br/>
                    <div> 
                      <TextStyle size="h5">This business account is currently in the Closed phase. 
                      This means the business account is in the process of being removed. 
                      You will lose access to this business once our business removal process is run.
                      </TextStyle>
                      <TextStyle size="h5">
                        You are unable to re-activate this account.
                      </TextStyle>
                    </div>
                    <br/>

                  </div>
                )}
                {this.props.businessIdentity.accountClosed !== true &&
                this.props.businessIdentity.accountPostClosure !== true && (
                  <div>
                    <div> 
                      <TextStyle size="h3">Account Status: Active</TextStyle>
                    </div>
                    <br/>
                  </div>
                )}

                
                
              </InputCard>
            </div>

            {this.props.businessIdentity.accountPostClosure === true ? (
              <div></div>
            ):(
              <div>
                {this.props.businessIdentity.accountClosed === true ? (
                    <div></div>
                  ):(
                    <div>
                      <InputCard headlineText="Delete Business" headlineSubText="Only the authorised business owner role can delete this business.">

                        {userBusinessRoutePermissions.includes("owner") ? 
                        (
                          <div>
        
                            <CheckboxOptionCardReduxV1
                            businessId={this.state.businessId}
                            checkboxOptionsId="deleteBusiness_agreement1"
                            checkboxText="I understand that by clicking this checkbox, I am deleting this business account and canceling all associated services. 
                            I understand that all users that are setup within this business (excluding users with the 'Owner' role) will be removed from this business account. 
                            I will be provided an account finalisation invoice for this business account which will include any usage costs for the period or exit fees."
                            />

                            <CheckboxOptionCardReduxV1
                            businessId={this.state.businessId}
                            checkboxOptionsId="deleteBusiness_agreement2"
                            checkboxText="I understand that after selecting my business to be deleted that my business account will move into a Post-Closure account status
                            where I will not be able to make any changes or use the services this platform offers. The Post-Closure phase ensures any pre-existing 
                            interactions are accessible to customers up until the interactions expires or a decision is made to move the account to the Closed phase. 
                            During the Post-Closure phase you will be able to view the current information including any invoices."
                            />

                            <CheckboxOptionCardReduxV1
                            businessId={this.state.businessId}
                            checkboxOptionsId="deleteBusiness_agreement3"
                            checkboxText="I understand that once my business account transitions to the Closed account status that I 
                            will not be able to log into or use this business account. The associated business account information will be removed
                            and will not be able to be recovered."
                            />

                            <br/><br/>
                            <StandardApiBtn
                              apiActivityId="deleteBusiness"
                              icon=""
                              iconLocation=""
                              text="Delete Business"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-primary"
                              handleClick={this.deleteBusiness}
                            />


                          </div>
                        ):(
                          <div>
                            Your current role does not allow you to delete this business. Please speak with the business user with the Owner role.
                          </div>
                        )}

                      </InputCard>
                    </div>
                  )}
              </div>
            )}
            
            
            
            
  
            

            <div>
              <InputCard headlineText="Account Support" headlineSubText="">
                For account support, please log a support case within in support portal.
              </InputCard>
            </div>

 




            <br/>
  
              

            <OverlayOnScreenResponsive
              id="selectBusinessContactIdentitiesItemToAdd"
              type="default"
              width="50%"
              height="100vh"
              anchor="right"
              title="Add a Business Contact Identity"
              classes=""
            >
              <div className={classNames({ pad10: true })}>
                <div className={classNames({ abc: true })}>
                  <TextStyle size="h3">Identities</TextStyle>
                  <TextStyle size="h5">Select an identity to add to this profile.</TextStyle>
                </div>
                <div className={classNames({ abc: true })}>

                  <BusinessIdentityList/>

                </div>

        
                <br/>
                <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
                  <div className={classNames({ abc: true })}>
                    <StandardBtn
                      icon=""
                      iconLocation=""
                      text="Close"
                      tooltip=""
                      action=""
                      classes="shcbtn shcbtn-secondary"
                      handleClick={() => this.closeOverlayOnScreen('selectBusinessContactIdentitiesItemToAdd')}
                    />
                  </div>
                  <div className={classNames({ abc: true })}>
                    
                  </div>
                </div>

              </div>
            </OverlayOnScreenResponsive>
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = '';
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  let accountOptions = {};
  try {
    accountOptions = businessIdentity.accountOptions;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business account options');
  }
  let identityNotificationStartTime = {};
  try {
    identityNotificationStartTime = accountOptions.identityNotificationStartTime;
  } catch (e) {}
  let identityNotificationEndTime = {};
  try {
    identityNotificationEndTime = accountOptions.identityNotificationEndTime;
  } catch (e) {}
  let accountCountry = {};
  try {
    accountCountry = accountOptions.accountCountry;
  } catch (e) {}
  let accountTimezone = {};
  try {
    accountTimezone = accountOptions.accountTimezone;
  } catch (e) {}



  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  let businessUiCheckboxOptionsValues = [];
  if (state.UI.uiCheckboxOptions) {
    //console.log('mapStateToProps | uiCheckboxOptions is true');
    for (let z = 0; z < state.UI.uiCheckboxOptions.length; z++) {
      //console.log(`mapStateToProps | seaching for business id: ${state.UI.uiCheckboxOptions[z].businessId} === ${businessId}`);
      try {
        if (state.UI.uiCheckboxOptions[z].businessId.toString() === businessId.toString()) {
          businessUiCheckboxOptionsValues.push(state.UI.uiCheckboxOptions[z]);
        }
      } catch (e) {}
      
    }
  } else {
    checkboxSelectedState = false;
    //console.log('mapStateToProps | Checkbox state not found');
  }


  let businessDataStorageRegion = '';
  try {
    businessDataStorageRegion = businessIdentity.dataRegion;
  } catch (e) {}
  if (businessDataStorageRegion === 'aus') {
    businessDataStorageRegion = "Australia";
  } else if (businessDataStorageRegion === 'usa') {
    businessDataStorageRegion = "United States";
  }


  let businessAccountPostClosure = false;
  try {
    businessAccountPostClosure = businessIdentity.accountPostClosure;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive account info');
  }

  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }
  

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
    businessAccountPostClosure: businessAccountPostClosure,

    businessUiCheckboxOptionsValues: Object.assign([], businessUiCheckboxOptionsValues),  //Allows the lookup of what checkbox options have been selected.
  

    identityNotificationStartTime,
    identityNotificationEndTime,
    accountCountry,
    accountTimezone,

    businessDataStorageRegion,
  
  };

};


const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  deleteBusiness,
  requestImmediateBusinessDeletion,
  removeBusinessContactIdentityFromAccount,


  toggleOverlayOnScreenResponsive,
  addBusinessContactIdentityToAccount,


};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)



/* IMMEDIATE CLOSURE HAS BEEN REMOVED AS PART OF LEGAL PROCESS
<div>
  <StandardApiBtn
  apiActivityId="requestImmediateBusinessDeletion"
  icon=""
  iconLocation=""
  text="Request Immediate Business Deletion"
  tooltip=""
  action=""
  classes="shcbtn shcbtn-primary"
  handleClick={this.requestImmediateBusinessDeletion}
  />
</div>
*/

/* REMOVE TEMPERORILY FROM THE PLATFORM

<InputCardItem headlineText="Country" 
headlineSubText="Country referenced for our platform.">
  <div className={classNames({ flex: true,})}>
    <div className={classNames({ minWidth350: true })}>
      <Select options={countryOptionList} 
      onChange={this.handleSelectInputChange_accountOptions_country} 
      value={countryOptionList.find(option => option.value === this.props.accountCountry)}
      />
    </div>
  </div>
</InputCardItem>

<InputCardItem headlineText="Timezone" 
headlineSubText="Timezone used for our platform contacting your business.">
  <div className={classNames({ flex: true, minWidth250: true })}>
    <div className={classNames({ minWidth350: true })}>
      <Select options={timezoneOptionList} 
      onChange={this.handleSelectInputChange_accountOptions_timezone} 
      value={timezoneOptionList.find(option => option.value === this.props.accountTimezone)}
      />
    </div>
  </div>
</InputCardItem>


<InputCardItem headlineText="Identity Verification Notification Times" 
headlineSubText="Select the time you would like to receive the automatic re-verification notifications">
  <div className={classNames({ flex: true })}>
    <div className={classNames({ minWidth110: true })}>
      <Select options={timeOptions} 
      onChange={this.handleSelectInputChange_startTime} 
      value={timeOptions.find(option => option.value === this.props.identityNotificationStartTime)}
      />
    </div>
    <div className={classNames({ minWidth110: true })}>
      <Select options={timeOptions} 
      onChange={this.handleSelectInputChange_endTime} 
      value={timeOptions.find(option => option.value === this.props.identityNotificationEndTime)}
      />
    </div>
  </div>
</InputCardItem>

*/