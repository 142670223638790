import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Routing

// CSS
import componentStyles from './Styles.css';

// Components

// Actions
import { toggleOverlayOnScreenResponsiveLoading } from 'Actions';




class OverlayOnScreenResponsiveLoading extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickClose(evt) {
    //console.log('onClick Close');
    this.props.toggleOverlayOnScreenResponsiveLoading('close', this.props.id);
    evt.stopPropagation();
  }


  onMouseOver(evt) {
    //console.log('onMouseOver (button)');
    // timeout before hiding - keep resetting the timeout each time we mouse over
  }

  onMouseLeave(evt) {
    //console.log('onMouseLeave (button)');
  }

  onMouseOut(evt) {
    //console.log('onMouseOut (button)');
  }

  onClick(evt) {
    //console.log('onClick (button)');
    //console.log(evt);
  }


  handleAddChip(chip) {
    //console.log('addign chip');
  }
  handleDeleteChip(chip, index) {
    //console.log('deleting chip');
  }

  
  render() {
    const overlayOnScreenResponsiveLoading = this.props.overlayOnScreenResponsiveLoading;


    const {
      id, type, classes, listener, width, height, anchor, title,
    } = this.props;

    let containerWidth;
    let containerHeight;
    let containerAnchor = {};

    let defaultStyle;

    if (type === 'default') {
      // default modal - use standard settings

      containerWidth = {};
      containerHeight = {};
      containerAnchor = {};

      defaultStyle = true;


    } else if (type === 'custom') {
      // custom modal - take paramaters from attributes
      defaultStyle = false;

      containerWidth = {
        width: `${width}`,
        webkitTransition: 'width 2s',
        transition: 'width 2s',
      };
      containerHeight = {
        height: `${height}`,
      };
      if (anchor === 'all') {
        containerAnchor = {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        };
      }
      if (anchor === 'left') {
        containerAnchor = {
          left: 0,
        };
      }
      if (anchor === 'right') {
        containerAnchor = {
          right: 0,
        };
      }
      if (anchor === 'top') {
        containerAnchor = {
          top: 0,
        };
      }
      if (anchor === 'bottom') {
        containerAnchor = {
          bottom: 0,
        };
      }

    }





    let popupPanel;
    // if (propertyPopupPanelInfo) {
    if (overlayOnScreenResponsiveLoading === id) {
      popupPanel = (


        <div
          className={classNames({ OverlayOnScreenResponsiveLoading_Container: true, OverlayOnScreenResponsiveLoading_Container_defaultStyle: defaultStyle })}
          style={Object.assign(containerWidth, containerHeight, containerAnchor)}
          onClick={(evt) => {
              evt.stopPropagation();
            }}
        >

          <div className={classNames({ OverlayOnScreenResponsiveLoading_ContainerContent: true })}>

            {this.props.children}

          </div>

        </div>

      );
    } else {
      popupPanel = null;
    }

    return (
      <div className={classNames({ OverlayScreenContainerBasePosition: true, OverlayScreenContainer: popupPanel !== null })}>
        <div
          className={classNames({ OverlayBaseBg: popupPanel !== null })}
          onClick={(evt) => {
            this.onClickClose(evt);
          }}
        >
          <ReactCSSTransitionGroup
            transitionName="OverlayOnScreenResponsive"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
          >
            {popupPanel}
          </ReactCSSTransitionGroup>
        </div>
      </div>

    );
  }
}
// https://reactjs.org/docs/animation.html

OverlayOnScreenResponsiveLoading.propTypes = {

};

const mapStateToProps = state => ({
  overlayOnScreenResponsiveLoading: state.CommonActions.overlayOnScreenResponsiveLoading,
});

const mapDispatchToProps = {
  toggleOverlayOnScreenResponsiveLoading,

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OverlayOnScreenResponsiveLoading);
