import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';

import ReactTable from "react-table";
import "react-table/react-table.css";

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { IdentityInputV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem, SingleLineCardItem } from 'Components/ShcCards';
import { InputText, InputTextV2, InputTextV3 } from 'Components/ShcInput';

import { VerifiedIdentityView } from 'Components/ShcModulesIdentity';
import { OverlayOnScreenResponsive  } from 'Components/ShcPopups';


// Actions
import { addBusinessIdentityAssociation, 
  removeBusinessIdentityAssociation,
  toggleOverlayOnScreenResponsive, reduxDataUpdate, apiDataUpdate } from 'Actions';
//apiDataUpdate is required when using apiDataTransferTimer.

// Functions
import { apiDataTransferTimer } from 'Helpers/Functions';

class VerifiedProfileInput extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this); // Handles data input changes (delaying until no entry before calling api)
    this.closeOverlayOnScreen = this.closeOverlayOnScreen.bind(this);
   
    this.removeIdentityFromProfile = this.removeIdentityFromProfile.bind(this);

    this.onChangeDynamicInputTextHandler = this.onChangeDynamicInputTextHandler.bind(this); //new dynamic


    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    
    this.state = {
      apiTimerCollection: [], // List of active timers.


      collapse: true,


      businessId: this.props.businessId,
      profileId: this.props.profileId,
      businessIdentities: [],


    };
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
  }

  componentDidUpdate(prevProps, prevState) {
  }

  collapseContainer = (evt) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  onChangeDynamicInputTextHandler(receivedUpdateObj) {
    //console.log("onChangeDynamicInputTextHandler");
    let recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));    
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core", 
      }
    ];
    */
    /************** LOCAL STATE DATA UPDATES *************/

    this.props.reduxDataUpdate({recordsForUpdate});

    /************** BACKEND DATA UPDATES *****************/
    //Each time there is a change, set a timer for a DB update to follow.
    
    //Traverse through every record that is being updated.
    for(let x=0; x<recordsForUpdate.length; x++) {
      //Get the "Name" which represents the dotPath for the update.
      //Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      let timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));
      //this.apiDTimer(timerReferenceGroup, recordsForUpdate[x]);
      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }

  }

  removeItem(profileId, evt) {
    //console.log("removeItem");
    //console.log(profileId);
    //console.log("evt");
    //console.log(evt);
    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    let removeItem = {
      profileId,
      apiActivityId,
    }
    this.props.onRemoveItem({removeItem});
  }

  addBusinessIdentityToProfile(profileId, event) {
    const data = {
      profileId: profileId,
      //tableData: tableData,
    };
    //Show the Add Identities Popup.
    this.props.toggleOverlayOnScreenResponsive('open', 'selectBusinessIdentitiesItemToAdd', data);

  }
  removeIdentityFromProfile(body) {
    //console.log("removeIdentityFromProfile | disassociate a business identity");
    const { item, apiUi } = body;

    const business = { 
      businessId: this.state.businessId,
      profileId: item.profileId,
      identityId: item.identityId,
     };

    //console.log("ADD BUSINESS IDENTITY TO PROFILE:");
    //console.log(business);
    this.props.removeBusinessIdentityAssociation({business, apiUi});
 
  }


  onChangeHandler(receivedUpdateObj) {
    //console.log('onChangeHandler');
    const recordsForUpdate = [];
    recordsForUpdate.push(receivedUpdateObj);
    //console.log(JSON.stringify(recordsForUpdate));
    /*
    let recordsForUpdate = [
      {
        name: 'test5.nested1.arr3.$.prop1',
        identifiers: [{name: 'a'}],
        value: "aabbccdd",
        stateIdentifier: "Core",
      }
    ];
    */
    this.props.reduxDataUpdate({ recordsForUpdate });
    // Traverse through every record that is being updated.
    for (let x = 0; x < recordsForUpdate.length; x++) {
      // Get the "Name" which represents the dotPath for the update.
      // Get the "stateIdentifer" which represents the timerRefrenceGroup (e.g. User records are consolidated)
      const timerReferenceGroup = recordsForUpdate[x].stateIdentifier;
      //console.log(timerReferenceGroup);
      //console.log(JSON.stringify(recordsForUpdate[x]));

      this.apiDataTransferTimer(timerReferenceGroup, recordsForUpdate[x]);
    }


  }
  onRemoveHandler() {

  }

  addBusinessIdentityToProfileFromTable(rowDetails, overlayProfileId, evt) {
    //console.log("addBusinessIdentityToProfileFromTable");
    //console.log(rowDetails);

    this.props.toggleOverlayOnScreenResponsive('close', "selectBusinessIdentitiesItemToAdd");

    let selectedRecord = rowDetails.original;

    const apiActivityId = evt.currentTarget.dataset.apiactivityid;
    /*
    //console.log('apiActivityId');
    //console.log(apiActivityId);

    //console.log('businessId');
    //console.log(this.state.businessId);

    //console.log('profileId');
    //console.log(this.props.data._id);

    //console.log('identityId');
    //console.log(selectedRecord._id);
    */

    const apiUi = { apiActivityId };
    const business = { 
      businessId: this.state.businessId,
      //profileId: this.props.data._id,
      profileId: overlayProfileId,    //NEW
      identityId: selectedRecord._id,
     };

    console.log("ASSOCIATE BUSINESS IDENTITY TO PROFILE (ADD OR REMOVE)");
    console.log(`overlayProfileId: ${overlayProfileId} | ${this.props.overlayProfileId}`);
    console.log(business);
    this.props.addBusinessIdentityAssociation({business, apiUi});
  }

  closeOverlayOnScreen = (overlayId) => {
    ////console.log("close the overlay screen");
    this.props.toggleOverlayOnScreenResponsive('close', overlayId);
  }

  

  render() {
    const { businessId, businessIdentities, stateIdentifier, identifiers, id, name, data, onChangeHandler,
      onRemoveItem,
    } = this.props;

    let businessIdentityProfileList = data.profileAttributes;
    
    let identity = "";

    let profileId = "";
    let profileName = "";
    let profileLabel = "";
    let profileDesc = "";
    let profileAttributes = [];

    try { profileId = data._id; } catch(e) {}
    try { profileName = data.profileName; } catch(e) {}
    try { profileLabel = data.profileLabel; } catch(e) {}
    try { profileDesc = data.profileDesc; } catch(e) {}
    try { profileAttributes = data.profileAttributes; } catch(e) {}
    
    let IdentityContactTypesList = [];
    let businessCardProfileCustomerView = [];
    try { 
      for(let x=0; x < profileAttributes.length; x++) {
        for(let y=0; y < this.props.businessIdentity.businessIdentities.length; y++) {
          if ( this.props.businessIdentity.businessIdentities[y]._id === profileAttributes[x].identityReferenceId) {
            //Found a profileAttribute ID that matches an identity ID.
            businessCardProfileCustomerView.push({
              _id: this.props.businessIdentity.businessIdentities[y]._id,
              type: this.props.businessIdentity.businessIdentities[y].type,
              identity: this.props.businessIdentity.businessIdentities[y].identity,
            });

            var item = IdentityContactTypesList.find(x => x === this.props.businessIdentity.businessIdentities[y].type);
            if (!item) {
              IdentityContactTypesList.push(this.props.businessIdentity.businessIdentities[y].type);
            }
          }
        }
      }
    } catch(e) {
      console.error("Unable to process profile identities.");
    }


    


    let headlineText = profileName;
    if (headlineText === "") {
      headlineText = "Setup New Profile";
    }
    let headlineProfileId = profileId;
    let headlineProfileIdentityTypes = IdentityContactTypesList.join(', ');
    if (headlineProfileIdentityTypes === "") {
      headlineProfileIdentityTypes = "There are currently no assocaited identities.";
    }

    const BusinessIdentityList =() => {
      if (businessIdentities !== '') {
        //Only show the verified identities to add to a profile
        let tableData = businessIdentities.filter(record => record.verificationStatus === true);

        //console.log("BusinessIdentityList UI Element");
        //console.log(tableData);
        if (typeof(tableData)!==undefined && tableData!==null) {
          if (tableData.length>0) {
            return (
              <ReactTable
                data={tableData}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Type",
                        accessor: "type"
                      },
                      {
                        Header: "Identity",
                        accessor: "identity"
                      },
                      {
                        Header: "Add",
                        Cell: row => (
                          <div>
                            
                            <StandardApiBtn
                              apiActivityId="AddBusinessIdentityToProfile"
                              icon=""
                              iconLocation=""
                              text="Add"
                              tooltip=""
                              action=""
                              classes="shcbtn shcbtn-small shcbtn-primary"
                              handleClick={this.addBusinessIdentityToProfileFromTable.bind(this, row, this.props.overlayProfileId)}
                            />
                            
                          </div>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            )
          }
          return "No verified identities found.";
        }
        return "No verified identities found.";
      }
      return null;
    }

    return (
      <div className={classNames({ ShcModulesIdentityContainer: true })}>
        <div className={classNames({ ShcModulesIdentityContainerHeader: true, flex: true, cursorPointer: true })}
        onClick={this.collapseContainer}>
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>{headlineProfileId}</div>
                  <div className={classNames({ text_h3: true })}>{headlineText}</div>
                  <div className={classNames({ text_h6: true })}>{headlineProfileIdentityTypes}</div>
                    
                </div>
              </div>
              <div className={classNames({ flex: true, flexJustifyCenter: true, flexAlignItemsCenter: true, 
                width30: true, cursorPointer: true })}>
                {this.state.collapse ?
                  <div><i class="fas fa-angle-down"></i></div>
                :
                <div><i class="fas fa-angle-up"></i></div>
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.collapse ?
        null
        :
        <div className={classNames({ ShcModulesIdentityStatusContainerBody: true })}>
          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardApiBtn
                apiActivityId={`RemoveBusinessProfile_${profileId}`}
                icon=""
                iconLocation=""
                text="Delete Profile"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-small shcbtn-secondary"
                handleClick={this.removeItem.bind(this, profileId)}
              />
            </div>
          </div>


          <div className={classNames({ aaa: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Profile</TextStyle>
              <TextStyle size="h5" weight="normal">The profile name is only for your reference.</TextStyle>
            </div>
            
            <div>
              <InputCardItem headlineText="Profile Name" headlineSubText="" additionalText="">
                <InputTextV3
                  id="identities.businesses.$.businessIdentityProfiles.$.profileName"
                  name="identities.businesses.$.businessIdentityProfiles.$.profileName"
                  identifiers={[{'_id': this.state.businessId}, {'_id': profileId}]}
                  stateIdentifier="BusinessIdentities"
                  fieldName='businessIdentityProfiles.$[profileId].profileName'
                  fieldRef={[{'profileId._id': profileId}]}
                  fieldStore="BusinessIdentities"
                  storeId={this.state.businessId}
                  label=""
                  value={profileName}
                  onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
                />
              </InputCardItem>

              
            </div>
            
            
            
          </div>


          <div className={classNames({ borderTLight: true, marLRMinus10: true })}>
          </div>



          <div className={classNames({ flex: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Business Identities</TextStyle>
              <TextStyle size="h5" weight="normal">These are the business identities associated with this profile.</TextStyle>
            </div>
            <div className={classNames({ padT10: true })}>
              <StandardApiBtn
                apiActivityId="AddBusinessIdentityToProfile"
                icon=""
                iconLocation=""
                text="Add Identity"
                tooltip=""
                action=""
                classes="shcbtn shcbtn-primary"
                handleClick={this.addBusinessIdentityToProfile.bind(this, this.state.profileId)}
              />
            </div>
          </div>
          <div>
            <div>

              {
              businessIdentityProfileList && 
              businessIdentityProfileList.length > 0 ?
                // Arrow function preserves "this"
                businessIdentityProfileList.map((item, i) => (

                <li key={item._id} className={classNames({ listStyleNone: true })}>

                  <VerifiedIdentityView
                    businessId={this.state.businessId}
                    profileId={profileId}
                    data={businessIdentityProfileList[i]}
                    onRemoveItem={this.removeIdentityFromProfile}
                  />

                </li>

                ), this)
              : (
                <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                  <TextStyle size="h4" weight="normal">No identities associated</TextStyle>
                  <TextStyle size="h5" weight="normal">There are currently no business identities assocaited with this profile.</TextStyle>
                  
                </div>
              )
              }
              

            </div>
          </div>

          <div className={classNames({ borderTLight: true, marLRMinus10: true })}>
          </div>


          <div className={classNames({ padT10: true})}>
            <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
              <TextStyle size="h3" weight="normal">Business Profile Card</TextStyle>
              <TextStyle size="h5" weight="normal">The following is the business details that a customer
              will see when you make contact with them.</TextStyle>
            </div>
            <div className={classNames({ pad10: true, containerStyleLight: true })}>
              

            {
              businessCardProfileCustomerView && 
              businessCardProfileCustomerView.length > 0 ?
                // Arrow function preserves "this"
                businessCardProfileCustomerView.map((item, i) => {
                  let itemKey = '';
                  let itemValue = '';

                  if (item.type === 'business-brand-words') {
                    itemKey = 'Brand';
                  } else if (item.type === 'business-brand-image') {
                    itemKey = 'Image';
                  } else if (item.type === 'business-name') {
                    itemKey = 'Business Name';
                  } else {
                    itemKey = item.type;
                  }

                  itemValue = item.identity;


                  return (
                    <li key={item._id} className={classNames({ listStyleNone: true })}>
                      
                      <SingleLineCardItem
                      headlineText={`${itemKey}: ${itemValue}`}
                      referenceId={item._id}
                      />

                    </li>
                  )


                }, this)
              : (
                <div className={classNames({ padT10: true, padB10: true, flexGrow: true })}>
                  <TextStyle size="h4" weight="normal">No customer visible identities</TextStyle>
                  <TextStyle size="h5" weight="normal">The customer would not see any business identifiable information. 
                  You are unable to make contact with a customer via our platform without verified business identities.</TextStyle>
                  
                </div>
              )
              }
            


            </div>
          </div>
          <br/><br/>
          
          
        </div>
        }









        <OverlayOnScreenResponsive
          id="selectBusinessIdentitiesItemToAdd"
          type="default"
          width="50%"
          height="100vh"
          anchor="right"
          title="Add an Identity"
          classes=""
        >
          <div className={classNames({ pad10: true })}>
            <div className={classNames({ abc: true })}>
              <TextStyle size="h3">Identities</TextStyle>
              <TextStyle size="h5">Select a verified identity to add to this profile.</TextStyle>
            </div>
            <div className={classNames({ abc: true })}>

              <BusinessIdentityList/>

            </div>

     
            <br/>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true, UsersRolesPermissionDisplay_popupRemovePermissionsFooter: true })}>
              <div className={classNames({ abc: true })}>
                <StandardBtn
                  icon=""
                  iconLocation=""
                  text="Close"
                  tooltip=""
                  action=""
                  classes="shcbtn shcbtn-secondary"
                  handleClick={() => this.closeOverlayOnScreen('selectBusinessIdentitiesItemToAdd')}
                />
              </div>
              <div className={classNames({ abc: true })}>
                
              </div>
            </div>

          </div>
        </OverlayOnScreenResponsive>


      </div>

    );
  }
}

VerifiedProfileInput.propTypes = {
  
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  let overlayProfileId = '';
  try {
    overlayProfileId = state.CommonActions.overlayOnScreenResponsiveData.profileId;
  } catch (e) {
    overlayProfileId = '';
  }
  if (overlayProfileId === undefined) {
    overlayProfileId = '';
  }

  return {
    businessIdentity: Object.assign({}, businessIdentity),
    overlayProfileId,
  };

};

const mapDispatchToProps = {
  addBusinessIdentityAssociation, 
  removeBusinessIdentityAssociation,

  toggleOverlayOnScreenResponsive,

  reduxDataUpdate,
  apiDataUpdate
};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifiedProfileInput);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(VerifiedProfileInput);


/* PROFILE LABLE AND DESCRIPTION FIELDS (WORKING - NOT FOR V1 LAUNCH)
<InputCardItem headlineText="Profile Label" headlineSubText="" additionalText="">
  <InputTextV3
    id="identities.businesses.$.businessIdentityProfiles.$.profileLabel"
    name="identities.businesses.$.businessIdentityProfiles.$.profileLabel"
    identifiers={[{'_id': this.state.businessId}, {'_id': profileId}]}
    stateIdentifier="BusinessIdentities"
    label=""
    value={profileLabel}
    onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
  />
</InputCardItem>

<InputCardItem headlineText="Profile Description" headlineSubText="" additionalText="">
  <InputTextV3
    id="identities.businesses.$.businessIdentityProfiles.$.profileDesc"
    name="identities.businesses.$.businessIdentityProfiles.$.profileDesc"
    identifiers={[{'_id': this.state.businessId}, {'_id': profileId}]}
    stateIdentifier="BusinessIdentities"
    label=""
    value={profileDesc}
    onChangeInputTextHandler={this.onChangeDynamicInputTextHandler}
  />
</InputCardItem>
*/