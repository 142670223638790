
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { StandardBtn } from 'Components/ShcButtons';
import { CardBasic } from 'Components/ShcCards';
// Actions
import { MapSearchApi } from 'Actions';


class InputCardItem extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      headlineSubText: props.headlineSubText,
      headlineText: props.headlineText,
      secondaryText: props.secondaryText,
      additionalText: props.additionalText,
      troubleshootingText: props.troubleshootingText,
      linkText: props.linkText,
      height: props.height,
      customStyle: props.customStyle,
    };
  }

  componentDidMount(props) {
  }
  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { classes } = this.props;
    const {
      headlineText, headlineSubText, secondaryText, additionalText, troubleshootingText, linkText,
      height, customStyle,
    } = this.state;


    const containerHeight = {
      height: this.props.height ? this.props.height : 'auto',
      width: '100%',
    };

    const containerHeightBaseSpacing = {
      height: this.props.height ? `calc(${this.props.height} - 50px)` : 'auto',
    };

    let sectionPadding = true;
    if (customStyle === 'slim') {
      sectionPadding = false;
    }


    return (
      <div >
        <div className={classNames({ SchInputContainer_inputSection: sectionPadding })}>
          <div className={classNames({ SchInputContainer_primaryText: true })}>
            {headlineText}
            { linkText ?
            <font className={classNames({ SchInputContainer_infoText: true })}>
              { linkText || null }
            </font>
            : null }
          </div>
          { headlineSubText ?
            <div className={classNames({ SchInputContainer_secondaryText: true })}>
              {headlineSubText}
            </div>
          : null }
          <div>
            {this.props.children}
          </div>
          { additionalText ?
            <div className={classNames({ SchInputContainer_helpText: true })}>
              {additionalText}
            </div>
          : null }
          { troubleshootingText ?
            <div className={classNames({ SchInputContainer_refText: true })}>
              {troubleshootingText}
            </div>
          : null }
        </div>
      </div>

    );
  }
}

InputCardItem.propTypes = {
  headlineText: PropTypes.string,
  headlineSubText: PropTypes.string,
  secondaryText: PropTypes.string,
  additionalText: PropTypes.string,
  troubleshootingText: PropTypes.string,
  linkText: PropTypes.string,
};

InputCardItem.defaultProps = {
  headlineText: '',
  headlineSubText: '',
  secondaryText: '',
  additionalText: '',
  troubleshootingText: '',
  linkText: '',
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InputCardItem);

/*
<Button
  size="small"
  className={classNames({ shcCardNavigationCard_button: true })}
>{linkText}
</Button>
*/
