import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Link from 'react-router-dom/Link';


import _ from 'lodash';


// CSS
import componentStyles from './styles.css';

// Components
// import Breadcrumb from 'Components/Breadcrumb';
import { Breadcrumb } from 'Components/ShcNavs';
import { BusinessConnectProfileSelection, BusinessAgentDesktopConnectProfileSelection } from 'Components/ShcModulesConnect';
import { SidebarSubNav, SidebarSubNavLinks, SidebarSubNavBackLink } from 'Components/ShcHeader';

import { HeadingStyle, TextStyle, NamedTextStyle } from 'Components/ShcTextStyles';
import { StandardBtn, StandardApiBtn } from 'Components/ShcButtons';
import { InputTextV3, InputTextV4, CheckboxOptionCardReduxV1 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem  } from 'Components/ShcCards';
import { NavAccountHeaderSummary } from 'Components/ShcAccountModules';
import { OverlayOnScreenResponsive } from 'Components/ShcPopups';
import { BusinessContactsIdentitySelection } from 'Components/ShcModulesIdentity';



//Troubleshooting Display (isDevAdmin)
import { TroubleshootingAdminWindow } from 'Components/ShcTroubleshootingUI';


// Actions
import { 
  apiDataUpdate, reduxDataUpdate,
  deleteBusiness, requestImmediateBusinessDeletion, 
  removeBusinessContactIdentityFromAccount,
toggleOverlayOnScreenResponsive, addBusinessContactIdentityToAccount } from 'Actions';


// Functions
import { apiDataTransferTimer, firebaseOnAuthStateChange } from 'Helpers/Functions';


class Template extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.apiDataTransferTimer = apiDataTransferTimer.bind(this);    //Handles data input changes (delaying until no entry before calling api)

   

    this.state = { 
      businessId: this.props.match.params.routebusinessid,
      user: this.props.user,    //The main user object
      
      apiTimerCollection : [],  //List of active timers. (MANDATORY)

      businessName: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("getDerivedStateFromProps - AccountBusinessDashboardPage");
    //console.log("prevState");
    //console.log(prevState);
    //console.log("nextProps");
    //console.log(nextProps);

    let updateState=false;

    if (nextProps.user !== prevState.user) {
      updateState=true;
    }

    if (updateState===true) {
      
      return {
        user: nextProps.user,
      }
    }
    return null;
  }

  async componentDidMount(props) {
    

    ///// Firebase On Auth State Change (START) /////
    try {
      let firebaseAuthResponse = await firebaseOnAuthStateChange();
      if (firebaseAuthResponse.status === true) {
        this.authFirebaseListener = firebaseAuthResponse.authFirebaseListener;
      } else {
        console.warn('Firebase auth failed. Logging user out.');
        this.props.logoutUser();
      }
    } catch (e) {
      console.warn('Firebase Auth State Change | Error');
      console.error(e);
    }
    ///// Firebase On Auth State Change (END) /////
  }

  componentWillUnmount() {
    

    
    ///// Firebase On Auth State Change | Unmount (START) /////
    try {
    this.authFirebaseListener && this.authFirebaseListener(); // Unlisten it by calling it as a function
    } catch (e) {}
    ///// Firebase On Auth State Change | Unmount (END) /////
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate");
    //console.log("forceUpdate()");
    this.forceUpdate();
   
  }


  render() {

    const { businessId } = this.state;
    const { userBusinessRoutePermissions, businessIdentities, businessAccountPostClosure, businessAccountClosed } = this.props;

    
    return (
      <div className={classNames({ pageWrapper: true })}>
        <SidebarSubNav
        urlmatch={this.props.match}
        url={this.props.url}
        backLinkTF="true"
        backTo="/account/business"
        backTitle="Back to Business"
        >
          <div>
            <ul className={classNames({ listNoMarginPadding: true })}>
              <NavAccountHeaderSummary
                urlmatch={this.props.match}
                url={this.props.url}
                backLinkTF="true"
                backTo="/account/business"
                backTitle="Back to Business"
                smallTitleTF="true"
                smallTitle="Business"
                headlineTitleTF="true"
                headlineTitle={this.props.businessIdentity.businessProfile.businessName}
                sublineTextTF="true"
                sublineText={this.props.match.params.routebusinessid}
                sectionTitle="Account Settings"
                linkGroupSelectionTF="true"
                linkGroupSelection="business-account"
              />
            </ul>
          </div>
          <div>
            <div>
              <div>
                <Breadcrumb breadcrumbData={this.props.breadcrumb} rightcrumbData={this.props.rightcrumb} />
              </div>
            </div>
            <div className={classNames({ LinkGroupLayout_ContainerTitle: true })}>
              {this.props.title}
            </div>


            
            <div>
              <InputCard headlineText="Customer Connect" 
              headlineSubText="Your business securely connecting with customers.">

                <div className={classNames({ text_s14: true })}>For your business to securely connect with customers 
                you are required to initiate the engagement via the Guard Point Agent Desktop. This allows Guard Point to securely
                notify your customer of your interaction and provide you with real-time information as to whether they are 
                available to take your call. </div>
                
                <br/>
                <InputCardItem headlineText="How to Connect with Customers" 
                headlineSubText="" additionalText="">
                  
                  <div className={classNames({ text_s14: true })}>
                    <ul>
                      <li>Ensure you have a valid business subscription</li>
                      <li>Ensure you have a business profile setup with all your business identities verified</li>
                      <li>Ensure you have business agent accounts setup.</li>
                      <li>Select a business profile below and open the Agent Desktop</li>
                      <li>Log in with your Agent Email Address</li>
                      <li>Type in your customers phone number or identity</li>
                      <li>Click Send and await a response from the customer</li>
                    </ul>
                  </div>
                  
                </InputCardItem>

 


              </InputCard>
            </div>

            <div>
              {
              this.props.businessIdentity && 
              this.props.businessIdentity.businessIdentityProfiles.length > 0 ?
                // Arrow function preserves "this"
              this.props.businessIdentity.businessIdentityProfiles.map((item, i) => {
                let profileName = '';
                try {
                  profileName = item.profileName;
                } catch (e) {
                  profileName = '';
                }
                if (profileName === undefined) {
                  profileName = '';
                }
                if (profileName === '') {
                  profileName = "Unnamed Profile";
                }
                let profileDetails = [];
                let profileTypes = '';
                let profileIdentities = '';
                try {
                  for (let y = 0; y < this.props.businessIdentity.businessIdentities.length; y++) {
                    for (let x = 0; x < item.profileAttributes.length; x++) {
                      let identityReferenceId = item.profileAttributes[x].identityReferenceId;
                      if (identityReferenceId === this.props.businessIdentity.businessIdentities[y]._id) {
                        //match
                        let identityData = {
                          identity: this.props.businessIdentity.businessIdentities[y].identity,
                          type: this.props.businessIdentity.businessIdentities[y].type,
                        }
                        profileDetails.push(identityData);
                        if (profileTypes.length === 0) {
                          profileTypes = this.props.businessIdentity.businessIdentities[y].type;
                        } else {
                          profileTypes = `${profileTypes}, ${this.props.businessIdentity.businessIdentities[y].type}`;
                        }
                        if (profileIdentities.length === 0) {
                          profileIdentities = this.props.businessIdentity.businessIdentities[y].identity;
                        } else {
                          profileIdentities = `${profileIdentities}, ${this.props.businessIdentity.businessIdentities[y].identity}`;
                        }
                      }
      
                    }
                  }
                } catch(e) {}

                

                return (
                  <li key={item._id} className={classNames({ listNone: true })}>
                    <BusinessAgentDesktopConnectProfileSelection
                    profileName={profileName}
                    profileDetails={profileDetails}
                    profileTypes={profileTypes}
                    profileIdentities={profileIdentities}
                    businessId={this.state.businessId}
                    businessDataRegion={this.props.businessIdentityDataRegion}
                    businessProfileId={item._id}
                    data={item}
                    />
                  </li>
                )

                }, this)
              : (
                <div className={classNames({ padT10: true })}>
                  Please create a Business Profile in the business account section.
                  <br/><br/>
                  A Business Profile is a collation of verified identities that represents your business. 
                  These identities are provided to the customer when you make contact with them.
                </div>
              )
              }
            </div>


  
            
            
          </div>
        </SidebarSubNav>
      </div>
    );
  }
}

Template.propTypes = {
};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  let businessIdentities = '';
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // console.log("ownProps");
  // console.log(ownProps);
  const businessId = ownProps.match.params.routebusinessid;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }

  try {
    businessIdentities = businessIdentity.businessIdentities;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business identities');
  }

  let accountOptions = {};
  try {
    accountOptions = businessIdentity.accountOptions;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive all the business account options');
  }
  let identityNotificationStartTime = {};
  try {
    identityNotificationStartTime = accountOptions.identityNotificationStartTime;
  } catch (e) {}
  let identityNotificationEndTime = {};
  try {
    identityNotificationEndTime = accountOptions.identityNotificationEndTime;
  } catch (e) {}
  let accountCountry = {};
  try {
    accountCountry = accountOptions.accountCountry;
  } catch (e) {}
  let accountTimezone = {};
  try {
    accountTimezone = accountOptions.accountTimezone;
  } catch (e) {}



  //console.log('businessIdentities');
  //console.log(businessIdentities);
  // console.log("mapStateToProps | businessIdentity");
  // console.log(businessIdentity);

  let businessUiCheckboxOptionsValues = [];
  if (state.UI.uiCheckboxOptions) {
    //console.log('mapStateToProps | uiCheckboxOptions is true');
    for (let z = 0; z < state.UI.uiCheckboxOptions.length; z++) {
      //console.log(`mapStateToProps | seaching for business id: ${state.UI.uiCheckboxOptions[z].businessId} === ${businessId}`);
      try {
        if (state.UI.uiCheckboxOptions[z].businessId.toString() === businessId.toString()) {
          businessUiCheckboxOptionsValues.push(state.UI.uiCheckboxOptions[z]);
        }
      } catch (e) {}
      
    }
  } else {
    checkboxSelectedState = false;
    //console.log('mapStateToProps | Checkbox state not found');
  }


  let businessDataStorageRegion = '';
  try {
    businessDataStorageRegion = businessIdentity.dataRegion;
  } catch (e) {}
  if (businessDataStorageRegion === 'aus') {
    businessDataStorageRegion = "Australia";
  } else if (businessDataStorageRegion === 'usa') {
    businessDataStorageRegion = "United States";
  }


  let businessAccountPostClosure = false;
  try {
    businessAccountPostClosure = businessIdentity.accountPostClosure;
  } catch (e) {
    console.error('mapStateToProps | Unable to retreive account info');
  }

  //// CHECK IF BUSINESS IS CLOSED / OR IN CLOSURE
  let businessAccountClosed = false;
  try {
    businessAccountClosed = businessIdentity.accountClosed;
  } catch (e) {}
  if (businessAccountClosed === undefined || businessAccountClosed === '' || businessAccountClosed === null) {
    businessAccountClosed = false;
  }
  

  return {
    businessAccountClosed,
    businessIdentity: Object.assign({}, businessIdentity),
    businessIdentities: Object.assign([], businessIdentities),
    user: state.User,
    businessAccountPostClosure: businessAccountPostClosure,

    businessUiCheckboxOptionsValues: Object.assign([], businessUiCheckboxOptionsValues),  //Allows the lookup of what checkbox options have been selected.
  

    identityNotificationStartTime,
    identityNotificationEndTime,
    accountCountry,
    accountTimezone,

    businessDataStorageRegion,
  
  };

};


const mapDispatchToProps = {
  reduxDataUpdate,
  apiDataUpdate,

  deleteBusiness,
  requestImmediateBusinessDeletion,
  removeBusinessContactIdentityFromAccount,


  toggleOverlayOnScreenResponsive,
  addBusinessContactIdentityToAccount,


};

export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Template)



/* IMMEDIATE CLOSURE HAS BEEN REMOVED AS PART OF LEGAL PROCESS
<div>
  <StandardApiBtn
  apiActivityId="requestImmediateBusinessDeletion"
  icon=""
  iconLocation=""
  text="Request Immediate Business Deletion"
  tooltip=""
  action=""
  classes="shcbtn shcbtn-primary"
  handleClick={this.requestImmediateBusinessDeletion}
  />
</div>
*/

/* REMOVE TEMPERORILY FROM THE PLATFORM

<InputCardItem headlineText="Country" 
headlineSubText="Country referenced for our platform.">
  <div className={classNames({ flex: true,})}>
    <div className={classNames({ minWidth350: true })}>
      <Select options={countryOptionList} 
      onChange={this.handleSelectInputChange_accountOptions_country} 
      value={countryOptionList.find(option => option.value === this.props.accountCountry)}
      />
    </div>
  </div>
</InputCardItem>

<InputCardItem headlineText="Timezone" 
headlineSubText="Timezone used for our platform contacting your business.">
  <div className={classNames({ flex: true, minWidth250: true })}>
    <div className={classNames({ minWidth350: true })}>
      <Select options={timezoneOptionList} 
      onChange={this.handleSelectInputChange_accountOptions_timezone} 
      value={timezoneOptionList.find(option => option.value === this.props.accountTimezone)}
      />
    </div>
  </div>
</InputCardItem>


<InputCardItem headlineText="Identity Verification Notification Times" 
headlineSubText="Select the time you would like to receive the automatic re-verification notifications">
  <div className={classNames({ flex: true })}>
    <div className={classNames({ minWidth110: true })}>
      <Select options={timeOptions} 
      onChange={this.handleSelectInputChange_startTime} 
      value={timeOptions.find(option => option.value === this.props.identityNotificationStartTime)}
      />
    </div>
    <div className={classNames({ minWidth110: true })}>
      <Select options={timeOptions} 
      onChange={this.handleSelectInputChange_endTime} 
      value={timeOptions.find(option => option.value === this.props.identityNotificationEndTime)}
      />
    </div>
  </div>
</InputCardItem>

*/