import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import _ from 'lodash';
// import { map, tail, times, uniq } from 'lodash';


// Routing

// Material UI

// CSS
import componentStyles from './styles.css';

// Components
import { HeadingStyle, TextStyle } from 'Components/ShcTextStyles';
import { StandardBtn } from 'Components/ShcButtons';
import { InputTextV2 } from 'Components/ShcInput';
import { CardBasicV2, InputCard, InputCardItem } from 'Components/ShcCards';


// Actions
// import {removeBusinessWebsiteVerificationItem } from 'Actions';


class BusinessConnectProfileSelectionItems extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    // this.selectItem = this.selectItem.bind(this);

    this.state = {

    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  selectItem(profileId, evt) {
    //console.log('Item Selected');
    //console.log('profileId');
    //console.log(profileId);

    this.props.onItemSelected(profileId);
  }


  render() {
    const { data, onItemSelected } = this.props;

    let profileId = '';
    try { profileId = data._id; } catch (e) {}
    let profileName = '';
    try { profileName = data.profileName; } catch (e) {}
    let profileDesc = '';
    try { profileDesc = data.profileDesc; } catch (e) {}
    let profileAttributes = [];
    try { profileAttributes = data.profileAttributes; } catch (e) {}

    const IdentityContactTypesList = [];
    try {
      for (let x = 0; x < profileAttributes.length; x++) {
        //console.log(`profileAttributes | ${profileAttributes[x].identityReferenceId}`);
        for (let y = 0; y < this.props.businessIdentity.businessIdentities.length; y++) {
          //console.log(`businessIdentities | ${this.props.businessIdentity.businessIdentities[y]._id}`);
          if (this.props.businessIdentity.businessIdentities[y]._id === profileAttributes[x].identityReferenceId) {
            // Found a profileAttribute ID that matches an identity ID.

            //console.log(`Matched Type | ${this.props.businessIdentity.businessIdentities[y].type}`);


            let item = IdentityContactTypesList.find(x => x === this.props.businessIdentity.businessIdentities[y].type);
            if (!item) {
              IdentityContactTypesList.push(this.props.businessIdentity.businessIdentities[y].type);
            }
          }
        }
      }
    } catch (e) {
      console.error('Unable to process profile identities.');
    }

    const headlineSmallText = profileId;
    const headlineText = profileName;
    const headlineSubText = IdentityContactTypesList.join(', ');

    // on select

    return (
      <div className={classNames({ ShcModulesIdentityContainer: true })}>
        <div
          className={classNames({ ShcModulesIdentityContainerHeader: true, flex: true, cursorPointer: true })}
          onClick={this.selectItem.bind(this, profileId)}
        >
          <div className={classNames({ pad10: true, flexGrow: true })}>
            <div className={classNames({ flex: true, flexJustifySpaceBetween: true })}>
              <div>
                <div className={classNames({ flex: true, flexColumn: true })}>
                  <div className={classNames({ text_h6: true })}>{headlineSmallText}</div>
                  <div className={classNames({ text_h3: true })}>{headlineText}</div>
                  <div className={classNames({ text_h6: true })}>{headlineSubText}</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

BusinessConnectProfileSelectionItems.propTypes = {

};

const mapStateToProps = (state, ownProps) => {
  let businessIdentity = null;
  try {
    businessIdentity = ownProps.businessIdentity;
  } catch (e) {}
  // //console.log("ownProps");
  // //console.log(ownProps);
  const businessId = ownProps.businessId;
  try {
    businessIdentity = state.BusinessIdentities.identities.businesses.find(business => business._id === businessId);
  } catch (e) {
    console.error('mapStateToProps | Unable to determine this business Identity');
  }
  // //console.log("mapStateToProps | businessIdentity");
  // //console.log(businessIdentity);

  return {
    businessIdentity: Object.assign({}, businessIdentity),
  };
};

const mapDispatchToProps = {

};
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifiedProfileInput);
*/
// Performance Impact: (deep inspection for connect) - https://stackoverflow.com/questions/37623628/react-componentdidupdate-method-wont-fire-on-inherited-props-change-if-connecte
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(BusinessConnectProfileSelectionItems);
